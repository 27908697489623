import React from "react";
import moment from "moment";
import applyName from "../../../services/idCardRealName";
import gSass from "../../../utils/sass";
import { BASE_URL, } from '../../../config/prod';
import { Link, } from "react-router-dom";
import qs from "qs";
import {
  Breadcrumb,
  Form,
  Input,
  message,
  Spin,
  Button,
  Upload,
  Icon,
  Modal,
  Select,
  Radio,
  DatePicker
} from "antd";
import { IS_HOUSEHOLDREGISTER } from "../../../services/idCardRealName";
import { getUploadImgUrl } from '../../../services/api';
import idCardRealNameApi from "../../../services/idCardRealName";
import applyApi, { EDUCATION, EDUCATION_ZH, } from "../../../services/apply";
import { GENDER, GENDER_ZH } from "../../../services/user";
import { IdentityCodeValid } from "../../../utils/utils";
import collegeApi from '../../../services/college';
import schoolSpecialityApi from "../../../services/schoolSpeciality";
import { IS_GRADUATE } from "../../../services/schoolInfo";
const style = gSass.admin.applyEdit.edit;
const Option = Select.Option;
const RadioGroup = Radio.Group;
const formItemLayout = {
  labelCol: {
    lg: {
      span: 4,
      offset: 4,
    },
  },
  wrapperCol: {
    lg: {
      span: 8,
      offset: 0,
    },
  },
};

//地址
const uploadButton = (
  <div>
    <Icon type="plus" />
    <div className="ant-upload-text">上传图片</div>
  </div>
);
// 届别
let date = new Date().getFullYear(), gradeList = [];
for (let i = 0; i <= (date - 1990); i++) {
  gradeList.push({ value: i, label: (1990 + i) })
}

class Edit extends React.Component {
  constructor(props) {
    super(props);
    let { id } = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    id = parseInt(id);
    this.state = {
      projectId: id,
      spinning: true,
      industryList: [],
      patentTypeList: [],
      patentList: [],
      financingList: [],
      userInfo: {
        schoolInfo: {
          isGraduate: 0,
          isOversea: 0,
        }
      },
      idCardFirstPic: [],
      idCardSecondPic: [],
      previewVisible: false,
      previewImage: '',
      householdList: [],
      residentPic: [],
      isShanghai: null,
      applyId: 0,
      infoId: 0,
      // 学校
      collegeList: [],
      specialityList: [],
      educationList: [],
      grades: gradeList,
      oneCardPassPic: [],
      degreePic: [],
      overseasCard: [],
      isGraduate: null,
      isOversea: null,
      gender: null,
    };
  }
  componentDidMount() {
    this.init();
  }
  init = async _ => {
    try {
      // 获取户籍
      let { data: { list: householdList } } = await idCardRealNameApi.getProvice({ page: -1, limit: -1 })
      let { data: { list: collegeList } } = await collegeApi.getList({ page: -1, limit: -1 })
      collegeList = collegeList.map(v => ({
        value: v.id,
        label: v.name,
      }))
      //获取学校专业列表
      let { data: { list: specialityList } } = await schoolSpecialityApi.getList({ page: -1, limit: -1 })
      specialityList = specialityList.map(v => ({
        value: v.id,
        label: v.name,
      }))
      //  获取学历
      let educationList = Object.values(EDUCATION).map(v => ({
        value: v,
        label: EDUCATION_ZH[v]
      }))
      // 获得服务器数据
      let { data: { detail, } } = await applyApi.getApplyDetail({ id: this.state.projectId });
      householdList = householdList.map(v => ({
        value: v.id,
        label: v.name,
      }))
      let address = ''
      for (let i = 0; i < householdList.length; i++) {
        if (householdList[i].value === detail.info.idHousehold) {
          address = householdList[i].label
        }
      }
      if (address === "上海") {
        detail.info.is_shanghai = IS_HOUSEHOLDREGISTER.TRUE;
      } else {
        detail.info.is_shanghai = IS_HOUSEHOLDREGISTER.FALSE;
      }
      console.log(detail.info.gender)
      this.setState({
        spinning: false,
        applyId: detail.apply_uid,
        infoId: detail.info.id,
        householdList,
        idCardFirstPic: [{
          url: BASE_URL + detail.info.id_card_first.url,
          uid: detail.info.id_card_first.id,
        }],

        idCardSecondPic: [{
          url: BASE_URL + detail.info.id_card_second.url,
          uid: detail.info.id_card_second.id,
        }],
        residentPic: detail.info.id_reside === "" ? [] : [{
          url: BASE_URL + detail.info.id_reside.url,
          uid: detail.info.id_reside.id,
        }],
        isShanghai: detail.info.is_shanghai,
        // 学校
        collegeList,
        specialityList,
        educationList,
        oneCardPassPic: detail.info.isGraduate !== IS_GRADUATE.TRUE && detail.info.oneCardPassPicList ? [{
          url: BASE_URL + detail.info.oneCardPassPicList.url,
          uid: detail.info.oneCardPassPicList.id,
        }] : [],
        degreePic: detail.info.isGraduate === IS_GRADUATE.TRUE && detail.info.degreePicList ? [{
          url: BASE_URL + detail.info.degreePicList.url,
          uid: detail.info.degreePicList.id,
        }] : [],
        overseasCard: detail.info.overseas_degree_pic_id_list && detail.info.overseas_degree_pic_id_list ? [{
          url: BASE_URL + detail.info.overseas_degree_pic_id_list.url,
          uid: detail.info.overseas_degree_pic_id_list.id,
        }] : [],
        isGraduate: detail.info.isGraduate,
        gender: detail.info.gender,
        isOversea: detail.info.is_overseas,
      }, () => {
      });
      setTimeout(() => {
        this.props.form.setFieldsValue({
          college: detail.info.collegeId,
          speciality: detail.info.specialityId,
          grade: detail.info.grade,
          education: detail.info.educationId,
          isGraduate: detail.info.isGraduate,
          gender: detail.info.gender,
          isOversea: detail.info.is_overseas,
          overseaCollege: detail.info.overseas_college_id,
          overseaSpeciality: detail.info.overseas_speciality_id,
          overseaEducation: detail.info.overseas_education,
          overseaTime: moment(detail.info.overseas_graduation_time, "YYYY-MM-DD HH:mm:ss"),
          name: detail.info.name,
          phone: detail.info.idPhone,
          age: detail.info.idAge,
          email: detail.info.idEmail,
          household: detail.info.idHousehold,
          idCardNo: detail.info.idCardNo,
          isShanghai: detail.info.is_shanghai
        })
      }, 0)
    } catch (e) {
      console.log(e);
      this.setState({
        spinning: false,
      });
    }
  };

  //提交
  updateUserInfo = evt => {
    evt.preventDefault();
    this.props.form.validateFields(["name", "phone", "age", "email", "gender", "household", "idCardNo", "idCardFirstPic", "idCardSecondPic", "residentPic",
      "college", "speciality", "grade", "education", "isGraduate", "isOversea", "overseaCollege", "overseaSpeciality", "overseaTime", "overseaEducation"], (err, fields) => {
        if (err) {
          return message.error(err[Object.keys(err)[0]].errors[0].message, 1);
        }
        if (this.state.isGraduate === 2) {
          if (this.state.oneCardPassPic.length === 0) {
            message.error("请上传一卡通证件照", 1);
            return;
          }
        }
        if (this.state.isGraduate === 1) {
          if (this.state.degreePic.length === 0) {
            message.error("请上传学位证证件照", 1);
            return;
          }
        }
        let { idCardFirstPic, idCardSecondPic, residentPic, oneCardPassPic, degreePic, overseasCard } = this.state
        if (idCardFirstPic[0].response) {
          idCardFirstPic = idCardFirstPic[0].response.data.picId
        } else {
          idCardFirstPic = idCardFirstPic[0].uid
        }

        if (idCardSecondPic[0].response) {

          idCardSecondPic = idCardSecondPic[0].response.data.picId
        } else {
          idCardSecondPic = idCardSecondPic[0].uid
        }
        if (residentPic.length !== 0) {
          if (residentPic[0].response) {
            residentPic = residentPic[0].response.data.picId
          } else {
            residentPic = residentPic[0].uid
          }
        }
        if (degreePic.length !== 0) {
          if (degreePic[0].response) {
            degreePic = degreePic[0].response.data.picId
          } else {
            degreePic = degreePic[0].uid
          }
        }
        if (overseasCard.length !== 0) {
          if (overseasCard[0].response) {
            overseasCard = overseasCard[0].response.data.picId
          } else {
            overseasCard = overseasCard[0].uid
          }
        }
        let data = {
          applyId: this.state.projectId,
          idCardName: fields.name,
          idPhone: fields.phone,
          idAge: fields.age,
          idEmail: fields.email,
          idGender: fields.gender, /////
          residePic: this.state.isShanghai === 1 ? residentPic : "",
          household: fields.household,
          idCardNo: fields.idCardNo || "",
          idCardFirstPic: idCardFirstPic,
          idCardSecondPic: idCardSecondPic,
          college: fields.college,
          speciality: fields.speciality,
          grade: fields.grade,
          education: fields.education,
          isGraduate: fields.isGraduate || this.state.userInfo.schoolInfo.isGraduate,
          isOverseas: fields.isOversea || this.state.userInfo.schoolInfo.isOversea,
          degreePic,
          oneCardPassPic,
          seasEducation: fields.overseaEducation,
          seasSpeciality: fields.overseaSpeciality,
          seasCollege: fields.overseaCollege,
          seasGraduationTime: moment(fields.overseaTime).format("YYYY-MM-DD HH:mm:ss"),
          overseasCard: overseasCard,
        }
        applyName
          .mergeInfoEditAdmin(data)
          .then(json => {
            message.success("修改成功", 1);
          })
          .catch(err => {
            message.error("修改失败,失败原因: " + err.msg, 1);
          });
      });
  };
  //上传图片
  handlePreview = file => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true,
    });
  }
  idCardFirstPicChange = ({ fileList }) => this.setState({ idCardFirstPic: fileList })
  idCardSecondPicChange = ({ fileList }) => {
    console.log(111)
    this.setState({ idCardSecondPic: fileList })
  }
  handleCancel = _ => this.setState({ previewVisible: false })
  residentPicChange = ({ fileList }) => this.setState({ residentPic: fileList })
  //身份证号码验证
  idCardNoValidation = evt => {
    let val = evt.target.value;
    IdentityCodeValid(val).then(json => {
      this.setState({
        idCardNoValidation: json.pass,
      });
      if (!json.pass) {
        message.error("身份证号码错误: " + json.msg, 1);
      }
    });
  };
  // 改变学校
  changeCollege = evt => {
    const { setFieldsValue } = this.state.form;
    let { specialityList } = this.state.specialityList
    specialityList = [];
    collegeApi.getSpecialityList(evt).then(json => {
      let detail = json.data.detail;
      for (let v of detail) {
        specialityList.push(v)
      }
      specialityList = specialityList.map(v => ({
        value: v.id,
        label: v.name
      }))
      this.setState({
        specialityList
      }, () => {
        setFieldsValue({
          speciality: specialityList.value
        })
      })
    })
  }
  // 改变户籍
  changeHouseholder = val => {
    let householdList = this.state.householdList, address = "";
    for (let i = 0; i < householdList.length; i++) {
      if (householdList[i].value === val) {
        address = householdList[i].label
      }
    }
    if (address === "上海") {
      this.setState({
        isShanghai: IS_HOUSEHOLDREGISTER.TRUE
      })
    } else {
      this.setState({
        isShanghai: IS_HOUSEHOLDREGISTER.FALSE
      })
    }
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    const { previewVisible, previewImage, idCardFirstPic, idCardSecondPic,
      residentPic, oneCardPassPic, degreePic, overseasCard, } = this.state;
    //头像上传按钮
    return (
      <Spin tip="Loading..." spinning={this.state.spinning} size="large">
        <div className={style.info}>
          <Breadcrumb className={style.title}>
            <Breadcrumb.Item><Link to="/admin">首页</Link></Breadcrumb.Item>
            <Breadcrumb.Item><Link to="/admin/apply/allList">申请管理</Link></Breadcrumb.Item>
            <Breadcrumb.Item>基本信息申请编辑</Breadcrumb.Item>
          </Breadcrumb>
          <div className={style.basicWrap}>
            <Form className={style.form}>
              <Form.Item {...formItemLayout} label="姓名">
                {getFieldDecorator("name", {
                  rules: [{ required: true, message: "请输入姓名" }],
                })(<Input placeholder="请输入" />)}
              </Form.Item>
              <Form.Item {...formItemLayout} label="电话">
                {getFieldDecorator("phone", {
                  rules: [{ required: true, message: "请输入电话" }],
                })(<Input placeholder="请输入" />)}
              </Form.Item>
              <Form.Item {...formItemLayout} label="年龄">
                {getFieldDecorator("age", {
                  rules: [{ required: true, message: "请输入年龄" }],
                })(<Input placeholder="请输入" />)}
              </Form.Item>
              <Form.Item {...formItemLayout} label="邮箱">
                {getFieldDecorator("email", {
                  rules: [{ required: true, message: "请输入邮箱" }],
                })(<Input placeholder="请输入" />)}
              </Form.Item>

              <Form.Item {...formItemLayout} label="性别"  >
                {getFieldDecorator('gender', {
                  rules: [{ required: true, message: '请选择性别' }],
                })(
                  <RadioGroup onChange={evt => {
                    this.setState({
                      gender: evt.target.value,
                    })
                  }}>
                    <Radio value={GENDER.MAN}>{GENDER_ZH[GENDER.MAN]}</Radio>
                    <Radio value={GENDER.WOMAN}>{GENDER_ZH[GENDER.WOMAN]}</Radio>
                  </RadioGroup>
                )}
              </Form.Item>
              <Form.Item {...formItemLayout} label="户籍"  >
                {getFieldDecorator('household', {
                  rules: [{ required: true, message: '请选择户籍' }],
                })(
                  <Select placeholder="请选择户籍" onChange={(e) => this.changeHouseholder(e)}>
                    {this.state.householdList.map((v, k) => {
                      return (<Option key={`${k}`} value={v.value}>{v.label}</Option>);
                    })}
                  </Select>
                )}
              </Form.Item>
              <Form.Item {...formItemLayout} label="居住证"
                className={this.state.isShanghai === IS_HOUSEHOLDREGISTER.FALSE ? "" : style.hidden}>
                {getFieldDecorator('residentPic', {
                })(
                  <div className="clearfix">
                    <Upload action={getUploadImgUrl()} listType="picture-card" fileList={residentPic}
                      onPreview={this.handlePreview} onChange={this.residentPicChange}>
                      {residentPic.length > 0 ? null : uploadButton}
                    </Upload>
                    <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                      <img alt="example" style={{ width: '100%' }} src={previewImage} />
                    </Modal>
                  </div>
                )}
              </Form.Item>
              <Form.Item {...formItemLayout} label="身份证号码"  >
                {getFieldDecorator('idCardNo', {
                  rules: [{ required: true, message: '请输入身份证号码' }],
                })(
                  <Input placeholder="请输入身份证号码" onBlur={this.idCardNoValidation} />)}
              </Form.Item>
              <Form.Item {...formItemLayout} label="身份证正面">
                {getFieldDecorator('idCardFirstPic', {})(
                  <div className="clearfix">
                    <Upload action={getUploadImgUrl()} listType="picture-card" fileList={idCardFirstPic}
                      onPreview={this.handlePreview} onChange={this.idCardFirstPicChange}>
                      {idCardFirstPic.length > 0 ? null : uploadButton}
                    </Upload>
                    <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                      <img alt="example" style={{ width: '100%' }} src={previewImage} />
                    </Modal>
                  </div>
                )}
              </Form.Item>
              <Form.Item {...formItemLayout} label="身份证反面">
                {getFieldDecorator('idCardSecondPic', {})(
                  <div className="clearfix">
                    <Upload action={getUploadImgUrl()} listType="picture-card" fileList={idCardSecondPic}
                      onPreview={this.handlePreview} onChange={this.idCardSecondPicChange}>
                      {idCardSecondPic.length > 0 ? null : uploadButton}
                    </Upload>
                    <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                      <img alt="example" style={{ width: '100%' }} src={previewImage} />
                    </Modal>
                  </div>
                )}
              </Form.Item>
              {/* 学校信息 */}
              <Form.Item {...formItemLayout} label="学校"  >
                {getFieldDecorator('college', {
                  rules: [{ required: true, message: '请选择学校' }],
                })(
                  <Select placeholder="请选择学校" onChange={(e) => {
                    this.changeCollege(e)
                  }}>
                    {this.state.collegeList.map((v, k) => {
                      return (<Option key={`${k}`} value={v.value}>{v.label}</Option>);
                    })}
                  </Select>
                )}
              </Form.Item>
              <Form.Item {...formItemLayout} label="专业"  >
                {getFieldDecorator('speciality', {
                  rules: [{ required: true, message: '请选择专业' }],
                })(
                  <Select placeholder="请选择专业">
                    {this.state.specialityList.map((v, k) => {
                      return (<Option key={`${k}`} value={v.value}>{v.label}</Option>);
                    })}
                  </Select>
                )}
              </Form.Item>
              <Form.Item {...formItemLayout} label="届别"  >
                {getFieldDecorator('grade', {
                  rules: [{ required: true, message: '请选择届别' }],
                })(
                  <Select placeholder="请选择届别">
                    {this.state.grades.map((v, k) => {
                      return (<Option key={`${k}`} value={v.value}>{v.label}</Option>);
                    })}
                  </Select>
                )}
              </Form.Item>

              <Form.Item {...formItemLayout} label="学历"  >
                {getFieldDecorator('education', {
                  rules: [{ required: true, message: '请选择学历' }],
                })(
                  <Select placeholder="请选择学历">
                    {this.state.educationList.map((v, k) => {
                      return (<Option key={`${k}`} value={v.value}>{v.label}</Option>);
                    })}
                  </Select>
                )}
              </Form.Item>

              <Form.Item {...formItemLayout} label="是否为毕业生"  >
                {getFieldDecorator('isGraduate', {
                  rules: [{ required: true, message: '请选择是否为毕业生' }],
                })(
                  <RadioGroup onChange={evt => {
                    this.setState({
                      isGraduate: evt.target.value,
                    })
                  }}>
                    <Radio value={IS_GRADUATE.FALSE}> 否</Radio>
                    <Radio value={IS_GRADUATE.TRUE}>是</Radio>
                  </RadioGroup>
                )}
              </Form.Item>

              <Form.Item {...formItemLayout} label="一卡通证件照"
                className={this.state.isGraduate === IS_GRADUATE.FALSE ? "" : style.hidden}>
                {getFieldDecorator('oneCardPassPic', {
                })(
                  <div className="clearfix">
                    <Upload action={getUploadImgUrl()} listType="picture-card" fileList={oneCardPassPic}
                      onPreview={this.handlePreview} onChange={this.oneCardPassPicChange}>
                      {oneCardPassPic.length > 0 ? null : uploadButton}
                    </Upload>
                    <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                      <img alt="example" style={{ width: '100%' }} src={previewImage} />
                    </Modal>
                  </div>
                )}
              </Form.Item>
              <Form.Item {...formItemLayout} label="学位证证件照"
                className={this.state.isGraduate === IS_GRADUATE.TRUE ? "" : style.hidden}>
                {getFieldDecorator('degreePic', {
                })(
                  <div className="clearfix">
                    <Upload action={getUploadImgUrl()} listType="picture-card" fileList={degreePic}
                      onPreview={this.handlePreview} onChange={this.degreePicChange}>
                      {degreePic.length > 0 ? null : uploadButton}
                    </Upload>
                    <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                      <img alt="example" style={{ width: '100%' }} src={previewImage} />
                    </Modal>
                  </div>
                )}
              </Form.Item>
              <Form.Item {...formItemLayout} label="是否有海外留学背景"  >
                {getFieldDecorator('isOversea', {
                  rules: [{ required: true, message: '请选择是否有海外留学背' }],
                })(
                  <RadioGroup onChange={evt => {
                    this.setState({
                      isOversea: evt.target.value,
                    })
                  }}>
                    <Radio value={IS_GRADUATE.UNDEFINED}> 否</Radio>
                    <Radio value={IS_GRADUATE.TRUE}>是</Radio>
                  </RadioGroup>
                )}
              </Form.Item>
              {this.state.isOversea === 1 ? (<div><Form.Item {...formItemLayout} label="海外学校" >
                {getFieldDecorator("overseaCollege", {
                  rules: [{ required: true, message: "请输入海外学校" }],
                })(<Input placeholder="请输入" />)}
              </Form.Item>
                <Form.Item {...formItemLayout} label="海外专业">
                  {getFieldDecorator("overseaSpeciality", {
                    rules: [{ required: true, message: "请输入海外专业" }],
                  })(<Input placeholder="请输入" />)}
                </Form.Item>
                <Form.Item {...formItemLayout} label="学历"  >
                  {getFieldDecorator('overseaEducation', {
                    rules: [{ required: true, message: '请选择学历' }],
                  })(
                    <Select placeholder="请选择学历">
                      {this.state.educationList.map((v, k) => {
                        return (<Option key={`${k}`} value={v.value}>{v.label}</Option>);
                      })}
                    </Select>
                  )}
                </Form.Item>
                <Form.Item {...formItemLayout} label="毕业时间"  >
                  {getFieldDecorator('overseaTime', {
                    rules: [{ required: true, message: '请选择毕业时间' }],
                  })(
                    <DatePicker placeholder="请选择" />
                  )}
                </Form.Item>
                <Form.Item {...formItemLayout} label="毕业证或在读证明">
                  {getFieldDecorator('overseasCard', {
                  })(
                    <div className="clearfix">
                      <Upload action={getUploadImgUrl()} listType="picture-card" fileList={overseasCard}
                        onPreview={this.handlePreview} onChange={this.overseasCardChange}>
                        {overseasCard.length > 0 ? null : uploadButton}
                      </Upload>
                      <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                        <img alt="example" style={{ width: '100%' }} src={previewImage} />
                      </Modal>
                    </div>
                  )}
                </Form.Item>
              </div>) : null
              }
              <Form.Item wrapperCol={{
                xs: {
                  span: 7,
                  offset: 8,
                },
              }}>
                <Button className={style.btn} type="primary" onClick={this.updateUserInfo}>更新基本信息</Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Spin>
    );
  }
}
export default Form.create()(Edit);
