import React from "react";
import {
  List, Picker, TextareaItem, ActivityIndicator, NavBar, Icon, InputItem,
  Button, Switch, Flex, DatePicker, Toast, ImagePicker, Modal,
} from "antd-mobile";
import { Form, message, Upload } from 'antd';
import { withRouter, } from 'react-router-dom';
import AliIcon from "../../../../utils/iconfont";
import { BASE_URL, } from "../../../../config/prod";
import gStyle from "../../../../utils/sass";
import companyApi from "../../../../services/company";
import { antdUploadImg, } from "../../../../services/api";
import userApi from "../../../../services/user";
import ApplyWithSendMsg from "../../../../components/index/user/apply/ApplyWithSendMsg";
import applyApi, { EDUCATION, EDUCATION_ZH, APPLY_STATUS, } from "../../../../services/apply";
import { TYPE as PatentType, TYPE_ZH as PatentTypeZh, FINANCING_TYPE as FinancingType, FINANCING_TYPE_ZH as FinancingTypeZh, SUBORDINATE_TYPE as SubordinateType, SUBORDINATE_TYPE_ZH as SubordinateTypeZh } from "../../../../services/patent";
import { getRegion, IdentityCodeValid } from "../../../../utils/utils";
import moment from "moment";

const style = gStyle.index.user.apply.companyEnter;
const Item = List.Item;
// 上传附件
let uploadList = [];
const upload = {
  action: BASE_URL + '/antdUploadImg',
  onChange({ file, fileList }) {
    if (file.status !== 'uploading') {
      if (file.response.code === 0) {
        console.log(file.response.data)
        uploadList.push(file.response.data)
      } else {
        console.log(file.response.msg);
      }
    }
  },
  defaultFileList: uploadList,

}
class Company extends ApplyWithSendMsg {
  isShowDetail = false;
  constructor(props) {
    super(props);
    this.isShowDetail = props.isShowDetail || false;
    this.applyId = props.applyId;
    this.state = {
      isAnimating: true,
      region: null,
      isModalActive: false,
      applyStatus: APPLY_STATUS.false,
      modalBody: '',
      enterpriseStateList: [],
      modalFooter: [],
      industryList: [],
      patentTypeList: [],
      financingTypeList: [],
      subordinateTypeList: [],
      educationList: [],
      patentList: [],
      financingList: [],
      shareholderList: [],
      awardRecordList: [],
      businessLicensePic: [],
      bankAccountOpeningCertificatePic: [],
      shareholderStudyAbroadEducationProvePic: [],
      abroadImg: [],//海外背景学历证书
      shareholderStudyAbroadList: [],
      applyDetail: {
        id: 1,
        steps: [],
      },
      education: [],
      entrepreneurs: false,
      oneCardPassPic: [],
      overseasBg: [],
      picList: [[]],  //海外留学的图片列表
      patentPicList: [[]],  //双层的原因,外层用来保存大数组的对应的下标值
      financingPicList: [[]],
      awardRecordPicList: [[]],
      isAllowEdit: false,  //是否允许编辑

      activePatent: [],  //获取专利的数据库的id
      activeFinancingList: [],
      activeAwardRecordList: [],
      activeOverseasMemberList: [],

      activeShareholderList: []
    }
  }
  componentDidMount() {
    this.init();
  }

  init = async _ => {
    let region = await getRegion();
    let { data: { list: oriIndustryList } } = await companyApi.getIndustryList({ page: -1, limit: -1 })
    let industryList = [oriIndustryList.map(v => ({
      value: v.id,
      label: v.name,
    }))]
    let patentTypeList = [Object.values(PatentType).map(v => ({
      value: v,
      label: PatentTypeZh[v]
    }))]
    // 融资形式
    let financingTypeList = [Object.values(FinancingType).map(v => ({
      value: v,
      label: FinancingTypeZh[v]
    }))]
    // 所属等级
    let subordinateTypeList = [Object.values(SubordinateType).map(v => ({
      value: v,
      label: SubordinateTypeZh[v],
    }))]

    let educationList = [Object.values(EDUCATION).map(v => ({
      value: v,
      label: EDUCATION_ZH[v]
    }))]

    let education = [
      Object.values(EDUCATION).map((v, k) => ({
        value: v,
        label: EDUCATION_ZH[v],
      })),
    ];
    let grade = [],
      date = new Date().getFullYear(),
      gradeList = [];
    for (let i = 0; i <= date - 1990; i++) {
      gradeList.push({ value: 1990 + i, label: 1990 + i });
    }
    grade = [gradeList];
    this.setState({
      industryList,
      patentTypeList,
      financingTypeList,
      subordinateTypeList,
      educationList,
      education,
      grade,
    })
    if (this.isShowDetail) {
      const { setFieldsValue, } = this.props.form;
      try {
        let { data: { detail: applyDetail, } } = await applyApi.getApplyDetail({ id: this.applyId });
        let isAllowEdit = this.state.isAllowEdit;
        if (applyDetail.status === APPLY_STATUS['process']) {
          isAllowEdit = true;
        } else {
          isAllowEdit = false;
        }
        this.setState({
          isAllowEdit,
        })
        if (applyDetail.info.company_plan.length > 0) {
          for (let v of applyDetail.info.company_plan) {
            uploadList.push({
              uid: v.picId,
              name: v.name,
              url: BASE_URL + v.url
            })
          }
        } else {
          uploadList = []
        }
        let patentList = [], financingList = [], shareholderList = [],
          awardRecordList = [], overseasBg = [], activePatent = [], activeFinancingList = [],
          activeAwardRecordList = [], activeOverseasMemberList = [], activeShareholderList = [];
        for (let i = 0; i < applyDetail.patentList.length; i++) {
          patentList.push(i)
          activePatent.push({
            id: applyDetail.patentList[i].id
          })
          await new Promise(s => {
            let patentPicList = this.state.patentPicList
            if (applyDetail.patentList[i].file_id_list.length > 0) {
              patentPicList[i] = [{
                url: BASE_URL + applyDetail.patentList[i].file_id_list[0].url || '', picId: applyDetail.patentList[i].file_id_list[0].id
              }]
            }
            this.setState({
              patentList,
              patentPicList
            }, s)
          })
          setTimeout(_ => {
            setFieldsValue({
              [`patentName${i}`]: applyDetail.patentList[i].name,
              [`patentTime${i}`]: new Date(applyDetail.patentList[i].time),
              [`patentType${i}`]: [applyDetail.patentList[i].type],
            })
          }, 50)
        }
        // 融资
        for (let i = 0; i < applyDetail.financingRecordList.length; i++) {
          financingList.push(i)
          activeFinancingList.push({
            id: applyDetail.financingRecordList[i].id
          })
          await new Promise(s => {
            let financingPicList = this.state.financingPicList;
            financingPicList[i] = [{ url: BASE_URL + applyDetail.financingRecordList[i].file_id_list[0].url, picId: applyDetail.financingRecordList[i].file_id_list[0].id }]
            this.setState({
              financingList,
              financingPicList
            }, s)
          })
          setTimeout(_ => {
            setFieldsValue({
              [`financingMoney${i}`]: applyDetail.financingRecordList[i].money / 100,
              [`financingTime${i}`]: new Date(applyDetail.financingRecordList[i].time),
              [`financingSource${i}`]: applyDetail.financingRecordList[i].source,
              [`financingMode${i}`]: parseInt([applyDetail.financingRecordList[i].mode]),
            })
          }, 50)
        }
        //todo 获取获奖记录信息
        for (let i = 0; i < applyDetail.awardRecordList.length; i++) {
          awardRecordList.push(i)
          activeAwardRecordList.push({
            id: applyDetail.awardRecordList[i].id
          })
          await new Promise(s => {
            let awardRecordPicList = this.state.awardRecordPicList;
            awardRecordPicList[i] = [{
              url: BASE_URL + applyDetail.awardRecordList[i].file_id_list[0].url,
              picId: applyDetail.awardRecordList[i].file_id_list[0].id
            }]
            this.setState({
              awardRecordList,
              awardRecordPicList
            }, s)
          })
          setTimeout(_ => {
            setFieldsValue({
              [`awardName${i}`]: applyDetail.awardRecordList[i].name,
              [`awardObtainTime${i}`]: new Date(applyDetail.awardRecordList[i].obtain_time),
              [`subordinateLevel${i}`]: [parseInt(applyDetail.awardRecordList[i].belong_level)],
              [`awardLevel${i}`]: applyDetail.awardRecordList[i].prize_level,
              // [`organiser${i}`]: applyDetail.awardRecordList[i].organiser,
            })
          }, 50)
        }
        // 海外留学
        for (let i = 0; i < applyDetail.overseas_id_list.length; i++) {
          overseasBg.push(i)
          activeOverseasMemberList.push({
            id: applyDetail.overseas_id_list[i].id
          })
          await new Promise(s => {
            let picList = this.state.picList;
            picList[i] = [{ url: BASE_URL + applyDetail.overseas_id_list[i].degree_pic_url.url, picId: applyDetail.overseas_id_list[i].degree_pic_url.id }]
            this.setState({
              overseasBg,
            }, s)
          })
          // 修改前图片接收处理方式
          // await new Promise(s => {
          //   let picList = this.state.picList;
          //   picList[i] = [{ url: applyDetail.overseas_id_list[i].degree_pic_url }]
          //   this.setState({
          //     overseasBg,
          //   }, s)
          // })
          setTimeout(_ => {
            setFieldsValue({
              [`overseasName${i}`]: applyDetail.overseas_id_list[i].name,
              [`overseasType${i}`]: [applyDetail.overseas_id_list[i].college_id],
              [`overseasMajor${i}`]: [applyDetail.overseas_id_list[i].speciality],
              [`overseasEducation${i}`]: [applyDetail.overseas_id_list[i].education],
              [`graduationTime${i}`]: new Date(applyDetail.overseas_id_list[i].graduation_time),
            })
          })
        }
        // 股东
        for (let i = 0; i < applyDetail.shareholderList.length; i++) {
          shareholderList.push(i)
          activeShareholderList.push({
            id: applyDetail.shareholderList[i].id
          })
          await new Promise(s => {
            let shareholderStudyAbroadEducationProvePic = this.state.shareholderStudyAbroadEducationProvePic,
              shareholderStudyAbroadList = this.state.shareholderStudyAbroadList;
            if (applyDetail.shareholderList[i].studyAbroadInfo) {
              applyDetail.shareholderList[i].studyAbroadInfo.educationProvePic.url =
                BASE_URL + applyDetail.shareholderList[i].studyAbroadInfo.educationProvePic.url;
              shareholderStudyAbroadEducationProvePic[i] = [applyDetail.shareholderList[i].studyAbroadInfo.educationProvePic];
              shareholderStudyAbroadList[i] = [0];
            }
            console.log(shareholderStudyAbroadList[i]);
            this.setState({
              shareholderList,
              shareholderStudyAbroadEducationProvePic,
              shareholderStudyAbroadList,
            }, s)
          })

          setTimeout(_ => {

            setFieldsValue({
              [`shareholderName${i}`]: applyDetail.shareholderList[i].name,
              [`shareholderEducation${i}`]: [applyDetail.shareholderList[i].education],
              [`shareholderAddress${i}`]: [
                applyDetail.shareholderList[i].address.province_cid,
                applyDetail.shareholderList[i].address.city_cid,
                applyDetail.shareholderList[i].address.county_cid,
              ],
              [`shareholderAddressDetail${i}`]: applyDetail.shareholderList[i].address.detail,
              [`shareholderHoldingShares${i}`]: applyDetail.shareholderList[i].holder_shares,

              [`shareholderIdCardNo${i}`]: applyDetail.shareholderList[i].idCardNo,

            })
            if (applyDetail.shareholderList[i].studyAbroadInfo) {
              setFieldsValue({
                [`shareholderHasStudyAbroad${i}`]: applyDetail.shareholderList[i].studyAbroadInfo || null,
                [`shareholderStudyAbroadSchoolName${i}`]: applyDetail.shareholderList[i].studyAbroadInfo.school_name || null,
                [`shareholderStudyAbroadEducation${i}`]: [applyDetail.shareholderList[i].studyAbroadInfo.education] || null,
              })
            }
          }, 100)
        }
        let bankAccountOpeningCertificatePic = [{ url: BASE_URL + applyDetail.info.bankAccountOpeningCertificatePic.url, uid: applyDetail.info.bankAccountOpeningCertificatePic.id }],
          businessLicensePic = [{ url: BASE_URL + applyDetail.info.businessLicensePic.url, uid: applyDetail.info.businessLicensePic.id }];
        // 公司基本信息接收
        setFieldsValue({
          businessLicensePic,
          bankAccountOpeningCertificatePic,
          cname: applyDetail.info.name,
          totalNumber: applyDetail.info.totalNumber,
          teamMemberNumberHai: applyDetail.info.shanghaiNumber,
          industryId: [applyDetail.info.industryId],
          entrepreneurs: applyDetail.info.entrepreneurs,
          hasShareholder: shareholderList.length > 0,
          paySocialSecurityNum: applyDetail.info.name,//缴纳社保人数
          mainBusiness: applyDetail.info.mainBusiness,
          registerCapital: applyDetail.info.registerCapital / 100,
          registerTime: new Date(applyDetail.info.registerTime),
          registerAddress: [applyDetail.info.registerAddress.province, applyDetail.info.registerAddress.city,
          applyDetail.info.registerAddress.county],
          registerAddressDetail: applyDetail.info.registerAddress.detail,
        })
        this.setState({
          loading: false,
          applyDetail,
          industryList,
          patentTypeList,
          financingTypeList,
          subordinateTypeList,
          entrepreneurs: applyDetail.info.entrepreneurs,
          educationList,
          region,
          bankAccountOpeningCertificatePic,
          businessLicensePic,
          activePatent,
          activeFinancingList,
          activeOverseasMemberList,
          activeAwardRecordList,
          activeShareholderList
        })
      } catch (e) {
        console.log(e)
        message.error('获取申请详情失败,错误信息: ' + e.msg)
      }
    } else {
      this.setState({
        isAnimating: false,
        industryList,
        patentTypeList,
        financingTypeList,
        subordinateTypeList,
        educationList,
        region,
      })
    }
    const { setFieldsValue } = this.props.form;
    try {
      let { data: { detail, } } = await userApi.getMyIdRealNameDetail();
      setFieldsValue({
        personalName: detail.name,
        idCardNo: detail.idCardNo,
      })
    } catch (e) {
    }
    try {
      let { data: detail } = await userApi.getUserInfo();
      console.log(detail)
      setFieldsValue({
        phone: detail.phone,
        email: detail.email,
      });
    } catch (e) {
      console.log(e);
    }
  }
  apply = _ => {
    this.props.form.validateFields(async (err, fieldsValue) => {
      if (err) {
        let firstErrKey = Object.keys(err)[0]
        if (firstErrKey !== 'msg') {
          return Toast.fail(err[firstErrKey].errors[0].message, 2)
        }
      }
      let fields = this.props.form.getFieldsValue();
      if (!fields.businessLicensePic || fields.businessLicensePic.length === 0) {
        return Toast.fail("请上传营业执照图片", 1);
      }
      if (!fields.bankAccountOpeningCertificatePic || fields.bankAccountOpeningCertificatePic.length === 0) {
        return Toast.fail("请上传银行开户正面图片", 1);
      }
      let patentList = [], financingList = [], shareholderList = [], awardRecordList = [],
        overseasBg = [];
      if (this.state.isAllowEdit) {
        let { businessLicensePic, bankAccountOpeningCertificatePic } = this.state
        if (businessLicensePic[0].picId) {
          businessLicensePic = businessLicensePic[0].picId
        } else {
          businessLicensePic = businessLicensePic[0].uid
        }
        if (bankAccountOpeningCertificatePic[0].picId) {
          bankAccountOpeningCertificatePic = bankAccountOpeningCertificatePic[0].picId
        } else {
          bankAccountOpeningCertificatePic = bankAccountOpeningCertificatePic[0].uid
        }
        // 海外留学背景成员
        let picList = this.state.picList
        for (let i = 0; i < picList.length; i++) {
          if (picList[i][0].picId) {
            picList[i][0].uid = picList[i][0].picId.toString();
            delete picList[i][0].picId
          }
        }
        let {
          activePatent,  //获取专利的数据库的id
          activeFinancingList,
          activeAwardRecordList,
          activeOverseasMemberList,
          patentPicList,
          activeShareholderList,
        } = this.state;
        // let pic = '';
        for (let v of this.state.overseasBg) {
          if (fields['overseasName' + v]) {
            overseasBg.push({
              id: activeOverseasMemberList[v] ? activeOverseasMemberList[v].id : 0,
              name: fields['overseasName' + v],
              college: fields['overseasType' + v],
              speciality: fields['overseasMajor' + v],
              education: parseInt(fields['overseasEducation' + v]),
              time: moment(fields['graduationTime' + v]).format("YYYY-MM-DD HH:mm:ss"),
              // url: this.state.abroadImg[0],
              overseasMemberPic: picList[v],
              // url: pic
            })
          }
        }
        let patentPicIdList = [];
        for (let i = 0; i < patentPicList.length; i++) {
          if (patentPicList[i][0]) {
            if (patentPicList[i][0].picId) {
              patentPicIdList[i] = [patentPicList[i][0].picId]
            } else {
              patentPicIdList[i] = [patentPicList[i][0].id]
            }
          }
        }
        // 专利
        for (let v of this.state.patentList) {
          patentList.push({
            id: activePatent[v] ? activePatent[v].id : 0,
            name: fields['patentName' + v],
            time: moment(fields['patentTime' + v]).format("YYYY-MM-DD HH:mm:ss"),
            type: parseInt(fields['patentType' + v]),
            pic: patentPicIdList[v] || []
          })
        }

        // 融资
        let financingPicList = this.state.financingPicList
        let financingPicIdList = [];
        for (let i = 0; i < financingPicList.length; i++) {
          if (financingPicList[i][0]) {
            if (financingPicList[i][0].picId) {
              financingPicIdList[i] = [financingPicList[i][0].picId]
            } else {
              financingPicIdList[i] = [financingPicList[i][0].id]
            }
          }
        }
        for (let v of this.state.financingList) {
          financingList.push({
            id: activeFinancingList[v] ? activeFinancingList[v].id : 0,
            money: parseInt(fields['financingMoney' + v]) * 100,
            time: moment(fields['financingTime' + v]).format("YYYY-MM-DD HH:mm:ss"),
            source: fields['financingSource' + v],
            mode: parseInt(fields['financingMode' + v]),
            pic: financingPicIdList[v] || []
          })
        }
        //todo 提交获奖信息记录
        let awardRecordPicList = this.state.awardRecordPicList
        let awardRecordPicIdList = [];
        for (let i = 0; i < awardRecordPicList.length; i++) {
          if (awardRecordPicList[i][0]) {
            if (awardRecordPicList[i][0].picId) {
              awardRecordPicIdList[i] = [awardRecordPicList[i][0].picId]
            } else {
              awardRecordPicIdList[i] = [awardRecordPicList[i][0].id]
            }
          }
        }
        for (let v of this.state.awardRecordList) {
          awardRecordList.push({
            id: activeAwardRecordList[v] ? activeAwardRecordList[v].id : 0,
            name: fields['awardName' + v],
            time: moment(fields['awardObtainTime' + v]).format("YYYY-MM-DD HH:mm:ss"),
            // organiser: fields['organiser' + v],
            belong_level: parseInt(fields[`subordinateLevel` + v]),
            prize_level: fields[`awardLevel` + v],
            pic: awardRecordPicIdList[v] || []
          })
        }
        for (let v of this.state.shareholderList) {
          shareholderList.push({
            id: activeShareholderList[v] ? activeShareholderList[v].id : 0,
            name: fields['shareholderName' + v],
            education: fields['shareholderEducation' + v][0],
            hasStudyAbroad: fields['shareholderHasStudyAbroad' + v],
            //todo 海外留学信息
            studyAbroadInfo: fields['shareholderHasStudyAbroad' + v] ? {
              schoolName: fields["shareholderStudyAbroadSchoolName" + v],
              education: fields['shareholderStudyAbroadEducation' + v][0],
              educationProvePic: this.state.shareholderStudyAbroadEducationProvePic[v][0],
            } : null,
            idCardNo: fields['shareholderIdCardNo' + v],
            holderShares: fields['shareholderHoldingShares' + v],
            address: {
              province: fields['shareholderAddress' + v][0],
              city: fields['shareholderAddress' + v][1],
              county: fields['shareholderAddress' + v][2],
              detail: fields['shareholderAddressDetail' + v],
            },
          })
        }
        for (let i = 0; i < uploadList.length; i++) {
          if (uploadList[i].picId) {
            uploadList[i].uid = uploadList[i].picId.toString();
            delete uploadList[i].picId
          }
        }
        try {
          companyApi
            .edit({
              applyId: this.applyId,
              admin: false,
              file: uploadList,
              cname: fields.cname,
              totalNumber: parseInt(fields.totalNumber),
              teamMemberNumberHai: parseInt(fields.teamMemberNumberHai),
              industryId: fields.industryId[0],
              mainBusiness: fields.mainBusiness,  //企业简介
              registerTime: moment(fields.registerTime).format("YYYY-MM-DD 00:00:00"),
              paySocialSecurityNum: parseInt(fields.paySocialSecurityNum),
              registerAddress: fields.registerAddress,
              registerCapital: parseInt(fields.registerCapital) * 100,
              registerAddressDetail: fields.registerAddressDetail,
              entrepreneurs: this.state.entrepreneurs ? 1 : 0,
              businessLicensePic,
              bankAccountOpeningCertificatePic,
              patentList,
              financingList,
              prizeList: awardRecordList,
              overseasMemberList: overseasBg,
              shareholderList,
            })
            .then(() => {
              this.setState({
                isAllowEdit: false,
              })
              message.success("修改成功", 1);
            })
            .catch(err => {
              message.error("修改失败,失败原因: " + err.msg, 1);
              console.log(err);
            });
        } catch (e) {
          this.setState({
            isModalActive: true,
            modalBody: <div>修改失败,失败原因:{e.msg}</div>,
            modalFooter: [{
              text: '确定', onPress: () =>
                this.setState({ isModalActive: false })
            }],
          })
        }
      } else {

        // 海外留学背景成员
        let picList = this.state.picList
        // let pic = '';
        for (let v of this.state.overseasBg) {
          if (fields['overseasName' + v]) {
            overseasBg.push({
              name: fields['overseasName' + v],
              school_name: fields['overseasType' + v],
              major: fields['overseasMajor' + v],
              education: parseInt(fields['overseasEducation' + v]),
              time: moment(fields['graduationTime' + v]).format("YYYY-MM-DD HH:mm:ss"),
              // url: this.state.abroadImg[0],
              url: picList[v],
              // url: pic
            })
          }
        }

        let patentPicList = this.state.patentPicList;
        let patentPicIdList = [];
        for (let i = 0; i < patentPicList.length; i++) {
          if (patentPicList[i][0]) {
            if (patentPicList[i][0].picId) {
              patentPicIdList[i] = [patentPicList[i][0].picId]
            } else {
              patentPicIdList[i] = [patentPicList[i][0].id]
            }
          }
        }
        // 专利
        for (let v of this.state.patentList) {
          console.log(v)
          patentList.push({
            name: fields['patentName' + v],
            time: moment(fields['patentTime' + v]).format("YYYY-MM-DD HH:mm:ss"),
            type: parseInt(fields['patentType' + v]),
            pic: patentPicIdList[v] || []
          })
        }
        // 融资
        let financingPicList = this.state.financingPicList
        let financingPicIdList = [];
        for (let i = 0; i < financingPicList.length; i++) {
          if (financingPicList[i][0]) {
            if (financingPicList[i][0].picId) {
              financingPicIdList[i] = [financingPicList[i][0].picId]
            } else {
              financingPicIdList[i] = [financingPicList[i][0].id]
            }
          }
        }
        for (let v of this.state.financingList) {
          financingList.push({
            money: parseInt(fields['financingMoney' + v]) * 100,
            time: moment(fields['financingTime' + v]).format("YYYY-MM-DD HH:mm:ss"),
            source: fields['financingSource' + v],
            shape: parseInt(fields['financingMode' + v]),
            pic: financingPicIdList[v] || []
          })
        }
        //todo 提交获奖信息记录
        let awardRecordPicList = this.state.awardRecordPicList
        let awardRecordPicIdList = [];
        for (let i = 0; i < awardRecordPicList.length; i++) {
          if (awardRecordPicList[i][0]) {
            if (awardRecordPicList[i][0].picId) {
              awardRecordPicIdList[i] = [awardRecordPicList[i][0].picId]
            } else {
              awardRecordPicIdList[i] = [awardRecordPicList[i][0].id]
            }
          }
        }
        for (let v of this.state.awardRecordList) {
          awardRecordList.push({
            name: fields['awardName' + v],
            obtainTime: moment(fields['awardObtainTime' + v]).format("YYYY-MM-DD HH:mm:ss"),
            // organiser: fields['organiser' + v],
            subordinate: parseInt(fields[`subordinateLevel` + v]),
            awardLevel: fields[`awardLevel` + v],
            pic: awardRecordPicIdList[v] || []
          })
        }
        for (let v of this.state.shareholderList) {
          shareholderList.push({
            name: fields['shareholderName' + v],
            education: fields['shareholderEducation' + v][0],
            hasStudyAbroad: fields['shareholderHasStudyAbroad' + v],
            //todo 海外留学信息
            studyAbroadInfo: fields['shareholderHasStudyAbroad' + v] ? {
              schoolName: fields["shareholderStudyAbroadSchoolName" + v],
              education: fields['shareholderStudyAbroadEducation' + v][0],
              educationProvePic: this.state.shareholderStudyAbroadEducationProvePic[v][0],
            } : null,
            idCardNo: fields['shareholderIdCardNo' + v],
            holderShares: fields['shareholderHoldingShares' + v],
            address: {
              province: fields['shareholderAddress' + v][0],
              city: fields['shareholderAddress' + v][1],
              county: fields['shareholderAddress' + v][2],
              detail: fields['shareholderAddressDetail' + v],
            },
          })
        }
        try {
          await companyApi.addCompanyApply({
            file: uploadList,
            name: fields.cname,  //公司名称
            totalNumber: parseInt(fields.totalNumber),
            teamMemberNumberHai: parseInt(fields.teamMemberNumberHai),
            entrepreneurs: this.state.entrepreneurs ? 1 : 0,
            paySocialSecurityNum: parseInt(fields.paySocialSecurityNum),
            overseasBg,
            // 结束
            industryId: fields.industryId[0],
            mainBusiness: fields.mainBusiness,  //企业简介
            registerTime: moment(fields.registerTime).format("YYYY-MM-DD 00:00:00"),
            registerAddress: fields.registerAddress,
            registerCapital: parseInt(fields.registerCapital) * 100,
            registerAddressDetail: fields.registerAddressDetail,
            awardRecordList,
            patentList,
            financingList,
            shareholderList,

            businessLicensePic: this.state.businessLicensePic[0],
            bankAccountOpeningCertificatePic: this.state.bankAccountOpeningCertificatePic[0],
          })
          this.setState({
            isModalActive: true,
            modalBody: <div>提交成功,请等待审核</div>,
            modalFooter: [{ text: '确定', onPress: () => this.props.history.push('/') }],
          })
        } catch (e) {
          this.setState({
            isModalActive: true,
            modalBody: <div>提交失败,错误信息:{e.msg}</div>,
            modalFooter: [{
              text: '确定', onPress: () =>
                this.setState({ isModalActive: false })
            }],
          })
        }
      }
    });
  }
  resetForm = _ => {
    this.props.form.resetFields();
  }
  //添加专利
  addPatent = _ => {
    let fieldNames = [];
    for (let i = 0; i < this.state.patentList.length; i++) {
      fieldNames.push('patentName' + i, 'patentTime' + i, 'patentType' + i)
    }
    this.props.form.validateFields(fieldNames, (err, fieldsValue) => {
      if (err) {
        let firstErrKey = Object.keys(err)[0]
        return Toast.fail(err[firstErrKey].errors[0].message, 2)
      }
      let patentList = this.state.patentList;

      patentList.push(patentList[patentList.length - 1] + 1)
      let patentPicList = this.state.patentPicList;
      patentPicList.push([])
      this.setState({
        patentList,
        patentPicList
      })
    });
  }
  //添加融资
  addFinancing = _ => {
    let fieldNames = [];
    for (let i = 0; i < this.state.financingList.length; i++) {
      fieldNames.push('financingMoney' + i, 'financingTime' + i, 'financingSource' + i, 'financingMode' + i)
    }
    this.props.form.validateFields(fieldNames, (err, fieldsValue) => {
      if (err) {
        let firstErrKey = Object.keys(err)[0]
        return Toast.fail(err[firstErrKey].errors[0].message, 2)
      }
      let financingList = this.state.financingList;
      let financingPicList = this.state.financingPicList;
      financingPicList.push([])
      financingList.push(financingList[financingList.length - 1] + 1)
      this.setState({
        financingList,
        financingPicList
      })
    });
  }
  //添加获奖信息
  addawardRecord = _ => {
    let fieldNames = [];
    for (let i = 0; i < this.state.awardRecordList.length; i++) {
      fieldNames.push('awardName' + i, 'awardObtainTime' + i, 'subordinateLevel' + i, 'awardLevel' + i)
    }
    this.props.form.validateFields(fieldNames, (err, fieldsValue) => {
      if (err) {
        let firstErrKey = Object.keys(err)[0]
        return Toast.fail(err[firstErrKey].errors[0].message, 2)
      }
      let awardRecordList = this.state.awardRecordList;
      awardRecordList.push(awardRecordList[awardRecordList.length - 1] + 1)
      let awardRecordPicList = this.state.awardRecordPicList;
      awardRecordPicList.push([])
      this.setState({
        awardRecordList,
        awardRecordPicList
      })
    });
  }
  //添加股东
  addShareholder = _ => {
    let fieldNames = [];
    for (let i = 0; i < this.state.shareholderList.length; i++) {
      fieldNames.push('shareholderName' + i, 'shareholderEducation' + i, 'shareholderAddress' + i, 'shareholderAddressDetail' + i, 'shareholderHoldingShares' + i, 'shareholderStudyAbroadEducationProvePic' + i,
        'shareholderHasStudyAbroad' + i, 'shareholderIdCardNo' + i,
        'shareholderStudyAbroadSchoolName' + i, "shareholderStudyAbroadEducation" + i)
    }
    this.props.form.validateFields(fieldNames, (err, fieldsValue) => {
      if (err) {
        let firstErrKey = Object.keys(err)[0]
        return Toast.fail(err[firstErrKey].errors[0].message, 2)
      }
      for (let i = 0; i < this.state.shareholderStudyAbroadEducationProvePic.length; i++) {
        if (this.state.shareholderStudyAbroadEducationProvePic[i].length === 0) {
          return Toast.fail("请上传股东" + (i + 1) + "的留学证明图片", 2);
        }
      }
      let shareholderList = this.state.shareholderList;
      shareholderList.push(shareholderList[shareholderList.length - 1] + 1)
      this.setState({
        shareholderList,
      })
    });
  }
  //添加参保人
  // addinsuredPerson = _ => {
  //   let fieldNames = [];
  //   for (let i = 0; i < this.state.insuredPersonList.length; i++) {
  //     fieldNames.push('insuredPersonName' + i, 'insuredPersonTime' + i)
  //   }
  //   this.props.form.validateFields(fieldNames, (err, fieldsValue) => {
  //     if (err) {
  //       let firstErrKey = Object.keys(err)[0]
  //       return Toast.fail(err[firstErrKey].errors[0].message, 2)
  //     }
  //     let insuredPersonList = this.state.insuredPersonList;
  //     insuredPersonList.push(insuredPersonList[insuredPersonList.length - 1] + 1)
  //     this.setState({
  //       insuredPersonList,
  //     })
  //   });
  // }
  // 添加海外留学成员
  addOverseasBg = _ => {
    let fieldNames = [];
    for (let i = 0; i < this.state.overseasBg.length; i++) {
      fieldNames.push('overseasName' + i, 'overseasType' + i, "overseasMajor" + i, "overseasEducation" + i + "graduationTime" + i)
    }
    this.props.form.validateFields(fieldNames, (err, fieldsValue) => {
      console.log(err)
      if (err) {
        let firstErrKey = Object.keys(err)[0]
        return Toast.fail(err[firstErrKey].errors[0].message, 2)
      }
      // for (let i = 0; i < this.state.abroadImg.length; i++) {
      //   if (this.state.abroadImg[i].length === 0) {
      //     return Toast.fail("请上传成员" + (i + 1) + "的留学证明图片", 2);
      //   }
      // }
      let overseasBg = this.state.overseasBg;
      let picList = this.state.picList;
      overseasBg.push(overseasBg[overseasBg.length - 1] + 1)
      picList.push([])
      this.setState({
        overseasBg,
        picList,
      })
      console.log(overseasBg)
    });
  }


  //身份证验证
  IdentityCodeValid = async data => {
    let result = await IdentityCodeValid(data);
    if (!result.pass) {
      Toast.fail("身份证不正确" + result.msg, 2)
    }
  }
  getApplyDetailDom = _ => {
    const { getFieldDecorator, getFieldError, getFieldProps } = this.props.form;
    return <form className={style.form}>
      <List>
        {/* 基本信息 */}
        {getFieldDecorator('personalName',
          {
            rules: [{ required: true, message: '法人姓名' },],
          })(<InputItem clear className={style.input} placeholder="请输入" error={!!getFieldError('personalName')}
            extra={<Icon size="md" color="#ccc" type="right"></Icon>} >法人姓名</InputItem>
          )}
        {getFieldDecorator('idCardNo', {
          rules: [{ required: true, message: '请输入身份证号码' }],
        })(
          <InputItem clear className={style.input} placeholder="请输入"
            error={!!getFieldError("idCard")}
            extra={<Icon size='md' color="#ccc" type="right"></Icon>}
            onBlur={this.idCardNoValidation}>身份证号</InputItem>)}
        {getFieldDecorator('phone',
          {
            rules: [{ required: true, message: '请输入电话' },],
          })(<InputItem type="digit" clear className={style.input} placeholder="请输入" error={!!getFieldError('phone')}
            extra={<Icon size="md" color="#ccc" type="right"></Icon>} >联系电话</InputItem>
          )}
        {getFieldDecorator('email',
          {
            rules: [{ required: true, message: '请输入邮箱' },],
          })(<InputItem clear className={style.input} placeholder="请输入" error={!!getFieldError('email')}
            extra={<Icon size="md" color="#ccc" type="right"></Icon>} >邮箱</InputItem>
          )}
        {/* 修改结束 */}
        {/* 公司信息 */}

        {getFieldDecorator('cname',
          {
            rules: [{ required: true, message: '请输入企业名称' },],
          })(
            <InputItem clear className={style.input} placeholder="请输入" error={!!getFieldError('cname')}
              extra={<Icon size="md" color="#ccc" type="right"></Icon>} >企业名称</InputItem>
          )}
        {getFieldDecorator('totalNumber',
          {
            rules: [{ required: true, message: '请输入公司总人数' },],
          })(
            <InputItem clear className={style.input} placeholder="请输入" type="digit" error={!!getFieldError('totalNumber')}
              extra={<Icon size="md" color="#ccc" type="right"></Icon>} >公司总人数</InputItem>
          )}
        {getFieldDecorator('teamMemberNumberHai',
          {
            rules: [
              { required: true, message: '请输入团队中上海户籍人数' },
            ],
          })(
            <InputItem clear type="digit" className={style.input} placeholder="请输入"
              error={!!getFieldError('teamMemberNumberHai')} extra={<Icon size="md" color="#ccc" type="right"></Icon>}
            >上海户籍人数</InputItem>
          )}

        {getFieldDecorator('industryId',
          {
            rules: [{ required: true, message: '请选择所属行业', },],
          })(
            <Picker data={this.state.industryList} title="选择所属行业" cascade={false}>
              <List.Item arrow="horizontal">所属行业</List.Item>
            </Picker>
          )}
        {getFieldDecorator('registerTime',
          {
            rules: [{ required: true, message: '请选择注册时间', },],
          })(
            <DatePicker mode="date" title="注册时间" extra="请选择"
            >
              <List.Item arrow="horizontal">注册时间</List.Item>
            </DatePicker>
          )}
        {getFieldDecorator('registerAddress',
          {
            rules: [{ required: true, message: '请选择注册时间', },],
          })(
            <Picker data={this.state.region} extra={<div className={style.address}>请选择
         <Icon size="md" color="#ccc" type="right" ></Icon></div>}
            >
              <List.Item>注册地址</List.Item>
            </Picker>
          )}
        {getFieldDecorator('registerAddressDetail',
          {
            rules: [{ required: true, message: '请输入详细地址', },],
          })(
            <InputItem clear className={style.input} placeholder="请输入" error={!!getFieldError('registerAddressDetail')}
              extra={<Icon size="md" color="#ccc" type="right"></Icon>} >详细地址</InputItem>
          )}
        {getFieldDecorator('registerCapital',
          {
            rules: [{ required: true, message: '请输入注册资本', },],
          })(
            <InputItem type="digit" clear className={style.input} placeholder="请输入" error={!!getFieldError('registerCapital')}
              extra={<Icon size="md" color="#ccc" type="right"></Icon>} >注册资金(元)</InputItem>
          )}
        {getFieldDecorator('paySocialSecurityNum',
          {
            rules: [{},],
          })(
            <InputItem clear className={style.input} placeholder="请输入"
              error={!!getFieldError('paySocialSecurityNum')}
              extra={<Icon size="md" color="#ccc" type="right" ></Icon>} >缴纳社保人数</InputItem>
          )}
        {/* 选择咨询 */}
        {/* 是否为连续创业者 */}
        <Flex className={style.formItem}>
          <div className={style.title}>是否连续创业者</div>
          <Switch
            {...getFieldProps("entrepreneurs", {
              initialValue: this.state.entrepreneurs === true,
              valuePropName: "checked",
              onChange: checked => {
                this.setState({
                  entrepreneurs: checked,
                });
                console.log(checked)
              },
            })}
            className={style.detail}
          />
        </Flex>

        {/*股东 */}
        <Flex className={style.formItem}>
          <div className={style.title}>是否有股东</div>
          {getFieldDecorator('hasShareholder',
            {
              onChange: checked => {
                let shareholderList = [];
                if (checked) {
                  shareholderList.push(0)
                }
                this.setState({
                  shareholderList,
                })
              },
            })(
              <Switch checked={this.state.shareholderList.length > 0} className={style.detail} />
            )}
        </Flex>
        <div className={this.state.shareholderList.length > 0 ? "" : style.hidden}>
          {this.state.shareholderList.map((v, k) => {
            return (<div key={k}>
              <div className={style.theme}>股东 {k + 1}
                <AliIcon className={style.deleteIcon} type="icon-close-line" onClick={_ => {
                  let shareholderList = this.state.shareholderList;
                  shareholderList.splice(k, 1)
                  this.setState({
                    shareholderList,
                  })
                }}></AliIcon>
              </div>
              <div className={style.formItem}>
                {getFieldDecorator('shareholderName' + v,
                  {
                    rules: [{ required: true, message: `请输入股东${k + 1}的姓名`, },],
                  })(
                    <InputItem clear className={style.input} placeholder="请输入"
                      error={!!getFieldError('shareholderName' + v)}
                      extra={<Icon size="md" color="#ccc" type="right" ></Icon>} >股东姓名</InputItem>
                  )}
              </div>
              <div className={style.formItem}>
                {getFieldDecorator('shareholderEducation' + v,
                  {
                    rules: [{ required: true, message: `请选择第${k + 1}的学历`, },],
                  })(
                    <Picker data={this.state.educationList} title="请选择" cascade={false}>
                      <List.Item arrow="horizontal">学历</List.Item>
                    </Picker>
                  )}
              </div>
              <div className={style.formItem}>
                <Picker
                  {...getFieldProps('shareholderAddress' + v, {
                    rules: [{ required: true, message: `请选择第${k + 1}个户籍地址` },],
                  })}
                  data={this.state.region} extra={<div className={style.address}>请选择 <Icon size="md" color="#ccc" type="right" ></Icon></div>}
                >
                  <List.Item>户籍地址</List.Item>
                </Picker>
              </div>
              <div className={style.formItem}>
                {getFieldDecorator('shareholderAddressDetail' + v,
                  {})(
                    <InputItem clear className={style.input} placeholder="请输入" error={!!getFieldError('shareholderAddressDetail' + v)}
                      extra={<Icon size="md" color="#ccc" type="right" ></Icon>} >详细地址</InputItem>
                  )}
              </div>
              <div className={style.formItem}>
                {getFieldDecorator('shareholderHoldingShares' + v,
                  {})(
                    <InputItem clear className={style.input} placeholder="请输入" error={!!getFieldError('shareholderHoldingShares' + v)}
                      extra={<Icon size="md" color="#ccc" type="right" ></Icon>} >持有股份</InputItem>
                  )}
              </div>
              <div className={style.formItem}>
                {getFieldDecorator('shareholderIdCardNo' + v,
                  { rules: [{ required: true, message: `请输入股东${k + 1}的身份证号码`, },], })(
                    <InputItem className={style.input} placeholder="请输入" error={!!getFieldError('shareholderIdCardNo' + v)}
                      extra={<Icon size="md" color="#ccc" type="right" ></Icon>} onBlur={this.IdentityCodeValid}
                    >身份证号码</InputItem>
                  )}
              </div>
              <div className={style.formItem}>
                <Flex className={style.formItem}>
                  <div className={style.title}>是否海外留学(含港澳台)</div>
                  <Switch {...getFieldProps('shareholderHasStudyAbroad' + v,
                    {
                      initialValue: false, valuePropName: 'checked', onChange: checked => {
                        let shareholderStudyAbroadEducationProvePic =
                          this.state.shareholderStudyAbroadEducationProvePic,
                          shareholderStudyAbroadList = this.state.shareholderStudyAbroadList;
                        if (checked) {
                          shareholderStudyAbroadEducationProvePic[v] = [];
                          shareholderStudyAbroadList[v] = [0];
                        } else {
                          shareholderStudyAbroadList[v] = null;
                        }
                        console.log(shareholderStudyAbroadEducationProvePic, shareholderStudyAbroadList)
                        this.setState({
                          shareholderStudyAbroadEducationProvePic,
                          shareholderStudyAbroadList,
                        })
                      },
                    })} className={style.hasStudyAbroad}
                  // checked={this.state.shareholderStudyAbroadList[v] && this.state.shareholderStudyAbroadList[v].length > 0}
                  />
                </Flex>
                {this.state.shareholderStudyAbroadList[v] ?
                  <div className={style.studyAbroadList}>
                    <div className={style.formItem}>
                      {getFieldDecorator('shareholderStudyAbroadSchoolName' + v,
                        { rules: [{ required: true, message: `请输入股东${k + 1}的学校姓名`, },], })(
                          <InputItem className={style.input} placeholder="请输入"
                            error={!!getFieldError('shareholderStudyAbroadSchoolName' + v)}
                            extra={<Icon size="md" color="#ccc" type="right" ></Icon>}
                          >学校姓名</InputItem>
                        )}
                    </div>
                    <div className={style.formItem}>
                      {getFieldDecorator('shareholderStudyAbroadEducation' + v,
                        {
                          rules: [{ required: true, message: `请选择股东${k + 1}的留学学历`, },],
                        })(
                          <Picker data={this.state.educationList} title="请选择" cascade={false}>
                            <List.Item arrow="horizontal">学历</List.Item>
                          </Picker>
                        )}
                    </div>
                    <div className={style.formItem}>
                      <Item >
                        <div className={style.title}>学历证明图片</div>
                        {getFieldDecorator('shareholderStudyAbroadEducationProvePic' + v, {})(
                          <ImagePicker
                            files={this.state.shareholderStudyAbroadEducationProvePic[v]}
                            onChange={(files, type, index) => {
                              if (type === 'add') {
                                let formData = new FormData();
                                formData.append("name", 'file');
                                formData.append("file", files[0].file);
                                antdUploadImg(formData).then(json => {
                                  const { data: { name, picId, url, } } = json;
                                  let shareholderStudyAbroadEducationProvePic =
                                    this.state.shareholderStudyAbroadEducationProvePic;
                                  shareholderStudyAbroadEducationProvePic[v] = [{
                                    name, picId, url: BASE_URL + url,
                                  }];
                                  console.log(shareholderStudyAbroadEducationProvePic);
                                  this.setState({
                                    shareholderStudyAbroadEducationProvePic,
                                  });
                                }).catch(e => {
                                  message.error('上传图片失败', 1)
                                  console.log(e)
                                })
                              } else if (type === 'remove') {
                                let shareholderStudyAbroadEducationProvePic =
                                  this.state.shareholderStudyAbroadEducationProvePic;
                                shareholderStudyAbroadEducationProvePic[v] = [];
                                this.setState({
                                  shareholderStudyAbroadEducationProvePic,
                                });
                              }
                            }}
                            selectable={this.state.shareholderStudyAbroadEducationProvePic[v].length
                              < 1} multiple={this.state.multiple}
                            style={{ flex: 1, }}
                          />
                        )}
                      </Item>
                    </div>
                  </div> : null}
              </div>
            </div>)
          })}
          <div className={style.addPatent} onClick={this.addShareholder}>
            <AliIcon className={style.icon} type="icon-jia1"></AliIcon>
          </div>
        </div>
        {/* 参保人 */}
        {/* <Flex className={style.formItem}>
          <div className={style.title}>是否有参保人</div>
          {getFieldDecorator('hasInsuredPerson',
            {
              onChange: checked => {
                let insuredPersonList = [];
                if (checked) {
                  insuredPersonList.push(0)
                }
                this.setState({
                  insuredPersonList,
                })
              },
            })(
              <Switch checked={this.state.insuredPersonList.length > 0} className={style.detail} />
            )}
        </Flex>
        <div className={this.state.insuredPersonList.length > 0 ? "" : style.hidden}>
          {this.state.insuredPersonList.map((v, k) => {
            return (<div key={k}>
              <div className={style.theme}>参保人 {k + 1}
                <AliIcon className={style.deleteIcon} type="icon-close-line" onClick={_ => {
                  let insuredPersonList = this.state.insuredPersonList;
                  insuredPersonList.splice(k, 1)
                  this.setState({
                    insuredPersonList,
                  })
                }}></AliIcon>
              </div>
              <div className={style.formItem}>
                {getFieldDecorator('insuredPersonName' + v,
                  {
                    rules: [{ required: true, message: `请输入参保人${k + 1}名字`, },],
                  })(
                    <InputItem clear className={style.input} placeholder="请输入" error={!!getFieldError('insuredPersonName' + v)}
                      extra={<Icon size="md" color="#ccc" type="right" ></Icon>} >参保人姓名</InputItem>
                  )}
              </div>
              <div className={style.formItem}>
                <DatePicker
                  {...getFieldProps('insuredPersonTime' + v, {
                    rules: [{ required: true, message: `请选择第${k + 1}个参保人开始缴纳时间` },],
                  })} mode="date" extra="请选择"  >
                  <List.Item arrow="horizontal">开始缴纳时间</List.Item>
                </DatePicker>
              </div>
            </div>)
          })}
          <div className={style.addPatent} onClick={this.addinsuredPerson}>
            <AliIcon className={style.icon} type="icon-jia1"></AliIcon>
          </div>
        </div> */}
        {/* 专利 */}
        <Flex className={style.formItem}>
          <div className={style.title}>是否有专利</div>
          {getFieldDecorator('hasPatent',
            {
              onChange: checked => {
                let patentList = [];
                if (checked) {
                  patentList.push(0)
                }
                this.setState({
                  patentList,
                })
              },
            })(
              <Switch checked={this.state.patentList.length > 0} className={style.detail} />
            )}
        </Flex>
        <div className={this.state.patentList.length > 0 ? "" : style.hidden}>
          {this.state.patentList.map((v, k) => {
            return (<div key={k}>
              <div className={style.theme}>专利 {k + 1}
                <AliIcon className={style.deleteIcon} type="icon-close-line" onClick={_ => {
                  let patentList = this.state.patentList;
                  patentList.splice(k, 1)
                  this.setState({
                    patentList,
                  })
                }}></AliIcon>
              </div>
              <div className={style.formItem}>
                {getFieldDecorator('patentName' + v,
                  {
                    rules: [{ required: true, message: `请输入专利${k + 1}的名称`, },],
                  })(
                    <InputItem clear className={style.input} placeholder="请输入" error={!!getFieldError('patentName' + v)}
                      extra={<Icon size="md" color="#ccc" type="right" ></Icon>} >专利名称</InputItem>
                  )}
              </div>
              <div className={style.formItem}>
                <DatePicker
                  {...getFieldProps('patentTime' + v, {
                    rules: [{ required: true, message: `请选择专利 ${k + 1} 获得时间` },],
                  })} mode="date" extra="请选择" >
                  <List.Item arrow="horizontal">获得时间</List.Item>
                </DatePicker>
              </div>
              <div className={style.formItem}>
                <Picker
                  {...getFieldProps('patentType' + v, {
                    rules: [{ required: true, message: `请选择第${k + 1}个专利类型` },],
                  })} data={this.state.patentTypeList} cascade={false} placeholder="请选择"  >
                  <List.Item arrow="horizontal">专利类型</List.Item>
                </Picker>
              </div>
              <div className={style.formItem}>
                <div className={style.diploma}>请上传专利证书</div>
                {getFieldDecorator("patentPicList" + v, {})(
                  <ImagePicker
                    className={style.diplomaPic}
                    files={this.state.patentPicList[v]}
                    onChange={(files, type, index) => {
                      if (type === "add") {
                        let formData = new FormData();
                        formData.append("name", "file")
                        formData.append("file", files[0].file);
                        antdUploadImg(formData).then(json => {
                          const { data: { name, picId, url, } } = json;
                          let patentPicList = this.state.patentPicList
                          patentPicList[v] = [{ name, picId, url: BASE_URL + url }]
                          this.setState({
                            patentPicList
                          })
                          console.log(patentPicList)
                        }).catch(e => {
                          message.error("上传失败", 1)
                          console.log(e)
                        })
                      } else if (type === "remove") {
                        let patentPicList = this.state.patentPicList
                        patentPicList[v] = []
                        this.setState({
                          patentPicList,
                        })
                      }
                    }}
                    selectable={this.state.patentPicList[v].length < 1}
                    multiple={this.state.multiple} style={{ flex: 1 }}
                  ></ImagePicker>
                )}
              </div>
            </div>)
          })}
          <div className={style.addPatent} onClick={this.addPatent}>
            <AliIcon className={style.icon} type="icon-jia1"></AliIcon>
          </div>
        </div>
        {/* 融资信息 */}
        <Flex className={style.formItem}>
          <div className={style.title}>是否融资</div>
          {getFieldDecorator('hasFinancing',
            {
              onChange: checked => {
                let financingList = [];
                if (checked) {
                  financingList.push(0)
                }
                this.setState({
                  financingList,
                })
              },
            })(
              <Switch checked={this.state.financingList.length > 0} className={style.detail} />
            )}
        </Flex>
        <div className={this.state.financingList.length > 0 ? "" : style.hidden}>
          {this.state.financingList.map((v, k) => {
            return (<div key={k}>
              <div className={style.theme}>融资 {k + 1}
                <AliIcon className={style.deleteIcon} type="icon-close-line" onClick={_ => {
                  let financingList = this.state.financingList;
                  financingList.splice(k, 1)
                  this.setState({
                    financingList,
                  })
                }}></AliIcon>
              </div>
              <div className={style.formItem}>
                {getFieldDecorator('financingMoney' + v,
                  {
                    rules: [{ required: true, message: `请输入融资 ${k + 1} 金额`, },],
                  })(
                    <InputItem clear className={style.input} placeholder="请输入" error={!!getFieldError('financingMoney' + v)}
                      extra={<Icon size="md" color="#ccc" type="right" ></Icon>} type="digit"
                    >融资金额(元)</InputItem>
                  )}
              </div>
              <div className={style.formItem}>
                {/* {getFieldDecorator('financingMode' + v,
                  {
                    rules: [{ required: true, message: `请输入融资${k + 1}形式`, },],
                  })(
                    <InputItem clear className={style.input} placeholder="请输入"
                      error={!!getFieldError('financingMode' + v)}
                      extra={<Icon size="md" color="#ccc" type="right" ></Icon>}>融资形式</InputItem>
                  )} */}
                <Picker
                  {...getFieldProps('financingMode' + v, {
                    rules: [{ required: true, message: `请选择第${k + 1}个融资形式` },],
                  })} data={this.state.financingTypeList} cascade={false} placeholder="请选择"  >
                  <List.Item arrow="horizontal">融资形式</List.Item>
                </Picker>
              </div>
              <div className={style.formItem}>
                <DatePicker
                  {...getFieldProps('financingTime' + v, {
                    rules: [{ required: true, message: `请选择融资 ${k + 1} 的融资时间` },],
                  })} mode="date" extra="请选择" >
                  <List.Item arrow="horizontal">融资时间</List.Item>
                </DatePicker>
              </div>
              <div className={style.formItem}>
                {getFieldDecorator('financingSource' + v,
                  {
                    rules: [{ required: true, message: `请输入融资 ${k + 1} 来源`, },],
                  })(
                    <InputItem clear className={style.input} placeholder="请输入" error={!!getFieldError('financingSource' + v)}
                      extra={<Icon size="md" color="#ccc" type="right" ></Icon>} >融资来源</InputItem>
                  )}
              </div>
              <div className={style.formItem}>
                <div className={style.diploma}>请上传融资证明</div>
                {getFieldDecorator("financingPicList" + v, {})(
                  <ImagePicker
                    className={style.diplomaPic}
                    files={this.state.financingPicList[v]}
                    onChange={(files, type, index) => {
                      if (type === "add") {
                        let formData = new FormData();
                        formData.append("name", "file")
                        formData.append("file", files[0].file);
                        antdUploadImg(formData).then(json => {
                          const { data: { name, picId, url, } } = json;
                          let financingPicList = this.state.financingPicList
                          financingPicList[v] = [{ name, picId, url: BASE_URL + url }]
                          this.setState({
                            financingPicList
                          })
                          console.log(financingPicList)
                        }).catch(e => {
                          message.error("上传失败", 1)
                          console.log(e)
                        })
                      } else if (type === "remove") {
                        let financingPicList = this.state.financingPicList
                        financingPicList[v] = []
                        this.setState({
                          financingPicList,
                        })
                      }
                    }}
                    selectable={this.state.financingPicList[v].length < 1}
                    multiple={this.state.multiple} style={{ flex: 1 }}
                  ></ImagePicker>
                )}
              </div>
            </div>)
          })}
          <div className={style.addPatent} onClick={this.addFinancing}>
            <AliIcon className={style.icon} type="icon-jia1"></AliIcon>
          </div>
        </div>
        {/* 获奖信息 */}
        <Flex className={style.formItem}>
          <div className={style.title}>是否获奖记录</div>
          {getFieldDecorator('hasAward',
            {
              onChange: checked => {
                let awardRecordList = [];
                if (checked) {
                  awardRecordList.push(0)
                }
                this.setState({
                  awardRecordList,
                })
              },
            })(
              <Switch checked={this.state.awardRecordList.length > 0} className={style.detail} />
            )}
        </Flex>
        <div className={this.state.awardRecordList.length > 0 ? "" : style.hidden}>
          {this.state.awardRecordList.map((v, k) => {
            return (<div key={k}>
              <div className={style.theme}>获奖记录 {k + 1}
                <AliIcon className={style.deleteIcon} type="icon-close-line" onClick={_ => {
                  let awardRecordList = this.state.awardRecordList;
                  awardRecordList.splice(k, 1)
                  this.setState({
                    awardRecordList,
                  })
                }}></AliIcon>
              </div>
              <div className={style.formItem}>
                {getFieldDecorator('awardName' + v,
                  {
                    rules: [{ required: true, message: `请输入获奖记录 ${k + 1} 的获奖名字`, },],
                  })(
                    <InputItem clear className={style.input} placeholder="请输入" error={!!getFieldError('awardName' + v)}
                      extra={<Icon size="md" color="#ccc" type="right" ></Icon>}
                    >获奖名字</InputItem>
                  )}
              </div>
              <div className={style.formItem}>
                <DatePicker
                  {...getFieldProps('awardObtainTime' + v, {
                    rules: [{ required: true, message: `请选择获奖记录 ${k + 1} 的获奖时间` },],
                  })} mode="date" extra="请选择" >
                  <List.Item arrow="horizontal">获奖时间</List.Item>
                </DatePicker>
              </div>
              <div className={style.formItem}>
                {/* {
                  getFieldDecorator("subordinateLevel" + v, {
                    rules: [{ required: true, message: `请输入获奖记录${k + 1}奖项的所属级别` }]
                  })(
                    <InputItem clear className={style.input} placeholder="请输入"
                      error={!!getFieldError("subordinateLevel" + v)}
                      extra={<Icon size="md" color="#ccc" type="right"></Icon>}
                    >所属级别</InputItem>
                  )
                } */}
                <Picker
                  {...getFieldProps('subordinateLevel' + v, {
                    rules: [{ required: true, message: `请选择第${k + 1}奖项的所属级别` },],
                  })} data={this.state.subordinateTypeList} cascade={false} placeholder="请选择"  >
                  <List.Item arrow="horizontal">所属级别</List.Item>
                </Picker>
              </div>
              <div className={style.formItem}>
                {getFieldDecorator("awardLevel" + v, {
                  rules: [{ required: true, message: `请输入获奖记录${k + 1}奖项的级别` }]
                })(<InputItem className={style.input} clear placeholder="请输入"
                  error={!!getFieldError("awardLevel" + v)}
                  extra={<Icon type="right" color="#ccc" size="md"></Icon>}
                >奖项级别</InputItem>)}
              </div>
              <div className={style.formItem}>
                <div className={style.diploma}>请上传获奖证书</div>
                {getFieldDecorator("awardRecordPicList" + v, {})(
                  <ImagePicker
                    className={style.diplomaPic}
                    files={this.state.awardRecordPicList[v]}
                    onChange={(files, type, index) => {
                      if (type === "add") {
                        let formData = new FormData();
                        formData.append("name", "file")
                        formData.append("file", files[0].file);
                        antdUploadImg(formData).then(json => {
                          const { data: { name, picId, url, } } = json;
                          let awardRecordPicList = this.state.awardRecordPicList
                          awardRecordPicList[v] = [{ name, picId, url: BASE_URL + url }]
                          this.setState({
                            awardRecordPicList
                          })
                          console.log(awardRecordPicList)
                        }).catch(e => {
                          message.error("上传失败", 1)
                          console.log(e)
                        })
                      } else if (type === "remove") {
                        let awardRecordPicList = this.state.awardRecordPicList
                        awardRecordPicList[v] = []
                        this.setState({
                          awardRecordPicList,
                        })
                      }
                    }}
                    selectable={this.state.awardRecordPicList[v].length < 1}
                    multiple={this.state.multiple} style={{ flex: 1 }}
                  ></ImagePicker>
                )}
              </div>
              {/* <div className={style.formItem}>
                {getFieldDecorator('organiser' + v,
                  {
                    rules: [{ required: true, message: `请输入获奖记录 ${k + 1} 的主办方名字`, },],
                  })(
                    <InputItem clear className={style.input} placeholder="请输入" error={!!getFieldError('organiser' + v)}
                      extra={<Icon size="md" color="#ccc" type="right" ></Icon>} >主办方名字</InputItem>
                  )}
              </div> */}
            </div>)
          })}
          <div className={style.addPatent} onClick={this.addawardRecord}>
            <AliIcon className={style.icon} type="icon-jia1"></AliIcon>
          </div>
        </div>
        {/* 修改开始 */}
        {/*是否有海外留学背景成员 */}
        <Flex className={style.formItem}>
          <div className={style.title}>是否有海外留学背景成员</div>
          {getFieldDecorator('hasOverseasBg',
            {
              onChange: checked => {
                let overseasBg = [];
                if (checked) {
                  overseasBg.push(0)
                }
                this.setState({
                  overseasBg,
                })
              },
            })(
              <Switch checked={this.state.overseasBg.length > 0} className={style.detail} />
            )}
        </Flex>
        {/*是否有海外留学背景成员 */}
        <div className={this.state.overseasBg.length > 0 ? "" : style.hidden}>
          {this.state.overseasBg.map((v, k) => {
            return (<div key={k}>
              <div className={style.theme}>有海外留学背景成员 {k + 1}
                <AliIcon className={style.deleteIcon} type="icon-icon_close" onClick={_ => {
                  let overseasBg = this.state.overseasBg;
                  overseasBg.splice(k, 1)
                  this.setState({
                    overseasBg,
                  })
                  console.log(overseasBg)
                }}></AliIcon>
              </div>

              <div className={style.item}>
                {getFieldDecorator('overseasName' + v,
                  {
                    rules: [{ required: true, message: `请输入海外留学背景成员${k + 1}的名字`, },],
                  })(
                    <InputItem clear className={style.input} placeholder="请输入"
                      error={!!getFieldError('overseasName' + v)} extra={<Icon size="md" color="#ccc" type="right" ></Icon>}
                    >成员名字</InputItem>
                  )}
              </div>
              <div className={style.item}>
                {getFieldDecorator('overseasType' + v,
                  {
                    rules: [{ required: true, message: `请输入海外留学背景成员${k + 1}的毕业院校`, },],
                  })(
                    <InputItem clear className={style.input} placeholder="请输入"
                      error={!!getFieldError('overseasType' + v)} extra={<Icon size="md" color="#ccc" type="right" ></Icon>}
                    >毕业学校</InputItem>
                  )}
              </div>
              <div className={style.item}>
                {getFieldDecorator('overseasMajor' + v,
                  {
                    rules: [{ required: true, message: `请输入海外留学背景成员${k + 1}的毕业专业`, },],
                  })(
                    <InputItem clear className={style.input} placeholder="请输入"
                      error={!!getFieldError('overseasMajor' + v)} extra={<Icon size="md" color="#ccc" type="right" ></Icon>}
                    >毕业专业</InputItem>
                  )}
              </div>

              <div className={style.item}>
                <Picker
                  {...getFieldProps('overseasEducation' + v, {
                    rules: [{ required: true, message: `请输入海外留学背景成员${k + 1}的学历` },],
                  })}
                  data={this.state.education} cascade={false} placeholder="选择学历" >
                  <List.Item arrow="horizontal">学历</List.Item>
                </Picker>
              </div>
              <div className={style.item}>
                <DatePicker
                  {...getFieldProps('graduationTime' + v, {
                    rules: [{ required: true, message: `请选择海外留学背景成员 ${k + 1}毕业时间` },],
                  })} mode="date" extra="请选择" >
                  <List.Item arrow="horizontal">毕业时间</List.Item>
                </DatePicker>
              </div>
              <div className={style.diploma}>请上传毕业证学位证</div>
              {getFieldDecorator('picList' + v, {})(
                <ImagePicker
                  className={style.diplomaPic}
                  files={this.state.picList[v]}
                  onChange={(files, type, index) => {
                    if (type === 'add') {
                      let formData = new FormData();
                      formData.append("name", 'file');
                      formData.append("file", files[0].file);
                      antdUploadImg(formData).then(json => {
                        const { data: { name, picId, url, } } = json;
                        let picList = this.state.picList
                        picList[v] = [{
                          name, picId, url: BASE_URL + url,
                        }]
                        this.setState({
                          picList,
                        });
                        console.log(picList)
                      }).catch(e => {
                        message.error('上传失败', 1)
                        console.log(e)
                      })
                    } else if (type === 'remove') {
                      let picList = this.state.picList
                      picList[v] = []
                      this.setState({
                        picList,
                      });
                    }
                  }}
                  selectable={this.state.picList[v].length < 1} multiple={this.state.multiple} style={{ flex: 1, }}
                />
              )}
            </div>)
          })}
          <div className={style.addPatent} onClick={this.addOverseasBg}>
            <AliIcon className={style.icon} type="icon-jia1"></AliIcon>
          </div>
        </div>
        {/* 上传附件 */}
        <Item>
          <div className={style.tips}>提示:如果有法人简历、社保缴纳证明等,请上传对应的附件</div>
          <Upload {...upload}>
            <Button className={style.uploadBtn} >
              上传
          </Button>
          </Upload>
        </Item>
        {/* 修改结束 */}
        {/* 上传图片 */}
        <Item >
          <div className={style.title}>营业执照图片</div>
          {getFieldDecorator('businessLicensePic', {})(
            <ImagePicker
              files={this.state.businessLicensePic}
              onChange={(files, type, index) => {
                if (type === 'add') {
                  let formData = new FormData();
                  formData.append("name", 'file');
                  formData.append("file", files[0].file);
                  antdUploadImg(formData).then(json => {
                    const { data: { name, picId, url, } } = json;
                    this.setState({
                      businessLicensePic: [{
                        name, picId, url: BASE_URL + url,
                      }],
                    });
                  }).catch(e => {
                    message.error('上传图片失败', 1)
                    console.log(e)
                  })
                } else if (type === 'remove') {
                  this.setState({
                    businessLicensePic: [],
                  });
                }
              }}
              selectable={this.state.businessLicensePic.length < 1} multiple={this.state.multiple} style={{ flex: 1, }}
            />
          )}
        </Item>
        <Item >
          <div className={style.title}>银行开户正面图片</div>
          {getFieldDecorator('bankAccountOpeningCertificatePic', {})(
            <ImagePicker
              files={this.state.bankAccountOpeningCertificatePic}
              onChange={(files, type, index) => {
                if (type === 'add') {
                  let formData = new FormData();
                  formData.append("name", 'file');
                  formData.append("file", files[0].file);
                  antdUploadImg(formData).then(json => {
                    const { data: { name, picId, url, } } = json;
                    this.setState({
                      bankAccountOpeningCertificatePic: [{
                        name, picId, url: BASE_URL + url,
                      }],
                    });
                  }).catch(e => {
                    message.error('上传图片失败', 1)
                    console.log(e)
                  })
                } else if (type === 'remove') {
                  this.setState({
                    bankAccountOpeningCertificatePic: [],
                  });
                }
              }}
              selectable={this.state.bankAccountOpeningCertificatePic.length < 1} multiple={this.state.multiple}
              style={{ flex: 1, }}
            />
          )}
        </Item>
        <Item>
          <div>企业简介</div>
          <TextareaItem
            {...getFieldProps('mainBusiness', {
              rules: [{ required: true, message: '企业简介及主营业务（200字以内）', },],
            })} rows={3} count={100} placeholder="企业简介及主营业务（200字以内）"
          />
        </Item>
      </List>
      {
        this.state.isAllowEdit ? (
          <div className={style.operation}>
            <Button size="small" type="primary" className={style.btn} inline onClick={this.apply}>修改提交</Button>
          </div>
        ) : ''
      }
      {this.getChatInfo()}
    </form>
  }
  render() {
    return (this.isShowDetail ? this.getApplyDetailDom() :
      <div className={style.company}>
        <Modal
          visible={this.state.isModalActive}
          transparent
          maskClosable={false}
          title="提示"
          footer={this.state.modalFooter}
        >
          {this.state.modalBody}
        </Modal>
        <ActivityIndicator animating={this.state.isAnimating} className={style.loading} text="loading..." />
        <NavBar mode="light" icon={<Icon type="left" />} onLeftClick={_ => { this.props.history.goBack() }}>公司申请</NavBar>
        {this.getApplyDetailDom()}
        <div className={style.operation}>
          <Button size="small" className={style.btn} inline onClick={this.resetForm}>重置</Button>
          <Button size="small" type="primary" className={style.btn} inline onClick={this.apply}>提交</Button>
        </div>
      </div>
    );
  }
}
export default withRouter(Form.create()(Company));
