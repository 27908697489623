import React, { Component } from "react";
import { ActivityIndicator, NavBar, Icon, Modal } from "antd-mobile";
import { Form } from "antd";
import qs from "qs";
import { withRouter } from "react-router-dom";
import CompanyEnter from "./CompanyEnter";
import CreateProject from "./CreateProject";
import CoffeeHouseRent from "./CoffeeHouseRent";
import ConferenceRoomRent from "./ConferenceRoomRent";
import MealCard from "./MealCard";
import ParkingPermit from "./ParkingPermit";
import IdCardRealName from "../auth/IdCardRealName";
import MergeInfo from "../auth/MergeInfo";
import Recruit from "./Recruit";
import SchoolInfo from "../auth/SchoolInfo";
import essentialInformationAuth from "../auth/MergeInfo";
import gStyle from "../../../../utils/sass";
import { TYPE as APPLY_TYPE } from "../../../../services/apply";
import { FormComponentProps } from "antd/lib/form";
import { Action } from "antd-mobile/lib/modal/PropsType";
import { RouteComponentProps } from "react-router";
import app from "../../../../redux/constants/app";

const style = gStyle.index.user.apply.companyEnter;
const typeMapComponent = {
  [APPLY_TYPE["companyEnter"]]: CompanyEnter,
  [APPLY_TYPE["createProject"]]: CreateProject,
  [APPLY_TYPE["coffeeHouseRent"]]: CoffeeHouseRent,
  [APPLY_TYPE["conferenceRoomRent"]]: ConferenceRoomRent,
  [APPLY_TYPE["mealCard"]]: MealCard,
  [APPLY_TYPE["parkingPermit"]]: ParkingPermit,
  [APPLY_TYPE["realNameAuth"]]: IdCardRealName,
  [APPLY_TYPE["recruit"]]: Recruit,
  [APPLY_TYPE["schoolInfoAuth"]]: SchoolInfo,
  [APPLY_TYPE["essentialInformationAuth"]]: essentialInformationAuth
};
const typeMapApplyName = {
  [APPLY_TYPE["companyEnter"]]: "公司入驻",
  [APPLY_TYPE["createProject"]]: "项目入驻",
  [APPLY_TYPE["coffeeHouseRent"]]: "咖啡屋",
  [APPLY_TYPE["conferenceRoomRent"]]: "会议室租用",
  [APPLY_TYPE["mealCard"]]: "饭卡",
  [APPLY_TYPE["parkingPermit"]]: "停车证",
  [APPLY_TYPE["realNameAuth"]]: "实名认证",
  [APPLY_TYPE["recruit"]]: "招聘",
  [APPLY_TYPE["schoolInfoAuth"]]: "校园信息认证",
  [APPLY_TYPE["essentialInformationAuth"]]: "基本信息认证"
};

export interface Props extends FormComponentProps, RouteComponentProps {}
export interface State {
  isAnimating: boolean;
  title: string;
  isModalActive: boolean;
  modalFooter: Action<any>[];
  modalBody: React.ReactNode;
}

class Detail extends Component<Props, State> {
  applyType = 1;
  applyId = 1;
  constructor(props: Props) {
    super(props);
    const {
      location: { search },
    } = props;
    let { type: applyType, id: applyId } = qs.parse(search, {
      ignoreQueryPrefix: true,
    });
    this.applyType = applyType;
    this.applyId = applyId;
    this.state = {
      isAnimating: false,
      isModalActive: false,
      title: "公司入驻申请详情",
      modalBody: "",
      modalFooter: [],
    };
  }
  componentDidMount() {
    this.init();
  }

  init = async () => {};
  apply = () => {};
  resetForm = () => {
    this.props.form.resetFields();
  };
  render() {
    const ApplyComponent = typeMapComponent[this.applyType];
    return (
      <div className={style.company}>
        <Modal
          visible={this.state.isModalActive}
          transparent
          maskClosable={false}
          title="提示"
          footer={this.state.modalFooter}
        >
          {this.state.modalBody}
        </Modal>
        <ActivityIndicator
          className={style.loading}
          animating={this.state.isAnimating}
          text="loading..."
        />
        <NavBar
          mode="light"
          icon={<Icon type="left" />}
          onLeftClick={() => {
            this.props.history.goBack();
          }}
        >
          {typeMapApplyName[this.applyType] + "-申请详情"}
        </NavBar>
        <div className="applyDetail">
          {<ApplyComponent isShowDetail={true} applyId={this.applyId} />}
        </div>
      </div>
    );
  }
}
// @ts-ignore
export default withRouter(Form.create()(Detail));
