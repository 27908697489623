import React, { Component } from "react";
import {
  Form, Steps, Input, Icon, Button, message,
} from 'antd';
import gImg from "../../../../utils/img";
import userApi, { HAS_STUDY_ABROAD, } from "../../../../services/user";
import gSass from '../../../../utils/sass';

const style = gSass.index.user.common.forgetPassword;

const Step = Steps.Step;
class Login extends Component {
  formItemLayout = {
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 5, offset: 10, },
    },
  };
  steps = [{
    title: '填写账号',
    status: "",
  }, {
    title: '设置新密码',
    status: "",
  }, {
    title: '完成',
    status: "success",
  }];
  constructor(props) {
    super(props);
    this.state = {
      verificationCode: "发送验正码",
      canSendVerifityCode: false,
      current: HAS_STUDY_ABROAD.UNDEFINED,
      phone: "",
    }
  }
  //下一步
  nextSubmit = evt => {
    evt.preventDefault();
    let fieldNames = [];
    if (this.state.current === HAS_STUDY_ABROAD.UNDEFINED) {
      fieldNames = ["userName", "verificationCode", "phone"];
    } else if (this.state.current === HAS_STUDY_ABROAD.TRUE) {
      fieldNames = ["password"];
    }
    this.props.form.validateFields(fieldNames, (err, fieldsValue) => {
      if (err) {
        return;
      }
      if (this.state.current === HAS_STUDY_ABROAD.UNDEFINED) {
        let body = {
          userName: fieldsValue.userName,
          phone: fieldsValue.phone,
          verificationCode: fieldsValue.verificationCode,
        }
        console.log(body);
        userApi.checkOldPwd({
          userName: fieldsValue.userName,
          phone: fieldsValue.phone,
          verificationCode: fieldsValue.verificationCode,
        }).then(json => {
          this.setState({
            current: HAS_STUDY_ABROAD.TRUE,
          })
        }).catch(err => {
          console.log(err);
        })

      } else if (this.state.current === HAS_STUDY_ABROAD.TRUE) {
        let body = {
          pwd: fieldsValue.password,
        }
        console.log(body);
        userApi.setPwd({ pwd: fieldsValue.password, }).then(json => {
          this.setState({
            current: HAS_STUDY_ABROAD.FALSE,
          })
        }).catch(err => {
          console.log(err);
        })
      }
    });
  }
  //

  sendVerificationCode = _ => {
    let s = 60;
    let that = this;
    let phone = this.state.phone;
    if (/^[1][3,4,5,6,7,8][0-9]{9}$/.test(phone)) {
      let timer = setInterval(function () {
        s--;
        if (s > 0) {
          that.setState({
            verificationCode: s + "秒",
            canSendVerifityCode: true,
          })
        } else {
          clearInterval(timer);
          that.setState({
            verificationCode: "发送验证码",
            canSendVerifityCode: false,
          })
        }
      }, 1000);
      userApi.sendForgetPwdVerifyCode(phone).then(json => {

      }).catch(err => {
        console.log(err);
      })
    } else {
      message.error('请输入正确的手机号码', 1);
    }
  }


  //确认密码
  validateToNextPassword = (rule, val, cb) => {
    const form = this.props.form;
    if (val && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    cb();
  }
  compareToFirstPassword = (rule, val, cb) => {
    const form = this.props.form;
    if (val && val !== form.getFieldValue('password')) {
      cb('两次密码不一致!');
    } else {
      cb();
    }
  }
  handleConfirmBlur = (evt) => {
    const val = evt.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!val });
  }


  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <div className={style.login}>
        <div className={style.loginBox}>
          <div className={style.headerBox}>
            <div className={style.header}>
              <div className={style.logo}>
                <a href="/login"><img src={`${gImg.common.adminLogo}`} alt="logo" className={style.logoImg} /></a>
                <span className={style.line}> | </span>
                <span className={style.title}> 重置密码 </span>
              </div>
              <div className={style.right}>
                <a href="/user/login">登录</a>
                <span className={style.line}> | </span>
                <a href="/user/register">注册</a>
              </div>
            </div>
          </div>
          <div className={style.stepBox} >
            <Steps current={this.state.current} className={style.steps}>
              {this.steps.map(item => <Step key={item.title} title={item.title} />)}
            </Steps>
          </div>
          <Form className={this.state.current === HAS_STUDY_ABROAD.UNDEFINED ? style.form : style.hidden}>
            <Form.Item {...this.formItemLayout}  >
              {getFieldDecorator('userName', {
                rules: [{ min: 2, max: 32, required: true, message: '请输入2-32的账号' }],
              })(
                <Input size="large" prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder="请输入您的账号" />
              )}
            </Form.Item >
            <Form.Item {...this.formItemLayout}  >
              {getFieldDecorator('phone', {
                rules: [{ pattern: /^[1][3,4,5,6,7,8][0-9]{9}$/, required: true, message: '请输入正确的手机号' }],
              })(
                <Input size="large" prefix={<Icon type="phone" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  onBlur={e => {
                    this.setState({
                      phone: e.target.value,
                    })
                  }} placeholder="请输入您的手机号码" />
              )}
            </Form.Item >
            <Form.Item {...this.formItemLayout} >
              {getFieldDecorator('verificationCode', {
                rules: [{ required: true, message: '请输入验证码', }],
              })(
                <div className={style.verificationCode}>
                  <Input size="large" placeholder="请输入验证码" className={style.verificationCodeTitle} />
                  <Button className={style.verificationCodeBtn} size="large" onClick={this.sendVerificationCode}
                    disabled={this.state.canSendVerifityCode}>{this.state.verificationCode}</Button>
                </div>
              )}
            </Form.Item >
            <Form.Item {...this.formItemLayout} >
              {(
                <div className={style.submit}>
                  <Button size="large" className={style.btn} type="primary" onClick={this.nextSubmit}>下一步</Button>
                </div>
              )}
            </Form.Item >
          </Form>
          <Form className={this.state.current === HAS_STUDY_ABROAD.TRUE ? style.form : style.hidden}>
            <Form.Item {...this.formItemLayout} >
              {getFieldDecorator('password', {
                rules: [{ min: 4, max: 32, required: true, message: '请输入4-32位密码', }, {
                  validator: this.validateToNextPassword,
                }],
              })(
                <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} size="large"
                  type="password" placeholder="请输入4-32位密码"
                />
              )}
            </Form.Item>
            <Form.Item {...this.formItemLayout} >
              {getFieldDecorator('confirm', {
                rules: [{ required: true, message: "请确认密码", }, {
                  validator: this.compareToFirstPassword,
                }],
              })(
                <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  size="large" type="password" placeholder="请确认密码" onBlur={this.handleConfirmBlur}
                />
              )}
            </Form.Item>
            <Form.Item {...this.formItemLayout}  >
              {(
                <div className={style.submit}>
                  <Button size="large" className={style.btn} type="primary" onClick={this.nextSubmit}>确定</Button>
                </div>
              )}
            </Form.Item >
          </Form>
          <div className={this.state.current === HAS_STUDY_ABROAD.FALSE ? style.result : style.hidden}>
            <Icon type="check" className={style.icon} />
            <div className={style.prompt}>修改成功</div>
            <Button type="primary" className={style.btn}><a href="/login">去登陆</a></Button>
          </div>
        </div>
        <div className={style.footer}>Copyright &copy;  2019 邻米网络出品</div>
      </div>
    );
  }
}

const WrappedLogin = Form.create()(Login);

export default WrappedLogin;
