import React from "react";
import { Form, Input, Button, message, Breadcrumb, Upload, Icon, Modal, Radio } from "antd";
import { Link } from "react-router-dom";
import userApi from "../../../services/user";
import { getUploadImgUrl } from "../../../services/api";
import gSass from "../../../utils/sass";

const style = gSass.admin.user.addUser;
const RadioGroup = Radio.Group;
class AddUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAvatarPreviewActive: false,
      avatarPreviewImage: "",
      fileList: [],
      uploadUrl: getUploadImgUrl,
    };
  }
  componentDidMount() { }
  //添加用户提交表单
  addUser = evt => {
    evt && evt.preventDefault();
    this.props.form.validateFields((err, fieldsValue) => {
      if (err) {
        console.log(err);
        return;
      }
      let data = {
        account: fieldsValue.account,
        pwd: fieldsValue.pwd,
        rePwd: fieldsValue.rePwd,
        nick: fieldsValue.nick,
        email: fieldsValue.email,
        profile: fieldsValue.profile,
        phone: fieldsValue.phone,
        isAdmin: fieldsValue.isAdmin,
      };
      userApi
        .addUser(data)
        .then(json => {
          this.props.form.resetFields();
          this.setState({
            previewVisible: false,
            previewImage: "",
            fileList: [],
            uploadUrl: getUploadImgUrl,
          });
          message.success("添加成功", 1);
        })
        .catch(err => {
          message.error("添加失败,失败原因: " + err.msg, 3);
          console.log(err);
        });
    });
  };
  //上传头像
  handleChange = ({ fileList }) => this.setState({ fileList });
  handlePreview = file => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true,
    });
  };
  handleCancel = _ => this.setState({ previewVisible: false });

  checkNickIsExist = async nick => {
    return await userApi.checkNickIsExist(nick);
  };
  checkAccountIsExist = async account => {
    return await userApi.checkAccountIsExist(account);
  };
  checkEmailIsExist = async email => {
    return await userApi.checkEmailIsExist(email);
  };
  checkPhoneIsExist = async phone => {
    return await userApi.checkPhoneIsExist(phone);
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        sm: {
          span: 4,
          offset: 4,
        },
      },
      wrapperCol: {
        sm: {
          span: 8,
          offset: 0,
        },
      },
    };
    //头像上传按钮
    const { fileList } = this.state;
    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">上传图片</div>
      </div>
    );

    return (
      <div className={style.permission}>
        <Breadcrumb className={style.title}>
          <Breadcrumb.Item>
            <Link to="/admin">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/admin/user/list">用户管理</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>添加用户</Breadcrumb.Item>
        </Breadcrumb>
        <Form className={style.form}>
          <Form.Item {...formItemLayout} label="用户名">
            {getFieldDecorator("account", {
              rules: [
                { min: 2, max: 32, required: true, message: "请输入2-32的用户名" },
                {
                  pattern: /^[a-zA-Z0-9_-]{2,12}$/,
                  message: "用户名必须为2-32位大小写字母数字-_",
                },
                {
                  validator: async (rule, val, cb) => {
                    let errors = [];
                    let isExist = await this.checkAccountIsExist(val);
                    if (isExist) {
                      errors.push(new Error("用户名已存在"));
                    }
                    cb(errors);
                  },
                },
              ],
            })(<Input size="large" placeholder="请输入用户名" />)}
          </Form.Item>
          <Form.Item {...formItemLayout} label="密码">
            {getFieldDecorator("pwd", {
              rules: [
                { min: 4, max: 32, message: "密码长度为4-32" },
                { required: true, message: "请输入密码" },
              ],
            })(<Input.Password size="large" placeholder="请输入密码" />)}
          </Form.Item>
          <Form.Item {...formItemLayout} label="确认密码">
            {getFieldDecorator("rePwd", {
              rules: [
                { required: true, message: "请确认密码" },
                {
                  validator: (rule, val, cb) => {
                    let pwd = this.props.form.getFieldValue("pwd");
                    let errors = [];
                    if (pwd !== val) {
                      errors.push(new Error("两次密码不一致"));
                    }
                    cb(errors);
                  },
                },
              ],
            })(<Input.Password size="large" placeholder="请再次输入密码" />)}
          </Form.Item>
          <Form.Item {...formItemLayout} label="职位">
            {getFieldDecorator("nick", {
              rules: [
                { min: 2, max: 12, message: "职位长度为2-12位" },
                {
                  pattern: /^[a-zA-Z0-9\u3400-\u9fff\uf900-\ufaff_-]{2,12}$/,
                  message: "职位只能为2-12位汉字、字母、数字和下划线_及破折号-",
                },
                {
                  validator: async (rule, val, cb) => {
                    let errors = [];
                    let isExist = await this.checkNickIsExist(val);
                    if (isExist) {
                      errors.push(new Error("职位已存在"));
                    }
                    cb(errors);
                  },
                },
              ],
            })(<Input size="large" placeholder="请输入职位" />)}
          </Form.Item>
          <Form.Item {...formItemLayout} label="头像">
            {getFieldDecorator("avatar", {
              rules: [
                // { required: false, message: '请选择头像' }
              ],
            })(
              <div className="clearfix">
                <Upload
                  action={this.state.getUploadImgUrl}
                  listType="picture-card"
                  fileList={fileList}
                  onPreview={this.handlePreview}
                  onChange={this.handleChange}>
                  {fileList.length > 0 ? null : uploadButton}
                </Upload>
                <Modal
                  visible={this.state.showAvatarPreviewActive}
                  footer={null}
                  onCancel={this.handleCancel}>
                  <img
                    alt="example"
                    style={{ width: "100%" }}
                    src={this.state.avatarPreviewImage}
                  />
                </Modal>
              </div>,
            )}
          </Form.Item>
          <Form.Item {...formItemLayout} label="手机号码">
            {getFieldDecorator("phone", {
              rules: [
                { message: "请输入正确的手机号码", pattern: /^1[3456789]\d{9}$/ },
                {
                  validator: async (rule, val, cb) => {
                    let errors = [];
                    let isExist = await this.checkPhoneIsExist(val);
                    if (isExist) {
                      errors.push(new Error("手机号已注册"));
                    }
                    cb(errors);
                  },
                },
              ],
            })(<Input size="large" placeholder="请输入手机号码" />)}
          </Form.Item>
          <Form.Item {...formItemLayout} label="邮箱">
            {getFieldDecorator("email", {
              rules: [
                { type: "email", message: "请输入正确的邮箱" },
                {
                  validator: async (rule, val, cb) => {
                    let errors = [];
                    let isExist = await this.checkEmailIsExist(val);
                    if (isExist) {
                      errors.push(new Error("邮箱已注册"));
                    }
                    cb(errors);
                  },
                },
              ],
            })(<Input size="large" placeholder="请输入邮箱" />)}
          </Form.Item>
          <Form.Item {...formItemLayout} label="是否为管理员">
            {getFieldDecorator("isAdmin", {
              rules: [{ required: true, message: "请选择是否为管理员" }],
            })(
              <RadioGroup>
                <Radio value={true}>是</Radio>
                <Radio value={false}>否</Radio>
              </RadioGroup>,
            )}
          </Form.Item>
          <Form.Item {...formItemLayout} label="个人介绍">
            {getFieldDecorator("profile")(
              <Input.TextArea rows={4} maxLength={500} placeholder="请输入个人介绍(500字以内)" />,
            )}
          </Form.Item>
          <Form.Item
            wrapperCol={{
              xs: {
                span: 7,
                offset: 8,
              },
            }}>
            <Button size="large" className={style.btn} type="primary" onClick={this.addUser}>
              添加
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}
const WrappedLogin = Form.create()(AddUser);
export default WrappedLogin;
