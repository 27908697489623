import React, { Component, } from 'react';
import { Switch, } from 'react-router-dom';
import AdminAuthRoute from '../../../utils/AdminAuth';
import ProjectList from "../../../pages/admin/project/List";
import Base from "../../../pages/admin/project/Base";

export default class Wrap extends Component {
  render() {
    return (
      <Switch>
        <AdminAuthRoute exact path="/admin/project/list" component={ProjectList} />
        <AdminAuthRoute path="/admin/project/info/" component={Base} />
      </Switch>
    );
  }

}
