import { bget, bpost } from './api';

export async function getProjectInfo() {
  return bget({
    url: '/apply/getProjectInfo',
    query: {}
  })
}
export async function applyRecordPullUpMore(page, limit, filter = {}) {
  return bget({
    url: '/HomeList',
    query: {
      page,
      limit,
      filter,
    }
  })
}
//项目申请--提交
export async function createProject({
  name, teamMemberNumber, industryId, description, patentList, financingList,
  overseasBg, awardRecordList, teamMemberNumberHai, file
}) {
  return bpost({
    url: '/apply/createProject',
    data: {
      name, teamMemberNumber, industryId, description, patentList, financingList,
      overseasBg, awardRecordList, teamMemberNumberHai, file
    }
  })
}
//用户项目申请--重新提交
export async function createProjectEdit({
  applyProjectId,name, teamMemberNumber, industryId, description, patentList, financingList,
  overseasBg, awardRecordList, teamMemberNumberHai, file
}) {
  return bpost({
    url: '/apply/editCreateProject',
    data: {
      applyProjectId,name, teamMemberNumber, industryId, description, patentList, financingList,
      overseasBg, awardRecordList, teamMemberNumberHai, file
    }
  })
}
//项目申请--编辑
export async function edit({
  applyProjectId, projectName, totalNumber, industry, shanghaiHouseholder, description, patentList, financingList, prizeList, overseasMemberList, file
}) {
  return bpost({
    url: '/editCreateProject',
    data: {
      applyProjectId, projectName, totalNumber, industry, shanghaiHouseholder, description, patentList, financingList, prizeList, overseasMemberList, file
    }
  })
}
/**
 * 获取项目列表 
 */
export async function getList({ page, limit, filter = {} }) {
  return bget({
    url: '/admin/getProjectList',
    query: {
      page,
      limit,
      filter,
    }
  })
}
/**
 * 筛选项目列表 
 */
export async function projectFilter({ date, teams }) {
  return bget({
    url: '/admin/getProjectList',
    query: {
      date, teams
    }
  })
}
/**
 * 获取项目列表 
 */
export async function projectDetail({ id }) {
  return bget({
    url: '/admin/getProjectDetail',
    query: {
      id,
    }
  })
}
/**
 * 获取项目负责人信息
 */
export async function getOwnerInfo({ id }) {
  return bget({
    url: '/admin/getProjectOwnerInfo',
    query: {
      id,
    }
  })
}

export default {
  getOwnerInfo,
  projectDetail,
  getList,
  getProjectInfo,
  applyRecordPullUpMore,
  createProject,
  createProjectEdit,
  edit,
  projectFilter,
}
