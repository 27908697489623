import { bget, bpost } from './api';

export async function applyRecruit({
  contactInfo, recruitPersonNumber, salary, station, description,
}) {
  return bpost({
    url: '/apply/recruit',
    data: {
      contactInfo, recruitPersonNumber, salary, station, description,
    }
  })
}
// 后台编辑
export async function edit({
  applyId, admin = true, contactInfo, recruitPersonNumber, maxSalary, minSalary, stationList, description,
}) {
  return bpost({
    url: '/editRecruit',
    data: {
      applyId, admin, contactInfo, recruitPersonNumber, minSalary, maxSalary, stationList, description,
    }
  })
}

export async function recruitDetail(id) {
  return bget({
    url: '/recruit/detail',
    query: {
      id,
    }
  })
}

export async function getRecruitInfo() {
  return bget({
    url: '/recruit/getRecruitInfo',
    query: {}
  })
}

export const STATION = {
  WEB_FONT_END_ENGINEER: 0x0,
  JAVA_DEVELOPMENT_ENGINEER: 0x1,
  UI_DESIGNER: 0x2,
  PHP_DEVELOPMENT_ENGINEER: 0x3,
  ALGORITHM_ENGINEER: 0x4,
  DATA_ANALYST: 0x5,
}
export const STATION_ZH = {
  [STATION.WEB_FONT_END_ENGINEER]: "Web前端开发",
  [STATION.JAVA_DEVELOPMENT_ENGINEER]: "Java开发工程师",
  [STATION.UI_DESIGNER]: "Ui设计师",
  [STATION.PHP_DEVELOPMENT_ENGINEER]: "Php开发工程师",
  [STATION.ALGORITHM_ENGINEER]: "算法工程师",
  [STATION.DATA_ANALYST]: "数据分析师",
}


export default {
  applyRecruit,
  recruitDetail,
  getRecruitInfo,
  edit
}
