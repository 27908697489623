import React from 'react';
import {
  Breadcrumb, Form, Steps, Alert, Spin, Divider, Tooltip, Collapse, Modal, Switch, Badge,
  Empty, Upload,
  Button
} from 'antd';
import { LocalStorage as storage } from "jsbdk";
import { Link, } from "react-router-dom";
import { BASE_URL, } from '../../../config/prod';
import gSass from '../../../utils/sass';
import csn from 'classnames';
import qs from 'qs';
import applyApi, { EDUCATION, EDUCATION_ZH, } from "../../../services/apply"
import AuditWithSendMsg from "../../../components/admin/apply/AuditWithSendMsg"
import companyApi from '../../../services/company';
import { TYPE as PATENT_TYPE, TYPE_ZH as PATENT_TYPE_ZH, FINANCING_TYPE as FinancingType, FINANCING_TYPE_ZH as FinancingTypeZh, SUBORDINATE_TYPE as SubordinateType, SUBORDINATE_TYPE_ZH as SubordinateTypeZh } from '../../../services/patent';
import Lightbox from 'react-images';
import collegeApi from "../../../services/college";
import schoolSpecialityApi from "../../../services/schoolSpeciality";
import userApi from "../../../services/user";
const style = gSass.admin.apply.companyEnterInfo;
const Step = Steps.Step;
const Panel = Collapse.Panel;
// 专利类型
const patentTypeList = Object.values(PATENT_TYPE).map(v => ({
  value: v,
  label: PATENT_TYPE_ZH[v],
}));
// 融资形式
const financingTypeList = Object.values(FinancingType).map(v => ({
  value: v,
  label: FinancingTypeZh[v]
}))
// 所属等级
const subordinateTypeList = Object.values(SubordinateType).map(v => ({
  value: v,
  label: SubordinateTypeZh[v],
}))
// 上传附件
let uploadList = [];
const upload = {
  action: BASE_URL + '/antdUploadImg',
  onChange({ file, fileList }) {
    if (file.status !== 'uploading') {
      if (file.response.code === 0) {
        uploadList.push(file.response.data)
      } else {
        console.log(file.response.msg);
      }
    }
  },
}
class CreateProjectInfo extends AuditWithSendMsg {
  constructor(props) {
    super(props)
    let { id } = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    id = parseInt(id)
    this.state = {
      id,
      userNick: '',
      loading: true,
      lightboxIsOpen: false,
      //data
      uploadStateList: [],//上传的附件列表默认值
      applyDetail: {
        info: {
          id: 1,
          name: '',
          totalNumber: null,
          industry: "",
          mainBusiness: "",
          businessLicensePic: '',
          bankAccountOpeningCertificatePic: '',
          ctime: '',
          registerCapital: '',
          registerTime: '',
          registerAddress: {
            whole: "",
            detail: "",
          }

        },
        apply_uid: 0,
        current: 1,
        total: 1,
        steps: [
        ],
        applyUser: {
          name: "",
          id_no: "",
          schoolName: "",
          grade: "",
          specialityName: "",
          education: "",
        },
        patentList: [],
        financingRecordList: [],
        awardRecordList: [],
        shareholderList: [],
        // insuredPersonList: [],
        overseas_id_list: [],
      },
      lightBoxImgs: [],
      college: "",
      speciality: "",
      grade: "",
      education: "",
      personalName: "",
      idCardNo: null,
      phone: null,
      email: null,
    };
  }

  componentDidMount() {
    this.init();
  }

  init = async _ => {
    try {
      this.setState({
        userNick: storage.get('nick'),
      })
      let { data: detailEmail } = await userApi.getUserInfo();
      console.log(detailEmail)
      this.setState({
        phone: detailEmail.phone,
        email: detailEmail.email,
      });

      // 获取类型数组
      let { data: { list: industryList, } } = await companyApi.getIndustryList({ page: -1, limit: -1, });
      industryList = industryList.map(v => ({
        value: v.id,
        label: v.name,
      }))
      // 获取学院属数组
      let {
        data: { list: collegeList },
      } = await collegeApi.getList({ page: -1, limit: -1 });
      collegeList = collegeList.map(v => ({
        value: v.id,
        label: v.name,
      }))
      // 获取专业数组
      let {
        data: { list: specialityList },
      } = await schoolSpecialityApi.getList({ page: -1, limit: -1 });
      specialityList = specialityList.map(v => ({
        value: v.id,
        label: v.name,
      }))
      // 获取学历
      let educationList =
        Object.values(EDUCATION).map((v, k) => ({
          value: v,
          label: EDUCATION_ZH[v],
        }))
      console.log(educationList)
      let { data: {
        detail: applyDetail,
      } } = await applyApi.getApplyDetail({ id: this.state.id });
      // 专利类型
      let industry = "";
      for (let v of industryList) {
        if (v.value === applyDetail.info.industryId) {
          industry = v.label;
        }
      }
      // 融资形式
      for (let i = 0; i < applyDetail.financingRecordList.length; i++) {
        for (let j = 0; j < financingTypeList.length; j++) {
          if (parseInt(applyDetail.financingRecordList[i].mode) === financingTypeList[j].value) {
            applyDetail.financingRecordList[i].mode = financingTypeList[j].label;
          }
        }
      }
      // 所属等级
      for (let i = 0; i < applyDetail.awardRecordList.length; i++) {
        for (let j = 0; j < subordinateTypeList.length; j++) {
          if (parseInt(applyDetail.awardRecordList[i].belong_level) === subordinateTypeList[j].value) {
            applyDetail.awardRecordList[i].belong_level = subordinateTypeList[j].label;
          }
        }
      }
      // 附件
      let uploadStateList = this.state.uploadStateList
      if (applyDetail.info.company_plan.length > 0) {
        for (let v of applyDetail.info.company_plan) {
          uploadStateList.push({
            uid: v.picId,
            name: v.name,
            url: BASE_URL + v.url
          })
        }
        uploadList = uploadStateList
      } else {
        uploadStateList = []
      }
      this.setState({
        loading: false,
      })
      applyDetail.info.industry = industry;
      console.log(111)
      for (let i = 0; i < applyDetail.patentList.length; i++) {
        for (let j = 0; j < patentTypeList.length; j++) {
          if (applyDetail.patentList[i].type === patentTypeList[j].value) {
            applyDetail.patentList[i].type = patentTypeList[j].label;
          }
        }
      }

      // 海外留学学院
      for (let i = 0; i < applyDetail.overseas_id_list.length; i++) {
        for (let j = 0; j < collegeList.length; j++) {
          if (applyDetail.overseas_id_list[i].college_id === collegeList[j].value) {
            applyDetail.overseas_id_list[i].college_id = collegeList[j].label;
          }
        }
      }
      // 海外留学专业
      for (let i = 0; i < applyDetail.overseas_id_list.length; i++) {
        for (let j = 0; j < specialityList.length; j++) {
          if (applyDetail.overseas_id_list[i].speciality_id === specialityList[j].value) {
            applyDetail.overseas_id_list[i].speciality_id = specialityList[j].label;
          }
        }
      }
      // 海外留学学历
      for (let i = 0; i < applyDetail.overseas_id_list.length; i++) {
        for (let j = 0; j < educationList.length; j++) {
          if (applyDetail.overseas_id_list[i].education === educationList[j].value) {
            applyDetail.overseas_id_list[i].education = educationList[j].label;
          }
        }
      }

      this.setState({
        applyDetail,
      })
    } catch (e) {
      console.log(e)
    }
  }
  closeLightbox = _ => {
    this.setState({
      lightboxIsOpen: false,
      lightBoxImgs: [],
    })
  }
  closeApply = _ => {

  }
  render() {
    return (
      <div className={style.info}>
        <Breadcrumb className={style.title}>
          <Breadcrumb.Item><Link to="/admin">首页</Link></Breadcrumb.Item>
          <Breadcrumb.Item><Link to="/admin/apply/allList">申请管理</Link></Breadcrumb.Item>
          <Breadcrumb.Item>公司入驻流程</Breadcrumb.Item>
        </Breadcrumb>
        <div className={style.prompt}>
          <Alert description=" 审核人可以审核之前与申请人进行沟通, 给予申请人在申请中出现的问题做答复" type="info" showIcon message="提示" />
        </div>
        <Spin spinning={this.state.loading} size="large">
          <div className={style.content}>
            <div className={style.step}>
              <Steps current={this.state.applyDetail.current - 1}>
                {this.state.applyDetail.steps.map((v, k) => {
                  return <Step key={k} title={v.name} description={<div>
                  </div>} />
                })}
              </Steps>
            </div>
            <div className={style.userInfo}>
              <div className={style.infoList}>

                <div className={style.item}>
                  <div className={style.name}>法人姓名:</div>
                  <div className={style.detail}>{this.state.applyDetail.applyUser.name}</div>
                </div>
                <div className={style.item}>
                  <div className={style.name}>身份证号码:</div>
                  <div className={style.detail}>{this.state.applyDetail.applyUser.id_no}</div>
                </div>
                <div className={style.item}>
                  <div className={style.name}>电话:</div>
                  <div className={style.detail}>{this.state.phone}</div>
                </div>
                <div className={style.item}>
                  <div className={style.name}>邮箱:</div>
                  <div className={style.detail}>{this.state.email}</div>
                </div>

                <div className={style.item}>
                  <div className={style.name}>团队总人数:</div>
                  <div className={style.detail}>{this.state.applyDetail.info.totalNumber}</div>
                </div>
                <div className={style.item}>
                  <div className={style.name}>团队上海户籍人数:</div>
                  <div className={style.detail}>{this.state.applyDetail.info.shanghaiNumber}</div>
                </div>
                <div className={style.item}>
                  <div className={style.name}>注册地址:</div>
                  <div className={style.detail}>{this.state.applyDetail.info.registerAddress.whole}</div>
                </div>
                <div className={style.item}>
                  <div className={style.name}>注册资金:</div>
                  <div className={style.detail}>{this.state.applyDetail.info.registerCapital}</div>
                </div>
                <div className={style.item}>
                  <div className={style.name}>注册时间:</div>
                  <div className={style.detail}>{this.state.applyDetail.info.registerTime.substr(0, 10)}</div>
                </div>
                <div className={style.item}>
                  <div className={style.name}>所属行业:</div>
                  <div className={style.detail}>{this.state.applyDetail.info.industry}</div>
                </div>
                <div className={style.panel}>
                  <Collapse accordion>
                    {/* 股东信息 */}
                    <Panel header={<div>股东列表{this.state.applyDetail.shareholderList.length > 0 ?
                      <Badge style={{ marginLeft: '10px', }} count={this.state.applyDetail.shareholderList.length} />
                      : null}</div>} key="1">
                      {this.state.applyDetail.shareholderList.map((v, k) => {
                        return (<div className={style.panelList} key={k}>
                          <div className={style.panelTitle}>{`股东${k + 1}`}</div>
                          <div className={style.panelItem}>
                            <div className={style.panelName}>{`股东名`}</div>
                            <div className={style.panelDetail}>{v.name}</div>
                          </div>
                          <div className={style.panelItem}>
                            <div className={style.panelName}>{`学历`}</div>
                            <div className={style.panelDetail}>{EDUCATION_ZH[v.education]}</div>
                          </div>
                          <div className={style.panelItem}>
                            <div className={style.panelName}>{`户籍地址`}</div>
                            <div className={style.panelDetail}>{v.address ? v.address.whole : ''}</div>
                          </div>
                          <div className={style.panelItem}>
                            <div className={style.panelName}>{`身份证号码`}</div>
                            <div className={style.panelDetail}>{v.idCardNo}</div>
                          </div>
                          <div className={style.panelItem}>
                            <div className={style.panelName}>{`是否海外留学(含港澳)`}</div>
                            <div className={style.panelDetail} style={{ border: "none" }}>
                              <Switch checkedChildren="是" unCheckedChildren="否" checked={v.studyAbroadInfo ? true : false} disabled />
                            </div>
                          </div>
                          <div className={v.studyAbroadInfo ? style.studyAbroad : style.hidden}>
                            <div className={style.panelItem}>
                              <div className={style.panelName}>{`学校姓名`}</div>
                              <div className={style.panelDetail}>{v.studyAbroadInfo ? v.studyAbroadInfo.school_name : ""}</div>
                            </div>
                            <div className={style.panelItem}>
                              <div className={style.panelName}>{`学历`}</div>
                              <div className={style.panelDetail}>{v.studyAbroadInfo ? EDUCATION_ZH[v.studyAbroadInfo.education] : EDUCATION_ZH.UNDEFINED}</div>
                            </div>
                            <div className={style.panelItem}>
                              <div className={style.panelName}>{`学历证明图片`}</div>
                              <img className={style.panelImg} src={v.studyAbroadInfo ? BASE_URL + v.studyAbroadInfo.educationProvePic.url : ""} alt="学历证明图片"
                                onClick={_ => {
                                  this.setState({
                                    lightboxIsOpen: true,
                                    lightBoxImgs: [{ src: `${BASE_URL}${v.studyAbroadInfo.educationProvePic.url}` }],
                                  })
                                }} />
                            </div>
                          </div>
                        </div>);
                      })}
                    </Panel>
                    {/*参保人信息 */}
                    {/* <Panel header={<div>参保人列表{this.state.applyDetail.insuredPersonList.length > 0 ?
                      <Badge style={{ marginLeft: '10px', }} count={this.state.applyDetail.insuredPersonList.length} /> : null}</div>} key="2">
                      {this.state.applyDetail.insuredPersonList.map((v, k) => {
                        return (<div className={style.panelList} key={k}>
                          <div className={style.panelTitle}>{`参保人${k + 1}`}</div>
                          <div className={style.panelItem}>
                            <div className={style.panelName}>{`姓名`}</div>
                            <div className={style.panelDetail}>{v.name}</div>
                          </div>
                          <div className={style.panelItem}>
                            <div className={style.panelName}>{`开始缴纳时间`}</div>
                            <div className={style.panelDetail}>{v.time.substr(0, 10)}</div>
                          </div>
                        </div>);
                      })}
                    </Panel> */}
                    {/* 专利信息 */}
                    <Panel header={<div>专利列表{this.state.applyDetail.patentList.length > 0 ?
                      <Badge style={{ marginLeft: '10px', }} count={this.state.applyDetail.patentList.length} /> : null}</div>} key="2">
                      {this.state.applyDetail.patentList.map((v, k) => {
                        console.log(v.file_id_list[0])
                        return (<div className={style.panelList} key={k}>
                          <div className={style.panelTitle}>{`专利${k + 1}`}</div>
                          <div className={style.panelItem}>
                            <div className={style.panelName}>{`专利名`}</div>
                            <div className={style.panelDetail}>{v.name}</div>
                          </div>
                          <div className={style.panelItem}>
                            <div className={style.panelName}>{`专利类型`}</div>
                            <div className={style.panelDetail}>{v.type}</div>
                          </div>
                          <div className={style.panelItem}>
                            <div className={style.panelName}>{`获取专利时间`}</div>
                            <div className={style.panelDetail}>{v.time.substr(0, 10)}</div>
                          </div>
                          <div className={style.panelItem}>
                            <div className={style.panelName}>{`专利证书`}</div>
                            <img className={style.panelImg} src={v.file_id_list[0] ? BASE_URL + v.file_id_list[0].url : ''} alt="专利证书"
                              onClick={_ => {
                                this.setState({
                                  lightboxIsOpen: true,
                                  lightBoxImgs: [{ src: `${BASE_URL}${v.studyAbroadInfo.educationProvePic.url}` }],
                                })
                              }} />
                          </div>
                        </div>);
                      })}
                    </Panel>
                    {/* 融资信息 */}
                    <Panel header={<div>融资列表{this.state.applyDetail.financingRecordList.length > 0 ?
                      <Badge style={{ marginLeft: '10px', }} count={this.state.applyDetail.financingRecordList.length} /> : null}</div>} key="3">
                      {this.state.applyDetail.financingRecordList.map((v, k) => {
                        return (<div className={style.panelList} key={k}>
                          <div className={style.panelTitle}>{`融资${k + 1}`}</div>
                          <div className={style.panelItem}>
                            <div className={style.panelName}>{`融资金额( 元 )`}</div>
                            <div className={style.panelDetail}>{v.money / 100}</div>
                          </div>
                          <div className={style.panelItem}>
                            <div className={style.panelName}>{`融资来源`}</div>
                            <div className={style.panelDetail}>{v.source}</div>
                          </div>
                          <div className={style.panelItem}>
                            <div className={style.panelName}>{`融资形式`}</div>
                            <div className={style.panelDetail}>{v.mode}</div>
                          </div>
                          <div className={style.panelItem}>
                            <div className={style.panelName}>{`获取融资时间`}</div>
                            <div className={style.panelDetail}>{v.time.substr(0, 10)}</div>
                          </div>
                          <div className={style.panelItem}>
                            <div className={style.panelName}>{`融资证明`}</div>
                            <img className={style.panelImg} src={BASE_URL + v.file_id_list[0].url} alt="融资证明"
                              onClick={_ => {
                                this.setState({
                                  lightboxIsOpen: true,
                                  lightBoxImgs: [{ src: `${BASE_URL}${v.studyAbroadInfo.educationProvePic.url}` }],
                                })
                              }} />
                          </div>
                        </div>);
                      })}
                    </Panel>
                    {/* 获奖列表 */}
                    <Panel header={<div>获奖列表{this.state.applyDetail.awardRecordList.length > 0 ?
                      <Badge style={{ marginLeft: '10px', }} count={this.state.applyDetail.awardRecordList.length} /> : null}</div>} key="4">
                      {this.state.applyDetail.awardRecordList.map((v, k) => {
                        return (<div className={style.panelList} key={k}>
                          <div className={style.panelTitle}>{`获奖${k + 1}`}</div>
                          <div className={style.panelItem}>
                            <div className={style.panelName}>{`获奖名称`}</div>
                            <div className={style.panelDetail}>{v.name}</div>
                          </div>
                          <div className={style.panelItem}>
                            <div className={style.panelName}>{`所属级别`}</div>
                            <div className={style.panelDetail}>{v.belong_level}</div>
                          </div>
                          <div className={style.panelItem}>
                            <div className={style.panelName}>{`奖项级别`}</div>
                            <div className={style.panelDetail}>{v.prize_level}</div>
                          </div>
                          <div className={style.panelItem}>
                            <div className={style.panelName}>{`获奖时间`}</div>
                            <div className={style.panelDetail}>{v.obtain_time.substr(0, 10)}</div>
                          </div>
                          <div className={style.panelItem}>
                            <div className={style.panelName}>{`获奖证书`}</div>
                            <img className={style.panelImg} src={BASE_URL + v.file_id_list[0].url} alt="获奖证书"
                              onClick={_ => {
                                this.setState({
                                  lightboxIsOpen: true,
                                  lightBoxImgs: [{ src: `${BASE_URL}${v.studyAbroadInfo.educationProvePic.url}` }],
                                })
                              }} />
                          </div>
                          {/* <div className={style.panelItem}>
                            <div className={style.panelName}>{`主办方名称`}</div>
                            <div className={style.panelDetail}>{v.organiser.substr(0, 10)}</div>
                          </div> */}
                        </div>);
                      })}
                    </Panel>
                    {/* 海外留学信息 */}
                    <Panel header={
                      <div>是否有海外留学背景成员{this.state.applyDetail.overseas_id_list.length > 0 ? <Badge style={{ marginLeft: '10px' }} count={this.state.applyDetail.overseas_id_list.length} /> : null}
                      </div>} key="5"
                    >
                      {this.state.applyDetail.overseas_id_list.map((v, k) => {
                        return (
                          <div className={style.panelList} key={k}>
                            <div className={style.panelTitle}>{`海外留学背景成员${k + 1}`}</div>
                            <div className={style.panelItem}>
                              <div className={style.panelName}>{`姓名`}</div>
                              <div className={style.panelDetail}>{v.name}</div>
                            </div>
                            <div className={style.panelItem}>
                              <div className={style.panelName}>{`学院`}</div>
                              <div className={style.panelDetail}>{v.college_id}</div>
                            </div>
                            <div className={style.panelItem}>
                              <div className={style.panelName}>{`专业`}</div>
                              <div className={style.panelDetail}>{v.speciality}</div>
                            </div>
                            <div className={style.panelItem}>
                              <div className={style.panelName}>{`学历`}</div>
                              <div className={style.panelDetail}>{v.education}</div>
                            </div>
                            <div className={style.panelItem}>
                              <div className={style.panelName}>{`毕业时间`}</div>
                              <div className={style.panelDetail}>{v.graduation_time.substr(0, 10)}</div>
                            </div>
                            <div className={style.panelItem}>
                              <div className={style.panelName}>{`学历证明图片`}</div>
                              <img className={style.panelImg} src={BASE_URL + v.degree_pic_url.url} alt="学历证明图片"
                                onClick={_ => {
                                  this.setState({
                                    lightboxIsOpen: true,
                                    lightBoxImgs: [{ src: `${BASE_URL}${v.studyAbroadInfo.educationProvePic.url}` }],
                                  })
                                }} />
                            </div>
                          </div>
                        )
                      })}

                    </Panel>
                  </Collapse>
                </div>
                <div className={style.itemImg}>
                  <div className={style.name}>营业执照图片:</div>
                  <div className={style.img} onClick={_ => {
                    this.setState({
                      lightboxIsOpen: true,
                      lightBoxImgs: [{ src: `${BASE_URL}${this.state.applyDetail.info.businessLicensePic.url}` }],
                    })
                  }}>
                    <img src={`${BASE_URL}${this.state.applyDetail.info.businessLicensePic.url}`}
                      alt="营业执照图片" />
                  </div>
                </div>
                <div className={style.itemImg}>
                  <div className={style.name}>银行开户正面照片:</div>
                  <div className={style.img} onClick={_ => {
                    this.setState({
                      lightboxIsOpen: true,
                      lightBoxImgs: [{ src: `${BASE_URL}${this.state.applyDetail.info.bankAccountOpeningCertificatePic.url}` }],
                    })
                  }}>
                    <img src={`${BASE_URL}${this.state.applyDetail.info.bankAccountOpeningCertificatePic.url}`}
                      alt="银行开户正面照片" />
                  </div>
                </div>
                <div className={style.item}>
                  <div className={style.name}>主营业务:</div>
                  <div className={csn(style.description, style.detail)} onClick={_ => {
                    Modal.success({
                      title: '岗位描述',
                      maskClosable: true,
                      content: (<div>{this.state.applyDetail.info.mainBusiness}</div>),
                    });
                  }}>{this.state.applyDetail.info.mainBusiness}</div>
                </div>
                <div className={style.itemUpload}>
                  <div className={style.tips}>提示:如果有负责人简历,专利证书,融资证明文件,获奖证书,
         项目计划书，商业计划书等,请上传一张对应的附件</div>
                  <Upload className={style.uploadWrap} {...upload} defaultFileList={this.state.uploadStateList}>
                    <Button className={style.uploadBtn} >
                      上传
                  </Button>
                  </Upload>
                </div>
              </div>
              {this.state.applyDetail.steps.length > 0 ? <div className={style.chargeUserList}>
                <div className={style.theme}>审核人列表</div>
                <div className={style.reviewStyle}>审核方式:
                <span className={style.reviewStyleTitle}>
                    {this.state.applyDetail.steps[this.state.applyDetail.current - 1].should_all_pass === 1 ?
                      <Tooltip title='“会签”：要求在每一轮审核中所有审核人员都通过该申请才会进入下一轮审核。'>会签</Tooltip> :
                      <Tooltip title='“或签”：要求在每一轮审核中其中一个审核人通过该申请就进入下一轮申请。'>或签</Tooltip>}
                  </span>
                </div>
                {this.state.applyDetail.steps[this.state.applyDetail.current - 1].charge_user_list.map((v, k) => {
                  return <div key={k} className={csn(style.item, { [style.stepIsPass]: v.charge_status === 1 },
                    { [style.stepIsReject]: v.charge_status === 2, },
                    { [style.stepIsProcess]: v.charge_status === 0, })}>
                    <div className={style.chargeUser}>
                      <div className={style.nick}>审核人: {v.nick}</div>
                      <div className={style.result}>{v.charge_status === 1 ? "通过" : v.charge_status === 2 ? "拒绝" : "审核中"}</div>
                    </div>
                    <Divider style={{ height: 50, }} type="vertical" />
                  </div>
                })}
                <div className={style.chargeUserMsg}>审核通过后自动进入下一审核流程</div>
              </div> : null}
            </div>
            <div className={style.msgList}>
              <div className={style.msgListTitle}>审核人员与申请人对话列表</div>
              <Divider style={{ margin: 0, }} />
              {this.state.applyDetail.steps.length > 0 ?
                this.state.applyDetail.steps[this.state.applyDetail.current - 1].messages.length > 0 ?
                  this.state.applyDetail.steps[this.state.applyDetail.current - 1].messages.map((v, k) => {
                    return <div key={k} className={style.item}>
                      {v.send_user.id === this.state.applyDetail.apply_uid ?
                        <div className={style.author}>
                          <div className={style.msgSendTime}>{v.ctime.substr(0, 10)}</div>
                          <div className={style.msgSendUserNick}>{v.send_user.nick}</div>
                        </div> :
                        <div className={style.author}>
                          <div className={style.msgSendUserNick}>{v.send_user.nick}</div>
                          <div className={style.msgSendTime}>{v.ctime.substr(0, 10)}</div>
                        </div>}
                      <div className={csn(style.msg, { [style.selfMsg]: v.send_user.id === this.state.applyDetail.apply_uid })}>
                        <div className={style.message}>{v.msg}</div>
                      </div>
                    </div>
                  }) : <Empty /> : <Empty />}
            </div>
            {this.getFooterOperationDom()}
          </div>
        </Spin>
        {/* 图片查看 */}
        <Lightbox images={this.state.lightBoxImgs} isOpen={this.state.lightboxIsOpen}
          onClose={this.closeLightbox} showCloseButton={false} showImageCount={false}
          backdropClosesModal={true}
        />
      </div>
    );
  }
}
export default Form.create()(CreateProjectInfo);
