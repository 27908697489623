import { bget, bpost } from "./api";

export const TYPE_ALL_APPLY = -1;
export const TYPE = {
  createProject: 0x0,
  companyEnter: 0x1,
  conferenceRoomRent: 0x2,
  coffeeHouseRent: 0x3,
  mealCard: 0x4,
  parkingPermit: 0x5,
  recruit: 0x6,
  realNameAuth: 0x7,
  schoolInfoAuth: 0x8,
  essentialInformationAuth: 0x9,
};
/**
 * 实名认证状态
 */
export const ID_REAL_NAME_APPLY_STATUS = {
  false: 0x0,
  true: 0x1,
  process: 0x2,
};
export const ID_REAL_NAME_APPLY_STATUS_ZH = {
  [ID_REAL_NAME_APPLY_STATUS["false"]]: "未实名认证",
  [ID_REAL_NAME_APPLY_STATUS["true"]]: "已实名认证",
  [ID_REAL_NAME_APPLY_STATUS["process"]]: "实名认证中",
};
/**
 * 申请状态
 */
export const APPLY_STATUS = {
  false: 0x0,
  true: 0x1,
  process: 0x2,
};
/**
 * 校园信息认证状态
 */
export const SCHOOL_INFO_APPLY_STATUS = {
  false: 0x0,
  true: 0x1,
  process: 0x2,
};
export const SCHOOL_INFO_APPLY_STATUS_ZH = {
  [SCHOOL_INFO_APPLY_STATUS["false"]]: "未校园认证",
  [SCHOOL_INFO_APPLY_STATUS["true"]]: "已校园认证",
  [SCHOOL_INFO_APPLY_STATUS["process"]]: "校园认证中",
};
/**
 * 申请类型与申请详情url映射
 */
export const typeMapApplyDetailUrl = {
  [TYPE["createProject"]]: "/admin/apply/createProjectInfo",
  [TYPE["companyEnter"]]: "/admin/apply/companyEnterInfo",
  [TYPE["conferenceRoomRent"]]: "/admin/apply/conferenceRoomRentInfo",
  [TYPE["coffeeHouseRent"]]: "/admin/apply/coffeeHouseRentInfo",
  [TYPE["mealCard"]]: "/admin/apply/mealCardInfo",
  [TYPE["parkingPermit"]]: "/admin/apply/parkingPermitInfo",
  [TYPE["recruit"]]: "/admin/apply/recruitInfo",
  [TYPE["realNameAuth"]]: "/admin/apply/idCardRealNameInfo",
  [TYPE["schoolInfoAuth"]]: "/admin/apply/schoolInfo",
  [TYPE["essentialInformationAuth"]]: "/admin/apply/mergeInfoDetail",
};
export const TYPE_ZH = {
  [TYPE.createProject]: "项目入驻",
  [TYPE.companyEnter]: "公司入驻",
  [TYPE.conferenceRoomRent]: "会议室租用",
  [TYPE.coffeeHouseRent]: "咖啡吧",
  [TYPE.mealCard]: "饭卡",
  [TYPE.parkingPermit]: "停车证",
  [TYPE.recruit]: "招聘",
  [TYPE.realNameAuth]: "实名认证",
  [TYPE.schoolInfoAuth]: "学校信息认证",
  [TYPE.essentialInformationAuth]: "基本信息认证",
};
export const STATUS = {
  process: 0x0,
  pass: 0x1,
  reject: 0x2,
  close: 0x3,
};
export const STATUS_ZH = {
  [STATUS.process]: "处理中",
  [STATUS.pass]: "已通过",
  [STATUS.reject]: "已拒绝",
  [STATUS.close]: "已关闭",
};
export const EDUCATION = {
  UNDEFINED: 0,
  UNDERGRADUATE: 1,
  MASTER_DEGREE_IN_READING: 2,
  DOCTORAL_DEGREE_IN_READING: 3,
  BACHELOR_DEGREE: 4,
  MASTER_DEGREE: 5,
  DOCTORAL_DEGREE: 6,
  PRIMARY_SCHOOL: 7,
  MIDDLE_SCHOOL: 8,
  HIGH_SCHOOL: 9,
  TECHNICAL_SECONDARY_SCHOOL: 10,

};
export const EDUCATION_ZH = {
  [EDUCATION.UNDEFINED]: "未知",
  [EDUCATION.UNDERGRADUATE]: "本科在读",
  [EDUCATION.MASTER_DEGREE_IN_READING]: "硕士在读",
  [EDUCATION.DOCTORAL_DEGREE_IN_READING]: "博士在读",
  [EDUCATION.BACHELOR_DEGREE]: "本科毕业",
  [EDUCATION.MASTER_DEGREE]: "硕士毕业",
  [EDUCATION.DOCTORAL_DEGREE]: "博士毕业",
  [EDUCATION.PRIMARY_SCHOOL]: "小学",
  [EDUCATION.MIDDLE_SCHOOL]: "中学",
  [EDUCATION.HIGH_SCHOOL]: "高中",
  [EDUCATION.TECHNICAL_SECONDARY_SCHOOL]: "中专",
};

export const IS_GRADUATE = {
  UNDEFINDE: 0x0,
  TRUE: 0x1,
  FALSE: 0x2,
};
export const IS_GRADUATE_ZH = {
  [IS_GRADUATE.UNDEFINDE]: "未知",
  [IS_GRADUATE.TRUE]: "是",
  [IS_GRADUATE.FALSE]: "否",
};

/**
 * 所有申请列表
 */
export async function getAllApplyList({ type, page, limit, filter = {} }) {
  return bget({
    url: "/apply/getList",
    query: {
      type: TYPE_ALL_APPLY,
      page,
      limit,
      filter,
    },
  });
}
/**
 * 获取实名认证申请列表
 */
export async function getRealNameAuthApplyList({ page, limit, filter = {} }) {
  return bget({
    url: "/apply/getList",
    query: {
      type: TYPE["realNameAuth"],
      page,
      limit,
      filter,
    },
  });
}
/**
 * 获取项目入驻申请列表
 */
export async function getCreateProjectApplyList({ page, limit, filter = {} }) {
  return bget({
    url: "/apply/getList",
    query: {
      type: TYPE["createProject"],
      page,
      limit,
      filter,
    },
  });
}
/**
 * 获取项目入驻申请详情
 */
export async function getCreateProjectApplyDetail({ id }) {
  return bget({
    url: "/apply/getDetail",
    query: {
      id,
    },
  });
}

/**
 * 获取公司入驻申请列表
 */
export async function getCompanyEnterApplyList({ page, limit, filter = {} }) {
  return bget({
    url: "/apply/getList",
    query: {
      type: TYPE["companyEnter"],
      page,
      limit,
      filter,
    },
  });
}
/**
 * 获取会议室租用申请列表
 */
export async function getConferenceRoomRentApplyList({ page, limit, filter = {} }) {
  return bget({
    url: "/apply/getList",
    query: {
      type: TYPE["conferenceRoomRent"],
      page,
      limit,
      filter,
    },
  });
}
/**
 * 获取咖啡室租用申请列表
 */
export async function getCoffeeHouseRentApplyList({ page, limit, filter = {} }) {
  return bget({
    url: "/apply/getList",
    query: {
      type: TYPE["coffeeHouseRent"],
      page,
      limit,
      filter,
    },
  });
}
/**
 * 获取饭卡申请列表
 */
export async function getMealCardApplyList({ page, limit, filter = {} }) {
  return bget({
    url: "/apply/getList",
    query: {
      type: TYPE["mealCard"],
      page,
      limit,
      filter,
    },
  });
}
/**
 * 获取停车证申请列表
 */
export async function getParkingPermitApplyList({ page, limit, filter = {} }) {
  return bget({
    url: "/apply/getList",
    query: {
      type: TYPE["parkingPermit"],
      page,
      limit,
      filter,
    },
  });
}
/**
 * 获取招聘申请列表
 */
export async function getRecruitApplyList({ page, limit, filter = {} }) {
  return bget({
    url: "/apply/getList",
    query: {
      type: TYPE["recruit"],
      page,
      limit,
      filter,
    },
  });
}
/**
 * 获取校园信息认证申请列表
 */
export async function getSchoolInfoAuthApplyList({ page, limit, filter = {} }) {
  return bget({
    url: "/apply/getList",
    query: {
      type: TYPE["schoolInfoAuth"],
      page,
      limit,
      filter,
    },
  });
}
/**
 * 获取基本信息认证申请列表
 */
export async function getEssentialInformationAuthApplyList({ page, limit, filter = {} }) {
  return bget({
    url: "/apply/getList",
    query: {
      type: TYPE["essentialInformationAuth"],
      page,
      limit,
      filter,
    },
  });
}
export async function sendAuditMsg({ applyId, msg }) {
  return bpost({
    url: "/apply/sendAuditMsg",
    data: {
      applyId,
      msg,
    },
  });
}
/**
 * 获取合并认证状态
 */
export async function getMergeInfoApplyStatus() {
  return bget({
    url: "/user/getIdRealNameApplyStatus",
  });
}

/**
 * 获取实名认证状态
 */
export async function getIdRealNameApplyStatus() {
  return bget({
    url: "/user/getIdRealNameApplyStatus",
  });
}
/**
 * 获取校园信息认证状态
 */
export async function getSchoolInfoApplyStatus() {
  return bget({
    url: "/user/getSchoolInfoApplyStatus",
  });
}
/**
 * 获取主体申请状态
 */
export async function getSubjectApplyStatus() {
  return bget({
    url: "/user/getSubjectApplyStatus",
  });
}
/**
 * 获取申请详情
 */
export async function getApplyDetail({ id }) {
  return bget({
    url: "/apply/getDetail",
    query: {
      id,
    },
  });
}
/**
 * 通过申请
 */
export async function passStep({ applyId }) {
  return bpost({
    url: "/apply/passStep",
    data: {
      applyId,
    },
  });
}
/**
 * 拒绝申请
 */
export async function rejectStep({ applyId }) {
  return bpost({
    url: "/apply/rejectStep",
    data: {
      applyId,
    },
  });
}
export default {
  passStep,
  rejectStep,
  getApplyDetail,
  getIdRealNameApplyStatus,
  getSchoolInfoApplyStatus,
  getEssentialInformationAuthApplyList,
  getSubjectApplyStatus,
  sendAuditMsg,
  getAllApplyList,
  getRealNameAuthApplyList,
  getCreateProjectApplyList,
  getCreateProjectApplyDetail,
  getCompanyEnterApplyList,
  getConferenceRoomRentApplyList,
  getCoffeeHouseRentApplyList,
  getMealCardApplyList,
  getParkingPermitApplyList,
  getRecruitApplyList,
  getSchoolInfoAuthApplyList,
  getMergeInfoApplyStatus
};
