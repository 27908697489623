import React, { Component, } from 'react';
import { Switch, } from 'react-router-dom';
import AdminAuthRoute from '../../../utils/AdminAuth';
import OprationList from "../../../pages/admin/operation/List";
import AddOpration from "../../../pages/admin/operation/Add";
import EditOpration from "../../../pages/admin/operation/Edit";
import OprationGroupList from "../../../pages/admin/operation/GroupList";
import AddOprationGroup from "../../../pages/admin/operation/AddGroup";
import EditOprationGroup from "../../../pages/admin/operation/EditGroup";
export default class Wrap extends Component {
  render() {
    return (
      <Switch>
        <AdminAuthRoute exact path="/admin/operation/list" component={OprationList} />
        <AdminAuthRoute exact path="/admin/operation/add" component={AddOpration} />
        <AdminAuthRoute exact path="/admin/operation/edit" component={EditOpration} />
        <AdminAuthRoute exact path="/admin/operation/groupList" component={OprationGroupList} />
        <AdminAuthRoute exact path="/admin/operation/addGroup" component={AddOprationGroup} />
        <AdminAuthRoute exact path="/admin/operation/editGroup" component={EditOprationGroup} />
      </Switch>
    );
  }

}
