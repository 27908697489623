import React, { Component } from "react"
import {
  Table,
  Button,
  Breadcrumb,
  Input,
  Icon,
  Menu,
  Dropdown,
  Popconfirm,
  Tooltip,
  Modal,
  message,

  Form,
  Select,
  DatePicker,
  // Cascader,
  // InputNumber,
  // Slider,
  Switch,
  Radio,
} from "antd"
import { TYPE, TYPE_ZH } from "../../../utils/constant"
import { TYPE as USER_TYPE, TYPE_ZH as USER_TYPE_ZH } from "../../../services/user"
import { trim } from "jsbdk"
import { Link, NavLink } from "react-router-dom"
import moment from "moment"
import user from "../../../services/user"
import { BASE_URL } from "../../../config/prod"
import { getRegion } from "../../../utils/utils"
import gSass from "../../../utils/sass"
import gImg from "../../../utils/img"

const style = gSass.admin.user.list
const Search = Input.Search
const Option = Select.Option
const RangePicker = DatePicker.RangePicker
const RadioGroup = Radio.Group

class UserList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hasFilter: false,
      tableIsLoading: true,
      dateSelect: TYPE.before,
      ageSelect: TYPE.undefined,
      minAgeBetween: 0,
      maxAgeBetween: 120,
      region: null,
      filteredInfo: null,
      sortedInfo: null,
      list: [],
      selectedRows: [],
      pagination: {
        current: 1,
        pageSize: 8,
        showTotal: total => {
          return `共${total}条记录`
        },
        onChange: async (page, pageSize) => {
          const pagination = this.state.pagination
          pagination.current = page
          pagination.pageSize = pageSize
          this.setState(
            {
              pagination,
            },
            this.getUserList,
          )
        },
      },
      page: 1,
      limit: 8,
      filter: {
        search: "",
      },
      defaultAvatar: gImg.user.defaultAvatar,
      typeChildren: [
        {
          value: USER_TYPE.createProject,
          label: USER_TYPE_ZH[USER_TYPE.createProject],
        },
        {
          value: USER_TYPE.companyEnter,
          label: USER_TYPE_ZH[USER_TYPE.companyEnter],
        },
        {
          value: USER_TYPE.conferenceRoomRent,
          label: USER_TYPE_ZH[USER_TYPE.conferenceRoomRent],
        },
        {
          value: USER_TYPE.coffeeHouseRent,
          label: USER_TYPE_ZH[USER_TYPE.coffeeHouseRent],
        },
        {
          value: USER_TYPE.mealCard,
          label: USER_TYPE_ZH[USER_TYPE.mealCard],
        },
        {
          value: USER_TYPE.parkingPermit,
          label: USER_TYPE_ZH[USER_TYPE.parkingPermit],
        },
        {
          value: USER_TYPE.recruit,
          label: USER_TYPE_ZH[USER_TYPE.recruit],
        },
        {
          value: USER_TYPE.realNameAuth,
          label: USER_TYPE_ZH[USER_TYPE.realNameAuth],
        },
        {
          value: USER_TYPE.schoolInfoAuth,
          label: USER_TYPE_ZH[USER_TYPE.schoolInfoAuth],
        },
      ],
    }
  }

  componentDidMount() {
    this.init()
  }

  init = async () => {
    this.getUserList()
    let region = await getRegion()
    this.setState({
      region,
    })
  }
  getUserList = async _ => {
    const pagination = this.state.pagination
    try {
      // 注释掉的东西不能删
      let page = pagination.current,
        limit = pagination.pageSize,
        filter = this.state.filter
      this.setState({
        tableIsLoading: true,
      })
      let json = await user.getList(page, limit, filter)
      pagination.total = json.count
      this.setState({
        tableIsLoading: false,
        list: json.data.list,
        pagination,
      })
    } catch (err) {
      console.log(err)
      this.setState({
        tableIsLoading: false,
      })
    }
  }
  handleChange = (pagination, filters, sorter) => {
    //筛选(排序,页码)
    console.log("Various parameters", pagination, filters, sorter)
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    })
  }

  setAgeSort = _ => {
    this.setState({
      sortedInfo: {
        order: "descend",
        columnKey: "age",
      },
    })
  }
  //编辑
  handleEdit = key => {
    console.log(key)
  }
  //删除
  deleteUser = idArr => {
    const list = this.state.list,
      selectedRows = this.state.selectedRows
    user
      .deleteUsers(idArr)
      .then(json => {
        message.success("删除成功", 1)
        this.setState({
          list: list.filter(item => !idArr.includes(item.id)),
          selectedRows: selectedRows.filter(item => !idArr.includes(item.id)),
        })
      })
      .catch(err => {
        message.error("删除失败,错误原因: " + err.msg, 3)
      })
  }

  //筛选
  onFilter = evt => {
    evt.preventDefault()
    this.setState({
      tableIsLoading: true,
    })
    this.props.form.validateFields((err, fields) => {
      let administrators = {
        condition: TYPE.eq,
        value: fields.administrators,
      },
        date = {
          condition: parseInt(fields.dateCondition),
          value: this.state.dateSelect === TYPE.before ? (fields.dateBeformVal ?
            moment(fields.dateBeformVal).format("YYYY-MM-DD HH:mm:ss") : "") :
            this.state.dateSelect === TYPE.betweenTime ? (fields.dateBetweenVal.length > 0 ? {
              min: fields.dateBetweenVal ?
                (fields.dateBetweenVal[0] ? moment(fields.dateBetweenVal[0]).format("YYYY-MM-DD HH:mm:ss") : "") : "",
              max: fields.dateBetweenVal ?
                (fields.dateBetweenVal[1] ? moment(fields.dateBetweenVal[1]).format("YYYY-MM-DD HH:mm:ss") : "") : "",
            } : ""
            ) :
              fields.dateAfterVal ? moment(fields.dateAfterVal).format("YYYY-MM-DD HH:mm:ss") : "",
        }
      console.log({
        administrators,
        date
      })
      //自己写接口啊
      // message.info("没写接口呢, 你自己加啊", 1)
      user.getFilterList({ administrators, date })
        .then(json => {
          message.success("正在筛选, 请稍等", 1)
          this.setState({
            list: json.data,
          })
        })
        .catch(err => {
          message.success("筛选失败, 请稍后重试", 1)
          console.log(err)
        })
      //关闭模态框
      this.setState({
        hasFilter: false,
        tableIsLoading: false,
      })
    })
  }
  render() {
    const { getFieldDecorator } = this.props.form
    let { filteredInfo } = this.state
    filteredInfo = filteredInfo || {}
    const columns = [
      {
        title: "ID",
        dataIndex: "id",
      },
      {
        title: "账号",
        dataIndex: "account",
      },
      {
        title: "昵称",
        dataIndex: "nick",
        render: (text, record, index) => {
          return (
            <Tooltip
              placement="right"
              title={_ => {
                return (
                  <div>
                    <div>{text}</div>
                    <div>{text}</div>
                  </div>
                )
              }}>
              <span style={{ color: "#1890ff" }}>{text}</span>
            </Tooltip>
          )
        },
      },
      {
        title: "性别",
        dataIndex: "gender",
        filters: [
          { text: "男", value: "1 " },
          { text: "女", value: "2" },
          { text: "未知", value: "0" },
        ],
        filteredValue: filteredInfo.gender || null,
        onFilter: (val, record) => record.gender === parseInt(val),
      },
      {
        title: "头像",
        dataIndex: "avatar",
        render: avt => {
          return (
            <div
              className={style.avatarBox}
              onClick={_ => {
                Modal.info({
                  title: "头像查看",
                  maskClosable: true,
                  okText: "关闭",
                  content: (
                    <div>
                      <img
                        style={{ width: 270 }}
                        src={avt ? BASE_URL + avt.url : this.state.defaultAvatar}
                        alt="头像"
                      />
                    </div>
                  ),
                })
              }}>
              <img
                className={style.avatar}
                src={avt ? BASE_URL + avt.url : this.state.defaultAvatar}
                alt="头像"
              />
            </div>
          )
        },
      },
      {
        title: "手机号码",
        dataIndex: "phone",
      },
      {
        title: "是否为管理员",
        dataIndex: "isAdmin",
        render: isAdmin => {
          return <Switch checkedChildren="是" unCheckedChildren="否" checked={isAdmin} disabled />
        },
      },
      {
        title: "邮箱",
        dataIndex: "email",
      },
      {
        title: "地址",
        dataIndex: "address['whole']",
        render: wholeAddress => {
          return wholeAddress ? (
            <div>
              <div style={{ marginBottom: 10 }}>{wholeAddress.substr(0, 10)}...</div>
              <div
                className={style.btn}
                onClick={_ => {
                  Modal.info({
                    title: "详细地址",
                    okText: "关闭",
                    maskClosable: true,
                    content: <div>{wholeAddress}</div>,
                  })
                }}>
                更多
              </div>
            </div>
          ) : (
              <div>-</div>
            )
        },
      },
      {
        title: "注册时间",
        dataIndex: "ctime",
        render: text => {
          return <div>{text.substr(0, 10)}</div>
        },
      },
      {
        title: "操作",
        dataIndex: "operation",
        width: 300,
        render: (text, record) =>
          this.state.list.length >= 1 ? (
            <div>
              <Link className={style.btn} to={"/admin/user/detail?id=" + record.id}>
                查看
              </Link>
              <div className="separate-line" />
              <span className={style.btn}>
                <Popconfirm
                  title={<div>你确定要删除用户{record.account}吗?</div>}
                  onConfirm={_ => this.deleteUser([record.id])}>
                  <span>删除</span>
                </Popconfirm>
              </span>
            </div>
          ) : null,
      },
    ]
    return (
      <div className={style.userList}>
        <Breadcrumb className={style.title}>
          <Breadcrumb.Item>
            <Link to="/admin">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/admin/user/list">用户管理</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>用户列表</Breadcrumb.Item>
        </Breadcrumb>
        <div className={style.filters}>
          <div className={style.item}>
            <NavLink to="/admin/user/add">
              <Button type="primary" icon="plus">
                新增
              </Button>
            </NavLink>
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item>
                    <div
                      onClick={_ => {
                        let idArr = []
                        for (let v of this.state.selectedRows) {
                          idArr.push(v.id)
                        }
                        if (idArr.length < 1) {
                          return message.error("请选择要删除的用户", 2)
                        }
                        this.deleteUser(idArr)
                      }}>
                      删除
                    </div>
                  </Menu.Item>
                </Menu>
              }
              className={style.drown}
              placement="bottomLeft">
              <Button>
                更多操作 <Icon type="down" />
              </Button>
            </Dropdown>
            <Button
              className={style.filterBtn}
              type="primary"
              icon="filter"
              onClick={_ => {
                this.setState({
                  hasFilter: true,
                })
              }}>
              筛选
            </Button>
          </div>
          <Search
            placeholder="请输入账号|手机号|邮箱..."
            enterButton="搜索"
            onSearch={val => {
              let page = this.state.page
              let filter = this.state.filter
              page = 1
              filter.search = trim(val)
              this.setState(
                {
                  page,
                  filter,
                },
                _ => {
                  user
                    .getList(this.state.page, this.state.limit, this.state.filter)
                    .then(json => {
                      this.setState({
                        list: json.data.list,
                      })
                    })
                    .catch(err => {
                      message.error("获取用户列表失败 " + err.msg, 3)
                    })
                },
              )
            }}
            className={style.search}
          />
        </div>
        <Table
          className={style.table}
          columns={columns}
          dataSource={this.state.list}
          onChange={this.handleChange}
          rowKey="id"
          loading={this.state.tableIsLoading}
          pagination={this.state.pagination}
          rowSelection={{
            onChange: (selectedRowKeys, selectedRows) => {
              this.setState({
                selectedRows,
              })
            },
          }}
        />
        <Modal
          title="筛选"
          visible={this.state.hasFilter}
          okText="确认"
          cancelText="取消"
          width={600}
          onCancel={_ => {
            this.setState({ hasFilter: false })
          }}
          onOk={this.onFilter}>
          <Form className={style.formList}>
            <div className={style.item}>
              <div className={style.title}>管理员</div>
              {getFieldDecorator("administrators", { initialValue: -1 })(
                <RadioGroup>
                  <Radio value={-1}>全部</Radio>
                  <Radio value={0}>是</Radio>
                  <Radio value={1}>否</Radio>
                </RadioGroup>,
              )}
            </div>
            <div className={style.item}>
              <div className={style.title}>注册时间</div>
              {getFieldDecorator('dateCondition', { initialValue: TYPE.undefined })(
                <Select className={style.condition} onChange={val => { this.setState({ dateSelect: val, }); }}
                >
                  <Option value={TYPE.undefined}>请选择</Option>
                  <Option value={TYPE.before}>{TYPE_ZH[TYPE.before]}</Option>
                  <Option value={TYPE.after}>{TYPE_ZH[TYPE.after]}</Option>
                  <Option value={TYPE.betweenTime}>{TYPE_ZH[TYPE.betweenTime]}</Option>
                </Select>
              )}
              {getFieldDecorator('dateBeformVal', {})(
                <DatePicker format={"YYYY-MM-DD"} className={this.state.dateSelect === TYPE.before ? style.input : style.hidden} />
              )}
              {getFieldDecorator('dateBetweenVal', {})(
                <RangePicker format={"YYYY-MM-DD"} className={this.state.dateSelect === TYPE.betweenTime ? style.input : style.hidden} />
              )}
              {getFieldDecorator('dateAfterVal', {})(
                <DatePicker format={"YYYY-MM-DD"} className={this.state.dateSelect === TYPE.after ? style.input : style.hidden} />
              )}
            </div>
          </Form>
        </Modal>
      </div>
    )
  }
}
export default Form.create()(UserList)
