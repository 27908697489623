import appAction from '../constants/app';
const initState = {
    admin: {
        isLogin: false,
    },
}

function adminLogin(state = initState, action) {
    if (action.type === appAction.ADMIN_LOGIN) {
        let newState = Object.assign({}, state);
        newState.admin.isLogin = true;
        return newState;
    }
    return state;
}
function adminLogout(state = initState, action) {
    if (action.type === appAction.ADMIN_LOGOUT) {
        let newState = Object.assign({}, state);
        newState.admin.isLogin = false;
        return newState;
    }
    return state;
}

export default function reducer(state = initState, action) {
    switch (action.type) {
        case appAction.ADMIN_LOGIN:
            return adminLogin(state, action)
        case appAction.ADMIN_LOGOUT:
            return adminLogout(state, action)
        default:
            break;
    };
    return state;
}


