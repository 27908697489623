import React from "react";
import { Route, Link, } from "react-router-dom";
import {
  Form, Menu,
} from 'antd';
import { getUploadImgUrl } from '../../../services/api';
import CompanyBaseInfo from "./BaseInfo";
import CompanyLegalPerson from "./LegalPerson";
import CompanyMealCard from "./MealCard";
import ParkingPermit from "./ParkingPermit";
import Management from "./Management"
import gSass from '../../../utils/sass';
import qs from "qs";

const style = gSass.admin.company.base;

class Base extends React.Component {
  constructor(props) {
    super(props);
    const companyInfo = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    let menuKey = 1;
    switch (props.location.pathname) {
      case "/admin/company/info/baseInfo":
        menuKey = 1;
        break;
      case "/admin/company/info/leagelPerson":
        menuKey = 2;
        break;
      case "/admin/company/info/mealCard":
        menuKey = 3;
        break;
      case "/admin/company/info/ParkingPermit":
        menuKey = 4;
        break;
      case "/admin/company/info/Management":
        menuKey = 5;
        break;
      default:
        menuKey = 1;
        break;
    }
    this.state = {
      companyId: companyInfo.id,
      previewVisible: false,
      previewImage: '',
      fileList: [],
      uploadUrl: getUploadImgUrl,
      menuKey,
    }
  }
  componentDidMount() {

  }
  menuOnSelect = evt => {
    this.setState({
      menuKey: parseInt(evt.key),
    })
  }
  render() {
    return (
      <div className={style.base}>
        <div className={style.baseBox}>
          <div className={style.left}>
            <Menu mode="inline" defaultSelectedKeys={["" + this.state.menuKey]}
              onSelect={this.menuOnSelect}>
              <Menu.Item key="1">
                <Link to={`/admin/company/info/baseInfo?id=${this.state.companyId}`}><span>基本信息</span></Link>
              </Menu.Item>
              <Menu.Item key="2">
                <Link to={`/admin/company/info/leagelPerson?id=${this.state.companyId}`}><span>法人信息</span></Link>
              </Menu.Item>
              <Menu.Item key="3">
                <Link to={`/admin/company/info/mealCard?id=${this.state.companyId}`}><span>饭卡</span></Link>
              </Menu.Item>
              <Menu.Item key="4">
                <Link to={`/admin/company/info/ParkingPermit?id=${this.state.companyId}`}><span>停车证</span></Link>
              </Menu.Item>
              <Menu.Item key="4">
                <Link to={`/admin/company/info/Management?id=${this.state.companyId}`}><span>管理信息</span></Link>
              </Menu.Item>
            </Menu>
          </div>
          <div className={style.right}>
            <Route path={`/admin/company/info/baseInfo`} component={CompanyBaseInfo} />
            <Route path={`/admin/company/info/leagelPerson`} component={CompanyLegalPerson} />
            <Route path={`/admin/company/info/mealCard`} component={CompanyMealCard} />
            <Route path={`/admin/company/info/ParkingPermit`} component={ParkingPermit} />
            <Route path={`/admin/company/info/Management`} component={Management} />
          </div>
        </div>
      </div >
    );
  }
}

export default Form.create()(Base);

