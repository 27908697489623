import { menuList } from '../config/menuList';
import { BASE_URL, antdUploadImgAction, } from "../config/prod";
import { request as BuffReq, LocalStorage as storage } from 'jsbdk';
import qs from 'qs';

export const getMenuList = _ => {
  return menuList;
}
export const getUploadImgUrl = _ => {
  return antdUploadImgAction;
}
const updateSessionTime = async _ => {
  const adminIsLogin = storage.get('adminIsLogin')
  const userIsLogin = storage.get('userIsLogin')
  if (adminIsLogin === true) {
    storage.set('adminIsLogin', true, 15 * 60)
  }
  if (userIsLogin === true) {
    storage.set('userIsLogin', true, 15 * 60)
  }
  if (storage.has('uid') && (userIsLogin || adminIsLogin)) {
    storage.set('uid', storage.get('uid'), 15 * 60)
  }
  if (storage.has('nick') && (userIsLogin || adminIsLogin)) {
    storage.set('nick', storage.get('nick'), 15 * 60)
  }
}
export async function request({ url, body = {}, method = 'POST' }) {
  updateSessionTime();
  return BuffReq(BASE_URL, url, body, method)
}
export async function bget({ url, query = {} }) {
  updateSessionTime();
  return BuffReq(BASE_URL, url + '?' + qs.stringify(query), null, 'GET')
}
export async function bpost({ url, data = {} }) {
  updateSessionTime();
  return BuffReq(BASE_URL, url, data)
}
export async function sendForgetPwdVerifyCode(phone) {
  return request({ url: "/index/ForgerPassword/verificationCode", body: { phone: phone }, })
}
export async function checkForgetPwdVerificationCode(body) {
  return request({ url: "/index/ForgerPassword/checkVerificationCode", body, })
}
export async function submitPwd(body) {
  return request({ url: "/index/ForgerPassword/submitPwd", body, })
}
export async function getverificationCode(body) {
  return request({ url: "/index/login/getverificationCode", body, })
}
export async function getRegion() {
  if (storage.has('region')) {
    return { data: { region: storage.get('region') } };
  }
  return bget({ url: "/getRegion", })
}
export async function antdUploadImg(formData) {
  return bpost({
    url: '/antdUploadImg', data: formData,
  })
}
export default {
  sendForgetPwdVerifyCode,
  checkForgetPwdVerificationCode,
  submitPwd,
  getverificationCode,
  getUploadImgUrl,
  getRegion,
  antdUploadImg,
}
