import React, { Component } from "react";
import { Table, Switch, Input, Popconfirm, Form, message, Button, Modal, DatePicker } from "antd";
import qs from "qs";
import moment from "moment";
import { TYPE } from "../../../utils/constant";
import { PAYMENT_STATUS } from "../../../services/mealCard";
import gSass from "../../../utils/sass";
import mealCardApi from "../../../services/mealCard";
import companyApi from "../../../services/company";
import { IdentityCodeValid } from "../../../utils/utils";

const style = gSass.admin.company.mealCardList;
const { RangePicker } = DatePicker;

class CompanyMealCard extends Component {
  constructor(props) {
    super(props);
    let { id } = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    id = parseInt(id);
    this.state = {
      companyId: id,
      hasFilter: false,
      tableLoding: true,
      addCompanyMealCard: false,
      dateSelect: TYPE.undefined,
      list: [],
      selectedRows: [],
      filter: {
        id,
      },
    };
  }

  componentDidMount() {
    this.init();
  }

  init = async _ => {
    try {
      const {
        data: { list },
      } = await companyApi.getMealCardList({ page: -1, limit: -1, filter: this.state.filter });
      this.setState({
        tableLoding: false,
        list,
      });
    } catch (e) {
      console.log(e);
      this.setState({
        tableLoding: false,
      });
      message.error("获取饭卡列表失败");
    }
  };

  //删除
  deleteCompany = idArr => {
    // const list = this.state.list,
    //   selectedRows = this.state.selectedRows;
    // user.deleteUsers(idArr).then(json => {
    //   message.success('删除成功', 1)
    //   this.setState({
    //     list: list.filter(item => !idArr.includes(item.id)),
    //     selectedRows: selectedRows.filter(item => !idArr.includes(item.id))
    //   });
    // }).catch(err => {
    //   message.error("删除失败,错误原因: " + err.msg, 3)
    // })
  };
  //修改押金缴纳状态
  changePaymentStatus = (idx, val) => {
    let list = this.state.list;
    list[idx].paymentStatus =
      val === PAYMENT_STATUS.FALSE ? PAYMENT_STATUS.TRUE : PAYMENT_STATUS.FALSE;
    list[idx].validTime.begin = "";
    list[idx].validTime.expire = "";
    list[idx].cardNumber = "";
    this.setState({
      list,
    });
    if (list[idx].paymentStatus === PAYMENT_STATUS.TRUE) {
      message.success("请输入卡号、使用期限", 2);
    }
  };

  //提交押金缴纳情况
  setValidTime = idx => {
    let list = this.state.list;
    console.log({
      id: list[idx].id,
      paymentStatus: PAYMENT_STATUS.TRUE,
      cardNumber: list[idx].cardNumber,
      validTime: {
        begin: list[idx].validTime.begin,
        expire: list[idx].validTime.expire,
      },
    });
    mealCardApi.setValidTime({
      id: list[idx].id,
      paymentStatus: PAYMENT_STATUS.TRUE,
      cardNumber: list[idx].cardNumber,
      validTime: {
        begin: list[idx].validTime.begin,
        expire: list[idx].validTime.expire,
      },
    });
  };
  //验证身份证号码
  validateIdCardNo = (rule, val, cb) => {
    const { getFieldValue } = this.props.form;
    IdentityCodeValid(getFieldValue("idCardNo")).then(result => {
      if (!result.pass) {
        cb(result.msg);
      }
      cb();
    });
  };
  //添加饭卡
  addCompanyMealCard = evt => {
    evt.preventDefault();
    this.props.form.validateFields(
      ["name", "contactInfo", "idCardNo", "cardNo", "validTime"],
      (err, Fields) => {
        if (err) {
          return;
        }
        console.log({
          companyId: this.state.companyId,
          name: Fields.name,
          contactInfo: Fields.contactInfo,
          idCardNo: Fields.idCardNo,
          cardNo: Fields.cardNo,
          userTime: {
            begin: moment(Fields.validTime[0]).format("YYYY-MM-DD 00:00:00"),
            end: moment(Fields.validTime[1]).format("YYYY-MM-DD 00:00:00"),
          },
        });
        return;
      },
    );
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        sm: {
          span: 6,
          offset: 0,
        },
      },
      wrapperCol: {
        sm: {
          span: 16,
          offset: 0,
        },
      },
    };
    const columns = [
      {
        title: "ID",
        dataIndex: "id",
      },
      {
        title: "姓名",
        dataIndex: "name",
      },
      {
        title: "是否已缴纳20元押金",
        dataIndex: "paymentStatus",
        render: (val, row, record) => {
          return (
            <Popconfirm
              title={<div>你确定要修改用户{row.id}的押金缴纳状态吗?</div>}
              onConfirm={_ => this.changePaymentStatus(record, val)}>
              <Switch checked={val === PAYMENT_STATUS.TRUE ? true : false} />
            </Popconfirm>
          );
        },
      },
      {
        title: "卡号",
        dataIndex: "cardNumber",
        render: (val, row, record) => {
          return (
            <div>
              {row.paymentStatus === PAYMENT_STATUS.TRUE ? (
                <Input
                  placeholder="请输入"
                  className={style.timeInput}
                  defaultValue={val}
                  autoFocus={val === ""}
                  onChange={evt => {
                    let list = this.state.list;
                    list[record].cardNumber = evt.target.value;
                    this.setState({
                      list,
                    });
                  }}
                  onBlur={_ => {
                    let list = this.state.list;
                    if (list[record].validTime.expire !== "") {
                      if (list[record].validTime.begin !== "") {
                        if (list[record].cardNumber !== "") {
                          this.setValidTime(record);
                        }
                      }
                    }
                  }}
                />
              ) : (
                <div style={{ textAlign: "center" }}>-</div>
              )}
            </div>
          );
        },
      },
      {
        title: "使用期限",
        width: 300,
        dataIndex: "validTime",
        render: (validTime, row, record) => {
          let beginTime = "",
            expireTime = "";
          if (validTime.begin !== "") {
            beginTime = validTime.begin.substr(0, 10);
          }
          if (validTime.expire !== "") {
            expireTime = validTime.expire.substr(0, 10);
          }
          return (
            <div>
              {row.paymentStatus === PAYMENT_STATUS.TRUE ? (
                <div className={style.rowTime}>
                  <Input
                    placeholder="例: 2019-01-01"
                    className={style.timeInput}
                    defaultValue={beginTime}
                    onChange={evt => {
                      let list = this.state.list;
                      list[record].validTime.begin = evt.target.value + " 00:00:00";
                      this.setState({
                        list,
                      });
                    }}
                    onBlur={_ => {
                      let list = this.state.list;
                      if (list[record].validTime.expire !== "") {
                        if (list[record].validTime.begin !== "") {
                          if (list[record].cardNumber !== "") {
                            this.setValidTime(record);
                          }
                        }
                      }
                    }}
                  />
                  -
                  <Input
                    placeholder="例: 2029-01-01"
                    className={style.timeInput}
                    defaultValue={expireTime}
                    onChange={evt => {
                      let list = this.state.list;
                      list[record].validTime.expire = evt.target.value + " 00:00:00";
                      this.setState({
                        list,
                      });
                    }}
                    onBlur={_ => {
                      let list = this.state.list;
                      if (list[record].validTime.expire !== "") {
                        if (list[record].validTime.begin !== "") {
                          if (list[record].cardNumber !== "") {
                            this.setValidTime(record);
                          }
                        }
                      }
                    }}
                  />
                </div>
              ) : (
                <div style={{ textAlign: "center" }}> - </div>
              )}
            </div>
          );
        },
      },

      {
        title: "操作",
        dataIndex: "operation",
        width: 300,
        render: (text, record) => (
          <div>
            <span className={style.btn}>
              <Popconfirm
                title={<div>你确定要删除用户{record.id}吗?</div>}
                onConfirm={_ => this.deleteCompany([record.id])}>
                <span>删除</span>
              </Popconfirm>
            </span>
          </div>
        ),
      },
    ];
    return (
      <div className={style.companyList}>
        <div className={style.prompt}>
          修改用户押金缴纳情况时：只有卡号、使用期限格式( 例如: 2019-01-10
          )正确，并且后一个时间大于前一个时间时，才会保存数据和状态； <br />
          如果只修改押金缴纳状态不填写使用期限，页面刷新时，数据、缴纳状态恢复原来的数据和状态。
        </div>
        <div className={style.filters}>
          <Button
            type="primary"
            icon="plus"
            onClick={_ => {
              this.setState({
                addCompanyMealCard: true,
              });
            }}>
            新增
          </Button>
        </div>
        <Table
          className={style.table}
          columns={columns}
          dataSource={this.state.list}
          rowKey="id"
          loading={this.state.tableLoding}
          pagination={false}
        />
        <Modal
          title="新增饭卡"
          centered
          visible={this.state.addCompanyMealCard}
          okText="提交"
          onOk={this.addCompanyMealCard}
          onCancel={_ => {
            this.setState({
              addCompanyMealCard: false,
            });
          }}>
          <Form className={style.form}>
            <Form.Item {...formItemLayout} label="姓名">
              {getFieldDecorator("name", {
                rules: [{ required: true, message: "请输入姓名" }],
              })(<Input size="large" placeholder="请输入" />)}
            </Form.Item>
            <Form.Item {...formItemLayout} label="手机号码">
              {getFieldDecorator("contactInfo", {
                rules: [{ required: true, message: "手机号码" }],
              })(<Input size="large" placeholder="请输入" />)}
            </Form.Item>
            <Form.Item {...formItemLayout} label="身份证号码">
              {getFieldDecorator("idCardNo", {
                rules: [
                  { required: true, message: "请输入身份证号码" },
                  { validator: this.validateIdCardNo },
                ],
              })(<Input size="large" placeholder="请输入" />)}
            </Form.Item>
            <Form.Item {...formItemLayout} label="饭卡卡号">
              {getFieldDecorator("cardNo", {
                rules: [{ required: true, message: "请输入饭卡卡号" }],
              })(<Input size="large" placeholder="请输入" />)}
            </Form.Item>
            <Form.Item {...formItemLayout} label="使用期限">
              {getFieldDecorator("validTime", {
                rules: [{ required: true, message: "请输入使用期限" }],
              })(<RangePicker />)}
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}
export default Form.create()(CompanyMealCard);
