export default {
  common: {
    adminLogo: require('../assets/images/common/admin-logo.png'),
    defaultAvatar: require('../assets/images/common/avatar.png'),
    adminCollapsedLogo: require('../assets/images/common/collapsed_logo.png'),
    cat: require('../assets/images/common/cats.png'),
    icon_1: require('../assets/images/common/icon_1.png'),
    icon_2: require('../assets/images/common/icon_2.png'),
    icon_3: require('../assets/images/common/icon_3.png'),
    banner: require('../assets/images/common/banner.png'),
    idCardDefaultPic: require('../assets/images/common/id_card_default.png'),
  },
  user: {
    defaultAvatar: require('../assets/images/user/default_avatar.jpg'),
  },
  login: {
    loginLogo: require("../assets/images/common/logo.png"),
  },
  index: {
    apply: {
      applyCompany: require("../assets/images/index/apply/apply_company.png"),
      companyRecord: require("../assets/images/index/apply/company_record.png"),
      applyConferenceRoomRent: require("../assets/images/index/apply/apply_conference_room_rent.png"),
      conferenceRoomRentRecord: require("../assets/images/index/apply/conference_room_rent_record.png"),
      applyCoffeeHouseRent: require("../assets/images/index/apply/apply_coffee_house_rent.png"),
      coffeeHouseRentApply: require("../assets/images/index/apply/coffee_house_rent_apply.png"),
      applyMealCard: require("../assets/images/index/apply/apply_meal_card.png"),
      mealCcardRecord: require("../assets/images/index/apply/meal_card_record.png"),
      applyParkingPermit: require("../assets/images/index/apply/apply_parking_permit.png"),
      parkingPermitRecord: require("../assets/images/index/apply/parking_permit_record.png"),
      applyRecruit: require("../assets/images/index/apply/apply_recruit.png"),
      recruitRecord: require("../assets/images/index/apply/recruit_record.png"),
      applyRecordBanner: require("../assets/images/index/apply/applyRecordBanner.jpg"),
    },
    banner1: require("../assets/images/index/banner1.jpg"),
    banner2: require("../assets/images/index/banner2.jpg"),
  },
}

