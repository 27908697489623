import React from "react";
import gSass from "../../../utils/sass";
import { Link, } from "react-router-dom";
import qs from "qs";
import {
  Breadcrumb,
  Form,
  Input,
  message,
  Spin,
  Button,
  Checkbox
} from "antd";
import applyApi from "../../../services/apply";
import recruit, { STATION, STATION_ZH } from "../../../services/recruit";
const style = gSass.admin.applyEdit.edit;
const { TextArea } = Input;
const formItemLayout = {
  labelCol: {
    lg: {
      span: 4,
      offset: 4,
    },
  },
  wrapperCol: {
    lg: {
      span: 8,
      offset: 0,
    },
  },
};


class Edit extends React.Component {
  constructor(props) {
    super(props);
    let { id } = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    id = parseInt(id);
    this.state = {
      projectId: id,
      spinning: true,
      industryList: [],
      patentTypeList: [],
      patentList: [],
      financingList: [],
      userInfo: {},
      idCardFirstPic: [],
      idCardSecondPic: [],
      previewVisible: false,
      previewImage: '',
      householdList: [],
      residentPic: [],
      providedItemStr: '',
      applyId: 0,
      infoId: 0,
      stationList: []
    };
  }
  componentDidMount() {
    this.init();
  }
  init = async _ => {
    try {
      // 获得服务器数据
      let { data: { detail, } } = await applyApi.getApplyDetail({ id: this.state.projectId });
      console.log(detail)
      let stationList = []
      for (let v in STATION) {
        stationList.push({
          value: STATION[v],
          label: STATION_ZH[STATION[v]],
          type: v,
          checked: false,
        })
      }
      console.log(stationList)
      for (let i = 0; i < stationList.length; i++) {
        for (let v of detail.stationList) {

          if (v === stationList[i].value) {
            stationList[i].checked = true
          }
        }
      }
      this.setState({
        applyId: detail.info.id,
        spinning: false,
        loading: false,
        stationList
      })
      this.props.form.setFieldsValue({
        contactInfo: detail.info.contactInfo,
        recruitPersonNumber: detail.info.recruitPersonNumber,
        maxSalary: detail.info.maxSalary / 100,
        minSalary: detail.info.minSalary / 100,
        description: detail.info.description,
      });
    } catch (e) {
      console.log(e);
      this.setState({
        spinning: false,
      });
    }
  };

  //提交
  updateUserInfo = evt => {
    evt.preventDefault();

    this.props.form.validateFields(["contactInfo", "recruitPersonNumber", "maxSalary", "minSalary", "station", "description",], (err, fields) => {
      if (err) {
        return message.error(err[Object.keys(err)[0]].errors[0].message, 1);
      }
      recruit
        .edit({
          applyId: this.state.applyId,
          contactInfo: fields.contactInfo,
          recruitPersonNumber: fields.recruitPersonNumber,
          maxSalary: fields.maxSalary * 100,
          minSalary: fields.minSalary * 100,
          stationList: this.state.stationList,
          description: fields.description,
        })
        .then(json => {
          message.success("修改成功", 1);
        })
        .catch(err => {
          message.error("修改失败,失败原因: " + err.msg, 1);
          console.log(err);
        });
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    //头像上传按钮
    return (
      <Spin tip="Loading..." spinning={this.state.spinning} size="large">
        <div className={style.info}>
          <Breadcrumb className={style.title}>
            <Breadcrumb.Item><Link to="/admin">首页</Link></Breadcrumb.Item>
            <Breadcrumb.Item><Link to="/admin/apply/allList">申请管理</Link></Breadcrumb.Item>
            <Breadcrumb.Item>招聘编辑</Breadcrumb.Item>
          </Breadcrumb>
          <div className={style.basicWrap}>
            <Form className={style.form}>
              <Form.Item {...formItemLayout} label="手机号码">
                {getFieldDecorator("contactInfo", {
                  rules: [{ required: true, message: "请输入手机号码" }],
                })(<Input placeholder="请输入" />)}
              </Form.Item>
              <Form.Item {...formItemLayout} label="招聘人数">
                {getFieldDecorator("recruitPersonNumber", {
                  rules: [{ required: true, message: "请输入招聘人数" }],
                })(<Input placeholder="请输入" />)}
              </Form.Item>
              <Form.Item {...formItemLayout} label="最低薪资( 元 )">
                {getFieldDecorator("minSalary", {
                  rules: [{ required: true, message: "请输入薪资( 元 )" }],
                })(<Input placeholder="请输入" />)}
              </Form.Item>
              <Form.Item {...formItemLayout} label="最高薪资( 元 )">
                {getFieldDecorator("maxSalary", {
                  rules: [{ required: true, message: "请输入薪资( 元 )" }],
                })(<Input placeholder="请输入" />)}
              </Form.Item>
              <Form.Item {...formItemLayout} label="招聘岗位">
                {this.state.stationList.map((v, k) => {
                  return (<div className={style.operationMethodItem} key={k}>
                    <Checkbox key={k}
                      onChange={_ => {
                        let stationList = this.state.stationList;
                        stationList[k].checked = !v.checked;
                        this.setState({
                          stationList,
                        })
                      }}
                      checked={v.checked}
                    >
                      {v.label}
                    </Checkbox>
                  </div>);
                })}
              </Form.Item>
              <Form.Item {...formItemLayout} label="岗位描述">
                {getFieldDecorator("description", {})(<TextArea rows={4} placeholder="请输入" />)}
              </Form.Item>
              <Form.Item wrapperCol={{
                xs: {
                  span: 7,
                  offset: 8,
                },
              }}>
                <Button className={style.btn} type="primary" onClick={this.updateUserInfo}>更新基本信息</Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Spin>
    );
  }
}
export default Form.create()(Edit);
