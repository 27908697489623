import React from "react";
import {
  Form, message, Upload, Icon, Modal, Spin, Select, Radio,
} from 'antd';
import qs from 'qs';
import { getUploadImgUrl } from '../../../services/api';
import collegeApi from '../../../services/college';
import schoolSpecialityApi from '../../../services/schoolSpeciality';
import { IS_GRADUATE, } from '../../../services/schoolInfo';
import userApi from "../../../services/user";
import { EDUCATION, EDUCATION_ZH, } from "../../../services/apply";
import gSass from '../../../utils/sass';
import { BASE_URL, } from '../../../config/prod';
const style = gSass.admin.settings.base;
const RadioGroup = Radio.Group;
const Option = Select.Option;
//地址
const uploadButton = (
  <div>
    <Icon type="plus" />
    <div className="ant-upload-text">上传图片</div>
  </div>
);
const formItemLayout = {
  labelCol: {
    lg: {
      span: 24,
      offset: 0,
    },
  },
  wrapperCol: {
    lg: {
      span: 24,
      offset: 0,
    },
  },
};
let date = new Date().getFullYear(), gradeList = [];
for (let i = 0; i <= (date - 1990); i++) {
  gradeList.push({ value: i, label: (1990 + i) })
}
class CampusInformation extends React.Component {
  constructor(props) {
    super(props);
    let { location: { search } } = props;
    const { id } = qs.parse(search, { ignoreQueryPrefix: true, })
    this.state = {
      id,
      spinning: true,
      previewVisible: false,
      previewImage: '',
      idCardNoValidation: true,
      collegeList: [],
      specialityList: [],
      educationList: [],
      grades: gradeList,
      isGraduate: null,//不能删除
      one_card_pass_pic: [],
      degree_pic: [],
      schoolInfo: {},
    }
  }
  componentDidMount() {
    this.init();
  }
  init = async _ => {
    const { setFieldsValue } = this.props.form;
    try {
      let { data: { list: college } } = await collegeApi.getList({ page: -1, limit: -1 })
      let collegeList = college.map(v => ({
        value: v.id,
        label: v.name,
      }))
      //获取学校专业列表
      let { data: { list: speciality } } = await schoolSpecialityApi.getList({ page: -1, limit: -1 })
      let specialityList = speciality.map(v => ({
        value: v.id,
        label: v.name,
      }))
      //获取学历信息
      let educationList = Object.values(EDUCATION).map((v, k) => ({
        value: v,
        label: EDUCATION_ZH[v],
      }))
      this.setState({
        spinning: false,
        collegeList,
        specialityList,
        educationList,
      })
      const { data: {
        collegeId,
        education,
        grade,
        isGraduate,
        specialityId,
        // ctime,
        degreePicModel,
        oneCardPassPicModel,
      } } = await userApi.adminGetUserSchoolInfo({ id: this.state.id, });
      let one_card_pass_pic = [], degree_pic = [];
      if (isGraduate === IS_GRADUATE.TRUE) {
        degree_pic = [{ url: BASE_URL + degreePicModel.url, uid: degreePicModel.id, }];
      } else {
        one_card_pass_pic = [{
          url: BASE_URL + oneCardPassPicModel.url,
          uid: oneCardPassPicModel.id,
        }];
      }
      this.setState({
        isGraduate,
      })
      this.setState({
        degree_pic,
        one_card_pass_pic,
      })
      setFieldsValue({
        college: collegeId,
        speciality: specialityId,
        grade,
        education,
        isGraduate,
      })
    } catch (e) {
      console.log(e)
    }

  }
  //提交
  updateUserIfno = evt => {
    evt.preventDefault();
    this.props.form.validateFields((err, fields) => {
      if (err !== null) {
        return message.error(err[0].errors[0].message, 3);
      }
      let name = fields.name,
        college = fields.college,
        speciality = fields.speciality,
        grade = fields.grade,
        education = fields.education,
        graduation = parseInt(fields.graduation),
        one_card_pass_pic = this.state.isGraduate === 0 ? fields.one_card_pass_pic : "",
        degree_pic = this.state.isGraduate === 1 ? fields.degree_pic : "";
      console.log({ name, college, speciality, grade, education, graduation, one_card_pass_pic, degree_pic, });

      message.info('自己写接口', 1);
      userApi.editCampushInformation({ name, college, speciality, grade, education, graduation, one_card_pass_pic, degree_pic, }).then(json => {
        message.success('修改成功', 1);
      }).catch(err => {
        message.error('修改失败,失败原因: ' + err.msg, 1);
        console.log(err);
      })
    });
  }
  //上传图片
  one_card_pass_picChange = ({ fileList }) => this.setState({ one_card_pass_pic: fileList })
  degree_picChange = ({ fileList }) => this.setState({ degree_pic: fileList })
  handlePreview = file => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true,
    });
  }
  handleCancel = _ => this.setState({ previewVisible: false })


  render() {
    const { getFieldDecorator } = this.props.form;
    const { one_card_pass_pic, previewVisible, previewImage, degree_pic, } = this.state;
    //头像上传按钮
    return (
      <Spin tip="Loading..." spinning={this.state.spinning} size="large">
        <div>
          <div className={style.title}>学校信息</div>
          <Form className={style.form}>
            {/* <Form.Item {...formItemLayout} label="姓名" >
              {getFieldDecorator('name', {
                rules: [{ required: true, message: '请输入姓名' }],
              })(<Input placeholder="请输入姓名" />)}
            </Form.Item> */}
            <Form.Item {...formItemLayout} label="学院"  >
              {getFieldDecorator('college', {

                rules: [{ required: true, message: '请选择学院' }],
              })(
                <Select placeholder="请选择学院">
                  {this.state.collegeList.map((v, k) => {
                    return (<Option key={`${k}`} value={v.value}>{v.label}</Option>);
                  })}
                </Select>
              )}
            </Form.Item>
            <Form.Item {...formItemLayout} label="专业"  >
              {getFieldDecorator('speciality', {
                rules: [{ required: true, message: '请选择专业' }],
              })(
                <Select placeholder="请选择专业">
                  {this.state.specialityList.map((v, k) => {
                    return (<Option key={`${k}`} value={v.value}>{v.label}</Option>);
                  })}
                </Select>
              )}
            </Form.Item>
            <Form.Item {...formItemLayout} label="年级"  >
              {getFieldDecorator('grade', {
                rules: [{ required: true, message: '请选择年级' }],
              })(
                <Select placeholder="请选择年级">
                  {this.state.grades.map((v, k) => {
                    return (<Option key={`${k}`} value={v.value}>{v.label}</Option>);
                  })}
                </Select>
              )}
            </Form.Item>
            <Form.Item {...formItemLayout} label="学历"  >
              {getFieldDecorator('education', {
                rules: [{ required: true, message: '请选择学历' }],
              })(
                <Select placeholder="请选择学历">
                  {this.state.educationList.map((v, k) => {
                    return (<Option key={`${k}`} value={v.value}>{v.label}</Option>);
                  })}
                </Select>
              )}
            </Form.Item>
            <Form.Item {...formItemLayout} label="是否为毕业生"  >
              {getFieldDecorator('isGraduate', {
                rules: [{ required: true, message: '请选择是否为毕业生' }],
              })(
                <RadioGroup onChange={evt => {
                  this.setState({
                    isGraduate: parseInt(evt.target.value),
                  })
                }}>
                  <Radio value={IS_GRADUATE.FALSE}>否</Radio>
                  <Radio value={IS_GRADUATE.TRUE}>是</Radio>
                </RadioGroup>
              )}
            </Form.Item>
            <Form.Item {...formItemLayout} label="一卡通证件照"
              className={this.state.isGraduate === IS_GRADUATE.FALSE ? "" : style.hidden}>
              {getFieldDecorator('one_card_pass_pic', {
              })(
                <div className="clearfix">
                  <Upload action={getUploadImgUrl()} listType="picture-card" fileList={one_card_pass_pic}
                    onPreview={this.handlePreview} onChange={this.one_card_pass_picChange}>
                    {one_card_pass_pic.length > 0 ? null : uploadButton}
                  </Upload>
                  <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                    <img alt="example" style={{ width: '100%' }} src={previewImage} />
                  </Modal>
                </div>
              )}
            </Form.Item>
            <Form.Item {...formItemLayout} label="学位证证件照"
              className={this.state.isGraduate === IS_GRADUATE.TRUE ? "" : style.hidden}>
              {getFieldDecorator('degree_pic', {
              })(
                <div className="clearfix">
                  <Upload action={getUploadImgUrl()} listType="picture-card" fileList={degree_pic}
                    onPreview={this.handlePreview} onChange={this.degree_picChange}>
                    {degree_pic.length > 0 ? null : uploadButton}
                  </Upload>
                  <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                    <img alt="example" style={{ width: '100%' }} src={previewImage} />
                  </Modal>
                </div>
              )}
            </Form.Item>
            {/* <Form.Item wrapperCol={{ lg: { span: 7, offset: 0, }, }}>
              <Button className={style.btn} type="primary" onClick={this.updateUserIfno}>更新基本信息</Button>
            </Form.Item> */}
          </Form>
        </div>
      </Spin >
    );
  }
}
export default Form.create()(CampusInformation);
