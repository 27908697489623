import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider, } from 'react-redux';
import { store } from './redux/stores/app';
import * as serviceWorker from './serviceWorker';
import { ConfigProvider } from 'antd';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
store.subscribe(_ => {
  let state = store.getState();
  console.log(state)
});
moment.locale('zh-cn');
ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider locale={zh_CN}><App /></ConfigProvider>
  </Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
