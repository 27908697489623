import { bget, bpost } from './api';

export async function apply({
  college, speciality, grade, education, isGraduate, isOverseas, degreePic, oneCardPassPic, seasEducation, seasSpeciality, seasCollege, seasGraduationTime, overseasCard,
}) {
  return bpost({
    url: '/apply/schoolInfoAuth',
    data: {
      college, speciality, grade, education, isGraduate, isOverseas, degreePic, oneCardPassPic, seasEducation, seasSpeciality, seasCollege, seasGraduationTime, overseasCard,
    }
  })
}

export async function detail(id) {
  return bget({
    url: '/mealCard/detail',
    query: {
      id,
    }
  })
}
export const IS_GRADUATE = {
  "UNDEFINED": 0,
  "TRUE": 1,
  "FALSE": 2
}
export const IS_OVERSEA = {
  "UNDEFINED": 0,
  "TRUE": 1,
  "FALSE": 2
}
export const IS_GRADUATE_ZH = [
  "未知",
  "是",
  "不是"
];

export default {
  apply,
  detail,
}
