import React from "react";
import applyName from "../../../services/idCardRealName";
import gSass from "../../../utils/sass";
import { BASE_URL, } from '../../../config/prod';
import { Link, } from "react-router-dom";
import qs from "qs";
import {
  Breadcrumb,
  Form,
  Input,
  message,
  Spin,
  Button,
  Upload,
  Icon,
  Modal,
  Select,
  Radio
} from "antd";
import { IS_HOUSEHOLDREGISTER } from "../../../services/idCardRealName";
import { getUploadImgUrl } from '../../../services/api';
import idCardRealNameApi from "../../../services/idCardRealName";
import applyApi from "../../../services/apply";
import { IdentityCodeValid } from "../../../utils/utils";
const style = gSass.admin.applyEdit.edit;
const Option = Select.Option;
const RadioGroup = Radio.Group;
const formItemLayout = {
  labelCol: {
    lg: {
      span: 4,
      offset: 4,
    },
  },
  wrapperCol: {
    lg: {
      span: 8,
      offset: 0,
    },
  },
};

//地址
const uploadButton = (
  <div>
    <Icon type="plus" />
    <div className="ant-upload-text">上传图片</div>
  </div>
);
class Edit extends React.Component {
  constructor(props) {
    super(props);
    let { id } = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    id = parseInt(id);
    this.state = {
      projectId: id,
      spinning: true,
      industryList: [],
      patentTypeList: [],
      patentList: [],
      financingList: [],
      userInfo: {},
      idCardFirstPic: [],
      idCardSecondPic: [],
      previewVisible: false,
      previewImage: '',
      householdList: [],
      residentPic: [],
      isShanghai: null,
      applyId: 0,
      infoId: 0,
    };
  }
  componentDidMount() {
    this.init();
  }
  init = async _ => {
    try {
      // 获取户籍
      let { data: { list: householdList } } = await idCardRealNameApi.getProvice({ page: -1, limit: -1 })

      // 获得服务器数据
      let { data: { detail, } } = await applyApi.getApplyDetail({ id: this.state.projectId });
      householdList = householdList.map(v => ({
        value: v.id,
        label: v.name,
      }))
      this.setState({
        spinning: false,
        applyId: detail.apply_uid,
        infoId: detail.info.id,
        householdList,
        idCardFirstPic: [{
          url: BASE_URL + detail.info.id_card_first.url,
          uid: detail.info.id_card_first.id,
        }],

        idCardSecondPic: [{
          url: BASE_URL + detail.info.id_card_second.url,
          uid: detail.info.id_card_second.id,
        }],
        residentPic: detail.info.id_reside === "" ? [] : [{
          url: BASE_URL + detail.info.id_reside.url,
          uid: detail.info.id_reside.id,
        }],
        isShanghai: detail.info.is_shanghai
      });
      this.props.form.setFieldsValue({
        name: detail.info.name,
        phone: detail.info.idPhone,
        age: detail.info.idAge,
        email: detail.info.idEmail,
        household: detail.info.idHousehold,
        idCardNo: detail.info.idCardNo,
        isShanghai: detail.info.is_shanghai
      });
    } catch (e) {
      console.log(e);
      this.setState({
        spinning: false,
      });
    }
  };

  //提交
  updateUserInfo = evt => {
    evt.preventDefault();
    this.props.form.validateFields(["name", "phone", "age", "email", "household",
      "isShanghai", "idCardNo", "idCardFirstPic", "idCardSecondPic", "residentPic"], (err, fields) => {
        if (err) {
          return message.error(err[Object.keys(err)[0]].errors[0].message, 1);
        }
        let { idCardFirstPic, idCardSecondPic, residentPic } = this.state
        if (idCardFirstPic[0].response) {
          idCardFirstPic = idCardFirstPic[0].response.data.picId
        } else {
          idCardFirstPic = idCardFirstPic[0].uid
        }

        if (idCardSecondPic[0].response) {

          idCardSecondPic = idCardSecondPic[0].response.data.picId
        } else {
          idCardSecondPic = idCardSecondPic[0].uid
        }
        if (residentPic.length !== 0) {
          if (residentPic[0].response) {
            residentPic = residentPic[0].response.data.picId
          } else {
            residentPic = residentPic[0].uid
          }
        }
        applyName
          .edit({
            applyId: this.state.applyId,
            infoId: this.state.infoId,
            name: fields.name,
            phone: fields.phone,
            age: fields.age,
            email: fields.email,
            household: fields.household,
            idCardNo: fields.idCardNo || "",
            idCardFirstPic: idCardFirstPic,
            idCardSecondPic: idCardSecondPic,
            isShanghai: fields.isShanghai,
            residentPic: this.state.isShanghai === 1 ? residentPic : "",
          })
          .then(json => {
            message.success("修改成功", 1);
          })
          .catch(err => {
            message.error("修改失败,失败原因: " + err.msg, 1);
            console.log(err);
          });
      });
  };
  //上传图片
  handlePreview = file => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true,
    });
  }
  idCardFirstPicChange = ({ fileList }) => this.setState({ idCardFirstPic: fileList })
  idCardSecondPicChange = ({ fileList }) => {
    console.log(111)
    this.setState({ idCardSecondPic: fileList }, () => {
      console.log(this.state.idCardSecondPic)
    })
  }
  handleCancel = _ => this.setState({ previewVisible: false })
  residentPicChange = ({ fileList }) => this.setState({ residentPic: fileList })
  //身份证号码验证
  idCardNoValidation = evt => {
    let val = evt.target.value;
    IdentityCodeValid(val).then(json => {
      this.setState({
        idCardNoValidation: json.pass,
      });
      if (!json.pass) {
        message.error("身份证号码错误: " + json.msg, 1);
      }
    });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    const { previewVisible, previewImage, idCardFirstPic, idCardSecondPic,
      residentPic } = this.state;
    //头像上传按钮
    return (
      <Spin tip="Loading..." spinning={this.state.spinning} size="large">
        <div className={style.info}>
          <Breadcrumb className={style.title}>
            <Breadcrumb.Item><Link to="/admin">首页</Link></Breadcrumb.Item>
            <Breadcrumb.Item><Link to="/admin/apply/allList">申请管理</Link></Breadcrumb.Item>
            <Breadcrumb.Item>实名认证编辑</Breadcrumb.Item>
          </Breadcrumb>
          <div className={style.basicWrap}>
            <Form className={style.form}>
              <Form.Item {...formItemLayout} label="姓名">
                {getFieldDecorator("name", {
                  rules: [{ required: true, message: "请输入姓名" }],
                })(<Input placeholder="请输入" />)}
              </Form.Item>
              <Form.Item {...formItemLayout} label="电话">
                {getFieldDecorator("phone", {
                  rules: [{ required: true, message: "请输入电话" }],
                })(<Input placeholder="请输入" />)}
              </Form.Item>
              <Form.Item {...formItemLayout} label="年龄">
                {getFieldDecorator("age", {
                  rules: [{ required: true, message: "请输入年龄" }],
                })(<Input placeholder="请输入" />)}
              </Form.Item>
              <Form.Item {...formItemLayout} label="邮箱">
                {getFieldDecorator("email", {
                  rules: [{ required: true, message: "请输入邮箱" }],
                })(<Input placeholder="请输入" />)}
              </Form.Item>

              <Form.Item {...formItemLayout} label="户籍"  >
                {getFieldDecorator('household', {
                  rules: [{ required: true, message: '请选择户籍' }],
                })(
                  <Select placeholder="请选择户籍">
                    {this.state.householdList.map((v, k) => {
                      return (<Option key={`${k}`} value={v.value}>{v.label}</Option>);
                    })}
                  </Select>
                )}
              </Form.Item>
              <Form.Item {...formItemLayout} label="是否有上海居住证">
                {getFieldDecorator('isShanghai', {
                  rules: [{ required: true, message: '请选择是有上海居住证' }],
                })(
                  <RadioGroup onChange={evt => {
                    this.setState({
                      isShanghai: evt.target.value,
                    })
                  }}>
                    <Radio value={IS_HOUSEHOLDREGISTER.UNDEFINED}> 否</Radio>
                    <Radio value={IS_HOUSEHOLDREGISTER.TRUE}>是</Radio>
                  </RadioGroup>
                )}
              </Form.Item>
              <Form.Item {...formItemLayout} label="居住证"
                className={this.state.isShanghai === IS_HOUSEHOLDREGISTER.TRUE ? "" : style.hidden}>
                {getFieldDecorator('residentPic', {
                })(
                  <div className="clearfix">
                    <Upload action={getUploadImgUrl()} listType="picture-card" fileList={residentPic}
                      onPreview={this.handlePreview} onChange={this.residentPicChange}>
                      {residentPic.length > 0 ? null : uploadButton}
                    </Upload>
                    <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                      <img alt="example" style={{ width: '100%' }} src={previewImage} />
                    </Modal>
                  </div>
                )}
              </Form.Item>
              <Form.Item {...formItemLayout} label="身份证号码"  >
                {getFieldDecorator('idCardNo', {
                  rules: [{ required: true, message: '请输入身份证号码' }],
                })(
                  <Input placeholder="请输入身份证号码" onBlur={this.idCardNoValidation} />)}
              </Form.Item>
              <Form.Item {...formItemLayout} label="身份证正面">
                {getFieldDecorator('idCardFirstPic', {})(
                  <div className="clearfix">
                    <Upload action={getUploadImgUrl()} listType="picture-card" fileList={idCardFirstPic}
                      onPreview={this.handlePreview} onChange={this.idCardFirstPicChange}>
                      {idCardFirstPic.length > 0 ? null : uploadButton}
                    </Upload>
                    <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                      <img alt="example" style={{ width: '100%' }} src={previewImage} />
                    </Modal>
                  </div>
                )}
              </Form.Item>
              <Form.Item {...formItemLayout} label="身份证反面">
                {getFieldDecorator('idCardSecondPic', {})(
                  <div className="clearfix">
                    <Upload action={getUploadImgUrl()} listType="picture-card" fileList={idCardSecondPic}
                      onPreview={this.handlePreview} onChange={this.idCardSecondPicChange}>
                      {idCardSecondPic.length > 0 ? null : uploadButton}
                    </Upload>
                    <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                      <img alt="example" style={{ width: '100%' }} src={previewImage} />
                    </Modal>
                  </div>
                )}
              </Form.Item>

              <Form.Item wrapperCol={{
                xs: {
                  span: 7,
                  offset: 8,
                },
              }}>
                <Button className={style.btn} type="primary" onClick={this.updateUserInfo}>更新基本信息</Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Spin>
    );
  }
}
export default Form.create()(Edit);
