import React, { Component } from 'react';
import {
  Table, Button, Breadcrumb, Input, Popconfirm, Spin, Modal, Form, message, Tooltip, Dropdown, Menu,
  Icon,
} from 'antd';
import { Link } from "react-router-dom";
import schoolSpeciality from "../../../services/schoolSpeciality";
import gSass from '../../../utils/sass';
import gImg from '../../../utils/img';
import moment from "moment";

const style = gSass.admin.school.specialityList;

const Search = Input.Search;
//编辑
const FormItem = Form.Item;
const EditableContext = React.createContext({});
const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
);
const EditableFormRow = Form.create()(EditableRow);
//编辑
class EditableCell extends React.Component {
  form = null;
  state = {
    editingCell: false,
  }
  componentDidMount() {
    if (this.props.editable) {
      document.addEventListener('click', this.handleClickOutside, true);
    }
  }
  componentWillUnmount() {
    if (this.props.editable) {
      document.removeEventListener('click', this.handleClickOutside, true);
    }
  }
  toggleEdit = _ => {
    const editingCell = !this.state.editingCell;
    this.setState({ editingCell }, _ => {
      if (editingCell) {
        this.input.focus();
      }
    });
  }
  handleClickOutside = evt => {
    const { editingCell } = this.state;
    if (editingCell && this.cell !== evt.target && !this.cell.contains(evt.target)) {
      this.save();
    }
  }
  save = _ => {
    const { record, handleSave } = this.props;
    this.form.validateFields((error, values) => {
      if (error) {
        return;
      }
      this.toggleEdit();
      handleSave({ ...record, ...values });
    });
  }
  render() {
    const { editingCell } = this.state;
    const { editable, dataIndex, title, record, index, handleSave, ...restProps } = this.props;
    return (
      <td ref={node => (this.cell = node)} {...restProps}>
        {editable ? (
          <EditableContext.Consumer>
            {(form) => {
              this.form = form;
              return (
                editingCell ? (
                  <FormItem style={{ margin: 0 }}>
                    {form.getFieldDecorator(dataIndex, {
                      rules: [{ required: true, message: `${title} is required.`, }],
                      initialValue: record[dataIndex],
                    })(
                      <Input ref={node => (this.input = node)} onPressEnter={this.save} />
                    )}
                  </FormItem>
                ) : (
                    <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={this.toggleEdit}  >
                      {restProps.children}
                    </div>
                  )
              );
            }}
          </EditableContext.Consumer>
        ) : restProps.children}
      </td>
    );
  }
}

class List extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filteredInfo: null,
      sortedInfo: null,
      list: [],
      filter: {},
      selectedRows: [],
      pagination: {
        current: 1,
        pageSize: 8,
        total: 0,
        showTotal: total => {
          return `共${total}条记录`
        },
        onChange: async (page, pageSize) => {
          const pagination = this.state.pagination;
          pagination.current = page;
          pagination.pageSize = pageSize;
          try {
            // 注释掉的东西不能删
            let page = pagination.current,
              limit = pagination.pageSize,
              filter = this.state.filter;
            this.setState({
              tableIsLoading: true,
            })
            let json = await schoolSpeciality.getList({ page, limit, filter });
            pagination.total = json.count;
            this.setState({
              tableIsLoading: false,
              list: json.data.list,
              pagination,
            })
          } catch (err) {
            console.log(err)
            this.setState({
              tableIsLoading: false,
            })
          }
        }
      },
      tableIsLoading: true,
      defaultAvatar: gImg.user.defaultAvatar,
      isAddSpecialityModalActive: false,
      isLoading: false,
      hasFilter: false,
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
        },
        {
          title: '专业名',
          dataIndex: 'name',
          editable: true,
          render: name => {
            return (<div style={{ cursor: "pointer", }}>
              <Tooltip title="点击修改">{name}</Tooltip>
            </div>);
          }
        },
        {
          title: '创建时间',
          dataIndex: 'ctime',
          render: ctime => {
            return (
              <div>{ctime ? ctime : moment().format('YYYY-MM-DD HH:mm:ss')}</div>
            );
          }
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 300,
          render: (text, record) => (
            this.state.list.length >= 1
              ? (
                <div>
                  {/* <span className={style.btn} onClick={_ => this.editSpeciality(record.id)}>编辑</span>
                  <div className="separate-line"></div> */}
                  <span className={style.btn}>
                    <Popconfirm title={<div>你确定要删除id为{record.id}的专业吗?</div>} onConfirm={_ => this.deleteSpeciality([record.id])}>
                      <span >删除</span>
                    </Popconfirm>
                  </span>
                </div>
              ) : null
          ),
        }
      ],
    };
  }
  componentDidMount() {
    this.init();
  }
  init = async _ => {
    try {
      // 注释掉的东西不能删
      let page = this.state.pagination.current,
        limit = this.state.pagination.pageSize,
        filter = this.state.filter;
      let pagination = this.state.pagination;
      let json = await schoolSpeciality.getList({ page, limit, filter });
      pagination.total = json.count;
      this.setState({
        isLoading: false,
        tableIsLoading: false,
        list: json.data.list,
        pagination,
      })
    } catch (err) {
      this.setState({
        isLoading: false,
        tableIsLoading: false,
      })
      console.log(err)
    }
  }

  //table 改变
  handleChange = async (pagination, filters, sorter) => {//筛选(排序,页码)

  }
  //操作---删除
  deleteSpeciality = async idArr => {
    try {
      await schoolSpeciality.deleteSpeciality(idArr);
      let list = this.state.list;
      list = list.filter(v => !idArr.includes(v.id))
      this.setState({
        list,
      })
      message.success('删除成功', 1)
    } catch (err) {
      message.error('删除失败,错误原因: ' + err.msg, 3)
    }
  }
  //头部添加按钮------添加
  addSpeciality = evt => {
    this.props.form.validateFields(["name",], (err, fields) => {
      if (err) {
        return;
      }
      schoolSpeciality.add({ name: fields.name }).then(json => {
        message.success("添加成功", 1, _ => {
          this.hiddenAddSpecialityModal()
          this.props.form.setFieldsValue({
            'name': '',
          })
          this.init()
        });
      }).catch(err => {
        console.log(err)
        this.hiddenAddSpecialityModal().then(_ => {
          message.error("添加失败, 失败原因: " + err.msg || err, 3);
        });
      })
    });
  }
  //修改一行
  editSpeciality = async row => {
    let list = this.state.list,
      rowIdx = list.findIndex(item => row.id === item.id),
      item = list[rowIdx];
    if (row.name === item.name) {
      return;
    }
    let id = row.id, name = row.name;
    schoolSpeciality.edit({ id, name }).then(_ => {
      list[rowIdx] = row;
      this.setState({ list }, _ => {
        message.success("修改成功", 1);
      });
    }).catch(err => {
      message.error("修改失败, 失败原因: " + err.msg, 1);
    })
  }
  //搜索
  search = _ => {
    this.props.form.validateFields(["search"], (err, fields) => {
      if (err) {
        return;
      }
      if (!fields.search) {
        message.info("无搜索内容", 1);
        return;
      }
      schoolSpeciality.getList({
        page: this.state.page,
        limit: this.state.limit,
        filter: {
          searchInput: fields.search,
        },
      }).then(json => {
        this.setState({
          list: json.data,
          hasFilter: false,
        })
      }).catch(err => {
        console.log(err);
      })
    })
  }

  showAddSpecialityModal = _ => {
    return new Promise(s => {
      this.setState({
        isAddSpecialityModalActive: true,
      }, s)
    })
  }
  hiddenAddSpecialityModal = _ => {
    return new Promise(s => {
      this.setState({
        isAddSpecialityModalActive: false,
      }, s)
    })
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell,
      },
    };
    const columns = this.state.columns.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.editSpeciality,
        }),
      };
    });
    return (
      <div className={style.content}>
        <Spin spinning={this.state.isLoading} className={this.state.isLoading ? style.loading : style.hidden} size="large">
          <Breadcrumb className={style.title}>
            <Breadcrumb.Item><Link to="/admin">首页</Link></Breadcrumb.Item>
            <Breadcrumb.Item><Link to="/admin/school/specialityList">学校管理</Link></Breadcrumb.Item>
            <Breadcrumb.Item>学校专业列表</Breadcrumb.Item>
          </Breadcrumb>
          <div className={style.filters}>
            <div className={style.item}>
              <div className={style.flex}>
                <Button type="primary" icon="plus"
                  onClick={this.showAddSpecialityModal}
                >新增</Button>
                <Dropdown overlay={<Menu>
                  <Menu.Item>
                    <div onClick={_ => {
                      let idArr = [];
                      for (let v of this.state.selectedRows) {
                        idArr.push(v.id)
                      }
                      if (idArr.length < 1) {
                        return message.error('请选择要删除的专业', 2)
                      }
                      this.deleteSpeciality(idArr)
                    }}>删除</div>
                  </Menu.Item>
                </Menu>} className={style.drown} placement="bottomLeft">
                  <Button>更多操作 <Icon type="down" /></Button>
                </Dropdown>
              </div>
            </div>
            <Form.Item wrapperCol={{ span: 14 }} >
              {getFieldDecorator('search', {
              })(
                <Search placeholder="请输入专业名/添加时间, 格式如:(2018-12-12)..." enterButton="搜索"
                  onSearch={this.search} className={style.search} />
              )}
            </Form.Item >
          </div>
          <Table className={style.table} columns={columns} components={components} dataSource={this.state.list}
            rowSelection={{
              onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                  selectedRows,
                })
              },
            }}
            onChange={this.handleChange} rowKey="id"
            pagination={this.state.pagination}
            loading={this.state.tableIsLoading}
          />
          {/* 点击添加 */}
          <Modal
            title="添加专业"
            visible={this.state.isAddSpecialityModalActive}
            onOk={this.addSpeciality}
            onCancel={this.hiddenAddSpecialityModal}
          >
            <Form>
              <Form.Item label="专业名" labelCol={{ span: 3, offset: 3 }} wrapperCol={{ span: 14, offset: 1 }} >
                {getFieldDecorator('name', {
                  rules: [
                    { required: true, message: '请输入专业名' },
                    { min: 2, max: 30, message: '专业名长度为2-30' },
                  ],
                })(
                  <Input type="text" autoFocus size="large" placeholder="请输入"
                    onPressEnter={this.addSpeciality}
                  />
                )}
              </Form.Item >
            </Form>
          </Modal>
        </Spin>
      </div >
    );
  }
}
const schoolSpecialityList = Form.create()(List);
export default schoolSpecialityList; 
