import { bget, bpost } from './api';
// 合并申请
export async function mergeInfoApply({
  idCardName, idPhone, idAge,
  idEmail, idGender, residePic, household, idCardNo, idCardFirstPic, idCardSecondPic,
  college, speciality, grade, education, isGraduate, isOverseas, degreePic, oneCardPassPic, seasEducation, seasSpeciality, seasCollege, seasGraduationTime, overseasCard,
}) {
  return bpost({
    url: '/apply/idCardRealNameAndSchoolInfoAuth',
    data: {
      idCardName, idPhone, idAge,
      idEmail, idGender, residePic, household, idCardNo, idCardFirstPic, idCardSecondPic,
      college, speciality, grade, education, isGraduate, isOverseas, degreePic, oneCardPassPic, seasEducation, seasSpeciality, seasCollege, seasGraduationTime, overseasCard,
    }
  })
}
// 合并编辑
export async function mergeInfoEdit({
  applyId,
  idCardName, idPhone, idAge,
  idEmail, idGender, residePic, household, idCardNo, idCardFirstPic, idCardSecondPic,
  college, speciality, grade, education, isGraduate, isOverseas, degreePic, oneCardPassPic, seasEducation, seasSpeciality, seasCollege, seasGraduationTime, overseasCard,
}) {
  return bpost({
    url: '/editMergeInfo',
    data: {
      applyId,
      idCardName, idPhone, idAge,
      idEmail, idGender, residePic, household, idCardNo, idCardFirstPic, idCardSecondPic,
      college, speciality, grade, education, isGraduate, isOverseas, degreePic, oneCardPassPic, seasEducation, seasSpeciality, seasCollege, seasGraduationTime, overseasCard,
    }
  })
}
// 后台合并编辑
export async function mergeInfoEditAdmin({
  applyId,
  idCardName, idPhone, idAge,
  idEmail, idGender, residePic, household, idCardNo, idCardFirstPic, idCardSecondPic,
  college, speciality, grade, education, isGraduate, isOverseas, degreePic, oneCardPassPic, seasEducation, seasSpeciality, seasCollege, seasGraduationTime, overseasCard,
}) {
  return bpost({
    url: '/editMergeInfoAdmin',
    data: {
      applyId,
      idCardName, idPhone, idAge,
      idEmail, idGender, residePic, household, idCardNo, idCardFirstPic, idCardSecondPic,
      college, speciality, grade, education, isGraduate, isOverseas, degreePic, oneCardPassPic, seasEducation, seasSpeciality, seasCollege, seasGraduationTime, overseasCard,
    }
  })
}
export async function apply({
  idCardName, idCardNo, idCardFirstPic, idCardSecondPic, idPhone,
  idEmail, idAge, residePic, household, idGender, isHouseholdRegister
}) {
  return bpost({
    url: '/idCardRealNameApply',
    data: {
      idCardName, idCardNo, idCardFirstPic, idCardSecondPic, idPhone,
      idEmail, idAge, residePic, household, idGender, isHouseholdRegister
    }
  })
}
//后台编辑实名认证
export async function edit({
  applyId, infoId, name, age, phone, email, household, idCardNo, idCardFirstPic, idCardSecondPic, isShanghai, residentPic
}) {
  return bpost({
    url: '/editIdCardRealName',
    data: {
      applyId, infoId, name, age, phone, email, household, idCardNo, idCardFirstPic, idCardSecondPic, isShanghai, residentPic
    }
  })
}

// url: '/idCardRealNameApply',
export async function detail(id) {
  return bget({
    url: '/idCardRealName/detail',
    query: {
      id,
    }
  })
}


export async function getProvice({ page, limit, filter = {} }) {
  return bget({
    url: '/getProvince',
    query: {
      page,
      limit,
      filter,
    }
  })
}

// 定义是否有为上海户籍
export const IS_HOUSEHOLDREGISTER = {
  'UNDEFINED': 0,
  'TRUE': 1,
  'FALSE': 2
}

export default {
  apply,
  detail,
  getProvice,
  edit,
  mergeInfoApply,
  mergeInfoEdit,
  mergeInfoEditAdmin
}
