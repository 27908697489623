import React from "react";
import project from "../../../services/project";
import gSass from "../../../utils/sass";
import moment from "moment";
import qs from "qs";
import {
  Form,

  message,

  Spin,
  Select,
  InputNumber,
  DatePicker,
  // Collapse,
  // Cascader,
  // Input,
  // Empty,
  // Icon,
} from "antd";
import { TYPE, TYPE_ZH, ROOM_NO_LIST } from "../../../services/conferenceRoomRent";
// import { EDUCATION, EDUCATION_ZH } from "../../../services/apply";
// import companyApi from "../../../services/company";
// import projectApi from "../../../services/project";
// import { getRegion, IdentityCodeValid } from "../../../utils/utils";

const style = gSass.admin.project.base;
const Option = Select.Option;
// const { TextArea } = Input;

const formItemLayout = {
  labelCol: {
    lg: {
      span: 4,
      offset: 1,
    },
  },
  wrapperCol: {
    lg: {
      span: 10,
      offset: 0,
    },
  },
};
class Management extends React.Component {
  constructor(props) {
    super(props);
    let { id } = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    id = parseInt(id);
    this.state = {
      projectId: id,
      spinning: true,
      patentTypeList: [],
      patentList: [],
      financingList: [],
      userInfo: {},
      roomList: [],
      enterpriseStateList: [],
    };
  }
  componentDidMount() {
    this.init();
  }
  init = async _ => {
    try {


      const enterpriseStateList = Object.values(TYPE).map(v => ({
        value: v,
        label: TYPE_ZH[v],
      }));
      const roomList = ROOM_NO_LIST.map(v => ({
        value: v,
        label: v,
      }))
      console.log(roomList)
      console.log(enterpriseStateList)
      //获取学历信息

      this.setState({
        spinning: false,
        roomList,
        enterpriseStateList,
      });


    } catch (e) {
      console.log(e);
      this.setState({
        spinning: false,
      });
    }
  };
  //提交
  updateUserInfo = evt => {
    evt.preventDefault();
    this.props.form.validateFields((err, fields) => {
      if (err) {
        return message.error(err[Object.keys(err)[0]].errors[0].message, 1);
      }
      let patentList = [],
        financingList = [];
      for (let v of this.state.patentList) {
        patentList.push({
          name: fields["patentName" + v],
          time: moment(fields["patentTime" + v]).format("YYYY-MM-DD HH:mm:ss"),
          type: fields["patentType" + v],
        });
      }
      for (let v of this.state.financingList) {
        financingList.push({
          price: fields["financingPrice" + v] * 100,
          time: moment(fields["financingTime" + v]).format("YYYY-MM-DD HH:mm:ss"),
          source: fields["financingSource" + v],
        });
      }
      let data = {
        name: fields.name,
        totalNumber: fields.totalNumber,
        industry: fields.industry,
        patentList,
        financingList,
        description: fields.description,
      };
      console.log(data);
      project
        .editProjectInfo({
          name: fields.name,
          totalNumber: fields.totalNumber,
          industry: fields.industry,
          patentList,
          financingList,
          description: fields.description,
        })
        .then(json => {
          message.success("修改成功", 1);
        })
        .catch(err => {
          message.error("修改失败,失败原因: " + err.msg, 1);
          console.log(err);
        });
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    //头像上传按钮
    return (
      <Spin tip="Loading..." spinning={this.state.spinning} size="large">
        <div>
          <div className={style.title}>基本信息</div>
          <Form className={style.form}>
            {/* 2.企业入驻：入驻日期/退出日期/收费金额/办公室房间号/企业状态（孵化一期/孵化二期/退出） */}
            <Form.Item {...formItemLayout} label="入驻日期">
              {getFieldDecorator("entryDate", {
                rules: [
                  {

                    message: `请选择入驻日期`,
                  },
                ],
              })(<DatePicker className={style.input} placeholder="请选择" />)}
            </Form.Item>
            <Form.Item {...formItemLayout} label="退出日期">
              {getFieldDecorator("exitDate", {
                rules: [
                  {

                    message: `请选择退出日期`,
                  },
                ],
              })(<DatePicker className={style.input} placeholder="请选择" />)}
            </Form.Item>
            <Form.Item {...formItemLayout} label="收费金额">
              {getFieldDecorator("charge", {
                rules: [{ message: "请输入" }],
              })(
                <InputNumber
                  className={style.input}
                  precision={0.0}
                  placeholder="请输入收费金额"
                />,
              )}
            </Form.Item>
            <Form.Item {...formItemLayout} label="房间号">
              {getFieldDecorator("roomNumber", {
                rules: [{ message: "请选择" }],
              })(
                <Select placeholder="请选择">
                  {this.state.roomList.map((v, k) => {
                    return (
                      <Option key={`${k}`} value={v.value}>
                        {v.label}
                      </Option>
                    );
                  })}
                </Select>,
              )}
            </Form.Item>
            <Form.Item {...formItemLayout} label="企业状态">
              {getFieldDecorator("enterpriseState", {
                rules: [{ message: "请选择" }],
              })(
                <Select placeholder="请选择">
                  {this.state.enterpriseStateList.map((v, k) => {
                    return (
                      <Option key={`${k}`} value={v.value}>
                        {v.label}
                      </Option>
                    );
                  })}
                </Select>,
              )}
            </Form.Item>
            {/* <Form.Item className={style.control}>
              <Button className={style.btn} type="primary" onClick={this.updateUserInfo}>更新基本信息</Button>
            </Form.Item> */}
          </Form>
        </div>
      </Spin>
    );
  }
}
export default Form.create()(Management);
