import React from "react";
import {
  List, ActivityIndicator, NavBar, Icon, InputItem, Button, DatePicker, Toast, Checkbox,
  Modal,
} from "antd-mobile";
import { Form } from 'antd';
import moment from "moment";
import { Link, withRouter, } from "react-router-dom";
import gStyle from "../../../../utils/sass";
import coffeeHouseRentApi from "../../../../services/coffeeHouseRent";
import { PROVIDES, PROVIDES_ZH } from "../../../../services/coffeeHouseRent";
import ApplyWithSendMsg from "../../../../components/index/user/apply/ApplyWithSendMsg";
import applyApi, { APPLY_STATUS } from "../../../../services/apply";

const style = gStyle.index.user.apply.coffeeHouseRent;
const Item = List.Item;
const CheckboxItem = Checkbox.CheckboxItem;


class CoffeeHouseRent extends ApplyWithSendMsg {
  isShowDetail = false;
  constructor(props) {
    super(props);
    this.isShowDetail = props.isShowDetail || false;
    this.applyId = props.applyId;
    this.state = {
      isAnimating: true,
      region: null,
      isModalActive: false,
      modalBody: '',
      modalFooter: [],
      provideItemList: [],
      applyDetail: {
        id: 1,
        steps: [],
      },
      isAllowEdit: false,
    }
  }
  componentDidMount() {
    this.init();
  }

  init = async _ => {
    let provideItemList = Object.values(PROVIDES).map(v => ({
      value: v,
      label: PROVIDES_ZH[v]
    }))
    this.setState({
      isAnimating: false,
      provideItemList,
    })
    if (this.isShowDetail) {
      const { setFieldsValue, } = this.props.form;
      try {
        let { data: { detail: applyDetail, } } = await applyApi.getApplyDetail({ id: this.applyId });
        let isAllowEdit = this.state.isAllowEdit;
        if (applyDetail.status === APPLY_STATUS['process']) {
          isAllowEdit = true;
        } else {
          isAllowEdit = false;
        }
        this.setState({
          isAllowEdit,
        })
        setFieldsValue({
          usePersonNumber: applyDetail.info.usePersonNumber,
          startTime: new Date(applyDetail.info.startTime),
          endTime: new Date(applyDetail.info.endTime),
        })
        for (let [k, v] of Object.entries(applyDetail.info.providedItem)) {
          if (v > 0) {
            setFieldsValue({
              ['provide' + PROVIDES[k]]: true,
            })
          }
        }
        applyDetail.id = applyDetail.apply_uid;
        this.setState({
          applyDetail,
        })
      } catch (err) {
        console.log(err);
      }
    }
  }

  apply = _ => {
    this.props.form.validateFields(async (err, fields) => {
      if (err) {
        let firstErrKey = Object.keys(err)[0]
        if (firstErrKey !== 'msg') {
          return Toast.fail(err[firstErrKey].errors[0].message, 2)
        }
      }
      let provideList = {};
      for (let v of this.state.provideItemList) {
        if (fields["provide" + v.value]) {
          provideList[v.value] = 1;
        }
      }
      if (this.state.isAllowEdit) {
        coffeeHouseRentApi.editCoffeeHouseRent({
          applyId: this.applyId,
          admin: false,
          userNum: parseInt(fields.usePersonNumber),
          startTime: moment(fields.startTime).format("YYYY-MM-DD HH:mm:ss"),
          endTime: moment(fields.endTime).format("YYYY-MM-DD HH:mm:ss"),
          provideItemList: provideList,
        })
          .then(() => {
            this.setState({
              isAllowEdit: false,
            })
            Toast.success("修改成功", 1);
          })
          .catch(err => {
            Toast.fail("修改失败,失败原因: " + err.msg, 1);
          });
      } else {
        try {
          await coffeeHouseRentApi.applyCoffeeHouseRent({
            usePersonNumber: parseInt(fields.usePersonNumber),
            startTime: moment(fields.startTime).format("YYYY-MM-DD HH:mm:ss"),
            endTime: moment(fields.endTime).format("YYYY-MM-DD HH:mm:ss"),
            // reason: fields.reason || null,
            provideList,
          })
          this.setState({
            isModalActive: true,
            modalBody: <div>提交成功,请等待审核</div>,
            modalFooter: [{ text: '确定', onPress: () => this.props.history.push('/') }],
          })
        } catch (err) {
          this.setState({
            isModalActive: true,
            modalBody: <div>提交失败,错误信息:{err.msg}</div>,
            modalFooter: [{
              text: '确定', onPress: () => this.setState({ isModalActive: false, })
            }],
          })
        }
      }
    });
  }
  resetForm = _ => {
    this.props.form.resetFields();
  }
  getApplyDetailDom = _ => {
    const { getFieldDecorator, getFieldError, getFieldProps } = this.props.form;
    return <form className={style.form}>
      <List>
        {/* {getFieldDecorator('name',
          { rules: [{ required: true, message: '请输入申请人/企业信息' },], })(
            <InputItem clear className={style.input} placeholder="请输入" error={!!getFieldError('name')}
              extra={<Icon size="md" color="#ccc" type="right"></Icon>}  >申请人/企业信息</InputItem>
          )} */}
        {getFieldDecorator('usePersonNumber',
          { rules: [{ required: true, message: '请输入使用人数' },], })(
            <InputItem clear className={style.input} placeholder="请输入" error={!!getFieldError('usePersonNumber')}
              type="digit" extra={<Icon size="md" color="#ccc" type="right"></Icon>} >使用人数</InputItem>
          )}
        <DatePicker
          {...getFieldProps('startTime',
            { rules: [{ required: true, message: `请选择开始使用时间` },], })} mode="datetime" extra="请选择">
          <List.Item arrow="horizontal">开始使用时间</List.Item>
        </DatePicker>
        <DatePicker
          {...getFieldProps('endTime',
            { rules: [{ required: true, message: `请选择结束使用时间` },], })} mode="datetime" extra="请选择" >
          <List.Item arrow="horizontal">结束使用时间</List.Item>
        </DatePicker>
        <Item>
          <div>需要提供(可多选)</div>
          {this.state.provideItemList.map((v, k) => {
            return (<div key={`${k}`}>
              <CheckboxItem {...getFieldProps('provide' + v.value, {
                valuePropName: 'checked',
              })}
                key={v.value} >{v.label}</CheckboxItem>
            </div>
            )
          })}
        </Item>
        {/* <Item>
          <div>租用原因</div>
          <TextareaItem
            {...getFieldProps('reason', {})}
            rows={3} count={100} placeholder="请输入租用原因" />
        </Item> */}
      </List>
      {
        this.state.isAllowEdit ? (
          <div className={style.operation}>
            <Button size="small" type="primary" className={style.btn} inline onClick={this.apply}>修改提交</Button>
          </div>
        ) : ''
      }
      {this.getChatInfo()}
    </form>
  }

  render() {
    return (this.isShowDetail ? this.getApplyDetailDom() :
      <div className={style.cafe}>
        <ActivityIndicator animating={this.state.isAnimating} className={style.loading} text="loading..." />
        <NavBar mode="light" icon={<Link to="/"><Icon type="left" /></Link>}>咖啡吧申请</NavBar>
        {this.getApplyDetailDom()}
        <div className={style.operation}>
          <Button size="small" className={style.btn} inline onClick={this.resetForm}>重置</Button>
          <Button size="small" type="primary" className={style.btn} inline onClick={this.apply}>提交</Button>
        </div>
        <Modal visible={this.state.isModalActive} transparent maskClosable={false}
          title="提示" footer={this.state.modalFooter}
        >
          {this.state.modalBody}
        </Modal>
      </div >
    );
  }
}
export default withRouter(Form.create()(CoffeeHouseRent));
