import React from "react";
import project from "../../../services/project";
import gSass from "../../../utils/sass";
import moment from "moment";
import { Link, } from "react-router-dom";
import qs from "qs";
import {
  Breadcrumb,
  Form,
  Input,
  message,
  Icon,
  Spin,
  Select,
  InputNumber,
  Collapse,
  Badge,
  Row,
  Col,
  DatePicker,
  Empty,
  Button,
  Upload,
  Modal,
} from "antd";
import { TYPE as PATENT_TYPE, TYPE_ZH as PATENT_TYPE_ZH, FINANCING_TYPE as FinancingType, FINANCING_TYPE_ZH as FinancingTypeZh, SUBORDINATE_TYPE as SubordinateType, SUBORDINATE_TYPE_ZH as SubordinateTypeZh } from "../../../services/patent";
import { EDUCATION, EDUCATION_ZH } from "../../../services/apply";
import companyApi from "../../../services/company";
import applyApi from "../../../services/apply";
import { getRegion, } from "../../../utils/utils";
import { getUploadImgUrl } from "../../../services/api";
import { BASE_URL } from "../../../config/prod";
const style = gSass.admin.applyEdit.edit;
const Option = Select.Option;
const { TextArea } = Input;
const Panel = Collapse.Panel;
let uploadList = [];
// 上传附件
const upload = {
  action: BASE_URL + '/antdUploadImg',
  onChange({ file, fileList }) {
    if (file.status !== 'uploading') {
      if (file.response.code === 0) {
        uploadList.push(file.response.data)
      } else {
        console.log(file.response.msg);
      }
    }
  },
  // defaultFileList: uploadList,

}
//地址
const uploadButton = (
  <div>
    <Icon type="plus" />
    <div className="ant-upload-text">上传图片</div>
  </div>
);
const formItemLayout = {
  labelCol: {
    lg: {
      span: 4,
      offset: 1,
    },
  },
  wrapperCol: {
    lg: {
      span: 10,
      offset: 0,
    },
  },
};
let date = new Date().getFullYear(), gradeList = [];
for (let i = 0; i <= (date - 1990); i++) {
  gradeList.push({ value: i, label: (1990 + i) })
}
class Edit extends React.Component {
  constructor(props) {
    super(props);
    let { id } = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    id = parseInt(id);
    this.state = {
      uploadStateList: [],
      projectId: id,
      collegeList: [],
      specialityList: [],
      educationList: [],
      grades: gradeList,
      spinning: true,
      industryList: [],
      applyProjectId: null,
      patentTypeList: [],
      financingTypeList: [],
      subordinateTypeList: [],
      patentList: [],
      activePatent: [],
      activeFinancingList: [],
      activePrizeList: [],
      activeOverseasMemberList: [],
      overseasMemberList: [],
      prizeList: [],
      financingList: [],
      userInfo: {},
      previewVisible: false,
      previewImage: "",
      overseasMemberPicList: [],
    };
  }
  componentDidMount() {
    this.init();
  }
  init = async _ => {
    try {
      // 获取行业
      let {
        data: { list: industryList },
      } = await companyApi.getIndustryList({ page: -1, limit: -1 });
      industryList = industryList.map(v => ({
        value: v.id,
        label: v.name,
      }));
      // 获取获奖类型
      const patentTypeList = Object.values(PATENT_TYPE).map(v => ({
        value: v,
        label: PATENT_TYPE_ZH[v],
      }));
      const financingTypeList = Object.values(FinancingType).map(v => ({
        value: v,
        label: FinancingTypeZh[v]
      }))
      // 所属等级
      const subordinateTypeList = Object.values(SubordinateType).map(v => ({
        value: v,
        label: SubordinateTypeZh[v],
      }))
      //获取学历信息
      let educationList = Object.values(EDUCATION).map((v, k) => ({
        value: v,
        label: EDUCATION_ZH[v],
      }));
      // 获得服务器数据
      let { data: { detail, } } = await applyApi.getCreateProjectApplyDetail({ id: this.state.projectId });
      let region = await getRegion();
      let shareholderList = [],
        insuredPersonList = [],
        patentList = [],
        financingList = [],
        prizeList = [], overseasMemberList = [];
      for (let i = 0; i < detail.patentList.length; i++) {
        patentList.push(i);

      }

      for (let i = 0; i < detail.financingRecordList.length; i++) {
        financingList.push(i);
      }
      for (let i = 0; i < detail.prize_id_list.length; i++) {
        prizeList.push(i);
      }
      for (let i = 0; i < detail.overseas_id_list.length; i++) {
        overseasMemberList.push(i);
      }
      let uploadStateList = this.state.uploadStateList
      console.log(detail.project_plan.length)
      if (detail.project_plan.length > 0) {
        for (let v of detail.project_plan) {
          uploadStateList.push({
            uid: v.picId,
            name: v.name,
            url: BASE_URL + v.url
          })
        }
      } else {
        uploadStateList = []
      }
      this.setState({
        spinning: false,
        industryList,
        patentTypeList,
        financingTypeList,
        subordinateTypeList,
        patentList,
        overseasMemberList,
        prizeList,
        educationList,
        region,
        uploadStateList,
        userInfo: {
          patentList: detail.patentList,
          financingList: detail.financingList,
        },
        shareholderList,
        insuredPersonList,
        financingList,
        applyProjectId: detail.info.id
      });
      this.props.form.setFieldsValue({
        // 基本信息
        name: detail.applyUser.name,
        idCardNo: detail.applyUser.id_no,
        phone: detail.applyUser.phone,
        email: detail.applyUser.email,
        // 项目
        projectName: detail.info.name,
        totalNumber: detail.info.totalNumber,
        industry: detail.info.industryId,
        description: detail.info.description,
        shanghaiHouseholder: detail.info.household,
      });
      // 专利
      let activePatent = [];
      for (let i = 0; i < detail.patentList.length; i++) {
        activePatent.push({
          id: detail.patentList[i].id
        })
        this.props.form.setFieldsValue({
          ["patentName" + i]: detail.patentList[i].name,
          ["patentTime" + i]: moment(detail.patentList[i].time, "YYYY-MM-DD HH:mm:ss"),
          ["patentType" + i]: detail.patentList[i].type,
        });
      }
      console.log(activePatent)
      // 融资
      let activeFinancingList = [];
      for (let i = 0; i < detail.financingRecordList.length; i++) {
        activeFinancingList.push({
          id: detail.financingRecordList[i].id
        })
        this.props.form.setFieldsValue({
          ["financingPrice" + i]: detail.financingRecordList[i].money / 100,
          ["financingTime" + i]: moment(
            detail.financingRecordList[i].time,
            "YYYY-MM-DD HH:mm:ss",
          ),
          ["financingSource" + i]: detail.financingRecordList[i].source,
          ["financingMode" + i]: parseInt([detail.financingRecordList[i].mode]),
        });
      }
      // 获奖
      let activePrizeList = []
      for (let i = 0; i < detail.prize_id_list.length; i++) {
        activePrizeList.push({
          id: detail.prize_id_list[i].id
        })
        this.props.form.setFieldsValue({
          ["prizeName" + i]: detail.prize_id_list[i].name,
          ["prizeTime" + i]: moment(detail.prize_id_list[i].win_time, "YYYY-MM-DD HH:mm:ss"),
          ["prizeLevel" + i]: detail.prize_id_list[i].prize_level,
          ["belongLevel" + i]: parseInt([detail.prize_id_list[i].belong_level]),
        });
      }
      // 海外留学成员
      let activeOverseasMemberList = []
      let overseasMemberPicList = this.state.overseasMemberPicList
      for (let i = 0; i < detail.overseas_id_list.length; i++) {
        activeOverseasMemberList.push({
          id: detail.overseas_id_list[i].id
        })
        overseasMemberPicList[i] = [
          {
            uid: detail.overseas_id_list[i].degree_pic_url.id,
            url: BASE_URL + detail.overseas_id_list[i].degree_pic_url.url,
          }
        ]
        this.props.form.setFieldsValue({
          ["overseasMemberName" + i]: detail.overseas_id_list[i].name,
          ["overseasMemberTime" + i]: moment(detail.overseas_id_list[i].graduation_time, "YYYY-MM-DD HH:mm:ss"),
          ["overseasMemberCollege" + i]: detail.overseas_id_list[i].college_id,
          ["overseasMemberSpeciality" + i]: detail.overseas_id_list[i].speciality,
          ["overseasMemberEducation" + i]: detail.overseas_id_list[i].education,
        });
      }

      this.setState({
        overseasMemberPicList,
        activePatent,
        activeFinancingList,
        activeOverseasMemberList,
        activePrizeList,
      })
    } catch (e) {
      this.setState({
        spinning: false,
      });
    }
  };
  //提交
  updateUserInfo = evt => {
    evt.preventDefault();
    this.props.form.validateFields((err, fields) => {
      if (err) {
        return message.error(err[Object.keys(err)[0]].errors[0].message, 1);
      }
      console.log(fields)
      let overseasMemberPicList = this.state.overseasMemberPicList;
      for (let v of overseasMemberPicList) {
        if (v[0].response) {
          let arr = v[0];
          v[0] = {};
          v[0].uid = arr.response.data.picId;
          v[0].url = BASE_URL + arr.response.data.url;
        }
      }
      for (let i = 0; i < uploadList.length; i++) {
        if (uploadList[i].picId) {
          console.log(uploadList[i])
          uploadList[i].uid = uploadList[i].picId.toString();
          delete uploadList[i].picId
        }
      }
      let patentList = [],
        financingList = [],
        prizeList = [],
        overseasMemberList = [];
      // 专利
      for (let i = 0; i < this.state.activePatent.length; i++) {
        let v = this.state.patentList[i]
        patentList.push({
          name: fields["patentName" + v],
          time: moment(fields["patentTime" + v]).format("YYYY-MM-DD HH:mm:ss"),
          type: fields["patentType" + v],
          id: this.state.activePatent[i].id,
        });
      }
      // 融资
      for (let i = 0; i < this.state.activeFinancingList.length; i++) {
        let v = this.state.financingList[i]
        financingList.push({
          id: this.state.activeFinancingList[i].id,
          price: fields["financingPrice" + v] * 100,
          time: moment(fields["financingTime" + v]).format("YYYY-MM-DD HH:mm:ss"),
          source: fields["financingSource" + v],
          mode: fields["financingMode" + v],
        });
      }
      // 获奖
      for (let i = 0; i < this.state.activePrizeList.length; i++) {
        let v = this.state.prizeList[i]
        prizeList.push({
          id: this.state.activePrizeList[i].id,
          name: fields["prizeName" + v],
          time: moment(fields["prizeTime" + v]).format("YYYY-MM-DD HH:mm:ss"),
          prize_level: fields["prizeLevel" + v],
          belong_level: fields["belongLevel" + v],
        });
      }
      // 海外 
      for (let i = 0; i < this.state.activeOverseasMemberList.length; i++) {
        let v = this.state.overseasMemberList[i];
        overseasMemberList.push({
          id: this.state.activeOverseasMemberList[i].id,
          name: fields["overseasMemberName" + v],
          college: fields["overseasMemberCollege" + v],
          speciality: fields["overseasMemberSpeciality" + v],
          time: moment(fields["overseasMemberTime" + v]).format("YYYY-MM-DD HH:mm:ss"),
          education: fields["overseasMemberEducation" + v],
          overseasMemberPic: this.state.overseasMemberPicList[v],
        });
      }
      // 海外修改前
      // for (let v of this.state.overseasMemberList) {
      //   overseasMemberList.push({
      //     name: fields["overseasMemberName" + v],
      //     college: fields["overseasMemberCollege" + v],
      //     speciality: fields["overseasMemberSpeciality" + v],
      //     time: moment(fields["overseasMemberTime" + v]).format("YYYY-MM-DD HH:mm:ss"),
      //     education: fields["overseasMemberEducation" + v],
      //     overseasMemberPic: this.state.overseasMemberPicList[0],
      //   });
      // }
      // let data = {
      //   file: uploadList,
      //   applyProjectId: this.state.applyProjectId,
      //   projectName: fields.projectName,
      //   totalNumber: fields.totalNumber,
      //   industry: fields.industry,
      //   shanghaiHouseholder: fields.shanghaiHouseholder,
      //   description: fields.description,
      //   patentList,
      //   financingList,
      //   prizeList,
      //   overseasMemberList,
      // };
      // console.log(data)
      project.edit({
        file: uploadList,
        applyProjectId: this.state.applyProjectId,
        projectName: fields.projectName,
        totalNumber: fields.totalNumber,
        industry: fields.industry,
        shanghaiHouseholder: fields.shanghaiHouseholder,
        description: fields.description,
        patentList,
        financingList,
        prizeList,
        overseasMemberList,
      }).then(json => {
        message.success("修改成功", 1);
      }).catch(err => {
        message.error("修改失败,失败原因: " + err.msg, 1);
        console.log(err);
      });
    });
  };
  //专利
  addPatent = _ => {
    let fieldNames = [];
    for (let i = 0; i < this.state.patentList.length; i++) {
      fieldNames.push("patentName" + i, "patentTime" + i, "patentType" + i);
    }
    this.props.form.validateFields(fieldNames, (err, fieldsValue) => {
      if (err) {
        let firstErrKey = Object.keys(err)[0];
        return message.error(err[firstErrKey].errors[0].message, 2);
      }
      let { patentList, activePatent } = this.state;
      if (this.state.patentList.length === 0) {
        patentList.push(0);
        activePatent.push({
          id: 0
        })
      } else {
        patentList.push(patentList[patentList.length - 1] + 1);
        activePatent.push({
          id: 0
        });
      }
      this.setState({
        patentList,
        activePatent
      });
    });
  };
  //融资
  addFinancing = _ => {
    let fieldNames = [];
    for (let i = 0; i < this.state.financingList.length; i++) {
      fieldNames.push("financingPrice" + i, "financingTime" + i, "financingSource" + i, "financingMode" + i);
    }
    this.props.form.validateFields(fieldNames, (err, fieldsValue) => {
      if (err) {
        let firstErrKey = Object.keys(err)[0];
        return message.error(err[firstErrKey].errors[0].message, 2);
      }
      let { financingList, activeFinancingList } = this.state;
      if (this.state.financingList.length === 0) {
        financingList.push(0);
        activeFinancingList.push({
          id: 0
        })
      } else {
        financingList.push(financingList[financingList.length - 1] + 1);
        activeFinancingList.push({
          id: 0
        })
      }
      this.setState({
        financingList,
        activeFinancingList,
      });
    });
  };
  // 获奖
  addPrize = _ => {
    let fieldNames = [];
    for (let i = 0; i < this.state.prizeList.length; i++) {
      fieldNames.push("prizeName" + i, "prizeTime" + i, "prizeLevel" + i, "belongLevel" + i);
    }
    this.props.form.validateFields(fieldNames, (err, fieldsValue) => {
      if (err) {
        let firstErrKey = Object.keys(err)[0];
        return message.error(err[firstErrKey].errors[0].message, 2);
      }
      let prizeList = this.state.prizeList;
      let activePrizeList = this.state.activePrizeList;
      if (this.state.prizeList.length === 0) {
        prizeList.push(0);
        activePrizeList.push({
          id: 0
        })
      } else {
        prizeList.push(prizeList[prizeList.length - 1] + 1);
        activePrizeList.push({
          id: 0
        })
      }
      this.setState({
        prizeList,
        activePrizeList

      });
    });
  }
  // 海外留学成员
  addOverseasMembers = _ => {
    let fieldNames = [];
    for (let i = 0; i < this.state.overseasMemberList.length; i++) {
      fieldNames.push("overseasMemberName" + i, "overseasMemberTime" + i, "overseasMemberCollege" + i, "overseasMemberSpeciality" + i, "overseasMemberEducation" + i);
    }
    this.props.form.validateFields(fieldNames, (err, fieldsValue) => {
      if (err) {
        let firstErrKey = Object.keys(err)[0];
        return message.error(err[firstErrKey].errors[0].message, 2);
      }
      console.log(fieldsValue)
      let overseasMemberList = this.state.overseasMemberList;
      let activeOverseasMemberList = this.state.activeOverseasMemberList;
      if (this.state.overseasMemberList.length === 0) {
        overseasMemberList.push(0);
        activeOverseasMemberList.push({
          id: 0
        })
      } else {
        overseasMemberList.push(overseasMemberList[overseasMemberList.length - 1] + 1);
        activeOverseasMemberList.push({
          id: 0
        })
      }
      this.setState({
        overseasMemberList,
        activeOverseasMemberList
      });
    });

  }
  //海外留学学历证明图片上传
  overseasMemberPicChange = (files, idx) => {
    let overseasMemberPicList = this.state
      .overseasMemberPicList,
      filesList = files.fileList;
    overseasMemberPicList[idx] = filesList;
    this.setState({ overseasMemberPicList });
  };
  //上传图片
  businessLicensePicChange = ({ fileList }) => this.setState({ businessLicensePic: fileList });
  bankAccountOpeningCertificatePicChange = ({ fileList }) =>
    this.setState({ bankAccountOpeningCertificatePic: fileList });
  handlePreview = file => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true,
    });
  };
  handleCancel = _ => this.setState({ previewVisible: false });
  // 删除海外
  deleteOverseaMember = index => {
    console.log(index)  //当前数组
    this.props.form.validateFields((err, fields) => {
      console.log(fields)
      delete fields["overseasMemberName" + index]
      delete fields["overseasMemberCollege" + index]
      delete fields["overseasMemberSpeciality" + index]
      delete fields["overseasMemberTime" + index]
      delete fields["overseasMemberEducation" + index]
    })
    let { overseasMemberList, activeOverseasMemberList } = this.state;
    overseasMemberList.splice(index, 1);
    activeOverseasMemberList.splice(index, 1);
    this.setState({
      overseasMemberList,
      activeOverseasMemberList,
    })
  }
  // 删除专利
  deletePatent = index => {
    console.log(index)  //当前数组
    this.props.form.validateFields((err, fields) => {
      console.log(fields)
      delete fields["patentName" + index]
      delete fields["patentTime" + index]
      delete fields["patentType" + index]
    })
    let { patentList, activePatent } = this.state;
    patentList.splice(index, 1);
    activePatent.splice(index, 1);
    this.setState({
      activePatent,
      patentList,
    })
  }
  // 删除融资
  deleteFinancing = index => {
    console.log(index)  //当前数组
    this.props.form.validateFields((err, fields) => {
      console.log(fields)
      delete fields["financingPrice" + index]
      delete fields["financingTime" + index]
      delete fields["financingSource" + index]
      delete fields["financingMode" + index]
    })
    let { financingList, activeFinancingList } = this.state;
    financingList.splice(index, 1);
    activeFinancingList.splice(index, 1);
    this.setState({
      financingList,
      activeFinancingList,
    })
  }
  // 删除获奖
  deleteAward = index => {
    console.log(index)  //当前数组
    this.props.form.validateFields((err, fields) => {
      console.log(fields)
      delete fields["prizeName" + index]
      delete fields["prizeTime" + index]
      delete fields["prizeLevel" + index]
      delete fields["belongLevel" + index]
    })
    let { prizeList, activePrizeList } = this.state;
    prizeList.splice(index, 1);
    activePrizeList.splice(index, 1);
    this.setState({
      prizeList,
      activePrizeList,
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { previewVisible,
      previewImage, } = this.state
    //头像上传按钮
    return (
      <Spin tip="Loading..." spinning={this.state.spinning} size="large">
        <div className={style.info}>
          <Breadcrumb className={style.title}>
            <Breadcrumb.Item><Link to="/admin">首页</Link></Breadcrumb.Item>
            <Breadcrumb.Item><Link to="/admin/apply/allList">申请管理</Link></Breadcrumb.Item>
            <Breadcrumb.Item>项目申请编辑</Breadcrumb.Item>
          </Breadcrumb>
          <div className={style.basicWrap}>
            <div className={style.title}>基本信息</div>
            <Form className={style.form}>
              {/* 基本信息 */}
              <Form.Item {...formItemLayout} label="姓名">
                {getFieldDecorator("name", {
                  rules: [{ required: true, message: "请输入姓名" }],
                })(<Input placeholder="请输入" disabled />)}
              </Form.Item>
              <Form.Item {...formItemLayout} label="电话">
                {getFieldDecorator("phone", {
                  rules: [{ required: true, message: "请输入电话" }],
                })(<Input placeholder="请输入" disabled />)}
              </Form.Item>
              <Form.Item {...formItemLayout} label="邮箱">
                {getFieldDecorator("email", {
                  rules: [{ required: true, message: "请输入邮箱" }],
                })(<Input placeholder="请输入" disabled />)}
              </Form.Item>
              <Form.Item {...formItemLayout} label="身份证号码"  >
                {getFieldDecorator('idCardNo', {
                  rules: [{ required: true, message: '请输入身份证号码' }],
                })(
                  <Input placeholder="请输入身份证号码" disabled />)}
              </Form.Item>

              {/* 项目新加 */}
              <Form.Item {...formItemLayout} label="项目名称">
                {getFieldDecorator("projectName", {
                  rules: [{ required: true, message: "请输入项目名称" }],
                })(<Input placeholder="请输入" />)}
              </Form.Item>
              <Form.Item {...formItemLayout} label="团队总人数">
                {getFieldDecorator("totalNumber", {
                  rules: [{ required: true, message: "请输入" }],
                })(
                  <InputNumber
                    className={style.input}
                    precision={0}
                    placeholder="请输入团队总人数"
                  />,
                )}
              </Form.Item>
              <Form.Item {...formItemLayout} label="团队中上海户籍人数">
                {getFieldDecorator("shanghaiHouseholder", {
                  rules: [{ required: true, message: "请输入" }],
                })(
                  <InputNumber
                    className={style.input}
                    precision={0}
                    placeholder="请输入团队中上海户籍人数"
                  />,
                )}
              </Form.Item>
              <Form.Item {...formItemLayout} label="所属行业">
                {getFieldDecorator("industry", {
                  rules: [{ required: true, message: "请选择" }],
                })(
                  <Select placeholder="请选择">
                    {this.state.industryList.map((v, k) => {
                      return (
                        <Option key={`${k}`} value={v.value}>
                          {v.label}
                        </Option>
                      );
                    })}
                  </Select>,
                )}
              </Form.Item>
              <Form.Item {...formItemLayout} label="上传附件">
                <div className={style.tips}>提示:如果有负责人简历,专利证书,融资证明文件,获奖证书,
         项目计划书，商业计划书等,请上传一张对应的附件</div>
                <Upload {...upload} defaultFileList={this.state.uploadStateList}>
                  <Button className={style.uploadBtn} >
                    上传
                  </Button>
                </Upload>
              </Form.Item>
              {/* 列表 */}
              <Row>
                <Col span={10} offset={5}>
                  <div className={style.panel}>
                    <Collapse defaultActiveKey={["1"]}>
                      <Panel
                        header={
                          <div>
                            专利列表
                          {this.state.patentList.length > 0 ? (
                              <Badge
                                style={{ marginLeft: "10px" }}
                                count={this.state.patentList.length}
                              />
                            ) : null}
                          </div>
                        }
                        key="1">
                        <div
                          className={
                            this.state.patentList.length > 0 ? style.shareholderList : style.hidden
                          }>
                          {this.state.patentList.map((v, k) => {
                            return (
                              <div key={k} className={style.shareholderItem}>
                                <div className={style.shareholderTitle}>
                                  <div className={style.theme}>专利 {v + 1}</div>
                                  <div
                                    className={style.icon}
                                    // onClick={_ => {
                                    //   let patentList = this.state.patentList;
                                    //   patentList.splice(k, 1);
                                    //   this.setState({
                                    //     patentList,
                                    //   });
                                    // }}
                                    onClick={_ => { this.deletePatent(k) }}
                                  >
                                    <Icon type="close-circle" />
                                  </div>
                                </div>
                                <div className={style.item}>
                                  <div className={style.label}>专利名称</div>
                                  <div className={style.input}>
                                    {getFieldDecorator("patentName" + v, {
                                      rules: [{ required: true, message: `请输入专利${k + 1}姓名` }],
                                    })(<Input className={style.input} placeholder="请输入" />)}
                                  </div>
                                </div>
                                <div className={style.item}>
                                  <div className={style.label}>获得时间</div>
                                  <div className={style.input}>
                                    {getFieldDecorator("patentTime" + v, {
                                      rules: [
                                        { required: true, message: `请选择专利${k + 1}获得时间` },
                                      ],
                                    })(<DatePicker className={style.input} placeholder="请选择" />)}
                                  </div>
                                </div>
                                <div className={style.item}>
                                  <div className={style.label}>专利类型</div>
                                  <div className={style.input}>
                                    {getFieldDecorator("patentType" + v, {
                                      rules: [
                                        { required: true, message: `请选择专利${k + 1}专利类型` },
                                      ],
                                    })(
                                      <Select placeholder="请选择">
                                        {this.state.patentTypeList.map((v, k) => {
                                          return (
                                            <Option key={`${k}`} value={v.value}>
                                              {v.label}
                                            </Option>
                                          );
                                        })}
                                      </Select>,
                                    )}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        <Empty
                          style={{ marginBottom: 15 }}
                          className={this.state.patentList.length > 0 ? style.hidden : ""}
                        />
                        <div className={style.shareholderAdd}>
                          <Icon type="plus" onClick={this.addPatent} />
                        </div>
                      </Panel>
                      <Panel
                        header={
                          <div>
                            融资列表
                          {this.state.financingList.length > 0 ? (
                              <Badge
                                style={{ marginLeft: "10px" }}
                                count={this.state.financingList.length}
                              />
                            ) : null}
                          </div>
                        }
                        key="2">
                        <div
                          className={
                            this.state.financingList.length > 0 ? style.shareholderList : style.hidden
                          }>
                          {this.state.financingList.map((v, k) => {
                            return (
                              <div key={k} className={style.shareholderItem}>
                                <div className={style.shareholderTitle}>
                                  <div className={style.theme}>融资记录 {v + 1}</div>
                                  <div
                                    className={style.icon}
                                    // onClick={_ => {
                                    //   let financingList = this.state.financingList;
                                    //   financingList.splice(k, 1);
                                    //   this.setState({
                                    //     financingList,
                                    //   });
                                    // }}
                                    onClick={_ => { this.deleteFinancing(k) }}
                                  >
                                    <Icon type="close-circle" />
                                  </div>
                                </div>
                                <div className={style.item}>
                                  <div className={style.label}>融资金额(元)</div>
                                  <div className={style.input}>
                                    {getFieldDecorator("financingPrice" + v, {
                                      rules: [{ required: true, message: `请输入融资${k + 1}金额` }],
                                    })(
                                      <InputNumber
                                        precision={2}
                                        className={style.input}
                                        placeholder="请输入"
                                      />,
                                    )}
                                  </div>
                                </div>
                                <div className={style.item}>
                                  <div className={style.label}>融资时间</div>
                                  <div className={style.input}>
                                    {getFieldDecorator("financingTime" + v, {
                                      rules: [
                                        { required: true, message: `请选择融资${k + 1}融资时间` },
                                      ],
                                    })(<DatePicker className={style.input} placeholder="请选择" />)}
                                  </div>
                                </div>
                                <div className={style.item}>
                                  <div className={style.label}>融资来源</div>
                                  <div className={style.input}>
                                    {getFieldDecorator("financingSource" + v, {
                                      rules: [{ required: true, message: `请选择融资${k + 1}来源` }],
                                    })(<Input className={style.input} placeholder="请输入" />)}
                                  </div>
                                </div>
                                <div className={style.item}>
                                  <div className={style.label}>融资形式</div>
                                  {/* <div className={style.input}>
                                    {getFieldDecorator("financingMode" + v, {
                                      rules: [{ required: true, message: `请选择融资${k + 1}形式` }],
                                    })(<Input className={style.input} placeholder="请输入" />)}
                                  </div> */}
                                  <div className={style.input}>
                                    {getFieldDecorator("financingMode" + v, {
                                      rules: [
                                        { required: true, message: `请选择融资${k + 1}形式` },
                                      ],
                                    })(
                                      <Select placeholder="请选择">
                                        {this.state.financingTypeList.map((v, k) => {
                                          return (
                                            <Option key={`${k}`} value={v.value}>
                                              {v.label}
                                            </Option>
                                          );
                                        })}
                                      </Select>,
                                    )}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        <Empty
                          style={{ marginBottom: 15 }}
                          className={this.state.financingList.length > 0 ? style.hidden : ""}
                        />
                        <div className={style.shareholderAdd}>
                          <Icon type="plus" onClick={this.addFinancing} />
                        </div>
                      </Panel>
                      <Panel
                        header={
                          <div>
                            获奖列表
                          {this.state.prizeList.length > 0 ? (
                              <Badge
                                style={{ marginLeft: "10px" }}
                                count={this.state.prizeList.length}
                              />
                            ) : null}
                          </div>
                        }
                        key="3">
                        <div
                          className={
                            this.state.prizeList.length > 0 ? style.shareholderList : style.hidden
                          }>
                          {this.state.prizeList.map((v, k) => {
                            return (
                              <div key={k} className={style.shareholderItem}>
                                <div className={style.shareholderTitle}>
                                  <div className={style.theme}>奖项 {v + 1}</div>
                                  <div
                                    className={style.icon}
                                    onClick={_ => { this.deleteAward(k) }}
                                  // onClick={_ => {
                                  //   let prizeList = this.state.prizeList;
                                  //   prizeList.splice(k, 1);
                                  //   this.setState({
                                  //     prizeList,
                                  //   });
                                  // }}
                                  >
                                    <Icon type="close-circle" />
                                  </div>
                                </div>
                                <div className={style.item}>
                                  <div className={style.label}>获奖名称</div>
                                  <div className={style.input}>
                                    {getFieldDecorator("prizeName" + v, {
                                      rules: [{ required: true, message: `请输入获奖${k + 1}名称` }],
                                    })(<Input className={style.input} placeholder="请输入" />)}
                                  </div>
                                </div>
                                <div className={style.item}>
                                  <div className={style.label}>获奖等级</div>
                                  <div className={style.input}>
                                    {getFieldDecorator("prizeLevel" + v, {
                                      rules: [{ required: true, message: `请输入获奖${k + 1}获奖等级` }],
                                    })(<Input className={style.input} placeholder="请输入" />)}
                                  </div>
                                </div>
                                <div className={style.item}>
                                  <div className={style.label}>所属等级</div>
                                  {/* <div className={style.input}>
                                    {getFieldDecorator("belongLevel" + v, {
                                      rules: [{ required: true, message: `请输入获奖${k + 1}所属等级` }],
                                    })(<Input className={style.input} placeholder="请输入" />)}
                                  </div> */}
                                  <div className={style.input}>
                                    {getFieldDecorator("belongLevel" + v, {
                                      rules: [
                                        { required: true, message: `请选择获奖${k + 1}所属等级` },
                                      ],
                                    })(
                                      <Select placeholder="请选择">
                                        {this.state.subordinateTypeList.map((v, k) => {
                                          return (
                                            <Option key={`${k}`} value={v.value}>
                                              {v.label}
                                            </Option>
                                          );
                                        })}
                                      </Select>,
                                    )}
                                  </div>
                                </div>
                                <div className={style.item}>
                                  <div className={style.label}>获奖时间</div>
                                  <div className={style.input}>
                                    {getFieldDecorator("prizeTime" + v, {
                                      rules: [
                                        { required: true, message: `请选择${k + 1}获奖时间` },
                                      ],
                                    })(<DatePicker className={style.input} placeholder="请选择" />)}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        <Empty
                          style={{ marginBottom: 15 }}
                          className={this.state.prizeList.length > 0 ? style.hidden : ""}
                        />
                        <div className={style.shareholderAdd}>
                          <Icon type="plus" onClick={this.addPrize} />
                        </div>
                      </Panel>
                      <Panel
                        header={
                          <div>
                            海外留学成员
                          {this.state.overseasMemberList.length > 0 ? (
                              <Badge
                                style={{ marginLeft: "10px" }}
                                count={this.state.overseasMemberList.length}
                              />
                            ) : null}
                          </div>
                        }
                        key="4">
                        <div
                          className={
                            this.state.overseasMemberList.length > 0 ? style.shareholderList : style.hidden
                          }>
                          {this.state.overseasMemberList.map((v, k) => {
                            return (
                              <div key={k} className={style.shareholderItem}>
                                <div className={style.shareholderTitle}>
                                  <div className={style.theme}>海外成员 {v + 1}</div>
                                  <div
                                    className={style.icon}
                                    onClick={_ => {
                                      this.deleteOverseaMember(k)

                                    }}>
                                    <Icon type="close-circle" />
                                  </div>
                                </div>
                                <div className={style.item}>
                                  <div className={style.label}>姓名</div>
                                  <div className={style.input}>
                                    {getFieldDecorator("overseasMemberName" + v, {
                                      rules: [{ required: true, message: `请输入海外成员${k + 1}姓名` }],
                                    })(<Input className={style.input} placeholder="请输入" />)}
                                  </div>
                                </div>
                                <div className={style.item}>
                                  <div className={style.label}>学校</div>
                                  <div className={style.input}>
                                    {getFieldDecorator("overseasMemberCollege" + v, {
                                      rules: [{ required: true, message: `请输入海外成员${k + 1}学校` }],
                                    })(<Input className={style.input} placeholder="请输入" />)}
                                  </div>
                                </div>
                                <div className={style.item}>
                                  <div className={style.label}>专业</div>
                                  <div className={style.input}>
                                    {getFieldDecorator("overseasMemberSpeciality" + v, {
                                      rules: [{ required: true, message: `请输入海外成员${k + 1}专业` }],
                                    })(<Input className={style.input} placeholder="请输入" />)}
                                  </div>
                                </div>
                                <div className={style.item}>
                                  <div className={style.label}>毕业时间</div>
                                  <div className={style.input}>
                                    {getFieldDecorator("overseasMemberTime" + v, {
                                      rules: [
                                        { required: true, message: `请选择海外成员${k + 1}毕业时间` },
                                      ],
                                    })(<DatePicker className={style.input} placeholder="请选择" />)}
                                  </div>
                                </div>
                                <div className={style.item}>
                                  <div className={style.label}>学历</div>
                                  <div className={style.input}>
                                    {getFieldDecorator("overseasMemberEducation" + v, {
                                      rules: [
                                        { required: true, message: `请选择海外成员${k + 1}学历` },
                                      ],
                                    })(
                                      <Select placeholder="请选择">
                                        {this.state.educationList.map((v, k) => {
                                          return (
                                            <Option key={`${k}`} value={v.value}>
                                              {v.label}
                                            </Option>
                                          );
                                        })}
                                      </Select>,
                                    )}
                                  </div>
                                </div>
                                <div className={style.item}>
                                  <div className={style.label}>学历证明图片</div>
                                  <div className={style.input}>
                                    {getFieldDecorator(
                                      "overseasMemberPic" + v,
                                      {},
                                    )(
                                      <div className="clearfix">
                                        <Upload
                                          action={getUploadImgUrl()}
                                          listType="picture-card"
                                          fileList={
                                            this.state
                                              .overseasMemberPicList[v]
                                          }
                                          onPreview={this.handlePreview}
                                          onChange={files =>
                                            this.overseasMemberPicChange(
                                              files,
                                              v,
                                            )
                                          }>
                                          {this.state.overseasMemberPicList[v] && this.state.overseasMemberPicList[v].length > 0 ? null : uploadButton}
                                        </Upload>
                                        <Modal
                                          visible={previewVisible}
                                          footer={null}
                                          onCancel={this.handleCancel}>
                                          <img
                                            alt="example"
                                            style={{ width: "100%" }}
                                            src={previewImage}
                                          />
                                        </Modal>
                                      </div>,
                                    )}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        <Empty
                          style={{ marginBottom: 15 }}
                          className={this.state.overseasMemberList.length > 0 ? style.hidden : ""}
                        />
                        <div className={style.shareholderAdd}>
                          <Icon type="plus" onClick={this.addOverseasMembers} />
                        </div>
                      </Panel>
                    </Collapse>
                  </div>
                </Col>
              </Row>
              <Form.Item {...formItemLayout} label="项目描述">
                {getFieldDecorator("description", {})(<TextArea rows={4} placeholder="请输入" />)}
              </Form.Item>
              <Form.Item className={style.control}>
                <Button className={style.btn} type="primary" onClick={this.updateUserInfo}>更新基本信息</Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Spin >
    );
  }
}
export default Form.create()(Edit);
