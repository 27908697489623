import { bget, bpost } from './api';

export async function applyParkingPermit({ carUserIdCardNo, contactInfo,
  driverLicensePic, drivingLicensePic, idCardNo, licenseNum, socialImg, idCardFirstPic, idCardSecondPic }) {
  return bpost({
    url: '/apply/parkingPermit',
    data: {
      carUserIdCardNo,
      contactInfo,
      driverLicensePic,
      drivingLicensePic, idCardNo, licenseNum, socialImg, idCardFirstPic, idCardSecondPic
    }
  })
}
// 编辑停车申请
export async function edit({ applyId, admin = true, contactInfo,
  driverLicensePic, drivingLicensePic, licenseNum, socialImg, idCardFirstPic, idCardSecondPic }) {
  return bpost({
    url: '/editParkingPermit',
    data: {
      applyId, admin,
      contactInfo,
      driverLicensePic,
      drivingLicensePic, licenseNum, socialImg, idCardFirstPic, idCardSecondPic
    }
  })
}

export async function parkingPermitDetail(id) {
  return bget({
    url: '/parkingPermit/detail',
    query: {
      id,
    }
  })
}

// 后台-公司-停车证
export async function editCompanyParkingPermit({ companyId, parkingPermitId, name, contactInfo }) {
  return bpost({
    url: '/parkingPermit/apply',
    data: {
      companyId,
      parkingPermitId,
      name,
      contactInfo,
    }
  })
}


export default {
  applyParkingPermit,
  parkingPermitDetail,
  editCompanyParkingPermit,
  edit,
}
