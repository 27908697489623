import React, { Component } from "react";
import { NavBar, Icon, ActivityIndicator, } from "antd-mobile";
import { Link, } from "react-router-dom";
import gStyle from "../../../utils/sass";
import MealCardList from "../../../components/index/company/MealCardList";
const style = gStyle.index.company.mealCard

export default class MealCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAnimating: true,
      list: [],
    }
  }
  componentDidMount() {
    this.init()
  }
  init = _ => {
    let list = [
      {
        id: 1,
        name: "吴二伟",
        cardNo: "EA45685658956556",
        beginTime: "2018-01-01 00:00",
        expireTime: "2018-12-12 00:00:00",
        paymentStatus: 0,
      },
      {
        id: 2,
        name: "吴二伟",
        cardNo: "EA45685658956556",
        beginTime: "2018-01-01 00:00",
        expireTime: "2018-12-12 00:00:00",
        paymentStatus: 1,
      },
    ];
    this.setState({
      isAnimating: false,
      list,
    })
  }
  render() {
    return (<div className={style.index}>
      <ActivityIndicator animating={this.state.isAnimating} className={style.loading} text="loading..." />
      <NavBar className={style.header} mode="light" icon={<Link to="/company/index"><Icon type="left" /></Link>}
        rightContent={this.state.list.length < 3 ? [<div key="0" style={{ marginRight: '16px', fontSize: 14 }} >
          <Link to="/user/apply/mealCard">申请饭卡</Link></div>] : null}
      >申请饭卡列表</NavBar>
      <MealCardList list={this.state.list} />
    </div >);
  }
}
