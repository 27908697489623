import React from "react";
import { Route, Link, } from "react-router-dom";
import {
  Form, Menu,
} from 'antd';
import { getUploadImgUrl } from '../../../services/api';
import ProjectBaseInfo from "./BaseInfo";
import ProjectOwnEr from "./OwnEr";
import ProjectManagement from "./Management";
import gSass from '../../../utils/sass';
import qs from "qs";

const style = gSass.admin.project.base;

class Base extends React.Component {
  constructor(props) {
    super(props);
    const companyInfo = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    this.state = {
      companyId: companyInfo.id,
      previewVisible: false,
      previewImage: '',
      fileList: [],
      uploadUrl: getUploadImgUrl,
      menuKey: 1,
    }
  }
  componentDidMount() {

  }
  menuOnSelect = evt => {
    this.setState({
      menuKey: parseInt(evt.key),
    })
  }
  render() {
    return (
      <div className={style.base}>
        <div className={style.baseBox}>
          <div className={style.left}>
            <Menu mode="inline" defaultSelectedKeys={["1"]}
              onSelect={this.menuOnSelect}>
              <Menu.Item key={"1"}>
                <Link to={`/admin/project/info/baseInfo?id=${this.state.companyId}`}><span>基本信息</span></Link>
              </Menu.Item>
              <Menu.Item key={"2"}>
                <Link to={`/admin/project/info/leagelPerson?id=${this.state.companyId}`}><span>项目负责人信息</span></Link>
              </Menu.Item>
              <Menu.Item key={"3"}>
                <Link to={`/admin/project/info/management?id=${this.state.companyId}`}><span>项目管理信息</span></Link>
              </Menu.Item>
            </Menu>
          </div>
          <div className={style.right}>
            <Route path={`/admin/project/info/baseInfo`} component={ProjectBaseInfo} />
            <Route path={`/admin/project/info/leagelPerson`} component={ProjectOwnEr} />
            <Route path={`/admin/project/info/management`} component={ProjectManagement} />
          </div>
        </div>
      </div >
    );
  }
}

export default Form.create()(Base);

