import { bget, bpost } from './api';

export const TYPE = {
  'airConditioning': 0x0,
  'property': 0x1,
  'hatchService': 0x2,
};
export const TYPE_ZH = {
  [TYPE.airConditioning]: '空调费',
  [TYPE.property]: '物业费',
  [TYPE.hatchService]: '孵化服务费',
};
export const STATUS = {
  'notPay': 0x0,
  'hasPay': 0x1,
  'delayPay': 0x2,
};
export const STATUS_ZH = {
  [STATUS.notPay]: '未缴纳',
  [STATUS.hasPay]: '已缴纳',
  [STATUS.delayPay]: '延期缴纳',
};

export async function addCompanyApply({
  name, totalNumber, industryId, mainBusiness, patentList,
  registerTime, registerAddress, registerAddressDetail, awardRecordList,
  registerCapital,
  financingList, shareholderList, businessLicensePic,
  bankAccountOpeningCertificatePic,
  entrepreneurs, teamMemberNumberHai, overseasBg,
  paySocialSecurityNum, file
}) {
  return bpost({
    url: '/apply/companyEnter',
    data: {
      name, totalNumber, industryId, mainBusiness, patentList,
      registerTime, registerAddress, registerAddressDetail, awardRecordList,
      registerCapital,
      financingList, shareholderList, businessLicensePic,
      bankAccountOpeningCertificatePic, entrepreneurs, teamMemberNumberHai, overseasBg,
      paySocialSecurityNum, file
    }
  })
}
// 后台编辑
export async function edit({
  applyId,admin = true, cname, totalNumber, teamMemberNumberHai, industryId, mainBusiness,
  registerTime, registerAddress, registerAddressDetail, registerCapital, paySocialSecurityNum, patentList, financingList, prizeList, shareholderList, businessLicensePic,
  bankAccountOpeningCertificatePic,
  entrepreneurs, overseasMemberList, file
}) {
  return bpost({
    url: '/editCompanyEnter',
    data: {
      applyId, admin, cname, totalNumber, teamMemberNumberHai, industryId, mainBusiness,
      registerTime, registerAddress, registerAddressDetail, registerCapital, paySocialSecurityNum, patentList, financingList, prizeList, shareholderList, businessLicensePic,
      bankAccountOpeningCertificatePic,
      entrepreneurs, overseasMemberList, file
    }
  })
}
export async function companyDetail({ id }) {
  return bget({
    url: '/companyDetail',
    query: {
      id,
    }
  })
}
// 学校专业
export async function addIndustry({ name, }) {
  return bpost({
    url: '/addCompanyIndustry',
    data: {
      name,
    }
  })
}
/**
 * 删除行业
 */
export async function deleteIndustry(idArr) {
  return bpost({
    url: '/deleteCompanyIndustry',
    data: {
      idArr,
    }
  })
}
/**
 * 编辑行业
 */
export async function editIndustry({
  id, name,
}) {
  return bpost({
    url: '/editCompanyIndustry',
    data: {
      id,
      name,
    }
  })
}
/**
 * 获取行业详情
 */
export async function industryDetail(id) {
  return bget({
    url: '/companyIndustryDetail',
    query: {
      id,
    }
  })
}
/**
 * 获取行业列表
 */
export async function getIndustryList({ page, limit, filter = {} }) {
  return bget({
    url: '/companyIndustryList',
    query: {
      page,
      limit,
      filter,
    }
  })
}
/**
 * 获取公司列表
 */
export async function getList({ page, limit, filter = {} }) {
  return bget({
    url: '/admin/getCompanyList',
    query: {
      page,
      limit,
      filter,
    }
  })
}
/**
 * 筛选公司列表
 */
export async function companyFilter({ date }) {
  return bget({
    url: '',
    query: {
      date,
    }
  })
}
/**
 * 获取股东列表
 */
export async function getShareholderList({ page, limit, filter = {} }) {
  return bget({
    url: '/getShareholderList',
    query: {
      page,
      limit,
      filter,
    }
  })
}
/**
 * 获取法人信息
 */
export async function getLegalPersonInfo({ id }) {
  return bget({
    url: '/getLegalPersonInfo',
    query: {
      id,
    },
  })
}
/**
 * 获取饭卡列表
 */
export async function getMealCardList({ page, limit, filter = {} }) {
  return bget({
    url: '/getMealCardList',
    query: {
      page,
      limit,
      filter,
    },
  })
}
/**
 * 获取停车证列表
 */
export async function getParkingPermitList({ page, limit, filter = {} }) {
  return bget({
    url: '/getParkingPermitList',
    query: {
      page,
      limit,
      filter,
    },
  })
}
/**
 *
 * 获取缴费记录列表
 * @export
 * @param {*} { page, limit, filter = {} }
 * @returns
 */
export async function getPaymentRecordList({ page, limit, filter = {} }) {
  return bget({
    url: '/company/getPaymentRecordList',
    query: {
      page,
      limit,
      filter,
    },
  })
}

/**
 * @export
 * @param {*} {
 *   subjectId, subjectType, paymentType, paymentStatus, date, money,
 * }
 * @returns
 */
export async function addPaymentRecord({
  subjectId, subjectType, paymentType, paymentStatus, date, money,
}) {
  return bpost({
    url: '/company/addPaymentRecord',
    data: {
      subjectId, subjectType, paymentType, paymentStatus, date, money,
    }
  })
}

/**
 * 编辑缴费记录
 * @export
 * @param {*} {
 *   id, paymentType, paymentStatus, money,
 * }
 * @returns
 */
export async function editPaymentRecord({
  id, paymentType, paymentStatus, paymentMoney,
}) {
  return bpost({
    url: '/company/editPaymentRecord',
    data: {
      id, paymentType, paymentStatus, paymentMoney,
    }
  })
}
export async function deletePaymentRecord(idArr) {
  return bpost({
    url: '/company/deletePaymentRecord',
    data: {
      idArr,
    }
  })
}
export default {
  deletePaymentRecord,
  addPaymentRecord,
  editPaymentRecord,
  getPaymentRecordList,
  getMealCardList,
  getParkingPermitList,
  getLegalPersonInfo,
  getShareholderList,
  getList,
  addCompanyApply,
  companyDetail,
  addIndustry,
  deleteIndustry,
  editIndustry,
  industryDetail,
  getIndustryList,
  edit,
  companyFilter
}
