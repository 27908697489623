import { bget, bpost } from './api';

// 学校专业

export async function add({ name, }) {
  return bpost({
    url: '/addSchoolSpeciality',
    data: {
      name,
    }
  })
}
export async function deleteSpeciality(idArr) {
  return bpost({
    url: '/deleteSchoolSpeciality',
    data: {
      idArr,
    }
  })
}
export async function edit({
  id, name,
}) {
  return bpost({
    url: '/editSchoolSpeciality',
    data: {
      id,
      name,
    }
  })
}
export async function detail(id) {
  return bget({
    url: '/schoolSpecialityDetail',
    query: {
      id,
    }
  })
}
export async function getList({ page, limit, filter = {} }) {
  return bget({
    url: '/schoolSpecialityList',
    query: {
      page,
      limit,
      filter,
    }
  })
}
//后台编辑学校认证
export async function editSchool({
  schoolId, idCardName, college, speciality, grade, education, isGraduate, isOversea, overseaCollege, overseaSpeciality, overseaTime, overseasCard, overseaEducation, oneCardPassPic, degreePic
}) {
  return bpost({
    url: '/editSchoolInfoAuth',
    data: {
      schoolId, idCardName, college, speciality, grade, education, isGraduate, isOversea, overseaCollege, overseaSpeciality, overseaTime, overseasCard, overseaEducation, oneCardPassPic, degreePic
    }
  })
}
export default {
  add,
  deleteSpeciality,
  edit,
  detail,
  getList,
  editSchool,
}
