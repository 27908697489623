import React, { Component } from "react"
import { Button, Form, Icon, Input, message, Tabs, Tooltip } from "antd"
import gImg from "../../../../utils/img"
import userApi, { REGISTER_TYPE } from "../../../../services/user"
import gSass from "../../../../utils/sass"
import { withRouter } from "react-router-dom"
import { BASE_URL } from "../../../../config/prod"

const style = gSass.index.user.common.register
const TabPane = Tabs.TabPane

class Register extends Component {
  formItemLayout = {
    wrapperCol: {
      xs: { span: 20, offset: 2 },
      sm: { span: 5, offset: 10 },
    },
  }
  constructor(props) {
    super(props)
    this.state = {
      verifyCodePicUrl: BASE_URL + "/getVerifyCode",
      registerType: REGISTER_TYPE.EMAIL_VERIFY_CODE_REGISTER,
      phoneVerifiyCode: "发送验证码",
      emailVerifiyCode: "发送验证码",
      canSendPhoneVerifyCode: false,
      canSendEmailVerifyCode: false,
    }
  }
  //注册
  registerSubmit = evt => {
    evt.preventDefault()
    let fieldNames = []
    if (this.state.registerType === REGISTER_TYPE.PHONE_VERIFY_CODE_REGISTER) {
      fieldNames = ["phoneUname", "phonePwd", "phone", "phonConfirm", "phoneVerifiyCode"]
    } else {
      fieldNames = ["emailUname", "emailPwd", "emailConfirm", "email", "emailVerifiyCode"]
    }
    this.props.form.validateFields(fieldNames, (err, fieldsValue) => {
      if (err !== null) {
        return message.error(err[0].error, 3)
      }
      let data
      if (this.state.registerType === REGISTER_TYPE.PHONE_VERIFY_CODE_REGISTER) {
        data = {
          uname: fieldsValue.phoneUname,
          pwd: fieldsValue.phonePwd,
          rePwd: fieldsValue.phonConfirm,
          phone: fieldsValue.phone,
          phoneVerifiyCode: fieldsValue.phoneVerifiyCode,
          registerType: this.state.registerType,
        }
      } else {
        data = {
          uname: fieldsValue.emailUname,
          pwd: fieldsValue.emailPwd,
          rePwd: fieldsValue.emailConfirm,
          // email: fieldsValue.email,
          emailVerifiyCode: fieldsValue.emailVerifiyCode,
          registerType: this.state.registerType,
        }
      }

      userApi
        .register(data)
        .then(json => {
          message.success("注册成功", 1)
          this.props.history.push("/user/login")
        })
        .catch(err => {
          message.success("注册失败,失败原因: " + err.msg, 1)
          this.refreshVerifyCode()
          console.log(err)
        })
    })
  }
  //选择注册类型
  selectLoginType = val => {
    let registerType =
      val === REGISTER_TYPE.PHONE_VERIFY_CODE_REGISTER
        ? REGISTER_TYPE.EMAIL_VERIFY_CODE_REGISTER
        : REGISTER_TYPE.PHONE_VERIFY_CODE_REGISTER
    this.setState({
      registerType,
    })
  }
  //发送手机号码验正码
  senPhoneVerifyCode = _ => {
    let s = 60
    let that = this
    let timer = setInterval(_ => {
      s--
      if (s > 0) {
        that.setState({
          phoneVerifiyCode: s + "秒",
          canSendPhoneVerifyCode: true,
        })
      } else {
        clearInterval(timer)
        that.setState({
          phoneVerifiyCode: "发送验证码",
          canSendPhoneVerifyCode: false,
        })
      }
    }, 1000)
  }
  //发送邮箱验正码
  senEmailVerifyCode = async _ => {
    try {
      await userApi.sendEmailRegisterVerifyCode({ email: this.props.form.getFieldValue("email") })
      let s = 60
      let that = this
      let timer = setInterval(_ => {
        s--
        if (s > 0) {
          that.setState({
            emailVerifiyCode: s + "秒",
            canSendEmailVerifyCode: true,
          })
        } else {
          clearInterval(timer)
          that.setState({
            emailVerifiyCode: "发送验证码",
            canSendEmailVerifyCode: false,
          })
        }
      }, 1000)
    } catch (err) {
      console.log(err)
      message.error("发送验证码失败,错误消息: " + err.msg)
    }
  }
  //phone确认密码
  validPhoneRegisterPwdEqRePwd = (rule, val, cb) => {
    const form = this.props.form
    if (val && this.state.phonConfirmDirty) {
      form.validateFields(["phonConfirm"], { force: true })
    }
    cb()
  }
  validPhoneRegisterPwdEqPwd = (rule, val, cb) => {
    const form = this.props.form
    if (val && val !== form.getFieldValue("phonePwd")) {
      cb("两次密码不一致!")
    } else {
      cb()
    }
  }
  handlPhoneConfirmBlur = evt => {
    const value = evt.target.value
    this.setState({ phonConfirmDirty: this.state.phonConfirmDirty || !!value })
  }

  //email确认密码
  validEmailRegisterPwdEqRePwd = (rule, value, callback) => {
    const form = this.props.form
    if (value && this.state.emailConfirmDirty) {
      form.validateFields(["emailConfirm"], { force: true })
    }
    callback()
  }
  validEmailRegisterPwdEqPwd = (rule, value, callback) => {
    const form = this.props.form
    if (value && value !== form.getFieldValue("emailPwd")) {
      callback("两次密码不一致!")
    } else {
      callback()
    }
  }
  handlEmailConfirmBlur = evt => {
    const value = evt.target.value
    this.setState({ emailConfirmDirty: this.state.emailConfirmDirty || !!value })
  }
  /**
   * 刷新验证码
   */
  refreshVerifyCode = () => {
    this.setState({
      verifyCodePicUrl: BASE_URL + "/getVerifyCode?" + new Date().getTime(),
    })
  }
  render() {
    const { getFieldDecorator } = this.props.form

    return (
      <div className={style.register}>
        <div className={style.loginBox}>
          <div className={style.logo}>
            <img src={`${gImg.login.loginLogo}`} alt="logo" className={style.logoImg} />
          </div>
          <div className={style.title}>新因子OA系统</div>
          <Tabs
            defaultActiveKey="2"
            animated={false}
            className={style.tabs}
            onChange={this.selectLoginType}>
            {/* <TabPane tab={<span>手机注册</span>} key="1">
              <Form className={style.form}>
                <Form.Item {...this.formItemLayout} >
                  {getFieldDecorator('phoneUname', {
                    rules: [{
                      min: 2, max: 32, required: true,
                      message: '请输入数字、字母、下划线、横杆组合2-32的账号'
                    }],
                  })(
                    <Input size="large" prefix={<Icon type="user"
                      style={{ color: 'rgba(0,0,0,.25)' }} />}
                      placeholder="请输入(允许输入数字、字母、下划线 '_'、横杆 '-')" />
                  )}
                </Form.Item>
                <Form.Item {...this.formItemLayout} >
                  {getFieldDecorator('phonePwd', {
                    rules: [{ min: 4, max: 32, required: true, message: '请输入4-32位密码', }, {
                      validator: this.validPhoneRegisterPwdEqRePwd,
                    }],
                  })(
                    <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                      size="large" type="password" placeholder="请输入4-32位密码" />
                  )}
                </Form.Item>
                <Form.Item {...this.formItemLayout}  >
                  {getFieldDecorator('phonConfirm', {
                    rules: [{ required: true, message: "请确认密码", },
                    { validator: this.validPhoneRegisterPwdEqPwd, }],
                  })(
                    <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                      size="large" type="password" placeholder="请确认密码"
                      onBlur={this.handlPhoneConfirmBlur} />
                  )}
                </Form.Item>
                <Form.Item {...this.formItemLayout} >
                  {getFieldDecorator('phone', {
                    rules: [{
                      required: true, message: '请输入正确手机号',
                      pattern: /^[1][3,4,5,6,7,8][0-9]{9}$/,
                    }],
                  })(
                    <Input size="large" prefix={<Icon type="phone" style={{ color: 'rgba(0,0,0,.25)' }} />}
                      placeholder="请输入您的手机号" />
                  )}
                </Form.Item>
                <Form.Item {...this.formItemLayout} >
                  {getFieldDecorator('phoneVerifiyCode', {
                    rules: [{ required: true, message: '请输入验证码', }],
                  })(
                    <div className={style.verificationCode}>
                      <Input size="large" placeholder="请输入验证码"
                        className={style.verificationCodeTitle} />
                      <Button className={style.verificationCodeBtn} size="large"
                        onClick={this.senPhoneVerifyCode}
                        disabled={this.state.canSendPhoneVerifyCode}>{this.state.phoneVerifiyCode}</Button>
                    </div>
                  )}
                </Form.Item>
                <Form.Item {...this.formItemLayout}>
                  <div className={style.btnBox}>
                    <Button type="primary" className={style.btn} size="large"
                      onClick={this.registerSubmit} htmlType="submit">注册</Button>
                    <a href="/user/login">使用现有账户</a>
                  </div>
                </Form.Item>
              </Form>
                  </TabPane>*/}
            <TabPane tab={<span>注册</span>} key="2">
              <Form className={style.form}>
                <Form.Item {...this.formItemLayout}>
                  {getFieldDecorator("emailUname", {
                    rules: [
                      {
                        min: 2,
                        max: 32,
                        required: true,
                        message: "请输入数字、字母、下划线、横杆组合2-32的账号",
                      },
                    ],
                  })(
                    <Input
                      size="large"
                      prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
                      placeholder="请输入用户名 ( 数字,字母,_- )"
                    />,
                  )}
                </Form.Item>
                <Form.Item {...this.formItemLayout}>
                  {getFieldDecorator("emailPwd", {
                    rules: [
                      { min: 4, max: 32, required: true, message: "请输入4-32位密码" },
                      {
                        validator: this.validEmailRegisterPwdEqRePwd,
                      },
                    ],
                  })(
                    <Input
                      prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
                      size="large"
                      type="password"
                      placeholder="请输入4-32位密码"
                    />,
                  )}
                </Form.Item>
                <Form.Item {...this.formItemLayout}>
                  {getFieldDecorator("emailConfirm", {
                    rules: [
                      { required: true, message: "请确认密码" },
                      { validator: this.validEmailRegisterPwdEqPwd },
                    ],
                  })(
                    <Input
                      prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
                      size="large"
                      type="password"
                      placeholder="请确认密码"
                      onBlur={this.handlEmailConfirmBlur}
                    />,
                  )}
                </Form.Item>
                {/* <Form.Item {...this.formItemLayout} >
                  {getFieldDecorator('email', {
                    rules: [{ type: 'email', min: 2, max: 32, required: true, message: '请输入正确的邮箱格式' }],
                  })(
                    <Input size="large" prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
                      placeholder="请输入您的邮箱" />
                  )}
                </Form.Item> */}
                <Form.Item {...this.formItemLayout}>
                  {getFieldDecorator("emailVerifiyCode", {
                    rules: [{ required: true, message: "请输入验证码" }],
                  })(
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}>
                      <Input
                        size="large"
                        placeholder="请输入验证码"
                        className={style.verificationCodeTitle}
                      />
                      <Tooltip title="点击刷新">
                        <img
                          src={this.state.verifyCodePicUrl}
                          onClick={this.refreshVerifyCode}
                          style={{
                            marginLeft: "10px",
                            width: "120px",
                            height: "40px",
                            cursor: "pointer",
                          }}
                          alt="点击刷新"
                        />
                      </Tooltip>
                    </div>,
                  )}
                </Form.Item>
                <Form.Item {...this.formItemLayout}>
                  <div className={style.btnBox}>
                    <Button
                      type="primary"
                      className={style.btn}
                      onClick={this.registerSubmit}
                      size="large"
                      htmlType="submit">
                      注册
                    </Button>
                    <a href="/user/login">使用现有账户登录</a>
                  </div>
                </Form.Item>
              </Form>
            </TabPane>
          </Tabs>
        </div>
        <div className={style.footer}>Copyright &copy; 2019 邻米网络出品</div>
      </div>
    )
  }
}

const WrappedLogin = Form.create()(withRouter(Register))

export default WrappedLogin
