import { bget, bpost } from './api';

// 学校专业

export async function add({ name, }) {
  return bpost({
    url: '/addCollege',
    data: {
      name,
    }
  })
}
export async function deleteCollege(idArr) {
  return bpost({
    url: '/deleteCollege',
    data: {
      idArr,
    }
  })
}
// 获取所有的专业列表
export async function schoolSpecialityList(idArr) {
  return bget({
    url: '/schoolSpecialityList',
    data: {
      idArr,
    }
  })
}
export async function edit({
  id, name,
}) {
  return bpost({
    url: '/editCollege',
    data: {
      id,
      name,
    }
  })
}
// 给学校添加专业
export async function addSpeciality({
  id, list
}) {
  return bpost({
    url: "/binding",
    data: {
      id, list
    }
  })
}
// 获取对应学校的专业id,
export async function getSpeciality(id) {
  return bget({
    url: "/getSpecielity",
    query: {
      id,
    }
  })
}

// 前台获取对应学校的专业id,
export async function getSpecialityList(id) {
  return bget({
    url: "/getSpecielityList",
    query: {
      id,
    }
  })
}

export async function detail(id) {
  return bget({
    url: '/collegeDetail',
    query: {
      id,
    }
  })
}
export async function getList({ page, limit, filter = {} }) {
  return bget({
    url: '/collegeList',
    query: {
      page,
      limit,
      filter,
    }
  })
}

export default {
  add,
  deleteCollege,
  schoolSpecialityList,
  addSpeciality,
  getSpeciality,
  edit,
  detail,
  getList,
  getSpecialityList
}
