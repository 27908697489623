import React from "react";
import {
  ActivityIndicator, NavBar, Icon, Flex,
} from "antd-mobile";
import { Link, } from "react-router-dom";
import gStyle from "../../../utils/sass";
import AliIcon from "../../../utils/iconfont";

const style = gStyle.index.user.settings;

export default class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      animating: true,
      list: [
        {
          icon: "icon-bianji",
          title: "修改密码",
          href: "/user/modifyPassword",
        },
        {
          icon: "icon-zhuxiao",
          title: "退出",
          href: "/logout",
        },
      ],
    }
  }
  componentDidMount() {
    this.init();
  }

  init = async _ => {
    this.setState({
      animating: false,
    })
  }

  render() {
    return (
      <div className={style.settings}>
        <ActivityIndicator animating={this.state.animating} className={style.loading}
          text="loading..." />
        <NavBar className={style.navbar} mode="light" icon={<Link to="/?tab=my"><Icon type="left" /></Link>} >设置 </NavBar>
        <div className={style.content}>
          <div className={style.list}>
            {this.state.list.map((v, k) => {
              return (<Link to={v.href} key={k} className={style.item}>
                <Flex justify="between">
                  <Flex>
                    <AliIcon className={style.icon} type={v.icon}></AliIcon>
                    <div className={style.title}>{v.title}</div>
                  </Flex>
                  <AliIcon className={style.iconLeft} type="icon-icon_arrow_right"></AliIcon>
                </Flex>
              </Link>);
            })}
          </div>
        </div>
      </div>
    );
  }
}

