import moment from "moment"
/*
const test = {
  icon: ['fas', 'user'],
  href: '/',
  title: '开发模块',
  child: [
    {
      href: '/admin/basicForm',
      title: '基础表单',
      child: null,
    },
    {
      href: '/admin/BasicList',
      title: '表格',
      child: null,
    },
    {
      href: '/admin/Test',
      title: 'Test',
      child: null,
    },
  ]
}
*/
let buildTime = ""
if (process.env.REACT_APP_BUILD_TIME) {
  console.log(`构建时间: ${process.env.REACT_APP_BUILD_TIME}`)
  buildTime = moment(process.env.REACT_APP_BUILD_TIME).format("MM-DD HH:mm")
}
const developer = {
  icon: ["paper-clip", "dev"],
  title: `开发者 ${buildTime || ""}`,
  child: [
    {
      href: "/",
      title: "CMS",
      child: [
        {
          href: "/",
          title: "栏目",
          child: null,
        },
        {
          href: "/",
          title: "内容",
          child: null,
        },
      ],
    },
    {
      href: "/",
      title: "日志",
      child: [
        {
          href: "/",
          title: "全部",
          child: null,
        },
        {
          href: "/",
          title: "短信",
          child: null,
        },
        {
          href: "/",
          title: "wxApi",
          child: null,
        },
        {
          href: "/",
          title: "sql",
          child: null,
        },
        {
          href: "/",
          title: "文章爬虫",
          child: null,
        },
      ],
    },
    {
      href: "/",
      title: "权限管理",
      child: [
        {
          href: "/admin/operation/list",
          title: "操作列表",
          child: null,
        },
        {
          href: "/admin/operation/add",
          title: "添加操作",
          child: null,
        },
        {
          href: "/admin/operation/groupList",
          title: "操作组列表",
          child: null,
        },
        {
          href: "/admin/operation/addGroup",
          title: "添加操作组",
          child: null,
        },
      ],
    },
  ],
}
const user = {
  icon: ["user", "user"],
  href: "",
  title: "用户管理",
  child: [
    {
      href: "/admin/user/list",
      title: "用户列表",
      child: null,
    },
    {
      href: "/admin/user/add",
      title: "添加用户",
      child: null,
    },
  ],
}
const school = {
  icon: ["book", "user"],
  href: "/",
  title: "学校管理",
  child: [
    {
      href: "/admin/school/specialityList",
      title: "学校专业列表",
      child: null,
    },
    {
      href: "/admin/school/collegeList",
      title: "学院列表",
      child: null,
    },
  ],
}
const company = {
  icon: ["tag", "user"],
  href: "/",
  title: "公司管理",
  child: [
    {
      href: "/admin/company/list",
      title: "公司列表",
      child: null,
    },
    {
      href: "/admin/company/industryList",
      title: "公司行业列表",
      child: null,
    },
    {
      href: "/admin/company/paymentRecordList",
      title: "缴费记录",
      child: null,
    },
  ],
}
const project = {
  icon: ["project", "user"],
  href: "/",
  title: "项目管理",
  child: [
    {
      href: "/admin/project/list",
      title: "项目列表",
      child: null,
    },
  ],
}
const apply = {
  icon: ["file-add", "user"],
  href: "/",
  title: "申请管理",
  child: [
    {
      href: "/admin/apply/allList",
      title: "所有申请列表",
      child: null,
    },
    {
      href: "/admin/apply/mergeInfoList",
      title: "基本信息申请",
      child: null,
    },
    // {
    //   href: "/admin/apply/idCardRealNameList",
    //   title: "实名认证",
    //   child: null,
    // },
    // {
    //   href: "/admin/apply/schoolInfoList",
    //   title: "学校信息",
    //   child: null,
    // },
    {
      href: "/admin/apply/createProjectList",
      title: "项目入驻",
      child: null,
    },
    {
      href: "/admin/apply/companyEnterList",
      title: "公司入驻",
      child: null,
    },
    {
      href: "/admin/apply/conferenceRoomRentList",
      title: "会议室租用",
      child: null,
    },
    {
      href: "/admin/apply/coffeeHouseRentList",
      title: "咖啡吧",
      child: null,
    },
    {
      href: "/admin/apply/mealCardList",
      title: "饭卡",
      child: null,
    },
    {
      href: "/admin/apply/parkingPermitList",
      title: "停车管理",
      child: null,
    },
    {
      href: "/admin/apply/recruitList",
      title: "招聘",
      child: null,
    },
  ],
}
const conferenceRoomRent = {
  icon: ["snippets", "user"],
  href: "/",
  title: "会议室管理",
  child: [
    {
      href: "/admin/conferenceRoomRent/list",
      title: "会议室列表",
      child: null,
    },
  ],
}
const cafeRoomRent = {
  icon: ["snippets", "user"],
  href: "/",
  title: "咖啡吧管理",
  child: [
    {
      href: "/admin/cafeRoomRent/list",
      title: "咖啡吧列表",
      child: null,
    },
  ],
}
export const menuList = [
  // test,
  /**
   * 开发者
   */
  developer,
  apply,
  company,
  project,
  school,
  user,
  conferenceRoomRent,
  cafeRoomRent,
]
