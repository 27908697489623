import React, { Component, } from 'react';
import { Switch, } from 'react-router-dom';
import AdminAuthRoute from '../../../utils/AdminAuth';
import CollegeList from "../../../pages/admin/school/CollegeList";
import SpecialityList from "../../../pages/admin/school/SpecialityList";
export default class Wrap extends Component {
  render() {
    return (
      <Switch>
        <AdminAuthRoute exact path="/admin/school/collegeList" component={CollegeList} />
        <AdminAuthRoute exact path="/admin/school/specialityList" component={SpecialityList} />
      </Switch>
    );
  }

}
