import React, { Component, } from 'react';
import { Button, Breadcrumb, Form, DatePicker, Modal, Input, InputNumber, message } from 'antd';
import { Link, } from "react-router-dom";
import RoomRentDetail from "./RoomRentDetail";
import moment from 'moment';
import conferenceRoomRentApi from "../../../services/conferenceRoomRent";
import gSass from '../../../utils/sass';
import coffeeHouseRent from '../../../services/coffeeHouseRent';


const style = gSass.admin.conferenceRoomRent.list;
const { RangePicker } = DatePicker;
class RoomList extends Component {
  constructor(props) {
    super(props)
    // const { computedMatch: { params: { roomNo } } } = props;

    this.state = {
      chooseDateSection: [
        moment(),
        moment().add(7, 'day'),
      ],
      userRentList: [],
      isAddTourist: false,
    };
  }
  componentDidMount() {
    this.init();
  }
  init = async => {
    console.log()
    this.buildUserRentList();
  }
  buildUserRentList = async () => {
    // const { data: { list: userRentList } } = await this.getUserRentList()
    const { data: { list: userRentList } } = await
      conferenceRoomRentApi.getCoffeeRentList({
        beginDate: this.state.chooseDateSection[0].format('YYYY-MM-DD 00:00:00'),
        endDate: this.state.chooseDateSection[1].format('YYYY-MM-DD 00:00:00'),
      })
    this.setState({
      userRentList,
    })
  }
  getUserRentList = async () => {
    console.log("getUserRentList")
    return {
      data: {
        list: [
          {
            date: "2020-02-24",
            userList: [
              {
                rentUserInfo: {
                  idCardName: "欧阳南岸",
                  idCardNo: "321023111900001",
                  companyName: "欧阳企业",
                  rentDateSection: [
                    moment("2020-02-22 8:30"),
                    moment("2020-02-22 10:00"),
                  ]
                }
              },
              {
                rentUserInfo: {
                  idCardName: "上官费云",
                  idCardNo: "321023111900001",
                  companyName: "上官企业",
                  rentDateSection: [
                    moment("2020-02-23 00:00"),
                    moment("2020-02-23 3:00"),
                  ]
                }
              },
              {
                rentUserInfo: {
                  idCardName: "南宫宇",
                  idCardNo: "321023111900001",
                  companyName: "南宫企业",
                  rentDateSection: [
                    moment("2020-02-29 10:00"),
                    moment("2020-02-29 14:00"),
                  ]
                }
              },
            ],

          }
        ]
      }
    }
  }
  // 添加线下游客使用咖啡吧情况
  addCoffeeInfo = evt => {
    this.props.form.validateFields(["name", "phone", "usePersonNumber", "beginTime", "endTime"], (err, fields) => {
      if (err) {
        return;
      }
      let data = {
        name: fields.name,
        phone: fields.phone,
        usePersonNumber: fields.usePersonNumber,
        beginTime: moment(fields.beginTime).format("YYYY-MM-DD HH:mm"),
        endTime: moment(fields.endTime).format("YYYY-MM-DD HH:mm"),
      }
      console.log(data)
      coffeeHouseRent.addCoffeeRentInfo({
        name: fields.name,
        phone: fields.phone,
        usePersonNumber: fields.usePersonNumber,
        beginTime: moment(fields.beginTime).format("YYYY-MM-DD HH:mm"),
        endTime: moment(fields.endTime).format("YYYY-MM-DD HH:mm"),
      }).then(json => {
        message.success("添加成功", 1)
      }).catch(err => {
        message.error("修改失败,失败原因: " + err.msg, 1);
      })
    })
  }
  render() {
    const { getFieldDecorator } = this.props.form
    const formItemLayout = {
      labelCol: {
        sm: {
          span: 4,
          offset: 3,
        },
      },
      wrapperCol: {
        sm: {
          span: 14,
          offset: 1,
        },
      },
    };
    return (
      <div className={style.roomList}>
        <Breadcrumb className={style.title}>
          <Breadcrumb.Item><Link to="/admin">首页</Link></Breadcrumb.Item>
          <Breadcrumb.Item>咖啡吧时间列表</Breadcrumb.Item>
        </Breadcrumb>
        <div className={style.filters}>
          {/* 房间号 */}
          {/* <div className={style.item}>
            {this.conferenceRoomRentList.map((v, k) => {
              return (
                <Button key={k} className={style.filterBtn}
                  type={this.state.currConferenceRoomRentNo === v.value ? "primary" : "default"}
                  onClick={_ => {
                    this.setState({
                      currConferenceRoomRentNo: v.value,
                      chooseDateSection: [moment(), moment().add(7, "day")]
                    })
                    console.log(v.value)
                    console.log([moment(), moment().add(7, "day")])
                  }}
                >
                  <Link to={`/admin/conferenceRoomRent/list/${v.value}`}>{v.label}</Link>
                </Button>
              )
            })}
          </div> */}
          <RangePicker className={style.datePicker} value={this.state.chooseDateSection}
            format={"YYYY-MM-DD HH:mm"}
            onChange={chooseDateSection => {
              console.log(chooseDateSection)
              this.setState({
                chooseDateSection
              }, this.buildUserRentList)
            }}
          ></RangePicker>
          <Button className={style.chooseDateSection} type="danger">确定</Button>
          <Button className={style.chooseDateSection} type="primary" onClick={() => {
            this.setState({
              isAddTourist: true
            })
          }}>添加</Button>
        </div>
        {/* 给子组件传递了三个参数:
        房间号    显示的时间范围
        当前租用的信息列表
        */}
        <RoomRentDetail
          chooseDateSection={[moment(this.state.chooseDateSection[0].format()), moment(this.state.chooseDateSection[1].format())]}
          userRentList={this.state.userRentList}
        ></RoomRentDetail>
        <Modal title="添加游客" visible={this.state.isAddTourist}
          onOk={this.addCoffeeInfo} onCancel={() => {
            return new Promise(s => {
              this.setState({
                isAddTourist: false,
              }, s)
            })
          }}>
          <Form>
            <Form.Item {...formItemLayout} label="用户名">
              {getFieldDecorator("name", {
                rules: [
                  { min: 2, max: 32, required: true, message: "请输入2-32的用户名" },
                ],
              })(<Input className={style.input} size="large" placeholder="请输入用户名" />)}
            </Form.Item>
            <Form.Item {...formItemLayout} label="手机号码">
              {getFieldDecorator("phone", {
                rules: [
                  { message: "请输入正确的手机号码", required: true, pattern: /^1[3456789]\d{9}$/ },
                  // {
                  //   validator: async (rule, val, cb) => {
                  //     let errors = [];
                  //     let isExist = await this.checkPhoneIsExist(val);
                  //     if (isExist) {
                  //       errors.push(new Error("手机号已注册"));
                  //     }
                  //     cb(errors);
                  //   },
                  // },
                ],
              })(<Input size="large" className={style.input} placeholder="请输入手机号码" />)}
            </Form.Item>
            <Form.Item {...formItemLayout} label="使用人数">
              {getFieldDecorator("usePersonNumber", {
                rules: [{ required: true, message: "请输入使用人数" }],
              })(
                <InputNumber
                  className={style.input}
                  precision={0}
                  placeholder="请输入使用人数"
                />,
              )}
            </Form.Item>
            <Form.Item {...formItemLayout} label="开始时间">
              {getFieldDecorator("beginTime", {
                rules: [
                  {
                    required: true,
                    message: `请选择开始时间`,
                  },
                ],
              })(<DatePicker className={style.input} placeholder="请选择" format="YYYY-MM-DD HH:mm" />)}
            </Form.Item>
            <Form.Item {...formItemLayout} label="结束时间">
              {getFieldDecorator("endTime", {
                rules: [
                  {
                    required: true,
                    message: `请选择结束时间`,
                  },
                ],
              })(<DatePicker className={style.input} placeholder="请选择" format="YYYY-MM-DD HH:mm" />)}
            </Form.Item>
          </Form>
        </Modal>
      </div>
    )
  }
}
export default Form.create()(RoomList);
