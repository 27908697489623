import React, { Component } from 'react';
import {
  Table, Button, Breadcrumb, Input, Icon, Menu, Dropdown, Popconfirm, Tooltip,
  DatePicker, Modal,
} from 'antd';
import { Link } from "react-router-dom";
import user from "../../services/user";
import gSass from '../../utils/sass';
import gImg from '../../utils/img';

const style = gSass.admin.user.list;

const Search = Input.Search;
const { RangePicker, } = DatePicker;
//复选框
const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(`选择用户id: ${selectedRowKeys}`, '选择用户信息: ', selectedRows);
  },
};
const menu = (<Menu>
  <Menu.Item>
    <a target="_blank" rel="noopener noreferrer" href="/">删除</a>
  </Menu.Item>
  <Menu.Item>
    <a target="_blank" rel="noopener noreferrer" href="/">删除</a>
  </Menu.Item>
</Menu>);


class UserList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filteredInfo: null,
      sortedInfo: null,
      list: [],
      page: 1,
      limit: 8,
      filters: [],
      defaultAvatar: gImg.user.defaultAvatar,
    };
  }

  componentDidMount() {
    this.init();
  }

  init = async _ => {
    try {
      let page = this.state.page,
        limit = this.state.limit,
        filters = this.state.filters;
      let json = await user.getList(page, limit, filters);
      this.setState({
        list: json.data,
      })
    } catch (e) {

    }


  }

  handleChange = (pagination, filters, sorter) => {//筛选(排序,页码)
    console.log('Various parameters', pagination, filters, sorter);
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  }
  clearFilters = _ => {
    this.setState({ filteredInfo: null });
  }

  clearAll = _ => {
    this.setState({
      filteredInfo: null,
      sortedInfo: null,
    });
  }

  setAgeSort = _ => {
    this.setState({
      sortedInfo: {
        order: 'descend',
        columnKey: 'age',
      },
    });
  }
  //编辑
  handleEdit = (key) => {
    console.log(key);
  }
  //删除
  handleDelete = (key) => {
    const list = [...this.state.list];
    this.setState({
      list: list.filter(item => item.id !== key)
    });
  }
  dataChange = (date, dateString) => {
    console.log(dateString);
  }


  render() {
    let { sortedInfo, filteredInfo } = this.state;
    sortedInfo = sortedInfo || {};
    filteredInfo = filteredInfo || {};
    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
      },
      {
        title: '名字',
        dataIndex: 'name',
        render: (text, record, index) => {
          return (<Tooltip placement="right" title={_ => {
            return (<div>
              <div>{text}</div>
              <div>{text}</div>
            </div>)
          }}>
            {text}
          </Tooltip>)
        }
      },
      {
        title: '性别',
        dataIndex: 'gender',
        render: gender => <div>{gender === 1 ? "男" : gender === 2 ? "女" : "未知"}</div>,
        filters: [
          { text: '男', value: "1 " },
          { text: '女', value: "2" },
          { text: '未知', value: "0" },
        ],
        filteredValue: filteredInfo.gender || null,
        onFilter: (val, record) => record.gender === parseInt(val),
      },
      {
        title: '年龄',
        dataIndex: 'age',
        sorter: (a, b) => a.age - b.age,
        sortOrder: sortedInfo.columnKey === 'age' && sortedInfo.order,
      },
      {
        title: '头像',
        dataIndex: 'avatar',
        render: avt => {
          return (
            <div className={style.avatarBox} onClick={_ => {
              Modal.info({
                title: '头像查看',
                okText: "关闭",
                content: (
                  <div>
                    <img style={{ width: 270, }} src={avt || this.state.defaultAvatar} alt="头像" />
                  </div>
                )
              })
            }}>
              <img className={style.avatar} src={avt || this.state.defaultAvatar} alt="头像" />
            </div>
          )
        }
      },
      {
        title: '手机号码',
        dataIndex: 'phone',
      },
      {
        title: '邮箱',
        dataIndex: 'email',
      },
      {
        title: '地址',
        dataIndex: "address['whole']",
        render: text => {
          let textOverflow = text.substr(0, 10);
          return (
            text ? <div>
              <div style={{ marginBottom: 10, }}>{textOverflow}...</div>
              <div className={style.btn} onClick={_ => {
                Modal.info({
                  title: '查看更多',
                  okText: "关闭",
                  content: (
                    <div>
                      {text}
                    </div>
                  )
                })
              }}>更多</div>
            </div> : <div>-</div>
          );
        }
      },
      {
        title: '操作',
        dataIndex: 'operation',
        width: 300,
        render: (text, record) => (
          this.state.list.length >= 1
            ? (
              <div>
                <span className={style.btn}>查看</span>
                <div className="separate-line"></div>
                <span className={style.btn} onClick={() => this.handleEdit(record.id)}>编辑</span>
                <div className="separate-line"></div>
                <span className={style.btn}>
                  <Popconfirm title={<div>你确定要删除id为{record.id}的用户吗?</div>} onConfirm={() => this.handleDelete(record.id)}>
                    <a href="/">删除</a>
                  </Popconfirm>
                </span>
              </div>
            ) : null
        ),
      }
    ];

    return (
      <div className={style.userList}>
        <Breadcrumb className={style.title}>
          <Breadcrumb.Item><Link to="/admin">首页</Link></Breadcrumb.Item>
          <Breadcrumb.Item>用户管理</Breadcrumb.Item>
          <Breadcrumb.Item>用户列表</Breadcrumb.Item>
        </Breadcrumb>
        <div className={style.filters}>
          <div className={style.item}>
            <Button type="primary" icon="plus">新增</Button>
            <Dropdown overlay={menu} className={style.drown} placement="bottomLeft">
              <Button>更多操作 <Icon type="down" /></Button>
            </Dropdown>
          </div>
          <RangePicker onChange={this.dataChange} className={style.selectDate} />
          <Search
            placeholder="请输入姓名|手机号|身份证号码..."
            enterButton="搜索"
            onSearch={value => console.log(value)}
            className={style.search}
          />
        </div>
        <Table className={style.table}
          columns={columns}
          dataSource={this.state.list}
          rowSelection={rowSelection}
          onChange={this.handleChange}
          rowKey="id"

        />
      </div>
    );
  }
}
export default UserList;
