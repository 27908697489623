import React from "react";
import {
  List, Picker, ActivityIndicator, Icon, Button, Switch, Flex, ImagePicker,
  Toast, Modal, NavBar, DatePicker, InputItem
} from "antd-mobile";
import { Form, message, } from "antd";
import { withRouter, Link, } from "react-router-dom";
import moment from "moment";
import gStyle from "../../../../utils/sass";
import schoolInfoApi, { IS_GRADUATE, IS_OVERSEA } from "../../../../services/schoolInfo";
import collegeApi from "../../../../services/college";
import schoolSpecialityApi from "../../../../services/schoolSpeciality";
import { EDUCATION, EDUCATION_ZH } from "../../../../services/apply";
import ApplyWithSendMsg from '../../../../components/index/user/apply/ApplyWithSendMsg';
import applyApi from "../../../../services/apply";
import { antdUploadImg, } from "../../../../services/api";
import { BASE_URL, } from "../../../../config/prod";

const style = gStyle.index.user.auth.schoolInfo;
const Item = List.Item;

class SchoolInfo extends ApplyWithSendMsg {
  isShowDetail = false;
  constructor(props) {
    super(props);
    this.isShowDetail = props.isShowDetail || false;
    this.applyId = props.applyId;
    this.state = {
      animating: true,
      isGraduate: false,
      isOverseas: false,
      isModalActive: false,
      modalBody: '',
      modalFooter: [],
      degreePic: [],
      overseasCard: [],
      oneCardPassPic: [],
      seasCollege: "",
      seasSpeciality: "",
      seasEducation: [],
      schoolInfo: {},
      college: [],
      collegesId: null,
      specialityList: [],
      activeSpecialityList: [],
      grade: [],
      education: [],
      picList: [
        []
      ],
      applyDetail: {
        id: 1,
        steps: [],

      },
    }
  }
  componentDidMount() {
    this.init();
  }

  init = async _ => {
    //获取学院列表
    try {
      let { data: { list: college } } = await collegeApi.getList({ page: -1, limit: -1 })
      college = [college.map(v => ({
        value: v.id,
        label: v.name,
      }))]

      //获取学校专业列表
      let { data: { list: specialityList } } = await schoolSpecialityApi.getList({ page: -1, limit: -1 })
      specialityList = [specialityList.map(v => ({
        value: v.id,
        label: v.name,
      }))]
      //获取学历信息
      let education = [Object.values(EDUCATION).map((v, k) => ({
        value: v,
        label: EDUCATION_ZH[v],
      }))]
      let grade = [], date = new Date().getFullYear(), gradeList = [];
      for (let i = 0; i <= (date - 1990); i++) {
        gradeList.push({ value: 1990 + i, label: (1990 + i) })
      }
      grade = [gradeList]

      this.setState({
        animating: false,
        college,
        // seasCollege: college,
        // seasSpeciality: speciality,
        // seasEducation: education,
        specialityList,
        education,
        grade,
      })
      /** 
       *申请详情
      */
      if (this.isShowDetail) {
        const { setFieldsValue, } = this.props.form;

        try {

          let { data: { detail: applyDetail, } } = await applyApi.getApplyDetail({ id: this.applyId })
          console.log(applyDetail)
          console.log(2222)
          this.setState({
            applyDetail,
            isGraduate: applyDetail.info.isGraduate === IS_GRADUATE.TRUE,
            isOverseas: applyDetail.info.is_overseas === IS_OVERSEA.TRUE,
            degreePic: [{ url: BASE_URL + applyDetail.info.degreePic }],
            oneCardPassPic: [{ url: BASE_URL + applyDetail.info.oneCardPassPic }],
            overseasCard: [{ url: BASE_URL + applyDetail.info.overseas_degree_pic_id }],
          })
          setFieldsValue({
            college: [applyDetail.info.collegeId],
            speciality: [applyDetail.info.specialityId],
            grade: [applyDetail.info.grade],
            education: [applyDetail.info.educationId],
            // graduationTime: new Date(applyDetail.info.graduation_time),
            isGraduate: [applyDetail.info.isGraduate],
          })
          if (this.state.isOverseas) {
            setFieldsValue({
              seasCollege: applyDetail.info.overseas_college_id,
              seasSpeciality: applyDetail.info.overseas_speciality_id,
              seasEducation: [applyDetail.info.overseas_education],
              isOverseas: [applyDetail.info.is_overseas],
              seasGraduationTime: new Date(applyDetail.info.overseas_graduation_time),
            })
          }

        } catch (err) {
          console.log(err);
        }
      }
    } catch (err) {
      console.log(err.msg);
    }
  }
  apply = () => {
    console.log(111)
    this.props.form.validateFields({ force: true }, async err => {
      console.log(222)
      let fields = this.props.form.getFieldsValue();
      // if (!fields.name) {
      //   return Toast.fail("请输入姓名", 1);
      // }
      console.log(fields)
      if (fields.college.length === 0) {
        return Toast.fail("请选择学院", 1);
      }
      if (fields.speciality.length === 0) {
        return Toast.fail("请选择专业", 1);
      }
      if (fields.grade.length === 0) {
        return Toast.fail("请选择年级", 1);
      }
      if (fields.education.length === 0) {
        return Toast.fail("请选择学历", 1);
      }
      if (this.state.isGraduate) {
        if (this.state.degreePic.length === 0) {
          return Toast.fail("请上传学位证", 1);
        }
      } else {
        if (this.state.oneCardPassPic.length === 0) {
          return Toast.fail("请上传一卡通", 1);
        }
      }
      try {
        await schoolInfoApi.apply({
          college: fields.college[0],
          speciality: fields.speciality[0],
          grade: parseInt(fields.grade[0]),
          education: fields.education[0],
          isGraduate: fields.isGraduate,
          degreePic: this.state.degreePic[0],
          oneCardPassPic: this.state.oneCardPassPic[0],
          // graduationTime: moment(fields.graduationTime).format("YYYY-MM-DD HH:mm:ss"),

          isOverseas: fields.isOverseas,
          seasSpeciality: fields.seasSpeciality,
          seasCollege: fields.seasCollege,
          seasEducation: fields.seasEducation === undefined ? [] : fields.seasEducation[0],
          seasGraduationTime: moment(fields.seasGraduationTime).format("YYYY-MM-DD HH:mm:ss"),
          overseasCard: this.state.overseasCard[0],
        })
        this.setState({
          isModalActive: true,
          modalBody: <div>提交成功,请等待审核</div>,
          modalFooter: [{ text: '确定', onPress: () => this.props.history.push('/') }],
        })
        console.log(4)
      } catch (err) {
        this.setState({
          isModalActive: true,
          modalBody: <div>提交失败,错误信息:{err.msg}</div>,
          modalFooter: [{ text: '确定', onPress: () => this.setState({ isModalActive: false, }) }],
        })
      }
    });
  }
  resetForm = () => {
    this.props.form.resetFields();
  }
  //是否是毕业生
  isGraduateChange = (val) => {
    this.setState({
      isGraduate: val,
    });
  };
  isOverseasChange = (val) => {
    this.setState({
      isOverseas: val,
    });
  };
  changeCollege = _ => {

    const { setFieldsValue, } = this.props.form;
    let fields = this.props.form.getFieldsValue();

    let { collegesId, specialityList, } = this.state
    collegesId = fields.college[0]
    console.log(specialityList)
    specialityList = []
    collegeApi.getSpecialityList(collegesId).then(json => {
      let detail = json.data.list
      console.log(detail)
      for (let v of detail) {
        specialityList.push(v)
      }
      console.log(specialityList)
      let activeList = this.state.specialityList;
      console.log(activeList)
      activeList = [[]];
      for (let v of specialityList) {
        activeList[0].push({
          value: v.id,
          label: v.name,
        })
      }
      console.log(fields.speciality)
      setFieldsValue({
        speciality: []
      })
      this.setState({
        specialityList: activeList
      })
    }).catch(err => {
      message.error("修改失败, 失败原因: " + err.msg, 1);
    })
  }
  getApplyDetailDom = _ => {
    const { getFieldProps, getFieldDecorator } = this.props.form;
    return <form className={style.form}>
      <List>
        {getFieldDecorator('college',
          { rules: [{ required: true, message: '请选择学院', },], initialValue: this.state.collegesId })(
            <Picker data={this.state.college} title="选择学院" cascade={false}
              onOk={this.changeCollege}>
              <List.Item arrow="horizontal">学校</List.Item>
            </Picker>
          )}
        {/* <Picker
          {...getFieldProps('college', { rules: [{ required: true, },], })}
          data={this.state.college} title="选择学校" cascade={false}
          onChange={(e) => {
            console.log(e)
          }}
        > 
          <List.Item arrow="horizontal">学校</List.Item>
        </Picker> */}
        <Picker {...getFieldProps('speciality', { rules: [{ required: true, },], })}
          data={this.state.specialityList} title="选择专业" cascade={false} >
          <List.Item arrow="horizontal">专业</List.Item>
        </Picker>
        <Picker
          {...getFieldProps('grade', { rules: [{ required: true, },], })}
          data={this.state.grade} title="选择届别" cascade={false}>
          <List.Item arrow="horizontal">届别</List.Item>
        </Picker>
        {/* <DatePicker
          {...getFieldProps('graduationTime', {
            rules: [{ required: true, message: `请选择海外留学背景毕业时间` },],
          })} mode="date" extra="请选择" >
          <List.Item arrow="horizontal">毕业时间</List.Item>
        </DatePicker> */}
        <Picker
          {...getFieldProps('education', { rules: [{ required: true, },], })}
          data={this.state.education} title="选择学历" cascade={false}>
          <List.Item arrow="horizontal">学历</List.Item>
        </Picker>
        <Item>
          <Flex>
            <div>是否为毕业生</div>
            <Switch
              {...getFieldProps('isGraduate', {
                initialValue: this.state.isGraduate === IS_GRADUATE.TRUE, valuePropName: 'checked',
                onChange: (checked) => {
                  this.setState({
                    isGraduate: checked,
                  })
                },
              })}
              className={style.isGraduate}
            />
          </Flex>
        </Item>
        <div className={style.toolps}>提示:如果是毕业生, 请上传学位证图片; 如果不是毕业生, 请上传"一卡通学生证"图片</div>
        <Item className={this.state.isGraduate ? "" : style.hidden}>
          {this.state.isGraduate ? <ImagePicker files={this.state.degreePic}
            onChange={(files, type, index) => {
              if (type === 'add') {
                let formData = new FormData();
                formData.append("name", 'file');
                formData.append("file", files[0].file);
                antdUploadImg(formData).then(json => {
                  const { data: { name, picId, url, } } = json;
                  this.setState({
                    degreePic: [{
                      name, picId, url: BASE_URL + url,
                    }],
                  });
                }).catch(e => {
                  message.error('上传图片失败', 1)
                  console.log(e)
                })
              } else if (type === 'remove') {
                this.setState({
                  degreePic: [],
                });
              }
            }}
            selectable={this.state.degreePic.length < 1} multiple={this.state.multiple} style={{ flex: 1, }}
          /> : null}
        </Item>
        <Item className={!this.state.isGraduate ? "" : style.hidden}>
          {!this.state.isGraduate ? <ImagePicker files={this.state.oneCardPassPic}
            onChange={(files, type, index) => {
              if (type === 'add') {
                let formData = new FormData();
                formData.append("name", 'file');
                formData.append("file", files[0].file);
                antdUploadImg(formData).then(json => {
                  const { data: { name, picId, url, } } = json;
                  this.setState({
                    oneCardPassPic: [{
                      name, picId, url: BASE_URL + url,
                    }],
                  });
                }).catch(e => {
                  message.error('上传图片失败', 1)
                  console.log(e)
                })
              } else if (type === 'remove') {
                this.setState({
                  oneCardPassPic: [],
                });
              }
            }}
            selectable={this.state.oneCardPassPic.length < 1} multiple={this.state.multiple} style={{ flex: 1, }}
          /> : null}
        </Item>
        {/* 修改开始 */}
        <Item>
          <Flex className={style.item}>
            <div className={style.title}>是否是海外留背景</div>
            <Switch
              {...getFieldProps('isOverseas', {
                initialValue: this.state.isOverseas === IS_OVERSEA.TRUE, valuePropName: 'checked',
                onChange: (checked) => {
                  this.setState({
                    isOverseas: checked,
                  })
                },
              })}
              className={style.isGraduate}
            />
          </Flex>
        </Item>
        {/*是否有海外留学背景成员 */}
        <Item>
          <div className={this.state.isOverseas ? "" : style.hidden}>
            {this.state.isOverseas ? (<div>
              <div className={style.theme}>海外留学背景</div>
              <div className={style.item}>
                <Picker
                  {...getFieldProps("seasEducation", { rules: [{}] })}
                  data={this.state.education} title="选择学历" cascade={false}
                >
                  <List.Item arrow="horizontal">学历</List.Item>
                </Picker>
              </div>
              <InputItem {...getFieldProps("seasCollege", { rules: [{}] })} clear
                placeholder="请输入海外学校" className={style.input}
                extra={<Icon size="md" color="#ccc" type="right"></Icon>}
              > 海外学校</InputItem>
              <InputItem {...getFieldProps("seasSpeciality", { rules: [{}] })} clear
                placeholder="请输入海外专业" className={style.input}
                extra={<Icon size="md" color="#ccc" type="right"></Icon>}
              > 海外专业</InputItem>

              <div className={style.item}>
                <DatePicker
                  {...getFieldProps('seasGraduationTime', {
                    rules: [{ required: true, message: `请选择海外留学背景毕业时间` },],
                  })} mode="date" extra="请选择" >
                  <List.Item arrow="horizontal">毕业时间</List.Item>
                </DatePicker>
              </div>
              <Item >
                <div className={style.title}>海外留学毕业证或在读证明</div>
                <ImagePicker files={this.state.overseasCard}
                  onChange={(files, type, index) => {
                    if (type === 'add') {
                      let formData = new FormData();
                      formData.append("name", 'file');
                      formData.append("file", files[0].file);
                      antdUploadImg(formData).then(json => {
                        const { data: { name, picId, url, } } = json;
                        this.setState({
                          overseasCard: [{
                            name, picId, url: BASE_URL + url,
                          }],
                        });
                      }).catch(e => {
                        message.error('上传图片失败', 1)
                        console.log(e)
                      })
                    } else if (type === 'remove') {
                      this.setState({
                        overseasCard: [],
                      });
                    }
                  }}
                  selectable={this.state.overseasCard.length < 1} multiple={this.state.multiple} style={{ flex: 1, }}
                />
              </Item>
            </div>) : null}

          </div>
        </Item>
        {/* 修改结束 */}
      </List>
      {this.getChatInfo()}
    </form>
  }

  render() {
    return (this.isShowDetail ? this.getApplyDetailDom() :
      <div className={style.schoolInfo}>
        <ActivityIndicator animating={this.state.animating} className={style.loading} text="loading..." />
        <NavBar className={this.isShowDetail ? style.hidden : null} mode="light"
          icon={<Link to="/?tab=my"><Icon type="left" /></Link>} >学校信息</NavBar>
        {this.getApplyDetailDom()}
        <div className={style.operation}>
          <Button size="small" className={style.btn} inline onClick={this.resetForm}>重置</Button>
          <Button size="small" type="primary" className={style.btn} inline onClick={this.apply}>提交</Button>
        </div>
        <Modal visible={this.state.isModalActive} transparent maskClosable={false}
          title="提示" footer={this.state.modalFooter}
        >
          {this.state.modalBody}
        </Modal>
      </div>
    );
  }
}
export default withRouter(Form.create()(SchoolInfo));


