import React from "react";
import {
  List,
  ActivityIndicator,
  NavBar,
  Icon,
  InputItem,
  Button,
  ImagePicker,
  Toast,
  Radio,
  Flex,
  TextareaItem,
  Modal,
} from "antd-mobile";
import { Link, withRouter } from "react-router-dom";
import { Form, message } from "antd";
import gStyle from "../../../utils/sass";
import UserApi, { GENDER, GENDER_ZH } from "../../../services/user";
import { BASE_URL } from "../../../config/prod";
import { antdUploadImg } from "../../../services/api";
const style = gStyle.index.user.info;
const Item = List.Item;
const RadioItem = Radio.RadioItem;

class Info extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      animating: true,
      isModalActive: false,
      modalBody: "",
      modalFooter: [],
      genders: [
        { value: GENDER.MAN, label: GENDER_ZH[GENDER.MAN] },
        { value: GENDER.WOMAN, label: GENDER_ZH[GENDER.WOMAN] },
      ],
      detail: {
        avatar: [],
      },
    };
  }
  componentDidMount() {
    this.init();
  }

  init = async _ => {
    try {
      let { data: detail } = await UserApi.getUserInfo();
      detail.avatar = detail.avatar ? [{ url: BASE_URL + detail.avatar.url }] : [];
      this.setState({
        animating: false,
        detail,
      });
    } catch (e) {
      console.log(e);
    }
  };
  apply = () => {
    this.props.form.validateFields({ force: true }, async err => {
      let fields = this.props.form.getFieldsValue();
      if (err) {
        Toast.fail(err[Object.keys(err)[0]].errors[0].message, 2);
        return;
      }
      console.log(fields)
      if (this.state.detail.avatar.length === 0) {
        Toast.fail("请选择头像", 1);
        return;
      }
      if (!/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(fields.email)) {
        Toast.fail("邮箱格式错误", 1);
        return;
      }
      console.log(this.state);
      try {
        await UserApi.editUserInfo({
          nick: fields.nick,
          avatar: this.state.detail.avatar[0],
          gender: this.state.detail.gender,
          email: fields.email,
          profile: fields.profile,
        });
        this.setState({
          isModalActive: true,
          modalBody: <div>修改成功</div>,
          modalFooter: [{ text: "确定", onPress: () => this.setState({ isModalActive: false }) }],
        });
      } catch (err) {
        this.setState({
          isModalActive: true,
          modalBody: <div>提交失败,错误信息:{err.msg}</div>,
          modalFooter: [{ text: "确定", onPress: () => this.setState({ isModalActive: false }) }],
        });
      }
    });
  };
  /**
   * 设置用户性别
   */
  genderChange = val => {
    let detail = this.state.detail;
    detail.gender = val;
    this.setState({
      detail,
    });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className={style.info}>
        <ActivityIndicator
          animating={this.state.animating}
          className={style.loading}
          text="loading..."
        />
        <NavBar
          mode="light"
          icon={
            <Link to="/?tab=my">
              <Icon type="left" />
            </Link>
          }
          rightContent={[
            <div key="0" style={{ marginRight: "16px", fontSize: 14 }}>
              <Link to="/user/modifyPassword">修改密码</Link>
            </div>,
          ]}>
          个人信息
        </NavBar>
        <form className={style.form}>
          <List>
            {getFieldDecorator("avatar", {
              initialValue: this.state.detail.avatar,
            })(
              <Item className={style.avatarFather}>
                <Flex justify="between">
                  <div className={style.title}>头像</div>
                  <ImagePicker
                    files={this.state.detail.avatar}
                    className={style.avatar}
                    onChange={(files, type, index) => {
                      if (type === "add") {
                        let formData = new FormData();
                        formData.append("name", "file");
                        formData.append("file", files[0].file);
                        antdUploadImg(formData)
                          .then(json => {
                            const {
                              data: { name, picId, url },
                            } = json;
                            let detail = this.state.detail;
                            detail.avatar = [
                              {
                                name,
                                picId,
                                url: BASE_URL + url,
                              },
                            ];
                            this.setState({
                              detail,
                            });
                          })
                          .catch(e => {
                            message.error("上传图片失败", 1);
                            console.log(e);
                          });
                      } else if (type === "remove") {
                        let detail = this.state.detail;
                        detail.avatar = [];
                        this.setState({
                          detail,
                        });
                      }
                    }}
                    selectable={this.state.detail.avatar.length < 1}
                    multiple={this.state.multiple}
                    style={{ flex: 1 }}
                  />
                </Flex>
              </Item>,
            )}
            {getFieldDecorator("account", {
              initialValue: this.state.detail.account,
            })(
              <InputItem
                disabled
                clear
                className={style.input}
                extra={<Icon size="md" color="#ccc" type="right" />}>
                账户
              </InputItem>,
            )}
            {getFieldDecorator("nick", {
              initialValue: this.state.detail.nick,
              rules: [{ required: true, message: "请输入昵称" }],
            })(
              <InputItem
                clear
                className={style.input}
                placeholder="请输入昵称"
                extra={<Icon size="md" color="#ccc" type="right" />}>
                昵称
              </InputItem>,
            )}
            {getFieldDecorator("gender", {
              initialValue: this.state.detail.gender,
              rules: [{ required: true, message: "请选择性别" }],
            })(
              <Item className={style.gender}>
                <Flex justify="between">
                  <div className={style.title}>性别</div>
                  <Flex>
                    {this.state.genders.map(i => (
                      <RadioItem
                        key={i.value}
                        className={style.genderLine}
                        checked={i.value === this.state.detail.gender}
                        onChange={_ => this.genderChange(i.value)}>
                        {i.label}
                      </RadioItem>
                    ))}
                  </Flex>
                </Flex>
              </Item>,
            )}
            {getFieldDecorator("email", {
              initialValue: this.state.detail.email,
              rules: [{ required: true, message: "请输入邮箱" }],
            })(
              <InputItem
                clear
                className={style.input}
                placeholder="请输入邮箱"
                disabled={this.state.detail.email !== ""}
                extra={<Icon size="md" color="#ccc" type="right" />}>
                邮箱
              </InputItem>,
            )}
            {getFieldDecorator("profile", {
              initialValue: this.state.detail.profile,
            })(<TextareaItem title="个人简介" placeholder="请输入个人简介" rows={3} count={100} />)}
          </List>
          <div className={style.control}>
            <Button size="small" type="primary" className={style.btn} inline onClick={this.apply}>
              修改信息
            </Button>
          </div>
        </form>
        <Modal
          visible={this.state.isModalActive}
          transparent
          maskClosable={false}
          title="提示"
          footer={this.state.modalFooter}>
          {this.state.modalBody}
        </Modal>
      </div>
    );
  }
}
export default withRouter(Form.create()(Info));
