import React, { Component, } from 'react';
import { Switch, Redirect, } from 'react-router-dom';
import AdminAuthRoute from '../../../utils/AdminAuth';
import conferenceRoomRentList from "../../../pages/admin/conferenceRoomRent/List";

export default class Wrap extends Component {
  render() {
    return (
      <Switch>
        <AdminAuthRoute exact path="/admin/conferenceRoomRent/list/:roomNo" component={conferenceRoomRentList} />
        <Redirect to="/admin/conferenceRoomRent/list/207" />
      </Switch>
    );
  }

}
