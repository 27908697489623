import React from 'react';
import {
  Breadcrumb, Form, Steps, Alert, Spin, Divider, Tooltip, Empty,
} from 'antd';
import { Link, } from "react-router-dom";
import csn from 'classnames';
import qs from "qs";
import { LocalStorage as storage } from "jsbdk";
import gSass from '../../../utils/sass';
import applyApi, { STATUS as APPLY_STATUS } from "../../../services/apply";
import { BASE_URL, } from "../../../config/prod";
import Lightbox from 'react-images';
import AuditWithSendMsg from '../../../components/admin/apply/AuditWithSendMsg';
import idCardRealNameApi from "../../../services/idCardRealName";

const style = gSass.admin.apply.idCardRealNameInfo;
const Step = Steps.Step;

class Info extends AuditWithSendMsg {
  constructor(props) {
    super(props)
    let { id } = qs.parse(props.location.search, { ignoreQueryPrefix: true, })
    this.state = {
      id,
      userNick: '',
      loading: true,
      lightboxIsOpen: false,
      household: [],
      applyDetail: {
        info: {
          idHousehold: '',
          name: '',
          idCardNo: "",
          id_card_first_pic: "",
          id_card_second_pic: "",
        },
        apply_uid: 5,
        current: 1,
        total: 5,
        status: APPLY_STATUS.process,
        steps: []
      },
      lightBoxImgs: [],
    };
  }

  componentDidMount() {
    this.init();
  }

  init = async _ => {
    try {
      let { data: { list: household } } = await idCardRealNameApi.getProvice({ page: -1, limit: -1 })
      let testHouse = ""
      let { data: { detail: applyDetail, } } = await applyApi.getApplyDetail({ id: this.state.id });
      this.setState({
        loading: false,
        userNick: storage.get('nick'),
        applyDetail,
      })
      for (let m of household) {
        if (m.id === applyDetail.info.idHousehold) {
          testHouse = m.name
        }
      }
      let applyDetails = this.state.applyDetail;
      applyDetails.info.idHousehold = testHouse;
      this.setState({
        applyDetail,
      })
    } catch (err) {
      console.log(err);
    }
  }
  closeLightbox = _ => {
    this.setState({
      lightboxIsOpen: false,
      lightBoxImgs: [],
    })
  }
  render() {
    return (
      <div className={style.info}>
        <Breadcrumb className={style.title}>
          <Breadcrumb.Item><Link to="/admin">首页</Link></Breadcrumb.Item>
          <Breadcrumb.Item><Link to="/admin/apply/allList">申请管理</Link></Breadcrumb.Item>
          <Breadcrumb.Item>实名认证流程</Breadcrumb.Item>
        </Breadcrumb>
        <div className={style.prompt}>
          <Alert description=" 审核人可以审核之前与申请人进行沟通, 给予申请人在申请中出现的问题做答复" type="info" showIcon message="提示" />
        </div>
        <Spin spinning={this.state.loading} size="large">
          <div className={style.content}>
            <div className={style.step}>
              <Steps current={this.state.applyDetail.current - 1}>
                {this.state.applyDetail.steps.map((v, k) => {
                  return <Step key={k} title={v.name} description={<div>
                  </div>} />
                })}
              </Steps>
            </div>
            <div className={style.userInfo}>
              <div className={style.infoList}>
                <div className={style.item}>
                  <div className={style.name}>姓名:</div>
                  <div className={style.detail}>{this.state.applyDetail.info.name}</div>
                </div>
                <div className={style.item}>
                  <div className={style.name}>电话:</div>
                  <div className={style.detail}>{this.state.applyDetail.info.idPhone}</div>
                </div>
                <div className={style.item}>
                  <div className={style.name}>年龄:</div>
                  <div className={style.detail}>{this.state.applyDetail.info.idAge}</div>
                </div>
                <div className={style.item}>
                  <div className={style.name}>邮箱:</div>
                  <div className={style.detail}>{this.state.applyDetail.info.idEmail}</div>
                </div>
                <div className={style.item}>
                  <div className={style.name}>户籍:</div>
                  <div className={style.detail}>{this.state.applyDetail.info.idHousehold}</div>
                </div>
                {
                  this.state.applyDetail.info.id_reside_pic !== "" ? (<div className={style.itemImg}>
                    <div className={style.name}>居住证:</div>
                    <div className={style.img} onClick={_ => {
                      this.setState({
                        lightboxIsOpen: true,
                        lightBoxImgs: [{ src: BASE_URL + this.state.applyDetail.info.id_reside_pic }],
                      })
                    }}>
                      <img src={`${BASE_URL + this.state.applyDetail.info.id_reside_pic}`} alt="居住证" />
                    </div>
                  </div>) : ""
                }

                <div className={style.item}>
                  <div className={style.name}>身份证号码:</div>
                  <div className={style.detail}>{this.state.applyDetail.info.idCardNo}</div>
                </div>

                <div className={style.itemImg}>
                  <div className={style.name}>身份证正面照:</div>
                  <div className={style.img} onClick={_ => {
                    this.setState({
                      lightboxIsOpen: true,
                      lightBoxImgs: [{ src: BASE_URL + this.state.applyDetail.info.id_card_first_pic }],
                    })
                  }}>
                    <img src={`${BASE_URL + this.state.applyDetail.info.id_card_first_pic}`} alt="身份证正面照" />
                  </div>
                </div>
                <div className={style.itemImg}>
                  <div className={style.name}>身份证反面照:</div>
                  <div className={style.img} onClick={_ => {
                    this.setState({
                      lightboxIsOpen: true,
                      lightBoxImgs: [{ src: BASE_URL + this.state.applyDetail.info.id_card_second_pic }],
                    })
                  }}>
                    <img src={`${BASE_URL + this.state.applyDetail.info.id_card_second_pic}`} alt="身份证反面照" />
                  </div>
                </div>
                {/* 图片查看 */}
                <Lightbox images={this.state.lightBoxImgs} isOpen={this.state.lightboxIsOpen}
                  onClose={this.closeLightbox} showCloseButton={false} showImageCount={false}
                  backdropClosesModal={true}
                />
              </div>
              <div className={style.chargeUserList}>
                <div className={style.theme}>审核人列表</div>
                <div className={style.reviewStyle}>审核方式:
                <span className={style.reviewStyleTitle}>
                    {this.state.applyDetail.steps.length > 0 ? this.state.applyDetail.steps[this.state.applyDetail.current - 1].should_all_pass === 1 ?
                      <Tooltip title='“会签”：要求在每一轮审核中所有审核人员都通过该申请才会进入下一轮审核。'>会签</Tooltip> :
                      <Tooltip title='“或签”：要求在每一轮审核中其中一个审核人通过该申请就进入下一轮申请。'>或签</Tooltip> : null}
                  </span>
                </div>
                {this.state.applyDetail.steps.length > 0 ? this.state.applyDetail.steps[this.state.applyDetail.current - 1].charge_user_list.map((v, k) => {
                  return <div key={k} className={csn(style.item, { [style.stepIsPass]: v.charge_status === 1 }, { [style.stepIsReject]: v.charge_status === 2, },
                    { [style.stepIsProcess]: v.charge_status === 0, })}>
                    <div className={style.chargeUser}>
                      <div className={style.nick}>审核人: {v.nick}</div>
                      <div className={style.result}>{v.charge_status === 1 ? "通过" : v.charge_status === 2 ? "拒绝" : "审核中"}</div>
                    </div>
                    <Divider style={{ height: 50, }} type="vertical" />
                  </div>
                }) : null}
                <div className={style.chargeUserMsg}>审核通过后自动进入下一审核流程</div>
              </div>
            </div>
            <div className={style.msgList}>
              <div className={style.msgListTitle}>审核人员与申请人对话列表</div>
              <Divider style={{ margin: 0, }} />
              {this.state.applyDetail.steps.length > 0 ? this.state.applyDetail.steps[this.state.applyDetail.current - 1].messages.length > 0 ? this.state.applyDetail.steps[this.state.applyDetail.current - 1].messages.map((v, k) => {
                return <div key={k} className={style.item}>
                  {v.send_user.id === this.state.applyDetail.apply_uid ?
                    <div className={style.author}>
                      <div className={style.msgSendTime}>{v.ctime.substr(0, 10)}</div>
                      <div className={style.msgSendUserNick}>{v.send_user.nick ? v.send_user.nick : `未命名用户${v.send_user.id}`}</div>
                    </div> :
                    <div className={style.author}>
                      <div className={style.msgSendUserNick}>{v.send_user.nick ? v.send_user.nick : `未命名用户${v.send_user.id}`}</div>
                      <div className={style.msgSendTime}>{v.ctime.substr(0, 10)}</div>
                    </div>}
                  <div className={csn(style.msg, { [style.selfMsg]: v.send_user.id === this.state.applyDetail.apply_uid })}>
                    <div className={style.message}>{v.msg}</div>
                  </div>
                </div>
              }) : <Empty /> : <Empty />}
            </div>
            {this.getFooterOperationDom()}
          </div>
        </Spin>
      </div>
    );
  }
}
export default Form.create()(Info);
