import React, { Component, } from 'react';
import {
  Route, Redirect, Switch,
} from 'react-router-dom';
import Index from '../../../../pages/index/user/apply/Index';
import CreateProject from '../../../../pages/index/user/apply/CreateProject';
import CompanyEnter from '../../../../pages/index/user/apply/CompanyEnter';
import ConferenceRoomRent from '../../../../pages/index/user/apply/ConferenceRoomRent';
import CoffeeHouseRent from '../../../../pages/index/user/apply/CoffeeHouseRent';
import MealCard from '../../../../pages/index/user/apply/MealCard';
import ParkingPermit from '../../../../pages/index/user/apply/ParkingPermit';
import Recruit from '../../../../pages/index/user/apply/Recruit';
import Detail from '../../../../pages/index/user/apply/Detail';

export default class Wrap extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }
  render() {
    return (
      <Switch>
        <Route exact path="/user/apply" component={Index} />
        <Route exact path="/user/apply/detail" component={Detail} />
        <Route exact path="/user/apply/createProject" component={CreateProject} />
        <Route exact path="/user/apply/companyEnter" component={CompanyEnter} />
        <Route exact path="/user/apply/conferenceRoomRent" component={ConferenceRoomRent} />
        <Route exact path="/user/apply/coffeeHouseRent" component={CoffeeHouseRent} />
        <Route exact path="/user/apply/mealCard" component={MealCard} />
        <Route exact path="/user/apply/parkingPermit" component={ParkingPermit} />
        <Route exact path="/user/apply/recruit" component={Recruit} />
        <Redirect to="/user/apply" />
      </Switch>
    );
  }
}
