import React from "react"
import { Breadcrumb, Form, Steps, Alert, Spin, Empty, Divider, Tooltip } from "antd"
import { Link } from "react-router-dom"
import gSass from "../../../utils/sass"
import gImg from "../../../utils/img"
import csn from "classnames"
import Lightbox from "react-images"
import { LocalStorage as storage } from "jsbdk"
import qs from "qs"
import applyApi from "../../../services/apply"
import AuditWithSendMsg from "../../../components/admin/apply/AuditWithSendMsg"
import { BASE_URL } from "../../../config/prod"

const style = gSass.admin.apply.parkingPermitInfo
const Step = Steps.Step

class info extends AuditWithSendMsg {
  constructor(props) {
    super(props)
    let { id } = qs.parse(props.location.search, { ignoreQueryPrefix: true })
    this.state = {
      id,
      loading: true,
      lightboxIsOpen: false,
      lightBoxImgs: [],
      userNick: "",
      applyDetail: {
        info: {
          subjectName: "",
          userName: "",
          idCardName: "",
          contactInfo: "",
          idCardNo: "",
          driverLicensePic: gImg.common.idCardDefaultPic,
          drivingLicensePic: gImg.common.idCardDefaultPic,
        },
        apply_uid: 5,
        current: 1,
        total: 5,
        steps: [],
      },
    }
  }

  componentDidMount() {
    this.init()
  }

  init = async _ => {
    try {
      let {
        data: { detail: applyDetail },
      } = await applyApi.getApplyDetail({ id: this.state.id })
      this.setState({
        loading: false,
        userNick: storage.get("nick"),
        applyDetail,
      })
    } catch (err) {
      console.log(err)
    }
  }
  closeLightbox = _ => {
    this.setState({
      lightboxIsOpen: false,
      lightBoxImgs: [],
    })
  }
  closeApply = _ => { }
  render() {
    return (
      <div className={style.info}>
        <Breadcrumb className={style.title}>
          <Breadcrumb.Item>
            <Link to="/admin">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/admin/apply/allList">申请管理</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>停车证申请流程</Breadcrumb.Item>
        </Breadcrumb>
        <div className={style.prompt}>
          <Alert
            description=" 审核人可以审核之前与申请人进行沟通, 给予申请人在申请中出现的问题做答复"
            type="info"
            showIcon
            message="提示"
          />
        </div>
        <Spin spinning={this.state.loading} size="large">
          <div className={style.content}>
            <div className={style.step}>
              <Steps current={this.state.applyDetail.current - 1}>
                {this.state.applyDetail.steps.map((v, k) => {
                  return <Step key={k} title={v.name} description={<div />} />
                })}
              </Steps>
            </div>
            <div className={style.userInfo}>
              <div className={style.infoList}>
                {/* <div className={style.item}>
                  <div className={style.name}>申请单位:</div>
                  <div className={style.detail}>{this.state.applyDetail.info.subjectName}</div>
                </div> */}
                <div className={style.item}>
                  <div className={style.name}>申请人姓名:</div>
                  <div className={style.detail}>{this.state.applyDetail.info.carUserIdCardName}</div>
                </div>
                <div className={style.item}>
                  <div className={style.name}>身份证号码:</div>
                  <div className={style.detail}>{this.state.applyDetail.info.carUserIdCardNo}</div>
                </div>
                <div className={style.item}>
                  <div className={style.name}>手机号码:</div>
                  <div className={style.detail}>{this.state.applyDetail.info.contactInfo}</div>
                </div>
                <div className={style.item}>
                  <div className={style.name}>车牌号码:</div>
                  <div className={style.detail}>{this.state.applyDetail.info.license}</div>
                </div>
                <div className={style.itemImg}>
                  <div className={style.name}>驾驶证证件照:</div>
                  <div
                    className={style.img}
                    onClick={_ => {
                      this.setState({
                        lightboxIsOpen: true,
                        lightBoxImgs: [
                          {
                            src: `${BASE_URL + this.state.applyDetail.info.driverLicensePic}`,
                          },
                        ],
                      })
                    }}>
                    <img
                      src={`${BASE_URL + this.state.applyDetail.info.driverLicensePic}`}
                      alt="驾驶证证件照"
                    />
                  </div>
                </div>
                <div className={style.itemImg}>
                  <div className={style.name}>行驶证证件照:</div>
                  <div
                    className={style.img}
                    onClick={_ => {
                      this.setState({
                        lightboxIsOpen: true,
                        lightBoxImgs: [
                          {
                            src: `${BASE_URL + this.state.applyDetail.info.drivingLicensePic}`,
                          },
                        ],
                      })
                    }}>
                    <img
                      src={`${BASE_URL + this.state.applyDetail.info.drivingLicensePic}`}
                      alt="行驶证证件照"
                    />
                  </div>
                </div>
                <div className={style.itemImg}>
                  <div className={style.name}>身份证正面照:</div>
                  <div
                    className={style.img}
                    onClick={_ => {
                      this.setState({
                        lightboxIsOpen: true,
                        lightBoxImgs: [
                          {
                            src: `${BASE_URL + this.state.applyDetail.info.idCardFirstPic}`,
                          },
                        ],
                      })
                    }}>
                    <img
                      src={`${BASE_URL + this.state.applyDetail.info.idCardFirstPic}`}
                      alt="身份证正面照"
                    />
                  </div>
                </div>
                <div className={style.itemImg}>
                  <div className={style.name}>身份证反面照:</div>
                  <div
                    className={style.img}
                    onClick={_ => {
                      this.setState({
                        lightboxIsOpen: true,
                        lightBoxImgs: [
                          {
                            src: `${BASE_URL + this.state.applyDetail.info.idCardSecondPic}`,
                          },
                        ],
                      })
                    }}>
                    <img
                      src={`${BASE_URL + this.state.applyDetail.info.idCardSecondPic}`}
                      alt="身份证反面照"
                    />
                  </div>
                </div>
                <div className={style.itemImg}>
                  <div className={style.name}>当月缴纳社保凭证:</div>
                  <div
                    className={style.img}
                    onClick={_ => {
                      this.setState({
                        lightboxIsOpen: true,
                        lightBoxImgs: [
                          {
                            src: `${BASE_URL + this.state.applyDetail.info.socialImg}`,
                          },
                        ],
                      })
                    }}>
                    <img
                      src={`${BASE_URL + this.state.applyDetail.info.socialImg}`}
                      alt="当月缴纳社保凭证"
                    />
                  </div>
                </div>
              </div>
              <div className={style.chargeUserList}>
                <div className={style.theme}>审核人列表</div>
                <div className={style.reviewStyle}>
                  审核方式:
                  <span className={style.reviewStyleTitle}>
                    {this.state.applyDetail.steps.length > 0 ? (
                      this.state.applyDetail.steps[this.state.applyDetail.current - 1]
                        .should_all_pass === 1 ? (
                          <Tooltip title="“会签”：要求在每一轮审核中所有审核人员都通过该申请才会进入下一轮审核。">
                            会签
                        </Tooltip>
                        ) : (
                          <Tooltip title="“或签”：要求在每一轮审核中其中一个审核人通过该申请就进入下一轮申请。">
                            或签
                        </Tooltip>
                        )
                    ) : null}
                  </span>
                </div>
                {this.state.applyDetail.steps.length > 0
                  ? this.state.applyDetail.steps[
                    this.state.applyDetail.current - 1
                  ].charge_user_list.map((v, k) => {
                    return (
                      <div
                        key={k}
                        className={csn(
                          style.item,
                          { [style.stepIsPass]: v.charge_status === 1 },
                          { [style.stepIsReject]: v.charge_status === 2 },
                          { [style.stepIsProcess]: v.charge_status === 0 },
                        )}>
                        <div className={style.chargeUser}>
                          <div className={style.nick}>审核人: {v.nick}</div>
                          <div className={style.result}>
                            {v.charge_status === 1
                              ? "通过"
                              : v.charge_status === 2
                                ? "拒绝"
                                : "审核中"}
                          </div>
                        </div>
                        <Divider style={{ height: 50 }} type="vertical" />
                      </div>
                    )
                  })
                  : null}
                <div className={style.chargeUserMsg}>审核通过后自动进入下一审核流程</div>
              </div>
            </div>
            <div className={style.msgList}>
              <div className={style.msgListTitle}>审核人员与申请人对话列表</div>
              <Divider style={{ margin: 0 }} />
              {this.state.applyDetail.steps.length > 0 ? (
                this.state.applyDetail.steps[this.state.applyDetail.current - 1].messages.length >
                  0 ? (
                    this.state.applyDetail.steps[this.state.applyDetail.current - 1].messages.map(
                      (v, k) => {
                        return (
                          <div key={k} className={style.item}>
                            {v.send_user.id === this.state.applyDetail.apply_uid ? (
                              <div className={style.author}>
                                <div className={style.msgSendTime}>{v.ctime.substr(0, 10)}</div>
                                <div className={style.msgSendUserNick}>{v.send_user.nick}</div>
                              </div>
                            ) : (
                                <div className={style.author}>
                                  <div className={style.msgSendUserNick}>{v.send_user.nick}</div>
                                  <div className={style.msgSendTime}>{v.ctime.substr(0, 10)}</div>
                                </div>
                              )}
                            <div
                              className={csn(style.msg, {
                                [style.selfMsg]: v.send_user.id === this.state.applyDetail.apply_uid,
                              })}>
                              <div className={style.message}>{v.msg}</div>
                            </div>
                          </div>
                        )
                      },
                    )
                  ) : (
                    <Empty />
                  )
              ) : (
                  <Empty />
                )}
            </div>
            {this.getFooterOperationDom()}
          </div>
        </Spin>
        <Lightbox
          images={this.state.lightBoxImgs}
          isOpen={this.state.lightboxIsOpen}
          onClose={this.closeLightbox}
          showCloseButton={false}
          showImageCount={false}
          backdropClosesModal={true}
        />
      </div>
    )
  }
}
export default Form.create()(info)
