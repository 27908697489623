import React from "react";
import mealCard from "../../../services/mealCard";
import gSass from "../../../utils/sass";
import { Link, } from "react-router-dom";
import qs from "qs";
import {
  Breadcrumb,
  Form,
  Input,
  message,
  Spin,
  Button,
  Radio,
  Upload,
} from "antd";
import applyApi from "../../../services/apply";
import { IdentityCodeValid } from "../../../utils/utils";
import { IS_GRADUATE } from "../../../services/schoolInfo";
import { BASE_URL } from "../../../config/prod";
const style = gSass.admin.applyEdit.edit;
const RadioGroup = Radio.Group;
const formItemLayout = {
  labelCol: {
    lg: {
      span: 4,
      offset: 4,
    },
  },
  wrapperCol: {
    lg: {
      span: 8,
      offset: 0,
    },
  },
};

// 上传附件
let uploadList = []
const upload = {
  action: BASE_URL + '/antdUploadImg',
  onChange({ file, fileList }) {
    if (file.status !== 'uploading') {
      if (file.response.code === 0) {
        uploadList.push(file.response.data)
      } else {
        console.log(file.response.msg);
      }
    }
  },
  // defaultFileList: uploadList,

}
class Edit extends React.Component {
  constructor(props) {
    super(props);
    let { id } = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    id = parseInt(id);
    this.state = {
      projectId: id,
      spinning: true,
      industryList: [],
      patentTypeList: [],
      patentList: [],
      financingList: [],
      userInfo: {},
      idCardFirstPic: [],
      idCardSecondPic: [],
      previewVisible: false,
      previewImage: '',
      householdList: [],
      residentPic: [],
      isShanghai: null,
      applyId: 0,
      infoId: 0,
      uploadStateList: [],
    };
  }
  componentDidMount() {
    this.init();
  }
  init = async _ => {
    try {
      // 获得服务器数据
      let { data: { detail, } } = await applyApi.getApplyDetail({ id: this.state.projectId });
      let uploadStateList = this.state.uploadStateList
      if (detail.info.file.length > 0) {
        for (let v of detail.info.file) {
          uploadStateList.push({
            uid: v.id,
            name: v.title,
            url: BASE_URL + v.url
          })
        }
      } else {
        uploadStateList = []
      }
      this.setState({
        spinning: false,
        applyId: detail.info.id,

      });
      console.log(detail.info.gender)
      this.props.form.setFieldsValue({
        name: detail.info.idCardName,
        phone: detail.info.contactInfo,
        idCardNo: detail.info.idCardNo,
        gender: detail.info.gender
      });
    } catch (e) {
      console.log(e);
      this.setState({
        spinning: false,
      });
    }
  };

  //提交
  updateUserInfo = evt => {
    evt.preventDefault();
    this.props.form.validateFields(["name", "phone", "idCardNo", "gender"], (err, fields) => {
      if (err) {
        return message.error(err[Object.keys(err)[0]].errors[0].message, 1);
      }
      for (let i = 0; i < uploadList.length; i++) {
        if (uploadList[i].picId) {
          console.log(uploadList[i])
          uploadList[i].uid = uploadList[i].picId.toString();
          delete uploadList[i].picId
        }
      }
      mealCard
        .edit({
          applyId: this.state.applyId,
          name: fields.name,
          phone: fields.phone,
          idCardNo: fields.idCardNo || "",
          gender: fields.gender,
          file: uploadList,
        })
        .then(json => {
          message.success("修改成功", 1);
        })
        .catch(err => {
          message.error("修改失败,失败原因: " + err.msg, 1);
          console.log(err);
        });
    });
  };
  //身份证号码验证
  idCardNoValidation = evt => {
    let val = evt.target.value;
    IdentityCodeValid(val).then(json => {
      this.setState({
        idCardNoValidation: json.pass,
      });
      if (!json.pass) {
        message.error("身份证号码错误: " + json.msg, 1);
      }
    });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    //头像上传按钮
    return (
      <Spin tip="Loading..." spinning={this.state.spinning} size="large">
        <div className={style.info}>
          <Breadcrumb className={style.title}>
            <Breadcrumb.Item><Link to="/admin">首页</Link></Breadcrumb.Item>
            <Breadcrumb.Item><Link to="/admin/apply/allList">申请管理</Link></Breadcrumb.Item>
            <Breadcrumb.Item>饭卡编辑</Breadcrumb.Item>
          </Breadcrumb>
          <div className={style.basicWrap}>
            <Form className={style.form}>
              <Form.Item {...formItemLayout} label="申请人">
                {getFieldDecorator("name", {
                  rules: [{ required: true, message: "请输入申请人" }],
                })(<Input placeholder="请输入" />)}
              </Form.Item>
              <Form.Item {...formItemLayout} label="联系方式">
                {getFieldDecorator("phone", {
                  rules: [{ required: true, message: "请输入联系方式" }],
                })(<Input placeholder="请输入" />)}
              </Form.Item>
              <Form.Item {...formItemLayout} label="身份证号码"  >
                {getFieldDecorator('idCardNo', {
                  rules: [{ required: true, message: '请输入身份证号码' }],
                })(
                  <Input placeholder="请输入身份证号码" onBlur={this.idCardNoValidation} />)}
              </Form.Item>
              <Form.Item {...formItemLayout} label="性别"  >
                {getFieldDecorator('gender', {
                  rules: [{ required: true, message: '性别' }],
                })(
                  <RadioGroup onChange={evt => {
                    this.setState({
                      isGraduate: evt.target.value,
                    })
                  }}>
                    <Radio value={IS_GRADUATE.UNDEFINED}> 男</Radio>
                    <Radio value={IS_GRADUATE.FALSE}>女</Radio>
                  </RadioGroup>
                )}
              </Form.Item>
              <Form.Item {...formItemLayout} label="提示">
                <div className={style.tips}>申请当月社保缴纳通知书</div>
                <Upload {...upload} defaultFileList={this.state.uploadStateList}>
                  <Button className={style.uploadBtn} >
                    上传
                  </Button>
                </Upload>
              </Form.Item>
              <Form.Item wrapperCol={{
                xs: {
                  span: 7,
                  offset: 8,
                },
              }}>
                <Button className={style.btn} type="primary" onClick={this.updateUserInfo}>更新基本信息</Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Spin>
    );
  }
}
export default Form.create()(Edit);
