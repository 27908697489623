import React, { Component, } from 'react';
import { Switch, Redirect, } from 'react-router-dom';
import AdminAuthRoute from '../../../utils/AdminAuth';
import cafeRoomRent from "../../../pages/admin/cafeRoomRent/List";

export default class Wrap extends Component {
  render() {
    return (
      <Switch>
        <AdminAuthRoute exact path="/admin/cafeRoomRent/list/" component={cafeRoomRent} />
        <Redirect to="/admin/cafeRoomRent/list/" />
      </Switch>
    );
  }

}
