import React, { Component } from "react";
import { Empty, } from "antd";
import cns from "classnames";
import { PAYMENT_STATUS, PAYMENT_STATUS_ZH } from "../../../services/mealCard";
import gStyle from "../../../utils/sass";
const style = gStyle.index.company.mealCard

export default class MealCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {
  }
  render() {
    return (
      <div className={style.list}>
        {this.props.list.length > 0 ? this.props.list.map((v, k) => {
          return (<div key={k} className={style.item}>
            <div className={style.num}>饭卡{k + 1}: </div>
            <div className={style.flex}>
              <div className={style.title}>姓名: </div>
              <div className={style.detail}>{v.name}</div>
            </div>
            <div className={style.flex}>
              <div className={style.title}>卡号: </div>
              <div className={style.detail}>{v.cardNo}</div>
            </div>
            <div className={style.flex}>
              <div className={style.title}>使用期限: </div>
              <div className={style.detail}>{v.beginTime.substr(0, 10)}至
              {v.expireTime.substr(0, 10)}</div>
            </div>
            <div className={style.flex}>
              <div className={style.title}>是否缴纳20元押金: </div>
              <div className={cns(style.detail, v.paymentStatus === PAYMENT_STATUS.TRUE ?
                style.green : style.red)}>{v.paymentStatus === PAYMENT_STATUS.TRUE ?
                  PAYMENT_STATUS_ZH[PAYMENT_STATUS.TRUE] : PAYMENT_STATUS_ZH[PAYMENT_STATUS.FALSE]}</div>
            </div>
          </div>);
        }) : <Empty />}
      </div>);
  }
}
