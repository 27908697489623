import React, { Component } from "react"
import {
  Table,
  Button,
  Breadcrumb,
  Input,
  Tooltip,
  Modal,
  message,
  Form,
  Select,
  DatePicker,
} from "antd"
import { trim } from "jsbdk"
import moment from "moment"
import { Link, NavLink } from "react-router-dom"
import applyApi from "../../../services/apply"
import { STATUS, STATUS_ZH } from "../../../services/apply"
import { TYPE as ConstantType, TYPE_ZH as ConstantTypeZh } from "../../../utils/constant"
import gSass from "../../../utils/sass"
import gImg from "../../../utils/img"

const style = gSass.admin.apply.mealCardList

const Search = Input.Search
const Option = Select.Option
const RangePicker = DatePicker.RangePicker
class MealCardList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isFilterModalActive: false,
      tableIsLoading: true,
      applyTimeCondition: ConstantType.undefined,
      filteredInfo: null,
      sortedInfo: null,
      defaultAvatar: gImg.user.defaultAvatar,
      filter: {
        applyUserNameOrPhoneOrIdCardNo: {
          condition: ConstantType.undefined,
          val: "",
        },
        status: {
          condition: ConstantType.undefined,
          val: ConstantType.undefined,
        },
        applyTime: {
          condition: ConstantType.undefined,
          val: null,
        },
      },
      list: [],
      pagination: {
        current: 1,
        total: 0,
        pageSize: 8,
        showTotal: total => {
          return `共${total}条记录`
        },
        onChange: async (page, pageSize) => {
          const pagination = this.state.pagination
          pagination.current = page
          pagination.pageSize = pageSize
          try {
            let page = pagination.current,
              limit = pagination.pageSize,
              filter = this.state.filter
            this.setState({
              tableIsLoading: true,
            })
            const {
              count,
              data: { list },
            } = await applyApi.getMealCardApplyList({ page, limit, filter })
            pagination.total = count
            this.setState({
              tableIsLoading: false,
              list,
              pagination,
            })
          } catch (err) {
            console.log(err)
            this.setState({
              tableIsLoading: false,
            })
          }
        },
      },
    }
  }

  componentDidMount() {
    this.init()
  }

  init = async _ => {
    try {
      let pagination = this.state.pagination
      const {
        data: { list },
        count,
      } = await applyApi.getMealCardApplyList({
        page: this.state.pagination.current,
        limit: this.state.pagination.pageSize,
        filter: this.state.filter,
      })
      pagination.total = count
      this.setState({
        tableIsLoading: false,
        list,
        pagination,
      })
    } catch (err) {
      this.setState({
        tableIsLoading: false,
      })
      console.log(err)
    }
  }
  //筛选
  getMealCardApplyList = evt => {
    evt.preventDefault()
    this.setState({
      tableIsLoading: true,
    })
    this.props.form.validateFields((err, fields) => {
      if (err !== null) {
        return message.error(err[0].errors, 3)
      }
      let filter = this.state.filter
      if (
        parseInt(fields.applyUserNameOrPhoneOrIdCardNoCondition) !== ConstantType.undefined &&
        trim(fields.applyUserNameOrPhoneOrIdCardNoVal) !== ""
      ) {
        filter.applyUserNameOrPhoneOrIdCardNo = {
          condition: parseInt(fields.applyUserNameOrPhoneOrIdCardNoCondition),
          val: trim(fields.applyUserNameOrPhoneOrIdCardNoVal),
        }
      }
      if (
        parseInt(fields.statusCondition) !== ConstantType.undefined &&
        Object.keys(STATUS)
          .map(statusKey => STATUS[statusKey])
          .includes(parseInt(fields.statusVal))
      ) {
        filter.status = {
          condition: parseInt(fields.statusCondition),
          val: parseInt(fields.statusVal),
        }
      }
      if (parseInt(fields.applyTimeCondition) !== ConstantType.undefined) {
        filter.applyTime.condition = parseInt(fields.applyTimeCondition)
        switch (filter.applyTime.condition) {
          case ConstantType.after:
            if (!fields.applyTimeAfterVal) {
              return message.error("请选择申请时间", 3)
            }
            filter.applyTime.val = moment(fields.applyTimeAfterVal).format("YYYY-MM-DD HH:mm:ss")
            break
          case ConstantType.before:
            if (!fields.applyTimeBeforeVal) {
              return message.error("请选择申请时间", 3)
            }
            filter.applyTime.val = moment(fields.applyTimeBeforeVal).format("YYYY-MM-DD HH:mm:ss")
            break
          case ConstantType.betweenTime:
            if (!fields.applyTimeBetweenVal) {
              return message.error("请选择申请时间", 3)
            }
            filter.applyTime.val = [
              moment(fields.applyTimeBetweenVal[0]).format("YYYY-MM-DD HH:mm:ss"),
              moment(fields.applyTimeBetweenVal[1]).format("YYYY-MM-DD HH:mm:ss"),
            ]
            break
          default:
            break
        }
      }
      console.log(filter)
      applyApi
        .getMealCardApplyList({
          page: this.state.pagination.current,
          limit: this.state.pagination.pageSize,
          filter,
        })
        .then(json => {
          message.success("正在筛选, 请稍等", 1)
          this.setState({
            list: json.data,
          })
        })
        .catch(err => {
          message.success("获取申请列表失败,错误信息 " + err.msg, 3)
          console.log(err)
        })
      //关闭模态框
      this.setState({
        filter,
        isFilterModalActive: false,
        tableIsLoading: false,
      })
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form
    const columns = [
      {
        title: "ID",
        dataIndex: "id",
      },
      {
        title: "申请人",
        dataIndex: "apply_user",
        render: (user, record, index) => {
          return (
            <Tooltip
              placement="right"
              title={_ => {
                return (
                  <div>
                    <div>id: {user.id}</div>
                    <div>注册时间: {user.ctime}</div>
                    <div>身份证号: {user.idCardNo}</div>
                  </div>
                )
              }}>
              <span style={{ color: "#1890ff" }}>{user.name}</span>
            </Tooltip>
          )
        },
      },
      // {
      //   title: '审核进程',
      //   dataIndex: 'step',
      //   render: (step, record, index) => {
      //     return (<div>{step.current} / {step.total}</div>)
      //   }
      // },
      {
        title: "审核状态",
        dataIndex: "status",
        render(status) {
          return status === "处理中" ? <div style={{ color: "red" }}>处理中</div> : status
        },
      },
      {
        title: "申请时间",
        dataIndex: "apply_time",
      },
      {
        title: "操作",
        dataIndex: "operation",
        width: 300,
        render: (text, record) =>
          this.state.list.length >= 1 ? (
            <div>
              <Link to={"/admin/apply/mealCardInfo?id=" + record.id} className={style.btn}>
                查看
              </Link>
              <div className="separate-line"></div>
              <Link to={"/admin/apply/editMealCardInfo?id=" + record.id} className={style.btn}>
                编辑
              </Link>
            </div>
          ) : null,
      },
    ]

    return (
      <div className={style.mealCardList}>
        <Breadcrumb className={style.title}>
          <Breadcrumb.Item>
            <Link to="/admin">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/admin/apply/allList">申请管理</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>饭卡</Breadcrumb.Item>
        </Breadcrumb>
        <div className={style.filters}>
          <div className={style.item}>
            <Button
              className={style.filterBtn}
              type="primary"
              icon="filter"
              onClick={_ => {
                this.setState({
                  isFilterModalActive: true,
                })
              }}>
              筛选
            </Button>
            <NavLink to="/admin/affairs/mealCard/edit" className={style.filterBtn}>
              <Button type="primary" icon="plus">
                编辑流程
              </Button>
            </NavLink>
          </div>
          <Search
            placeholder="请输入申请人姓名|手机号|邮箱|身份证号码..."
            enterButton="搜索"
            className={style.search}
            onSearch={val => {
              let page = this.state.page
              let filter = this.state.filter
              page = 1
              filter.search = trim(val)
              this.setState(
                {
                  page,
                  filter,
                },
                _ => {
                  applyApi
                    .getMealCardApplyList({
                      page: 1,
                      limit: this.state.pagination.pageSize,
                      filter: this.state.filter,
                    })
                    .then(json => {
                      let pagination = this.state.pagination
                      pagination.current = 1
                      pagination.total = json.count
                      this.setState({
                        list: json.data.list,
                      })
                    })
                    .catch(err => {
                      message.error("获取用户列表失败 " + err.msg, 3)
                    })
                },
              )
            }}
          />
        </div>
        <Table
          className={style.table}
          columns={columns}
          dataSource={this.state.list}
          rowKey="id"
          loading={this.state.tableIsLoading}
          pagination={this.state.pagination}
          rowSelection={{
            onChange: (selectedRowKeys, selectedRows) => {
              this.setState({
                selectedRows,
              })
            },
          }}
        />
        <Modal
          title="筛选"
          visible={this.state.isFilterModalActive}
          okText="确认"
          cancelText="取消"
          onCancel={_ => {
            this.setState({ isFilterModalActive: false })
          }}
          onOk={this.getMealCardApplyList}>
          <Form className={style.formList}>
            <div className={style.item}>
              <div className={style.title}>申请人</div>
              {getFieldDecorator("applyUserNameOrPhoneOrIdCardNoCondition", {
                initialValue: ConstantType.undefined,
              })(
                <Select className={style.condition}>
                  <Option value={ConstantType.undefined}>请选择</Option>
                  <Option value={ConstantType.eqString}>
                    {ConstantTypeZh[ConstantType.eqString]}
                  </Option>
                  <Option value={ConstantType.like}>{ConstantTypeZh[ConstantType.like]}</Option>
                  <Option value={ConstantType.neqString}>
                    {ConstantTypeZh[ConstantType.neqString]}
                  </Option>
                </Select>,
              )}
              {getFieldDecorator("applyUserNameOrPhoneOrIdCardNoVal", {})(
                <Input placeholder="请输入" className={style.input} />,
              )}
            </div>
            <div className={style.item}>
              <div className={style.title}>审核状态</div>
              {getFieldDecorator("statusCondition", { initialValue: ConstantType.undefined })(
                <Select className={style.condition}>
                  <Option value={ConstantType.undefined}>请选择</Option>
                  <Option value={ConstantType.eq}>{ConstantTypeZh[ConstantType.eq]}</Option>
                  <Option value={ConstantType.neq}>{ConstantTypeZh[ConstantType.neq]}</Option>
                </Select>,
              )}
              {getFieldDecorator("statusVal", { initialValue: "-1" })(
                <Select className={style.input}>
                  <Option value="-1">全部</Option>
                  {Object.keys(STATUS)
                    .map(i => STATUS[i])
                    .map((v, k) => {
                      return (
                        <Option key={`${k}`} value={v}>
                          {STATUS_ZH[v]}
                        </Option>
                      )
                    })}
                </Select>,
              )}
            </div>
            <div className={style.item}>
              <div className={style.title}>申请时间</div>
              {getFieldDecorator("applyTimeCondition", { initialValue: ConstantType.undefined })(
                <Select
                  className={style.condition}
                  onChange={val => {
                    this.setState({ applyTimeCondition: val })
                  }}>
                  <Option value={ConstantType.undefined}>请选择</Option>
                  <Option value={ConstantType.before}>{ConstantTypeZh[ConstantType.before]}</Option>
                  <Option value={ConstantType.after}>{ConstantTypeZh[ConstantType.after]}</Option>
                  <Option value={ConstantType.betweenTime}>
                    {ConstantTypeZh[ConstantType.betweenTime]}
                  </Option>
                </Select>,
              )}
              {getFieldDecorator("applyTimeBeforeVal", {})(
                <DatePicker
                  format={"YYYY-MM-DD"}
                  className={
                    this.state.applyTimeCondition === ConstantType.before
                      ? style.input
                      : style.hidden
                  }
                />,
              )}
              {getFieldDecorator("applyTimeBetweenVal", {})(
                <RangePicker
                  format={"YYYY-MM-DD"}
                  className={
                    this.state.applyTimeCondition === ConstantType.betweenTime
                      ? style.input
                      : style.hidden
                  }
                />,
              )}
              {getFieldDecorator("applyTimeAfterVal", {})(
                <DatePicker
                  format={"YYYY-MM-DD"}
                  className={
                    this.state.applyTimeCondition === ConstantType.after
                      ? style.input
                      : style.hidden
                  }
                />,
              )}
            </div>
          </Form>
        </Modal>
      </div>
    )
  }
}
export default Form.create()(MealCardList)
