import React from 'react';
import {
  Route, Redirect, Switch,
} from 'react-router-dom';
import { Layout, } from 'antd';
import gSass from '../../utils/sass';

import Workplace from '../../pages/common/Workplace';
import BasicList from '../../pages/common/BasicList';
import BuffTest from '../test/Buff';
import BasicForm from "../../pages/common/BasicForm";
import OperationWrap from "../../components/admin/operation/Wrap";
import UserWrap from "../../components/admin/user/Wrap";
import ApplyWrap from '../../components/admin/apply/Wrap';
import SchoolWrap from "../../components/admin/school/Wrap";
import CompanyWrap from "../../components/admin/company/Wrap";
import ProjectWrap from "../../components/admin/project/Wrap";
import conferenceRoomRentWrap from "../../components/admin/conferenceRoomRent/Wrap";
import cafeRoomRent from "../../components/admin/cafeRoomRent/Wrap";
import Settings from "../../pages/admin/settings/Base";
// import Information from "../../pages/admin/user/Information";
import AdminAuthRoute from '../../utils/AdminAuth';
import AffairsEdit from "../../pages/admin/affairs/Edit";
const style = gSass.common.content;

const { Content: AntdContent, } = Layout;

export default class Content extends AntdContent {
  constructor(props) {
    super(props)
    this.state = {
    }
  }
  render() {
    return (
      <AntdContent>
        <div className={style.content}>
          <Switch>
            <Route exact path="/buff" component={BuffTest} />
            <AdminAuthRoute exact path="/admin" component={Workplace} />
            <AdminAuthRoute exact path="/admin/BasicList" component={BasicList} />
            <AdminAuthRoute exact path="/admin/basicForm" component={BasicForm} />
            <AdminAuthRoute path="/admin/operation" component={OperationWrap} />
            <AdminAuthRoute path="/admin/user" component={UserWrap} />
            <AdminAuthRoute path="/admin/apply" component={ApplyWrap} />
            <AdminAuthRoute path="/admin/school" component={SchoolWrap} />
            <AdminAuthRoute path="/admin/company" component={CompanyWrap} />
            <AdminAuthRoute path="/admin/project" component={ProjectWrap} />
            <AdminAuthRoute path="/admin/conferenceRoomRent" component={conferenceRoomRentWrap} />
            <AdminAuthRoute path="/admin/cafeRoomRent" component={cafeRoomRent} />
            <AdminAuthRoute path="/settings" component={Settings} />
            {/* <AdminAuthRoute exact path="/admin/information" component={Information} /> */}
            <AdminAuthRoute exact path="/admin/affairs/:affairs/edit" component={AffairsEdit} />
            <Redirect to="/admin" />
          </Switch>
        </div>
      </AntdContent>
    );
  }

}
