import React from "react"
import {
  Breadcrumb,
  Form,
  Steps,
  Alert,
  Spin,
  Divider,
  Tooltip,
  Collapse,
  Modal,
  Badge,
  Upload,
  Button
} from "antd"
import { LocalStorage as storage } from "jsbdk"
import { BASE_URL, } from '../../../config/prod';
import { Link } from "react-router-dom"
import csn from "classnames"
import qs from "qs"
import gSass from "../../../utils/sass"
// import applyApi from "../../../services/apply"
import applyApi, { EDUCATION, EDUCATION_ZH, } from "../../../services/apply"
import companyApi from "../../../services/company"
import AuditWithSendMsg from "../../../components/admin/apply/AuditWithSendMsg"
import { TYPE as PATENT_TYPE, TYPE_ZH as PATENT_TYPE_ZH, FINANCING_TYPE as FinancingType, FINANCING_TYPE_ZH as FinancingTypeZh, SUBORDINATE_TYPE as SubordinateType, SUBORDINATE_TYPE_ZH as SubordinateTypeZh } from "../../../services/patent"

const style = gSass.admin.apply.createProjectInfo
const Step = Steps.Step
const Panel = Collapse.Panel
// 专利类型
const patentTypeList = Object.values(PATENT_TYPE).map(v => ({
  value: v,
  label: PATENT_TYPE_ZH[v],
}))
// 融资形式
const financingTypeList = Object.values(FinancingType).map(v => ({
  value: v,
  label: FinancingTypeZh[v]
}))
// 所属等级
const subordinateTypeList = Object.values(SubordinateType).map(v => ({
  value: v,
  label: SubordinateTypeZh[v],
}))

// 上传附件
let uploadList = [];
const upload = {
  action: BASE_URL + '/antdUploadImg',
  onChange({ file, fileList }) {
    if (file.status !== 'uploading') {
      if (file.response.code === 0) {
        uploadList.push(file.response.data)
      } else {
        console.log(file.response.msg);
      }
    }
  },
  // defaultFileList: uploadList,
}
class CreateProjectInfo extends AuditWithSendMsg {
  constructor(props) {
    super(props)
    let { id } = qs.parse(props.location.search, { ignoreQueryPrefix: true })
    id = parseInt(id)
    this.state = {
      id,
      userNick: "",
      loading: true,
      phone: "",
      email: "",
      uploadStateList: [],
      applyDetail: {
        needProcess: false,
        apply_uid: 5,
        current: 1,
        total: 5,
        info: {
          id: 1,
          name: "",
          totalNumber: null,
          industry: "",
          description: "",
          ctime: "",
          education: null,

        },
        applyUser: {
          name: "",
          id_no: "",
          phone: "",
          email: "",
        },
        steps: [],
        patentList: [],
        prize_id_list: [],
        financingRecordList: [],
        overseas_id_list: [],
      },
    }
  }

  componentDidMount() {
    this.init()
  }
  init = async _ => {
    try {
      this.setState({
        userNick: storage.get("nick"),
      })
      // 获取学历
      let educationList =
        Object.values(EDUCATION).map((v, k) => ({
          value: v,
          label: EDUCATION_ZH[v],
        }))
      // 获取行业
      let {
        data: { list: industryList },
      } = await companyApi.getIndustryList({ page: -1, limit: -1 })
      industryList = industryList.map(v => ({
        value: v.id,
        label: v.name,
      }))
      let {
        data: { detail: applyDetail },
      } = await applyApi.getCreateProjectApplyDetail({ id: this.state.id })
      let industry = ""
      for (let v of industryList) {
        if (v.value === applyDetail.info.industryId) {
          industry = v.label
        }
      }
      this.setState({
        loading: false,
        applyDetail,
      })
      let education = "";
      for (let v of educationList) {
        if (v.value === applyDetail.applyUser.education) {
          education = v.label
        }
      }
      this.setState({
        loading: false,
        applyDetail,
      })
      applyDetail = this.state.applyDetail
      applyDetail.info.industry = industry
      applyDetail.applyUser.education = education
      // 专利类型
      for (let i = 0; i < applyDetail.patentList.length; i++) {
        for (let j = 0; j < patentTypeList.length; j++) {
          if (applyDetail.patentList[i].type === patentTypeList[j].value) {
            applyDetail.patentList[i].type = patentTypeList[j].label
          }
        }
      }
      // 海外留学学历
      for (let i = 0; i < applyDetail.overseas_id_list.length; i++) {
        for (let j = 0; j < educationList.length; j++) {
          if (applyDetail.overseas_id_list[i].education === educationList[j].value) {
            applyDetail.overseas_id_list[i].education = educationList[j].label;
          }
        }
      }
      // 融资形式
      for (let i = 0; i < applyDetail.financingRecordList.length; i++) {
        for (let j = 0; j < financingTypeList.length; j++) {
          if (parseInt(applyDetail.financingRecordList[i].mode) === financingTypeList[j].value) {
            applyDetail.financingRecordList[i].mode = financingTypeList[j].label;
          }
        }
      }
      // 所属等级
      for (let i = 0; i < applyDetail.prize_id_list.length; i++) {
        for (let j = 0; j < subordinateTypeList.length; j++) {
          if (parseInt(applyDetail.prize_id_list[i].belong_level) === subordinateTypeList[j].value) {
            applyDetail.prize_id_list[i].belong_level = subordinateTypeList[j].label;
          }
        }
      }
      let uploadStateList = this.state.uploadStateList
      console.log(applyDetail.project_plan.length)
      if (applyDetail.project_plan.length > 0) {
        for (let v of applyDetail.project_plan) {
          uploadStateList.push({
            uid: v.picId,
            name: v.name,
            url: BASE_URL + v.url
          })
        }
      } else {
        uploadStateList = []
      }
      this.setState({
        applyDetail,
        uploadStateList,
      })

    } catch (e) {
      console.log(e)
    }
  }
  //关闭模态框
  closePicActiveModal = _ => {
    this.setState({
      isShowPicModalActive: false,
    })
  }

  closeApply = _ => { }
  render() {
    return (
      <div className={style.info}>
        <Breadcrumb className={style.title}>
          <Breadcrumb.Item>
            <Link to="/admin">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/admin/apply/allList">申请管理</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>项目入驻流程</Breadcrumb.Item>
        </Breadcrumb>
        <div className={style.prompt}>
          <Alert
            description=" 审核人可以审核之前与申请人进行沟通, 给予申请人在申请中出现的问题做答复"
            type="info"
            showIcon
            message="提示"
          />
        </div>
        <Spin spinning={this.state.loading} size="large">
          <div className={style.content}>
            <div className={style.step}>
              <Steps current={this.state.applyDetail.current - 1}>
                {this.state.applyDetail.steps.length > 0 &&
                  this.state.applyDetail.steps.map((v, k) => {
                    return <Step key={k} title={v.name} description={<div />} />
                  })}
              </Steps>
            </div>
            <div className={style.userInfo}>
              <div className={style.infoList}>
                <div className={style.item}>
                  <div className={style.name}>申请人:</div>
                  <div className={style.detail}>{this.state.applyDetail.applyUser.name}</div>
                </div>
                <div className={style.item}>
                  <div className={style.name}>申请人身份证号:</div>
                  <div className={style.detail}>{this.state.applyDetail.applyUser.id_no}</div>
                </div>
                <div className={style.item}>
                  <div className={style.name}>电话:</div>
                  <div className={style.detail}>{this.state.applyDetail.applyUser.phone}</div>
                </div>
                <div className={style.item}>
                  <div className={style.name}>邮箱:</div>
                  <div className={style.detail}>{this.state.applyDetail.applyUser.email}</div>
                </div>
                <div className={style.item}>
                  <div className={style.name}>项目名称:</div>
                  <div className={style.detail}>{this.state.applyDetail.info.name}</div>
                </div>
                <div className={style.item}>
                  <div className={style.name}>项目负责人姓名:</div>
                  <div className={style.detail}>{this.state.applyDetail.applyUser.name}</div>
                </div>
                <div className={style.item}>
                  <div className={style.name}>团队总人数:</div>
                  <div className={style.detail}>{this.state.applyDetail.info.totalNumber}</div>
                </div>
                <div className={style.item}>
                  <div className={style.name}>团队中上海户籍人数:</div>
                  <div className={style.detail}>{this.state.applyDetail.info.household}</div>
                </div>
                <div className={style.item}>
                  <div className={style.name}>所属行业:</div>
                  <div className={style.detail}>{this.state.applyDetail.info.industry}</div>
                </div>

                <div className={style.panel}>
                  <Collapse accordion>
                    {/* 专利信息 */}
                    <Panel
                      header={
                        <div>
                          专利信息
                          {this.state.applyDetail.patentList.length > 0 ? (
                            <Badge
                              style={{ marginLeft: "10px" }}
                              count={this.state.applyDetail.patentList.length}
                            />
                          ) : null}
                        </div>
                      }
                      key="1">
                      {this.state.applyDetail.patentList.map((v, k) => {
                        return (
                          <div className={style.panelList} key={k}>
                            <div className={style.panelTitle}>{`专利${k + 1}`}</div>
                            <div className={style.panelItem}>
                              <div className={style.panelName}>{`专利名`}</div>
                              <div className={style.panelDetail}>{v.name}</div>
                            </div>
                            <div className={style.panelItem}>
                              <div className={style.panelName}>{`专利类型`}</div>
                              <div className={style.panelDetail}>{v.type}</div>
                            </div>
                            <div className={style.panelItem}>
                              <div className={style.panelName}>{`获取专利时间`}</div>
                              <div className={style.panelDetail}>{v.time.substr(0, 10)}</div>
                            </div>
                          </div>
                        )
                      })}
                    </Panel>
                    {/* 融资信息 */}
                    <Panel
                      header={
                        <div>
                          融资信息
                          {this.state.applyDetail.financingRecordList.length > 0 ? (
                            <Badge
                              style={{ marginLeft: "10px" }}
                              count={this.state.applyDetail.financingRecordList.length}
                            />
                          ) : null}
                        </div>
                      }
                      key="2">
                      {this.state.applyDetail.financingRecordList.map((v, k) => {
                        return (
                          <div className={style.panelList} key={k}>
                            <div className={style.panelTitle}>{`融资${k + 1}`}</div>
                            <div className={style.panelItem}>
                              <div className={style.panelName}>{`融资金额( 元 )`}</div>
                              <div className={style.panelDetail}>{v.money / 100}</div>
                            </div>
                            <div className={style.panelItem}>
                              <div className={style.panelName}>{`融资来源`}</div>
                              <div className={style.panelDetail}>{v.source}</div>
                            </div>
                            <div className={style.panelItem}>
                              <div className={style.panelName}>{`获取融资时间`}</div>
                              <div className={style.panelDetail}>{v.time.substr(0, 10)}</div>
                            </div>
                            <div className={style.panelItem}>
                              <div className={style.panelName}>{`融资形式`}</div>
                              <div className={style.panelDetail}>{v.mode}</div>
                            </div>
                          </div>
                        )
                      })}
                    </Panel>
                    {/* 获奖列表 */}
                    <Panel header={<div>获奖列表{this.state.applyDetail.prize_id_list.length > 0 ?
                      <Badge style={{ marginLeft: '10px', }} count={this.state.applyDetail.prize_id_list.length} /> : null}</div>} key="3">
                      {this.state.applyDetail.prize_id_list.map((v, k) => {
                        return (<div className={style.panelList} key={k}>
                          <div className={style.panelTitle}>{`获奖${k + 1}`}</div>
                          <div className={style.panelItem}>
                            <div className={style.panelName}>{`获奖名称`}</div>
                            <div className={style.panelDetail}>{v.name}</div>
                          </div>
                          <div className={style.panelItem}>
                            <div className={style.panelName}>{`所属等级`}</div>
                            <div className={style.panelDetail}>{v.belong_level}</div>
                          </div>
                          <div className={style.panelItem}>
                            <div className={style.panelName}>{`获奖时间`}</div>
                            <div className={style.panelDetail}>{v.win_time.substr(0, 10)}</div>
                          </div>
                          <div className={style.panelItem}>
                            <div className={style.panelName}>{`获奖等级`}</div>
                            <div className={style.panelDetail}>{v.prize_level}</div>
                          </div>
                        </div>);
                      })}
                    </Panel>
                    {/* 海外留学信息 */}
                    <Panel header={
                      <div>是否有海外留学背景成员{this.state.applyDetail.overseas_id_list.length > 0 ? <Badge style={{ marginLeft: '10px' }} count={this.state.applyDetail.overseas_id_list.length} /> : null}
                      </div>} key="6"
                    >
                      {this.state.applyDetail.overseas_id_list.map((v, k) => {
                        return (
                          <div className={style.panelList} key={k}>
                            <div className={style.panelTitle}>{`海外留学背景成员${k + 1}`}</div>
                            <div className={style.panelItem}>
                              <div className={style.panelName}>{`姓名`}</div>
                              <div className={style.panelDetail}>{v.name}</div>
                            </div>
                            <div className={style.panelItem}>
                              <div className={style.panelName}>{`学校`}</div>
                              <div className={style.panelDetail}>{v.college_id}</div>
                            </div>
                            <div className={style.panelItem}>
                              <div className={style.panelName}>{`专业`}</div>
                              <div className={style.panelDetail}>{v.speciality}</div>
                            </div>
                            <div className={style.panelItem}>
                              <div className={style.panelName}>{`学历`}</div>
                              <div className={style.panelDetail}>{v.education}</div>
                            </div>
                            <div className={style.panelItem}>
                              <div className={style.panelName}>{`毕业时间`}</div>
                              <div className={style.panelDetail}>{v.graduation_time.substr(0, 10)}</div>
                            </div>
                            <div className={style.panelItemImg}>
                              <div className={style.panelName}>{`学历证明图片`}</div>
                              <img className={style.panelImg} src={BASE_URL + v.degree_pic_url.url} alt="学历证明图片"
                                onClick={_ => {
                                  this.setState({
                                    lightboxIsOpen: true,
                                    lightBoxImgs: [{ src: `${BASE_URL}${v.studyAbroadInfo.educationProvePic.url}` }],
                                  })
                                }} />

                            </div>
                          </div>
                        )
                      })}
                    </Panel>
                  </Collapse>
                </div>

                <div className={style.item}>
                  <div className={style.name}>项目描述:</div>
                  <div
                    className={csn(style.detail, style.description)}
                    onClick={_ => {
                      Modal.success({
                        title: "岗位描述",
                        maskClosable: true,
                        content: <div>{this.state.applyDetail.info.description}</div>,
                      })
                    }}>
                    {this.state.applyDetail.info.description}
                  </div>
                </div>
                <div className={style.itemUpload}>
                  <div className={style.tips}>提示:如果有负责人简历,专利证书,融资证明文件,获奖证书,
         项目计划书，商业计划书等,请上传一张对应的附件</div>
                  <Upload className={style.uploadWrap} {...upload} defaultFileList={this.state.uploadStateList}>
                    <Button className={style.uploadBtn} >
                      上传
                  </Button>
                  </Upload>
                </div>
              </div>
              <div className={style.chargeUserList}>
                <div className={style.theme}>审核人列表</div>
                <div className={style.reviewStyle}>
                  审核方式:
                  <span className={style.reviewStyleTitle}>
                    {this.state.applyDetail.steps.length > 0 &&
                      this.state.applyDetail.steps[this.state.applyDetail.current - 1]
                        .should_all_pass === 1 ? (
                        <Tooltip title="“会签”：要求在每一轮审核中所有审核人员都通过该申请才会进入下一轮审核。">
                          会签
                        </Tooltip>
                      ) : (
                        <Tooltip title="“或签”：要求在每一轮审核中其中一个审核人通过该申请就进入下一轮申请。">
                          或签
                        </Tooltip>
                      )}
                  </span>
                </div>
                {this.state.applyDetail.steps.length > 0 &&
                  this.state.applyDetail.steps[
                    this.state.applyDetail.current - 1
                  ].charge_user_list.map((v, k) => {
                    return (
                      <div
                        key={k}
                        className={csn(
                          style.item,
                          { [style.stepIsPass]: v.charge_status === 1 },
                          { [style.stepIsReject]: v.charge_status === 2 },
                          { [style.stepIsProcess]: v.charge_status === 0 },
                        )}>
                        <div className={style.chargeUser}>
                          <div className={style.nick}>审核人: {v.nick}</div>
                          <div className={style.result}>
                            {v.charge_status === 1
                              ? "通过"
                              : v.charge_status === 2
                                ? "拒绝"
                                : "审核中"}
                          </div>
                        </div>
                        <Divider style={{ height: 50 }} type="vertical" />
                      </div>
                    )
                  })}
                <div className={style.chargeUserMsg}>审核通过后自动进入下一审核流程</div>
              </div>
            </div>
            <div className={style.msgList}>
              <div className={style.msgListTitle}>审核人员与申请人对话列表</div>
              <Divider style={{ margin: 0 }} />
              {this.state.applyDetail.steps.length > 0 &&
                this.state.applyDetail.steps[this.state.applyDetail.current - 1].messages.map(
                  (v, k) => {
                    return (
                      <div key={k} className={style.item}>
                        {v.send_user.id === this.state.applyDetail.apply_uid ? (
                          <div className={style.author}>
                            <div className={style.msgSendTime}>{v.ctime.substr(0, 10)}</div>
                            <div className={style.msgSendUserNick}>{v.send_user.nick}</div>
                          </div>
                        ) : (
                            <div className={style.author}>
                              <div className={style.msgSendUserNick}>{v.send_user.nick}</div>
                              <div className={style.msgSendTime}>{v.ctime.substr(0, 10)}</div>
                            </div>
                          )}
                        <div
                          className={csn(style.msg, {
                            [style.selfMsg]: v.send_user.id === this.state.applyDetail.apply_uid,
                          })}>
                          <div className={style.message}>{v.msg}</div>
                        </div>
                      </div>
                    )
                  },
                )}
            </div>
            {this.getFooterOperationDom()}
          </div>
        </Spin>
      </div>
    )
  }
}
export default Form.create()(CreateProjectInfo)
