import React, { Component } from 'react';
import {
  Table, Button, Breadcrumb, Input, Icon, Menu, Dropdown, Popconfirm, message,
} from 'antd';
import { Link, Redirect, } from "react-router-dom";
import operationApi from "../../../services/operation";
import gSass from '../../../utils/sass';
const style = gSass.admin.operation.list;
const Search = Input.Search;
export default class OperationList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      list: [],
      filters: [],
      pagination: {
        current: 1,
        pageSize: 8,
        showTotal: total => `共 ${total} 条记录`,
      },
      isLoadingTableData: true,
      redirect: null,
      columns: [
        {
          title: '操作ID',
          dataIndex: 'id',
        },
        {
          title: '操作名',
          dataIndex: 'name',
        },
        {
          title: '方法',
          dataIndex: 'action',
        },
        {
          title: '描述',
          dataIndex: 'description',
        },
        {
          title: '创建用户ID',
          dataIndex: "cuid",
        },
        {
          title: '操作',
          dataIndex: 'operation',
          render: (text, record) => (
            <div>
              <Button type="primary" className={style.btn} onClick={_ => this.editOperation(record.id)}>编辑</Button>
              <Button type="danger" className={style.btn}>
                <Popconfirm title="确定删除此操作吗?" onConfirm={_ => this.deleteOperation(record.id)}>
                  <div>删除</div>
                </Popconfirm>
              </Button>
            </div>
          ),
        }
      ],
    };
  }
  editOperation = id => {
    this.setState({
      redirect: '/admin/operation/edit?id=' + id,
    })
  }
  deleteOperation = async id => {
    try {
      await operationApi.deleteOperation(id);
      let list = this.state.list;
      list = list.filter(v => v.id !== id)
      console.log(list)
      this.setState({
        list,
      })
      message.success('删除成功', 1)
    } catch (err) {
      message.error('删除失败,错误原因: ' + err.msg, 3)
    }
  }
  componentDidMount() {
    this.init();
  }
  init = async _ => {
    try {
      await this.getOperationList();
    } catch (err) {
      console.log(err.msg);
    }
  }
  getOperationList = async _ => {
    this.setState({
      isLoadingTableData: true,
    })
    let json = await operationApi.getOperationList(this.state.pagination.current, this.state.pagination.pageSize);
    let pagination = this.state.pagination;
    pagination.total = json.count;
    this.setState({
      list: json.data.list,
      pagination,
      isLoadingTableData: false,
    })
  }
  onTableChange = (pagination, filters, sorter) => {//筛选(排序,页码)
    const pager = this.state.pagination;
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    }, _ => {
      try {
        this.getOperationList();
      } catch (e) {
        console.log(e)
      }
    });

  }
  render() {
    if (this.state.redirect !== null) {
      return <Redirect to={this.state.redirect} />
    }
    return (
      <div className={style.userList}>
        <Breadcrumb className={style.title}>
          <Breadcrumb.Item><Link to="/admin">首页</Link></Breadcrumb.Item>
          <Breadcrumb.Item><Link to="/admin/operation/list">权限管理</Link></Breadcrumb.Item>
          <Breadcrumb.Item>操作列表</Breadcrumb.Item>
        </Breadcrumb>
        <div className={style.filters}>
          <div className={style.item}>
            <Button type="primary" icon="plus"><Link to="/admin/operation/add" className={style.itemAdd}>新增</Link></Button>
            <Dropdown overlay={<Menu>
              <Menu.Item>
                <div>删除</div>
              </Menu.Item>
            </Menu>} className={style.drown} placement="bottomLeft">
              <Button>更多操作 <Icon type="down" /></Button>
            </Dropdown>
          </div>
          <Search
            placeholder="请输入姓名 | 手机号 | 身份证号码..."
            enterButton="搜索"
            onSearch={value => console.log(value)}
            className={style.search}
          />
        </div>
        <Table className={style.table}
          columns={this.state.columns} dataSource={this.state.list}
          loading={this.state.isLoadingTableData}
          rowSelection={{
            onChange: (selectedRowKeys, selectedRows) => {
              console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
          }} onChange={this.onTableChange}
          rowKey="id" pagination={this.state.pagination} />
      </div>
    );
  }
}

