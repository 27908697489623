import React from "react";
import { Route, Link, } from "react-router-dom";
import { Form, Menu, } from 'antd';
import qs from 'qs';
import { getUploadImgUrl } from '../../../services/api';
import BaseSettings from "./baseSettings";
import Security from "./Security";
import RealNameAuthentication from "./RealNameAuthentication";
import CampusInformation from "./CampusInformation";
import gSass from '../../../utils/sass';

const style = gSass.admin.settings.base;

class Detail extends React.Component {
  constructor(props) {
    super(props);
    console.log(props)
    let { location: { search } } = props;
    const { id } = qs.parse(search, { ignoreQueryPrefix: true, })
    let menuKey = 1;
    switch (props.location.pathname) {
      case "/admin/user/detail":
      case "/admin/user/detail/base":
        menuKey = 1;
        break;
      case "/admin/user/detail/idRealName":
        menuKey = 2;
        break;
      case "/admin/user/detail/schoolInfo":
        menuKey = 3;
        break;
      case "/admin/user/detail/security":
        menuKey = 4;
        break;
      default:
        menuKey = 1;
        break;
    }
    this.state = {
      id,
      previewVisible: false,
      previewImage: '',
      fileList: [],
      uploadUrl: getUploadImgUrl,
      menuKey,
    }
  }
  componentDidMount() {

  }
  menuOnSelect = evt => {
    this.setState({
      menuKey: parseInt(evt.key),
    })
  }
  render() {
    return (
      <div className={style.base}>
        <div className={style.baseBox}>
          <div className={style.left}>
            <Menu mode="inline" defaultSelectedKeys={[this.state.menuKey + ""]}
              onSelect={this.menuOnSelect}>
              <Menu.Item key="1">
                <Link to={"/admin/user/detail/base?id=" + this.state.id}><span>基本信息</span></Link>
              </Menu.Item>
              <Menu.Item key="2">
                <Link to={"/admin/user/detail/idRealName?id=" + this.state.id}><span>实名认证</span></Link>
              </Menu.Item>
              <Menu.Item key="3">
                <Link to={"/admin/user/detail/schoolInfo?id=" + this.state.id}><span>校园信息</span></Link>
              </Menu.Item>
              <Menu.Item key="4">
                <Link to={"/admin/user/detail/security?id=" + this.state.id}><span>安全设置</span></Link>
              </Menu.Item>
            </Menu>
          </div>
          <div className={style.right}>
            <Route exact path={"/admin/user/detail"} component={BaseSettings} />
            <Route exact path={"/admin/user/detail/base"} component={BaseSettings} />
            <Route exact path={"/admin/user/detail/idRealName"} component={RealNameAuthentication} />
            <Route exact path={"/admin/user/detail/schoolInfo"} component={CampusInformation} />
            <Route exact path={"/admin/user/detail/security"} component={Security} />
          </div>
        </div>
      </div>
    );
  }
}

export default Form.create()(Detail);

