import React, { Component } from "react";
import { Moment } from "moment";
import { Tooltip } from "antd";
import gSass from "../../../utils/sass";
import moment from "moment";
import csn from "classnames";
const style = gSass.admin.conferenceRoomRent.RoomRentDetail;
interface Props {
  roomNo: string;
  chooseDateSection: Moment[];
  userRentList: UserRentInfo[];
}
interface State {
  rentList: RentInfo[];
}

interface UserRentInfo {
  date: Moment;
  userList: { rentUserInfo: RentUserInfo }[];
}

interface RentUserInfo {
  idCardName: string;
  idCardNo: string;
  companyName: string;
  rentDateSection: string[];
}
interface timeUseItem {
  color?: string;
  rentUserInfo?: RentUserInfo;
}
interface RentInfo {
  date: Moment;
  timeUseList: timeUseItem[];
}
interface DateMapRentListIdx {
  [date: string]: number;
}

export default class RoomRentDetail extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = this.getInitState();
  }
  getInitState = () => {
    return {
      rentList: []
    };
  };
  componentDidMount() {
    this.buildRentList(this.props);
  }
  getTimeSectionIdxByMoment = (momentTime: Moment) => {
    let hour = parseInt(momentTime.format("HH"));
    return 2 * hour;
  };
  buildRentList = (props: Props) => {
    console.log(props);
    let rentList: RentInfo[] = [];
    let [chooseBeginDate, chooseEndDate] = props.chooseDateSection;
    let chooseDayCount = chooseEndDate.diff(chooseBeginDate, "day") + 1;
    for (let i = 0; i < chooseDayCount; i++) {
      rentList.push({
        date: moment(chooseBeginDate).add(i, "days"),
        timeUseList: [
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {},
          {}
        ]
      });
    }
    const dateMapRentListIdx: DateMapRentListIdx = {};
    rentList.forEach((v, k) => {
      dateMapRentListIdx[v.date.format("MM-DD")] = k;
    });
    const userRentList = props.userRentList;
    for (let v of userRentList) {
      let rentDate = moment(v.date).format("MM-DD");
      if (!(rentDate in dateMapRentListIdx)) {
        continue;
      }
      let rentInfo = rentList[dateMapRentListIdx[rentDate]];
      if (!rentInfo) {
        continue;
      }
      for (let useInfo of v.userList) {
        let rentBeginTime = moment(useInfo.rentUserInfo.rentDateSection[0]),
          rentEndTime = moment(useInfo.rentUserInfo.rentDateSection[1]);
        let rentBeginTimeSectionIdx = this.getTimeSectionIdxByMoment(
            rentBeginTime
          ),
          rentEndTimeSectionIdx = this.getTimeSectionIdxByMoment(rentEndTime);
        if (rentEndTime.format("mm") !== "00") {
          rentEndTimeSectionIdx += 2;
        }
        let sectionColor = "antiquewhite";
        if (rentInfo.timeUseList[rentBeginTimeSectionIdx].rentUserInfo) {
          sectionColor =
            rentInfo.timeUseList[rentBeginTimeSectionIdx].color ===
            "antiquewhite"
              ? "darkorange"
              : "antiquewhite";
        }
        if (rentInfo.timeUseList[rentEndTimeSectionIdx - 2].rentUserInfo) {
          sectionColor =
            rentInfo.timeUseList[rentEndTimeSectionIdx - 2].color ===
            "antiquewhite"
              ? "darkorange"
              : "antiquewhite";
        }
        for (let i = rentBeginTimeSectionIdx; i < rentEndTimeSectionIdx; i++) {
          if (
            rentBeginTimeSectionIdx === i &&
            rentInfo.timeUseList[i].rentUserInfo
          ) {
            rentInfo.timeUseList[i + 1].rentUserInfo = {
              companyName: useInfo.rentUserInfo.companyName,
              idCardName: useInfo.rentUserInfo.idCardName,
              idCardNo: useInfo.rentUserInfo.idCardNo,
              rentDateSection: useInfo.rentUserInfo.rentDateSection
            };
            rentInfo.timeUseList[i + 1].color = sectionColor;
            i++;
            continue;
          }
          if (
            rentEndTimeSectionIdx - 2 === i &&
            rentInfo.timeUseList[i].rentUserInfo
          ) {
            rentInfo.timeUseList[i].rentUserInfo = {
              companyName: useInfo.rentUserInfo.companyName,
              idCardName: useInfo.rentUserInfo.idCardName,
              idCardNo: useInfo.rentUserInfo.idCardNo,
              rentDateSection: useInfo.rentUserInfo.rentDateSection
            };
            rentInfo.timeUseList[i + 1].color = sectionColor;
            i++;
            continue;
          }
          rentInfo.timeUseList[i].color = sectionColor;
          rentInfo.timeUseList[i].rentUserInfo = {
            companyName: useInfo.rentUserInfo.companyName,
            idCardName: useInfo.rentUserInfo.idCardName,
            idCardNo: useInfo.rentUserInfo.idCardNo,
            rentDateSection: useInfo.rentUserInfo.rentDateSection
          };
        }
      }
    }
    this.setState({
      rentList
    });
  };
  componentWillReceiveProps(nextProps: Props) {
    this.buildRentList(nextProps);
  }
  render() {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div className={style.rentList}>
          <div className={style.timeSectionList}>
            {Array.from(new Array(24)).map((v, k) => {
              return (
                <div className={style.item} key={k}>
                  {moment("2019-01-01 00:00")
                    .add(k * 60, "m")
                    .format("HH:mm")}
                </div>
              );
            })}
          </div>
          {this.state.rentList.map((dateRentItem, dateRentIdx) => {
            return (
              <div className={style.dateRentItem} key={dateRentIdx}>
                <div className={style.date}>
                  {dateRentItem.date.format("MM-DD")}
                </div>
                {dateRentItem.timeUseList.map((timeUseInfo, k) => {
                  return (
                    <div
                      key={k}
                      className={csn({
                        [style.halfHourRentInfo]: true,
                        [style.upperPart]: !(k & 1),
                        [style.lowerPart]: k & 1
                      })}
                      style={{
                        backgroundColor:
                          timeUseInfo.color !== "" ? timeUseInfo.color : "none"
                      }}
                    >
                      {timeUseInfo.rentUserInfo ? (
                        <Tooltip
                          title={
                            <div>
                              租用信息:
                              <br />
                              公司名: {timeUseInfo.rentUserInfo.companyName}
                              <br />
                              用户姓名: {timeUseInfo.rentUserInfo.idCardName}
                              <br />
                              用户身份证号: {timeUseInfo.rentUserInfo.idCardNo}
                              <br />
                              租用时间:{" "}
                              {timeUseInfo.rentUserInfo.rentDateSection
                                ? moment(
                                    timeUseInfo.rentUserInfo.rentDateSection[0]
                                  ).format("MM-DD HH:mm")
                                : ""}{" "}
                              -{" "}
                              {timeUseInfo.rentUserInfo.rentDateSection
                                ? moment(
                                    timeUseInfo.rentUserInfo.rentDateSection[1]
                                  ).format("MM-DD HH:mm")
                                : ""}
                              <br />
                            </div>
                          }
                          placement="right"
                        >
                          <div className={style.tooltip}></div>
                        </Tooltip>
                      ) : null}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
