import React from "react";
import {
  List, Picker, TextareaItem, ActivityIndicator, NavBar, Icon, InputItem,
  Button, Switch, Flex, DatePicker, Toast, Modal, ImagePicker,
} from "antd-mobile";
import { Form, message, Upload } from 'antd';

import moment from "moment";
import { withRouter } from "react-router-dom";
import AliIcon from "../../../../utils/iconfont";
import gStyle from "../../../../utils/sass";
import projectApi from "../../../../services/project";
import companyApi from "../../../../services/company";
import { antdUploadImg, } from "../../../../services/api";
import { BASE_URL, } from "../../../../config/prod";
import { TYPE, TYPE_ZH, FINANCING_TYPE as FinancingType, FINANCING_TYPE_ZH as FinancingTypeZh, SUBORDINATE_TYPE as SubordinateType, SUBORDINATE_TYPE_ZH as SubordinateTypeZh } from "../../../../services/patent";
import userApi from "../../../../services/user";


import applyApi, { EDUCATION, EDUCATION_ZH, APPLY_STATUS } from "../../../../services/apply";
import { getRegion, } from "../../../../utils/utils";
import ApplyWithSendMsg from '../../../../components/index/user/apply/ApplyWithSendMsg';

const style = gStyle.index.user.apply.createProject;

const Item = List.Item;
let uploadList = [];
const upload = {
  action: BASE_URL + '/antdUploadImg',
  onChange({ file, fileList }) {
    if (file.status !== 'uploading') {
      if (file.response.code === 0) {
        console.log(file.response.data)
        uploadList.push(file.response.data)
      } else {
        console.log(file.response.msg);
      }
    }
  },
  defaultFileList: uploadList,

}

class Project extends ApplyWithSendMsg {
  patentTypeList = [Object.keys(TYPE).map(v => ({ value: TYPE[v], label: TYPE_ZH[TYPE[v]] }))]
  // 融资形式
  financingTypeList = [Object.values(FinancingType).map(v => ({
    value: v,
    label: FinancingTypeZh[v]
  }))]
  // 所属等级
  subordinateTypeList = [Object.values(SubordinateType).map(v => ({
    value: v,
    label: SubordinateTypeZh[v],
  }))]

  /**
   * 显示申请详情
   */
  isShowDetail = false;
  constructor(props) {
    super(props);
    this.isShowDetail = props.isShowDetail || false;
    /**
     * 申请的id
     */
    this.applyId = props.applyId;
    this.state = {
      hasApplyCompanyOrProject: false,
      canSubmit: true,
      isAnimating: true,
      residencePermitPic: [],
      overseasBg: [],
      graduationList: [],
      patentList: [],
      financingList: [],
      industryList: [],

      grade: [],
      education: [],
      awardRecordList: [],
      picList: [
        []
      ],
      overseasCard: [],
      seasEducation: [],

      /**
       * 显示详情时 从服务器获取的信息
       */
      applyDetail: {
        id: 1,
        steps: [],
      },
      isAllowEdit: false,  //是否允许编辑
      activePatent: [],
      activeFinancingList: [],
      activePrizeList: [],
      activeOverseasMemberList: [],
    }
  }
  componentDidMount() {
    this.init();
  }

  init = async _ => {
    try {
      let region = await getRegion();
      let { data: { list: oriIndustryList } } = await companyApi.getIndustryList({ page: -1, limit: -1 })
      let industryList = [oriIndustryList.map(v => ({
        value: v.id, label: v.name,
      }))]
      this.setState({
        industryList: industryList
      })


      let education = [
        Object.values(EDUCATION).map((v, k) => ({
          value: v,
          label: EDUCATION_ZH[v],
        })),
      ];
      // 获奖列表
      let dateGraduation = new Date().getFullYear(), graduationList = [];
      for (let i = 0; i <= (dateGraduation - 1990); i++) {
        graduationList.push({ value: 1990 + i, label: 1990 + i })
      }
      graduationList = [graduationList];
      let grade = [],
        date = new Date().getFullYear(),
        gradeList = [];
      for (let i = 0; i <= date - 1990; i++) {
        gradeList.push({ value: 1990 + i, label: 1990 + i });
      }
      grade = [gradeList];
      await new Promise(s => {
        this.setState({
          industryList,
          education,
          grade,
          graduationList,

        }, s)
      })
      const { setFieldsValue, } = this.props.form;
      let educationList = [Object.values(EDUCATION).map(v => ({
        value: v,
        label: EDUCATION_ZH[v]
      }))]
      let {
        data: { detail },
      } = await userApi.getMySchoolInfoDetail();
      this.setState({
        detail,
        overseasCard: [{ url: BASE_URL + detail.overseasCard }],
      })
      setFieldsValue({
        graduationList: [detail.graduationList],
      });
      try {
        let { data: { detail, } } = await userApi.getMyIdRealNameDetail();

        setFieldsValue({
          personalName: detail.name,
          idCardNo: detail.idCardNo,
        })
      } catch (e) {
      }
      try {
        let { data: detail } = await userApi.getUserInfo();
        setFieldsValue({
          phone: detail.phone,
          email: detail.email,
        });
      } catch (e) {
        console.log(e);
      }
      if (this.isShowDetail) {
        const { setFieldsValue, } = this.props.form;
        try {
          let { data: { detail: applyDetail, } } = await applyApi.getApplyDetail({ id: this.applyId })
          let isAllowEdit = this.state.isAllowEdit;
          if (applyDetail.status === APPLY_STATUS['process']) {
            isAllowEdit = true;
          } else {
            isAllowEdit = false;
          }
          this.setState({
            isAllowEdit,
          })
          if (applyDetail.project_plan.length > 0) {
            for (let v of applyDetail.project_plan) {
              uploadList.push({
                uid: v.picId,
                name: v.name,
                url: BASE_URL + v.url
              })
            }
          } else {
            uploadList = []
          }
          let patentList = [], financingList = [], awardRecordList = [], overseasBg = []
          // 专利
          let activePatent = [];
          for (let i = 0; i < applyDetail.patentList.length; i++) {
            activePatent.push({
              id: applyDetail.patentList[i].id
            })
            patentList.push(i)
            await new Promise(s => {
              this.setState({
                patentList,
              }, s)
            })
            setTimeout(_ => {
              setFieldsValue({
                [`patentName${i}`]: applyDetail.patentList[i].name,
                [`patentTime${i}`]: new Date(applyDetail.patentList[i].time),
                [`patentType${i}`]: [applyDetail.patentList[i].type],
              })
            }, 50)
          }

          //  融资
          let activeFinancingList = [];
          for (let i = 0; i < applyDetail.financingRecordList.length; i++) {
            financingList.push(i)
            activeFinancingList.push({
              id: applyDetail.financingRecordList[i].id
            })
            await new Promise(s => {
              this.setState({
                financingList,
              }, s)
            })
            setTimeout(_ => {
              setFieldsValue({
                [`financingMoney${i}`]: applyDetail.financingRecordList[i].money / 100,
                [`financingTime${i}`]: new Date(applyDetail.financingRecordList[i].time),
                [`financingSource${i}`]: applyDetail.financingRecordList[i].source,
                [`financingMode${i}`]: [parseInt(applyDetail.financingRecordList[i].mode)],
              })
            }, 50)
          }

          // 获奖
          let activePrizeList = []
          for (let i = 0; i < applyDetail.prize_id_list.length; i++) {
            awardRecordList.push(i)
            activePrizeList.push({
              id: applyDetail.prize_id_list[i].id
            })
            await new Promise(s => {
              this.setState({
                awardRecordList,
              }, s)
            })
            setTimeout(_ => {
              setFieldsValue({
                [`awardName${i}`]: applyDetail.prize_id_list[i].name,
                [`subordinateLevel${i}`]: [parseInt(applyDetail.prize_id_list[i].belong_level)],
                [`awardLevel${i}`]: applyDetail.prize_id_list[i].prize_level,
                [`awardObtainTime${i}`]: new Date(applyDetail.prize_id_list[i].win_time),
                // [`patentTime${i}`]: new Date(applyDetail.patentList[i].time),
              })
            }, 50)
          }

          // 海外留学
          let activeOverseasMemberList = []
          for (let i = 0; i < applyDetail.overseas_id_list.length; i++) {
            overseasBg.push(i)
            activeOverseasMemberList.push({
              id: applyDetail.overseas_id_list[i].id
            })
            await new Promise(s => {
              let picList = this.state.picList;
              picList[i] = [{
                url: BASE_URL + applyDetail.overseas_id_list[i].degree_pic_url.url,
                picId: applyDetail.overseas_id_list[i].degree_pic_url.id,
                uid: applyDetail.overseas_id_list[i].degree_pic_url.id
              }]
              this.setState({
                overseasBg,
              }, s)
            })
            setTimeout(_ => {
              setFieldsValue({
                [`overseasName${i}`]: applyDetail.overseas_id_list[i].name,
                [`overseasType${i}`]: applyDetail.overseas_id_list[i].college_id,
                [`overseasMajor${i}`]: applyDetail.overseas_id_list[i].speciality,
                [`overseasEducation${i}`]: [applyDetail.overseas_id_list[i].education],
                [`graduationTime${i}`]: new Date(applyDetail.overseas_id_list[i].graduation_time),
              })
            }, 50)
          }

          setFieldsValue({
            pname: applyDetail.info.name,
            teamMemberNumber: applyDetail.info.totalNumber,
            teamMemberNumberHai: applyDetail.info.household,
            industryId: [applyDetail.info.industryId],
            description: applyDetail.info.description,
          })
          this.setState({
            loading: false,
            applyDetail,
            industryList,
            educationList,
            region,
            activePatent,
            activeFinancingList,
            activePrizeList,
            activeOverseasMemberList,
          })
        } catch (e) {
          console.log(e)
          message.error('获取申请详情失败,错误信息: ' + e.msg)
        }
        return
      }
      const { data: { hasApplyCompanyOrProject } } = await userApi.checkHasApplyCompanyOrProject()
      this.setState({
        hasApplyCompanyOrProject,
      })
      this.setState({
        isAnimating: false,
        industryList,
      })
    } catch (err) {
      console.log(err)
      this.setState({
        isAnimating: false,
      })
    }
    const { setFieldsValue } = this.props.form;
    // 获取基本信息
    try {
      let education = [
        Object.values(EDUCATION).map((v, k) => ({
          value: v,
          label: EDUCATION_ZH[v],
        })),
      ];
      // 获奖列表
      let dateGraduation = new Date().getFullYear(), graduationList = [];
      for (let i = 0; i <= (dateGraduation - 1990); i++) {
        graduationList.push({ value: 1990 + i, label: 1990 + i })
      }
      graduationList = [graduationList];
      let grade = [],
        date = new Date().getFullYear(),
        gradeList = [];
      for (let i = 0; i <= date - 1990; i++) {
        gradeList.push({ value: 1990 + i, label: 1990 + i });
      }
      grade = [gradeList];
      await new Promise(s => {
        this.setState({

          education,
          grade,
          graduationList,
          seasEducation: education,
        }, s)
      })
      let {
        data: { detail },
      } = await userApi.getMySchoolInfoDetail();
      console.log(detail)
      this.setState({
        detail,
        overseasCard: [{ url: BASE_URL + detail.overseasCard }],
      })
      setFieldsValue({
        graduationList: [detail.graduationList],
      });
    } catch (err) {
      console.log(err);
    }
    try {
      let { data: { detail, } } = await userApi.getMyIdRealNameDetail();
      setFieldsValue({
        personalName: detail.name,
        idCardNo: detail.idCardNo,
      })
    } catch (e) {
    }
    try {
      let { data: detail } = await userApi.getUserInfo();
      console.log(detail)
      setFieldsValue({
        phone: detail.phone,
        email: detail.email,
      });
    } catch (e) {
      console.log(e);
    }
  }
  onSubmit = async _ => {
    this.props.form.validateFields(async (err, fieldsValue) => {
      if (err) {
        let firstErrKey = Object.keys(err)[0]
        if (firstErrKey !== 'msg') {
          return Toast.fail(err[firstErrKey].errors[0].message, 2)
        }
      }
      let fields = this.props.form.getFieldsValue();
      let overseasBg = [], patentList = [], financingList = [], awardRecordList = [];
      let picList = this.state.picList;
      // let pic = '';
      let {
        activePatent,
        activeFinancingList,
        activePrizeList,
        activeOverseasMemberList,
      } = this.state;
      if (this.state.isAllowEdit) {
        for (let v of picList) {
          if (v[0].response) {
            let arr = v[0];
            v[0] = {};
            v[0].uid = arr.response.data.picId;
            v[0].url = BASE_URL + arr.response.data.url;
          }
        }
      }
      for (let v of this.state.overseasBg) {
        if (fields['overseasName' + v]) {
          // if (picList[v].length > 0) {
          //   pic = picList[v][0].url
          // } else {
          //   pic = ''
          // }
          overseasBg.push({
            id: activeOverseasMemberList[v] ? activeOverseasMemberList[v].id : 0,
            name: fields['overseasName' + v],
            school_name: fields['overseasType' + v],
            url: picList[v],
            time: moment(fields['graduationTime' + v]).format("YYYY-MM-DD HH:mm:ss"),
            major: fields['overseasMajor' + v],
            education: parseInt(fields['overseasEducation' + v]),
          })
        }
      }
      for (let v of this.state.patentList) {
        patentList.push({
          id: activePatent[v] ? activePatent[v].id : 0,
          name: fields['patentName' + v],
          time: moment(fields['patentTime' + v]).format("YYYY-MM-DD HH:mm:ss"),
          type: parseInt(fields['patentType' + v]),
        })
      }
      for (let v1 of this.state.financingList) {
        financingList.push({
          id: activeFinancingList[v1] ? activeFinancingList[v1].id : 0,
          money: parseInt(fields['financingMoney' + v1]) * 100,
          time: moment(fields['financingTime' + v1]).format("YYYY-MM-DD HH:mm:ss"),
          source: fields['financingSource' + v1],
          mode: parseInt(fields['financingMode' + v1]),
        })
      }
      // 提交获奖记录列表
      for (let v of this.state.awardRecordList) {
        awardRecordList.push({
          id: activePrizeList[v] ? activePrizeList[v].id : 0,
          name: fields[`awardName` + v],
          subordinate: parseInt(fields[`subordinateLevel` + v]),
          awardLevel: fields[`awardLevel` + v],
          obtainTime: moment(fields['awardObtainTime' + v]).format("YYYY-MM-DD HH:mm:ss"),
        })
      }
      this.setState({
        canSubmit: false,
      })
      if (!this.state.isAllowEdit) {
        await projectApi.createProject({
          file: uploadList,
          name: fields.pname,
          teamMemberNumber: parseInt(fields.teamMemberNumber),
          teamMemberNumberHai: parseInt(fields.teamMemberNumberHai),
          industryId: fields.industryId[0],
          description: fields.description,
          awardRecordList,
          overseasBg,
          patentList,
          financingList,
        }).then(_ => {
          Toast.success("提交成功, 请等待审核 ", 1, _ => {
            this.props.history.goBack()
          });
        }).catch(err => {
          Toast.fail("提交失败,失败原因: " + err.msg, 3);
        })
      } else {
        for (let i = 0; i < uploadList.length; i++) {
          if (uploadList[i].picId) {
            console.log(uploadList[i])
            uploadList[i].uid = uploadList[i].picId.toString();
            delete uploadList[i].picId
          }
        }
        await projectApi.createProjectEdit({
          applyProjectId: this.applyId,
          file: uploadList,
          name: fields.pname,
          teamMemberNumber: parseInt(fields.teamMemberNumber),
          teamMemberNumberHai: parseInt(fields.teamMemberNumberHai),
          industryId: fields.industryId[0],
          description: fields.description,
          awardRecordList,
          overseasBg,
          patentList,
          financingList,
        }).then(_ => {
          this.setState({
            isAllowEdit: false,
          })
          Toast.success("提交成功, 请等待审核 ", 1, _ => {
            this.props.history.goBack()
          });
        }).catch(err => {
          Toast.fail("提交失败,失败原因: " + err.msg, 3);
        })
      }
      this.setState({
        canSubmit: true,
      })
    });
  }
  onReset = _ => {
    this.props.form.resetFields();
  }
  // 添加海外留学生信息
  addOverseasBg = _ => {
    let fieldNames = [];
    for (let i = 0; i < this.state.overseasBg.length; i++) {
      fieldNames.push('overseasName' + i, 'overseasType' + i, "overseasMajor" + i, "overseasEducation" + i + "graduationTime" + i)
    }

    this.props.form.validateFields(fieldNames, (err, fieldsValue) => {
      console.log(err)
      if (err) {
        let firstErrKey = Object.keys(err)[0]
        return Toast.fail(err[firstErrKey].errors[0].message, 2)
      }
      let overseasBg = this.state.overseasBg;
      let picList = this.state.picList;
      // console.log(overseasBg)
      // console.log(picList)
      // return false
      overseasBg.push(overseasBg[overseasBg.length - 1] + 1)
      picList.push([])
      this.setState({
        overseasBg,
        picList,
      })
      console.log(overseasBg)
    });
  }
  // 添加专利信息
  addPatent = _ => {
    let fieldNames = [];
    for (let i = 0; i < this.state.patentList.length; i++) {
      fieldNames.push('patentName' + i, 'patentTime' + i, 'patentType' + i)
    }
    this.props.form.validateFields(fieldNames, (err, fieldsValue) => {
      console.log(err)
      if (err) {
        let firstErrKey = Object.keys(err)[0]
        return Toast.fail(err[firstErrKey].errors[0].message, 2)
      }
      let patentList = this.state.patentList;
      patentList.push(patentList[patentList.length - 1] + 1)
      this.setState({
        patentList,
      })
      console.log(patentList)
    });
  }
  // 添加融资
  addFinancing = _ => {
    let fieldNames = [];
    for (let i = 0; i < this.state.financingList.length; i++) {
      fieldNames.push('financingMoney' + i, 'financingTime' + i, 'financingSource' + i, 'financingMode' + i)
    }
    this.props.form.validateFields(fieldNames, (err, fieldsValue) => {
      if (err) {
        let firstErrKey = Object.keys(err)[0]
        return Toast.fail(err[firstErrKey].errors[0].message, 2)
      }
      let financingList = this.state.financingList;
      financingList.push(financingList[financingList.length - 1] + 1)
      this.setState({
        financingList,
      })
    });
  }
  // 添加获奖信息
  addawardRecord = _ => {
    let fieldNames = []
    for (let i = 0; i < this.state.awardRecordList.length; i++) {
      fieldNames.push('awardName' + i, 'subordinateLevel' + i, 'awardLevel' + i, 'awardObtainTime' + i)
    }
    this.props.form.validateFields(fieldNames, (err, fieldsValue) => {
      if (err) {
        let firstErrKey = Object.keys(err)[0]
        return Toast.fail(err[firstErrKey].errors[0].message, 2)
      }
      let awardRecordList = this.state.awardRecordList
      awardRecordList.push(awardRecordList[awardRecordList.length - 1] + 1)
      console.log(awardRecordList)
      this.setState({
        awardRecordList,
      })
    })
  }
  getApplyDetailDom = _ => {
    const { getFieldDecorator, getFieldError, getFieldProps } = this.props.form;
    return <form className={style.form}>
      <List>
        {/* 修改开始 */}
        {getFieldDecorator('personalName',
          {
            rules: [{ required: true, message: '请输入负责人姓名' },],
          })(<InputItem clear disabled className={style.input} placeholder="请输入" error={!!getFieldError('personalName')}
            extra={<Icon size="md" color="#ccc" type="right"></Icon>} >负责人姓名</InputItem>
          )}
        {getFieldDecorator('idCardNo', {
          rules: [{ required: true, message: '请输入身份证号码' }],
        })(
          <InputItem clear disabled className={style.input} placeholder="请输入"
            error={!!getFieldError("idCard")}
            extra={<Icon size='md' color="#ccc" type="right"></Icon>}
            onBlur={this.idCardNoValidation}>身份证号</InputItem>)}
        {getFieldDecorator('phone',
          {
            rules: [{ required: true, message: '请输入电话' },],
          })(<InputItem type="digit" clear disabled className={style.input} placeholder="请输入" error={!!getFieldError('phone')}
            extra={<Icon size="md" color="#ccc" type="right"></Icon>} >联系电话</InputItem>
          )}
        {getFieldDecorator('email',
          {
            rules: [{ required: true, message: '请输入邮箱' },],
          })(<InputItem clear disabled className={style.input} placeholder="请输入" error={!!getFieldError('email')}
            extra={<Icon size="md" color="#ccc" type="right"></Icon>} >邮箱</InputItem>
          )}
        {/* 修改结束 */}
        {getFieldDecorator('pname',
          {
            rules: [{ required: true, message: '请输入项目名称' },],
          })(<InputItem clear className={style.input} placeholder="请输入" error={!!getFieldError('pname')}
            extra={<Icon size="md" color="#ccc" type="right"></Icon>} >项目名称</InputItem>
          )}
        {getFieldDecorator('teamMemberNumber',
          {
            rules: [
              { required: true, message: '请输入团队总人数' },
            ],
          })(
            <InputItem clear type="digit" className={style.input} placeholder="请输入"
              error={!!getFieldError('teamMemberNumber')} extra={<Icon size="md" color="#ccc" type="right"></Icon>}
            >团队总人数</InputItem>
          )}
        {getFieldDecorator('teamMemberNumberHai',
          {
            rules: [
              { required: true, message: '请输入团队总人数' },
            ],
          })(
            <InputItem clear type="digit" className={style.input} placeholder="请输入"
              error={!!getFieldError('teamMemberNumberHai')} extra={<Icon size="md" color="#ccc" type="right"></Icon>}
            >上海户籍人数</InputItem>
          )}

        {getFieldDecorator('industryId',
          { rules: [{ required: true, message: '请选择所属行业', },], })(
            <Picker data={this.state.industryList} title="选择所属行业" cascade={false}>
              <List.Item arrow="horizontal">所属行业</List.Item>
            </Picker>
          )}
        {/* 修改开始 */}
        <Flex className={style.item}>
          <div className={style.title}>是否有海外留学背景成员</div>
          {getFieldDecorator('hasOverseasBg',
            {
              onChange: checked => {
                let overseasBg = [];
                if (checked) {
                  overseasBg.push(0)
                }
                this.setState({
                  overseasBg,
                })
              },
            })(
              <Switch checked={this.state.overseasBg.length > 0} className={style.detail} />
            )}
        </Flex>
        {/*是否有海外留学背景成员 */}
        <div className={this.state.overseasBg.length > 0 ? "" : style.hidden}>
          {this.state.overseasBg.map((v, k) => {
            return (<div key={k}>
              <div className={style.theme}>有海外留学背景成员 {k + 1}
                <AliIcon className={style.deleteIcon} type="icon-icon_close" onClick={_ => {
                  let overseasBg = this.state.overseasBg;
                  overseasBg.splice(k, 1)
                  this.setState({
                    overseasBg,
                  })
                  console.log(overseasBg)
                }}></AliIcon>
              </div>

              <div className={style.item}>
                {getFieldDecorator('overseasName' + v,
                  {
                    rules: [{ required: true, message: `请输入海外留学背景成员${k + 1}的名字`, },],
                  })(
                    <InputItem clear className={style.input} placeholder="请输入"
                      error={!!getFieldError('overseasName' + v)} extra={<Icon size="md" color="#ccc" type="right" ></Icon>}
                    >成员名字</InputItem>
                  )}
              </div>
              <div className={style.item}>
                {getFieldDecorator('overseasType' + v,
                  {
                    rules: [{ required: true, message: `请输入海外留学背景成员${k + 1}的毕业院校`, },],
                  })(
                    <InputItem clear className={style.input} placeholder="请输入"
                      error={!!getFieldError('overseasType' + v)} extra={<Icon size="md" color="#ccc" type="right" ></Icon>}
                    >毕业学校</InputItem>
                  )}
              </div>
              <div className={style.item}>
                {getFieldDecorator('overseasMajor' + v,
                  {
                    rules: [{ required: true, message: `请输入海外留学背景成员${k + 1}的毕业专业`, },],
                  })(
                    <InputItem clear className={style.input} placeholder="请输入"
                      error={!!getFieldError('overseasMajor' + v)} extra={<Icon size="md" color="#ccc" type="right" ></Icon>}
                    >毕业专业</InputItem>
                  )}
              </div>

              <div className={style.item}>
                <Picker
                  {...getFieldProps('overseasEducation' + v, {
                    rules: [{ required: true, message: `请输入海外留学背景成员${k + 1}的学历` },],
                  })}
                  data={this.state.education} cascade={false} placeholder="选择学历" >
                  <List.Item arrow="horizontal">学历</List.Item>
                </Picker>
              </div>
              <div className={style.item}>
                <DatePicker
                  {...getFieldProps('graduationTime' + v, {
                    rules: [{ required: true, message: `请选择海外留学背景成员 ${k + 1}毕业时间` },],
                  })} mode="date" extra="请选择" >
                  <List.Item arrow="horizontal">毕业时间</List.Item>
                </DatePicker>
              </div>
              <div className={style.diploma}>请上传毕业证学位证</div>
              {getFieldDecorator('picList' + v, {})(
                <ImagePicker
                  className={style.diplomaPic}
                  files={this.state.picList[v]}
                  onChange={(files, type, index) => {
                    if (type === 'add') {
                      let formData = new FormData();
                      formData.append("name", 'file');
                      formData.append("file", files[0].file);
                      antdUploadImg(formData).then(json => {
                        const { data: { name, picId, url, } } = json;
                        let picList = this.state.picList
                        picList[v] = [{
                          name, picId, url: BASE_URL + url,
                        }]
                        this.setState({
                          picList,
                        });
                        console.log(picList)
                      }).catch(e => {
                        message.error('上传失败', 1)
                        console.log(e)
                      })
                    } else if (type === 'remove') {
                      let picList = this.state.picList
                      picList[v] = []
                      this.setState({
                        picList,
                      });
                    }
                  }}
                  selectable={this.state.picList[v].length < 1} multiple={this.state.multiple} style={{ flex: 1, }}
                />

              )}
            </div>)
          })}
          <div className={style.addPatent} onClick={this.addOverseasBg}>
            <AliIcon className={style.icon} type="icon-jia1"></AliIcon>
          </div>
        </div>

        {/* 修改结束 */}
        <Flex className={style.item}>
          <div className={style.title}>是否有专利</div>
          {getFieldDecorator('hasPatent',
            {
              onChange: checked => {
                let patentList = [];
                if (checked) {
                  patentList.push(0)
                }
                this.setState({
                  patentList,
                })
              },
            })(
              <Switch checked={this.state.patentList.length > 0} className={style.detail} />
            )}
        </Flex>
        {/*专利 */}
        <div className={this.state.patentList.length > 0 ? "" : style.hidden}>
          {this.state.patentList.map((v, k) => {
            return (<div key={k}>
              <div className={style.theme}>专利 {k + 1}
                <AliIcon className={style.deleteIcon} type="icon-icon_close" onClick={_ => {
                  let patentList = this.state.patentList;
                  patentList.splice(k, 1)
                  this.setState({
                    patentList,
                  })
                }}></AliIcon>
              </div>
              <div className={style.item}>
                {getFieldDecorator('patentName' + v,
                  {
                    rules: [{ required: true, message: `请输入专利${k + 1}的名称`, },],
                  })(
                    <InputItem clear className={style.input} placeholder="请输入"
                      error={!!getFieldError('patentName' + v)} extra={<Icon size="md" color="#ccc" type="right" ></Icon>}
                    >专利名称</InputItem>
                  )}
              </div>
              <div className={style.item}>
                <DatePicker
                  {...getFieldProps('patentTime' + v, {
                    rules: [{ required: true, message: '请选择专利获得时间' },],
                  })} mode="date" extra="请选择"  >
                  <List.Item arrow="horizontal">获得时间</List.Item>
                </DatePicker>
              </div>
              <div className={style.item}>
                <Picker
                  {...getFieldProps('patentType' + v, {
                    rules: [{ required: true, message: `请选择第${k + 1}个专利类型` },],
                  })}
                  data={this.patentTypeList} cascade={false} placeholder="请选择" >
                  <List.Item arrow="horizontal">专利类型</List.Item>
                </Picker>
              </div>
            </div>)
          })}
          <div className={style.addPatent} onClick={this.addPatent}>
            <AliIcon className={style.icon} type="icon-jia1"></AliIcon>
          </div>
        </div>
        <Flex className={style.item}>
          <div className={style.title}>是否融资</div>
          {getFieldDecorator('isFinancing',
            {
              onChange: checked => {
                let financingList = [];
                if (checked) {
                  financingList.push(0)
                }
                this.setState({
                  financingList,
                })
              },
            })(
              <Switch checked={this.state.financingList.length > 0} className={style.detail} />
            )}
        </Flex>
        {/* 融资信息 */}
        <div className={this.state.financingList.length > 0 ? "" : style.hidden}>
          {this.state.financingList.map((v, k) => {
            return (<div key={k}>
              <div className={style.theme}>融资 {k + 1}
                <AliIcon className={style.deleteIcon} type="icon-icon_close" onClick={_ => {
                  let financingList = this.state.financingList;
                  financingList.splice(k, 1)
                  this.setState({
                    financingList,
                  })
                }}></AliIcon>
              </div>
              <div className={style.item}>
                {getFieldDecorator('financingMoney' + v,
                  {
                    rules: [{ required: true, message: `请输入融资${k + 1}金额`, },],
                  })(
                    <InputItem clear className={style.input} placeholder="请输入"
                      error={!!getFieldError('financingMoney' + v)} type="digit"
                      extra={<Icon size="md" color="#ccc" type="right" ></Icon>}
                    >融资金额(元)</InputItem>
                  )}
              </div>
              <div className={style.item}>
                <DatePicker
                  {...getFieldProps('financingTime' + v, {
                    rules: [{ required: true, message: `请选择融资${k + 1}时间` },],
                  })} mode="date" extra="请选择" >
                  <List.Item arrow="horizontal">融资时间</List.Item>
                </DatePicker>
              </div>
              <div className={style.item}>
                {getFieldDecorator('financingSource' + v,
                  {
                    rules: [{ required: true, message: `请输入融资${k + 1}来源`, },],
                  })(
                    <InputItem clear className={style.input} placeholder="请输入"
                      error={!!getFieldError('financingSource' + v)}
                      extra={<Icon size="md" color="#ccc" type="right" ></Icon>}>融资来源</InputItem>
                  )}
              </div>
              <div className={style.item}>
                <Picker
                  {...getFieldProps('financingMode' + v, {
                    rules: [{ required: true, message: `请选择融资${k + 1}形式` },],
                  })}
                  data={this.financingTypeList} cascade={false} placeholder="请选择" >
                  <List.Item arrow="horizontal">融资形式</List.Item>
                </Picker>
                {/* {getFieldDecorator('financingMode' + v,
                  {
                    rules: [{ required: true, message: `请输入融资${k + 1}形式`, },],
                  })(
                    <InputItem clear className={style.input} placeholder="请输入"
                      error={!!getFieldError('financingMode' + v)}
                      extra={<Icon size="md" color="#ccc" type="right" ></Icon>}>融资形式</InputItem>
                  )} */}
              </div>
            </div>)
          })}
          <div className={style.addPatent} onClick={this.addFinancing}>
            <AliIcon className={style.icon} type="icon-jia1"></AliIcon>
          </div>
        </div>
        {/* 获奖信息 */}
        <Flex className={style.item}>
          <div className={style.title}>是否获得奖项</div>
          {getFieldDecorator("hasAward", {
            onChange: checked => {
              let awardRecordList = [];
              if (checked) {
                awardRecordList.push(0)
              }
              this.setState({
                awardRecordList,
              })
            }
          })(
            <Switch checked={this.state.awardRecordList.length > 0} className={style.detail} />
          )}
        </Flex>
        <div className={this.state.awardRecordList.length > 0 ? "" : style.hidden}>
          {this.state.awardRecordList.map((v, k) => {
            return (
              <div key={k}>
                <div className={style.theme}>获奖记录{k + 1}
                  <AliIcon className={style.deleteIcon} type="icon-close-line" onClick={_ => {
                    let awardRecordList = this.state.awardRecordList;
                    awardRecordList.splice(k, 1)
                    this.setState({
                      awardRecordList,
                    })
                  }}></AliIcon></div>
                <div className={style.item}>
                  {
                    getFieldDecorator("awardName" + v, {
                      rules: [{ required: true, message: `请输入获奖记录${k + 1}获奖名称` }]
                    })(
                      <InputItem
                        clear
                        className={style.input}
                        placeholder="请输入"
                        error={!!getFieldError("awardName" + v)}
                        extra={<Icon size="md" color="#ccc" type="right"></Icon>}
                      >获奖名称</InputItem>
                    )
                  }
                </div>
                <div className={style.item}>
                  {/* {
                    getFieldDecorator("subordinateLevel" + v, {
                      rules: [{ required: true, message: `请输入获奖记录${k + 1}奖项的所属级别` }]
                    })(
                      <InputItem clear className={style.input} placeholder="请输入"
                        error={!!getFieldError("subordinateLevel" + v)}
                        extra={<Icon size="md" color="#ccc" type="right"></Icon>}
                      >所属级别</InputItem>
                    )
                  } */}
                  <Picker
                    {...getFieldProps('subordinateLevel' + v, {
                      rules: [{ required: true, message: `请选择获奖记录${k + 1}奖项的所属级别` },],
                    })}
                    data={this.subordinateTypeList} cascade={false} placeholder="请选择" >
                    <List.Item arrow="horizontal">所属级别</List.Item>
                  </Picker>
                </div>
                <div className={style.item}>
                  {getFieldDecorator("awardLevel" + v, {
                    rules: [{ required: true, message: `请输入获奖记录${k + 1}奖项的级别` }]
                  })(<InputItem className={style.input} clear placeholder="请输入"
                    error={!!getFieldError("awardLevel" + v)}
                    extra={<Icon type="right" color="#ccc" size="md"></Icon>}
                  >奖项级别</InputItem>)}
                </div>
                <div className={style.item}>
                  <DatePicker
                    {...getFieldProps("awardObtainTime" + v, {
                      rules: [{ required: true, message: `请选择获奖记录$(k+1)的获奖时间` }]
                    })}
                    mode="date"
                    extra="请选择"
                  >
                    <List.Item arrow="horizontal">获奖时间</List.Item>
                  </DatePicker>
                </div>
              </div>
            )
          })}
          <div className={style.addPatent} onClick={this.addawardRecord}>
            <AliIcon className={style.icon} type="icon-jia1"></AliIcon>
          </div>
        </div>
        <Item>
          <div>项目简介</div>
          <TextareaItem {...getFieldProps('description',
            {
              rules: [{ required: true, message: "项目简介必填" },],
            })} rows={4} count={300} placeholder="请输入项目简介(200字以内)" />
        </Item>
        <Item>
          <div className={style.tips}>提示:如果有负责人简历,专利证书,融资证明文件,获奖证书,
         项目计划书，商业计划书等,请上传对应的附件</div>
          <Upload {...upload}>
            <Button className={style.uploadBtn} >
              上传
          </Button>
          </Upload>
        </Item>

      </List>
      {
        this.state.isAllowEdit ? (
          <div className={style.operation}>
            <Button size="small" type="primary" className={style.btn} inline onClick={this.onSubmit}>修改提交</Button>
          </div>
        ) : ''
      }
      {this.isShowDetail ? this.getChatInfo() : null}
    </form>
  }
  render() {
    return (this.isShowDetail ? this.getApplyDetailDom() :
      <div className={style.project}>
        <Modal
          visible={this.state.hasApplyCompanyOrProject}
          transparent
          maskClosable={false}
          title="提示"
          footer={[{ text: '确定', onPress: () => this.props.history.push('/') }]}
        >
          <div>你已经申请过公司入驻获取创建企业,无法重新申请</div>
        </Modal>
        <ActivityIndicator animating={this.state.isAnimating} className={style.loading} text="loading..." />
        <NavBar mode="light" icon={<Icon type="left" />} onLeftClick={_ => { this.props.history.goBack() }}>项目信息</NavBar>
        {this.getApplyDetailDom()}
        <div className={style.operation}>
          <Button size="small" className={style.btn} inline onClick={this.onReset}>重置</Button>
          <Button disabled={!this.state.canSubmit} size="small" type="primary" className={style.btn} inline onClick={this.onSubmit}>提交</Button>
        </div>
      </div>
    );
  }
}
export default withRouter(Form.create()(Project));
