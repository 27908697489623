import React, { Component } from "react";
import applyApi, { STATUS } from "../../../../services/apply";
import { LocalStorage as storage, } from "jsbdk";
import {
  List, TextareaItem,
  Button, Toast, Steps,
} from "antd-mobile";
import moment from "moment";
import gStyle from "../../../../utils/sass";
const idCardRealNameStyle = gStyle.index.user.auth.idCardRealName;
const Step = Steps.Step;
class ApplyWithSendMsg extends Component {
  isShowDetail = false;
  constructor(props) {
    super(props)
    this.state = {
      applyDetail: {
      }
    }
  }
  sendMsg = _ => {
    const { setFieldsValue } = this.props.form;
    this.props.form.validateFields(['msg',], (err) => {
      if (err !== null) {
        return Toast.fail("请输入内容", 2);
      }
      let fields = this.props.form.getFieldsValue();
      applyApi.sendAuditMsg({ msg: fields.msg, applyId: this.applyId, }).then(_ => {
        let applyDetail = this.state.applyDetail;
        applyDetail.steps[applyDetail.current - 1].messages.push({
          send_user: {
            id: storage.get('uid'),
            nick: this.state.userNick,
          },
          msg: fields.msg,
          ctime: moment().format('YYYY-MM-DD HH:mm:ss'),
        });
        setFieldsValue({ msg: '', });
        this.setState({
          applyDetail,
        })
        // Toast.success("发送成功, 请等待管理员回复", 1);
      }).catch(err => {
        console.log(err)
        Toast.fail("发送失败, 失败原因: " + err.msg, 3);
      })
    });
  }
  getChatInfo = _ => {
    const { getFieldDecorator, } = this.props.form;
    return (this.isShowDetail ? <div className={idCardRealNameStyle.content}>
      <Steps size="small">
        {this.state.applyDetail.steps.map((v, k) => {
          return (<Step key={k} status={v.status === 1 ? "process" : v.status === 2 ? "error" : "Waiting"}
            title={v.name} description={
              v.messages.map((v1, k1) => {
                return (<div key={k1} className={idCardRealNameStyle.meg}>
                  <div className={idCardRealNameStyle.author}>
                    <div className={idCardRealNameStyle.name}>{v1.send_user.id === this.state.applyDetail.apply_uid ? "我" :
                      v1.send_user.nick ? v1.send_user.nick : '未实名认证用户'}</div>
                    <div className={idCardRealNameStyle.time}>{v1.ctime.substr(0, 10)}</div>
                  </div>
                  <div className={v1.send_user.id === this.state.applyDetail.apply_uid ? idCardRealNameStyle.dialogRight : idCardRealNameStyle.dialogLeft}>
                    <span className={v1.send_user.id === this.state.applyDetail.apply_uid ? idCardRealNameStyle.right : idCardRealNameStyle.left}>{v1.msg}</span>
                  </div>
                </div>);
              })
            } />);
        })}
      </Steps>
      {this.state.applyDetail && this.state.applyDetail.status === STATUS.pass ? <div style={{ textAlign: 'center', fontSize: '20px', }}>当前申请已通过</div> :
        this.state.applyDetail.status === STATUS.close ? <div style={{ textAlign: 'center', fontSize: '20px', }}>当前申请已关闭</div> : <List>
          <List.Item>
            {getFieldDecorator('msg',
              { rules: [{ required: true, message: "请输入内容" }] })(
                <TextareaItem placeholder="请输入内容" rows={3} count={100}
                />
              )}
          </List.Item>
          <Button type="primary" onClick={this.sendMsg}>发送</Button>
        </List>}
    </div> : null)
  }
}
export default ApplyWithSendMsg
