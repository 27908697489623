import React from "react";
import {
  Carousel, Button, NavBar,
} from "antd-mobile";
import gStyle from "../../../../utils/sass";
import gImg from "../../../../utils/img";

const style = gStyle.index.user.apply.index;


export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAnimating: true,
      data: [
        {
          alt: "雪景",
          url: gImg.common.banner,
        },
        {
          alt: "雪景",
          url: gImg.common.banner,
        },
        {
          alt: "雪景",
          url: gImg.common.banner,
        },
      ],
      prompt: "项目申请/公司入驻必须通过审核才能进入下一步操作",
      slideIndex: 1,
    }
  }
  componentDidMount() {
    this.init();
  }

  init = async _ => {
    setTimeout(_ => {
      this.setState({
        isAnimating: false,
      })
    }, 500)
  }

  render() {
    return (
      <div className={style.index}>
        <NavBar mode="light">尚创汇</NavBar>
        <Carousel autoplay={true} infinite selectedIndex={this.state.slideIndex}
          className={style.banner} dots={false}>
          {this.state.data.map((v, k) => (
            <div key={k}>
              <img src={`${v.url}`} alt={v.alt} />
            </div>
          ))}
        </Carousel>
        <div className={style.btns}>
          <Button type="ghost" className={style.btn} inline size="small"
            onClick={_ => window.location.href = "/user/apply/createProject"}> 申请项目</Button>
          <Button type="ghost" className={style.btn} inline size="small"
            onClick={_ => window.location.href = "/user/apply/companyEnter"}>公司入驻</Button>
        </div>
        <div className={style.prompt}>提示: {this.state.prompt}</div>
      </div >
    );
  }
}

