import React, { Component } from 'react';
import { bget, bpost } from '../../services/api';
export default class UserList extends Component {
  state = {
  };
  async componentDidMount() {
    bget({
      url: 'index/index/test',
      query: {
        as: 123,
        as23: 'sdfgsd申达股份',
      }
    }).then(json => {
      console.log(json)

    }).catch(err => {
      console.log(err)
    })
    bpost({
      url: 'index/index/test',
      data: {
        as: 123,
        as23: 'sdfgsd申达股份',
      }
    }).then(json => {
      console.log(json)

    }).catch(err => {
      console.log(err)
    })
  }
  render() {
    return <div>
      this is buff test
   </div>
  }
}
