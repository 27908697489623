import React from "react";
import {
  Form, Input, message, Upload, Icon, Modal, Spin, Row, Col, Select, Radio,
} from 'antd';
import companyApi from "../../../services/company";
import schoolSpecialityApi from "../../../services/schoolSpeciality";
import { getUploadImgUrl } from '../../../services/api';
import { EDUCATION, EDUCATION_ZH, } from '../../../services/apply';
import collegeApi from '../../../services/college';
import { BASE_URL, } from '../../../config/prod';
import gSass from '../../../utils/sass';
import { IdentityCodeValid } from "../../../utils/utils";
import qs from "qs";
import { IS_GRADUATE } from "../../../services/schoolInfo";

const style = gSass.admin.company.base;
const Option = Select.Option;
const RadioGroup = Radio.Group;
//地址
const uploadButton = (
  <div>
    <Icon type="plus" />
    <div className="ant-upload-text">上传图片</div>
  </div>
);
const formItemLayout = {
  labelCol: {
    sm: {
      span: 4,
      offset: 1,
    },
  },
  wrapperCol: {
    sm: {
      span: 10,
      offset: 0,
    },
  },
};

let date = new Date().getFullYear(), gradeList = [];
for (let i = 0; i <= (date - 1990); i++) {
  gradeList.push({ value: i, label: (1990 + i) })
}


class LegalPerson extends React.Component {
  constructor(props) {
    super(props);
    const { id } = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    this.state = {
      companyId: parseInt(id),
      spinning: true,
      previewVisible: false,
      previewImage: '',
      collegeList: [],
      specialityList: [],
      educationList: [],
      grades: gradeList,
      idCardNoValidation: true,
      isGraduate: null,
      //data
      idCardFirstPic: [],
      idCardSecondPic: [],
      oneCardPassPic: [],
      degreePic: [],
      userInfo: {
        schoolInfo: {
          isGraduate: 0,
        }
      },
    }
  }
  componentDidMount() {
    this.init();
  }
  init = async _ => {
    try {
      let { data: { list: collegeList } } = await collegeApi.getList({ page: -1, limit: -1 })
      //获取学校专业列表
      let { data: { list: specialityList } } = await schoolSpecialityApi.getList({ page: -1, limit: -1 })
      specialityList = specialityList.map(v => ({
        value: v.id,
        label: v.name,
      }))
      let educationList = Object.values(EDUCATION).map(v => ({
        value: v,
        label: EDUCATION_ZH[v]
      }))
      const {
        data: { detail, },
      } = await companyApi.getLegalPersonInfo({ id: this.state.companyId });
      collegeList = collegeList.map(v => ({
        value: v.id,
        label: v.name,
      }))
      this.setState({
        spinning: false,
        collegeList,
        specialityList,
        educationList,
        userInfo: detail,
        idCardFirstPic: [{
          url: BASE_URL + detail.idCardRealNameInfo.idCardFirstPicModel.url,
          uid: detail.idCardRealNameInfo.idCardFirstPicModel.url,
        }],
        idCardSecondPic: [{
          url: BASE_URL + detail.idCardRealNameInfo.idCardSecondPicModel.url,
          uid: detail.idCardRealNameInfo.idCardSecondPicModel.url,
        }],
        oneCardPassPic: detail.schoolInfo.is_graduate !== IS_GRADUATE.TRUE ? [{
          url: BASE_URL + detail.schoolInfo.oneCardPassPicModel.url,
          uid: detail.schoolInfo.oneCardPassPicModel.url,
        }] : [],
        degreePic: detail.schoolInfo.is_graduate === IS_GRADUATE.TRUE ? [{
          url: BASE_URL + detail.schoolInfo.degreePicModel.url,
          uid: detail.schoolInfo.degreePicModel.url,
        }] : [],
        isGraduate: detail.schoolInfo.is_graduate,
      })
      this.props.form.setFieldsValue({
        idCardName: detail.idCardRealNameInfo.id_name,
        idCardNo: detail.idCardRealNameInfo.id_no,
        // sname: detail.schoolInfo.name,
        college: detail.schoolInfo.college_id,
        speciality: detail.schoolInfo.speciality_id,
        grade: detail.schoolInfo.grade,
        education: detail.schoolInfo.education,
        isGraduate: detail.schoolInfo.is_graduate,
      })
    } catch (e) {
      console.log(e)
      this.setState({
        spinning: false,
      })
    }
  }
  //提交
  updateUserInfo = evt => {
    evt.preventDefault();
    //实名认证
    this.props.form.validateFields(["idCardName", "idCardNo", "idCardFirstPic", "idCardSecondPic"], (err, fields) => {
      if (err) {
        message.error(err[Object.keys(err)[0]].errors[0].message, 1);
        return;
      }
      if (!this.state.idCardNoValidation) {
        message.error("身份证号码不正确", 1);
        return;
      }

      if (this.state.idCardFirstPic.length === 0) {
        message.error("请上传身份证正面照", 1);
        return;
      }
      if (this.state.idCardSecondPic.length === 0) {
        message.error("请上传身份证反面照", 1);
        return;
      }
      let data = {
        campanyId: this.state.companyId,
        name: fields.idCardName,
        idCardNo: fields.idCardNo || "",
        idCardFirstPic: this.state.idCardFirstPic,
        idCardSecondPic: this.state.idCardSecondPic,
      };
      console.log(data);
      // company.editBaseInfo({
      //   campanyId: this.state.companyId,
      //   name: fields.idCardName,
      //   idCardNo: fields.idCardNo || "",
      //   idCardFirstPic: this.state.idCardFirstPic,
      //   idCardSecondPic: this.state.idCardSecondPic,
      // }).then(json => {
      //   message.success('修改成功', 1);
      // }).catch(err => {
      //   message.error('修改失败,失败原因: ' + err.msg, 1);
      //   console.log(err);
      // })
    });
    //学校信息
    this.props.form.validateFields(["sname", "college", "speciality", "grade", "education",
      "isGraduate", "oneCardPassPic", "degreePic"], (err, fields) => {
        if (err !== null) {
          return message.error(err[0].errors[0].message, 3);
        }
        if (this.state.isGraduate === 0) {
          if (this.state.oneCardPassPic.length === 0) {
            message.error("请上传一卡通证件照", 1);
            return;
          }
        }
        if (this.state.isGraduate === 1) {
          if (this.state.degreePic.length === 0) {
            message.error("请上传学位证证件照", 1);
            return;
          }
        }
        let data = {
          campanyId: this.state.companyId,
          name: fields.sname,
          college: fields.college,
          speciality: fields.speciality,
          grade: fields.grade,
          education: fields.education,
          isGraduate: fields.isGraduate || this.state.userInfo.schoolInfo.isGraduate,
          oneCardPassPic: this.state.isGraduate === 0 ? this.state.oneCardPassPic[0].url : "",
          degreePic: this.state.isGraduate === 1 ? this.state.degreePic[0].url : "",
        }
        console.log(data);
        // company.editSchoolInfo({
        //   campanyId: this.state.companyId,
        //   name: fields.sname,
        //   college: fields.college,
        //   speciality: fields.speciality,
        //   grade: fields.grade,
        //   education: fields.education,
        //   isGraduate: fields.isGraduate || this.state.userInfo.schoolInfo.isGraduate,
        //   oneCardPassPic: this.state.isGraduate === 0 ? this.state.oneCardPassPic[0].url : "",
        //   degreePic: this.state.isGraduate === 1 ? this.state.degreePic[0].url : "",
        // }).then(json => {
        //   message.success('修改成功', 1);
        // }).catch(err => {
        //   message.error('修改失败,失败原因: ' + err.msg, 1);
        //   console.log(err);
        // })
      });
  }
  //上传图片
  handlePreview = file => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true,
    });
  }
  idCardFirstPicChange = ({ fileList }) => this.setState({ idCardFirstPic: fileList })
  idCardSecondPicChange = ({ fileList }) => this.setState({ idCardSecondPic: fileList })
  handleCancel = _ => this.setState({ previewVisible: false })

  //学校信息
  oneCardPassPicChange = ({ fileList }) => this.setState({ oneCardPassPic: fileList })
  degreePicChange = ({ fileList }) => this.setState({ degreePic: fileList })

  //身份证号码验证
  idCardNoValidation = evt => {
    let val = evt.target.value;
    IdentityCodeValid(val).then(json => {
      this.setState({
        idCardNoValidation: json.pass,
      })
      if (!json.pass) {
        message.error("身份证号码错误: " + json.msg, 1)
      }
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    //头像上传按钮
    const { previewVisible, previewImage, idCardFirstPic, idCardSecondPic,
      oneCardPassPic, degreePic, } = this.state;
    return (
      <Spin tip="Loading..." spinning={this.state.spinning} size="large">
        <div>
          <div className={style.title}>法人信息</div>
          <Row>
            <Form className={style.form}>
              <Col span={12}>
                <div className={style.layout}>
                  <div className={style.realNameTitle}>实名认证</div>
                  <Form.Item {...formItemLayout} label="姓名" >
                    {getFieldDecorator('idCardName', {
                    })(<Input placeholder="请输入姓名" />)}
                  </Form.Item>
                  <Form.Item {...formItemLayout} label="身份证号码"  >
                    {getFieldDecorator('idCardNo', {
                      rules: [{ required: true, message: '请输入身份证号码' }],
                    })(
                      <Input placeholder="请输入身份证号码" onBlur={this.idCardNoValidation} />)}
                  </Form.Item>
                  <Form.Item {...formItemLayout} label="身份证正面">
                    {getFieldDecorator('idCardFirstPic', {})(
                      <div className="clearfix">
                        <Upload action={getUploadImgUrl()} listType="picture-card" fileList={idCardFirstPic}
                          onPreview={this.handlePreview} onChange={this.idCardFirstPicChange}>
                          {idCardFirstPic.length > 0 ? null : uploadButton}
                        </Upload>
                        <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                          <img alt="example" style={{ width: '100%' }} src={previewImage} />
                        </Modal>
                      </div>
                    )}
                  </Form.Item>
                  <Form.Item {...formItemLayout} label="身份证反面">
                    {getFieldDecorator('idCardSecondPic', {})(
                      <div className="clearfix">
                        <Upload action={getUploadImgUrl()} listType="picture-card" fileList={idCardSecondPic}
                          onPreview={this.handlePreview} onChange={this.idCardSecondPicChange}>
                          {idCardSecondPic.length > 0 ? null : uploadButton}
                        </Upload>
                        <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                          <img alt="example" style={{ width: '100%' }} src={previewImage} />
                        </Modal>
                      </div>
                    )}
                  </Form.Item>
                </div>
                {/* <Form.Item >
                  <div className={style.controlLegalPerson}>
                    <Button className={style.btn} type="primary"
                      onClick={this.updateUserInfo}>更新基本信息</Button>
                  </div>
                </Form.Item> */}
              </Col>
              <Col span={12}>
                <div className={style.layout}>
                  <div className={style.realNameTitle}>学校信息</div>
                  {/* <Form.Item {...formItemLayout} label="姓名" >
                    {getFieldDecorator('sname', {
                      rules: [{ required: true, message: '请输入姓名' }],
                    })(<Input placeholder="请输入姓名" />)}
                  </Form.Item> */}
                  <Form.Item {...formItemLayout} label="学院"  >
                    {getFieldDecorator('college', {
                      rules: [{ required: true, message: '请选择学院' }],
                    })(
                      <Select placeholder="请选择学院">
                        {this.state.collegeList.map((v, k) => {
                          return (<Option key={`${k}`} value={v.value}>{v.label}</Option>);
                        })}
                      </Select>
                    )}
                  </Form.Item>
                  <Form.Item {...formItemLayout} label="专业"  >
                    {getFieldDecorator('speciality', {
                      rules: [{ required: true, message: '请选择专业' }],
                    })(
                      <Select placeholder="请选择专业">
                        {this.state.specialityList.map((v, k) => {
                          return (<Option key={`${k}`} value={v.value}>{v.label}</Option>);
                        })}
                      </Select>
                    )}
                  </Form.Item>
                  <Form.Item {...formItemLayout} label="年级"  >
                    {getFieldDecorator('grade', {
                      rules: [{ required: true, message: '请选择年级' }],
                    })(
                      <Select placeholder="请选择年级">
                        {this.state.grades.map((v, k) => {
                          return (<Option key={`${k}`} value={v.value}>{v.label}</Option>);
                        })}
                      </Select>
                    )}
                  </Form.Item>
                  <Form.Item {...formItemLayout} label="学历"  >
                    {getFieldDecorator('education', {
                      rules: [{ required: true, message: '请选择学历' }],
                    })(
                      <Select placeholder="请选择学历">
                        {this.state.educationList.map((v, k) => {
                          return (<Option key={`${k}`} value={v.value}>{v.label}</Option>);
                        })}
                      </Select>
                    )}
                  </Form.Item>
                  <Form.Item {...formItemLayout} label="是否为毕业生"  >
                    {getFieldDecorator('isGraduate', {
                      rules: [{ required: true, message: '请选择是否为毕业生' }],
                    })(
                      <RadioGroup onChange={evt => {
                        this.setState({
                          isGraduate: evt.target.value,
                        })
                      }}>
                        <Radio value={IS_GRADUATE.FALSE}> 否</Radio>
                        <Radio value={IS_GRADUATE.TRUE}>是</Radio>
                      </RadioGroup>
                    )}
                  </Form.Item>

                  <Form.Item {...formItemLayout} label="一卡通证件照"
                    className={this.state.isGraduate === IS_GRADUATE.FALSE ? "" : style.hidden}>
                    {getFieldDecorator('oneCardPassPic', {
                    })(
                      <div className="clearfix">
                        <Upload action={getUploadImgUrl()} listType="picture-card" fileList={oneCardPassPic}
                          onPreview={this.handlePreview} onChange={this.oneCardPassPicChange}>
                          {oneCardPassPic.length > 0 ? null : uploadButton}
                        </Upload>
                        <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                          <img alt="example" style={{ width: '100%' }} src={previewImage} />
                        </Modal>
                      </div>
                    )}
                  </Form.Item>
                  <Form.Item {...formItemLayout} label="学位证证件照"
                    className={this.state.isGraduate === IS_GRADUATE.TRUE ? "" : style.hidden}>
                    {getFieldDecorator('degreePic', {
                    })(
                      <div className="clearfix">
                        <Upload action={getUploadImgUrl()} listType="picture-card" fileList={degreePic}
                          onPreview={this.handlePreview} onChange={this.degreePicChange}>
                          {degreePic.length > 0 ? null : uploadButton}
                        </Upload>
                        <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                          <img alt="example" style={{ width: '100%' }} src={previewImage} />
                        </Modal>
                      </div>
                    )}
                  </Form.Item>
                </div>
              </Col>
            </Form>
          </Row>
        </div>
      </Spin >
    );
  }
}
export default Form.create()(LegalPerson);
