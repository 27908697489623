import React from "react";
import {
  List, ActivityIndicator, Icon, InputItem, Button, ImagePicker, Toast, Modal, NavBar, Flex, Switch, Radio, Picker
} from "antd-mobile";
import { withRouter, Link, } from "react-router-dom";
import { Form, message, } from 'antd';
import qs from 'qs';
import gStyle from "../../../../utils/sass";
import { BASE_URL, } from "../../../../config/prod";
import idCardRealNameApi, { IS_HOUSEHOLDREGISTER } from "../../../../services/idCardRealName";
import { antdUploadImg, } from "../../../../services/api";
import { IdentityCodeValid } from "../../../../utils/utils";
import ApplyWithSendMsg from '../../../../components/index/user/apply/ApplyWithSendMsg';
import applyApi from "../../../../services/apply";
import { GENDER, GENDER_ZH } from "../../../../services/user";
const style = gStyle.index.user.auth.idCardRealName;
const Item = List.Item;
const RadioItem = Radio.RadioItem;
class IdcardRealName extends ApplyWithSendMsg {
  isShowDetail = false;
  constructor(props) {
    super(props);
    this.isShowDetail = props.isShowDetail || false;
    if (this.isShowDetail === false) {
      const { location: { search } } = props;
      const searchData = qs.parse(search, { ignoreQueryPrefix: true });
      if ('isShowDetail' in searchData) {
        this.isShowDetail = true;
      }
    }
    this.applyId = parseInt(props.applyId);
    this.state = {
      animating: true,
      hasError: false,
      isModalActive: false,
      modalBody: '',
      modalFooter: [],
      genders: [
        { value: GENDER.MAN, label: GENDER_ZH[GENDER.MAN] },
        { value: GENDER.WOMAN, label: GENDER_ZH[GENDER.WOMAN] },
      ],
      isHouseholdRegister: false,

      household: [],
      //@3
      applyDetail: {
        info: {
          id: 1,
          name: '',
          email: '',
          age: '',
          phone: '',
          idCardNo: '',
          idCardFirstPic: [],
          idCardSecondPic: [],
          residePic: [],
          gender: 1,
        },
        steps: [],
      },
    }
  }
  componentDidMount() {
    this.init();
  }
  init = async _ => {
    try {
      let { data: { list: household } } = await idCardRealNameApi.getProvice({ page: -1, limit: -1 })
      household = [
        household.map(v => ({
          value: v.id,
          label: v.name,
        }))
      ];
      this.setState({
        household: household
      })
      if (this.isShowDetail) {
        const { setFieldsValue, } = this.props.form;
        try {
          let { data: { detail: applyDetail, } } = await applyApi.getApplyDetail({ id: this.applyId });
          applyDetail.info.idCardFirstPic = [{ url: BASE_URL + applyDetail.info.id_card_first_pic }]
          applyDetail.info.idCardSecondPic = [{ url: BASE_URL + applyDetail.info.id_card_second_pic }]
          applyDetail.info.residePic = [{ url: BASE_URL + applyDetail.info.id_reside_pic }]

          this.setState({
            applyDetail,
            isHouseholdRegister: applyDetail.info.is_shanghai
          })
          setFieldsValue({
            name: applyDetail.info.name,
            idCardNo: applyDetail.info.idCardNo,
            phone: applyDetail.info.idPhone,
            email: applyDetail.info.idEmail,
            age: applyDetail.info.idAge,
            gender: applyDetail.info.gender,
            household: [applyDetail.info.idHousehold],
            isHouseholdRegister: applyDetail.info.is_shanghai
          })
          console.log(this.state.isHouseholdRegister)
        } catch (e) {
          console.log(e);
        }
      } else {
        console.log()
        this.setState({
          animating: false,
        })
      }
    } catch (err) {
      console.log(err.msg);
    }
  }
  /**
   * 提交实名认证申请
   */
  apply = () => {
    this.props.form.validateFields(['name', 'phone', 'email', 'age', 'idCardNo', "isHouseholdRegister", "household",], { force: true }, async err => {
      if (err !== null) {
        return Toast.fail("填写错误", 2);
      }
      let fields = this.props.form.getFieldsValue();
      console.log(fields)
      try {
        await idCardRealNameApi.apply({
          idCardName: fields.name,
          idPhone: fields.phone,
          idEmail: fields.email,
          idAge: fields.age,
          idCardNo: fields.idCardNo,
          idGender: this.state.applyDetail.info.gender,
          idCardFirstPic: this.state.applyDetail.info.idCardFirstPic[0],
          idCardSecondPic: this.state.applyDetail.info.idCardSecondPic[0],
          residePic: this.state.applyDetail.info.residePic[0],
          isHouseholdRegister: fields.isHouseholdRegister,
          // isHouseholdRegister: this.state.isHouseholdRegister,
          household: fields.household[0],

        })

        this.setState({
          isModalActive: true,
          modalBody: <div>提交成功,请等待审核</div>,
          modalFooter: [{ text: '确定', onPress: () => this.props.history.push('/') }],
        })
      } catch (err) {
        this.setState({
          isModalActive: true,
          modalBody: <div>提交失败,错误信息:{err.msg}</div>,
          modalFooter: [{ text: '确定', onPress: () => this.setState({ isModalActive: false, }) }],
        })
      }
    });
  }
  /**
   * 重置实名认证申请
   */
  resetForm = () => {
    this.props.form.resetFields(['name', 'phone', 'email', 'age', 'idCardNo', "household"]);
  }
  //身份证验证
  iDCardVerification = async val => {
    let result = await IdentityCodeValid(val);
    if (result.pass) {
      this.setState({
        idCardPass: true,
      });
    } else {
      this.setState({
        idCardPass: false,
      });
      Toast.fail(result.msg, 3)
    }
    let idCardNo = this.state.applyDetail.info.idCardNo;
    this.setState({
      idCardNo,
    })
  }
  genderChange = val => {
    let applyDetail = this.state.applyDetail;
    applyDetail.info.gender = val
    this.setState({
      applyDetail
    })
  };
  /**
   * 详情
   *  */
  getApplyDetailDom = _ => {
    const { getFieldProps, getFieldDecorator, } = this.props.form;
    return <form className={style.form}>
      <List>
        <InputItem
          {...getFieldProps('name', {
            rules: [{ required: true, },],
          })}
          clear className={style.input} placeholder="请输入姓名"
          extra={<Icon size="md" color="#ccc" type="right"></Icon>}
        >姓名</InputItem>
        <InputItem
          {...getFieldProps('phone', {
            rules: [{ required: true, },],
          })}
          clear className={style.input} placeholder="请输入联系电话"
          extra={<Icon size="md" color="#ccc" type="right"></Icon>}
        >联系电话</InputItem>
        <InputItem
          {...getFieldProps('age', {
            rules: [{ required: true, },],
          })}
          clear className={style.input} placeholder="请输入年龄"
          extra={<Icon size="md" color="#ccc" type="right"></Icon>}
        >年龄</InputItem>
        <InputItem
          {...getFieldProps('email', {
            rules: [{ required: true, },],
          })}
          clear className={style.input} placeholder="请输入邮箱"
          extra={<Icon size="md" color="#ccc" type="right"></Icon>}
        >邮箱</InputItem>
        {getFieldDecorator("gender", {
          initialValue: this.state.applyDetail.info.gender,
          rules: [{ required: true, message: "请选择性别" }],
        })(
          <Item className={style.gender}>
            <Flex justify="between">
              <div className={style.title}>性别</div>
              <Flex>
                {this.state.genders.map(i => (
                  <RadioItem
                    key={i.value}
                    className={style.genderLine}
                    checked={i.value === this.state.applyDetail.info.gender}
                    onChange={_ => this.genderChange(i.value)}>
                    {i.label}
                  </RadioItem>
                ))}
              </Flex>
            </Flex>
          </Item>,
        )}
        {getFieldDecorator('household',
          { rules: [{ required: true, message: '请选择户籍', },], })(
            <Picker data={this.state.household} title="选择户籍" cascade={false}>
              <List.Item arrow="horizontal">户籍</List.Item>
            </Picker>
          )}
        <div>
          <Item>
            <Flex>
              <div className={style.title}>是否有上海居住证</div>
              <Switch
                {
                ...getFieldProps("isHouseholdRegister", {
                  initialValue: this.state.isHouseholdRegister === IS_HOUSEHOLDREGISTER.TRUE,
                  valuePropName: "checked",
                  onChange: (checked) => {
                    this.setState({
                      isHouseholdRegister: checked,
                    })
                  }
                })
                }
                className={style.isHouseholdRegister}
              ></Switch>
            </Flex>
          </Item>
          <div className={style.tips}>提示:如果非上海户籍,请上传一张居住证的图片</div>
          <Item className={this.state.isHouseholdRegister ? "" : style.hidden}>
            {this.state.isHouseholdRegister ? <ImagePicker files={this.state.applyDetail.info.residePic}
              onChange={(files, type, index) => {
                if (type === 'add') {
                  let formData = new FormData();
                  formData.append("name", "file");
                  formData.append("file", files[0].file);
                  antdUploadImg(formData).then(json => {
                    const { data: { name, picId, url } } = json
                    let applyDetail = this.state.applyDetail
                    applyDetail.info.residePic = [{
                      name, picId, url: BASE_URL + url,
                    }]
                    this.setState({
                      applyDetail,
                    });
                  }).catch(e => {
                    message.error("上传图片失败", 1)
                    console.log(e)
                  })
                } else if (type === 'remove') {
                  let applyDetail = this.state.applyDetail
                  applyDetail.info.residePic = []
                  this.setState({
                    applyDetail,
                  })
                }
              }}
              selectable={this.state.applyDetail.info.residePic.length < 1}
              multiple={this.state.multiple}
              style={{ flex: 1, }}
            ></ImagePicker> : null}
          </Item>
        </div>
        <InputItem
          {...getFieldProps('idCardNo', {
            rules: [{ required: true, },],
          })}
          className={style.input} placeholder="请输入身份证号码" extra={<Icon size="md" color="#ccc" type="right"></Icon>}
          onBlur={this.iDCardVerification} maxLength={18} minLength={15}
        >身份证号码</InputItem>
        <Item >
          <div className={style.title}>身份证正面照</div>
          <ImagePicker files={this.state.applyDetail.info.idCardFirstPic}
            onChange={(files, type, index) => {
              if (type === 'add') {
                let formData = new FormData();
                formData.append("name", 'file');
                formData.append("file", files[0].file);
                antdUploadImg(formData).then(json => {
                  const { data: { name, picId, url, } } = json;
                  // let idCardFirstPic = [{
                  //   name, picId, url: BASE_URL + url,
                  // }];
                  let applyDetail = this.state.applyDetail;
                  applyDetail.info.idCardFirstPic = [{
                    name, picId, url: BASE_URL + url,
                  }];
                  this.setState({
                    applyDetail,
                  });
                }).catch(e => {
                  message.error('上传图片失败', 1)
                  console.log(e)
                })
              } else if (type === 'remove') {
                // let idCardFirstPic = [];
                let applyDetail = this.state.applyDetail;
                applyDetail.info.idCardFirstPic = [];
                this.setState({
                  applyDetail,
                });
              }
            }}
            selectable={this.state.applyDetail.info.idCardFirstPic.length < 1} style={{ flex: 1, }}
          />
        </Item>
        <Item >
          <div className={style.title}>身份证反面照</div>
          <ImagePicker files={this.state.applyDetail.info.idCardSecondPic}
            onChange={(files, type, index) => {
              if (type === 'add') {
                let formData = new FormData();
                formData.append("name", 'file');
                formData.append("file", files[0].file);
                antdUploadImg(formData).then(json => {
                  const { data: { name, picId, url, } } = json;
                  let applyDetail = this.state.applyDetail;
                  applyDetail.info.idCardSecondPic = [{
                    name, picId, url: BASE_URL + url,
                  }];
                  this.setState({
                    applyDetail,
                  });
                }).catch(e => {
                  message.error('上传图片失败', 1)
                  console.log(e)
                })
              } else if (type === 'remove') {
                let applyDetail = this.state.applyDetail;
                applyDetail.info.idCardSecondPic = []
                this.setState({
                  applyDetail,
                });
              }
            }}
            selectable={this.state.applyDetail.info.idCardSecondPic.length < 1} style={{ flex: 1, }}
          />
        </Item>
      </List>
      {this.getChatInfo()}
    </form>
  }
  // 是否为上海户籍
  isHouseholdRegisterChange = (val) => {
    this.setState({
      isHouseholdRegister: val,
    });
  };
  render() {
    return (this.isShowDetail ? this.getApplyDetailDom() :
      <div className={style.idCardRealName}>
        <Modal visible={this.state.isModalActive} transparent maskClosable={false}
          title="提示" footer={this.state.modalFooter}
        >{this.state.modalBody}
        </Modal>
        <ActivityIndicator animating={this.state.animating} toast className={style.loading}
          text="loading..." />
        <NavBar className={this.isShowDetail ? style.hidden : null} mode="light"
          icon={<Link to="/?tab=my"><Icon type="left" /></Link>} >实名认证</NavBar>
        {this.getApplyDetailDom()}
        <div className={style.operation}>
          <Button size="small" className={style.btn} inline onClick={this.resetForm}>重置</Button>
          <Button size="small" type="primary" className={style.btn} inline onClick={this.apply}>提交</Button>
          {/* <Button size="small" type="primary" className={style.btn} inline onClick={this.apply}>修改提交</Button> */}
        </div>
      </div>
    );
  }
}
export default withRouter(Form.create()(IdcardRealName));


