import React from "react";
import {
  Form, message, Upload, Modal, Icon, Spin, Breadcrumb, Input, Button
} from 'antd';
import { Link, } from "react-router-dom";
import parking from "../../../services/parkingPermit";
import applyApi from '../../../services/apply';
import gSass from '../../../utils/sass';
import { IdentityCodeValid } from "../../../utils/utils";
import { BASE_URL, } from '../../../config/prod';
import { getUploadImgUrl } from '../../../services/api';
import qs from "qs";
const style = gSass.admin.applyEdit.edit;
//地址
const uploadButton = (
  <div>
    <Icon type="plus" />
    <div className="ant-upload-text">上传图片</div>
  </div>
);
const formItemLayout = {
  labelCol: {
    sm: {
      span: 4,
      offset: 4,
    },
  },
  wrapperCol: {
    sm: {
      span: 8,
      offset: 0,
    },
  },
};

let date = new Date().getFullYear(), gradeList = [];
for (let i = 0; i <= (date - 1990); i++) {
  gradeList.push({ value: i, label: (1990 + i) })
}


class Edit extends React.Component {
  constructor(props) {
    super(props);
    const { id } = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    this.state = {
      projectId: parseInt(id),
      spinning: true,
      previewVisible: false,
      previewImage: '',
      collegeList: [],
      specialityList: [],
      educationList: [],
      grades: gradeList,
      idCardNoValidation: true,
      isGraduate: null,
      isOversea: null,
      //data
      idCardFirstPic: [],
      idCardSecondPic: [],
      driverLicensePic: [],
      drivingLicensePic: [],
      socialImg: [],
      applyId: null,
      overseasCard: [],
      userInfo: {
        schoolInfo: {
          isGraduate: 0,
          isOversea: 0,
        }
      },
    }
  }
  componentDidMount() {
    this.init();
  }
  init = async _ => {
    try {
      let { data: { detail, } } = await applyApi.getApplyDetail({ id: this.state.projectId });
      console.log(detail)
      this.setState({
        spinning: false,
        driverLicensePic: detail.info.driverLicense !== "" ? [{
          url: BASE_URL + detail.info.driverLicense.url,
          uid: detail.info.driverLicense.id
        }] : [],
        drivingLicensePic: detail.info.drivingLicense !== "" ? [{
          url: BASE_URL + detail.info.drivingLicense.url,
          uid: detail.info.drivingLicense.id,
        }] : [],
        idCardFirstPic: detail.info.idCardFirst !== "" ? [{
          url: BASE_URL + detail.info.idCardFirst.url,
          uid: detail.info.idCardFirst.id,
        }] : [],
        idCardSecondPic: detail.info.idCardSecond !== "" ? [{
          url: BASE_URL + detail.info.idCardSecond.url,
          uid: detail.info.idCardSecond.id,
        }] : [],
        socialImg: detail.info.social !== "" ? [{
          url: BASE_URL + detail.info.social.url,
          uid: detail.info.social.id,
        }] : [],
        applyId: detail.info.id,
      })
      this.props.form.setFieldsValue({
        userName: detail.info.carUserIdCardName,
        contactInfo: detail.info.contactInfo,
        licenseNum: detail.info.license,
        carUserIdCardNo: detail.info.carUserIdCardNo,
      })
    } catch (e) {
      console.log(e)
      this.setState({
        spinning: false,
      })
    }
  }
  //提交
  updateUserInfo = evt => {
    evt.preventDefault();
    //学校信息
    this.props.form.validateFields(["userName", "contactInfo", "licenseNum", "carUserIdCardNo", "drivingLicensePic", "driverLicensePic", "socialImg", "idCardFirstPic", "idCardSecondPic",], (err, fields) => {
      if (err !== null) {
        return message.error(err[0].errors[0].message, 3);
      }
      let { driverLicensePic, drivingLicensePic, idCardSecondPic, idCardFirstPic, socialImg } = this.state
      if (driverLicensePic.length !== 0) {
        if (driverLicensePic[0].response) {
          driverLicensePic = driverLicensePic[0].response.data.picId
        } else {
          driverLicensePic = driverLicensePic[0].uid
        }
      }
      if (drivingLicensePic.length !== 0) {
        if (drivingLicensePic[0].response) {
          drivingLicensePic = drivingLicensePic[0].response.data.picId
        } else {
          drivingLicensePic = drivingLicensePic[0].uid
        }
      }
      if (idCardFirstPic.length !== 0) {
        if (idCardFirstPic[0].response) {
          idCardFirstPic = idCardFirstPic[0].response.data.picId
        } else {
          idCardFirstPic = idCardFirstPic[0].uid
        }
      }
      if (idCardSecondPic.length !== 0) {
        if (idCardSecondPic[0].response) {
          idCardSecondPic = idCardSecondPic[0].response.data.picId
        } else {
          idCardSecondPic = idCardSecondPic[0].uid
        }
      }

      if (socialImg.length !== 0) {
        if (socialImg[0].response) {
          socialImg = socialImg[0].response.data.picId
        } else {
          socialImg = socialImg[0].uid
        }
      }


      parking.edit({
        applyId: this.state.applyId,
        contactInfo: fields.contactInfo,
        licenseNum: fields.licenseNum,
        drivingLicensePic,
        driverLicensePic,
        idCardSecondPic,
        idCardFirstPic,
        socialImg,
      }).then(json => {
        message.success('修改成功', 1);
      }).catch(err => {
        message.error('修改失败,失败原因: ' + err.msg, 1);
        console.log(err);
      })
    });
  }
  //上传图片
  handlePreview = file => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true,
    });
  }
  handleCancel = _ => this.setState({ previewVisible: false })
  //学校信息
  drivingLicensePicChange = ({ fileList }) => this.setState({ drivingLicensePic: fileList })
  driverLicensePicChange = ({ fileList }) => this.setState({ driverLicensePic: fileList })
  socialImgChange = ({ fileList }) => this.setState({ socialImg: fileList })
  idCardFirstPicChange = ({ fileList }) => this.setState({ idCardFirstPic: fileList })
  idCardSecondPicChange = ({ fileList }) => this.setState({ idCardSecondPic: fileList })
  //身份证号码验证
  idCardNoValidation = evt => {
    let val = evt.target.value;
    IdentityCodeValid(val).then(json => {
      this.setState({
        idCardNoValidation: json.pass,
      })
      if (!json.pass) {
        message.error("身份证号码错误: " + json.msg, 1)
      }
    })
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    //头像上传按钮
    const { previewVisible, previewImage,
      driverLicensePic, drivingLicensePic, socialImg, idCardFirstPic, idCardSecondPic } = this.state;
    return (
      <Spin tip="Loading..." spinning={this.state.spinning} size="large">
        <div className={style.info}>
          <Breadcrumb className={style.title}>
            <Breadcrumb.Item><Link to="/admin">首页</Link></Breadcrumb.Item>
            <Breadcrumb.Item><Link to="/admin/apply/allList">申请管理</Link></Breadcrumb.Item>
            <Breadcrumb.Item>停车证信息编辑</Breadcrumb.Item>
          </Breadcrumb>
          <div className={style.basicWrap}>
            <Form className={style.form}>

              <Form.Item {...formItemLayout} label="申请人姓名">
                {getFieldDecorator("userName", {
                  rules: [{ required: true, message: "请输入申请人姓名" }],
                })(<Input placeholder="请输入" disabled />)}
              </Form.Item>
              <Form.Item {...formItemLayout} label="身份证号码">
                {getFieldDecorator("carUserIdCardNo", {
                  rules: [{ required: true, message: "请输入身份证号码" }],
                })(<Input placeholder="请输入" disabled />)}
              </Form.Item>
              <Form.Item {...formItemLayout} label="手机号码">
                {getFieldDecorator("contactInfo", {
                  rules: [{ required: true, message: "请输入手机号码" }],
                })(<Input placeholder="请输入" />)}
              </Form.Item>
              <Form.Item {...formItemLayout} label="车牌号">
                {getFieldDecorator("licenseNum", {
                  rules: [{ required: true, message: "请输入车牌号" }],
                })(<Input placeholder="请输入" />)}
              </Form.Item>
              <Form.Item {...formItemLayout} label="驾驶证证件照"
              >
                {getFieldDecorator('driverLicensePic', {
                })(
                  <div className="clearfix">
                    <Upload action={getUploadImgUrl()} listType="picture-card" fileList={driverLicensePic}
                      onPreview={this.handlePreview} onChange={this.driverLicensePicChange}>
                      {driverLicensePic.length > 0 ? null : uploadButton}
                    </Upload>
                    <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                      <img alt="example" style={{ width: '100%' }} src={previewImage} />
                    </Modal>
                  </div>
                )}
              </Form.Item>
              <Form.Item {...formItemLayout} label="行驶证证件照"
              >
                {getFieldDecorator('drivingLicensePic', {
                })(
                  <div className="clearfix">
                    <Upload action={getUploadImgUrl()} listType="picture-card" fileList={drivingLicensePic}
                      onPreview={this.handlePreview} onChange={this.drivingLicensePicChange}>
                      {drivingLicensePic.length > 0 ? null : uploadButton}
                    </Upload>
                    <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                      <img alt="example" style={{ width: '100%' }} src={previewImage} />
                    </Modal>
                  </div>
                )}
              </Form.Item>
              <Form.Item {...formItemLayout} label="身份证正面">
                {getFieldDecorator('idCardFirstPic', {})(
                  <div className="clearfix">
                    <Upload action={getUploadImgUrl()} listType="picture-card" fileList={idCardFirstPic}
                      onPreview={this.handlePreview} onChange={this.idCardFirstPicChange}>
                      {idCardFirstPic.length > 0 ? null : uploadButton}
                    </Upload>
                    <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                      <img alt="example" style={{ width: '100%' }} src={previewImage} />
                    </Modal>
                  </div>
                )}
              </Form.Item>
              <Form.Item {...formItemLayout} label="身份证反面">
                {getFieldDecorator('idCardSecondPic', {})(
                  <div className="clearfix">
                    <Upload action={getUploadImgUrl()} listType="picture-card" fileList={idCardSecondPic}
                      onPreview={this.handlePreview} onChange={this.idCardSecondPicChange}>
                      {idCardSecondPic.length > 0 ? null : uploadButton}
                    </Upload>
                    <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                      <img alt="example" style={{ width: '100%' }} src={previewImage} />
                    </Modal>
                  </div>
                )}
              </Form.Item>
              <Form.Item {...formItemLayout} label="当月缴纳社保凭证"
              >
                {getFieldDecorator('socialImg', {
                })(
                  <div className="clearfix">
                    <Upload action={getUploadImgUrl()} listType="picture-card" fileList={socialImg}
                      onPreview={this.handlePreview} onChange={this.socialImgChange}>
                      {socialImg.length > 0 ? null : uploadButton}
                    </Upload>
                    <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                      <img alt="example" style={{ width: '100%' }} src={previewImage} />
                    </Modal>
                  </div>
                )}
              </Form.Item>
              <Form.Item wrapperCol={{
                xs: {
                  span: 7,
                  offset: 8,
                },
              }}>
                <Button className={style.btn} type="primary" onClick={this.updateUserInfo}>更新基本信息</Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Spin >
    );
  }
}
export default Form.create()(Edit);
