import React from "react";
import {
  Form,
  Input,
  Button,
  message,
  Upload,
  Icon,
  Modal,
  Spin,
  Col,
  DatePicker,
  Empty,
  Select,
  InputNumber,
  Collapse,
  Cascader,
  Badge,
  Radio,
  Row,
} from "antd";
import { NavLink } from "react-router-dom";
import { getUploadImgUrl } from "../../../services/api";
import { TYPE as PATENT_TYPE, TYPE_ZH as PATENT_TYPE_ZH } from "../../../services/patent";
import { EDUCATION, EDUCATION_ZH } from "../../../services/apply";
import companyApi from "../../../services/company";
import gSass from "../../../utils/sass";
import { BASE_URL } from "../../../config/prod";
import { getRegion, IdentityCodeValid } from "../../../utils/utils";
import moment from "moment";
import qs from "qs";

const style = gSass.admin.company.base;
const Option = Select.Option;
const { TextArea } = Input;
const Panel = Collapse.Panel;
const RadioGroup = Radio.Group;
//地址
const uploadButton = (
  <div>
    <Icon type="plus" />
    <div className="ant-upload-text">上传图片</div>
  </div>
);
const formItemLayout = {
  labelCol: {
    lg: {
      span: 4,
      offset: 1,
    },
  },
  wrapperCol: {
    lg: {
      span: 10,
      offset: 0,
    },
  },
};
class BaseInfo extends React.Component {
  constructor(props) {
    super(props);
    const { id } = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    this.state = {
      companyId: parseInt(id),
      spinning: true,
      previewVisible: false,
      previewImage: "",
      idCardNoValidation: true,
      region: null,
      //date
      industryList: [],
      patentTypeList: [],
      educationList: [],
      patentList: [],
      financingList: [],
      shareholderList: [],
      insuredPersonList: [],
      businessLicensePic: [],
      bankAccountOpeningCertificatePic: [],
      awardRecordList: [],
      shareholderStudyAbroadEducationProvePicList: [],
      shareholderStudyAbroadList: [],
      userInfo: {},
    };
  }
  componentDidMount() {
    this.init();
  }
  init = async _ => {
    try {
      let {
        data: { list: industryList },
      } = await companyApi.getIndustryList({ page: -1, limit: -1 });
      industryList = industryList.map(v => ({
        value: v.id,
        label: v.name,
      }));
      const patentTypeList = Object.values(PATENT_TYPE).map(v => ({
        value: v,
        label: PATENT_TYPE_ZH[v],
      }));
      //获取学历信息
      let educationList = Object.values(EDUCATION).map((v, k) => ({
        value: v,
        label: EDUCATION_ZH[v],
      }));
      let {
        data: { detail },
      } = await companyApi.companyDetail({ id: this.state.companyId });
      let region = await getRegion();
      let shareholderList = [],
        insuredPersonList = [],
        patentList = [],
        financingList = [],
        awardRecordList = [];
      for (let i = 0; i < detail.shareholderList.length; i++) {
        shareholderList.push(i);
      }
      for (let i = 0; i < detail.insuredPersonList.length; i++) {
        insuredPersonList.push(i);
      }
      for (let i = 0; i < detail.patentList.length; i++) {
        patentList.push(i);
      }
      for (let i = 0; i < detail.financingRecordList.length; i++) {
        financingList.push(i);
      }
      for (let i = 0; i < detail.awardRecordList.length; i++) {
        awardRecordList.push(i);
      }
      let shareholderStudyAbroadEducationProvePicList = this.state
        .shareholderStudyAbroadEducationProvePicList,
        shareholderStudyAbroadList = this.state.shareholderStudyAbroadList;
      for (let i = 0; i < detail.shareholderList.length; i++) {
        shareholderStudyAbroadEducationProvePicList[i] = [
          {
            uid: detail.shareholderList[i].studyAbroadInfo.educationProvePic.id,
            url: BASE_URL + detail.shareholderList[i].studyAbroadInfo.educationProvePic.url,
          },
        ];
        shareholderStudyAbroadList[i] = [0];
      }
      this.setState({
        spinning: false,
        industryList,
        patentTypeList,
        patentList,
        educationList,
        shareholderStudyAbroadEducationProvePicList,
        shareholderStudyAbroadList,
        region,
        businessLicensePic: [
          {
            uid: detail.businessLicensePic.id,
            url: BASE_URL + detail.businessLicensePic.url,
          },
        ],
        bankAccountOpeningCertificatePic: [
          {
            uid: detail.bankAccountOpeningCertificatePic.id,
            url: BASE_URL + detail.bankAccountOpeningCertificatePic.url,
          },
        ],
        userInfo: {
          shareholderList: detail.shareholderList,
          insuredPersonList: detail.insuredPersonList,
          patentList: detail.patentList,
          financingList: detail.financingList,
          awardRecordList: detail.awardRecordList,
        },
        shareholderList,
        insuredPersonList,
        financingList,
        awardRecordList,
      });
      this.props.form.setFieldsValue({
        name: detail.name,
        totalNumber: detail.total_number,
        industry: detail.industry_id,
        mainBusiness: detail.main_business,
      });
      for (let i = 0; i < detail.shareholderList.length; i++) {
        this.props.form.setFieldsValue({
          ["shareholderName" + i]: detail.shareholderList[i].id_card_name,
          ["shareholderEducation" + i]: detail.shareholderList[i].education,
          ["shareholderAddress" + i]: [
            [
              detail.shareholderList[i].address.province_cid,
              detail.shareholderList[i].address.city_cid,
              detail.shareholderList[i].address.county_cid,
            ],
          ],
          ["shareholderDetail" + i]: detail.shareholderList[i].address.detail,
          ["shareholderHasStudyAbroad" + i]: !!detail.shareholderList[i].studyAbroadInfo,
          ["shareholderStudyAbroadSchoolName" + i]: detail.shareholderList[i].studyAbroadInfo
            .school_name,
          ["shareholderStudyAbroadEducation" + i]: detail.shareholderList[i].studyAbroadInfo
            .education,
          ["shareholderIdCardNo" + i]: detail.shareholderList[i].id_card_no,
        });
      }
      for (let i = 0; i < detail.insuredPersonList.length; i++) {
        this.props.form.setFieldsValue({
          ["insuredPersonName" + i]: detail.insuredPersonList[i].name,
          ["insuredPersonTime" + i]: moment(
            detail.insuredPersonList[i].begin_time,
            "YYYY-MM-DD HH:mm:ss",
          ),
        });
      }
      for (let i = 0; i < detail.patentList.length; i++) {
        this.props.form.setFieldsValue({
          ["patentName" + i]: detail.patentList[i].name,
          ["patentTime" + i]: moment(detail.patentList[i].obtain_time, "YYYY-MM-DD HH:mm:ss"),
          ["patentType" + i]: detail.patentList[i].type,
        });
      }
      for (let i = 0; i < detail.financingRecordList.length; i++) {
        this.props.form.setFieldsValue({
          ["financingPrice" + i]: detail.financingRecordList[i].money / 100,
          ["financingTime" + i]: moment(detail.financingRecordList[i].ctime, "YYYY-MM-DD HH:mm:ss"),
          ["financingSource" + i]: detail.financingRecordList[i].source,
        });
      }
      for (let i = 0; i < detail.awardRecordList.length; i++) {
        this.props.form.setFieldsValue({
          ["awardName" + i]: detail.awardRecordList[i].name,
          ["awardObtainTime" + i]: moment(
            detail.awardRecordList[i].obtain_time,
            "YYYY-MM-DD HH:mm:ss",
          ),
          ["organiser" + i]: detail.awardRecordList[i].organiser,
        });
      }
    } catch (e) {
      console.log(e);
      this.setState({
        spinning: false,
      });
    }
  };
  //提交
  updateUserInfo = evt => {
    evt.preventDefault();
    this.props.form.validateFields((err, fields) => {
      if (err) {
        return message.error(err[Object.keys(err)[0]].errors[0].message, 1);
      }
      if (this.state.businessLicensePic.length === 0) {
        return message.error("请上传营业执照图片", 1);
      }
      if (this.state.bankAccountOpeningCertificatePic.length === 0) {
        return message.error("请上传银行开户正面图片", 1);
      }
      let patentList = [],
        financingList = [],
        shareholderList = [],
        insuredPersonList = [],
        awardRecordList = [];
      for (let v of this.state.patentList) {
        patentList.push({
          name: fields["patentName" + v],
          time: moment(fields["patentTime" + v]).format("YYYY-MM-DD HH:mm:ss"),
          type: fields["patentType" + v],
        });
      }
      for (let v of this.state.financingList) {
        financingList.push({
          price: fields["financingPrice" + v] * 100,
          time: moment(fields["financingTime" + v]).format("YYYY-MM-DD HH:mm:ss"),
          source: fields["financingSource" + v],
        });
      }
      for (let v of this.state.awardRecordList) {
        awardRecordList.push({
          name: fields["awardName" + v],
          obtainTime: moment(fields["awardObtainTime" + v]).format("YYYY-MM-DD HH:mm:ss"),
          organiser: fields["organiser" + v],
        });
      }
      for (let v of this.state.shareholderList) {
        shareholderList.push({
          companyId: this.state.companyId,
          name: fields["shareholderName" + v],
          education: fields["shareholderEducation" + v],
          idCardNo: fields["shareholderIdCardNo" + v],
          address: {
            addressIds: fields["shareholderAddress" + v],
            detail: fields["shareholderDetail" + v],
          },
          hasStudyAbroad: fields["shareholderHasStudyAbroad" + v],
          studyAbroadInfo: fields["shareholderHasStudyAbroad" + v]
            ? {
              schoolName: fields["shareholderStudyAbroadSchoolName" + v],
              education: fields["shareholderStudyAbroadEducation" + v][0],
              educationProvePic: this.state.shareholderStudyAbroadEducationProvePicList[v][0],
            }
            : null,
        });
      }
      for (let v of this.state.insuredPersonList) {
        insuredPersonList.push({
          name: fields["insuredPersonName" + v],
          time: moment(fields["insuredPersonTime" + v]).format("YYYY-MM-DD HH:mm:ss"),
        });
      }
      let data = {
        name: fields.name,
        totalNumber: fields.totalNumber,
        industry: fields.industry,
        patentList,
        financingList,
        shareholderList,
        insuredPersonList,
        awardRecordList,
        businessLicensePic: fields.businessLicensePic || this.state.businessLicensePic[0].url,
        bankAccountOpeningCertificatePic:
          fields.bankAccountOpeningCertificatePic ||
          this.state.bankAccountOpeningCertificatePic[0].url,
        mainBusiness: fields.mainBusiness,
      };
      console.log(data);

      //   company.editCompanyInfo({
      //   name: fields.name,
      //   totalNumber: fields.totalNumber,
      //   industry: fields.industry,
      //   patentList,
      //   financingList,
      //   shareholderList,
      //   insuredPersonList,
      //   businessLicensePic: fields.businessLicensePic,
      //   bankAccountOpeningCertificatePic: fields.bankAccountOpeningCertificatePic,
      //   mainBusiness: fields.mainBusiness,
      // }).then(json => {
      //   message.success('修改成功', 1);
      // }).catch(err => {
      //   message.error('修改失败,失败原因: ' + err.msg, 1);
      //   console.log(err);
      // })
    });
  };
  //股东海外留学学历证明图片上传
  shareholderStudyAbroadEducationProvePicChange = (files, idx) => {
    let shareholderStudyAbroadEducationProvePicList = this.state
      .shareholderStudyAbroadEducationProvePicList,
      filesList = files.fileList;
    shareholderStudyAbroadEducationProvePicList[idx] = filesList;
    this.setState({ shareholderStudyAbroadEducationProvePicList });
  };
  //上传图片
  businessLicensePicChange = ({ fileList }) => this.setState({ businessLicensePic: fileList });
  bankAccountOpeningCertificatePicChange = ({ fileList }) =>
    this.setState({ bankAccountOpeningCertificatePic: fileList });
  handlePreview = file => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true,
    });
  };
  handleCancel = _ => this.setState({ previewVisible: false });

  //身份证号码验证
  idCardNoValidation = evt => {
    let val = evt.target.value;
    IdentityCodeValid(val).then(json => {
      this.setState({
        idCardNoValidation: json.pass,
      });
      if (!json.pass) {
        message.error("身份证号码错误: " + json.msg, 1);
      }
    });
  };
  //添加股东
  addShareholder = _ => {
    let fieldNames = [];
    for (let i = 0; i < this.state.shareholderList.length; i++) {
      fieldNames.push(
        "shareholderName" + i,
        "shareholderEducation" + i,
        "shareholderAddress" + i,
        "shareholderDetail" + i,
        "shareholderHasStudyAbroad" + i,
        "shareholderIdCardNo" + i,
        "shareholderStudyAbroadSchoolName" + i,
        "shareholderStudyAbroadEducation" + i,
      );
    }
    this.props.form.validateFields(fieldNames, (err, fields) => {
      if (err) {
        let firstErrKey = Object.keys(err)[0];
        return message.error(err[firstErrKey].errors[0].message, 2);
      }
      for (let i = 0; i < this.state.shareholderStudyAbroadEducationProvePicList.length; i++) {
        if (this.state.shareholderStudyAbroadEducationProvePicList[i].length === 0) {
          return message.error("请上传股东" + (i + 1) + "的留学证明图片", 2);
        }
      }
      let shareholderList = this.state.shareholderList;
      if (this.state.shareholderList.length === 0) {
        shareholderList.push(0);
      } else {
        shareholderList.push(shareholderList[shareholderList.length - 1] + 1);
      }
      this.setState({
        shareholderList,
      });
    });
  };
  //添加参保人
  addinsuredPerson = _ => {
    let fieldNames = [];
    for (let i = 0; i < this.state.insuredPersonList.length; i++) {
      fieldNames.push("insuredPersonName" + i, "insuredPersonTime" + i);
    }
    this.props.form.validateFields(fieldNames, (err, fieldsValue) => {
      if (err) {
        let firstErrKey = Object.keys(err)[0];
        return message.error(err[firstErrKey].errors[0].message, 2);
      }
      let insuredPersonList = this.state.insuredPersonList;
      if (this.state.insuredPersonList.length === 0) {
        insuredPersonList.push(0);
      } else {
        insuredPersonList.push(insuredPersonList[insuredPersonList.length - 1] + 1);
      }
      this.setState({
        insuredPersonList,
      });
    });
  };
  //专利
  addPatent = _ => {
    let fieldNames = [];
    for (let i = 0; i < this.state.patentList.length; i++) {
      fieldNames.push("patentName" + i, "patentTime" + i, "patentType" + i);
    }
    this.props.form.validateFields(fieldNames, (err, fieldsValue) => {
      if (err) {
        let firstErrKey = Object.keys(err)[0];
        return message.error(err[firstErrKey].errors[0].message, 2);
      }
      let patentList = this.state.patentList;
      if (this.state.patentList.length === 0) {
        patentList.push(0);
      } else {
        patentList.push(patentList[patentList.length - 1] + 1);
      }
      this.setState({
        patentList,
      });
    });
  };
  //融资
  addFinancing = _ => {
    let fieldNames = [];
    for (let i = 0; i < this.state.financingList.length; i++) {
      fieldNames.push("financingPrice" + i, "financingTime" + i, "financingSource" + i);
    }
    this.props.form.validateFields(fieldNames, (err, fieldsValue) => {
      if (err) {
        let firstErrKey = Object.keys(err)[0];
        return message.error(err[firstErrKey].errors[0].message, 2);
      }
      let financingList = this.state.financingList;
      if (this.state.financingList.length === 0) {
        financingList.push(0);
      } else {
        financingList.push(financingList[financingList.length - 1] + 1);
      }
      this.setState({
        financingList,
      });
    });
  };
  //获奖
  addawardRecord = _ => {
    let fieldNames = [];
    for (let i = 0; i < this.state.awardRecordList.length; i++) {
      fieldNames.push("awardName" + i, "awardObtainTime" + i, "organiser" + i);
    }
    this.props.form.validateFields(fieldNames, (err, fieldsValue) => {
      if (err) {
        let firstErrKey = Object.keys(err)[0];
        return message.error(err[firstErrKey].errors[0].message, 2);
      }
      let awardRecordList = this.state.awardRecordList;
      if (this.state.awardRecordList.length === 0) {
        awardRecordList.push(0);
      } else {
        awardRecordList.push(awardRecordList[awardRecordList.length - 1] + 1);
      }
      this.setState({
        awardRecordList,
      });
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    //头像上传按钮
    const {
      previewVisible,
      previewImage,
      bankAccountOpeningCertificatePic,
      businessLicensePic,
    } = this.state;
    return (
      <Spin tip="Loading..." spinning={this.state.spinning} size="large">
        <div>
          <div className={style.title}>基本信息</div>
          <Form className={style.form}>
            <Form.Item {...formItemLayout} label="公司名称">
              {getFieldDecorator("name", {
                rules: [{ required: true, message: "请输入公司名称" }],
              })(<Input placeholder="请输入公司名称" />)}
            </Form.Item>
            <Form.Item {...formItemLayout} label="公司总人数">
              {getFieldDecorator("totalNumber", {
                rules: [{ required: true, message: "请输入公司总人数" }],
              })(
                <InputNumber
                  className={style.input}
                  precision={0}
                  placeholder="请输入公司总人数"
                />,
              )}
            </Form.Item>
            <Form.Item {...formItemLayout} label="所属行业">
              {getFieldDecorator("industry", {
                rules: [{ required: true, message: "请输入所属行业" }],
              })(
                <Select placeholder="请选择所属行业">
                  {this.state.industryList.map((v, k) => {
                    return (
                      <Option key={`${k}`} value={v.value}>
                        {v.label}
                      </Option>
                    );
                  })}
                </Select>,
              )}
            </Form.Item>
            <Row>
              <Col span={10} offset={5}>
                <div className={style.panel}>
                  <Collapse>
                    <Panel
                      header={
                        <div>
                          股东列表
                          {this.state.shareholderList.length > 0 ? (
                            <Badge
                              style={{ marginLeft: "10px" }}
                              count={this.state.shareholderList.length}
                            />
                          ) : null}
                        </div>
                      }
                      key="1">
                      <div
                        className={
                          this.state.shareholderList.length > 0
                            ? style.shareholderList
                            : style.hidden
                        }>
                        {this.state.shareholderList.map((v, k) => {
                          return (
                            <div key={k} className={style.shareholderItem}>
                              <div className={style.shareholderTitle}>
                                <div className={style.theme}>股东 {v + 1}</div>
                                <div
                                  className={style.icon}
                                  onClick={_ => {
                                    let shareholderList = this.state.shareholderList;
                                    shareholderList.splice(k, 1);
                                    this.setState({
                                      shareholderList,
                                    });
                                  }}>
                                  <Icon type="close-circle" />
                                </div>
                              </div>
                              <div className={style.item}>
                                <div className={style.label}>姓名</div>
                                <div className={style.input}>
                                  {getFieldDecorator("shareholderName" + v, {
                                    rules: [
                                      {
                                        required: true,
                                        message: `请输入股东${k + 1}姓名`,
                                      },
                                    ],
                                  })(<Input className={style.input} placeholder="请输入" />)}
                                </div>
                              </div>
                              <div className={style.item}>
                                <div className={style.label}>学历</div>
                                <div className={style.input}>
                                  {getFieldDecorator("shareholderEducation" + v, {
                                    rules: [
                                      {
                                        required: true,
                                        message: `请选择股东${k + 1}学历`,
                                      },
                                    ],
                                  })(
                                    <Select placeholder="请选择学历">
                                      {this.state.educationList.map((v, k) => {
                                        return (
                                          <Option key={`${k}`} value={v.value}>
                                            {v.label}
                                          </Option>
                                        );
                                      })}
                                    </Select>,
                                  )}
                                </div>
                              </div>
                              <div className={style.item}>
                                <div className={style.label}>户籍地址</div>
                                <div className={style.input}>
                                  {getFieldDecorator("shareholderAddress" + v, {
                                    rules: [
                                      {
                                        required: true,
                                        message: `请选择股东${k + 1}户籍地址`,
                                      },
                                    ],
                                  })(<Cascader options={this.state.region} placeholder="请选择" />)}
                                </div>
                              </div>
                              <div className={style.item}>
                                <div className={style.label}>详细地址</div>
                                <div className={style.input}>
                                  {getFieldDecorator("shareholderDetail" + v, {})(
                                    <Input className={style.input} placeholder="请输入" />,
                                  )}
                                </div>
                              </div>
                              <div className={style.item}>
                                <div className={style.label}>身份证号码</div>
                                <div className={style.input}>
                                  {getFieldDecorator("shareholderIdCardNo" + v, {
                                    rules: [
                                      {
                                        required: true,
                                        message: `请输入股东${k + 1}身份证号码`,
                                      },
                                    ],
                                  })(
                                    <Input
                                      className={style.input}
                                      placeholder="请输入"
                                      onBlur={this.idCardNoValidation}
                                    />,
                                  )}
                                </div>
                              </div>
                              <div className={style.item}>
                                <div className={style.label}>是否海外留学(含港外)</div>
                                <div className={style.input}>
                                  {getFieldDecorator("shareholderHasStudyAbroad" + v, {
                                    rules: [
                                      {
                                        required: true,
                                        message: `请选择股东${k + 1}是否海外留学`,
                                      },
                                    ],
                                  })(
                                    <RadioGroup
                                      onChange={evt => {
                                        let shareholderStudyAbroadEducationProvePicList = this.state
                                          .shareholderStudyAbroadEducationProvePicList,
                                          shareholderStudyAbroadList = this.state
                                            .shareholderStudyAbroadList;
                                        if (evt.target.value) {
                                          shareholderStudyAbroadEducationProvePicList[v] = [];
                                          shareholderStudyAbroadList[v] = [0];
                                        } else {
                                          shareholderStudyAbroadList[v] = null;
                                        }
                                        this.setState({
                                          shareholderStudyAbroadEducationProvePicList,
                                          shareholderStudyAbroadList,
                                        });
                                      }}>
                                      <Radio value={false}>否</Radio>
                                      <Radio value={true}>是</Radio>
                                    </RadioGroup>,
                                  )}
                                </div>
                              </div>
                              {this.state.shareholderStudyAbroadList[v] ? (
                                <div className={style.studyAbroadInfo}>
                                  <div className={style.item}>
                                    <div className={style.label}>学校姓名</div>
                                    <div className={style.input}>
                                      {getFieldDecorator("shareholderStudyAbroadSchoolName" + v, {
                                        rules: [
                                          {
                                            required: true,
                                            message: `请输入股东${k + 1}的学校姓名`,
                                          },
                                        ],
                                      })(<Input className={style.input} placeholder="请输入" />)}
                                    </div>
                                  </div>
                                  <div className={style.item}>
                                    <div className={style.label}>学历</div>
                                    <div className={style.input}>
                                      {getFieldDecorator("shareholderStudyAbroadEducation" + v, {
                                        rules: [
                                          {
                                            required: true,
                                            message: `请选择股东${k + 1}海外留学的学历`,
                                          },
                                        ],
                                      })(
                                        <Select placeholder="请选择学历">
                                          {this.state.educationList.map((v, k) => {
                                            return (
                                              <Option key={`${k}`} value={v.value}>
                                                {v.label}
                                              </Option>
                                            );
                                          })}
                                        </Select>,
                                      )}
                                    </div>
                                  </div>
                                  <div className={style.item}>
                                    <div className={style.label}>学历证明图片</div>
                                    <div className={style.input}>
                                      {getFieldDecorator(
                                        "shareholderStudyAbroadEducationProvePic" + v,
                                        {},
                                      )(
                                        <div className="clearfix">
                                          <Upload
                                            action={getUploadImgUrl()}
                                            listType="picture-card"
                                            fileList={
                                              this.state
                                                .shareholderStudyAbroadEducationProvePicList[v]
                                            }
                                            onPreview={this.handlePreview}
                                            onChange={files =>
                                              this.shareholderStudyAbroadEducationProvePicChange(
                                                files,
                                                v,
                                              )
                                            }>
                                            {this.state.shareholderStudyAbroadEducationProvePicList[
                                              v
                                            ].length > 0
                                              ? null
                                              : uploadButton}
                                          </Upload>
                                          <Modal
                                            visible={previewVisible}
                                            footer={null}
                                            onCancel={this.handleCancel}>
                                            <img
                                              alt="example"
                                              style={{ width: "100%" }}
                                              src={previewImage}
                                            />
                                          </Modal>
                                        </div>,
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          );
                        })}
                      </div>
                      <Empty
                        style={{ marginBottom: 15 }}
                        className={this.state.shareholderList.length > 0 ? style.hidden : ""}
                      />
                      <div className={style.shareholderAdd}>
                        <Icon type="plus" onClick={this.addShareholder} />
                      </div>
                    </Panel>
                    <Panel
                      header={
                        <div>
                          参保人列表
                          {this.state.insuredPersonList.length > 0 ? (
                            <Badge
                              style={{ marginLeft: "10px" }}
                              count={this.state.insuredPersonList.length}
                            />
                          ) : null}
                        </div>
                      }
                      key="2">
                      <div
                        className={
                          this.state.insuredPersonList.length > 0
                            ? style.shareholderList
                            : style.hidden
                        }>
                        {this.state.insuredPersonList.map((v, k) => {
                          return (
                            <div key={k} className={style.shareholderItem}>
                              <div className={style.shareholderTitle}>
                                <div className={style.theme}>参保人 {v + 1}</div>
                                <div
                                  className={style.icon}
                                  onClick={_ => {
                                    let insuredPersonList = this.state.insuredPersonList;
                                    insuredPersonList.splice(k, 1);
                                    this.setState({
                                      insuredPersonList,
                                    });
                                  }}>
                                  <Icon type="close-circle" />
                                </div>
                              </div>
                              <div className={style.item}>
                                <div className={style.label}>姓名</div>
                                <div className={style.input}>
                                  {getFieldDecorator("insuredPersonName" + v, {
                                    rules: [
                                      {
                                        required: true,
                                        message: `请输入参保人${k + 1}姓名`,
                                      },
                                    ],
                                  })(<Input className={style.input} placeholder="请输入" />)}
                                </div>
                              </div>
                              <div className={style.item}>
                                <div className={style.label}>开始缴纳时间</div>
                                <div className={style.input}>
                                  {getFieldDecorator("insuredPersonTime" + v, {
                                    rules: [
                                      {
                                        required: true,
                                        message: `请选择参保人${k + 1}开始缴纳时间`,
                                      },
                                    ],
                                  })(<DatePicker className={style.input} placeholder="请选择" />)}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <Empty
                        style={{ marginBottom: 15 }}
                        className={this.state.insuredPersonList.length > 0 ? style.hidden : ""}
                      />
                      <div className={style.shareholderAdd}>
                        <Icon type="plus" onClick={this.addinsuredPerson} />
                      </div>
                    </Panel>
                    <Panel
                      header={
                        <div>
                          专利列表
                          {this.state.patentList.length > 0 ? (
                            <Badge
                              style={{ marginLeft: "10px" }}
                              count={this.state.patentList.length}
                            />
                          ) : null}
                        </div>
                      }
                      key="3">
                      <div
                        className={
                          this.state.patentList.length > 0 ? style.shareholderList : style.hidden
                        }>
                        {this.state.patentList.map((v, k) => {
                          return (
                            <div key={k} className={style.shareholderItem}>
                              <div className={style.shareholderTitle}>
                                <div className={style.theme}>专利人 {v + 1}</div>
                                <div
                                  className={style.icon}
                                  onClick={_ => {
                                    let patentList = this.state.patentList;
                                    patentList.splice(k, 1);
                                    this.setState({
                                      patentList,
                                    });
                                  }}>
                                  <Icon type="close-circle" />
                                </div>
                              </div>
                              <div className={style.item}>
                                <div className={style.label}>专利名称</div>
                                <div className={style.input}>
                                  {getFieldDecorator("patentName" + v, {
                                    rules: [
                                      {
                                        required: true,
                                        message: `请输入专利${k + 1}姓名`,
                                      },
                                    ],
                                  })(<Input className={style.input} placeholder="请输入" />)}
                                </div>
                              </div>
                              <div className={style.item}>
                                <div className={style.label}>获得时间</div>
                                <div className={style.input}>
                                  {getFieldDecorator("patentTime" + v, {
                                    rules: [
                                      {
                                        required: true,
                                        message: `请选择专利${k + 1}获得时间`,
                                      },
                                    ],
                                  })(<DatePicker className={style.input} placeholder="请选择" />)}
                                </div>
                              </div>
                              <div className={style.item}>
                                <div className={style.label}>专利类型</div>
                                <div className={style.input}>
                                  {getFieldDecorator("patentType" + v, {
                                    rules: [
                                      {
                                        required: true,
                                        message: `请选择专利${k + 1}专利类型`,
                                      },
                                    ],
                                  })(
                                    <Select placeholder="请选择">
                                      {this.state.patentTypeList.map((v, k) => {
                                        return (
                                          <Option key={`${k}`} value={v.value}>
                                            {v.label}
                                          </Option>
                                        );
                                      })}
                                    </Select>,
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <Empty
                        style={{ marginBottom: 15 }}
                        className={this.state.patentList.length > 0 ? style.hidden : ""}
                      />
                      <div className={style.shareholderAdd}>
                        <Icon type="plus" onClick={this.addPatent} />
                      </div>
                    </Panel>
                    <Panel
                      header={
                        <div>
                          融资列表
                          {this.state.financingList.length > 0 ? (
                            <Badge
                              style={{ marginLeft: "10px" }}
                              count={this.state.financingList.length}
                            />
                          ) : null}
                        </div>
                      }
                      key="4">
                      <div
                        className={
                          this.state.financingList.length > 0 ? style.shareholderList : style.hidden
                        }>
                        {this.state.financingList.map((v, k) => {
                          return (
                            <div key={k} className={style.shareholderItem}>
                              <div className={style.shareholderTitle}>
                                <div className={style.theme}>融资 {v + 1}</div>
                                <div
                                  className={style.icon}
                                  onClick={_ => {
                                    let financingList = this.state.financingList;
                                    financingList.splice(k, 1);
                                    this.setState({
                                      financingList,
                                    });
                                  }}>
                                  <Icon type="close-circle" />
                                </div>
                              </div>
                              <div className={style.item}>
                                <div className={style.label}>融资金额(元)</div>
                                <div className={style.input}>
                                  {getFieldDecorator("financingPrice" + v, {
                                    rules: [
                                      {
                                        required: true,
                                        message: `请输入融资${k + 1}金额`,
                                      },
                                    ],
                                  })(
                                    <InputNumber
                                      precision={2}
                                      className={style.input}
                                      placeholder="请输入"
                                    />,
                                  )}
                                </div>
                              </div>
                              <div className={style.item}>
                                <div className={style.label}>融资时间</div>
                                <div className={style.input}>
                                  {getFieldDecorator("financingTime" + v, {
                                    rules: [
                                      {
                                        required: true,
                                        message: `请选择融资${k + 1}融资时间`,
                                      },
                                    ],
                                  })(<DatePicker className={style.input} placeholder="请选择" />)}
                                </div>
                              </div>
                              <div className={style.item}>
                                <div className={style.label}>融资来源</div>
                                <div className={style.input}>
                                  {getFieldDecorator("financingSource" + v, {
                                    rules: [
                                      {
                                        required: true,
                                        message: `请选择融资${k + 1}来源`,
                                      },
                                    ],
                                  })(<Input className={style.input} placeholder="请输入" />)}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <Empty
                        style={{ marginBottom: 15 }}
                        className={this.state.financingList.length > 0 ? style.hidden : ""}
                      />
                      <div className={style.shareholderAdd}>
                        <Icon type="plus" onClick={this.addFinancing} />
                      </div>
                    </Panel>
                    <Panel
                      header={
                        <div>
                          {" "}
                          获奖列表
                          {this.state.awardRecordList.length > 0 ? (
                            <Badge
                              style={{ marginLeft: "10px" }}
                              count={this.state.awardRecordList.length}
                            />
                          ) : null}
                        </div>
                      }
                      key="5">
                      <div
                        className={
                          this.state.awardRecordList.length > 0
                            ? style.shareholderList
                            : style.hidden
                        }>
                        {this.state.awardRecordList.map((v, k) => {
                          return (
                            <div key={k} className={style.shareholderItem}>
                              <div className={style.shareholderTitle}>
                                <div className={style.theme}>获奖 {v + 1}</div>
                                <div
                                  className={style.icon}
                                  onClick={_ => {
                                    let awardRecordList = this.state.awardRecordList;
                                    awardRecordList.splice(k, 1);
                                    this.setState({
                                      awardRecordList,
                                    });
                                  }}>
                                  <Icon type="close-circle" />
                                </div>
                              </div>
                              <div className={style.item}>
                                <div className={style.label}>获奖名称</div>
                                <div className={style.input}>
                                  {getFieldDecorator("awardName" + v, {
                                    rules: [
                                      {
                                        required: true,
                                        message: `请输入获奖${k + 1}获奖名称`,
                                      },
                                    ],
                                  })(<Input className={style.input} placeholder="请输入" />)}
                                </div>
                              </div>
                              <div className={style.item}>
                                <div className={style.label}>获奖时间</div>
                                <div className={style.input}>
                                  {getFieldDecorator("awardObtainTime" + v, {
                                    rules: [
                                      {
                                        required: true,
                                        message: `请选择获奖${k + 1}获奖名称`,
                                      },
                                    ],
                                  })(<DatePicker className={style.input} placeholder="请选择" />)}
                                </div>
                              </div>
                              <div className={style.item}>
                                <div className={style.label}>主办方</div>
                                <div className={style.input}>
                                  {getFieldDecorator("organiser" + v, {
                                    rules: [
                                      {
                                        required: true,
                                        message: `请输入获奖${k + 1}主办方名称`,
                                      },
                                    ],
                                  })(<Input className={style.input} placeholder="请输入" />)}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <Empty
                        style={{ marginBottom: 15 }}
                        className={this.state.awardRecordList.length > 0 ? style.hidden : ""}
                      />
                      <div className={style.shareholderAdd}>
                        <Icon type="plus" onClick={this.addawardRecord} />
                      </div>
                    </Panel>
                  </Collapse>
                </div>
              </Col>
            </Row>

            <Form.Item {...formItemLayout} label="营业执照证件照">
              {getFieldDecorator("businessLicensePic", {})(
                <div className="clearfix">
                  <Upload
                    action={getUploadImgUrl()}
                    listType="picture-card"
                    fileList={businessLicensePic}
                    onPreview={this.handlePreview}
                    onChange={this.businessLicensePicChange}>
                    {businessLicensePic.length > 0 ? null : uploadButton}
                  </Upload>
                  <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                    <img alt="example" style={{ width: "100%" }} src={previewImage} />
                  </Modal>
                </div>,
              )}
            </Form.Item>
            <Form.Item {...formItemLayout} label="银行开户正面图片">
              {getFieldDecorator("bankAccountOpeningCertificatePic", {})(
                <div className="clearfix">
                  <Upload
                    action={getUploadImgUrl()}
                    listType="picture-card"
                    fileList={bankAccountOpeningCertificatePic}
                    onPreview={this.handlePreview}
                    onChange={this.bankAccountOpeningCertificatePicChange}>
                    {bankAccountOpeningCertificatePic.length > 0 ? null : uploadButton}
                  </Upload>
                  <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                    <img alt="example" style={{ width: "100%" }} src={previewImage} />
                  </Modal>
                </div>,
              )}
            </Form.Item>
            <Form.Item {...formItemLayout} label="主营业务">
              {getFieldDecorator("mainBusiness", {})(
                <TextArea rows={4} placeholder="请输入主营业务" />,
              )}
            </Form.Item>
            <Form.Item className={style.control}>
              {/* <Button className={style.btn} type="primary" onClick={this.updateUserInfo}>更新基本信息</Button> */}
              <NavLink to={`/admin/company/paymentRecordList?id=${this.state.companyId}`}>
                <Button className={style.btn} type="primary">
                  查看缴费记录
                </Button>
              </NavLink>
              <NavLink to={`/admin/company/addPaymentRecord?id=${this.state.companyId}`}>
                <Button className={style.btn} type="primary">
                  添加缴费记录
                </Button>
              </NavLink>
            </Form.Item>
          </Form>
        </div>
      </Spin>
    );
  }
}
export default Form.create()(BaseInfo);
