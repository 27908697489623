import React from "react";
import {
  Form, Input, message, Upload, Icon, Modal, Spin,
} from 'antd';
import qs from 'qs';
import { getUploadImgUrl } from '../../../services/api';
import userApi from "../../../services/user";
import gSass from '../../../utils/sass';
import { BASE_URL, } from '../../../config/prod';
import { IdentityCodeValid } from "../../../utils/utils";

const style = gSass.admin.settings.base;
//地址
const uploadButton = (
  <div>
    <Icon type="plus" />
    <div className="ant-upload-text">上传图片</div>
  </div>
);
const formItemLayout = {
  labelCol: {
    lg: {
      span: 24,
      offset: 0,
    },
  },
  wrapperCol: {
    lg: {
      span: 24,
      offset: 0,
    },
  },
};
class RealNameAuthentication extends React.Component {
  constructor(props) {
    super(props);
    let { location: { search } } = props;
    const { id } = qs.parse(search, { ignoreQueryPrefix: true, })
    this.state = {
      id,
      spinning: true,
      previewVisible: false,
      previewImage: '',
      idCardNoValidation: true,
      //date
      idCardFirstPic: [],
      idCardSecondPic: [],
      userInfo: {},
    }
  }
  componentDidMount() {
    this.init();
  }
  init = async _ => {
    const { setFieldsValue } = this.props.form;
    try {
      const { data: {
        idCardFirstPicModel,
        idCardSecondPicModel,
        idCardName,
        idCardNo,
      } } = await userApi.adminGetUserIdRealNameDetail({ id: this.state.id });
      setFieldsValue({
        name: idCardName,
        idCardNo,
      })
      this.setState({
        idCardFirstPic: [{
          url: BASE_URL + idCardFirstPicModel.url,
          uid: idCardFirstPicModel.id
        }],
        idCardSecondPic: [{
          url: BASE_URL + idCardSecondPicModel.url,
          uid: idCardSecondPicModel.id
        }],
        spinning: false,
      })
    } catch (e) {
      this.setState({
        spinning: false,
      })
    }
  }
  //提交
  updateUserIfno = evt => {
    evt.preventDefault();
    this.props.form.validateFields((err, fields) => {
      if (err) {
        message.error(err[Object.keys(err)[0]].errors[0].message, 1);
        return;
      }
      if (!this.state.idCardNoValidation) {
        message.error("身份证号码不正确", 1);
        return;
      }
      if (this.state.idCardFirstPic.length === 0 || !fields.idCardFirstPic) {
        message.error("请上传身份证正面照", 1);
        return;
      }
      if (this.state.idCardSecondPic.length === 0 || !fields.idCardSecondPic) {
        message.error("请上传身份证反面照", 1);
        return;
      }
      let name = fields.name,
        idCardNo = fields.idCardNo || "",
        idCardFirstPic = this.state.idCardFirstPic[0].url || fields.idCardFirstPic,
        idCardSecondPic = this.state.idCardSecondPic[0].url || fields.idCardSecondPic;
      console.log({ name, idCardNo, idCardFirstPic, idCardSecondPic, });

      userApi.editRealNameAuthentication({ name, idCardNo, idCardFirstPic, idCardSecondPic, }).then(json => {
        message.success('修改成功', 1);
      }).catch(err => {
        message.error('修改失败,失败原因: ' + err.msg, 1);
        console.log(err);
      })
    });
  }
  //上传图片
  idCardFirstPicChange = ({ fileList }) => this.setState({ idCardFirstPic: fileList })
  idCardSecondPicChange = ({ fileList }) => this.setState({ idCardSecondPic: fileList })
  handlePreview = file => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true,
    });
  }
  handleCancel = _ => this.setState({ previewVisible: false })
  //地址变化出现详细地址
  changeAddress = addressArr => {
    this.setState({
      address: addressArr,
    })
  }
  //身份证号码验证
  idCardNoValidation = evt => {
    let val = evt.target.value;
    IdentityCodeValid(val).then(json => {
      this.setState({
        idCardNoValidation: json.pass,
      })
      if (!json.pass) {
        message.error("身份证号码错误: " + json.msg, 1)
      }
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    //头像上传按钮
    const { previewVisible, previewImage, idCardSecondPic, idCardFirstPic } = this.state;
    return (
      <Spin tip="Loading..." spinning={this.state.spinning} size="large">
        <div>
          <div className={style.title}>实名认证</div>
          <Form className={style.form}>
            <Form.Item {...formItemLayout} label="姓名" >
              {getFieldDecorator('name', {
              })(<Input placeholder="请输入姓名" />)}
            </Form.Item>
            <Form.Item {...formItemLayout} label="身份证号码"  >
              {getFieldDecorator('idCardNo', {
                rules: [{ required: true, message: '请输入身份证号码' }],
              })(
                <Input placeholder="请输入身份证号码" onBlur={this.idCardNoValidation} />)}
            </Form.Item>
            <Form.Item {...formItemLayout} label="身份证正面">
              {getFieldDecorator('idCardFirstPic', {})(
                <div className="clearfix">
                  <Upload action={getUploadImgUrl()} listType="picture-card" fileList={idCardFirstPic}
                    onPreview={this.handlePreview} onChange={this.idCardFirstPicChange}>
                    {idCardFirstPic.length > 0 ? null : uploadButton}
                  </Upload>
                  <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                    <img alt="example" style={{ width: '100%' }} src={previewImage} />
                  </Modal>
                </div>
              )}
            </Form.Item>
            <Form.Item {...formItemLayout} label="身份证反面">
              {getFieldDecorator('idCardSecondPic', {})(
                <div className="clearfix">
                  <Upload action={getUploadImgUrl()} listType="picture-card" fileList={idCardSecondPic}
                    onPreview={this.handlePreview} onChange={this.idCardSecondPicChange}>
                    {idCardSecondPic.length > 0 ? null : uploadButton}
                  </Upload>
                  <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                    <img alt="example" style={{ width: '100%' }} src={previewImage} />
                  </Modal>
                </div>
              )}
            </Form.Item>
            {/* <Form.Item wrapperCol={{ lg: { span: 7, offset: 0, }, }}>
              <Button className={style.btn} type="primary" onClick={this.updateUserIfno}>更新基本信息</Button>
            </Form.Item> */}
          </Form>
        </div>
      </Spin >
    );
  }
}
export default Form.create()(RealNameAuthentication);
