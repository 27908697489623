import { bget, bpost } from './api';

export const PAYMENT_STATUS = {
  FALSE: 0,
  TRUE: 1,
};
export const PAYMENT_STATUS_ZH = {
  [PAYMENT_STATUS.FALSE]: "未缴纳",
  [PAYMENT_STATUS.TRUE]: "已缴纳",
};

export async function applyMealCard({ file, idCardName, idCardNo, contactInfo, idGender }) {
  return bpost({
    url: '/apply/mealCard',
    data: {
      file,
      idCardName,
      idCardNo,
      contactInfo,
      idGender
    }
  })
}
// 后台编辑
export async function edit({
  applyId, admin = true, name, phone, idCardNo, gender, file
}) {
  return bpost({
    url: '/editMealCard',
    data: {
      applyId, admin, name, phone, idCardNo, gender, file
    }
  })
}

export async function mealCardDetail(id) {
  return bget({
    url: '/mealCard/detail',
    query: {
      id,
    }
  })
}

export async function setValidTime({ id, paymentStatus, cardNumber, validTime, }) {
  return bpost({
    url: '/mealCard/apply',
    data: {
      id,
      paymentStatus,
      cardNumber,
      validTime,
    }
  })
}


export default {
  applyMealCard,
  mealCardDetail,
  setValidTime,
  edit,
}
