import React, { Component, } from 'react';

class Page404 extends Component {
  render() {
    return (
      <div>
        404 Not Found
      </div>
    )
  }
}
export default Page404;
