import React, { Component, } from 'react';
import { Button, Breadcrumb, Form, DatePicker, } from 'antd';
import { Link, } from "react-router-dom";
import RoomRentDetail from "./RoomRentDetail";
import moment from 'moment';
import conferenceRoomRentApi from "../../../services/conferenceRoomRent";
import gSass from '../../../utils/sass';

const style = gSass.admin.conferenceRoomRent.list;
const { RangePicker } = DatePicker;
class RoomList extends Component {
  constructor(props) {
    super(props)
    const { computedMatch: { params: { roomNo } } } = props;
    this.conferenceRoomRentList = [
      {
        label: "207",
        value: "207",
      },
      {
        label: "208",
        value: "208",
      },
      {
        label: "307",
        value: "307",
      },
      {
        label: "308",
        value: "308",
      },
      {
        label: "309",
        value: "309",
      },
      {
        label: "407",
        value: "407",
      },
      {
        label: "408",
        value: "408",
      },
      {
        label: "409",
        value: "409",
      },
    ];
    this.state = {
      currConferenceRoomRentNo: roomNo,
      chooseDateSection: [
        moment(),
        moment().add(7, 'day'),
      ],
      userRentList: [],
    };
  }
  componentDidMount() {
    this.init();
  }
  init = async _ => {
    this.buildUserRentList();
  }
  buildUserRentList = async () => {
    // const { data: { list: userRentList } } = await this.getUserRentList()
    const { data: { list: userRentList } } = await
      conferenceRoomRentApi.getUserRentList({
        roomNo: this.state.currConferenceRoomRentNo,
        beginDate: this.state.chooseDateSection[0].format('YYYY-MM-DD 00:00:00'),
        endDate: this.state.chooseDateSection[1].format('YYYY-MM-DD 00:00:00'),
      })
    console.log(userRentList)
    this.setState({
      userRentList,
    })
  }
  getUserRentList = async () => {
    console.log('getUserRentList')
    return {
      data: {
        list: [
          {
            date: '2019-03-07',
            useList: [
              {
                rentUserInfo: {
                  idCardName: '吴二伟',
                  idCardNo: '321023111900001',
                  companyName: '吴大伟网络',
                  rentDateSection: [
                    moment('2019-03-07 10:10'),
                    moment('2019-03-07 11:02'),
                  ],
                },
              },
              {
                rentUserInfo: {
                  idCardName: 'buffge',
                  idCardNo: '321023111900001',
                  companyName: '邻米网络',
                  rentDateSection: [
                    moment('2019-03-07 11:10'),
                    moment('2019-03-07 15:01'),
                  ],
                },
              },
              {
                rentUserInfo: {
                  idCardName: '吴三伟',
                  idCardNo: '321023111900001',
                  companyName: '吴三伟网络',
                  rentDateSection: [
                    moment('2019-03-07 15:10'),
                    moment('2019-03-07 16:02'),
                  ],
                },
              },
              {
                rentUserInfo: {
                  idCardName: '吴三伟',
                  idCardNo: '321023111900001',
                  companyName: '吴三伟网络',
                  rentDateSection: [
                    moment('2019-03-07 16:10'),
                    moment('2019-03-07 18:02'),
                  ],
                },
              },
            ],
          }
        ]
      }
    };

  }
  render() {
    return (
      <div className={style.roomList}>
        <Breadcrumb className={style.title}>
          <Breadcrumb.Item><Link to="/admin">首页</Link></Breadcrumb.Item>
          {/* <Breadcrumb.Item><Link to="/admin/company/list">会议室管理</Link></Breadcrumb.Item> */}
          <Breadcrumb.Item>会议室管理</Breadcrumb.Item>
        </Breadcrumb>
        <div className={style.filters}>
          <div className={style.item}>
            {this.conferenceRoomRentList.map((v, k) => {
              return (<Button key={k} className={style.filterBtn}
                type={this.state.currConferenceRoomRentNo === v.value ? "primary" : "default"}
                onClick={_ => {
                  this.setState({
                    currConferenceRoomRentNo: v.value,
                    chooseDateSection: [moment(), moment().add(7, 'days')],
                  }, this.buildUserRentList)
                }}><Link to={`/admin/conferenceRoomRent/list/${v.value}`}>{v.label}</Link></Button>);
            })}
          </div>
          <RangePicker className={style.datePicker} value={this.state.chooseDateSection}
            format={"YYYY-MM-DD HH:mm"}
            onChange={chooseDateSection => {
              console.log(chooseDateSection)
              this.setState({
                chooseDateSection,
              }, this.buildUserRentList)
            }}
          />
          <Button type="danger" className={style.chooseDateSection} onClick={() => {

          }}>确定</Button>
        </div>
        <RoomRentDetail roomNo={this.state.currConferenceRoomRentNo}
          chooseDateSection={[moment(this.state.chooseDateSection[0].format()),
          moment(this.state.chooseDateSection[1].format())]}
          userRentList={this.state.userRentList}
        />
      </div>
    );
  }
}
export default Form.create()(RoomList);
