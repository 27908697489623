import React, { Component } from 'react';
import { Layout, } from 'antd';
import Sidebar from '../../components/common/Sidebar';
import Header from '../../components/common/Header';
import Content from '../../components/common/Content';
import "../../assets/sass/global.scss";
// import Login from "../../pages/admin/user/Login";
// import Register from "../../pages/index/user/Register";
// import ForgetPassword from "../../pages/index/user/ForgetPassword";
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sideBarOpenKeys: [],
      sideBarIsCollasped: false,
    };
  }
  changeCollaspe = sideBarIsCollasped => {
    this.setState({
      sideBarIsCollasped,
    })
  }
  render() {
    return (
      <Layout style={{ minHeight: '100vh' }}>
        <Sidebar collasped={this.state.sideBarIsCollasped} sideBarOpenKeys={this.state.sideBarOpenKeys} changeCollaspe={this.changeCollaspe} />
        <Layout>
          <Header collasped={this.state.sideBarIsCollasped} changeCollaspe={this.changeCollaspe} />
          <Content />
        </Layout>
      </Layout>
    )
  }
}
export default Index;
