import React from "react";
import {
  List,
  TextareaItem,
  ActivityIndicator,
  Icon,
  InputItem,
  Button,
  Flex,
  Toast,
  Checkbox,
  Modal,
  NavBar,
} from "antd-mobile";
import { Form } from "antd";
import { withRouter, Link } from "react-router-dom";
import gStyle from "../../../../utils/sass";
import recruitApi, { STATION, STATION_ZH } from "../../../../services/recruit";
import ApplyWithSendMsg from "../../../../components/index/user/apply/ApplyWithSendMsg";
import applyApi, { APPLY_STATUS } from "../../../../services/apply";

const style = gStyle.index.user.apply.recruit;
const Item = List.Item;
const CheckboxItem = Checkbox.CheckboxItem;

class Recruit extends ApplyWithSendMsg {
  isShowDetail = false;
  constructor(props) {
    super(props);
    this.isShowDetail = props.isShowDetail || false;
    this.applyId = props.applyId;
    this.state = {
      isAnimating: true,
      isModalActive: false,
      modalBody: "",
      modalFooter: [],
      station: [],
      applyDetail: {
        id: 1,
        steps: [],
        stationList: [],
      },
      isAllowEdit: false,
    };
  }
  componentDidMount() {
    this.init();
  }

  init = async _ => {
    let station = Object.values(STATION).map((v, k) => ({
      value: v,
      label: STATION_ZH[v],
    }));
    this.setState({
      isAnimating: false,
      station,
    });
    /**
     *申请详情
     */
    if (this.isShowDetail) {
      const { setFieldsValue } = this.props.form;
      try {
        let {
          data: { detail: applyDetail },
        } = await applyApi.getApplyDetail({ id: this.applyId });
        applyDetail.id = applyDetail.apply_uid;
        let isAllowEdit = false;
        if (applyDetail.status === APPLY_STATUS['process']) {
          isAllowEdit = true;
        }
        this.setState({
          applyDetail,
          isAllowEdit,
        });
        for (let stationIdx of this.state.applyDetail.stationList) {
          setFieldsValue({
            ["station" + stationIdx]: true,
          });
        }
        setFieldsValue({
          contactInfo: applyDetail.info.contactInfo,
          recruitPersonNumber: applyDetail.info.recruitPersonNumber,
          minSalary: (applyDetail.info.minSalary / 100).toFixed(2),
          maxSalary: (applyDetail.info.maxSalary / 100).toFixed(2),
          description: applyDetail.info.description,
        });
      } catch (err) {
        console.log(err);
      }
    }
  };
  apply = _ => {
    this.props.form.validateFields(async (err, fieldsValue) => {
      if (err) {
        let firstErrKey = Object.keys(err)[0];
        if (firstErrKey !== 'msg') {
          return Toast.fail(err[firstErrKey].errors[0].message, 2);
        }
      }
      let fields = this.props.form.getFieldsValue(),
        station = [];
      for (let v of this.state.station) {
        if (fields["station" + v.value]) {
          station.push(parseInt(v.value));
        }
      }
      if (this.state.isAllowEdit) {
        recruitApi
        .edit({
          applyId: this.applyId,
          admin: false,
          contactInfo: fields.contactInfo,
          recruitPersonNumber:  parseInt(fields.recruitPersonNumber),
          maxSalary: parseInt(fields.maxSalary) * 100,
          minSalary:  parseInt(fields.minSalary) * 100,
          stationList:station,
          description: fields.description,
        })
        .then(() => {
          this.setState({
            isAllowEdit: false,
          })
          Toast.success("修改成功", 1);
        })
        .catch(err => {
          Toast.fail("修改失败,失败原因: " + err.msg, 1);
        });
      } else {
        try {
          //todo 联系方式改为了手机号码  删除了申请人 公司名称
          await recruitApi.applyRecruit({
            contactInfo: fields.contactInfo,
            recruitPersonNumber: parseInt(fields.recruitPersonNumber),
            salary: {
              min: parseInt(fields.minSalary) * 100,
              max: parseInt(fields.maxSalary) * 100,
            },
            station,
            description: fields.description || "",
          });
          this.setState({
            isModalActive: true,
            modalBody: <div>提交成功,请等待审核</div>,
            modalFooter: [{ text: "确定", onPress: () => this.props.history.push("/") }],
          });
        } catch (err) {
          this.setState({
            isModalActive: true,
            modalBody: <div>提交失败,错误信息:{err.msg}</div>,
            modalFooter: [{ text: "确定", onPress: () => this.setState({ isModalActive: false }) }],
          });
        }
      }
    });
  };
  resetForm = _ => {
    this.props.form.resetFields();
  };

  getApplyDetailDom = _ => {
    const { getFieldDecorator, getFieldError, getFieldProps } = this.props.form;
    return (
      <form className={style.form}>
        <List>
          {getFieldDecorator("contactInfo", {
            rules: [{ required: true, max: 11, message: "请输入手机号码" }],
          })(
            <InputItem
              className={style.input}
              placeholder="请输入"
              error={!!getFieldError("contactInfo")}
              type="digit"
              extra={<Icon size="md" color="#ccc" type="right" />}>
              手机号码
            </InputItem>,
          )}
          {getFieldDecorator("recruitPersonNumber", {
            rules: [{ required: true, message: "请输入招聘人数" }],
          })(
            <InputItem
              clear
              className={style.input}
              placeholder="请输入"
              error={!!getFieldError("recruitPersonNumber")}
              type="digit"
              extra={<Icon size="md" color="#ccc" type="right" />}>
              招聘人数
            </InputItem>,
          )}
          <Item>
            <Flex className={style.salary}>
              <div>薪资 (元)</div>
              {getFieldDecorator("minSalary", {
                rules: [{ required: true, message: "请输入最低薪资" }],
              })(
                <InputItem
                  clear
                  className={style.input}
                  placeholder="最低金额"
                  error={!!getFieldError("minSalary")}
                  type="digit"
                />,
              )}
              <div> - </div>
              {getFieldDecorator("maxSalary", {
                rules: [{ required: true, message: "请输入最高薪资" }],
              })(
                <InputItem
                  clear
                  className={style.input}
                  placeholder="最高金额"
                  error={!!getFieldError("maxSalary")}
                  type="digit"
                />,
              )}
            </Flex>
          </Item>
          <Item>
            <div>招聘岗位(可多选)</div>
            {this.state.station.map((v, k) => {
              return (
                <div key={`${k}`}>
                  {getFieldDecorator("station" + v.value, {
                    valuePropName: "checked",
                  })(<CheckboxItem key={v.value}>{v.label}</CheckboxItem>)}
                </div>
              );
            })}
          </Item>
          <Item>
            <div>岗位描述</div>

            <TextareaItem
              {...getFieldProps("description", {})}
              rows={3}
              count={300}
              className={style.description}
              placeholder="请输入岗位描述"
            />
          </Item>
        </List>
        {
          this.state.isAllowEdit ? (
            <div className={style.operation}>
              <Button size="small" type="primary" className={style.btn} inline onClick={this.apply}>修改提交</Button>
            </div>
          ) : ''
        }
        {this.getChatInfo()}
      </form>
    );
  };
  render() {
    return this.isShowDetail ? (
      this.getApplyDetailDom()
    ) : (
        <div className={style.recruitment}>
          <ActivityIndicator
            animating={this.state.isAnimating}
            className={style.loading}
            text="loading..."
          />
          <NavBar
            className={this.isShowDetail ? style.hidden : null}
            mode="light"
            icon={
              <Link to="/">
                <Icon type="left" />
              </Link>
            }>
            招聘申请
        </NavBar>
          <Modal
            visible={this.state.isModalActive}
            transparent
            maskClosable={false}
            title="提示"
            footer={this.state.modalFooter}>
            {this.state.modalBody}
          </Modal>
          {this.getApplyDetailDom()}
          <div className={style.operation}>
            <Button size="small" className={style.btn} inline onClick={this.resetForm}>
              重置
          </Button>
            <Button size="small" type="primary" className={style.btn} inline onClick={this.apply}>
              提交
          </Button>
          </div>

        </div>
      );
  }
}
export default withRouter(Form.create()(Recruit));
