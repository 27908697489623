import React from "react";
import project from "../../../services/project";
import gSass from "../../../utils/sass";
import moment from "moment";
import qs from "qs";
import {
  Form,
  Input,
  message,
  Icon,
  Spin,
  Select,
  InputNumber,
  Collapse,
  Badge,
  Row,
  Col,
  DatePicker,
  Empty,
} from "antd";
import { TYPE as PATENT_TYPE, TYPE_ZH as PATENT_TYPE_ZH } from "../../../services/patent";
import { EDUCATION, EDUCATION_ZH } from "../../../services/apply";
import companyApi from "../../../services/company";
import projectApi from "../../../services/project";
import { getRegion, IdentityCodeValid } from "../../../utils/utils";

const style = gSass.admin.project.base;
const Option = Select.Option;
const { TextArea } = Input;
const Panel = Collapse.Panel;

const formItemLayout = {
  labelCol: {
    lg: {
      span: 4,
      offset: 1,
    },
  },
  wrapperCol: {
    lg: {
      span: 10,
      offset: 0,
    },
  },
};
class BaseInfo extends React.Component {
  constructor(props) {
    super(props);
    let { id } = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    id = parseInt(id);
    this.state = {
      projectId: id,
      spinning: true,
      industryList: [],
      patentTypeList: [],
      patentList: [],
      financingList: [],
      userInfo: {},
    };
  }
  componentDidMount() {
    this.init();
  }
  init = async _ => {
    try {
      let {
        data: { list: industryList },
      } = await companyApi.getIndustryList({ page: -1, limit: -1 });
      industryList = industryList.map(v => ({
        value: v.id,
        label: v.name,
      }));
      const patentTypeList = Object.values(PATENT_TYPE).map(v => ({
        value: v,
        label: PATENT_TYPE_ZH[v],
      }));
      //获取学历信息
      let educationList = [
        Object.values(EDUCATION).map((v, k) => ({
          value: v,
          label: EDUCATION_ZH[v],
        })),
      ];
      let {
        data: { detail },
      } = await projectApi.projectDetail({ id: this.state.projectId });
      let region = await getRegion();
      let shareholderList = [],
        insuredPersonList = [],
        patentList = [],
        financingList = [];
      for (let i = 0; i < detail.patentList.length; i++) {
        patentList.push(i);
      }
      for (let i = 0; i < detail.financingRecordList.length; i++) {
        financingList.push(i);
      }
      this.setState({
        spinning: false,
        industryList,
        patentTypeList,
        patentList,
        educationList,
        region,
        userInfo: {
          patentList: detail.patentList,
          financingList: detail.financingList,
        },
        shareholderList,
        insuredPersonList,
        financingList,
      });
      this.props.form.setFieldsValue({
        name: detail.name,
        totalNumber: detail.total_number,
        industry: detail.industry_id,
        description: detail.description,
      });
      for (let i = 0; i < detail.patentList.length; i++) {
        this.props.form.setFieldsValue({
          ["patentName" + i]: detail.patentList[i].name,
          ["patentTime" + i]: moment(detail.patentList[i].obtain_time, "YYYY-MM-DD HH:mm:ss"),
          ["patentType" + i]: detail.patentList[i].type,
        });
      }
      for (let i = 0; i < detail.financingRecordList.length; i++) {
        this.props.form.setFieldsValue({
          ["financingPrice" + i]: detail.financingRecordList[i].money / 100,
          ["financingTime" + i]: moment(
            detail.financingRecordList[i].obtain_time,
            "YYYY-MM-DD HH:mm:ss",
          ),
          ["financingSource" + i]: detail.financingRecordList[i].source,
        });
      }
    } catch (e) {
      console.log(e);
      this.setState({
        spinning: false,
      });
    }
  };
  //提交
  updateUserInfo = evt => {
    evt.preventDefault();
    this.props.form.validateFields((err, fields) => {
      if (err) {
        return message.error(err[Object.keys(err)[0]].errors[0].message, 1);
      }
      let patentList = [],
        financingList = [];
      for (let v of this.state.patentList) {
        patentList.push({
          name: fields["patentName" + v],
          time: moment(fields["patentTime" + v]).format("YYYY-MM-DD HH:mm:ss"),
          type: fields["patentType" + v],
        });
      }
      for (let v of this.state.financingList) {
        financingList.push({
          price: fields["financingPrice" + v] * 100,
          time: moment(fields["financingTime" + v]).format("YYYY-MM-DD HH:mm:ss"),
          source: fields["financingSource" + v],
        });
      }
      let data = {
        name: fields.name,
        totalNumber: fields.totalNumber,
        industry: fields.industry,
        patentList,
        financingList,
        description: fields.description,
      };
      console.log(data);
      project
        .editProjectInfo({
          name: fields.name,
          totalNumber: fields.totalNumber,
          industry: fields.industry,
          patentList,
          financingList,
          description: fields.description,
        })
        .then(json => {
          message.success("修改成功", 1);
        })
        .catch(err => {
          message.error("修改失败,失败原因: " + err.msg, 1);
          console.log(err);
        });
    });
  };
  //身份证号码验证
  idCardNoValidation = evt => {
    let val = evt.target.value;
    IdentityCodeValid(val).then(json => {
      this.setState({
        idCardNoValidation: json.pass,
      });
      if (!json.pass) {
        message.error("身份证号码错误: " + json.msg, 1);
      }
    });
  };

  //专利
  addPatent = _ => {
    let fieldNames = [];
    for (let i = 0; i < this.state.patentList.length; i++) {
      fieldNames.push("patentName" + i, "patentTime" + i, "patentType" + i);
    }
    this.props.form.validateFields(fieldNames, (err, fieldsValue) => {
      if (err) {
        let firstErrKey = Object.keys(err)[0];
        return message.error(err[firstErrKey].errors[0].message, 2);
      }
      let patentList = this.state.patentList;
      if (this.state.patentList.length === 0) {
        patentList.push(0);
      } else {
        patentList.push(patentList[patentList.length - 1] + 1);
      }
      this.setState({
        patentList,
      });
    });
  };
  //融资
  addFinancing = _ => {
    let fieldNames = [];
    for (let i = 0; i < this.state.financingList.length; i++) {
      fieldNames.push("financingPrice" + i, "financingTime" + i, "financingSource" + i);
    }
    this.props.form.validateFields(fieldNames, (err, fieldsValue) => {
      if (err) {
        let firstErrKey = Object.keys(err)[0];
        return message.error(err[firstErrKey].errors[0].message, 2);
      }
      let financingList = this.state.financingList;
      if (this.state.financingList.length === 0) {
        financingList.push(0);
      } else {
        financingList.push(financingList[financingList.length - 1] + 1);
      }
      this.setState({
        financingList,
      });
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    //头像上传按钮
    return (
      <Spin tip="Loading..." spinning={this.state.spinning} size="large">
        <div>
          <div className={style.title}>基本信息</div>
          <Form className={style.form}>
            <Form.Item {...formItemLayout} label="项目名称">
              {getFieldDecorator("name", {
                rules: [{ required: true, message: "请输入项目名称" }],
              })(<Input placeholder="请输入" />)}
            </Form.Item>
            <Form.Item {...formItemLayout} label="团队总人数">
              {getFieldDecorator("totalNumber", {
                rules: [{ required: true, message: "请输入" }],
              })(
                <InputNumber
                  className={style.input}
                  precision={0}
                  placeholder="请输入团队总人数"
                />,
              )}
            </Form.Item>
            <Form.Item {...formItemLayout} label="所属行业">
              {getFieldDecorator("industry", {
                rules: [{ required: true, message: "请选择" }],
              })(
                <Select placeholder="请选择">
                  {this.state.industryList.map((v, k) => {
                    return (
                      <Option key={`${k}`} value={v.value}>
                        {v.label}
                      </Option>
                    );
                  })}
                </Select>,
              )}
            </Form.Item>
            <Row>
              <Col span={10} offset={5}>
                <div className={style.panel}>
                  <Collapse defaultActiveKey={["1"]}>
                    <Panel
                      header={
                        <div>
                          专利列表
                          {this.state.patentList.length > 0 ? (
                            <Badge
                              style={{ marginLeft: "10px" }}
                              count={this.state.patentList.length}
                            />
                          ) : null}
                        </div>
                      }
                      key="3">
                      <div
                        className={
                          this.state.patentList.length > 0 ? style.shareholderList : style.hidden
                        }>
                        {this.state.patentList.map((v, k) => {
                          return (
                            <div key={k} className={style.shareholderItem}>
                              <div className={style.shareholderTitle}>
                                <div className={style.theme}>专利 {v + 1}</div>
                                <div
                                  className={style.icon}
                                  onClick={_ => {
                                    let patentList = this.state.patentList;
                                    patentList.splice(k, 1);
                                    this.setState({
                                      patentList,
                                    });
                                  }}>
                                  <Icon type="close-circle" />
                                </div>
                              </div>
                              <div className={style.item}>
                                <div className={style.label}>专利名称</div>
                                <div className={style.input}>
                                  {getFieldDecorator("patentName" + v, {
                                    rules: [{ required: true, message: `请输入专利${k + 1}姓名` }],
                                  })(<Input className={style.input} placeholder="请输入" />)}
                                </div>
                              </div>
                              <div className={style.item}>
                                <div className={style.label}>获得时间</div>
                                <div className={style.input}>
                                  {getFieldDecorator("patentTime" + v, {
                                    rules: [
                                      { required: true, message: `请选择专利${k + 1}获得时间` },
                                    ],
                                  })(<DatePicker className={style.input} placeholder="请选择" />)}
                                </div>
                              </div>
                              <div className={style.item}>
                                <div className={style.label}>专利类型</div>
                                <div className={style.input}>
                                  {getFieldDecorator("patentType" + v, {
                                    rules: [
                                      { required: true, message: `请选择专利${k + 1}专利类型` },
                                    ],
                                  })(
                                    <Select placeholder="请选择">
                                      {this.state.patentTypeList.map((v, k) => {
                                        return (
                                          <Option key={`${k}`} value={v.value}>
                                            {v.label}
                                          </Option>
                                        );
                                      })}
                                    </Select>,
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <Empty
                        style={{ marginBottom: 15 }}
                        className={this.state.patentList.length > 0 ? style.hidden : ""}
                      />
                      <div className={style.shareholderAdd}>
                        <Icon type="plus" onClick={this.addPatent} />
                      </div>
                    </Panel>
                    <Panel
                      header={
                        <div>
                          融资列表
                          {this.state.financingList.length > 0 ? (
                            <Badge
                              style={{ marginLeft: "10px" }}
                              count={this.state.financingList.length}
                            />
                          ) : null}
                        </div>
                      }
                      key="4">
                      <div
                        className={
                          this.state.financingList.length > 0 ? style.shareholderList : style.hidden
                        }>
                        {this.state.financingList.map((v, k) => {
                          return (
                            <div key={k} className={style.shareholderItem}>
                              <div className={style.shareholderTitle}>
                                <div className={style.theme}>融资记录 {v + 1}</div>
                                <div
                                  className={style.icon}
                                  onClick={_ => {
                                    let financingList = this.state.financingList;
                                    financingList.splice(k, 1);
                                    this.setState({
                                      financingList,
                                    });
                                  }}>
                                  <Icon type="close-circle" />
                                </div>
                              </div>
                              <div className={style.item}>
                                <div className={style.label}>融资金额(元)</div>
                                <div className={style.input}>
                                  {getFieldDecorator("financingPrice" + v, {
                                    rules: [{ required: true, message: `请输入融资${k + 1}金额` }],
                                  })(
                                    <InputNumber
                                      precision={2}
                                      className={style.input}
                                      placeholder="请输入"
                                    />,
                                  )}
                                </div>
                              </div>
                              <div className={style.item}>
                                <div className={style.label}>融资时间</div>
                                <div className={style.input}>
                                  {getFieldDecorator("financingTime" + v, {
                                    rules: [
                                      { required: true, message: `请选择融资${k + 1}融资时间` },
                                    ],
                                  })(<DatePicker className={style.input} placeholder="请选择" />)}
                                </div>
                              </div>
                              <div className={style.item}>
                                <div className={style.label}>融资来源</div>
                                <div className={style.input}>
                                  {getFieldDecorator("financingSource" + v, {
                                    rules: [{ required: true, message: `请选择融资${k + 1}来源` }],
                                  })(<Input className={style.input} placeholder="请输入" />)}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <Empty
                        style={{ marginBottom: 15 }}
                        className={this.state.financingList.length > 0 ? style.hidden : ""}
                      />
                      <div className={style.shareholderAdd}>
                        <Icon type="plus" onClick={this.addFinancing} />
                      </div>
                    </Panel>
                  </Collapse>
                </div>
              </Col>
            </Row>
            <Form.Item {...formItemLayout} label="项目描述">
              {getFieldDecorator("description", {})(<TextArea rows={4} placeholder="请输入" />)}
            </Form.Item>
            {/* <Form.Item className={style.control}>
              <Button className={style.btn} type="primary" onClick={this.updateUserInfo}>更新基本信息</Button>
            </Form.Item> */}
          </Form>
        </div>
      </Spin>
    );
  }
}
export default Form.create()(BaseInfo);
