import { bget, bpost } from './api';
/**
 * 事务名称对应数据库id
 */
export const nameMapDbId = {
  'realNameAuth': 1,
  'schoolInfoAuth': 2,
  'createProject': 3,
  'companyEnter': 4,
  'conferenceRoomRent': 5,
  'coffeeHouseRent': 6,
  'mealCard': 7,
  'parkingPermit': 8,
  'recruit': 9,
};
export async function edit({
  id, detail,
}) {
  return bpost({
    url: '/affairs/edit',
    data: {
      id,
      detail,
    }
  })
}
export async function detail({ id }) {
  return bget({
    url: '/affairs/detail',
    query: {
      id,
    }
  })
}

export default {
  edit,
  detail,
}
