import React from "react";
import project from "../../../services/project";
import gSass from "../../../utils/sass";
import moment from "moment";
import qs from "qs";
import {
  Form,

  message,

  Spin,
  Select,
  InputNumber,

  Cascader,
  DatePicker,
  // Collapse,
  Input,
  // Empty,
  // Icon,
} from "antd";
import { TYPE as PATENT_TYPE, TYPE_ZH as PATENT_TYPE_ZH } from "../../../services/patent";
import { EDUCATION, EDUCATION_ZH } from "../../../services/apply";
import companyApi from "../../../services/company";
import projectApi from "../../../services/project";
// import { getRegion, IdentityCodeValid } from "../../../utils/utils";
import { getRegion } from "../../../utils/utils";
const style = gSass.admin.project.base;
const Option = Select.Option;
// const { TextArea } = Input;

const formItemLayout = {
  labelCol: {
    lg: {
      span: 4,
      offset: 1,
    },
  },
  wrapperCol: {
    lg: {
      span: 10,
      offset: 0,
    },
  },
};
class Management extends React.Component {
  constructor(props) {
    super(props);
    let { id } = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    id = parseInt(id);
    this.state = {
      projectId: id,
      spinning: true,
      industryList: [],
      patentTypeList: [],
      patentList: [],
      financingList: [],
      userInfo: {},
    };
  }
  componentDidMount() {
    this.init();
  }
  init = async _ => {
    try {
      let {
        data: { list: industryList },
      } = await companyApi.getIndustryList({ page: -1, limit: -1 });
      industryList = industryList.map(v => ({
        value: v.id,
        label: v.name,
      }));
      const patentTypeList = Object.values(PATENT_TYPE).map(v => ({
        value: v,
        label: PATENT_TYPE_ZH[v],
      }));
      //获取学历信息
      let educationList = [
        Object.values(EDUCATION).map((v, k) => ({
          value: v,
          label: EDUCATION_ZH[v],
        })),
      ];
      let {
        data: { detail },
      } = await projectApi.projectDetail({ id: this.state.projectId });
      let region = await getRegion();
      let shareholderList = [],
        insuredPersonList = [],
        patentList = [],
        financingList = [];
      for (let i = 0; i < detail.patentList.length; i++) {
        patentList.push(i);
      }
      for (let i = 0; i < detail.financingRecordList.length; i++) {
        financingList.push(i);
      }
      this.setState({
        spinning: false,
        industryList,
        patentTypeList,
        patentList,
        educationList,
        region,
        userInfo: {
          patentList: detail.patentList,
          financingList: detail.financingList,
        },
        shareholderList,
        insuredPersonList,
        financingList,
      });
      this.props.form.setFieldsValue({
        name: detail.name,
        totalNumber: detail.total_number,
        industry: detail.industry_id,
        description: detail.description,
      });
      for (let i = 0; i < detail.patentList.length; i++) {
        this.props.form.setFieldsValue({
          ["patentName" + i]: detail.patentList[i].name,
          ["patentTime" + i]: moment(detail.patentList[i].obtain_time, "YYYY-MM-DD HH:mm:ss"),
          ["patentType" + i]: detail.patentList[i].type,
        });
      }
      for (let i = 0; i < detail.financingRecordList.length; i++) {
        this.props.form.setFieldsValue({
          ["financingPrice" + i]: detail.financingRecordList[i].money / 100,
          ["financingTime" + i]: moment(
            detail.financingRecordList[i].obtain_time,
            "YYYY-MM-DD HH:mm:ss",
          ),
          ["financingSource" + i]: detail.financingRecordList[i].source,
        });
      }
    } catch (e) {
      console.log(e);
      this.setState({
        spinning: false,
      });
    }
  };
  //提交
  updateUserInfo = evt => {
    evt.preventDefault();
    this.props.form.validateFields((err, fields) => {
      if (err) {
        return message.error(err[Object.keys(err)[0]].errors[0].message, 1);
      }
      let patentList = [],
        financingList = [];
      for (let v of this.state.patentList) {
        patentList.push({
          name: fields["patentName" + v],
          time: moment(fields["patentTime" + v]).format("YYYY-MM-DD HH:mm:ss"),
          type: fields["patentType" + v],
        });
      }
      for (let v of this.state.financingList) {
        financingList.push({
          price: fields["financingPrice" + v] * 100,
          time: moment(fields["financingTime" + v]).format("YYYY-MM-DD HH:mm:ss"),
          source: fields["financingSource" + v],
        });
      }
      let data = {
        name: fields.name,
        totalNumber: fields.totalNumber,
        industry: fields.industry,
        patentList,
        financingList,
        description: fields.description,
      };
      console.log(data);
      project
        .editProjectInfo({
          name: fields.name,
          totalNumber: fields.totalNumber,
          industry: fields.industry,
          patentList,
          financingList,
          description: fields.description,
        })
        .then(json => {
          message.success("修改成功", 1);
        })
        .catch(err => {
          message.error("修改失败,失败原因: " + err.msg, 1);
          console.log(err);
        });
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    //头像上传按钮
    return (
      <Spin tip="Loading..." spinning={this.state.spinning} size="large">
        <div>
          <div className={style.title}>基本信息</div>
          <Form className={style.form}>
            {/* 1.//收费金额/成立企业名称/成立日期/注册资本/注册地址 */}
            <Form.Item {...formItemLayout} label="入驻日期">
              {getFieldDecorator("entryDate", {
                rules: [
                  {

                    message: `请选择入驻日期`,
                  },
                ],
              })(<DatePicker className={style.input} placeholder="请选择" />)}
            </Form.Item>
            <Form.Item {...formItemLayout} label="退出日期">
              {getFieldDecorator("exitDate", {
                rules: [
                  {

                    message: `请选择退出日期`,
                  },
                ],
              })(<DatePicker className={style.input} placeholder="请选择" />)}
            </Form.Item>
            <Form.Item {...formItemLayout} label="收费金额">
              {getFieldDecorator("charge", {
                rules: [{ message: "请输入" }],
              })(
                <InputNumber
                  className={style.input}
                  precision={0.0}
                  placeholder="请输入收费金额"
                />,
              )}
            </Form.Item>
            <Form.Item {...formItemLayout} label="成立企业名称">
              {getFieldDecorator("companyName", {
                rules: [{ message: "请输入项目名称" }],
              })(<Input placeholder="请输入" />)}
            </Form.Item>
            <Form.Item {...formItemLayout} label="成立日期">
              {getFieldDecorator("establishmentDate", {
                rules: [
                  {

                    message: `请选择成立日期`,
                  },
                ],
              })(<DatePicker className={style.input} placeholder="请选择" />)}
            </Form.Item>
            <Form.Item {...formItemLayout} label="注册资本">
              {getFieldDecorator("registeredCapital", {
                rules: [{ message: "请输入" }],
              })(
                <InputNumber
                  className={style.input}
                  precision={0}
                  placeholder="请输入注册资本"
                />,
              )}
            </Form.Item>
            <Form.Item {...formItemLayout} label="注册地址">
              {getFieldDecorator("registerAddress", {
                rules: [
                  {

                    message: `请选择注册地址`,
                  },
                ],
              })(<Cascader options={this.state.region} placeholder="请选择" />)}
            </Form.Item>
            <Form.Item {...formItemLayout} label="所属行业">
              {getFieldDecorator("industry", {
                rules: [{ message: "请选择" }],
              })(
                <Select placeholder="请选择">
                  {this.state.industryList.map((v, k) => {
                    return (
                      <Option key={`${k}`} value={v.value}>
                        {v.label}
                      </Option>
                    );
                  })}
                </Select>,
              )}
            </Form.Item>
            {/* <Form.Item className={style.control}>
              <Button className={style.btn} type="primary" onClick={this.updateUserInfo}>更新基本信息</Button>
            </Form.Item> */}
          </Form>
        </div>
      </Spin>
    );
  }
}
export default Form.create()(Management);
