import React, { Component, } from 'react';
import { Switch, } from 'react-router-dom';
import AdminAuthRoute from '../../../utils/AdminAuth';
import CompanyList from "../../../pages/admin/company/List";
import CompanyIndustryList from "../../../pages/admin/company/IndustryList";
import CompanyPaymentRecordList from "../../../pages/admin/company/PaymentRecordList";
import AddPaymentRecord from "../../../pages/admin/company/AddPaymentRecord";
import Base from "../../../pages/admin/company/Base";

export default class Wrap extends Component {
  render() {
    return (
      <Switch>
        <AdminAuthRoute exact path="/admin/company/list" component={CompanyList} />
        <AdminAuthRoute exact path="/admin/company/industryList" component={CompanyIndustryList} />
        <AdminAuthRoute exact path="/admin/company/paymentRecordList" component={CompanyPaymentRecordList} />
        <AdminAuthRoute exact path="/admin/company/addPaymentRecord" component={AddPaymentRecord} />
        <AdminAuthRoute path="/admin/company/info/" component={Base} />
      </Switch>
    );
  }

}
