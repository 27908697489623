import React, { Component } from 'react';
import { Layout, Menu, Icon } from 'antd';
import { Link, } from "react-router-dom";
import gImg from "../../utils/img.js";
import { getMenuList } from '../../services/api';
import gSass from '../../utils/sass';

const style = gSass.common.sidebar;
const { SubMenu } = Menu;
const { Sider } = Layout;
let menuConfig =
{
  theme: 'dark',
  defaultSelectedKeys: ['1'],
  mode: 'inline',
  list: getMenuList(),
};
export default class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 250,
      openKeys: [],
      inlineCollapsed: false,
    };
    this.menuKey = 0;
    this.rootSubmenuKeys = [];
  }
  getMenuList = (menuList, level = 0) => {
    let that = this;
    if (level === 0) {
      that.menuKey = 0;
    }
    return menuList.map((v, k) => {
      if (Array.isArray(v.child) && v.child.length > 0) {
        if (level === 0) {
          that.rootSubmenuKeys.push(`sub${that.menuKey}`);
        }
        return <SubMenu key={`sub${that.menuKey++}`}
          // title={<span> {v.icon ? <FIcon className={style['menu-icon']} icon={v.icon} /> : ''}
          title={<span> {v.icon ? <Icon type={v.icon[0]} /> : ''}
            <span >{v.title}</span>
          </span>}>
          {that.getMenuList(v.child, level + 1)}
        </SubMenu>
      } else {
        return <Menu.Item
          key={v.key || `${that.menuKey++}`}>
          {/* <a href={v.href}> {v.icon ? <Icon type={v.icon} /> : ''}
            <span>{v.title}</span></a> */}
          <Link to={v.href}>{v.icon ? <Icon type={v.icon} /> : ''}
            <span>{v.title}</span></Link>
        </Menu.Item>
      }
    })
  }
  //关闭其他的展开栏目
  onOpenChange = (openKeys) => {
    const latestOpenKey = openKeys.find(key => this.state.openKeys.indexOf(key) === -1);
    if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      this.setState({ openKeys });
    } else {
      this.setState({
        openKeys: latestOpenKey ? [latestOpenKey] : [],
      });
    }
  }
  toggleCollapsed = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  }
  render(props) {
    return (
      <Sider collapsible
        collapsed={this.props.collasped}
        onCollapse={this.props.changeCollaspe}//当前张开收起
        width={this.state.width}
      >
        <div className={this.props.collasped ? style.collapsedLogo : style.logo + " flex center v-center"}>
          {
            this.props.collasped ?
              <img className={style['collapsed-img']} src={gImg.common.adminCollapsedLogo} alt="亨中信科" /> :
              <img className={style['img']} src={gImg.common.adminLogo} alt="亨中信科" />
          }
        </div>
        {<Menu theme={menuConfig.theme === 'dark' ? 'dark' : 'light'}
          defaultSelectedKeys={menuConfig.defaultSelectedKeys}
          mode={
            menuConfig.mode === 'vertical' ? 'vertical' :
              menuConfig.mode === 'vertical-left' ? 'vertical-left' :
                menuConfig.mode === 'vertical-right' ? 'vertical-right' :
                  menuConfig.mode === 'horizontal' ? 'horizontal' : 'inline'
          }
          openKeys={this.state.openKeys}
          onOpenChange={this.onOpenChange}
          defaultOpenKeys={this.state.openKeys}
        >
          {this.getMenuList(menuConfig.list)}
        </Menu>}
      </Sider >
    )
  }
}
