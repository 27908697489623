import React from "react";
import {
  List, TextareaItem, ActivityIndicator, Icon, InputItem, Button, Flex, Toast, Checkbox, Modal, NavBar,
} from "antd-mobile";
import { Form } from 'antd';
import { withRouter, Link, } from "react-router-dom";
import gStyle from "../utils/sass";
import recruitApi, { STATION, STATION_ZH, } from "../services/recruit";
import ApplyWithSendMsg from '../components/index/user/apply/ApplyWithSendMsg';

const style = gStyle.index.user.apply.recruit;
const Item = List.Item;
const CheckboxItem = Checkbox.CheckboxItem;


class Recruit extends ApplyWithSendMsg {
  isShowDetail = false;
  constructor(props) {
    super(props);
    this.isShowDetail = true;
    this.applyId = props.applyId;
    this.state = {
      isAnimating: true,
      isModalActive: false,
      modalBody: '',
      modalFooter: [],
      station: [],
      stationList: [],
      applyDetail: {
        id: 1,
        steps: [],
      },
    }
  }
  componentDidMount() {
    this.init();
  }

  init = async _ => {
    let station = Object.values(STATION).map((v, k) => ({
      value: v,
      label: STATION_ZH[v],
    }))
    let stationList = [1, 2, 3, 7];
    this.setState({
      isAnimating: false,
      stationList,
      station,
    })
  }
  apply = _ => {
    this.props.form.validateFields((err, fieldsValue) => {
      if (err) {
        let firstErrKey = Object.keys(err)[0]
        return Toast.fail(err[firstErrKey].errors[0].message, 2)
      }
      let fields = this.props.form.getFieldsValue(), station = [];
      if (!(/^1[345678]\d{9}$/.test(fields.phone))) {
        Toast.fail("手机号码不正确", 1)
        return false;
      }
      for (let v of this.state.station) {
        if (fields["station" + v.value]) {
          station.push(v.value);
        }
      }
      try {
        recruitApi.applyRecruit({
          companyName: fields.companyName,
          applicantName: fields.applicantName,
          phone: fields.phone,
          number: fields.number,
          salary: {
            min: parseInt(fields.minSalary) * 100,
            max: parseInt(fields.maxSalary) * 100,
          },
          station: station,
          description: fields.description || "",
        })
        this.setState({
          isModalActive: true,
          modalBody: <div>提交成功,请等待审核</div>,
          modalFooter: [{ text: '确定', onPress: () => this.props.history.push('/') }],
        })
      } catch (err) {
        this.setState({
          isModalActive: true,
          modalBody: <div>提交失败,错误信息:{err.msg}</div>,
          modalFooter: [{ text: '确定', }],
        })
      }
    });
  }
  resetForm = _ => {
    this.props.form.resetFields();
  }

  //手机号码验证
  phoneValid = data => {
    if (data === "") {
      Toast.fail("手机号码不能为空", 1)
      return false;
    }
    if (!(/^1[345678]\d{9}$/.test(data))) {
      Toast.fail("手机号码不正确", 1)
      return false;
    }
  }

  getApplyDetailDom = _ => {
    const { getFieldDecorator, getFieldError, getFieldProps } = this.props.form;
    return <form className={style.form}>
      <List>
        {getFieldDecorator('companyName',
          { rules: [{ required: true, message: '请输入企业名称' },], })(
            <InputItem clear className={style.input} placeholder="请输入" error={!!getFieldError('companyName')}
              extra={<Icon size="md" color="#ccc" type="right"></Icon>}>企业名称</InputItem>
          )}
        {getFieldDecorator('applicantName',
          { rules: [{ required: true, message: '请输入申请人' },], })(
            <InputItem clear className={style.input} placeholder="请输入" error={!!getFieldError('applicantName')}
              extra={<Icon size="md" color="#ccc" type="right"></Icon>}>申请人</InputItem>
          )}
        {getFieldDecorator('phone',
          { rules: [{ required: true, max: 11, message: '请输入联系方式' },], })(
            <InputItem className={style.input} placeholder="请输入"
              error={!!getFieldError('phone')} type="digit" onBlur={val => this.phoneValid(val)}
              extra={<Icon size="md" color="#ccc" type="right"></Icon>}>联系方式</InputItem>
          )}
        {getFieldDecorator('number',
          { rules: [{ required: true, message: '请输入招聘人数' },], })(
            <InputItem clear className={style.input} placeholder="请输入" error={!!getFieldError('number')}
              type="digit" extra={<Icon size="md" color="#ccc" type="right"></Icon>}  >招聘人数</InputItem>
          )}
        <Item>
          <Flex className={style.salary}>
            <div>薪资 (元)</div>
            {getFieldDecorator('minSalary',
              { rules: [{ required: true, message: '请输入最低薪资' },], })(
                <InputItem clear className={style.input} placeholder="最低金额"
                  error={!!getFieldError('minSalary')} type="digit"  ></InputItem>
              )}
            <div> - </div>
            {getFieldDecorator('maxSalary',
              { rules: [{ required: true, message: '请输入最高薪资' },], })(
                <InputItem clear className={style.input} placeholder="最高金额"
                  error={!!getFieldError('maxSalary')} type="digit" ></InputItem>)
            }
          </Flex>
        </Item>
        <Item>
          <div>招聘岗位(可多选)</div>
          {this.state.station.map((v, k) => {
            let result = false;
            if (this.isShowDetail) {
              for (let v1 of this.state.stationList) {
                if (v.value === v1) {
                  result = true;
                } else {
                  result = false;
                }
              }
              console.log(result)
            }
            return (<div key={`${k}`}>
              {getFieldDecorator('station' + v.value, {})(
                <CheckboxItem
                  defaultChecked={result}
                  key={v.value}>{v.label}</CheckboxItem>)}
            </div>
            )
          })}
        </Item>
        <Item>
          <div>岗位描述</div>
          <TextareaItem
            {...getFieldProps('description', {})}
            rows={3} count={100} className={style.description} placeholder="请输入岗位描述"
          />
        </Item>
      </List>
      {this.getChatInfo()}
    </form>
  }


  render() {
    return (this.isShowDetail ? this.getApplyDetailDom() :
      <div className={style.recruitment}>
        <ActivityIndicator animating={this.state.isAnimating} className={style.loading} text="loading..." />
        <NavBar className={this.isShowDetail ? style.hidden : null} mode="light"
          icon={<Link to="/?tab=my"><Icon type="left" /></Link>} >招聘申请</NavBar>
        {this.getApplyDetailDom()}
        <div className={style.operation}>
          <Button size="small" className={style.btn} inline onClick={this.resetForm}>重置</Button>
          <Button size="small" type="primary" className={style.btn} inline onClick={this.apply}>提交</Button>
        </div>
        <Modal visible={this.state.isModalActive} transparent maskClosable={false}
          title="提示" footer={this.state.modalFooter}
        >
          {this.state.modalBody}
        </Modal>
      </div >
    );
  }
}
export default withRouter(Form.create()(Recruit));
