import React from "react";
import {
  List, ActivityIndicator, Icon, InputItem, ImagePicker, NavBar,
} from "antd-mobile";
import { withRouter, Link, } from "react-router-dom";
import { Form, } from 'antd';
import gStyle from "../../../utils/sass";
import { BASE_URL, } from "../../../config/prod";
import userApi from "../../../services/user";
import ApplyWithSendMsg from '../../../components/index/user/apply/ApplyWithSendMsg';

const style = gStyle.index.user.auth.idCardRealName;
const Item = List.Item;

class IdcardRealName extends ApplyWithSendMsg {
  isShowDetail = false;
  constructor(props) {
    super(props);
    this.state = {
      animating: true,
      name: '',
      idCardNo: '',
      idCardFirstPic: [],
      idCardSecondPic: [],
    }
  }
  componentDidMount() {
    this.init();
  }
  init = async _ => {
    const { setFieldsValue, } = this.props.form;
    try {
      let { data: { detail, } } = await userApi.getMyIdRealNameDetail();
      this.setState({
        idCardFirstPic: [{ url: BASE_URL + detail.id_card_first_pic }],
        idCardSecondPic: [{ url: BASE_URL + detail.id_card_second_pic }]
      })
      setFieldsValue({
        name: detail.name,
        idCardNo: detail.idCardNo,
      })
      this.setState({
        animating: false,
      })
    } catch (e) {
      console.log(e);
      this.setState({
        animating: false,
      })
    }
  }
  /**
   * 详情
   *  */
  getApplyDetailDom = _ => {
    const { getFieldProps } = this.props.form;
    return <form className={style.form}>
      <List>
        <InputItem
          {...getFieldProps('name', {
            rules: [{ required: true, },],
          })}
          disabled clear className={style.input} placeholder="请输入姓名"
          extra={<Icon size="md" color="#ccc" type="right"></Icon>}
        >姓名</InputItem>
        <InputItem
          {...getFieldProps('idCardNo', {
            rules: [{ required: true, },],
          })}
          disabled className={style.input} placeholder="请输入身份证号码"
          extra={<Icon size="md" color="#ccc" type="right"></Icon>} maxLength={18} minLength={15}
        >身份证号码</InputItem>
        <Item >
          <div className={style.title}>身份证正面照</div>
          <ImagePicker files={this.state.idCardFirstPic}
            disabled selectable={this.state.idCardFirstPic.length < 1} style={{ flex: 1, }}
          />
        </Item>
        <Item >
          <div className={style.title}>身份证反面照</div>
          <ImagePicker files={this.state.idCardSecondPic}
            disabled selectable={this.state.idCardSecondPic.length < 1} style={{ flex: 1, }}
          />
        </Item>
      </List>
    </form>
  }
  render() {
    return (this.isShowDetail ? this.getApplyDetailDom() :
      <div className={style.idCardRealName}>
        <ActivityIndicator animating={this.state.animating} toast className={style.loading}
          text="loading..." />
        <NavBar mode="light"
          icon={<Link to="/?tab=my"><Icon type="left" /></Link>} >实名认证</NavBar>
        {this.getApplyDetailDom()}
      </div>
    );
  }
}
export default withRouter(Form.create()(IdcardRealName));


