import React from 'react';
import {
  Breadcrumb, Form, Steps, Alert, Spin, Divider, Tooltip, Switch, Empty,
} from 'antd';
// import moment from "moment";
import { Link, } from "react-router-dom";
import qs from "qs";
import { LocalStorage as storage } from "jsbdk";
import csn from 'classnames';
import Lightbox from 'react-images';
import gSass from '../../../utils/sass';
import gImg from "../../../utils/img";
import applyApi, { EDUCATION, EDUCATION_ZH, IS_GRADUATE, } from "../../../services/apply";
import { BASE_URL, } from "../../../config/prod";
import collegeApi from "../../../services/college";
import schoolSpecialityApi from "../../../services/schoolSpeciality";
import AuditWithSendMsg from '../../../components/admin/apply/AuditWithSendMsg';

const style = gSass.admin.apply.schoolInfo;
const Step = Steps.Step;

class SchoolInfo extends AuditWithSendMsg {
  constructor(props) {
    super(props)
    let { id } = qs.parse(props.location.search, { ignoreQueryPrefix: true, })
    this.state = {
      id,
      loading: true,
      lightboxIsOpen: false,
      //data
      applyDetail: {
        info: {
          college: "",
          speciality: "",
          grade: "",
          education: null,
          isGraduate: null,
          degreePic: gImg.common.idCardDefaultPic,
          oneCardPassPic: gImg.common.idCardDefaultPic,
          overseaCollege: "",
          overseaSpeciality: "",
          overseaEducation: "",
          graduation_time: "",
          overseas_graduation_time: "",
        },
        apply_uid: 5,
        current: 1,
        total: 5,
        steps: []
      },
      lightBoxImgs: [],
      userNick: "",
    };
  }

  componentDidMount() {
    this.init();
  }

  init = async _ => {
    try {
      let { data: { detail: applyDetail, } } = await applyApi.getApplyDetail({ id: this.state.id });
      this.setState({
        loading: false,
        userNick: storage.get('nick'),
        applyDetail,
      })
      console.log(applyDetail)
      console.log(applyDetail.info.collegeId)
      let { data: { list: collegeList } } = await collegeApi.getList({ page: -1, limit: -1 })
      console.log(collegeList)
      //获取学校专业列表
      let { data: { list: specialityList } } = await schoolSpecialityApi.getList({ page: -1, limit: -1 })
      let college = "", speciality = "", education = "", overseaEducation = "", overseaSpeciality = "", overseaCollege = "";
      console.log(collegeList)
      for (let v of collegeList) {
        console.log(v)
        console.log(applyDetail.info.collegeId)
        if (v.id === applyDetail.info.collegeId) {
          college = v.name;
          console.log(v.name)
        }
        if (v.id === applyDetail.info.overseas_college_id) {
          overseaCollege = v.name
        }
      }
      console.log(applyDetail.info.specialityId)
      for (let v of specialityList) {
        if (v.id === applyDetail.info.specialityId) {
          speciality = v.name;
        }
        if (v.id === applyDetail.info.overseas_speciality_id) {
          overseaSpeciality = v.name;
        }
      }
      switch (applyDetail.info.educationId) {
        case EDUCATION.MASTER_DEGREE_IN_READING:
          education = EDUCATION_ZH[EDUCATION.MASTER_DEGREE_IN_READING];
          break;
        case EDUCATION.BACHELOR_DEGREE:
          education = EDUCATION_ZH[EDUCATION.BACHELOR_DEGREE];
          break;
        case EDUCATION.DOCTORAL_DEGREE:
          education = EDUCATION_ZH[EDUCATION.DOCTORAL_DEGREE];
          break;
        case EDUCATION.DOCTORAL_DEGREE_IN_READING:
          education = EDUCATION_ZH[EDUCATION.DOCTORAL_DEGREE_IN_READING];
          break;
        case EDUCATION.MASTER_DEGREE:
          education = EDUCATION_ZH[EDUCATION.MASTER_DEGREE];
          break;
        case EDUCATION.UNDERGRADUATE:
          education = EDUCATION_ZH[EDUCATION.UNDERGRADUATE];
          break;
        default:
          education = EDUCATION_ZH[EDUCATION.UNDEFINED];
          break;
      }
      switch (applyDetail.info.overseas_education) {
        case EDUCATION.MASTER_DEGREE_IN_READING:
          overseaEducation = EDUCATION_ZH[EDUCATION.MASTER_DEGREE_IN_READING];
          break;
        case EDUCATION.BACHELOR_DEGREE:
          overseaEducation = EDUCATION_ZH[EDUCATION.BACHELOR_DEGREE];
          break;
        case EDUCATION.DOCTORAL_DEGREE:
          overseaEducation = EDUCATION_ZH[EDUCATION.DOCTORAL_DEGREE];
          break;
        case EDUCATION.DOCTORAL_DEGREE_IN_READING:
          overseaEducation = EDUCATION_ZH[EDUCATION.DOCTORAL_DEGREE_IN_READING];
          break;
        case EDUCATION.MASTER_DEGREE:
          overseaEducation = EDUCATION_ZH[EDUCATION.MASTER_DEGREE];
          break;
        case EDUCATION.UNDERGRADUATE:
          overseaEducation = EDUCATION_ZH[EDUCATION.UNDERGRADUATE];
          break;
        default:
          overseaEducation = EDUCATION_ZH[EDUCATION.UNDEFINED];
          break;
      }
      let applyDetails = this.state.applyDetail;
      applyDetails.info.college = college;
      applyDetails.info.speciality = speciality;
      applyDetails.info.education = education;
      applyDetails.info.overseaCollege = overseaCollege;
      applyDetails.info.overseaSpeciality = overseaSpeciality;
      applyDetails.info.overseaEducation = overseaEducation;
      this.setState({
        applyDetail,
      })
    } catch (err) {
      console.log(err);
    }
  }

  closeLightbox = _ => {
    this.setState({
      lightboxIsOpen: false,
      lightBoxImgs: [],
    })
  }
  closeApply = _ => {

  }
  render() {
    return (
      <div className={style.info}>
        <Breadcrumb className={style.title}>
          <Breadcrumb.Item><Link to="/admin">首页</Link></Breadcrumb.Item>
          <Breadcrumb.Item><Link to="/admin/apply/allList">申请管理</Link></Breadcrumb.Item>
          <Breadcrumb.Item>学校信息流程</Breadcrumb.Item>
        </Breadcrumb>
        <div className={style.prompt}>
          <Alert description=" 审核人可以审核之前与申请人进行沟通, 给予申请人在申请中出现的问题做答复" type="info" showIcon message="提示" />
        </div>
        <Spin spinning={this.state.loading} size="large">
          <div className={style.content}>
            <div className={style.step}>
              <Steps current={this.state.applyDetail.current - 1}>
                {this.state.applyDetail.steps.map((v, k) => {
                  return <Step key={k} title={v.name} description={<div>
                  </div>} />
                })}
              </Steps>
            </div>
            <div className={style.userInfo}>
              <div className={style.infoList}>
                <div className={style.item}>
                  <div className={style.name}>姓名:</div>
                  <div className={style.detail}>{this.state.applyDetail.info.name}</div>
                </div>
                <div className={style.item}>
                  <div className={style.name}>学校:</div>
                  <div className={style.detail}>{this.state.applyDetail.info.college}</div>
                </div>
                <div className={style.item}>
                  <div className={style.name}>专业:</div>
                  <div className={style.detail}>{this.state.applyDetail.info.speciality}</div>
                </div>
                <div className={style.item}>
                  <div className={style.name}>年级:</div>
                  <div className={style.detail}>{this.state.applyDetail.info.grade}</div>
                </div>
                <div className={style.item}>
                  <div className={style.name}>届别:</div>
                  <div className={style.detail}>{this.state.applyDetail.info.education} </div>
                </div>

                <div className={style.item}>
                  <div className={style.name}>是否为毕业生:</div>
                  <div className={style.detail} style={{ border: "none" }}>
                    <Switch checkedChildren="是" unCheckedChildren="否"
                      checked={this.state.applyDetail.info.isGraduate === IS_GRADUATE.TRUE ? true : false} disabled />
                  </div>
                </div>

                <div className={style.itemImg}>
                  <div className={style.name}>学位证/一卡通图片:</div>
                  <div className={style.img} onClick={_ => {
                    this.setState({
                      lightboxIsOpen: true,
                      lightBoxImgs: [{
                        src:
                          this.state.applyDetail.info.isGraduate === IS_GRADUATE.FALSE ?
                            `${BASE_URL + this.state.applyDetail.info.oneCardPassPic}` :
                            `${BASE_URL + this.state.applyDetail.info.degreePic}`
                      }],
                    })
                  }}>
                    <img src={this.state.applyDetail.info.isGraduate === IS_GRADUATE.FALSE ?
                      `${BASE_URL + this.state.applyDetail.info.oneCardPassPic}` :
                      `${BASE_URL + this.state.applyDetail.info.degreePic}`
                    } alt="学位证/一卡通图片" />
                  </div>
                </div>
                <div className={style.item}>
                  <div className={style.name}>是否有海外留学经历:</div>
                  <div className={style.detail} style={{ border: "none" }}>
                    <Switch checkedChildren="是" unCheckedChildren="否"
                      checked={this.state.applyDetail.info.is_overseas === IS_GRADUATE.TRUE ? true : false} disabled />
                  </div>
                </div>
                {
                  this.state.applyDetail.info.is_overseas === 1 ? (
                    <div className={style.infoList}>
                      <div className={style.item}>
                        <div className={style.name}>海外学校:</div>
                        <div className={style.detail}>{this.state.applyDetail.info.overseas_college_id}</div>
                      </div>
                      <div className={style.item}>
                        <div className={style.name}>海外专业:</div>
                        <div className={style.detail}>{this.state.applyDetail.info.overseas_speciality_id}</div>
                      </div>
                      <div className={style.item}>
                        <div className={style.name}>海外毕业时间:</div>
                        <div className={style.detail}>{this.state.applyDetail.info.overseas_graduation_time.substr(0, 10)}</div>
                      </div>
                      <div className={style.item}>
                        <div className={style.name}>海外学历:</div>
                        <div className={style.detail}>{this.state.applyDetail.info.overseaEducation}</div>
                      </div>
                      <div className={style.itemImg}>
                        <div className={style.name}>海外毕业证:</div>
                        <div className={style.img} onClick={_ => {
                          this.setState({
                            lightboxIsOpen: true,
                            lightBoxImgs: [{
                              src: `${BASE_URL + this.state.applyDetail.info.overseas_degree_pic_id}`
                            }],
                          })
                        }}>
                          <img src={
                            `${BASE_URL + this.state.applyDetail.info.overseas_degree_pic_id}`
                          } alt="海外毕业证" />
                        </div>
                      </div>
                    </div>

                  ) : null
                }
              </div>

              <div className={style.chargeUserList}>
                <div className={style.theme}>审核人列表</div>
                <div className={style.reviewStyle}>审核方式:
                <span className={style.reviewStyleTitle}>
                    {this.state.applyDetail.steps.length > 0 ?
                      this.state.applyDetail.steps[this.state.applyDetail.current - 1].should_all_pass === 1 ?
                        <Tooltip title='“会签”：要求在每一轮审核中所有审核人员都通过该申请才会进入下一轮审核。'>会签</Tooltip> :
                        <Tooltip title='“或签”：要求在每一轮审核中其中一个审核人通过该申请就进入下一轮申请。'>或签</Tooltip> : null}
                  </span>
                </div>
                {this.state.applyDetail.steps.length > 0 ? this.state.applyDetail.steps[this.state.applyDetail.current - 1].charge_user_list.map((v, k) => {
                  return <div key={k} className={csn(style.item, { [style.stepIsPass]: v.charge_status === 1 }, { [style.stepIsReject]: v.charge_status === 2, },
                    { [style.stepIsProcess]: v.charge_status === 0, })}>
                    <div className={style.chargeUser}>
                      <div className={style.nick}>审核人: {v.nick}</div>
                      <div className={style.result}>{v.charge_status === 1 ? "通过" : v.charge_status === 2 ? "拒绝" : "审核中"}</div>
                    </div>
                    <Divider style={{ height: 50, }} type="vertical" />
                  </div>
                }) : <Empty />}
                <div className={style.chargeUserMsg}>审核通过后自动进入下一审核流程</div>
              </div>
            </div>
            <div className={style.msgList}>
              <div className={style.msgListTitle}>审核人员与申请人对话列表</div>
              <Divider style={{ margin: 0, }} />
              {this.state.applyDetail.steps.length > 0 ?
                this.state.applyDetail.steps[this.state.applyDetail.current - 1].messages.length > 0 ?
                  this.state.applyDetail.steps[this.state.applyDetail.current - 1].messages.map((v, k) => {
                    return <div key={k} className={style.item}>
                      {v.send_user.id === this.state.applyDetail.apply_uid ?
                        <div className={style.author}>
                          <div className={style.msgSendTime}>{v.ctime.substr(0, 10)}</div>
                          <div className={style.msgSendUserNick}>{v.send_user.nick}</div>
                        </div> :
                        <div className={style.author}>
                          <div className={style.msgSendUserNick}>{v.send_user.nick}</div>
                          <div className={style.msgSendTime}>{v.ctime.substr(0, 10)}</div>
                        </div>}
                      <div className={csn(style.msg, { [style.selfMsg]: v.send_user.id === this.state.applyDetail.apply_uid })}>
                        <div className={style.message}>{v.msg}</div>
                      </div>
                    </div>
                  }) : <Empty /> : <Empty />}
            </div>
            {this.getFooterOperationDom()}
          </div>
        </Spin>
        {/* 图片查看 */}
        <Lightbox images={this.state.lightBoxImgs} isOpen={this.state.lightboxIsOpen}
          onClose={this.closeLightbox} showCloseButton={false} showImageCount={false}
          backdropClosesModal={true}
        />
      </div>
    );
  }
}
export default Form.create()(SchoolInfo);
