import { bget, bpost } from "./api";

export const MERGE_APPLY_STATUS = {
  FALSE: 0x0,
  TRUE: 0x1,
  PROCESS: 0x2,
};
export const MERGE_APPLY_STATUS_ZH = {
  [MERGE_APPLY_STATUS.FALSE]: "未基本信息认证",
  [MERGE_APPLY_STATUS.TRUE]: "已基本信息认证",
  [MERGE_APPLY_STATUS.PROCESS]: "基本信息认证中",
};

export const ID_REAL_NAME_APPLY_STATUS = {
  FALSE: 0x0,
  TRUE: 0x1,
  PROCESS: 0x2,
};
export const ID_REAL_NAME_APPLY_STATUS_ZH = {
  [ID_REAL_NAME_APPLY_STATUS.FALSE]: "未实名认证",
  [ID_REAL_NAME_APPLY_STATUS.TRUE]: "已实名认证",
  [ID_REAL_NAME_APPLY_STATUS.PROCESS]: "实名认证中",
};
export const SCHOOL_INFO_APPLY_STATUS = {
  FALSE: 0x0,
  TRUE: 0x1,
  PROCESS: 0x2,
};
export const SCHOOL_INFO_APPLY_STATUS_ZH = {
  [SCHOOL_INFO_APPLY_STATUS.FALSE]: "未校园认证",
  [SCHOOL_INFO_APPLY_STATUS.TRUE]: "已校园认证",
  [SCHOOL_INFO_APPLY_STATUS.PROCESS]: "校园认证中",
};
export const SUBJECT_APPLY_STATUS = {
  FALSE: 0x0,
  TRUE: 0x1,
  PROCESS: 0x2,
};
export const SUBJECT_APPLY_STATUS_ZH = {
  [SUBJECT_APPLY_STATUS.FALSE]: "未有公司或项目主体",
  [SUBJECT_APPLY_STATUS.TRUE]: "已有公司或项目主体",
  [SUBJECT_APPLY_STATUS.PROCESS]: "公司或项目主体认证中",
};
/**
 * 登录方式
 */
export const LOGIN_TYPE = {
  ACCOUNT_LOGIN: 0x0,
  PHONE_VERIFY_CODE_LOGIN: 0x1,
  EMAIL_VERIFY_CODE_LOGIN: 0x2,
};
export const REGISTER_TYPE = {
  ACCOUNT_REGISTER: 0x0,
  PHONE_VERIFY_CODE_REGISTER: 0x1,
  EMAIL_VERIFY_CODE_REGISTER: 0x2,
};
export const GENDER = {
  UNDEFINED: 0,
  MAN: 1,
  WOMAN: 2,
};
export const GENDER_ZH = ["未知", "男", "女"];
export const TYPE = {
  createProject: 0x0,
  companyEnter: 0x1,
  conferenceRoomRent: 0x2,
  coffeeHouseRent: 0x3,
  mealCard: 0x4,
  parkingPermit: 0x5,
  recruit: 0x6,
  realNameAuth: 0x7,
  schoolInfoAuth: 0x8,
};
export const TYPE_ZH = {
  [TYPE.createProject]: "项目入驻",
  [TYPE.companyEnter]: "公司入驻",
  [TYPE.conferenceRoomRent]: "会议室租用",
  [TYPE.coffeeHouseRent]: "咖啡吧",
  [TYPE.mealCard]: "饭卡",
  [TYPE.parkingPermit]: "停车证",
  [TYPE.recruit]: "招聘",
  [TYPE.realNameAuth]: "实名认证",
  [TYPE.schoolInfoAuth]: "学校信息认证",
};
export const STATUS = {
  process: 0x0,
  pass: 0x1,
  reject: 0x2,
  close: 0x3,
};
export const STATUS_ZH = {
  [STATUS.process]: "处理中",
  [STATUS.pass]: "已通过",
  [STATUS.reject]: "已拒绝",
  [STATUS.close]: "已关闭",
};
export const HAS_STUDY_ABROAD = {
  UNDEFINED: 0x0,
  TRUE: 0x1,
  FALSE: 0x2,
};
export const HAS_STUDY_ABROAD_ZH = {
  [HAS_STUDY_ABROAD.UNDEFINED]: "未知",
  [HAS_STUDY_ABROAD.TRUE]: "有",
  [HAS_STUDY_ABROAD.FALSE]: "没有",
};
export const NOT_LIMIT = -1;

/**
 * 后台列表用户搜索
 */
export async function getList(page, limit, filter = {}) {
  return bget({
    url: "/userList",
    query: {
      page,
      limit,
      filter,
    },
  });
}
/**
 * 后台列表用户筛选
 */
export async function getFilterList({ administrators, date }) {
  return bget({
    url: "/userList",
    query: {
      administrators, date,
    },
  });
}
export async function register({
  uname,
  pwd,
  rePwd,
  registerType,
  phone = "",
  phoneVerifiyCode = "",
  email = "",
  emailVerifiyCode = "",
}) {
  return bpost({
    url: "/user/register",
    data: {
      uname,
      pwd,
      phone,
      rePwd,
      phoneVerifiyCode,
      email,
      emailVerifiyCode,
      registerType,
    },
  });
}
//忘记密码--index
export async function checkOldPwd({ userName, phone, verificationCode }) {
  return bpost({
    url: "index/juice/login",
    data: {
      userName,
      phone,
      verificationCode,
    },
  });
}
//(忘记密码,修改密码)-----index
export async function setPwd({ pwd }) {
  return bpost({
    url: "index/juice/login",
    data: {
      pwd,
    },
  });
}
export async function checkAdminIsLogin() {
  return new Promise(s => {
    setTimeout(_ => s({ data: { isLogin: true } }), 3000);
  });
}
export async function userLogin({
  type,
  account,
  pwd,
  accountVerifyCode,
  rememberAccountPwd,
  email,
  emailVerifyCode,
  rememberEmailPwd,
  phone,
  phoneVerifyCode,
  rememberPhonePwd,
}) {
  switch (type) {
    case LOGIN_TYPE.ACCOUNT_LOGIN:
      return userAccountLogin({
        account,
        pwd,
        accountVerifyCode,
        rememmberPwd: rememberAccountPwd,
      });
    case LOGIN_TYPE.EMAIL_VERIFY_CODE_LOGIN:
      return userEmailLogin({ email, emailVerifyCode, rememmberPwd: rememberEmailPwd });
    case LOGIN_TYPE.PHONE_VERIFY_CODE_LOGIN:
      return userPhoneLogin({ phone, phoneVerifyCode, rememmberPwd: rememberPhonePwd });
    default:
      return Promise.reject("登录方式不正确");
  }
}
async function userAccountLogin({ account, pwd, accountVerifyCode, rememmberPwd = false }) {
  return bpost({
    url: "user/login",
    data: {
      loginType: LOGIN_TYPE.ACCOUNT_LOGIN,
      account,
      pwd,
      accountVerifyCode,
      rememmberPwd,
    },
  });
}
async function userEmailLogin({ email, emailVerifyCode, rememmberPwd }) {
  return bpost({
    url: "user/login",
    data: {
      loginType: LOGIN_TYPE.EMAIL_VERIFY_CODE_LOGIN,
      email,
      emailLoginVerifyCode: emailVerifyCode,
      rememmberPwd,
    },
  });
}
async function userPhoneLogin({ phone, phoneVerifyCode, rememmberPwd }) {
  return bpost({
    url: "user/login",
    data: {
      loginType: LOGIN_TYPE.PHONE_VERIFY_CODE_LOGIN,
      phone,
      phoneLoginVerifyCode: phoneVerifyCode,
      rememmberPwd,
    },
  });
}
export async function adminLogin({
  type,
  account,
  pwd,
  accountVerifyCode,
  rememberAccountPwd,
  email,
  emailVerifyCode,
  rememberEmailPwd,
  phone,
  phoneVerifyCode,
  rememberPhonePwd,
}) {
  switch (type) {
    case LOGIN_TYPE.ACCOUNT_LOGIN:
      return adminAccountLogin({
        account,
        pwd,
        accountVerifyCode,
        rememmberPwd: rememberAccountPwd,
      });
    case LOGIN_TYPE.EMAIL_VERIFY_CODE_LOGIN:
      return adminEmailLogin({ email, emailVerifyCode, rememmberPwd: rememberEmailPwd });
    case LOGIN_TYPE.PHONE_VERIFY_CODE_LOGIN:
      return adminPhoneLogin({ phone, phoneVerifyCode, rememmberPwd: rememberPhonePwd });
    default:
      return Promise.reject("登录方式不正确");
  }
}
async function adminAccountLogin({ account, pwd, accountVerifyCode, rememmberPwd = false }) {
  return bpost({
    url: "admin/login",
    data: {
      loginType: LOGIN_TYPE.ACCOUNT_LOGIN,
      account,
      pwd,
      accountVerifyCode,
      rememmberPwd,
    },
  });
}
async function adminEmailLogin({ email, emailVerifyCode, rememmberPwd }) {
  return bpost({
    url: "admin/login",
    data: {
      loginType: LOGIN_TYPE.EMAIL_VERIFY_CODE_LOGIN,
      email,
      emailLoginVerifyCode: emailVerifyCode,
      rememmberPwd,
    },
  });
}
async function adminPhoneLogin({ phone, phoneVerifyCode, rememmberPwd }) {
  return bpost({
    url: "admin/login",
    data: {
      loginType: LOGIN_TYPE.PHONE_VERIFY_CODE_LOGIN,
      phone,
      phoneLoginVerifyCode: phoneVerifyCode,
      rememmberPwd,
    },
  });
}

//个人设置修改个人信息
export async function editUserInfo({ nick, avatar, email, gender, address, profile }) {
  return bpost({
    url: "/editUserInfo",
    data: {
      nick,
      avatar,
      email,
      gender,
      address,
      profile,
    },
  });
}
//管理员修改个人信息
export async function adminEditGeneralUserInfo({
  id,
  nick,
  avatar,
  email,
  gender,
  address,
  profile,
}) {
  return bpost({
    url: "/adminEditGeneralUserInfo",
    data: {
      id,
      nick,
      avatar,
      email,
      gender,
      address,
      profile,
    },
  });
}
//个人设置获取个人信息
export async function getUserInfo() {
  return bget({
    url: "/getUserInfo",
    query: {},
  });
}
//管理员获取个人信息
export async function adminGetGeneralUserInfo({ id }) {
  return bget({
    url: "/adminGetGeneralUserInfo",
    query: { id },
  });
}
//修改密码
export async function modifyPwd({ oriPwd, newPwd, rePwd }) {
  return bpost({
    url: "/modifyPwd",
    data: { oriPwd, newPwd, rePwd },
  });
}
//检查昵称是否存在
export async function checkNickIsExist(nick) {
  try {
    let json = await bpost({
      url: "/checkUniqueFieldExist/nick",
      data: {
        nick,
      },
    });
    return json.data.isExist;
  } catch (e) {
    console.log("检查昵称是否存在失败", e);
    return true;
  }
}
//检查用户名是否存在
export async function checkAccountIsExist(account) {
  try {
    let json = await bpost({
      url: "/checkUniqueFieldExist/account",
      data: {
        account,
      },
    });
    return json.data.isExist;
  } catch (e) {
    console.log("检查用户名是否存在失败", e);
    return true;
  }
}
//检查邮箱是否存在
export async function checkEmailIsExist(email) {
  try {
    let json = await bpost({
      url: "/checkUniqueFieldExist/email",
      data: {
        email,
      },
    });
    return json.data.isExist;
  } catch (e) {
    console.log("检查邮箱是否存在失败", e);
    return true;
  }
}
//检查手机号是否存在
export async function checkPhoneIsExist(phone) {
  try {
    let json = await bpost({
      url: "/checkUniqueFieldExist/phone",
      data: {
        phone,
      },
    });
    return json.data.isExist;
  } catch (e) {
    console.log("检查邮箱是否存在失败", e);
    return true;
  }
}
//退出
export async function logout() {
  return bget({
    url: "/logout",
  });
}
//添加用户
export async function addUser({ account, pwd, rePwd, nick, email, phone, isAdmin, profile }) {
  return bpost({
    url: "/addUser",
    data: {
      account,
      pwd,
      rePwd,
      nick,
      email,
      phone,
      isAdmin,
      profile,
    },
  });
}
//删除多个用户
export async function deleteUsers(idArr) {
  return bpost({
    url: "/deleteUsers",
    data: {
      idArr,
    },
  });
}

/**
 * 获取申请记录列表
 */
export async function getApplyRecordList({ page, limit, filter = {} }) {
  return bget({
    url: "/user/getApplyRecordList",
    query: {
      page,
      limit,
      filter,
    },
  });
}

//个人信息
export async function editInfo({ account, nick, avatar, gender, phone, email, profile }) {
  return bpost({
    url: "/EditInfo",
    data: {
      account,
      nick,
      avatar,
      gender,
      phone,
      email,
      profile,
    },
  });
}
export async function infoDetail() {
  return bget({
    url: "/InfoDetail",
    query: {},
  });
}
export async function getIdCardRealNameDetail() {
  return bget({
    url: "/getIdCardRealNameDetail",
    query: {},
  });
}
export async function sendEmailRegisterVerifyCode({ email }) {
  return bpost({
    url: "/user/register/sendMailCode",
    data: { email },
  });
}
/**
 * 检查用户是否已申请企业入驻或者已有项目入驻申请
 */
export async function checkHasApplyCompanyOrProject() {
  return bget({
    url: "/user/checkHasApplyCompanyOrProject",
  });
}

export async function getAdminList({ page, limit, filter = {} }) {
  return bget({
    url: "/user/getAdminList",
    query: {
      page,
      limit,
      filter,
    },
  });
}
export async function getMyIdRealNameApplyId() {
  return bget({
    url: "/user/getMyIdRealNameApplyId",
  });
}
export async function getMyIdRealNameDetail() {
  return bget({
    url: "/user/getMyIdRealNameDetail",
  });
}
export async function getMySchoolInfoApplyId() {
  return bget({
    url: "/user/getMySchoolInfoApplyId",
  });
}
export async function getMySchoolInfoDetail() {
  return bget({
    url: "/user/getMySchoolInfoDetail",
  });
}
export async function adminGetUserIdRealNameDetail({ id }) {
  return bget({
    url: "/adminGetUserIdRealNameDetail",
    query: {
      id,
    },
  });
}
export async function adminEditUserIdRealNameDetail() {
  return bpost({
    url: "/adminEditUserIdRealNameDetail",
  });
}
export async function adminGetUserSchoolInfo({ id }) {
  return bget({
    url: "/adminGetUserSchoolInfo",
    query: {
      id,
    },
  });
}
export async function adminEditUserSchoolInfo() {
  return bpost({
    url: "/adminEditUserSchoolInfo",
  });
}
export async function adminGetGeneralUserData() {
  return bget({
    url: "/adminGetGeneralUserData",
    query: {},
  });
}
export async function adminModifyGeneralUserPwd({ uid, newPwd, rePwd }) {
  return bpost({
    url: "/adminModifyGeneralUserPwd",
    data: {
      uid,
      newPwd,
      rePwd,
    },
  });
}
export async function getMySubjectId() {
  return bget({
    url: "/user/getMySubjectId",
    query: {},
  });
}
export default {
  getMySubjectId,
  adminModifyGeneralUserPwd,
  adminGetUserIdRealNameDetail,
  adminEditUserIdRealNameDetail,
  adminGetUserSchoolInfo,
  adminEditUserSchoolInfo,
  adminGetGeneralUserInfo,
  adminEditGeneralUserInfo,
  getMyIdRealNameDetail,
  getMyIdRealNameApplyId,
  getMySchoolInfoApplyId,
  getMySchoolInfoDetail,
  getAdminList,
  sendEmailRegisterVerifyCode,
  getIdCardRealNameDetail,
  getList,
  register,
  adminLogin,
  getUserInfo,
  checkNickIsExist,
  checkAccountIsExist,
  checkEmailIsExist,
  checkPhoneIsExist,
  editUserInfo,
  modifyPwd,
  logout,
  addUser,
  deleteUsers,
  getApplyRecordList,
  editInfo,
  infoDetail,
  userLogin,
  checkOldPwd,
  setPwd,
  REGISTER_TYPE,
  LOGIN_TYPE,
  checkHasApplyCompanyOrProject,
  getFilterList
};
