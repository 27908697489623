import React from "react";
import gSass from "../../../utils/sass";
import moment from "moment";
import { Link, } from "react-router-dom";
import qs from "qs";
import {
  Breadcrumb,
  Form,
  Input,
  DatePicker,
  message,
  Spin,
  Button,
  Select,
} from "antd";
import applyApi from "../../../services/apply";
import conference, { ROOM_NO_LIST } from "../../../services/conferenceRoomRent";
import { IdentityCodeValid } from "../../../utils/utils";
const style = gSass.admin.applyEdit.edit;
const { TextArea } = Input;
const Option = Select.Option;
const formItemLayout = {
  labelCol: {
    lg: {
      span: 4,
      offset: 4,
    },
  },
  wrapperCol: {
    lg: {
      span: 8,
      offset: 0,
    },
  },
};


class Edit extends React.Component {
  constructor(props) {
    super(props);
    let { id } = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    id = parseInt(id);
    this.state = {
      projectId: id,
      spinning: true,
      industryList: [],
      patentTypeList: [],
      patentList: [],
      financingList: [],
      userInfo: {},
      idCardFirstPic: [],
      idCardSecondPic: [],
      previewVisible: false,
      previewImage: '',
      householdList: [],
      residentPic: [],
      isShanghai: null,
      applyId: 0,
      applyUid: 0,
      infoId: 0,
      roomList: [],
    };
  }
  componentDidMount() {
    this.init();
  }
  init = async _ => {


    try {
      // 获得服务器数据
      const roomList =
        ROOM_NO_LIST.map(v => ({
          value: v,
          label: v,
        }))

      console.log(roomList)
      let { data: { detail, } } = await applyApi.getApplyDetail({ id: this.state.projectId });
      console.log(detail)
      this.setState({
        applyUid: detail.apply_uid,
        applyId: detail.info.id,
        spinning: false,
        roomList,
      }, () => {
        console.log(this.state.roomList)
      })
      this.props.form.setFieldsValue({
        name: detail.info.name,
        company: detail.info.companyName,
        startTime: moment(detail.info.applyBeginTime, "YYYY-MM-DD HH:mm:ss"),
        endTime: moment(detail.info.applyEndTime, "YYYY-MM-DD HH:mm:ss"),
        reason: detail.info.reason,
        roomNo: [detail.info.roomNo],
      });
    } catch (e) {
      console.log(e);
      this.setState({
        spinning: false,
      });
    }
  };

  //提交
  updateUserInfo = evt => {
    evt.preventDefault();

    this.props.form.validateFields(["name", "company", "startTime", "endTime", "reason", "roomNo"], (err, fields) => {
      if (err) {
        return message.error(err[Object.keys(err)[0]].errors[0].message, 1);
      }
      conference
        .editConferenceRoomRent({
          applyId: this.state.applyId,
          applyUid: this.state.applyUid,
          name: fields.name,
          company: fields.company,
          reason: fields.reason,
          roomNo: fields.roomNo,
          startTime: moment(fields.startTime).format("YYYY-MM-DD HH:mm:ss"),
          endTime: moment(fields.endTime).format("YYYY-MM-DD HH:mm:ss"),
        })
        .then(json => {
          message.success("修改成功", 1);
        })
        .catch(err => {
          message.error("修改失败,失败原因: " + err.msg, 1);
          console.log(err);
        });
    });
  };
  //上传图片
  handlePreview = file => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true,
    });
  }
  idCardFirstPicChange = ({ fileList }) => this.setState({ idCardFirstPic: fileList })
  idCardSecondPicChange = ({ fileList }) => this.setState({ idCardSecondPic: fileList })
  handleCancel = _ => this.setState({ previewVisible: false })
  residentPicChange = ({ fileList }) => this.setState({ residentPic: fileList })
  //身份证号码验证
  idCardNoValidation = evt => {
    let val = evt.target.value;
    IdentityCodeValid(val).then(json => {
      this.setState({
        idCardNoValidation: json.pass,
      });
      if (!json.pass) {
        message.error("身份证号码错误: " + json.msg, 1);
      }
    });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    //头像上传按钮
    return (
      <Spin tip="Loading..." spinning={this.state.spinning} size="large">
        <div className={style.info}>
          <Breadcrumb className={style.title}>
            <Breadcrumb.Item><Link to="/admin">首页</Link></Breadcrumb.Item>
            <Breadcrumb.Item><Link to="/admin/apply/allList">申请管理</Link></Breadcrumb.Item>
            <Breadcrumb.Item>会议室管理编辑</Breadcrumb.Item>
          </Breadcrumb>
          <div className={style.basicWrap}>
            <Form className={style.form}>
              <Form.Item {...formItemLayout} label="申请人姓名">
                {getFieldDecorator("name", {
                  rules: [{ required: true, message: "请输入申请人姓名" }],
                })(<Input placeholder="请输入" />)}
              </Form.Item>
              <Form.Item {...formItemLayout} label="单位名称">
                {getFieldDecorator("company", {
                  rules: [{ required: true, message: "请输入单位名称" }],
                })(<Input placeholder="请输入" />)}
              </Form.Item>
              <Form.Item {...formItemLayout} label="开始使用时间"  >
                {getFieldDecorator('startTime', {
                  rules: [{ required: true, message: '请选择开始使用时间' }],
                })(
                  <DatePicker placeholder="请选择" format="YYYY-MM-DD HH:mm:ss" />
                )}
              </Form.Item>
              <Form.Item {...formItemLayout} label="结束使用时间"  >
                {getFieldDecorator('endTime', {
                  rules: [{ required: true, message: '请选择结束使用时间' }],
                })(
                  <DatePicker placeholder="请选择" format="YYYY-MM-DD HH:mm:ss" />
                )}
              </Form.Item>
              <Form.Item {...formItemLayout} label="租用房间">
                {getFieldDecorator("roomNo", {
                  rules: [
                    { required: true, message: `请选择会议室` },
                  ],
                })(
                  <Select placeholder="请选择">
                    {this.state.roomList.map((v, k) => {
                      return (
                        <Option key={`${k}`} value={v.value}>
                          {v.label}
                        </Option>
                      );
                    })}
                  </Select>,
                )}
              </Form.Item>
              <Form.Item {...formItemLayout} label="租用原因">
                {getFieldDecorator("reason", {})(<TextArea rows={4} placeholder="请输入" />)}
              </Form.Item>
              <Form.Item wrapperCol={{
                xs: {
                  span: 7,
                  offset: 8,
                },
              }}>
                <Button className={style.btn} type="primary" onClick={this.updateUserInfo}>更新基本信息</Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Spin>
    );
  }
}
export default Form.create()(Edit);
