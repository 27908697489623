import React, { Component } from "react";
import { Empty } from "antd";
import { BASE_URL } from "../../../config/prod";
import gStyle from "../../../utils/sass";
const style = gStyle.index.company.parkingPermit;

export default class ParkingPermit extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className={style.list}>
        {this.props.list.length > 0 ? (
          this.props.list.map((v, k) => {
            return (
              <div key={k} className={style.item}>
                <div className={style.num}>停车证{k + 1}: </div>
                <div className={style.flex}>
                  <div className={style.title}>车主: </div>
                  <div className={style.detail}>{v.name}</div>
                </div>
                <div className={style.flex}>
                  <div className={style.title}>手机号码: </div>
                  <div className={style.detail}>{v.contactInfo}</div>
                </div>
                <div className={style.flex}>
                  <div className={style.title}>驾驶证图片: </div>
                  <img
                    className={style.img}
                    src={BASE_URL + v.driverLicensePic.url}
                    alt="驾驶证图片"
                  />
                </div>
                <div className={style.flex}>
                  <div className={style.title}>行驶证图片: </div>
                  <img
                    className={style.img}
                    src={BASE_URL + v.drivingLicensePic.url}
                    alt="驾驶证图片"
                  />
                </div>
                <div className={style.flex}>
                  <div className={style.title}>申请时间: </div>
                  <div className={style.detail}>{v.ctime.substr(0, 10)}</div>
                </div>
              </div>
            );
          })
        ) : (
          <Empty />
        )}
      </div>
    );
  }
}
