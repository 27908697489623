import React, { Component } from 'react';
import {
  Table, Button, Breadcrumb, Input, Icon, Menu, Dropdown, Popconfirm, Tooltip, Modal, message,
  Form, Select, DatePicker,
  //  Cascader, Radio,InputNumber, Slider,
} from 'antd';
import { Link, } from "react-router-dom";
import moment from "moment";
import companyApi from "../../../services/company";
import { TYPE, TYPE_ZH, } from "../../../utils/constant";
import { TYPE as USER_TYPE, TYPE_ZH as USER_TYPE_ZH } from "../../../services/user";
import gSass from '../../../utils/sass';
import { trim } from "jsbdk"
const style = gSass.admin.company.list;

const Search = Input.Search;
const Option = Select.Option;
const RangePicker = DatePicker.RangePicker;
// const RadioGroup = Radio.Group;

class CompanyList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hasFilter: false,
      tableLoding: true,
      dateSelect: TYPE.undefined,
      list: [],
      selectedRows: [],
      page: 1,
      limit: 8,
      filter: {
        search: '',
      },
      typeChildren: [
        {
          value: USER_TYPE.createProject,
          label: USER_TYPE_ZH[USER_TYPE.createProject],
        },
        {
          value: USER_TYPE.companyEnter,
          label: USER_TYPE_ZH[USER_TYPE.companyEnter],
        },
        {
          value: USER_TYPE.conferenceRoomRent,
          label: USER_TYPE_ZH[USER_TYPE.conferenceRoomRent],
        },
        {
          value: USER_TYPE.coffeeHouseRent,
          label: USER_TYPE_ZH[USER_TYPE.coffeeHouseRent],
        },
        {
          value: USER_TYPE.mealCard,
          label: USER_TYPE_ZH[USER_TYPE.mealCard],
        },
        {
          value: USER_TYPE.parkingPermit,
          label: USER_TYPE_ZH[USER_TYPE.parkingPermit],
        },
        {
          value: USER_TYPE.recruit,
          label: USER_TYPE_ZH[USER_TYPE.recruit],
        },
        {
          value: USER_TYPE.realNameAuth,
          label: USER_TYPE_ZH[USER_TYPE.realNameAuth],
        },
        {
          value: USER_TYPE.schoolInfoAuth,
          label: USER_TYPE_ZH[USER_TYPE.schoolInfoAuth],
        },

      ],
      chooseDateSection: [
        moment(), moment().add(7, 'day'),
      ],
      isExportTable: false,
    };
  }

  componentDidMount() {
    this.init();
  }

  init = async _ => {
    try {
      const { data: { list } } = await companyApi.getList({
        page: this.state.page, limit: this.state.limit, filter: this.state.filter
      });
      this.setState({
        tableLoding: false,
        list,
      })
    } catch (e) {
      this.setState({
        tableLoding: false,
      })
      console.log(e)
    }
  }
  handleChange = (pagination, filters, sorter) => {//筛选(排序,页码)
    // console.log('Various parameters', pagination, filters, sorter);
    // this.setState({
    //   filteredInfo: filters,
    //   sortedInfo: sorter,
    // });
  }



  //删除
  deleteCompany = idArr => {
    // const list = this.state.list,
    //   selectedRows = this.state.selectedRows;
    // user.deleteUsers(idArr).then(json => {
    //   message.success('删除成功', 1)
    //   this.setState({
    //     list: list.filter(item => !idArr.includes(item.id)),
    //     selectedRows: selectedRows.filter(item => !idArr.includes(item.id))
    //   });
    // }).catch(err => {
    //   message.error("删除失败,错误原因: " + err.msg, 3)
    // })
  }

  //筛选
  // onFilter = evt => {
  //   evt.preventDefault();
  //   this.setState({
  //     tableLoding: true,
  //   })
  //   this.props.form.validateFields((err, fields) => {
  //     let administrators = {
  //       value: fields.administrators,
  //     },
  //       account = {
  //         condition: parseInt(fields.accountCondition),
  //         value: fields.accountVal || "",
  //       },
  //       nick = {
  //         condition: parseInt(fields.nickCondition),
  //         value: fields.nickVal || "",
  //       },
  //       gender = {
  //         condition: parseInt(fields.genderCondition),
  //         value: parseInt(fields.genderVal),
  //       },
  //       phone = {
  //         condition: parseInt(fields.phoneCondition),
  //         value: fields.phoneVal || "",
  //       },
  //       email = {
  //         condition: parseInt(fields.emailCondition),
  //         value: fields.emailVal || "",
  //       },
  //       date = {
  //         condition: parseInt(fields.dateCondition),
  //         value: this.state.dateSelect ===TYPE.before ? (fields.dateBeformVal ?
  //           moment(fields.dateBeformVal).format("YYYY-MM-DD HH:mm:ss") : "") :
  //           this.state.dateSelect === TYPE.betweenTime ? (fields.dateBetweenVal.length > 0 ? {
  //             min: fields.dateBetweenVal ?
  //               (fields.dateBetweenVal[0] ? moment(fields.dateBetweenVal[0]).format("YYYY-MM-DD HH:mm:ss") : "") : "",
  //             max: fields.dateBetweenVal ?
  //               (fields.dateBetweenVal[1] ? moment(fields.dateBetweenVal[1]).format("YYYY-MM-DD HH:mm:ss") : "") : "",
  //           } : ""
  //           ) :
  //             fields.dateAfterVal ? moment(fields.dateAfterVal).format("YYYY-MM-DD HH:mm:ss") : "",
  //       },
  //       address = {
  //         condition: parseInt(fields.addressCondition),
  //         value: fields.addressVal ? fields.addressVal : "",
  //       };
  //     console.log({ administrators, account, nick, gender, phone, email, date, address })
  //     //自己写接口啊
  //     message.info("没写接口呢, 你自己加啊", 1);
  //     user.filter({ administrators, account, nick, gender, phone, email, date, address }).then(json => {
  //       message.success("正在筛选, 请稍等", 1);
  //       this.setState({
  //         list: json.data,
  //       })
  //     }).catch(err => {
  //       message.success("筛选失败, 请稍后重试", 1);
  //       console.log(err);
  //     })
  //     //关闭模态框
  //     this.setState({
  //       hasFilter: false,
  //       tableLoding: false,
  //     })
  //   });
  // }
  onFilter = evt => {
    evt.preventDefault()
    this.props.form.validateFields((err, fields) => {
      let date = {
        condition: parseInt(fields.dataCondition),
        value: this.state.dateSelect === TYPE.before ? (fields.dateBeformVal ?
          moment(fields.dateBeformVal).format("YYYY-MM-DD HH:mm:ss") : "") :
          this.state.dateSelect === TYPE.betweenTime ? (fields.dateBetweenVal.length > 0 ? {
            min: fields.dateBetweenVal ?
              (fields.dateBetweenVal[0] ? moment(fields.dateBetweenVal[0]).format("YYYY-MM-DD HH:mm:ss") : "") : "",
            max: fields.dateBetweenVal ?
              (fields.dateBetweenVal[1] ? moment(fields.dateBetweenVal[1]).format("YYYY-MM-DD HH:mm:ss") : "") : "",
          } : ""
          ) :
            fields.dateAfterVal ? moment(fields.dateAfterVal).format("YYYY-MM-DD HH:mm:ss") : "",
      }
      console.log(date)
    })
  }

  exportTable = evt => {
    evt.preventDefault()
    window.location.href = "https://dh.imlimit.com/admin/companyExcel?beginDate=" + this.state.chooseDateSection[0].format('YYYY-MM-DD') + "&endDate=" + this.state.chooseDateSection[1].format('YYYY-MM-DD')
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
      },
      {
        title: '公司名称',
        dataIndex: 'companyName',
        render: val => {
          return (<div style={{ color: '#007bff', }}>{val}</div>);
        }
      },
      {
        title: '法人姓名',
        dataIndex: 'name',
        render: (name, record, index) => {
          return (<Tooltip placement="right" title={_ => {
            return (<div>
              <div>法人id: {record.legalPerson.id}</div>
              <div>法人身份证号: {record.legalPerson.idCardNo}</div>
            </div>)
          }}>
            {name}
          </Tooltip>)
        }
      },
      {
        title: '参保人数',
        dataIndex: 'insured_person_number',
      },
      {
        title: '公司总人数',
        dataIndex: 'total_number',
      },
      {
        title: '注册资本(万元)',
        dataIndex: 'register_capital',
        render: (price, record, index) => {
          return <div>{(parseInt(price) / 1000000).toFixed(2)}</div>
        }
      },
      {
        title: '注册时间',
        dataIndex: 'register_time',
      },
      {
        title: '操作',
        dataIndex: 'operation',
        width: 300,
        render: (text, record) => (
          <div>
            <Link className={style.btn} to={`/admin/company/info/baseInfo?id=${record.id}`}>查看</Link>
            <div className="separate-line"></div>
            <Link className={style.btn} to={`/admin/company/addPaymentRecord?id=${record.id}`}>添加缴费记录</Link>
            <div className="separate-line"></div>
            <span className={style.btn}>
              <Popconfirm title={<div>你确定要删除用户{record.id}吗?</div>} onConfirm={_ => this.deleteCompany([record.id])}>
                <span >删除</span>
              </Popconfirm>
            </span>
          </div>
        ),
      }
    ];
    return (
      <div className={style.companyList}>
        <Breadcrumb className={style.title}>
          <Breadcrumb.Item><Link to="/admin">首页</Link></Breadcrumb.Item>
          <Breadcrumb.Item><Link to="/admin/company/list">公司管理</Link></Breadcrumb.Item>
          <Breadcrumb.Item>公司列表</Breadcrumb.Item>
        </Breadcrumb>
        <div className={style.filters}>
          <div className={style.item}>
            {/* <NavLink to="/admin/user/add"><Button type="primary" icon="plus">新增</Button></NavLink> */}
            <Dropdown overlay={<Menu>
              <Menu.Item>
                <div onClick={_ => {
                  let idArr = [];
                  for (let v of this.state.selectedRows) {
                    idArr.push(v.id)
                  }
                  if (idArr.length < 1) {
                    return message.error('请选择要删除的公司', 2)
                  }
                  this.deleteCompany(idArr)
                }}>删除</div>
              </Menu.Item>
            </Menu>} className={style.drown} placement="bottomLeft">
              <Button>更多操作 <Icon type="down" /></Button>
            </Dropdown>
            <Button className={style.filterBtn} type="primary" icon="filter"
              onClick={_ => {
                this.setState({
                  hasFilter: true,
                })
              }}>筛选</Button>
            <Button className={style.filterBtn} type="danger" icon="export"
              onClick={_ => {
                this.setState({
                  isExportTable: true,
                })
              }}
            >导出</Button>
          </div>
          <Search
            placeholder="请输入公司名称|法人姓名..."
            enterButton="搜索"
            onSearch={val => {
              let page = this.state.page;
              let filter = this.state.filter;
              page = 1;
              filter.search = trim(val);
              this.setState({
                page,
                filter,
              }, _ => {
                companyApi.getList({ page: this.state.page, limit: this.state.limit, filter: this.state.filter }).then(json => {
                  this.setState({
                    list: json.data.list,
                  })
                }).catch(err => {
                  message.error('获取用户列表失败 ' + err.msg, 3)
                })
              })
            }}
            className={style.search}
          />
        </div>
        <Table className={style.table} columns={columns} dataSource={this.state.list}
          onChange={this.handleChange} rowKey="id" loading={this.state.tableLoding}
          rowSelection={{
            onChange: (selectedRowKeys, selectedRows) => {
              this.setState({
                selectedRows,
              })
            },
          }}
        />
        <Modal title="筛选" visible={this.state.hasFilter} okText="确认" cancelText="取消"
          onCancel={_ => { this.setState({ hasFilter: false, }) }} onOk={this.onFilter}
        >
          <Form className={style.formList}  >
            {/* <div className={style.item}>
              <div className={style.title}>管理员</div>
              {getFieldDecorator('administrators', { initialValue: -1 })(
                <RadioGroup>
                  <Radio value={-1}>全部</Radio>
                  <Radio value={0}>是</Radio>
                  <Radio value={1}>否</Radio>
                </RadioGroup>
              )}
            </div>
            <div className={style.item}>
              <div className={style.title}>账号</div>
              {getFieldDecorator('accountCondition', { initialValue: TYPE.undefined })(
                <Select className={style.condition}>
                  <Option value={TYPE.undefined}>请选择</Option>
                  <Option value={TYPE.eqString}>{TYPE_ZH[TYPE.eqString]}</Option>
                  <Option value={TYPE.like}>{TYPE_ZH[TYPE.like]}</Option>
                  <Option value={TYPE.notLike}>{TYPE_ZH[TYPE.notLike]}</Option>
                </Select>
              )}
              {getFieldDecorator('accountVal', {})(
                <Input placeholder="请输入" className={style.input} />
              )}
            </div>
            <div className={style.item}>
              <div className={style.title}>昵称</div>
              {getFieldDecorator('nickCondition', { initialValue: TYPE.undefined })(
                <Select className={style.condition}>
                  <Option value={TYPE.undefined}>请选择</Option>
                  <Option value={TYPE.eqString}>{TYPE_ZH[TYPE.eqString]}</Option>
                  <Option value={TYPE.like}>{TYPE_ZH[TYPE.like]}</Option>
                  <Option value={TYPE.notLike}>{TYPE_ZH[TYPE.notLike]}</Option>
                </Select>
              )}
              {getFieldDecorator('nickVal', {})(
                <Input placeholder="请输入" className={style.input} />
              )}
            </div>
            <div className={style.item}>
              <div className={style.title}>性别</div>
              {getFieldDecorator('genderCondition', { initialValue: TYPE.undefined })(
                <Select className={style.condition}>
                  <Option value={TYPE.undefined}>请选择</Option>
                  <Option value={TYPE.eq}>{TYPE_ZH[TYPE.eq]}</Option>
                  <Option value={TYPE.neq}>{TYPE_ZH[TYPE.neq]}</Option>
                </Select>
              )}
              {getFieldDecorator('genderVal', { initialValue: "-1" })(
                <Select className={style.input}>
                  <Option value="-1">全部</Option>
                  <Option value="0">未知</Option>
                  <Option value="1">男</Option>
                  <Option value="2">女</Option>
                </Select>
              )}
            </div>
            <div className={style.item}>
              <div className={style.title}>手机号码</div>
              {getFieldDecorator('phoneCondition', { initialValue: TYPE.undefined })(
                <Select className={style.condition}>
                  <Option value={TYPE.undefined}>请选择</Option>
                  <Option value={TYPE.eqString}>{TYPE_ZH[TYPE.eqString]}</Option>
                  <Option value={TYPE.like}>{TYPE_ZH[TYPE.like]}</Option>
                  <Option value={TYPE.notLike}>{TYPE_ZH[TYPE.notLike]}</Option>
                </Select>
              )}
              {getFieldDecorator('phoneVal', {})(
                <InputNumber placeholder="请输入" className={style.input} />
              )}
            </div>
            <div className={style.item}>
              <div className={style.title}>邮箱</div>
              {getFieldDecorator('emailCondition', { initialValue: TYPE.undefined })(
                <Select className={style.condition}>
                  <Option value={TYPE.undefined}>请选择</Option>
                  <Option value={TYPE.eqString}>{TYPE_ZH[TYPE.eqString]}</Option>
                  <Option value={TYPE.like}>{TYPE_ZH[TYPE.like]}</Option>
                  <Option value={TYPE.notLike}>{TYPE_ZH[TYPE.notLike]}</Option>
                </Select>
              )}
              {getFieldDecorator('emailVal', {})(
                <Input placeholder="请输入" className={style.input} />
              )}
            </div> */}
            <div className={style.item}>
              <div className={style.title}>注册时间</div>
              {getFieldDecorator('dateCondition', { initialValue: TYPE.undefined })(
                <Select className={style.condition} onChange={val => { this.setState({ dateSelect: val, }); }}
                >
                  <Option value={TYPE.undefined}>请选择</Option>
                  <Option value={TYPE.before}>{TYPE_ZH[TYPE.before]}</Option>
                  <Option value={TYPE.after}>{TYPE_ZH[TYPE.after]}</Option>
                  <Option value={TYPE.betweenTime}>{TYPE_ZH[TYPE.betweenTime]}</Option>
                </Select>
              )}
              {getFieldDecorator('dateBeformVal', {})(
                <DatePicker format={"YYYY-MM-DD"} className={this.state.dateSelect === TYPE.before ? style.input : style.hidden} />
              )}
              {getFieldDecorator('dateBetweenVal', {})(
                <RangePicker format={"YYYY-MM-DD"} className={this.state.dateSelect === TYPE.betweenTime ? style.input : style.hidden} />
              )}
              {getFieldDecorator('dateAfterVal', {})(
                <DatePicker format={"YYYY-MM-DD"} className={this.state.dateSelect === TYPE.after ? style.input : style.hidden} />
              )}
            </div>
            {/* <div className={style.item}>
              <div className={style.title}>地址</div>
              {getFieldDecorator('addressCondition', { initialValue: TYPE.undefined })(
                <Select className={style.condition}>
                  <Option value={TYPE.undefined}>请选择</Option>
                  <Option value={TYPE.eq}>{TYPE_ZH[TYPE.eq]}</Option>
                  <Option value={TYPE.neq}>{TYPE_ZH[TYPE.neq]}</Option>
                </Select>
              )}
              {getFieldDecorator('addressVal', {})(
                <Cascader options={this.state.region} className={style.input} placeholder="请选择地址" />
              )}
            </div> 
            <div className={style.item}>
              <div className={style.title}>年龄 (岁)</div>
              {getFieldDecorator('ageCondition', { initialValue: TYPE.undefined })(
                <Select className={style.condition} onChange={val => this.setState({ ageSelect: val, })}>
                  <Option value={TYPE.undefined}>请选择</Option>
                  <Option value={TYPE.eq}>{TYPE_ZH[TYPE.eq]}</Option>
                  <Option value={TYPE.lt}>{TYPE_ZH[TYPE.lt]}</Option>
                  <Option value={TYPE.gt}>{TYPE_ZH[TYPE.gt]}</Option>
                  <Option value={TYPE.neq}>{TYPE_ZH[TYPE.neq]}</Option>
                  <Option value={TYPE.betweenValue}>{TYPE_ZH[TYPE.betweenValue]}</Option>
                </Select>
              )}
              {getFieldDecorator('ageVal', {})(
                <InputNumber placeholder="请输入" min={0} max={120} className={this.state.ageSelect === TYPE.betweenValue ? style.hidden : style.input} />
              )}
              <div className={this.state.ageSelect === TYPE.betweenValue ? style.age : style.hidden}>
                <div className={style.ageInput}>{this.state.minAgeBetween}</div>
                {getFieldDecorator('ageBetweenVal', { initialValue: [0, 100] })(
                  <Slider className={style.slider} range
                    onChange={ageArr => {
                      this.setState({
                        minAgeBetween: ageArr[0],
                        maxAgeBetween: ageArr[1],
                      })
                    }}
                  />
                )}
                <div className={style.ageInput}>{this.state.maxAgeBetween}</div>
              </div>
            </div>
            <div className={style.item}>
              <div className={style.title}>类型</div>
              {getFieldDecorator('typeCondition', { initialValue: TYPE.undefined })(
                <Select className={style.condition}>
                  <Option value={TYPE.undefined}>请选择</Option>
                  <Option value={TYPE.in}>{TYPE_ZH[TYPE.in]}</Option>
                  <Option value={TYPE.notIn}>{TYPE_ZH[TYPE.notIn]}</Option>
                </Select>
              )}
              {getFieldDecorator('typeVal', {})(
                <Select mode="multiple" placeholder="请选择" className={style.input}>
                  {this.state.typeChildren.map((v, k) => {
                    return (<Option key={`${k}`} value={v.value}>{v.label}</Option>);
                  })}
                </Select>
              )}
            </div>*/}
          </Form>

        </Modal>
        {/* 单击导出 */}
        <Modal
          title="导出公司数据"
          visible={this.state.isExportTable}
          onOk={this.exportTable}
          onCancel={() => {
            return new Promise(s => {
              this.setState({
                isExportTable: false,
              }, s)
            })
          }}
        >
          <RangePicker className={style.datePicker} value={this.state.chooseDateSection}
            format={"YYYY-MM-DD HH:mm"}
            onChange={chooseDateSection => {
              this.setState({
                chooseDateSection,
              })
            }}
          />
        </Modal>
      </div>
    );
  }
}
export default Form.create()(CompanyList);
