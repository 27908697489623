import { bpost, bget, } from './api';

export async function addOperation({ name, action, description, }) {
  return bpost({
    url: 'addOperation',
    data: {
      name, action, description,
    },
  })
}
export async function deleteOperation(id) {
  return bpost({
    url: 'deleteOperation',
    data: {
      id,
    }
  })
}
export async function deleteOperationList(idArr) {
  return bpost({
    url: 'deleteOperation',
    data: {
      idArr,
    }
  })
}
export async function editOperation({ id, name, action, description, }) {
  return bpost({
    url: 'editOperation', data: {
      id, name, action, description,
    },
  })
}
export async function getDetail(id) {
  return bget({
    url: 'operationDetail', query: {
      id,
    }
  })
}
export async function getOperationList(page, limit, filter = {}) {
  return bget({
    url: 'operationList', query: {
      page,
      limit,
      filter,
    }
  })
}
/// 操作组
export async function addOperationGroup({ name, description, operationList, operationGroupList, }) {
  return bpost({
    url: 'addOperationGroup',
    data: {
      name, description, operationList, operationGroupList,
    },
  })
}
export async function deleteOperationGroup(id) {
  return bpost({
    url: 'deleteOperationGroup',
    data: {
      id,
    }
  })
}
export async function deleteOperationGroupList(idArr) {
  return bpost({
    url: 'deleteOperationGroup',
    data: {
      idArr,
    }
  })
}
export async function editOperationGroup({ id, name, description, operationList, operationGroupList, }) {
  return bpost({
    url: 'editOperationGroup',
    data: {
      id, name, description, operationList, operationGroupList,
    },
  })
}
export async function getGroupDetail(id) {
  return bget({
    url: 'operationGroupDetail', query: {
      id,
    }
  })
}

export async function getGroupList(page, limit, filter = {}) {
  return bget({
    url: 'operationGroupList', query: {
      page,
      limit,
      filter,
    },
  })
}

export default {
  addOperation,
  deleteOperation,
  deleteOperationList,
  editOperation,
  getDetail,
  getOperationList,

  addOperationGroup,
  deleteOperationGroup,
  deleteOperationGroupList,
  editOperationGroup,
  getGroupDetail,
  getGroupList,
}
