import React from "react";
import {
  Form, InputNumber, Button, message, Breadcrumb, Select, DatePicker,
} from 'antd';
import { Link } from "react-router-dom";
import gSass from '../../../utils/sass';
import companyApi, { TYPE, TYPE_ZH, STATUS, STATUS_ZH, } from "../../../services/company";
import { SUBJECT_TYPE, } from "../../../services/subject";
import moment from "moment";
import qs from "qs";

const style = gSass.admin.company.addPaymentRecord;
const Option = Select.Option;
const { MonthPicker, } = DatePicker;


class PaymentRecordAdd extends React.Component {
  constructor(props) {
    super(props);
    const search = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    this.state = {
      companyId: search.id || null,
      companyList: [],
    }
  }
  componentDidMount() {
    this.init();
  }
  init = async _ => {
    let { data: { list: oriCompanyList, } } = await companyApi.getList({ page: -1, limit: -1 })
    let companyList = [];
    for (let v of oriCompanyList) {
      companyList.push({
        id: v.id,
        title: v.companyName,
      })
    }
    this.setState({
      companyList,
    })

  }
  //添加用户提交表单
  addPaymentRecord = evt => {
    evt && evt.preventDefault();
    this.props.form.validateFields((err, fields) => {
      if (err) {
        return;
      }
      companyApi.addPaymentRecord({
        subjectId: fields.companyId,
        subjectType: SUBJECT_TYPE.company,
        paymentType: fields.type,
        paymentStatus: fields.status,
        date: moment(fields.date).format("YYYY-MM-01 00:00:00"),
        money: parseInt(fields.money) * 100,
      }).then(json => {
        this.props.form.resetFields();
        message.success('添加成功', 1);
      }).catch(err => {
        message.error('添加失败,失败原因: ' + err.msg, 3);
        console.log(err);
      })
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        sm: {
          span: 4,
          offset: 4,
        },
      },
      wrapperCol: {
        sm: {
          span: 8,
          offset: 0,
        },
      },
    };


    return (
      <div className={style.paymentRecordAdd}>
        <Breadcrumb className={style.title}>
          <Breadcrumb.Item><Link to="/">首页</Link></Breadcrumb.Item>
          <Breadcrumb.Item>公司管理</Breadcrumb.Item>
          <Breadcrumb.Item><Link to="/admin/user/add">添加缴费记录</Link></Breadcrumb.Item>
        </Breadcrumb>
        <Form className={style.form}>
          <Form.Item {...formItemLayout} label="公司">
            {getFieldDecorator('companyId', {
              initialValue: parseInt(this.state.companyId),
              rules: [{ required: true, message: '请选择公司', },],
            })(
              <Select placeholder="请选择" disabled={!!this.state.companyId}>
                {this.state.companyList.map((v, k) => {
                  return (<Option key={`${k}`} value={v.id}>{v.title}</Option>);
                })}

              </Select>
            )}
          </Form.Item>
          <Form.Item {...formItemLayout} label="缴费类型">
            {getFieldDecorator('type', {
              rules: [{ required: true, message: '请选择缴费类型', },],
            })(
              <Select placeholder="请选择">
                {Object.keys(TYPE).map(typeKey => TYPE[typeKey]).map((v, k) => {
                  return (<Option key={k + ""} value={v}>{TYPE_ZH[v]}</Option>);
                })}
              </Select>
            )}
          </Form.Item>
          <Form.Item {...formItemLayout} label="缴费状态">
            {getFieldDecorator('status', {
              rules: [{ required: true, message: '请选择缴费状态', },],
            })(
              <Select placeholder="请选择">
                {Object.keys(STATUS).map(statusKey => STATUS[statusKey]).map((v, k) => {
                  return (<Option key={k + ""} value={v}>{STATUS_ZH[v]}</Option>);
                })}
              </Select>
            )}
          </Form.Item>
          <Form.Item {...formItemLayout} label="缴费时间">
            {getFieldDecorator('date', {
              rules: [{ required: true, message: '请选择缴费时间', },],
            })(
              <MonthPicker style={{ width: "100%", }} placeholder="请选择" />
            )}
          </Form.Item>
          <Form.Item {...formItemLayout} label="缴费金额 (元)">
            {getFieldDecorator('money', {
              rules: [{ required: true, message: '请输入缴缴费金额', },],
            })(
              <InputNumber precision={2} style={{ width: "100%", }} placeholder="请输入" />
            )}
          </Form.Item>
          <Form.Item
            wrapperCol={{
              xs: {
                span: 7,
                offset: 8,
              },
            }}
          >
            <Button size="large" className={style.btn} type="primary" onClick={this.addPaymentRecord}>添加</Button>
          </Form.Item>
        </Form>
      </div >
    );
  }
}
export default Form.create()(PaymentRecordAdd);
