import React from "react";
import {
  List,
  ActivityIndicator,
  NavBar,
  Icon,
  InputItem,
  Button,
  Toast,
  Modal,
  Flex,
  Radio
} from "antd-mobile";
import { Form, Upload } from "antd";
import { BASE_URL, } from "../../../../config/prod";
import { Link, withRouter } from "react-router-dom";
import gStyle from "../../../../utils/sass";
import mealCardApi from "../../../../services/mealCard";
import { IdentityCodeValid as identityCodeValid } from "../../../../utils/utils";
import ApplyWithSendMsg from "../../../../components/index/user/apply/ApplyWithSendMsg";
import applyApi, { APPLY_STATUS } from "../../../../services/apply";
import { GENDER, GENDER_ZH } from "../../../../services/user";
const style = gStyle.index.user.apply.mealCard;
const Item = List.Item;
const RadioItem = Radio.RadioItem;
let uploadList = [];
const upload = {
  action: BASE_URL + '/antdUploadImg',
  onChange({ file, fileList }) {
    if (file.status !== 'uploading') {
      if (file.response.code === 0) {
        uploadList.push(file.response.data)
      } else {
        console.log(file.response.msg);
      }
    }
  },
}
class MealCard extends ApplyWithSendMsg {
  isShowDetail = false;
  constructor(props) {
    super(props);
    this.isShowDetail = props.isShowDetail || false;
    this.applyId = props.applyId;
    this.state = {
      isAnimating: true,
      identityCodeValidRecode: true,
      isModalActive: false,
      modalBody: "",
      modalFooter: [],
      applyDetail: {
        id: 1,
        steps: [],
        gender: 1,
      },
      genders: [
        { value: GENDER.MAN, label: GENDER_ZH[GENDER.MAN] },
        { value: GENDER.WOMAN, label: GENDER_ZH[GENDER.WOMAN] },
      ],
      uploadStateList: [],
      isAllowEdit: false,
    };
  }
  componentDidMount() {
    this.init();
  }
  genderChange = val => {
    let applyDetail = this.state.applyDetail;
    applyDetail.gender = val
    this.setState({
      applyDetail
    })
  };
  init = async _ => {
    if (this.isShowDetail) {
      const { setFieldsValue } = this.props.form;
      try {
        let {
          data: { detail },
        } = await applyApi.getApplyDetail({ id: this.applyId });
        let isAllowEdit = false;
        if (detail.status === APPLY_STATUS['process']) {
          isAllowEdit = true;
        }
        this.setState({
          isAllowEdit,
        })
        let uploadStateList = this.state.uploadList;
        uploadStateList = []
        if (detail.info.file.length > 0) {
          for (let v of detail.info.file) {
            uploadStateList.push({
              uid: (v.id).toString(),
              name: v.name,
              url: BASE_URL + v.url,
            })
          }
        }
        this.setState({
          uploadStateList,
        })
        uploadList = detail.info.file || []
        setFieldsValue({
          idCardName: detail.info.idCardName,
          idCardNo: detail.info.idCardNo,
          contactInfo: detail.info.contactInfo,
          gender: detail.info.gender
        });
        let applyDetail = this.state.applyDetail;
        applyDetail.id = detail.apply_uid;
        applyDetail.steps = detail.steps;
        this.setState({
          isAllowEdit,
          applyDetail,
        });
      } catch (err) {
        console.log(err);
      }
    }
    this.setState({
      isAnimating: false,
    });
  };
  apply = _ => {
    this.props.form.validateFields(async (err, fieldsValue) => {
      if (err) {
        let firstErrKey = Object.keys(err)[0];
        if (firstErrKey !== 'msg') {
          return Toast.fail(err[firstErrKey].errors[0].message, 2);
        }
      }
      let fields = this.props.form.getFieldsValue(),
        contactInfo = fields.contactInfo,
        idCardNo = fields.idCardNo;
      if (this.state.isAllowEdit) {
        for (let i = 0; i < uploadList.length; i++) {
          if (uploadList[i].picId) {
            uploadList[i].uid = uploadList[i].picId.toString();
            delete uploadList[i].picId
          }
        }
        mealCardApi
          .edit({
            applyId: this.applyId,
            admin: false,
            name: fields.idCardName,
            phone: contactInfo,
            idCardNo: idCardNo,
            gender: this.state.applyDetail.gender,
            file: uploadList,
          })
          .then(() => {
            this.setState({
              isAllowEdit: false,
            })
            Toast.success("修改成功", 1);
          })
          .catch(err => {
            Toast.fail("修改失败,失败原因: " + err.msg, 1);
          });
      } else {
        try {
          await mealCardApi.applyMealCard({
            file: uploadList,
            idCardName: fields.idCardName,
            idCardNo: idCardNo,
            contactInfo: contactInfo,
            idGender: this.state.applyDetail.gender,
          });
          this.setState({
            isModalActive: true,
            modalBody: <div>提交成功,请等待审核</div>,
            modalFooter: [{ text: "确定", onPress: () => this.props.history.push("/") }],
          });
        } catch (err) {
          this.setState({
            isModalActive: true,
            modalBody: <div>提交失败,错误信息:{err.msg}</div>,
            modalFooter: [{ text: "确定", onPress: () => this.setState({ isModalActive: false }) }],
          });
        }
      }
    });
  };
  onReset = _ => {
    this.props.form.resetFields();
  };
  //身份证验证
  identityCodeValid = async data => {
    let result = await identityCodeValid(data);
    console.log(result)
    if (data === "") {
      Toast.fail("身份证不能为空", 1);
      this.setState({
        identityCodeValidRecode: false,
      });
      return false;
    }
    if (!result.pass) {
      Toast.fail("身份证不正确" + result.msg, 2);
      this.setState({
        identityCodeValidRecode: false,
      });
    }
    return true;
  };

  getApplyDetailDom = _ => {
    const { getFieldDecorator, getFieldError } = this.props.form;
    return (
      <form className={style.form}>
        <List>
          {getFieldDecorator("idCardName", {
            rules: [{ required: true, message: "请输入申请人姓名" }],
          })(
            <InputItem
              clear
              className={style.input}
              placeholder="请输入"
              error={!!getFieldError("idCardName")}
              extra={<Icon size="md" color="#ccc" type="right" />}>
              申请人
            </InputItem>,
          )}
          {getFieldDecorator("idCardNo", {
            rules: [{ required: true, message: "请输入身份证号码" }],
          })(
            <InputItem
              className={style.input}
              placeholder="请输入"
              error={!!getFieldError("idCardNo")}
              onBlur={val => this.identityCodeValid(val)}
              extra={<Icon size="md" color="#ccc" type="right" />}>
              身份证号码
            </InputItem>,
          )}
          {getFieldDecorator("contactInfo", {
            rules: [{ required: true, message: "请输入手机号码" }],
          })(
            <InputItem
              className={style.input}
              placeholder="请输入"
              error={!!getFieldError("contactInfo")}
              type="digit"
              extra={<Icon size="md" color="#ccc" type="right" />}>
              手机号码
            </InputItem>,
          )}
          {getFieldDecorator("gender", {
            initialValue: this.state.applyDetail.gender,
            rules: [{ required: true, message: "请选择性别" }],
          })(
            <Item className={style.gender}>
              <Flex justify="between">
                <div className={style.title}>性别</div>
                <Flex>
                  {this.state.genders.map(i => (
                    <RadioItem
                      key={i.value}
                      className={style.genderLine}
                      checked={i.value === this.state.applyDetail.gender}
                      onChange={_ => this.genderChange(i.value)}>
                      {i.label}
                    </RadioItem>
                  ))}
                </Flex>
              </Flex>
            </Item>,
          )}
          <div className={style.tips}>提示:请上传申请当月社保缴纳通知书</div>
          <Upload {...upload} defaultFileList={this.state.uploadStateList} class={style.uploadWrap}>
            <Button className={style.uploadBtn} >
              上传
            </Button>
          </Upload>
        </List>
        {
          this.state.isAllowEdit ? (
            <div className={style.primary}>
              <Button size="small" type="primary" className={style.btn} inline onClick={this.apply}>修改提交</Button>
            </div>
          ) : ''
        }
        {this.getChatInfo()}
      </form>
    );
  };

  render() {
    return this.isShowDetail ? (
      this.getApplyDetailDom()
    ) : (
        <div className={style.mealCard}>
          <ActivityIndicator
            animating={this.state.isAnimating}
            className={style.loading}
            text="loading..."
          />
          <NavBar
            mode="light"
            icon={
              <Link to="/">
                <Icon type="left" />
              </Link>
            }>
            饭卡申请
        </NavBar>
          {this.getApplyDetailDom()}
          <div className={style.operation}>
            <Button size="small" className={style.btn} inline onClick={this.onReset}>
              重置
          </Button>
            <Button size="small" type="primary" className={style.btn} inline onClick={this.apply}>
              提交
          </Button>
          </div>
          <Modal
            visible={this.state.isModalActive}
            transparent
            maskClosable={false}
            title="提示"
            footer={this.state.modalFooter}>
            {this.state.modalBody}
          </Modal>
        </div>
      );
  }
}
export default withRouter(Form.create()(MealCard));
