import React, { Component, } from 'react';
import { Route, Switch, } from 'react-router-dom';
import All from '../../../pages/admin/apply/AllApplyList';
import CoffeeHouseRentList from '../../../pages/admin/apply/CoffeeHouseRentList';
import CompanyEnterList from '../../../pages/admin/apply/CompanyEnterList';
import ConferenceRoomRentList from '../../../pages/admin/apply/ConferenceRoomRentList';
import CreateProjectList from '../../../pages/admin/apply/CreateProjectList';
import MealCardList from '../../../pages/admin/apply/MealCardList';
import ParkingPermitList from '../../../pages/admin/apply/ParkingPermitList';
import RecruitList from '../../../pages/admin/apply/RecruitList';
import SchoolInfoList from '../../../pages/admin/apply/SchoolInfoList';
import IdCardRealNameList from '../../../pages/admin/apply/IdCardRealNameList';
import IdCardRealNameInfo from '../../../pages/admin/apply/IdCardRealNameInfo';
import SchoolInfo from '../../../pages/admin/apply/SchoolInfo';
import CreateProjectInfo from '../../../pages/admin/apply/CreateProjectInfo';
import CompanyEnterInfo from '../../../pages/admin/apply/CompanyEnterInfo';
import ConferenceRoomRentInfo from '../../../pages/admin/apply/ConferenceRoomRentInfo';
import CoffeeHouseRentInfo from '../../../pages/admin/apply/CoffeeHouseRentInfo';
import MealCardInfo from '../../../pages/admin/apply/MealCardInfo';
import ParkingPermitInfo from '../../../pages/admin/apply/ParkingPermitInfo';
import RecruitInfo from '../../../pages/admin/apply/RecruitInfo';
import EditIdCardRealNameInfo from '../../../pages/admin/apply/EditIdCardRealNameInfo';
import EditSchoolInfo from '../../../pages/admin/apply/EditSchoolInfo';
import EditProjectInfo from '../../../pages/admin/apply/EditProjectInfo';
import EditCompanyEnterInfo from '../../../pages/admin/apply/EditCompanyEnterInfo';
import EditConferenceRoomRentInfo from '../../../pages/admin/apply/EditConferenceRoomRentInfo';
import EditCoffeeHouseRentInfo from '../../../pages/admin/apply/EditCoffeeHouseRentInfo';
import EditMealCardInfo from '../../../pages/admin/apply/EditMealCardInfo';
import EditParkingPermitInfo from '../../../pages/admin/apply/EditParkingPermitInfo';
import EditRecruitInfo from '../../../pages/admin/apply/EditRecruitInfo';
import MergeInfoList from '../../../pages/admin/apply/MergeInfoList';
import MergeInfoDetail from '../../../pages/admin/apply/MergeInfoDetail';
import EditMergeInfo from '../../../pages/admin/apply/EditMergeInfo';
export default class Wrap extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }
  render() {
    return (
      <Switch>
        <Route exact path="/admin/apply/allList" component={All} />
        <Route exact path="/admin/apply/createProjectList" component={CreateProjectList} />
        <Route exact path="/admin/apply/companyEnterList" component={CompanyEnterList} />
        <Route exact path="/admin/apply/conferenceRoomRentList" component={ConferenceRoomRentList} />
        <Route exact path="/admin/apply/coffeeHouseRentList" component={CoffeeHouseRentList} />
        <Route exact path="/admin/apply/mealCardList" component={MealCardList} />
        <Route exact path="/admin/apply/parkingPermitList" component={ParkingPermitList} />
        <Route exact path="/admin/apply/recruitList" component={RecruitList} />
        <Route exact path="/admin/apply/schoolInfoList" component={SchoolInfoList} />

        <Route exact path="/admin/apply/idCardRealNameList" component={IdCardRealNameList} />
        <Route exact path="/admin/apply/idCardRealNameInfo" component={IdCardRealNameInfo} />

        <Route exact path="/admin/apply/schoolInfo" component={SchoolInfo} />

        <Route exact path="/admin/apply/createProjectInfo" component={CreateProjectInfo} />

        <Route exact path="/admin/apply/companyEnterInfo" component={CompanyEnterInfo} />
        <Route exact path="/admin/apply/conferenceRoomRentInfo" component={ConferenceRoomRentInfo} />
        <Route exact path="/admin/apply/coffeeHouseRentInfo" component={CoffeeHouseRentInfo} />
        <Route exact path="/admin/apply/mealCardInfo" component={MealCardInfo} />
        <Route exact path="/admin/apply/parkingPermitInfo" component={ParkingPermitInfo} />
        <Route exact path="/admin/apply/recruitInfo" component={RecruitInfo} />
        <Route exact path="/admin/apply/editIdCardRealNameInfo" component={EditIdCardRealNameInfo} />
        <Route exact path="/admin/apply/editSchoolInfo" component={EditSchoolInfo} />
        <Route exact path="/admin/apply/editProjectInfo" component={EditProjectInfo} />
        <Route exact path="/admin/apply/editCompanyEnterInfo" component={EditCompanyEnterInfo} />
        <Route exact path="/admin/apply/editConferenceRoomRentInfo" component={EditConferenceRoomRentInfo} />
        <Route exact path="/admin/apply/editCoffeeHouseRentInfo" component={EditCoffeeHouseRentInfo} />
        <Route exact path="/admin/apply/editMealCardInfo" component={EditMealCardInfo} />
        <Route exact path="/admin/apply/editParkingPermitInfo" component={EditParkingPermitInfo} />
        <Route exact path="/admin/apply/editRecruitInfo" component={EditRecruitInfo} />
        <Route exact path="/admin/apply/mergeInfoList" component={MergeInfoList} />
        <Route exact path="/admin/apply/mergeInfoDetail" component={MergeInfoDetail} />
        <Route exact path="/admin/apply/editMergeInfo" component={EditMergeInfo} />
      </Switch>
    );
  }

}
