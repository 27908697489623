import React from 'react';
import {
  Breadcrumb, Form, Steps, Alert, Spin, Divider, Tooltip, Empty, Radio, Upload, Button
} from 'antd';
import { Link, } from "react-router-dom";
import gSass from '../../../utils/sass';
import csn from 'classnames';
import qs from "qs";
import { LocalStorage as storage } from "jsbdk";
import applyApi from "../../../services/apply";
import AuditWithSendMsg from '../../../components/admin/apply/AuditWithSendMsg';
import { BASE_URL } from "../../../config/prod";
const style = gSass.admin.apply.mealCardInfo;
const Step = Steps.Step;
const RadioGroup = Radio.Group;
let uploadList = [];
// 上传附件
const upload = {
  action: BASE_URL + '/antdUploadImg',
  onChange({ file, fileList }) {
    if (file.status !== 'uploading') {
      if (file.response.code === 0) {
        uploadList.push(file.response.data)
      } else {
        console.log(file.response.msg);
      }
    }
  },
  // defaultFileList: uploadList,

}
class info extends AuditWithSendMsg {
  constructor(props) {
    super(props)
    let { id } = qs.parse(props.location.search, { ignoreQueryPrefix: true, })
    id = parseInt(id);
    this.state = {
      id,
      loading: true,
      userNick: "",
      gender: 0,
      uploadStateList: [],
      applyDetail: {
        info: {
          id: null,
          idCardName: "",
          idCardNo: "",
          contactInfo: "",
        },
        apply_uid: 0,
        current: 1,
        total: 1,
        steps: []
      },
    };
  }

  componentDidMount() {
    this.init();
  }

  init = async _ => {
    try {
      const { data: { detail: applyDetail, } } = await applyApi.getApplyDetail({ id: this.state.id });
      console.log(applyDetail)
      let gender = applyDetail.info.gender
      let uploadStateList = this.state.uploadStateList;

      console.log(applyDetail.info.file.length)
      if (applyDetail.info.file.length > 0) {
        for (let v of applyDetail.info.file) {
          uploadStateList.push({
            uid: v.id,
            name: v.title,
            url: BASE_URL + v.url
          })
        }
      } else {
        uploadStateList = []
      }
      this.setState({
        loading: false,
        userNick: storage.get('nick'),
        applyDetail,
        gender,
        uploadStateList,
      })
    } catch (err) {
      console.log(err);
    }
  }
  closeApply = _ => { }
  render() {
    return (
      <div className={style.info}>
        <Breadcrumb className={style.title}>
          <Breadcrumb.Item><Link to="/admin">首页</Link></Breadcrumb.Item>
          <Breadcrumb.Item><Link to="/admin/apply/allList">申请管理</Link></Breadcrumb.Item>
          <Breadcrumb.Item>饭卡流程</Breadcrumb.Item>
        </Breadcrumb>
        <div className={style.prompt}>
          <Alert description=" 审核人可以审核之前与申请人进行沟通, 给予申请人在申请中出现的问题做答复" type="info" showIcon message="提示" />
        </div>
        <Spin spinning={this.state.loading} size="large">
          <div className={style.content}>
            <div className={style.step}>
              <Steps current={this.state.applyDetail.current - 1}>
                {this.state.applyDetail.steps.map((v, k) => {
                  return <Step key={k} title={v.name} description={<div>
                  </div>} />
                })}
              </Steps>
            </div>
            <div className={style.userInfo}>
              <div className={style.infoList}>
                <div className={style.item}>
                  <div className={style.name}>申请人:</div>
                  <div className={style.detail}>{this.state.applyDetail.info.idCardName}</div>
                </div>
                <div className={style.item}>
                  <div className={style.name}>身份证号码  :</div>
                  <div className={style.detail}>{this.state.applyDetail.info.idCardNo}</div>
                </div>
                <div className={style.item}>
                  <div className={style.name}>联系方式:</div>
                  <div className={style.detail}>{this.state.applyDetail.info.contactInfo}</div>
                </div>
                <div className={style.item}>
                  <div className={style.name}>性别:</div>
                  <RadioGroup className={style.detail}>
                    <Radio value={true} checked={this.state.gender === 0 ? true : false}>男</Radio>
                    <Radio value={false} checked={this.state.gender === 1 ? true : false}>女</Radio>
                  </RadioGroup>
                </div>
                <div className={style.itemUpload}>
                  <div className={style.tips}>提示:申请当月社保缴纳通知书</div>
                  <Upload className={style.uploadWrap} {...upload} defaultFileList={this.state.uploadStateList}>
                    <Button className={style.uploadBtn} >
                      上传
                  </Button>
                  </Upload>
                </div>
              </div>
              <div className={style.chargeUserList}>
                <div className={style.theme}>审核人列表</div>
                <div className={style.reviewStyle}>审核方式:
                <span className={style.reviewStyleTitle}>
                    {this.state.applyDetail.steps.length > 0 ? this.state.applyDetail.steps[this.state.applyDetail.current - 1].should_all_pass === 1 ?
                      <Tooltip title='“会签”：要求在每一轮审核中所有审核人员都通过该申请才会进入下一轮审核。'>会签</Tooltip> :
                      <Tooltip title='“或签”：要求在每一轮审核中其中一个审核人通过该申请就进入下一轮申请。'>或签</Tooltip> : null}
                  </span>
                </div>
                {this.state.applyDetail.steps.length > 0 ? this.state.applyDetail.steps[this.state.applyDetail.current - 1].charge_user_list.map((v, k) => {
                  return <div key={k} className={csn(style.item, { [style.stepIsPass]: v.charge_status === 1 }, { [style.stepIsReject]: v.charge_status === 2, },
                    { [style.stepIsProcess]: v.charge_status === 0, })}>
                    <div className={style.chargeUser}>
                      <div className={style.nick}>审核人: {v.nick}</div>
                      <div className={style.result}>{v.charge_status === 1 ? "通过" : v.charge_status === 2 ? "拒绝" : "审核中"}</div>
                    </div>
                    <Divider style={{ height: 50, }} type="vertical" />
                  </div>
                }) : <Empty />}
                <div className={style.chargeUserMsg}>审核通过后自动进入下一审核流程</div>
              </div>
            </div>
            <div className={style.msgList}>
              <div className={style.msgListTitle}>审核人员与申请人对话列表</div>
              <Divider style={{ margin: 0, }} />
              {this.state.applyDetail.steps.length > 0 ?
                this.state.applyDetail.steps[this.state.applyDetail.current - 1].messages.length > 0 ?
                  this.state.applyDetail.steps[this.state.applyDetail.current - 1].messages.map((v, k) => {
                    return <div key={k} className={style.item}>
                      {v.send_user.id === this.state.applyDetail.apply_uid ?
                        <div className={style.author}>
                          <div className={style.msgSendTime}>{v.ctime.substr(0, 10)}</div>
                          <div className={style.msgSendUserNick}>{v.send_user.nick}</div>
                        </div> :
                        <div className={style.author}>
                          <div className={style.msgSendUserNick}>{v.send_user.nick}</div>
                          <div className={style.msgSendTime}>{v.ctime.substr(0, 10)}</div>
                        </div>}
                      <div className={csn(style.msg, { [style.selfMsg]: v.send_user.id === this.state.applyDetail.apply_uid })}>
                        <div className={style.message}>{v.msg}</div>
                      </div>
                    </div>
                  }) : <Empty /> : <Empty />}
            </div>
            {this.getFooterOperationDom()}
          </div>
        </Spin>
      </div>
    );
  }
}
export default Form.create()(info);
