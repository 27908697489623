import React, { Component } from "react";
import {
  Form, Input, Icon, Button, message,
} from 'antd';
import { NavBar } from "antd-mobile";
import { Link, } from "react-router-dom";
import userApi from "../../../../services/user";
import gSass from '../../../../utils/sass';

const style = gSass.index.user.common.modifyPassword;


class ModifyPassword extends Component {
  formItemLayout = {
    wrapperCol: {
      xs: { span: 20, offset: 2, },
      sm: { span: 5, offset: 10, },
    },
  };

  constructor(props) {
    super(props);
    this.state = {

    }
  }

  modifyPwd = evt => {
    evt.preventDefault();
    this.props.form.validateFields((err, fields) => {
      if (err !== null) {
        return;
      }
      userApi.modifyPwd({ oriPwd: fields.oldPassword, newPwd: fields.password, rePwd: fields.rePwd }).then(json => {
        message.success('修改成功', 1);
      }).catch(err => {
        message.error('修改失败,失败原因: ' + err.msg, 1);
        console.log(err);
      })
    });
  }

  //原密码
  validateToOldPassword = (rule, val, cb) => {
    //原密码检测

    cb();
  }
  //phone确认密码
  validateToNextPassword = (rule, val, cb) => {
    const form = this.props.form;
    if (val && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    cb();
  }
  compareToFirstPassword = (rule, value, cb) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('password')) {
      cb('两次密码不一致!');
    } else {
      cb();
    }
  }
  handleConfirmBlur = (e) => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  }


  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className={style.modifyPassword}>
        <NavBar className={style.navbar} mode="light" icon={<Link to="/?tab=my"><Icon type="left" /></Link>}>修改密码</NavBar>
        <div>
          <Form className={style.form}>
            <Form.Item {...this.formItemLayout} >
              {getFieldDecorator('oldPassword', {
                rules: [{ min: 4, max: 32, required: true, message: '请输入原密码', }, {
                  validator: this.validateToOldPassword,
                }],
              })(
                <Input.Password prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  size="large" type="password" placeholder="请输入原密码" />
              )}
            </Form.Item>
            <Form.Item {...this.formItemLayout} >
              {getFieldDecorator('password', {
                rules: [{ min: 4, max: 32, required: true, message: '请输入4-32位密码', }, {
                  validator: this.validateToNextPassword,
                }],
              })(
                <Input.Password prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  size="large" type="password" placeholder="请输入4-32位密码" />
              )}
            </Form.Item>
            <Form.Item  {...this.formItemLayout}  >
              {getFieldDecorator('rePwd', {
                rules: [{ required: true, message: "请确认密码", }, {
                  validator: this.compareToFirstPassword,
                }],
              })(
                <Input.Password prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                  size="large" type="password" placeholder="请确认密码" onBlur={this.handleConfirmBlur}
                />
              )}
            </Form.Item>
            <Form.Item {...this.formItemLayout}>
              <div className={style.control}>
                <Button type="primary" className={style.btn} size="large"
                  onClick={this.modifyPwd} htmlType="submit"
                >确认修改</Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}

const WrappedLogin = Form.create()(ModifyPassword);

export default WrappedLogin;
