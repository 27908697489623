import React, { Component } from "react";
import {
  Table,
  Button,
  Input,
  Popconfirm,
  Tooltip,
  Form,
  Modal,
  message,
  Upload,
  Icon,
  Select,
} from "antd";
import qs from "qs";
import Lightbox from "react-images";
import gSass from "../../../utils/sass";
import { TYPE } from "../../../utils/constant";
import parkingPermitApi from "../../../services/parkingPermit";
import companyApi from "../../../services/company";
import { getUploadImgUrl } from "../../../services/api";

const style = gSass.admin.company.ParkingPermit;
const Option = Select.Option;

class CompanyParkingPermit extends Component {
  constructor(props) {
    super(props);
    let { id } = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    id = parseInt(id);
    this.state = {
      companyId: id,
      hasFilter: false,
      tableLoding: true,
      edit: false,
      add: false,
      addParkingPermit: false,
      lightboxIsOpen: false,
      previewVisible: false,
      previewImage: "",
      currentItemIdx: TYPE.undefined,
      filter: {
        id,
        search: "",
      },
      list: [],
      corporateList: [],
      selectedRows: [],
      lightBoxImgs: [],
      driverLicensePic: [],
      drivingLicensePic: [],
    };
  }

  componentDidMount() {
    this.init();
  }

  init = async _ => {
    try {
      const {
        data: { list },
      } = await companyApi.getParkingPermitList({ page: -1, limit: -1, filter: this.state.filter });
      this.setState({
        tableLoding: false,
        list,
      });
    } catch (e) {
      console.log(e);
      this.setState({
        tableLoding: false,
      });
      message.error("获取停车证列表失败");
    }
  };
  handleChange = (pagination, filters, sorter) => {
    //筛选(排序,页码)
    // console.log('Various parameters', pagination, filters, sorter);
    // this.setState({
    //   filteredInfo: filters,
    //   sortedInfo: sorter,
    // });
  };
  //删除
  deleteCompany = idArr => {
    // const list = this.state.list,
    //   selectedRows = this.state.selectedRows;
    // user.deleteUsers(idArr).then(json => {
    //   message.success('删除成功', 1)
    //   this.setState({
    //     list: list.filter(item => !idArr.includes(item.id)),
    //     selectedRows: selectedRows.filter(item => !idArr.includes(item.id))
    //   });
    // }).catch(err => {
    //   message.error("删除失败,错误原因: " + err.msg, 3)
    // })
  };
  //编辑
  edit = evt => {
    evt.preventDefault();
    this.props.form.validateFields(
      ["companyId", "parkingPermitId", "name", "contactInfo"],
      (err, fields) => {
        if (err) {
          return;
        }
        console.log({
          companyId: this.state.companyId,
          parkingPermitId: this.state.currentItemIdx,
          name: fields.name,
          contactInfo: fields.contactInfo,
        });
        parkingPermitApi
          .editCompanyParkingPermit({
            companyId: this.state.companyId,
            parkingPermitId: this.state.currentItemIdx,
            name: fields.name,
            contactInfo: fields.contactInfo,
          })
          .then(_ => {
            message.success("修改成功", 1);
          })
          .catch(err => {
            message.error("修改失败, 失败原因: " + err.msg, 1);
            console.log(err);
          });
      },
    );
  };
  //添加停车证
  addCompanyParkingPermit = evt => {
    evt.preventDefault();
    this.props.form.validateFields(
      ["owner", "contactInfo", "driverLicensePic", "drivingLicensePic"],
      (err, fields) => {
        if (err) {
          return;
        }
        if (!this.state.driverLicensePic) {
          message.error("请上传驾驶证证件照", 1);
          return;
        }

        if (this.state.drivingLicensePic.length === 0) {
          message.error("请上传行驶证证件照", 1);
          return;
        }
        let idCardNo = "";
        for (let v of this.state.corporateList) {
          if (v.id === fields.owner) {
            idCardNo = v.idCardNo;
          }
        }
        console.log({
          companyId: this.state.companyId,
          owner: fields.owner,
          contactInfo: fields.contactInfo,
          idCardNo,
          driverLicensePic: this.state.driverLicensePic,
          drivingLicensePic: this.state.drivingLicensePic,
        });
      },
    );
  };
  closeLightbox = _ => {
    this.setState({
      lightboxIsOpen: false,
      lightBoxImgs: [],
    });
  };
  //上传图片
  handlePreview = file => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true,
    });
  };
  driverLicensePicChange = ({ fileList }) => this.setState({ driverLicensePic: fileList });
  drivingLicensePicChange = ({ fileList }) => this.setState({ drivingLicensePic: fileList });
  handleCancel = _ => this.setState({ previewVisible: false });

  render() {
    const { getFieldDecorator } = this.props.form;
    //头像上传按钮
    const { previewVisible, previewImage, driverLicensePic, drivingLicensePic } = this.state;
    const formItemLayout = {
      labelCol: {
        sm: {
          span: 5,
          offset: 0,
        },
      },
      wrapperCol: {
        sm: {
          span: 16,
          offset: 0,
        },
      },
    };
    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">上传图片</div>
      </div>
    );
    const columns = [
      {
        title: "ID",
        dataIndex: "id",
      },
      {
        title: "车主",
        dataIndex: "name",
        render: (name, record, index) => {
          return (
            <Tooltip
              placement="right"
              title={_ => {
                return (
                  <div>
                    <div>法人id: {record.legalPerson.id}</div>
                    <div>身份证号: {record.legalPerson.idCardNo}</div>
                  </div>
                );
              }}>
              {name}
            </Tooltip>
          );
        },
      },
      {
        title: "手机号码",
        dataIndex: "contactInfo",
        render: (val, row) => {
          return <div>{row.legalPerson.contactInfo}</div>;
        },
      },
      {
        title: "驾驶证图片",
        dataIndex: "driverLicensePicId",
        render: val => {
          return (
            <div
              onClick={_ => {
                this.setState({
                  lightboxIsOpen: true,
                  lightBoxImgs: [{ src: val }],
                });
              }}>
              <img style={{ width: 100, cursor: "pointer" }} src={val} alt="驾驶证图片" />
            </div>
          );
        },
      },
      {
        title: "行驶证图片",
        dataIndex: "drivingLicensePic",
        render: val => {
          return (
            <div
              onClick={_ => {
                this.setState({
                  lightboxIsOpen: true,
                  lightBoxImgs: [{ src: val }],
                });
              }}>
              <img style={{ width: 100, cursor: "pointer" }} src={val} alt="驾驶证图片" />
            </div>
          );
        },
      },
      {
        title: "时间",
        dataIndex: "time",
        render: time => {
          return <div>{time.substr(0, 10)}</div>;
        },
      },
      {
        title: "操作",
        dataIndex: "operation",
        width: 300,
        render: (text, record, idx) => (
          <div>
            <span
              className={style.btn}
              onClick={_ => {
                this.setState({
                  edit: true,
                  currentItemIdx: idx,
                });
              }}>
              编辑
            </span>
            <div className="separate-line" />
            <span className={style.btn}>
              <Popconfirm
                title={<div>你确定要删除xx公司的xx用户的停车证吗?</div>}
                onConfirm={_ => this.deleteCompany([record.id])}>
                <span>删除</span>
              </Popconfirm>
            </span>
          </div>
        ),
      },
    ];
    return (
      <div className={style.companyList}>
        <div className={style.filters}>
          <Button
            type="primary"
            icon="plus"
            onClick={_ => {
              this.setState({
                addParkingPermit: true,
              });
            }}>
            新增
          </Button>
        </div>
        <Table
          className={style.table}
          columns={columns}
          dataSource={this.state.list}
          onChange={this.handleChange}
          rowKey="id"
          loading={this.state.tableLoding}
        />
        <Modal
          visible={this.state.edit}
          title="编辑"
          onOk={this.edit}
          onCancel={_ => {
            this.setState({
              edit: false,
              currentItemIdx: TYPE.undefined,
            });
          }}>
          <Form>
            <Form.Item labelCol={{ span: 4 }} wrapperCol={{ span: 19, offset: 1 }} label="法人姓名">
              {getFieldDecorator("name", {
                initialValue:
                  this.state.currentItemIdx > TYPE.undefined
                    ? this.state.list[this.state.currentItemIdx].name
                    : "",
                rules: [{ required: true, message: "请输入法人姓名" }],
              })(<Input placeholder="请输入" type="text" />)}
            </Form.Item>
            <Form.Item labelCol={{ span: 4 }} wrapperCol={{ span: 19, offset: 1 }} label="手机号码">
              {getFieldDecorator("contactInfo", {
                initialValue:
                  this.state.currentItemIdx > TYPE.undefined
                    ? this.state.list[this.state.currentItemIdx].legalPerson.contactInfo
                    : "",
                rules: [{ required: true, message: "请输入手机号码" }],
              })(<Input type="text" placeholder="请输入" />)}
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title="新增停车证"
          centered
          visible={this.state.addParkingPermit}
          okText="提交"
          onOk={this.addCompanyParkingPermit}
          onCancel={_ => {
            this.setState({
              addParkingPermit: false,
            });
          }}>
          <Form className={style.form}>
            <Form.Item {...formItemLayout} label="车主">
              {getFieldDecorator("owner", {
                rules: [{ required: true, message: "请选择车主" }],
              })(
                <Select showSearch placeholder="请选择" optionFilterProp="children">
                  {this.state.corporateList.map((v, k) => {
                    return (
                      <Option key={`${k}`} value={v.id}>
                        {v.name}
                      </Option>
                    );
                  })}
                </Select>,
              )}
            </Form.Item>
            <Form.Item {...formItemLayout} label="手机号码">
              {getFieldDecorator("contactInfo", {
                rules: [{ required: true, message: "请输入手机号码" }],
              })(<Input size="large" placeholder="请输入" />)}
            </Form.Item>
            <Form.Item {...formItemLayout} label="驾驶证证件照">
              {getFieldDecorator("driverLicensePic", {
                rules: [],
              })(
                <div className="clearfix">
                  <Upload
                    action={getUploadImgUrl()}
                    listType="picture-card"
                    fileList={driverLicensePic}
                    onPreview={this.handlePreview}
                    onChange={this.driverLicensePicChange}>
                    {driverLicensePic.length > 0 ? null : uploadButton}
                  </Upload>
                  <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                    <img alt="example" style={{ width: "100%" }} src={previewImage} />
                  </Modal>
                </div>,
              )}
            </Form.Item>
            <Form.Item {...formItemLayout} label="行驶证证件照">
              {getFieldDecorator("drivingLicensePic", {
                rules: [],
              })(
                <div className="clearfix">
                  <Upload
                    action={getUploadImgUrl()}
                    listType="picture-card"
                    fileList={drivingLicensePic}
                    onPreview={this.handlePreview}
                    onChange={this.drivingLicensePicChange}>
                    {drivingLicensePic.length > 0 ? null : uploadButton}
                  </Upload>
                  <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                    <img alt="example" style={{ width: "100%" }} src={previewImage} />
                  </Modal>
                </div>,
              )}
            </Form.Item>
          </Form>
        </Modal>
        <Lightbox
          images={this.state.lightBoxImgs}
          isOpen={this.state.lightboxIsOpen}
          onClose={this.closeLightbox}
          showCloseButton={false}
          showImageCount={false}
          backdropClosesModal={true}
        />
      </div>
    );
  }
}
export default Form.create()(CompanyParkingPermit);
