import React from "react";
import ApplyWithSendMsg from "../../../../components/index/user/apply/ApplyWithSendMsg";
import {
  List, ActivityIndicator, Icon, InputItem, Button, ImagePicker, Toast, Modal, NavBar, Flex, Radio, Picker, Switch, DatePicker
} from "antd-mobile";
import qs from 'qs';
import moment from "moment";
import idCardRealNameApi from "../../../../services/idCardRealName";
import { Form, message } from 'antd'
import { withRouter, Link, } from "react-router-dom";
import gStyle from "../../../../utils/sass";
import { BASE_URL, } from "../../../../config/prod";
import { antdUploadImg, } from "../../../../services/api";
import { IdentityCodeValid } from "../../../../utils/utils";
import { GENDER, GENDER_ZH } from "../../../../services/user";
import collegeApi from "../../../../services/college";
import schoolSpecialityApi from "../../../../services/schoolSpeciality";
import { EDUCATION, EDUCATION_ZH } from "../../../../services/apply";
import { IS_GRADUATE, IS_OVERSEA } from "../../../../services/schoolInfo";
import applyApi, { APPLY_STATUS } from "../../../../services/apply";
const style = gStyle.index.user.auth.mergeInfo;
const Item = List.Item;
const RadioItem = Radio.RadioItem;
class MergeInfo extends ApplyWithSendMsg {
  isShowDetail = false;
  constructor(props) {
    super(props);
    this.isShowDetail = props.isShowDetail || false
    if (this.isShowDetail === false) {
      const { location: { search } } = props
      const searchData = qs.parse(search, { ignoreQueryPrefix: true })
      if ('isShowDetail' in searchData) {
        this.isShowDetail = true
      }
    }
    this.applyId = parseInt(props.applyId);
    this.state = {
      animating: true,
      hasError: false,
      isModalActive: false,
      modalBody: '',
      modalFooter: [],

      applyDetail: {
        info: {
          id: 1,
          name: '',
          email: '',
          age: '',
          phone: '',
          idCardNo: '',
          idCardFirstPic: [],
          idCardSecondPic: [],
          residePic: [],
          gender: 1,
        },
        steps: [],
      },
      genders: [
        { value: GENDER.MAN, label: GENDER_ZH[GENDER.MAN] },
        { value: GENDER.WOMAN, label: GENDER_ZH[GENDER.WOMAN] }
      ],   //性别数组[{value:1,label:"男"},{value:2,label:"女"}]
      householdList: [],//户籍数组

      collegesId: null,//学校的id
      college: [],
      specialityList: [],
      grade: [],//届别
      education: [],//学历
      isGraduate: false, //是否是毕业生
      isOverseas: false,  //是否有海外留学经历
      oneCardPassPic: [], // 一卡通学生证
      degreePic: [],  //学位证
      overseasCard: [],//海外留学毕业证

      applyId: 0,
      isAllowEdit: false,  //是否允许编辑

    }
  }
  componentDidMount() {
    this.init()
  }
  init = async _ => {
    try {
      // 获取户籍
      let { data: { list: householdList } } = await idCardRealNameApi.getProvice({ page: -1, limit: -1 })
      householdList =
        [householdList.map(v => ({
          value: v.id,
          label: v.name
        }))]
      // 获取学院列表
      let { data: { list: college } } = await collegeApi.getList({ page: -1, limit: -1 })
      college = [college.map(v => ({
        value: v.id,
        label: v.name,
      }))]

      //获取学校专业列表
      let { data: { list: specialityList } } = await schoolSpecialityApi.getList({ page: -1, limit: -1 })
      specialityList = [specialityList.map(v => ({
        value: v.id,
        label: v.name,
      }))]
      //获取学历信息
      let education = [Object.values(EDUCATION).map((v, k) => ({
        value: v,
        label: EDUCATION_ZH[v],
      }))]
      let grade = [], date = new Date().getFullYear(), gradeList = [];
      for (let i = 0; i <= (date - 1990); i++) {
        gradeList.push({ value: 1990 + i, label: (1990 + i) })
      }
      grade = [gradeList]
      this.setState({
        householdList,
        college,
        specialityList,
        education,
        grade,
      })
      if (this.isShowDetail) {
        const { setFieldsValue, } = this.props.form;
        try {
          let { data: { detail: applyDetail, } } = await applyApi.getApplyDetail({ id: this.applyId });
          applyDetail.info.idCardFirstPic = [{ url: BASE_URL + applyDetail.info.id_card_first_pic }]
          applyDetail.info.idCardSecondPic = [{ url: BASE_URL + applyDetail.info.id_card_second_pic }]
          applyDetail.info.residePic = [{ url: BASE_URL + applyDetail.info.id_reside_pic }]
          let isAllowEdit = this.state.isAllowEdit;
          if (applyDetail.status === APPLY_STATUS['process']) {
            isAllowEdit = true;
          } else {
            isAllowEdit = false;
          }
          this.setState({
            applyDetail,
            isHouseholdRegister: applyDetail.info.is_shanghai,
            isGraduate: applyDetail.info.isGraduate === IS_GRADUATE.TRUE,
            isOverseas: applyDetail.info.is_overseas === IS_OVERSEA.TRUE,
            degreePic: applyDetail.info.degreePic ? [{ url: BASE_URL + applyDetail.info.degreePic }] : [],
            oneCardPassPic: applyDetail.info.oneCardPassPic ? [{ url: BASE_URL + applyDetail.info.oneCardPassPic }] : [],
            overseasCard: applyDetail.info.overseas_degree_pic_id ? [{ url: BASE_URL + applyDetail.info.overseas_degree_pic_id }] : [],
            isAllowEdit
          })
          let address = ''
          for (let i = 0; i < householdList[0].length; i++) {
            if (householdList[0][i].value === applyDetail.info.idHousehold) {
              address = householdList[0][i].label
            }
          }
          if (address === "上海") {
            this.setState({
              isNoShanghai: false
            })
          } else {
            this.setState({
              isNoShanghai: true
            })
          }
          setFieldsValue({
            college: [applyDetail.info.collegeId],
            speciality: [applyDetail.info.specialityId],
            grade: [applyDetail.info.grade],
            education: [applyDetail.info.educationId],
            // graduationTime: new Date(applyDetail.info.graduation_time),
            // isGraduate: [applyDetail.info.isGraduate],
            name: applyDetail.info.name,
            idCardNo: applyDetail.info.idCardNo,
            phone: applyDetail.info.idPhone,
            email: applyDetail.info.idEmail,
            age: applyDetail.info.idAge,
            gender: applyDetail.info.gender,
            household: [applyDetail.info.idHousehold],
          })
          if (this.state.isOverseas) {
            setFieldsValue({
              seasCollege: applyDetail.info.overseas_college_id,
              seasSpeciality: applyDetail.info.overseas_speciality_id,
              seasEducation: [applyDetail.info.overseas_education],
              isOverseas: [applyDetail.info.is_overseas],
              seasGraduationTime: new Date(applyDetail.info.overseas_graduation_time),
            })
          }
        } catch (e) {
          console.log(e);
        }

      } else {
        this.setState({
          animating: false
        })
      }
    } catch (err) {
      console.log(err.msg)
    }
  }
  // 改变性别
  genderChange = val => {
    let applyDetail = this.state.applyDetail
    console.log(val)
    applyDetail.info.gender = val
    this.setState({
      applyDetail
    })
  }
  // 改变户籍
  changeHouseholder = val => {
    let num = val[0], householdList = this.state.householdList, address = "";
    for (let i = 0; i < householdList[0].length; i++) {
      if (householdList[0][i].value === num) {
        address = householdList[0][i].label
      }
    }
    if (address === "上海") {
      this.setState({
        isNoShanghai: false
      })
    } else {
      this.setState({
        isNoShanghai: true
      })
    }
  }
  // 身份证验证
  idCardVerification = async val => {
    let result = await IdentityCodeValid(val)
    console.log(result)
    if (result.pass) {
      this.setState({
        idCardPass: true,
      });
    } else {
      this.setState({
        idCardPass: false,
      });
      Toast.fail(result.msg, 3)
    }
    let idCardNo = this.state.applyDetail.info.idCardNo;
    this.setState({
      idCardNo,
    })
  }
  // 选择学校改变对应专业
  changeCollege = _ => {
    const { setFieldsValue } = this.props.form;
    let fields = this.props.form.getFieldsValue()
    console.log(fields)
    let { collegesId, specialityList } = this.state
    collegesId = fields.college[0]
    specialityList = []
    collegeApi.getSpecialityList(collegesId).then(json => {
      let detail = json.data.list
      for (let v of detail) {
        specialityList.push(v)
      }
      console.log(specialityList)
      let activeList = this.state.specialityList
      activeList = [[]]
      for (let v of specialityList) {
        activeList[0].push({
          value: v.id,
          label: v.name,
        })
      }
      setFieldsValue({
        speciality: []
      })
      this.setState({
        specialityList: activeList
      })
    }).catch(err => {
      message.error("修改失败,失败原因" + err.msg, 1)
    })
  }
  // 提交基本信息表单
  apply = () => {
    this.props.form.validateFields(['name', 'phone', 'email', 'age', 'idCardNo', "isHouseholdRegister", "household",], { force: true }, async err => {
      if (err !== null) {
        return Toast.fail("填写错误", 2)
      }
      let fields = this.props.form.getFieldsValue()
      if (fields.college.length === 0) {
        return Toast.fail("请选择学院", 1);
      }
      if (fields.speciality.length === 0) {
        return Toast.fail("请选择专业", 1);
      }
      if (fields.grade.length === 0) {
        return Toast.fail("请选择年级", 1);
      }
      if (fields.education.length === 0) {
        return Toast.fail("请选择学历", 1);
      }
      if (this.state.isGraduate) {
        if (this.state.degreePic.length === 0) {
          return Toast.fail("请上传学位证", 1);
        }
      } else {
        if (this.state.oneCardPassPic.length === 0) {
          return Toast.fail("请上传一卡通", 1);
        }
      }
      try {
        if (this.state.isAllowEdit) {
          // return false;
          await idCardRealNameApi.mergeInfoEdit({
            applyId: this.applyId,
            idCardName: fields.name,
            idPhone: fields.phone,
            idAge: fields.age,
            idEmail: fields.email,
            idGender: this.state.applyDetail.info.gender,
            residePic: this.state.applyDetail.info.residePic[0] || '',
            household: fields.household[0],
            idCardNo: fields.idCardNo,
            idCardFirstPic: this.state.applyDetail.info.idCardFirstPic[0],
            idCardSecondPic: this.state.applyDetail.info.idCardSecondPic[0],
            // 学校基本信息
            college: fields.college[0],
            speciality: fields.speciality[0],
            grade: parseInt(fields.grade[0]),
            education: fields.education[0],
            isGraduate: fields.isGraduate,
            degreePic: this.state.degreePic[0] || '',
            oneCardPassPic: this.state.oneCardPassPic[0] || '',
            isOverseas: fields.isOverseas,
            seasEducation: fields.seasEducation === undefined ? [] : fields.seasEducation[0],
            seasSpeciality: fields.seasSpeciality,
            seasCollege: fields.seasCollege,

            seasGraduationTime: moment(fields.seasGraduationTime).format("YYYY-MM-DD HH:mm:ss"),
            overseasCard: this.state.overseasCard[0]
          })
        } else {
          await idCardRealNameApi.mergeInfoApply({
            idCardName: fields.name,
            idPhone: fields.phone,
            idAge: fields.age,
            idEmail: fields.email,
            idGender: this.state.applyDetail.info.gender,
            residePic: this.state.applyDetail.info.residePic[0] || '',
            household: fields.household[0],
            idCardNo: fields.idCardNo,
            idCardFirstPic: this.state.applyDetail.info.idCardFirstPic[0],
            idCardSecondPic: this.state.applyDetail.info.idCardSecondPic[0],
            // 学校基本信息
            college: fields.college[0],
            speciality: fields.speciality[0],
            grade: parseInt(fields.grade[0]),
            education: fields.education[0],
            isGraduate: fields.isGraduate,
            degreePic: this.state.degreePic[0] || '',
            oneCardPassPic: this.state.oneCardPassPic[0] || '',
            isOverseas: fields.isOverseas,
            seasEducation: fields.seasEducation === undefined ? [] : fields.seasEducation[0],
            seasSpeciality: fields.seasSpeciality,
            seasCollege: fields.seasCollege,

            seasGraduationTime: moment(fields.seasGraduationTime).format("YYYY-MM-DD HH:mm:ss"),
            overseasCard: this.state.overseasCard[0]
          })
        }
        this.setState({
          isAllowEdit: false,
          isModalActive: true,
          modalBody: <div>提交成功,请等待审核</div>,
          modalFooter: [{ text: "确定", onPress: () => { this.props.history.push("/") } }]
        })
      } catch (err) {
        this.setState({
          isModalActive: true,
          modalBody: <div>提交失败,错误信息:{err.msg}</div>,
          modalFooter: [{ text: '确定', onPress: () => this.setState({ isModalActive: false, }) }],
        })
      }
    })
  }
  /**
    * 详情
    *  */
  getApplyDetailDom = _ => {
    const { getFieldProps, getFieldDecorator, } = this.props.form;
    return <form className={style.form}>
      <List>
        {/* 姓名,联系电话,年龄,邮箱,性别,户籍,身份证号,身份证照片(正反面) */}
        <InputItem
          {...getFieldProps('name', {
            rules: [{ required: true, },],
          })}
          clear className={style.input} placeholder="请输入姓名"
          extra={<Icon size="md" color="#ccc" type="right"></Icon>}
        >姓名</InputItem>
        <InputItem
          {...getFieldProps('phone', {
            rules: [{ required: true, },],
          })}
          clear className={style.input} placeholder="请输入联系电话"
          extra={<Icon size="md" color="#ccc" type="right"></Icon>}
        >联系电话</InputItem>
        <InputItem
          {...getFieldProps('age', {
            rules: [{ required: true, },],
          })}
          type="digit"
          clear className={style.input} placeholder="请输入年龄"
          extra={<Icon size="md" color="#ccc" type="right"></Icon>}
        >年龄</InputItem>
        <InputItem
          {...getFieldProps('email', {
            rules: [{ required: true, },],
          })}
          clear className={style.input} placeholder="请输入邮箱"
          extra={<Icon size="md" color="#ccc" type="right"></Icon>}
        >邮箱</InputItem>
        {
          getFieldDecorator("gender", {
            initialValue: this.state.applyDetail.info.gender,
            rules: [{ required: true, message: "请选择性别" }]
          })(
            <Item className={style.gender}>
              <Flex justify="between">
                <div className={style.title}>性别</div>
                <Flex>
                  {this.state.genders.map((i, v) => (
                    <RadioItem
                      key={v}
                      className={style.genderLine}
                      checked={i.value === this.state.applyDetail.info.gender}
                      onChange={_ => { this.genderChange(i.value) }}
                    >{i.label}</RadioItem>
                  ))}
                </Flex>
              </Flex>
            </Item>
          )
        }
        {getFieldDecorator('household',
          { rules: [{ required: true, message: '请选择户籍', },], })(
            <Picker data={this.state.householdList} title="选择户籍" cascade={false}
              onOk={(e) => this.changeHouseholder(e)}>
              <List.Item arrow="horizontal">户籍</List.Item>
            </Picker>
          )}
        {this.state.isNoShanghai ? <div>
          <div className={style.toolps}>提示:如果非上海户籍,请上传一张居住证的图片</div>
          <Item>
            <ImagePicker files={this.state.applyDetail.info.residePic}
              onChange={(files, type, index) => {
                if (type === 'add') {
                  let formData = new FormData();
                  formData.append("name", "file");
                  formData.append("file", files[0].file);
                  antdUploadImg(formData).then(json => {
                    const { data: { name, picId, url } } = json
                    let applyDetail = this.state.applyDetail
                    applyDetail.info.residePic = [{
                      name, picId, url: BASE_URL + url,
                    }]
                    this.setState({
                      applyDetail,
                    });
                  }).catch(e => {
                    message.error("上传图片失败", 1)
                    console.log(e)
                  })
                } else if (type === 'remove') {
                  let applyDetail = this.state.applyDetail
                  applyDetail.info.residePic = []
                  this.setState({
                    applyDetail,
                  })
                }
              }}
              selectable={this.state.applyDetail.info.residePic.length < 1}
              multiple={this.state.multiple}
              style={{ flex: 1, }}
            ></ImagePicker>
          </Item>
        </div> : null}

        <InputItem
          {...getFieldProps('idCardNo', {
            rules: [{ required: true, },],
          })}
          className={style.input} placeholder="请输入身份证号码" extra={<Icon size="md" color="#ccc" type="right"></Icon>}
          onBlur={this.iDCardVerification} maxLength={18} minLength={15}
        >身份证号码</InputItem>
        <Item >
          <div className={style.title}>身份证正面照</div>
          <ImagePicker files={this.state.applyDetail.info.idCardFirstPic}
            onChange={(files, type, index) => {
              if (type === 'add') {
                let formData = new FormData();
                formData.append("name", 'file');
                formData.append("file", files[0].file);
                antdUploadImg(formData).then(json => {
                  const { data: { name, picId, url, } } = json;
                  // let idCardFirstPic = [{
                  //   name, picId, url: BASE_URL + url,
                  // }];
                  let applyDetail = this.state.applyDetail;
                  applyDetail.info.idCardFirstPic = [{
                    name, picId, url: BASE_URL + url,
                  }];
                  this.setState({
                    applyDetail,
                  });
                }).catch(e => {
                  message.error('上传图片失败', 1)
                  console.log(e)
                })
              } else if (type === 'remove') {
                // let idCardFirstPic = [];
                let applyDetail = this.state.applyDetail;
                applyDetail.info.idCardFirstPic = [];
                this.setState({
                  applyDetail,
                });
              }
            }}
            selectable={this.state.applyDetail.info.idCardFirstPic.length < 1} style={{ flex: 1, }}
          />
        </Item>
        <Item >
          <div className={style.title}>身份证反面照</div>
          <ImagePicker files={this.state.applyDetail.info.idCardSecondPic}
            onChange={(files, type, index) => {
              if (type === 'add') {
                let formData = new FormData();
                formData.append("name", 'file');
                formData.append("file", files[0].file);
                antdUploadImg(formData).then(json => {
                  const { data: { name, picId, url, } } = json;
                  let applyDetail = this.state.applyDetail;
                  applyDetail.info.idCardSecondPic = [{
                    name, picId, url: BASE_URL + url,
                  }];
                  this.setState({
                    applyDetail,
                  });
                }).catch(e => {
                  message.error('上传图片失败', 1)
                  console.log(e)
                })
              } else if (type === 'remove') {
                let applyDetail = this.state.applyDetail;
                applyDetail.info.idCardSecondPic = []
                this.setState({
                  applyDetail,
                });
              }
            }}
            selectable={this.state.applyDetail.info.idCardSecondPic.length < 1} style={{ flex: 1, }}
          />
        </Item>
        {/* 学校基本信息 */}
        {getFieldDecorator('college',
          { rules: [{ required: true, message: '请选择学院', },], initialValue: this.state.collegesId })(
            <Picker data={this.state.college} title="选择学院" cascade={false}
              onOk={this.changeCollege}>
              <List.Item arrow="horizontal">学校</List.Item>
            </Picker>
          )}
        <Picker {...getFieldProps('speciality', { rules: [{ required: true, },], })}
          data={this.state.specialityList} title="选择专业" cascade={false} >
          <List.Item arrow="horizontal">专业</List.Item>
        </Picker>
        <Picker
          {...getFieldProps('grade', { rules: [{ required: true, },], })}
          data={this.state.grade} title="选择届别" cascade={false}>
          <List.Item arrow="horizontal">届别</List.Item>
        </Picker>
        <Picker
          {...getFieldProps('education', { rules: [{ required: true, },], })}
          data={this.state.education} title="选择学历" cascade={false}>
          <List.Item arrow="horizontal">学历</List.Item>
        </Picker>
        <Item>
          <Flex>
            <div>是否为毕业生</div>
            {/*valuePropName: 子节点的值的属性 */}
            <Switch {...getFieldProps('isGraduate', {
              initialValue: this.state.isGraduate, valuePropName: 'checked',
              onChange: (checked) => {
                this.setState({
                  isGraduate: checked
                })
              }
            })}
              className={style.isGraduate}></Switch>
          </Flex>
        </Item>
        <div className={style.toolps}>提示:如果是毕业生, 请上传学位证图片; 如果不是毕业生, 请上传"一卡通学生证"图片</div>
        <Item className={this.state.isGraduate ? "" : style.hidden}>
          {this.state.isGraduate ? <ImagePicker files={this.state.degreePic}
            onChange={(files, type, index) => {
              if (type === 'add') {
                let formData = new FormData();
                formData.append("name", 'file');
                formData.append("file", files[0].file);
                antdUploadImg(formData).then(json => {
                  const { data: { name, picId, url, } } = json;
                  this.setState({
                    degreePic: [{
                      name, picId, url: BASE_URL + url,
                    }],
                  });
                }).catch(e => {
                  message.error('上传图片失败', 1)
                  console.log(e)
                })
              } else if (type === 'remove') {
                this.setState({
                  degreePic: [],
                });
              }
            }}
            selectable={this.state.degreePic.length < 1} multiple={this.state.multiple} style={{ flex: 1, }}
          /> : null}
        </Item>
        <Item className={!this.state.isGraduate ? "" : style.hidden}>
          {!this.state.isGraduate ? <ImagePicker files={this.state.oneCardPassPic}
            onChange={(files, type, index) => {
              if (type === 'add') {
                let formData = new FormData();
                formData.append("name", 'file');
                formData.append("file", files[0].file);
                antdUploadImg(formData).then(json => {
                  const { data: { name, picId, url, } } = json;
                  this.setState({
                    oneCardPassPic: [{
                      name, picId, url: BASE_URL + url,
                    }],
                  });
                }).catch(e => {
                  message.error('上传图片失败', 1)
                  console.log(e)
                })
              } else if (type === 'remove') {
                this.setState({
                  oneCardPassPic: [],
                });
              }
            }}
            selectable={this.state.oneCardPassPic.length < 1} multiple={this.state.multiple} style={{ flex: 1, }}
          /> : null}
        </Item>
        {/* 是否是海外留学 */}
        <Item>
          <Flex className={style.item}>
            <div className={style.title}>是否是海外留背景</div>
            <Switch
              {...getFieldProps('isOverseas', {
                initialValue: this.state.isOverseas, valuePropName: 'checked',
                onChange: (checked) => {
                  this.setState({
                    isOverseas: checked,
                  })
                },
              })}
              className={style.isGraduate}
            />
          </Flex>
        </Item>
        <Item>
          <div className={this.state.isOverseas ? "" : style.hidden}>
            <div>
              <div className={style.theme}>海外留学背景</div>
              <div className={style.item}>
                <Picker
                  {...getFieldProps("seasEducation", { rules: [{}] })}
                  data={this.state.education} title="选择学历" cascade={false}
                >
                  <List.Item arrow="horizontal">学历</List.Item>
                </Picker>
              </div>
              <InputItem {...getFieldProps("seasCollege", { rules: [{}] })} clear
                placeholder="请输入海外学校" className={style.input}
                extra={<Icon size="md" color="#ccc" type="right"></Icon>}
              > 海外学校</InputItem>
              <InputItem {...getFieldProps("seasSpeciality", { rules: [{}] })} clear
                placeholder="请输入海外专业" className={style.input}
                extra={<Icon size="md" color="#ccc" type="right"></Icon>}
              > 海外专业</InputItem>

              <div className={style.item}>
                <DatePicker
                  {...getFieldProps('seasGraduationTime', {
                    rules: [{ required: true, message: `请选择海外留学背景毕业时间` },],
                  })} mode="date" extra="请选择" >
                  <List.Item arrow="horizontal">毕业时间</List.Item>
                </DatePicker>
              </div>
              <Item >
                <div className={style.title}>海外留学毕业证或在读证明</div>
                <ImagePicker files={this.state.overseasCard}
                  onChange={(files, type, index) => {
                    if (type === 'add') {
                      let formData = new FormData();
                      formData.append("name", 'file');
                      formData.append("file", files[0].file);
                      antdUploadImg(formData).then(json => {
                        const { data: { name, picId, url, } } = json;
                        this.setState({
                          overseasCard: [{
                            name, picId, url: BASE_URL + url,
                          }],
                        });
                      }).catch(e => {
                        message.error('上传图片失败', 1)
                        console.log(e)
                      })
                    } else if (type === 'remove') {
                      this.setState({
                        overseasCard: [],
                      });
                    }
                  }}
                  selectable={this.state.overseasCard.length < 1} multiple={this.state.multiple} style={{ flex: 1, }}
                />
              </Item>
            </div>
          </div>
        </Item>
      </List>
      {
        this.state.isAllowEdit ? (
          <div className={style.operation}>
            <Button size="small" type="primary" className={style.btn} inline onClick={this.apply}>修改提交</Button>
          </div>
        ) : ''
      }
      {this.getChatInfo()}
    </form>
  }
  render() {
    return (this.isShowDetail ? this.getApplyDetailDom() : <div className={style.idCardRealName}>
      {/* 对话框 */}
      <Modal visible={this.state.isModalActive} transparent maskClosable={false}
        title="提示" footer={this.state.modalFooter}
      >{this.state.modalBody}
      </Modal>
      {/* 活动指示器,表明某个任务正在进行中 */}
      <ActivityIndicator animating={this.state.animating} toast className={style.loading}
        text="loading..." />
      {/* 顶部导航栏 */}
      <NavBar className={this.isShowDetail ? style.hidden : null} mode="light"
        icon={<Link to="/?tab=my"><Icon type="left" /></Link>} >基本信息申请</NavBar>
      {this.getApplyDetailDom()}
      <div className={style.operation}>
        <Button size="small" className={style.btn} inline onClick={this.resetForm}>重置</Button>
        <Button size="small" type="primary" className={style.btn} inline onClick={this.apply}>提交</Button>
        {
          this.state.isAllowEdit ? (
            <Button size="small" type="primary" className={style.btn} inline onClick={this.apply}>修改提交</Button>
          ) : null
        }

      </div>
    </div>)
  }
}

export default withRouter(Form.create()(MergeInfo));
