import React, { Component } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import ForgetPassword from "../../../pages/index/user/common/ForgetPassword";
import Register from "../../../pages/index/user/common/Register";
import Login from "../../../pages/index/user/common/Login";
import UserAuthRoute from "../../../utils/UserAuth";
import IndexUserApplyWrap from "../../../components/index/user/apply/Wrap";
import IndexUserAuthWrap from "../../../components/index/user/auth/Wrap";
import Info from "../../../pages/index/user/Info";
import IdCardRealName from "../../../pages/index/user/IdCardRealName";

import SchoolInfo from "../../../pages/index/user/SchoolInfo";
import Settings from "../../../pages/index/user/Settings";
import ModifyPassword from "../../../pages/index/user/common/ModifyPassword";
export default class Wrap extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Switch>
        <Route exact path="/user/forgetPassword" component={ForgetPassword} />
        <Route exact path="/user/register" component={Register} />
        <Route exact path="/user/login" component={Login} />
        <UserAuthRoute path="/user/apply" component={IndexUserApplyWrap} />
        <UserAuthRoute path="/user/info" component={Info} />
        <UserAuthRoute path="/user/settings" component={Settings} />
        <UserAuthRoute path="/user/modifyPassword" component={ModifyPassword} />
        <UserAuthRoute path="/user/auth" component={IndexUserAuthWrap} />
        <UserAuthRoute path="/user/idCardRealName" component={IdCardRealName} />
        <UserAuthRoute path="/user/schoolInfo" component={SchoolInfo} />
        <Redirect to="/user/register" />
      </Switch>
    );
  }
}
