import { bget, bpost } from './api';
export const ROOM_NO_LIST = [
  "207",
  "208",
  "307",
  "308",
  "407",
  "408"
];

export const TYPE = {
  "HATCHONE": 0x0,
  "HATCHTWO": 0x1,
  "EXIT": 0x2,
}
export const TYPE_ZH = [
  "孵化一期",
  "孵化二期",
  "退出"
]
export async function applyConferenceRoomRent({
  roomNo, startTime, endTime, reason,
  //  applyName, applyCompany, userNum, conferenceName,
}) {
  return bpost({
    url: '/apply/conferenceRoomRent',
    data: {
      roomNo, startTime, endTime, reason,
      // applyName, applyCompany, userNum, conferenceName,
    }
  })
}
// 编辑信息
export async function editConferenceRoomRent({ applyUid, applyId, admin = true,
  name, company, startTime, endTime, reason, roomNo
}) {
  return bpost({
    url: '/editConferenceRoomRent',
    data: {
      applyUid, applyId, admin, name, company, startTime, endTime, reason, roomNo
    }
  })
}

export async function conferenceRoomRentDetail(id) {
  return bget({
    url: '/conferenceRoomRent/detail',
    query: {
      id,
    }
  })
}
/**
 * 获取会议室租用情况
 */
export async function getUserRentList({ roomNo, beginDate, endDate }) {
  return bget({
    url: '/admin/getConferenceRoomRentList',
    query: {
      roomNo, beginDate, endDate,
    }
  })
}
/**
 * 获取咖啡吧租用情况
 */
export async function getCafeRentList({ beginDate, endDate }) {
  return bget({
    url: '/admin/getCafeRoomRentList',
    query: {
      beginDate, endDate,
    }
  })
}

/**
 * 获取会议室租用情况
 */
export async function getCoffeeRentList({ beginDate, endDate }) {
  return bget({
    url: '/admin/getCoffeeList',
    query: {
      beginDate, endDate,
    }
  })
}

export default {
  applyConferenceRoomRent,
  conferenceRoomRentDetail,
  getUserRentList,
  getCafeRentList,
  editConferenceRoomRent,
  getCoffeeRentList
}
