import React, { Component, } from 'react';
import { Route, Redirect, } from "react-router-dom";
import { Spin } from 'antd';
import { LocalStorage as storage } from 'jsbdk';
class AdminAuth extends Component {
  checkTimer = null;
  constructor(props) {
    super(props)
    this.state = {
      isPending: true,
      isLogin: false,
    }
  }
  componentDidMount() {
    this.checkIsLogin()
    this.checkTimer = setInterval(_ => {
      let isLogin = storage.has('adminIsLogin');
      if (isLogin !== this.state.isLogin) {
        this.setState({
          isLogin,
        })
        if (isLogin === false) {
          storage.remove('uid')
          storage.remove('nick')
        }
      }
    }, 1000)
  }
  checkIsLogin = _ => {
    this.setState({
      isPending: false,
      isLogin: storage.has('adminIsLogin'),
    })
  }
  componentWillUnmount() {
    clearInterval(this.checkTimer)
  }
  render() {
    const { component: Component, ...rest } = this.props
    return (
      <Route {...rest} render={props => {
        if (this.state.isPending) {
          return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
              <Spin size="large" />
            </div>
          )
        }
        return this.state.isLogin
          ? <Component {...this.props} />
          : <Redirect to="/admin/login" />
      }} />
    )
  }
}
export default AdminAuth;
