import React from "react"
import {
  List,
  TextareaItem,
  ActivityIndicator,
  NavBar,
  Icon,
  Button,
  DatePicker,
  Toast,
  Picker,
  InputItem,
  // Switch, Flex,

} from "antd-mobile"
import { Link, withRouter } from "react-router-dom"
import { Form, Modal, } from "antd"
import gStyle from "../../../../utils/sass"
import conferenceRoomRentApi, { ROOM_NO_LIST } from "../../../../services/conferenceRoomRent"
import applyApi, { APPLY_STATUS } from "../../../../services/apply"
import ApplyWithSendMsg from "../../../../components/index/user/apply/ApplyWithSendMsg"
import moment from "moment"
import userApi from "../../../../services/user";

const style = gStyle.index.user.apply.conferenceRoomRent
const Item = List.Item

class ConferenceRoomRent extends ApplyWithSendMsg {
  constructor(props) {
    super(props)
    this.isShowDetail = props.isShowDetail || false
    this.applyId = props.applyId
    this.state = {
      applyName: "",
      isAnimating: true,
      startTime: null,
      endTime: null,
      reason: "",
      isModalActive: false,
      modalBody: "",
      modalFooter: [],
      conferenceTools: false,
      conferenceSnack: false,
      applyDetail: {
        id: 1,
        steps: [],
      },
      roomList: [],
      fileList: [
        {
          uid: '-1',
          name: 'xxx.png',
          status: 'done',
          url: 'http://www.baidu.com/xxx.png',
        },
      ],
      isAllowEdit: false,
    }
  }
  componentDidMount() {
    this.init()
  }
  handleChange = info => {
    let fileList = [...info.fileList];

    // 1. Limit the number of uploaded files
    // Only to show two recent uploaded files, and old ones will be replaced by the new
    fileList = fileList.slice(-2);

    // 2. Read from response and show file link
    fileList = fileList.map(file => {
      if (file.response) {
        // Component will show file.url as link
        file.url = file.response.url;
      }
      return file;
    });

    this.setState({ fileList });
  };

  init = async _ => {
    const roomList = [
      ROOM_NO_LIST.map(v => ({
        value: v,
        label: v,
      })),
    ]
    await new Promise(s =>
      this.setState(
        {
          roomList,
        },
        s,
      ),
    )
    const { setFieldsValue } = this.props.form
    try {
      let { data: { detail, } } = await userApi.getMyIdRealNameDetail();
      setFieldsValue({
        applyName: detail.name
      })
      console.log(111)
    } catch (e) {
    }

    if (this.isShowDetail) {

      const { setFieldsValue } = this.props.form
      try {
        let {
          data: { detail },
        } = await applyApi.getApplyDetail({ id: this.applyId })
        let isAllowEdit = this.state.isAllowEdit;
        if (detail.status === APPLY_STATUS['process']) {
          isAllowEdit = true;
        } else {
          isAllowEdit = false;
        }
        this.setState({
          isAllowEdit,
        })
        setFieldsValue({
          applyName: [detail.info.name],
          roomNo: [detail.info.roomNo],
          startTime: new Date(detail.info.applyBeginTime),
          endTime: new Date(detail.info.applyEndTime),
          reason: detail.info.reason,
        })
        let applyDetail = detail
        applyDetail.id = detail.apply_uid
        this.setState({
          applyDetail,
        })
      } catch (err) {
        console.log(err)
      }
    }
    this.setState({
      isAnimating: false,
    })
  }
  apply = _ => {
    this.props.form.validateFields((err, fieldsValue) => {
      if (err) {
        let firstErrKey = Object.keys(err)[0]
        if (firstErrKey !== 'msg') {
          return Toast.fail(err[firstErrKey].errors[0].message, 2)
        }
      }

      let fields = fieldsValue
      let $startTime = moment(fields.startTime),
        $endTime = moment(fields.endTime)
      if ($endTime.diff($startTime, "m") > 240) {
        return Toast.fail("每次申请时长不能超过4小时")
      }
      if (this.state.isAllowEdit) {
        conferenceRoomRentApi
          .editConferenceRoomRent({
            applyId: this.applyId,
            admin: false,
            reason: fields.reason || null,
            roomNo: fields.roomNo,
            startTime: $startTime.format("YYYY-MM-DD HH:mm:00"),
            endTime: $endTime.format("YYYY-MM-DD HH:mm:00"),
          })
          .then(() => {
            this.setState({
              isAllowEdit: false,
            })
            Toast.success("修改成功", 1);
          })
          .catch(err => {
            Toast.fail("修改失败,失败原因: " + err.msg, 1);
          });
      } else {
        conferenceRoomRentApi
          .applyConferenceRoomRent({
            // applyName: this.statethis.state.applyName,
            // applyCompany: fields.applyCompany,
            // userNum: fields.userNum,
            // conferenceName: fields.conferenceName,
            // conferenceSnack: this.state.conferenceSnack ? 1 : 0,
            // conferenceTools: this.state.conferenceSnack ? 1 : 0,

            roomNo: fields.roomNo[0],
            startTime: $startTime.format("YYYY-MM-DD HH:mm:00"),
            endTime: $endTime.format("YYYY-MM-DD HH:mm:00"),
            reason: fields.reason || null,
          })
          .then(_ => {
            Toast.success("提交成功, 请等待审核 ", 1, _ => this.props.history.push("/"))
          })
          .catch(err => {
            Toast.fail("提交失败,失败原因: " + err.msg, 3)
          })
      }
    })
  }
  resetForm = _ => {
    this.props.form.resetFields()
  }
  getApplyDetailDom = () => {
    const { getFieldProps, getFieldDecorator, getFieldError } = this.props.form
    return (
      <form className={style.form}>
        <List>
          <div className={style.prompt}>
            <div className={style.tips}>
              一周使用时间不能超过48小时
              <br />
              为了资源共享，每次预定不能超过4小时
            </div>
          </div>
          {getFieldDecorator('applyName',
            { rules: [{ required: true, message: '申请人' },], })(
              <InputItem clear className={style.input} placeholder="请输入" error={!!getFieldError('applyName')}
                extra={<Icon size="md" color="#ccc" type="right"></Icon>} >申请人</InputItem>
            )}
          {/* {getFieldDecorator('applyCompany',
            { rules: [{ required: true, message: '申请人企业' },], })(
              <InputItem clear className={style.input} placeholder="请输入" error={!!getFieldError('applyCompany')}
                extra={<Icon size="md" color="#ccc" type="right"></Icon>} >申请人企业</InputItem>
            )}
          {getFieldDecorator('userNum',
            { rules: [{ required: true, message: '使用人数' },], })(
              <InputItem clear className={style.input} placeholder="请输入" error={!!getFieldError('userNum')}
                type="digit" extra={<Icon size="md" color="#ccc" type="right"></Icon>} >使用人数</InputItem>
            )}
          {getFieldDecorator('conferenceName',
            { rules: [{ required: true, message: '会议室名称' },], })(
              <InputItem clear className={style.input} placeholder="请输入" error={!!getFieldError('conferenceName')}
                extra={<Icon size="md" color="#ccc" type="right"></Icon>} >会议室名称</InputItem>
            )} */}
          <Picker
            {...getFieldProps("roomNo", { rules: [{ required: true, message: `请选择会议室` }] })}
            data={this.state.roomList}
            title="选择会议室"
            cascade={false}>
            <List.Item arrow="horizontal">会议室房间号</List.Item>
          </Picker>
          {/* <Flex className={style.checkedItem}>
            <div className={style.title}>是否需要提供咖啡、茶点</div>
            <Switch
              {...getFieldProps("conferenceSnack", {
                initialValue: this.state.conferenceSnack === true,
                valuePropName: "checked",
                onChange: checked => {
                  this.setState({
                    conferenceSnack: checked,
                  });
                  console.log(checked)
                },
              })}
              className={style.detail}
            />
          </Flex>
          <Flex className={style.checkedItem}>
            <div className={style.title}>是否需要提供会议所需基本工具</div>
            <Switch
              {...getFieldProps("conferenceTools", {
                initialValue: this.state.conferenceTools === true,
                valuePropName: "checked",
                onChange: checked => {
                  this.setState({
                    conferenceTools: checked,
                  });
                  console.log(checked)
                },
              })}
              className={style.detail}
            />
          </Flex> */}
          <DatePicker
            {...getFieldProps("startTime", {
              rules: [{ required: true, message: `请选择开始使用时间` }],
            })}
            mode="datetime"
            extra="请选择"
            ranges={{ begin: [moment(), moment().add(7, "days")] }}>
            <List.Item arrow="horizontal">开始使用时间</List.Item>
          </DatePicker>
          <DatePicker
            {...getFieldProps("endTime", {
              rules: [{ required: true, message: `请选择结束使用时间` }],
            })}
            mode="datetime"
            extra="请选择"
            ranges={{ end: [moment(), moment().add(7, "days")] }}>
            <List.Item arrow="horizontal">结束使用时间</List.Item>
          </DatePicker>
          <Item>
            <div>租用原因</div>
            <TextareaItem
              {...getFieldProps("reason", {})}
              rows={3}
              count={100}
              placeholder="请输入租用原因"
            />
          </Item>
          {/* <Item className={style.uploadWrap}>
            <Upload className={style.uploadList} fileList={this.state.fileList}>
              <Button>
                <Icon type="upload" /> Upload
            </Button>
            </Upload>
          </Item> */}
        </List>
        {
          this.state.isAllowEdit ? (
            <div className={style.primary}>
              <Button size="small" type="primary" className={style.btn} inline onClick={this.apply}>修改提交</Button>
            </div>
          ) : ''
        }
        {this.getChatInfo()}
      </form>
    )
  }
  render() {
    return this.isShowDetail ? (
      this.getApplyDetailDom()
    ) : (
        <div className={style.meettingRoom}>
          <ActivityIndicator
            animating={this.state.isAnimating}
            className={style.loading}
            text="loading..."
          />
          <NavBar
            mode="light"
            icon={
              <Link to="/">
                <Icon type="left" />
              </Link>
            }>
            会议室租用
        </NavBar>
          {this.getApplyDetailDom()}
          <div className={style.operation}>
            <Button size="small" className={style.btn} inline onClick={this.onReset}>
              重置
          </Button>
            <Button size="small" type="primary" className={style.btn} inline onClick={this.apply}>
              提交
          </Button>
          </div>
          <Modal
            visible={this.state.isModalActive}
            transparent
            maskClosable={false}
            title="提示"
            footer={this.state.modalFooter}>
            {this.state.modalBody}
          </Modal>
        </div>
      )
  }
}
export default withRouter(Form.create()(ConferenceRoomRent))
