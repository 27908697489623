import React from "react";
import {
  Form, Input, Button, message, Modal, Spin,
} from 'antd';
import userApi from '../../../services/user';
import gSass from '../../../utils/sass';
import { trim } from 'jsbdk';
import qs from 'qs';

const style = gSass.admin.settings.base;

const confirm = Modal.confirm;
class Security extends React.Component {
  constructor(props) {
    super(props);
    let { location: { search } } = props;
    const { id } = qs.parse(search, { ignoreQueryPrefix: true, })
    this.state = {
      id,
      spinning: true,
      oriPwd: "",
      newPwd: "",
      rePwd: "",
      email: "",
      secutiy: [
        {
          title: "密码强度",
          description: "当前密码强度",
          class: "green",
          detail: "强",
          clickEvt: this.openModifyPwdModal
        },
        {
          title: "密保手机",
          description: "已绑定手机号码",
          class: "",
          detail: "",
          clickEvt: null,
        },
        {
          title: "绑定邮箱",
          description: "已绑定邮箱",
          class: "",
          detail: "",
          clickEvt: null,
        },
      ],
    }
  }
  componentDidMount() {
    this.init();
  }
  init = async _ => {
    const { data: {
      phone,
      email,
    } } = await userApi.adminGetGeneralUserInfo({ id: this.state.id, })
    let security = this.state.secutiy;
    if (phone) {
      security[1].detail = phone.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2");
    }
    if (email) {
      security[2].detail = email.replace(/(\w{2})[^@]*/, '$1****');
    }
    this.setState({
      security,
      spinning: false,
    })
  }
  onEmailChange = evt => {
    this.setState({
      email: evt.target.value,
    })
  }
  modifyPwd = _ => {
    if (this.state.newPwd === "") {
      return message.error("请输入新密码", 1);
    }
    if (this.state.rePwd === "") {
      return message.error("请输入确认密码", 1);
    }
    let data = {
      uid: this.state.id,
      newPwd: this.state.newPwd,
      rePwd: this.state.rePwd,
    }
    userApi.adminModifyGeneralUserPwd(data).then(json => {
      message.success("修改成功", 1);
    }).catch(err => {
      message.error("修改失败,失败原因为 : " + err.msg, 3);
    })
  }
  //打开修改密码modal
  openModifyPwdModal = _ => {
    let that = this;
    confirm({
      title: "修改密码",
      content: (
        <div>
          新密码: <Input.Password style={{ margin: '10px 0' }} min={4} max={32} placeholder="请输入新密码"
            onChange={evt => {
              this.setState({
                newPwd: trim(evt.target.value),
              })
            }}
          />
          确认密码: <Input.Password style={{ margin: '10px 0' }} min={4} max={32} placeholder="请确认新密码"
            onChange={evt => {
              this.setState({
                rePwd: trim(evt.target.value),
              })
            }}
            onPressEnter={that.modifyPwd}
          />
        </div>
      ),
      onOk() {
        that.modifyPwd()
      },
      onCancel() { },
      okText: "确认",
      cancelText: "取消",
    });
  }
  render() {
    return (
      <Spin tip="Loading..." spinning={this.state.spinning} size="large">
        <div className={style.title}>安全设置</div>
        <div className={style.security}>
          {this.state.secutiy.map((v, k) => {
            if (v.detail === '') {
              return null;
            }
            return (<div className={style.box} key={k}>
              <div className={style.securityLeft}>
                <div className={style.securityTitle}>{v.title}</div>
                <div className={style.securityDetail}>{v.description} : <span >{v.detail}</span></div>
              </div>
              <div className={style.securityRight}>
                <Button disabled={!v.clickEvt} onClick={v.clickEvt}>修改</Button>
              </div>
            </div>);
          })}
        </div>
      </Spin>
    );
  }
}

const WrappedLogin = Form.create()(Security);

export default WrappedLogin;
