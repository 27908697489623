import React, { Component } from "react";
import { LocalStorage as storage, } from "jsbdk";
import { message, } from 'antd';
import moment from 'moment';
import { trim, } from 'jsbdk';
import { Form, Input, Button, } from 'antd';
import gSass from '../../../utils/sass';
import applyApi, { STATUS as APPLY_STATUS } from "../../../services/apply";
const style = gSass.admin.apply.idCardRealNameInfo;
const { TextArea } = Input;

class AuditWithSendMsg extends Component {
  isShowDetail = false;
  constructor(props) {
    super(props)
    this.state = {
      id: 0,
      userNick: '',
      applyDetail: {
        steps: [],
      }
    }
  }
  sendMsg = _ => {
    const { validateFields, getFieldValue, setFieldsValue, } = this.props.form;
    validateFields(['msg'], {}, async (err, values) => {
      if (err !== null) {
        console.log(err)
        return;
      }
      let msg = trim(getFieldValue('msg'));
      setFieldsValue({ msg, });
      try {
        await applyApi.sendAuditMsg({ applyId: this.state.id, msg });
        message.success('发送成功', 1)
        let applyDetail = this.state.applyDetail;
        applyDetail.steps[applyDetail.current - 1].messages.push({
          send_user: {
            id: storage.get('uid'),
            nick: this.state.userNick,
          },
          msg,
          ctime: moment().format('YYYY-MM-DD HH:mm:ss'),
        });
        setFieldsValue({ msg: '', });
        this.setState({
          applyDetail,
        })
      } catch (e) {
        console.log(e)
        message.error('发送消息失败,错误信息: ' + e.msg, 3)
      }
    })
  }
  passStep = async _ => {
    try {
      await applyApi.passStep({ applyId: this.state.id });
      message.success('通过流程成功', 1, _ => window.location.reload())
    } catch (e) {
      console.log(e)
      message.error('通过流程成功,错误信息: ' + e.msg, 3)
    }
  }
  rejectStep = async _ => {
    try {
      await applyApi.rejectStep({ applyId: this.state.id });
      message.success('已拒绝', 1, _ => window.location.reload())
    } catch (e) {
      console.log(e)
      message.error('拒绝流程失败,错误信息: ' + e.msg, 3)
    }
  }
  getFooterOperationDom = _ => {
    const { getFieldDecorator } = this.props.form;
    // console.log(this.state)
    return this.state.applyDetail.status === APPLY_STATUS.pass ?
      <div style={{ textAlign: 'center', margin: '30px auto', }}>当前流程已通过</div>
      : this.state.applyDetail.status === APPLY_STATUS.close ?
        <div style={{ textAlign: 'center', margin: '30px auto', }}>当前流程已关闭</div>
        : this.state.applyDetail.needProcess ? <div className={style.sendMsg}>
          <Form>
            <Form.Item>
              {getFieldDecorator("msg", {
                rules: [{ required: true, msg: "请输入内容" }],
              })(
                <TextArea rows={5} placeholder="请输入内容" />
              )}
            </Form.Item>
            <Form.Item className={style.operationList}>
              <Button className={style.item} type="ghost" onClick={this.sendMsg}>发送信息</Button>
              <Button className={style.item} type="primary" onClick={this.passStep}>通过</Button>
              <Button className={style.item} type="ghost" onClick={this.rejectStep}>拒绝</Button>
              <Button className={style.item} type="danger" onClick={this.closeApply}>关闭</Button>
            </Form.Item>
          </Form>
        </div>
          : <div style={{ textAlign: 'center', margin: '30px auto', }}>你不是此申请的处理人</div>
  }
}
export default AuditWithSendMsg
