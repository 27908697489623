import React, { Component } from "react"
import {
  Table,
  Button,
  Breadcrumb,
  Input,
  Icon,
  Menu,
  Dropdown,
  Popconfirm,
  Tooltip,
  Modal,
  message,
  Spin,
  Form,
} from "antd"
import { Link } from "react-router-dom"
import companyIndustry from "../../../services/company"
import gSass from "../../../utils/sass"
import gImg from "../../../utils/img"
import { TYPE as USER_TYPE, TYPE_ZH as USER_TYPE_ZH } from "../../../services/user"
import moment from "moment"

const style = gSass.admin.company.industryList
const Search = Input.Search

//编辑
const FormItem = Form.Item
const EditableContext = React.createContext({})
const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr {...props} />
  </EditableContext.Provider>
)
const EditableFormRow = Form.create()(EditableRow)
//编辑
class EditableCell extends React.Component {
  form = null
  state = {
    editingCell: false,
  }
  componentDidMount() {
    if (this.props.editable) {
      document.addEventListener("click", this.handleClickOutside, true)
    }
  }
  componentWillUnmount() {
    if (this.props.editable) {
      document.removeEventListener("click", this.handleClickOutside, true)
    }
  }
  toggleEdit = _ => {
    const editingCell = !this.state.editingCell
    this.setState({ editingCell }, _ => {
      if (editingCell) {
        this.input.focus()
      }
    })
  }
  handleClickOutside = evt => {
    const { editingCell } = this.state
    if (editingCell && this.cell !== evt.target && !this.cell.contains(evt.target)) {
      this.save()
    }
  }
  save = _ => {
    const { record, handleSave } = this.props
    this.form.validateFields((error, values) => {
      if (error) {
        return
      }
      this.toggleEdit()
      handleSave({ ...record, ...values })
    })
  }
  render() {
    const { editingCell } = this.state
    const { editable, dataIndex, title, record, index, handleSave, ...restProps } = this.props
    return (
      <td ref={node => (this.cell = node)} {...restProps}>
        {editable ? (
          <EditableContext.Consumer>
            {form => {
              this.form = form
              return editingCell ? (
                <FormItem style={{ margin: 0 }}>
                  {form.getFieldDecorator(dataIndex, {
                    rules: [{ required: true, message: `${title} is required.` }],
                    initialValue: record[dataIndex],
                  })(<Input ref={node => (this.input = node)} onPressEnter={this.save} />)}
                </FormItem>
              ) : (
                <div
                  className="editable-cell-value-wrap"
                  style={{ paddingRight: 24 }}
                  onClick={this.toggleEdit}>
                  {restProps.children}
                </div>
              )
            }}
          </EditableContext.Consumer>
        ) : (
          restProps.children
        )}
      </td>
    )
  }
}

class List extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filteredInfo: null,
      sortedInfo: null,
      list: [],
      filter: {},
      selectedRows: [],
      typeChildren: [
        {
          value: USER_TYPE.createProject,
          label: USER_TYPE_ZH[USER_TYPE.createProject],
        },
        {
          value: USER_TYPE.companyEnter,
          label: USER_TYPE_ZH[USER_TYPE.companyEnter],
        },
        {
          value: USER_TYPE.conferenceRoomRent,
          label: USER_TYPE_ZH[USER_TYPE.conferenceRoomRent],
        },
        {
          value: USER_TYPE.coffeeHouseRent,
          label: USER_TYPE_ZH[USER_TYPE.coffeeHouseRent],
        },
        {
          value: USER_TYPE.mealCard,
          label: USER_TYPE_ZH[USER_TYPE.mealCard],
        },
        {
          value: USER_TYPE.parkingPermit,
          label: USER_TYPE_ZH[USER_TYPE.parkingPermit],
        },
        {
          value: USER_TYPE.recruit,
          label: USER_TYPE_ZH[USER_TYPE.recruit],
        },
        {
          value: USER_TYPE.realNameAuth,
          label: USER_TYPE_ZH[USER_TYPE.realNameAuth],
        },
        {
          value: USER_TYPE.schoolInfoAuth,
          label: USER_TYPE_ZH[USER_TYPE.schoolInfoAuth],
        },
      ],
      pagination: {
        current: 1,
        pageSize: 8,
        showTotal: total => {
          return `共${total}条记录`
        },
        onChange: async (page, pageSize) => {
          const pagination = this.state.pagination
          pagination.current = page
          pagination.pageSize = pageSize
          try {
            // 注释掉的东西不能删
            let page = pagination.current,
              limit = pagination.pageSize,
              filter = this.state.filter
            this.setState({
              tableIsLoading: true,
            })
            let json = await companyIndustry.getIndustryList({ page, limit, filter })
            pagination.total = json.count
            this.setState({
              tableIsLoading: false,
              list: json.data.list,
              pagination,
            })
          } catch (err) {
            console.log(err)
            this.setState({
              tableIsLoading: false,
            })
          }
        },
      },
      tableIsLoading: true,
      defaultAvatar: gImg.user.defaultAvatar,
      isAddCompanyIndutryModalActive: false,
      isLoading: false,
      hasFilter: false,
      columns: [
        {
          title: "ID",
          dataIndex: "id",
        },
        {
          title: "行业名",
          dataIndex: "name",
          editable: true,
          render: name => {
            return (
              <div style={{ cursor: "pointer", color: "#1890ff" }}>
                <Tooltip title="点击修改">{name}</Tooltip>
              </div>
            )
          },
        },
        {
          title: "创建时间",
          dataIndex: "ctime",
          render: ctime => {
            return <div>{ctime ? ctime : moment().format("YYYY-MM-DD HH:mm:ss")}</div>
          },
        },
        {
          title: "操作",
          dataIndex: "operation",
          width: 300,
          render: (text, record) =>
            this.state.list.length >= 1 ? (
              <div>
                {/* <span className={style.btn} onClick={_ => this.editComapnyIndustry(record.id)}>编辑</span>
                  <div className="separate-line"></div> */}
                <span className={style.btn}>
                  <Popconfirm
                    title={<div>你确定要删除id为{record.id}的行业吗?</div>}
                    onConfirm={_ => this.deleteCompanyIndustry([record.id])}>
                    <span>删除</span>
                  </Popconfirm>
                </span>
              </div>
            ) : null,
        },
      ],
    }
  }
  componentDidMount() {
    this.init()
  }
  init = async _ => {
    try {
      // 注释掉的东西不能删
      let page = this.state.pagination.current,
        limit = this.state.pagination.pageSize,
        filter = this.state.filter
      let pagination = this.state.pagination
      let json = await companyIndustry.getIndustryList({ page, limit, filter })
      pagination.total = json.count
      this.setState({
        isLoading: false,
        tableIsLoading: false,
        list: json.data.list,
        pagination,
      })
    } catch (err) {
      this.setState({
        isLoading: false,
        tableIsLoading: false,
      })
      console.log(err)
    }
  }
  //table 改变//筛选(排序,页码)
  handleChange = async (pagination, filters, sorter) => {}
  //操作---删除
  deleteCompanyIndustry = async idArr => {
    try {
      await companyIndustry.deleteIndustry(idArr)
      let list = this.state.list
      list = list.filter(v => !idArr.includes(v.id))
      this.setState({
        list,
      })
      message.success("删除成功", 1)
    } catch (err) {
      message.error("删除失败,错误原因: " + err.msg, 3)
    }
  }
  //头部添加按钮------添加
  addCompanyIndustry = evt => {
    this.props.form.validateFields(["name"], (err, fields) => {
      if (err) {
        return
      }
      companyIndustry
        .addIndustry({ name: fields.name })
        .then(json => {
          message.success("添加成功", 1, _ => {
            this.hiddenAddCompanyIndutryModal()
            this.props.form.setFieldsValue({
              name: "",
            })
            this.init()
          })
        })
        .catch(err => {
          console.log(err)
          this.hiddenAddCompanyIndutryModal().then(_ => {
            message.error("添加失败, 失败原因: " + err.msg || err, 3)
          })
        })
    })
  }
  //修改一行
  editComapnyIndustry = async row => {
    let list = this.state.list,
      rowIdx = list.findIndex(item => row.id === item.id),
      item = list[rowIdx]
    if (row.name === item.name) {
      return
    }
    let id = row.id,
      name = row.name
    companyIndustry
      .editIndustry({ id, name })
      .then(_ => {
        list[rowIdx] = row
        this.setState({ list }, _ => {
          message.success("修改成功", 1)
        })
      })
      .catch(err => {
        message.error("修改失败, 失败原因: " + err.msg, 1)
      })
  }
  //搜索
  search = _ => {
    this.props.form.validateFields(["search"], (err, fields) => {
      if (err) {
        return
      }
      console.log(fields.search)
      if (!fields.search) {
        message.info("无搜索内容", 1)
        return
      }
      companyIndustry
        .getIndustryList({
          page: this.state.page,
          limit: this.state.limit,
          filter: {
            searchInput: fields.search,
          },
        })
        .then(json => {
          this.setState({
            list: json.data,
            hasFilter: false,
          })
        })
        .catch(err => {
          console.log(err)
        })
    })
  }

  showAddCompanyIndutryModal = _ => {
    return new Promise(s => {
      this.setState(
        {
          isAddCompanyIndutryModalActive: true,
        },
        s,
      )
    })
  }
  hiddenAddCompanyIndutryModal = _ => {
    return new Promise(s => {
      this.setState(
        {
          isAddCompanyIndutryModalActive: false,
        },
        s,
      )
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form
    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell,
      },
    }
    const columns = this.state.columns.map(col => {
      if (!col.editable) {
        return col
      }
      return {
        ...col,
        onCell: record => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.editComapnyIndustry,
        }),
      }
    })
    return (
      <div className={style.content}>
        <Spin
          spinning={this.state.isLoading}
          className={this.state.isLoading ? style.loading : style.hidden}
          size="large">
          <Breadcrumb className={style.title}>
            <Breadcrumb.Item>
              <Link to="/admin">首页</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/admin/company/list">公司管理</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>公司行业列表</Breadcrumb.Item>
          </Breadcrumb>
          <div className={style.filters}>
            <div className={style.item}>
              <div className={style.flex}>
                <Button type="primary" icon="plus" onClick={this.showAddCompanyIndutryModal}>
                  新增
                </Button>
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item>
                        <div
                          onClick={_ => {
                            let idArr = []
                            for (let v of this.state.selectedRows) {
                              idArr.push(v.id)
                            }
                            if (idArr.length < 1) {
                              return message.error("请选择要删除的行业", 2)
                            }
                            this.deleteCompanyIndustry(idArr)
                          }}>
                          删除
                        </div>
                      </Menu.Item>
                    </Menu>
                  }
                  className={style.drown}
                  placement="bottomLeft">
                  <Button>
                    更多操作 <Icon type="down" />
                  </Button>
                </Dropdown>
              </div>
            </div>
            <Form.Item wrapperCol={{ span: 14 }}>
              {getFieldDecorator("search", {})(
                <Search
                  placeholder="请输入行业名/添加时间, 格式如:(2018-12-12)..."
                  enterButton="搜索"
                  onSearch={this.search}
                  className={style.search}
                />,
              )}
            </Form.Item>
          </div>
          <Table
            className={style.table}
            columns={columns}
            components={components}
            dataSource={this.state.list}
            rowSelection={{
              onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                  selectedRows,
                })
              },
            }}
            onChange={this.handleChange}
            rowKey="id"
            pagination={this.state.pagination}
            loading={this.state.tableIsLoading}
          />
          {/* 点击添加 */}
          <Modal
            title="添加行业"
            visible={this.state.isAddCompanyIndutryModalActive}
            onOk={this.addCompanyIndustry}
            onCancel={this.hiddenAddCompanyIndutryModal}>
            <Form>
              <Form.Item
                label="行业名"
                labelCol={{ span: 3, offset: 3 }}
                wrapperCol={{ span: 14, offset: 1 }}>
                {getFieldDecorator("name", {
                  rules: [
                    { required: true, message: "请输入行业名" },
                    { min: 2, max: 30, message: "行业名长度为2-30" },
                  ],
                })(
                  <Input
                    type="text"
                    autoFocus
                    size="large"
                    placeholder="请输入"
                    onPressEnter={this.addCompanyIndustry}
                  />,
                )}
              </Form.Item>
            </Form>
          </Modal>
        </Spin>
      </div>
    )
  }
}
export default Form.create()(List)
