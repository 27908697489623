import React, { Component, } from 'react';
import {
  Route, Redirect, Switch,
} from 'react-router-dom';
import IdCardRealName from '../../../../pages/index/user/auth/IdCardRealName';
import SchoolInfo from '../../../../pages/index/user/auth/SchoolInfo';
import MergeInfo from '../../../../pages/index/user/auth/MergeInfo';
export default class Wrap extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }
  render() {
    return (
      <Switch>
        <Route exact path="/user/auth/idCardRealName" component={IdCardRealName} />
        <Route exact path="/user/auth/schoolInfo" component={SchoolInfo} />
        <Route exact path="/user/auth/mergeInfo" component={MergeInfo} />
        <Redirect to="/user/register" />
      </Switch>
    );
  }

}
