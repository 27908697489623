import React, { Component, } from 'react';
import {
  Route, Redirect, Switch,
} from 'react-router-dom';
import Index from '../../../pages/index/company/Index';
import MealCard from '../../../pages/index/company/MealCard';
import ParkingPermit from '../../../pages/index/company/ParkingPermit';
export default class Wrap extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }
  render() {
    return (
      <Switch>
        <Route exact path="/company/index" component={Index} />
        <Route exact path="/company/mealCard" component={MealCard} />
        <Route exact path="/company/parkingPermit" component={ParkingPermit} />
        <Redirect to="/" />
      </Switch>
    );
  }

}
