import { bget, bpost } from './api';

export async function applyCoffeeHouseRent({ usePersonNumber, startTime, endTime, provideList }) {
  return bpost({
    url: '/apply/coffeeHouseRent',
    data: {
      usePersonNumber,
      startTime,
      endTime,
      // reason,
      provideList
    }
  })
}
export async function editCoffeeHouseRent({ applyId, admin = true, userNum, startTime, endTime, reason, provideItemList, coffeeId = 0 }) {
  return bpost({
    url: '/editCoffeeHouseRent',
    data: {
      applyId, admin, userNum, startTime, endTime, reason, provideItemList, coffeeId
    }
  })
}

export async function coffeeHouseRentDetail(id) {
  return bget({
    url: '/coffeeHouseRent/detail',
    query: {
      id,
    }
  })
}
// 后台添加咖啡吧

export async function addCoffeeRentInfo({
  name, phone, usePersonNumber, beginTime, endTime
}) {
  return bpost({
    url: '/admin/CoffeeHouseRentTourist/addCoffeeHouseRentTourist',
    data: {
      name, phone, usePersonNumber, beginTime, endTime
    }
  })
}

export const PROVIDES = {
  coffee: 0x0,
  tea: 0x1,
  sound: 0x2,
  projector: 0x3,
  tv: 0x4,
  mike: 0x5,
  notebook: 0x6,
  wireless_headset: 0x7,
}
export const PROVIDES_ZH = {
  [PROVIDES.coffee]: "咖啡",
  [PROVIDES.tea]: "茶点",
  [PROVIDES.sound]: "音响",
  [PROVIDES.projector]: "投影仪",
  [PROVIDES.tv]: "电视",
  [PROVIDES.mike]: "话筒",
  [PROVIDES.notebook]: "笔记本",
  [PROVIDES.wireless_headset]: "无线耳麦",
}



export default {
  applyCoffeeHouseRent,
  coffeeHouseRentDetail,
  editCoffeeHouseRent,
  addCoffeeRentInfo
}
