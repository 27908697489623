import React from "react";
import moment from "moment";
import {
  Form, message, Upload, Icon, Modal, Spin, Breadcrumb, Select, Radio, Input, DatePicker, Button
} from 'antd';
import { Link, } from "react-router-dom";
import schoolSpecialityApi from "../../../services/schoolSpeciality";
import { getUploadImgUrl } from '../../../services/api';
import applyApi, { EDUCATION, EDUCATION_ZH, } from '../../../services/apply';
import collegeApi from '../../../services/college';
import { BASE_URL, } from '../../../config/prod';
import gSass from '../../../utils/sass';
import { IdentityCodeValid } from "../../../utils/utils";
import qs from "qs";
import { IS_GRADUATE } from "../../../services/schoolInfo";
const style = gSass.admin.applyEdit.edit;
const Option = Select.Option;
const RadioGroup = Radio.Group;
//地址
const uploadButton = (
  <div>
    <Icon type="plus" />
    <div className="ant-upload-text">上传图片</div>
  </div>
);
const formItemLayout = {
  labelCol: {
    sm: {
      span: 4,
      offset: 4,
    },
  },
  wrapperCol: {
    sm: {
      span: 8,
      offset: 0,
    },
  },
};

let date = new Date().getFullYear(), gradeList = [];
for (let i = 0; i <= (date - 1990); i++) {
  gradeList.push({ value: i, label: (1990 + i) })
}


class Edit extends React.Component {
  constructor(props) {
    super(props);
    const { id } = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    this.state = {
      projectId: parseInt(id),
      spinning: true,
      previewVisible: false,
      previewImage: '',
      collegeList: [],
      specialityList: [],
      educationList: [],
      grades: gradeList,
      idCardNoValidation: true,
      isGraduate: null,
      isOversea: null,
      //data
      idCardFirstPic: [],
      idCardSecondPic: [],
      oneCardPassPic: [],
      degreePic: [],
      applyId: null,
      overseasCard: [],
      userInfo: {
        schoolInfo: {
          isGraduate: 0,
          isOversea: 0,
        }
      },
    }
  }
  componentDidMount() {
    this.init();
  }
  init = async _ => {
    try {
      let { data: { list: collegeList } } = await collegeApi.getList({ page: -1, limit: -1 })
      collegeList = collegeList.map(v => ({
        value: v.id,
        label: v.name,
      }))
      //获取学校专业列表
      let { data: { list: specialityList } } = await schoolSpecialityApi.getList({ page: -1, limit: -1 })
      specialityList = specialityList.map(v => ({
        value: v.id,
        label: v.name,
      }))
      //  获取学历
      let educationList = Object.values(EDUCATION).map(v => ({
        value: v,
        label: EDUCATION_ZH[v]
      }))
      let { data: { detail, } } = await applyApi.getApplyDetail({ id: this.state.projectId });

      this.setState({
        spinning: false,
        collegeList,
        specialityList,
        educationList,
        oneCardPassPic: detail.info.isGraduate !== IS_GRADUATE.TRUE ? [{
          url: BASE_URL + detail.info.oneCardPassPicList.url,
          uid: detail.info.oneCardPassPicList.id,
        }] : [],
        degreePic: detail.info.isGraduate === IS_GRADUATE.TRUE ? [{
          url: BASE_URL + detail.info.degreePicList.url,
          uid: detail.info.degreePicList.id,
        }] : [],
        overseasCard: detail.info.overseas_degree_pic_id_list ? [{
          url: BASE_URL + detail.info.overseas_degree_pic_id_list.url,
          uid: detail.info.overseas_degree_pic_id_list.id,
        }] : [],
        isGraduate: detail.info.isGraduate,
        isOversea: detail.info.is_overseas,
        applyId: detail.info.id,
      })
      setTimeout(() => {
        this.props.form.setFieldsValue({
          idCardName: detail.info.name,
          college: detail.info.collegeId,
          speciality: detail.info.specialityId,
          grade: detail.info.grade,
          education: detail.info.educationId,
          isGraduate: detail.info.isGraduate,
          isOversea: detail.info.is_overseas,
          overseaCollege: detail.info.overseas_college_id,
          overseaSpeciality: detail.info.overseas_speciality_id,
          overseaEducation: detail.info.overseas_education,
          overseaTime: moment(detail.info.overseas_graduation_time, "YYYY-MM-DD HH:mm:ss"),
        })
      }, 0)

    } catch (e) {
      this.setState({
        spinning: false,
      })
    }
  }
  //提交
  updateUserInfo = evt => {
    evt.preventDefault();
    //学校信息
    this.props.form.validateFields((err, fields) => {
      if (err !== null) {
        return message.error(err[0].errors[0].message, 3);
      }
      if (this.state.isGraduate === 0) {
        if (this.state.oneCardPassPic.length === 0) {
          message.error("请上传一卡通证件照", 1);
          return;
        }
      }
      if (this.state.isGraduate === 1) {
        if (this.state.degreePic.length === 0) {
          message.error("请上传学位证证件照", 1);
          return;
        }
      }
      let { oneCardPassPic, degreePic, overseasCard } = this.state
      if (oneCardPassPic.length !== 0) {
        if (oneCardPassPic[0].response) {
          oneCardPassPic = oneCardPassPic[0].response.data.picId
        } else {
          oneCardPassPic = oneCardPassPic[0].uid
        }
      }
      if (degreePic.length !== 0) {
        if (degreePic[0].response) {
          degreePic = degreePic[0].response.data.picId
        } else {
          degreePic = degreePic[0].uid
        }
      }
      if (overseasCard.length !== 0) {
        if (overseasCard[0].response) {
          overseasCard = overseasCard[0].response.data.picId
        } else {
          overseasCard = overseasCard[0].uid
        }
      }
      schoolSpecialityApi.editSchool({
        idCardName: fields.idCardName,
        schoolId: this.state.applyId,
        college: fields.college,
        speciality: fields.speciality,
        grade: fields.grade,
        education: fields.education,
        isGraduate: fields.isGraduate || this.state.userInfo.schoolInfo.isGraduate,
        isOversea: fields.isOversea || this.state.userInfo.schoolInfo.isOversea,
        overseaCollege: fields.overseaCollege,
        overseaSpeciality: fields.overseaSpeciality,
        overseaTime: moment(fields.overseaTime).format("YYYY-MM-DD HH:mm:ss"),
        overseaEducation: fields.overseaEducation,
        overseasCard: overseasCard,
        oneCardPassPic,
        degreePic,
      }).then(json => {
        message.success('修改成功', 1);
      }).catch(err => {
        message.error('修改失败,失败原因: ' + err.msg, 1);
        console.log(err);
      })
    });
  }
  //上传图片
  handlePreview = file => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true,
    });
  }
  handleCancel = _ => this.setState({ previewVisible: false })
  overseasCardChange = ({ fileList }) => this.setState({ overseasCard: fileList })
  //学校信息
  oneCardPassPicChange = ({ fileList }) => this.setState({ oneCardPassPic: fileList })
  degreePicChange = ({ fileList }) => this.setState({ degreePic: fileList })

  //身份证号码验证
  idCardNoValidation = evt => {
    let val = evt.target.value;
    IdentityCodeValid(val).then(json => {
      this.setState({
        idCardNoValidation: json.pass,
      })
      if (!json.pass) {
        message.error("身份证号码错误: " + json.msg, 1)
      }
    })
  }
  changeCollege = evt => {
    const { setFieldsValue, } = this.props.form;
    // let fields = this.props.form.getFieldsValue();
    let { specialityList } = this.state
    specialityList = [];
    collegeApi.getSpecialityList(evt).then(json => {
      let detail = json.data.list
      for (let v of detail) {
        specialityList.push(v)
      }
      specialityList = specialityList.map(v => ({
        value: v.id,
        label: v.name,
      }))
      this.setState({
        specialityList
      }, () => {
        setFieldsValue({
          speciality: specialityList.value
        })
      })
    }).catch(err => {
      message.error("修改失败, 失败原因: " + err.msg, 1);
    })
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    //头像上传按钮
    const { previewVisible, previewImage,
      oneCardPassPic, degreePic, overseasCard, } = this.state;
    return (
      <Spin tip="Loading..." spinning={this.state.spinning} size="large">
        <div className={style.info}>
          <Breadcrumb className={style.title}>
            <Breadcrumb.Item><Link to="/admin">首页</Link></Breadcrumb.Item>
            <Breadcrumb.Item><Link to="/admin/apply/allList">申请管理</Link></Breadcrumb.Item>
            <Breadcrumb.Item>学校信息编辑</Breadcrumb.Item>
          </Breadcrumb>
          <div className={style.basicWrap}>
            <Form className={style.form}>
              <Form.Item {...formItemLayout} label="姓名">
                {getFieldDecorator("idCardName", {
                  rules: [{ required: true, message: "请输入姓名" }],
                })(<Input placeholder="请输入" />)}
              </Form.Item>
              <Form.Item {...formItemLayout} label="学校"  >
                {getFieldDecorator('college', {
                  rules: [{ required: true, message: '请选择学校' }],
                })(
                  <Select placeholder="请选择学校" onChange={(e) => {
                    this.changeCollege(e)
                  }}>
                    {this.state.collegeList.map((v, k) => {
                      return (<Option key={`${k}`} value={v.value}>{v.label}</Option>);
                    })}
                  </Select>
                )}
              </Form.Item>
              <Form.Item {...formItemLayout} label="专业"  >
                {getFieldDecorator('speciality', {
                  rules: [{ required: true, message: '请选择专业' }],
                })(
                  <Select placeholder="请选择专业">
                    {this.state.specialityList.map((v, k) => {
                      return (<Option key={`${k}`} value={v.value}>{v.label}</Option>);
                    })}
                  </Select>
                )}
              </Form.Item>
              <Form.Item {...formItemLayout} label="届别">
                {getFieldDecorator('grade', {
                  rules: [{ required: true, message: '请选择届别' }],
                })(
                  <Select placeholder="请选择届别">
                    {this.state.grades.map((v, k) => {
                      return (<Option key={`${k}`} value={v.value}>{v.label}</Option>);
                    })}
                  </Select>
                )}
              </Form.Item>

              <Form.Item {...formItemLayout} label="学历"  >
                {getFieldDecorator('education', {
                  rules: [{ required: true, message: '请选择学历' }],
                })(
                  <Select placeholder="请选择学历">
                    {this.state.educationList.map((v, k) => {
                      return (<Option key={`${k}`} value={v.value}>{v.label}</Option>);
                    })}
                  </Select>
                )}
              </Form.Item>

              <Form.Item {...formItemLayout} label="是否为毕业生"  >
                {getFieldDecorator('isGraduate', {
                  rules: [{ required: true, message: '请选择是否为毕业生' }],
                })(
                  <RadioGroup onChange={evt => {
                    this.setState({
                      isGraduate: evt.target.value,
                    })
                  }}>
                    <Radio value={IS_GRADUATE.FALSE}> 否</Radio>
                    <Radio value={IS_GRADUATE.TRUE}>是</Radio>
                  </RadioGroup>
                )}
              </Form.Item>

              <Form.Item {...formItemLayout} label="一卡通证件照"
                className={this.state.isGraduate === IS_GRADUATE.FALSE ? "" : style.hidden}>
                {getFieldDecorator('oneCardPassPic', {
                })(
                  <div className="clearfix">
                    <Upload action={getUploadImgUrl()} listType="picture-card" fileList={oneCardPassPic}
                      onPreview={this.handlePreview} onChange={this.oneCardPassPicChange}>
                      {oneCardPassPic.length > 0 ? null : uploadButton}
                    </Upload>
                    <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                      <img alt="example" style={{ width: '100%' }} src={previewImage} />
                    </Modal>
                  </div>
                )}
              </Form.Item>
              <Form.Item {...formItemLayout} label="学位证证件照"
                className={this.state.isGraduate === IS_GRADUATE.TRUE ? "" : style.hidden}>
                {getFieldDecorator('degreePic', {
                })(
                  <div className="clearfix">
                    <Upload action={getUploadImgUrl()} listType="picture-card" fileList={degreePic}
                      onPreview={this.handlePreview} onChange={this.degreePicChange}>
                      {degreePic.length > 0 ? null : uploadButton}
                    </Upload>
                    <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                      <img alt="example" style={{ width: '100%' }} src={previewImage} />
                    </Modal>
                  </div>
                )}
              </Form.Item>
              <Form.Item {...formItemLayout} label="是否有海外留学背景"  >
                {getFieldDecorator('isOversea', {
                  rules: [{ required: true, message: '请选择是否有海外留学背' }],
                })(
                  <RadioGroup onChange={evt => {
                    this.setState({
                      isOversea: evt.target.value,
                    })
                  }}>
                    <Radio value={IS_GRADUATE.UNDEFINED}> 否</Radio>
                    <Radio value={IS_GRADUATE.TRUE}>是</Radio>
                  </RadioGroup>
                )}
              </Form.Item>

              {
                this.state.isOversea === 1 ? (<div><Form.Item {...formItemLayout} label="海外学校" >
                  {getFieldDecorator("overseaCollege", {
                    rules: [{ required: true, message: "请输入海外学校" }],
                  })(<Input placeholder="请输入" />)}
                </Form.Item>
                  <Form.Item {...formItemLayout} label="海外专业">
                    {getFieldDecorator("overseaSpeciality", {
                      rules: [{ required: true, message: "请输入海外专业" }],
                    })(<Input placeholder="请输入" />)}
                  </Form.Item>
                  <Form.Item {...formItemLayout} label="学历"  >
                    {getFieldDecorator('overseaEducation', {
                      rules: [{ required: true, message: '请选择学历' }],
                    })(
                      <Select placeholder="请选择学历">
                        {this.state.educationList.map((v, k) => {
                          return (<Option key={`${k}`} value={v.value}>{v.label}</Option>);
                        })}
                      </Select>
                    )}
                  </Form.Item>
                  <Form.Item {...formItemLayout} label="毕业时间"  >
                    {getFieldDecorator('overseaTime', {
                      rules: [{ required: true, message: '请选择毕业时间' }],
                    })(
                      <DatePicker placeholder="请选择" />
                    )}
                  </Form.Item>
                  <Form.Item {...formItemLayout} label="毕业证或在读证明">
                    {getFieldDecorator('overseasCard', {
                    })(
                      <div className="clearfix">
                        <Upload action={getUploadImgUrl()} listType="picture-card" fileList={overseasCard}
                          onPreview={this.handlePreview} onChange={this.overseasCardChange}>
                          {overseasCard.length > 0 ? null : uploadButton}
                        </Upload>
                        <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                          <img alt="example" style={{ width: '100%' }} src={previewImage} />
                        </Modal>
                      </div>
                    )}
                  </Form.Item>
                </div>) : null
              }
              <Form.Item wrapperCol={{
                xs: {
                  span: 7,
                  offset: 8,
                },
              }}>
                <Button className={style.btn} type="primary" onClick={this.updateUserInfo}>更新基本信息</Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Spin >
    );
  }
}
export default Form.create()(Edit);
