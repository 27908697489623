export const TYPE = {
  "INVENTION": 0x0,
  "USE_OF_NEW": 0x1,
  "INVENTION_DESIGN": 0x2,
  "SOFTWARE_COPYRIGHT": 0x3,
  "OTHERS": 0x4
}
export const TYPE_ZH = [
  "发明专利",
  "使用新型专利",
  "外观设计专利",
  "软件著作权",
  "其他"
]
export const TYPE_ZH_MAP_VAL = {
  "发明专利": 0x0,
  "使用新型专利": 0x1,
  "外观设计专利": 0x2,
  "软件著作权": 0x3,
  "其他": 0x4
}

// 融资类型
// Financing type
// 所属等级
// Subordinate level
export const FINANCING_TYPE = {
  "STOCK_RIGHT": 0x0,
  "CLAIMS": 0x1,
  "OTHERS": 0x2,
}
export const FINANCING_TYPE_ZH = [
  "股权", "债权", "其他",
]
export const SUBORDINATE_TYPE = {
  "NATIONAL_LEVEL": 0x0,
  "PROVINCIAL_LEVEL": 0x1,
  "DISTRICT_LEVEL": 0x2,
  "SCHOOL_LEVEL": 0x3,
  "OTHERS": 0x4,
}
export const SUBORDINATE_TYPE_ZH = [
  "国家级", "省市级", "区级", "校级", "其他",
]
