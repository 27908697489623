import React, { Component } from "react";
import {
  Carousel,
  TabBar,
  NavBar,
  ActivityIndicator,
  Flex,
  List,
  Picker,
  PullToRefresh,
  DatePicker,
  Modal,
} from "antd-mobile";
import { Form, Empty, Spin } from "antd";
import { LocalStorage as storage } from "jsbdk";
import { withRouter, Link } from "react-router-dom";
import moment from "moment";
import qs from "qs";
import AliIcon from "../../utils/iconfont";
import gStyle from "../../utils/sass";
import gImg from "../../utils/img";
import { BASE_URL } from "../../config/prod";
import userApi, {
  STATUS,
  STATUS_ZH,
  ID_REAL_NAME_APPLY_STATUS,
  SCHOOL_INFO_APPLY_STATUS,
  SUBJECT_APPLY_STATUS,
  MERGE_APPLY_STATUS,
} from "../../services/user";
import applyApi, { TYPE, TYPE_ZH } from "../../services/apply";

const LOGIN_PATH = "/user/login";
const style = gStyle.index.home;
class Apply extends Component {
  types = [
    [
      {
        value: TYPE.createProject,
        label: TYPE_ZH[TYPE.createProject],
      },
      {
        value: TYPE.companyEnter,
        label: TYPE_ZH[TYPE.companyEnter],
      },
      {
        value: TYPE.conferenceRoomRent,
        label: TYPE_ZH[TYPE.conferenceRoomRent],
      },
      {
        value: TYPE.coffeeHouseRent,
        label: TYPE_ZH[TYPE.coffeeHouseRent],
      },
      {
        value: TYPE.mealCard,
        label: TYPE_ZH[TYPE.mealCard],
      },
      {
        value: TYPE.parkingPermit,
        label: TYPE_ZH[TYPE.parkingPermit],
      },
      {
        value: TYPE.recruit,
        label: TYPE_ZH[TYPE.recruit],
      },
      {
        value: TYPE.realNameAuth,
        label: TYPE_ZH[TYPE.realNameAuth],
      },
      {
        value: TYPE.schoolInfoAuth,
        label: TYPE_ZH[TYPE.schoolInfoAuth],
      },
    ],
  ];
  statusPicker = [
    [
      {
        value: STATUS.process,
        label: STATUS_ZH[STATUS.process],
      },
      {
        value: STATUS.pass,
        label: STATUS_ZH[STATUS.pass],
      },
      {
        value: STATUS.reject,
        label: STATUS_ZH[STATUS.reject],
      },
      {
        value: STATUS.close,
        label: STATUS_ZH[STATUS.close],
      },
    ],
  ];
  constructor(props) {
    super(props);
    let search = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    this.state = {
      isLogin: false,
      isAnimating: true,
      applyRecordList: [],
      refreshing: false,
      page: 1,
      limit: 10,
      filter: {
        type: search.type ? [parseInt(search.type)] : null,
        status: search.status ? [parseInt(search.status)] : null,
        date: null,
      },
    };
  }
  async componentDidMount() {
    await this.checkIsLogin();
    this.init();
    this.checkTimer = setInterval(_ => {
      let isLogin = storage.has("userIsLogin");
      if (isLogin !== this.state.isLogin) {
        this.setState({
          isLogin,
        });
      }
    }, 1000);
  }
  componentWillUnmount() {
    clearInterval(this.checkTimer);
  }
  init = async _ => {
    if (this.state.isLogin) {
      let {
        data: { list: applyRecordList },
      } = await userApi.getApplyRecordList({
        page: -1,
        limit: -1,
        filter: {
          type: this.state.filter.type ? this.state.filter.type[0] : null,
          status: this.state.filter.status ? this.state.filter.status[0] : null,
        },
      });
      this.setState({
        applyRecordList,
      });
    }
  };
  checkIsLogin = _ => {
    return new Promise(s => {
      this.setState(
        {
          isAnimating: false,
          isLogin: storage.has("userIsLogin"),
        },
        s,
      );
    });
  };
  //上拉记载更多
  pullUpMore = async _ => {
    let fields = this.props.form.getFieldsValue();
    let page = this.state.page,
      limit = this.state.limit,
      filter = this.state.filter,
      list = this.state.list;
    filter.date = fields.date ? moment(fields.date).format("YYYY-MM-DD HH:mm:ss") : null;
    filter.status = fields.status;
    filter.type = fields.type;
    if (this.state.refreshing) {
      page = ++page;
    }
    try {
      userApi.getApplyRecordList({ page, limit, filter }).then(json => {
        for (let v of json.data) {
          list.push(v);
        }
        this.setState({
          refreshing: false,
          applyRecordList: list,
          page,
        });
      });
    } catch (err) { }
  };
  //筛选
  filterChange = _ => {
    let fields = this.props.form.getFieldsValue();
    let page = this.state.page,
      limit = this.state.limit,
      filter = {
        type: fields.type ? parseInt(fields.type) : null,
        status: fields.status ? parseInt(fields.status) : null,
        date: fields.date ? moment(fields.date).format("YYYY-MM-DD 00:00:00") : null,
      };
    userApi
      .getApplyRecordList({ page, limit, filter })
      .then(json => {
        this.setState({
          refreshing: false,
          applyRecordList: json.data.list,
          page: json.data.page,
        });
      })
      .catch(err => {
        console.log(err.msg);
      });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className={style.page}>
        <div className={style.apply}>
          <NavBar mode="light">申请列表</NavBar>
          <div className={style.banner}>
            <div className={style.img}>
              <img src={`${gImg.index.apply.applyRecordBanner}`} alt="apply" />
            </div>
            <form>
              <Flex justify="center" align="center" className={style.filters}>
                <div className={style.item}>
                  {getFieldDecorator("type", { initialValue: this.state.filter.type })(
                    <Picker
                      data={this.types}
                      title="申请类型"
                      cascade={false}
                      extra="全部类型"
                      disabled={!this.state.isLogin}
                      onOk={this.filterChange}>
                      <List.Item arrow="horizontal" />
                    </Picker>,
                  )}
                </div>
                <div className={style.line} />
                <div className={style.item}>
                  {getFieldDecorator("date", { initialValue: this.state.filter.date })(
                    <DatePicker
                      mode="date"
                      disabled={!this.state.isLogin}
                      title="申请时间"
                      extra="全部时间"
                      onOk={this.filterChange}>
                      <List.Item arrow="horizontal" />
                    </DatePicker>,
                  )}
                </div>
                <div className={style.line} />
                <div className={style.item}>
                  {getFieldDecorator("status", { initialValue: this.state.filter.status })(
                    <Picker
                      data={this.statusPicker}
                      title="申请状态"
                      cascade={false}
                      extra="全部状态"
                      disabled={!this.state.isLogin}
                      onOk={this.filterChange}>
                      <List.Item arrow="horizontal" />
                    </Picker>,
                  )}
                </div>
              </Flex>
            </form>
          </div>
          {this.state.isLogin ? (
            this.state.applyRecordList.length === 0 ? (
              <Empty description="暂无申请记录" />
            ) : (
                <PullToRefresh
                  getScrollContainer={_ => null}
                  direction="up"
                  indicator={this.state.down ? {} : { deactivate: "上拉加载更多" }}
                  distanceToRefresh={25}
                  refreshing={this.state.refreshing}
                  damping={100}
                  onRefresh={_ => {
                    this.setState({ refreshing: true });
                    this.pullUpMore();
                  }}>
                  <div className={style.applyList}>
                    {this.state.applyRecordList.map((v, k) => {
                      return (
                        <Link key={k} to={`/user/apply/detail?type=${v.type}&id=${v.id}`}>
                          <Flex className={style.item}>
                            <AliIcon className={style.icon} type="icon-flow-determine" />
                            <div className={style.title}>{TYPE_ZH[v.type] + "申请"}</div>
                            <div style={{ marginLeft: "5px" }}>{v.status}</div>
                            <div className={style.time}>{v.time.substr(0, 10)}</div>
                          </Flex>
                        </Link>
                      );
                    })}
                  </div>
                </PullToRefresh>
              )
          ) : (
              <Empty description={<a href={LOGIN_PATH}>请先登录</a>} />
            )}
        </div>
      </div>
    );
  }
}
class Main extends Component {
  checkTimer = null;
  bannerList = [
    {
      alt: "尚创汇",
      url: gImg.index.banner1,
    },
    {
      alt: "尚创汇",
      url: gImg.index.banner2,
    },
  ];
  processList = [
    {
      title: "公司入驻/项目申请",
      itemList: [
        {
          id: TYPE.createProject,
          icon: gImg.index.apply.applyCompany,
          name: TYPE_ZH[TYPE.createProject],
          href: "/user/apply/createProject",
          type: "apply",
        },
        // {
        //   id: TYPE.createProject,
        //   icon: gImg.index.apply.companyRecord,
        //   name: "项目记录",
        //   href: `/?tab=apply&type=${TYPE.createProject}`,
        //   type: "record",
        // },
        {
          id: TYPE.companyEnter,
          icon: gImg.index.apply.applyCompany,
          name: TYPE_ZH[TYPE.companyEnter],
          href: "/user/apply/companyEnter",
          type: "apply",
        },
        // {
        //   id: TYPE.companyEnter,
        //   icon: gImg.index.apply.companyRecord,
        //   name: "公司入驻记录",
        //   href: `/?tab=apply&type=${TYPE.companyEnter}`,
        //   type: "record",
        // },
      ],
    },
    {
      title: "会议室租用申请",
      itemList: [
        {
          id: TYPE.conferenceRoomRent,
          icon: gImg.index.apply.applyConferenceRoomRent,
          name: TYPE_ZH[TYPE.conferenceRoomRent],
          href: "/user/apply/conferenceRoomRent",
          type: "apply",
        },
        // {
        //   id: TYPE.conferenceRoomRent,
        //   icon: gImg.index.apply.conferenceRoomRentRecord,
        //   name: "申请记录",
        //   href: `/?tab=apply&type=${TYPE.conferenceRoomRent}`,
        //   type: "record",
        // },
      ],
    },
    {
      title: "咖啡吧申请",
      itemList: [
        {
          id: TYPE.coffeeHouseRent,
          icon: gImg.index.apply.applyCoffeeHouseRent,
          name: TYPE_ZH[TYPE.coffeeHouseRent],
          href: "/user/apply/coffeeHouseRent",
          type: "apply",
        },
        // {
        //   id: TYPE.coffeeHouseRent,
        //   icon: gImg.index.apply.coffeeHouseRentApply,
        //   name: "申请记录",
        //   href: `/?tab=apply&type=${TYPE.coffeeHouseRent}`,
        //   type: "record",
        // },
      ],
    },
    {
      title: "饭卡申请",
      itemList: [
        {
          id: TYPE.mealCard,
          icon: gImg.index.apply.applyMealCard,
          name: TYPE_ZH[TYPE.mealCard],
          href: "/user/apply/mealCard",
          type: "apply",
        },
        // {
        //   id: TYPE.mealCard,
        //   icon: gImg.index.apply.mealCcardRecord,
        //   name: "饭卡申请记录",
        //   href: `/?tab=apply&type=${TYPE.mealCard}`,
        //   type: "record",
        // },
      ],
    },
    {
      title: "停车证申请",
      itemList: [
        {
          id: TYPE.parkingPermit,
          icon: gImg.index.apply.applyParkingPermit,
          name: TYPE_ZH[TYPE.parkingPermit],
          href: "/user/apply/parkingPermit",
          type: "apply",
        },
        // {
        //   id: TYPE.parkingPermit,
        //   icon: gImg.index.apply.parkingPermitRecord,
        //   name: "申请记录",
        //   href: `/?tab=apply&type=${TYPE.parkingPermit}`,
        //   type: "record",
        // },
      ],
    },
    {
      title: "招聘申请",
      itemList: [
        {
          id: TYPE.recruit,
          icon: gImg.index.apply.applyRecruit,
          name: TYPE_ZH[TYPE.recruit],
          href: "/user/apply/recruit",
          type: "apply",
        },
        // {
        //   id: TYPE.recruit,
        //   icon: gImg.index.apply.recruitRecord,
        //   name: "招聘申请记录",
        //   href: `/?tab=apply&type=${TYPE.recruit}`,
        //   type: "record",
        // },
      ],
    },
  ];
  constructor(props) {
    super(props);
    this.state = {
      isAnimating: true,
      isLogin: false,
      isModalActive: false,
      subjectApplyStatus: null,
      idCardRealNameStatus: null,
      schollInfoApplyStatus: null,
      modalBody: "",
      modalFooter: [],
    };
  }
  componentDidMount() {
    this.init();
  }
  init = async _ => {
    await this.checkIsLogin();
    this.checkTimer = setInterval(_ => {
      let isLogin = storage.has("userIsLogin");
      if (isLogin !== this.state.isLogin) {
        this.setState({
          isLogin,
        });
      }
    }, 1000);
  };
  componentWillUnmount() {
    clearInterval(this.checkTimer);
  }
  checkIsLogin = _ => {
    return new Promise(s => {
      this.setState(
        {
          isAnimating: false,
          isLogin: storage.has("userIsLogin"),
        },
        s,
      );
    });
  };
  linkToProcess = processItem => {
    if (this.state.isLogin) {
      if (processItem.type === "apply") {
        // 新增合并判断
        if (this.props.mergeInfoApplyStatus === MERGE_APPLY_STATUS.FALSE) {
          this.setState({
            isModalActive: true,
            modalBody: <div>您还未完成基本信息认证,去基本信息认证?</div>,
            modalFooter: [
              {
                text: "取消",
                onPress: _ => {
                  this.setState({
                    isModalActive: false,
                  })
                },
              },
              {
                text: "确定",
                onPress: _ => {
                  console.log(this.props.history)
                  this.props.history.push("/user/auth/mergeInfo")
                }
              }
            ]
          })
          return;
        } else if (this.props.mergeInfoApplyStatus === MERGE_APPLY_STATUS.PROCESS) {
          this.setState({
            isModalActive: true,
            modalBody: <div>您已提交过基本信息申请，请在申请记录内查询审批进度</div>,
            modalFooter: [
              {
                text: "取消",
                onPress: _ => {
                  this.setState({
                    isModalActive: false,
                  });
                },
              },
              {
                text: "确定",
                onPress: _ => {
                  this.setState({
                    isModalActive: false,
                  });
                },
              },
            ],
          });
          return;
        }
        // if (this.props.idRealNameApplyStatus !== ID_REAL_NAME_APPLY_STATUS.TRUE) {
        //   this.setState({
        //     isModalActive: true,
        //     modalBody: <div>您未完成实名认证,去实名认证?</div>,
        //     modalFooter: [
        //       {
        //         text: "取消",
        //         onPress: _ => {
        //           this.setState({
        //             isModalActive: false,
        //           });
        //         },
        //       },
        //       {
        //         text: "确定",
        //         onPress: _ => {
        //           this.props.history.push("/user/auth/idCardRealName");
        //         },
        //       },
        //     ],
        //   });
        //   return;
        // }
        // if (this.props.schoolInfoApplyStatus !== SCHOOL_INFO_APPLY_STATUS.TRUE) {
        //   this.setState({
        //     isModalActive: true,
        //     modalBody: <div>您未完成学校认证, 去学校认证?</div>,
        //     modalFooter: [
        //       {
        //         text: "取消",
        //         onPress: _ => {
        //           this.setState({
        //             isModalActive: false,
        //           });
        //         },
        //       },
        //       {
        //         text: "确定",
        //         onPress: _ => {
        //           this.props.history.push("/user/auth/schoolInfo");
        //         },
        //       },
        //     ],
        //   });
        //   return;
        // }
        // 4.26修改
        // if (this.props.idRealNameApplyStatus === ID_REAL_NAME_APPLY_STATUS.FALSE) {
        //   this.setState({
        //     isModalActive: true,
        //     modalBody: <div>您未完成实名认证,去实名认证?</div>,
        //     modalFooter: [
        //       {
        //         text: "取消",
        //         onPress: _ => {
        //           this.setState({
        //             isModalActive: false,
        //           });
        //         },
        //       },
        //       {
        //         text: "确定",
        //         onPress: _ => {
        //           console.log(this.props.history)
        //           this.props.history.push("/user/auth/idCardRealName");
        //         },
        //       },
        //     ],
        //   });
        //   return;
        // } else if (this.props.idRealNameApplyStatus === ID_REAL_NAME_APPLY_STATUS.PROCESS) {
        //   this.setState({
        //     isModalActive: true,
        //     modalBody: <div>您已提交过实名认证申请，请在申请记录内查询审批进度</div>,
        //     modalFooter: [
        //       {
        //         text: "取消",
        //         onPress: _ => {
        //           this.setState({
        //             isModalActive: false,
        //           });
        //         },
        //       },
        //       {
        //         text: "确定",
        //         onPress: _ => {
        //           this.setState({
        //             isModalActive: false,
        //           });
        //         },
        //       },
        //     ],
        //   });
        //   return;
        // }
        // if (this.props.schoolInfoApplyStatus === SCHOOL_INFO_APPLY_STATUS.FALSE) {
        //   this.setState({
        //     isModalActive: true,
        //     modalBody: <div>您未完成学校认证, 去学校认证?</div>,
        //     modalFooter: [
        //       {
        //         text: "取消",
        //         onPress: _ => {
        //           this.setState({
        //             isModalActive: false,
        //           });
        //         },
        //       },
        //       {
        //         text: "确定",
        //         onPress: _ => {
        //           this.props.history.push("/user/auth/schoolInfo");
        //         },
        //       },
        //     ],
        //   });
        //   return;
        // } else if (this.props.schoolInfoApplyStatus === SCHOOL_INFO_APPLY_STATUS.PROCESS) {
        //   this.setState({
        //     isModalActive: true,
        //     modalBody: <div>您已提交过学校申请，请在申请记录内查询审批进度</div>,
        //     modalFooter: [
        //       {
        //         text: "取消",
        //         onPress: _ => {
        //           this.setState({
        //             isModalActive: false,
        //           });
        //         },
        //       },
        //       {
        //         text: "确定",
        //         onPress: _ => {
        //           this.setState({
        //             isModalActive: false,
        //           });
        //         },
        //       },
        //     ],
        //   });
        //   return;
        // }
        // 4.26修改
        // if (
        //   processItem.href === "/user/apply/createProject" ||
        //   processItem.href === "/user/apply/companyEnter"
        // ) {
        //   if (this.props.subjectApplyStatus !== SUBJECT_APPLY_STATUS.FALSE) {
        //     return this.setState({
        //       isModalActive: true,
        //       modalBody: <div>您已有公司入驻或者项目</div>,
        //       modalFooter: [
        //         {
        //           text: "确定",
        //           onPress: _ => {
        //             this.setState({
        //               isModalActive: false,
        //             });
        //           },
        //         },
        //       ],
        //     });
        //   }
        // } else {
        //   if (this.props.subjectApplyStatus !== SUBJECT_APPLY_STATUS.TRUE) {
        //     return this.setState({
        //       isModalActive: true,
        //       modalBody: <div>您的公司或项目主体未完成认证,请先入驻公司或项目入驻</div>,
        //       modalFooter: [
        //         {
        //           text: "确定",
        //           onPress: _ => {
        //             this.setState({
        //               isModalActive: false,
        //             });
        //           },
        //         },
        //       ],
        //     });
        //   }
        // }
        this.props.history.push(processItem.href);
      } else {
        window.location.href = `${processItem.href}&id=${processItem.id}`;
      }
    } else {
      Modal.alert("登录", "你还没有登录,是否先去登录?", [
        { text: "取消" },

        {
          text: "确定",
          onPress: _ => {
            this.props.history.push(LOGIN_PATH);
          },
        },
      ]);
      return;
    }
  };
  render() {
    return (
      <div className={style.page}>
        <ActivityIndicator
          className={style.loading}
          size="large"
          toast
          text="正在加载"
          animating={this.state.isAnimating}
        />
        <NavBar mode="light">首页</NavBar>
        <Carousel autoplay={true} infinite className={style.banner} dots={false}>
          {this.bannerList.map((v, k) => (
            <div key={k} style={{ minHeight: "230px" }}>
              <img src={`${v.url}`} alt={v.alt} style={{ maxHeight: "230px" }} />
            </div>
          ))}
        </Carousel>
        <div className={style.homeList}>
          {this.processList.map((v, k) => {
            return (
              <div key={k} className={style.item}>
                <div className={style.title}>{v.title}</div>
                <Flex className={style.secondsList} align="center" wrap="wrap">
                  {v.itemList.map((v1, k1) => {
                    return (
                      <div key={k1} className={style.theme} onClick={_ => this.linkToProcess(v1)}>
                        <div className={style.icon}>
                          <img src={`${v1.icon}`} alt={v1.name} />
                        </div>
                        <div className={style.name}>{v1.name.substr(0, 6)}</div>
                      </div>
                    );
                  })}
                </Flex>
              </div>
            );
          })}
        </div>
        <Modal
          visible={this.state.isModalActive}
          transparent
          maskClosable={false}
          title="提示"
          footer={this.state.modalFooter}>
          {this.state.modalBody}
        </Modal>
      </div>
    );
  }
}
class My extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogin: false,
      isAnimating: true,
      defaultAvatar: gImg.user.defaultAvatar,
      avatar: null,
      nick: null,
      phone: "",
      list: [
        {
          icon: "icon-smiling",
          title: "个人信息",
          href: "/user/info",
        },
        {
          icon: "icon-xukezheng",
          title: "基本信息认证",
          href: "/user/auth/mergeInfo",
        },
        // {
        //   icon: "icon-xukezheng",
        //   title: "实名认证",
        //   href: "/user/auth/idCardRealName",
        // },
        // {
        //   icon: "icon-flow-resume",
        //   title: "学校信息",
        //   href: "/user/auth/schoolInfo",
        // },
        // {
        //   icon: "icon-iconfontzhizuobiaozhun30",
        //   title: "我的项目",
        //   href: "/project/index",
        // },
        // {
        //   icon: "icon-renzhengqiye",
        //   title: "我的公司",
        //   href: "/company/index",
        // },
        {
          icon: "icon-flow-determine",
          title: "我的申请",
          href: "/?tab=apply",
        },
        {
          icon: "icon-shezhi-xianxing",
          title: "设置",
          href: "/user/settings",
        },
      ],
    };
  }
  async componentDidMount() {
    await this.checkIsLogin();
    this.checkTimer = setInterval(_ => {
      let isLogin = storage.has("userIsLogin");
      if (isLogin !== this.state.isLogin) {
        this.setState({
          isLogin,
        });
      }
    }, 1000);
    this.init();
  }
  init = async () => {
    if (this.state.isLogin) {
      let { data: detail } = await userApi.getUserInfo();
      if (detail.avatar) {
        detail.avatar.url = BASE_URL + detail.avatar.url;
      }
      this.setState({
        avatar: detail.avatar,
        nick: detail.nick,
      });
    }
  };
  componentWillUnmount() {
    clearInterval(this.checkTimer);
  }
  checkIsLogin = _ => {
    return new Promise(s => {
      this.setState(
        {
          isAnimating: false,
          isLogin: storage.has("userIsLogin"),
        },
        s,
      );
    });
  };
  linkToProcess = async processItem => {
    if (!this.state.isLogin) {
      Modal.alert("登录", "您还没有登录,是否先去登录?", [
        { text: "取消" },
        {
          text: "确定",
          onPress: _ => {
            this.props.history.push(LOGIN_PATH);
          },
        },
      ]);
      return;
    }
    if (processItem.href === "/company/index" || processItem.href === "/project/index") {
      if (this.props.subjectApplyStatus !== SUBJECT_APPLY_STATUS.FALSE) {
        this.props.history.push(processItem.href);
      } else {
        if (this.props.subjectApplyStatus !== SUBJECT_APPLY_STATUS.TRUE) {
          return this.setState({
            isModalActive: true,
            modalBody: <div>您的公司或项目主体未完成认证,请先入驻公司或项目入驻</div>,
            modalFooter: [
              {
                text: "确定",
                onPress: _ => {
                  this.setState({
                    isModalActive: false,
                  });
                },
              },
            ],
          });
        }
      }
    }
    // 学校认证
    if (processItem.href === "/user/auth/schoolInfo") {
      if (this.props.idRealNameApplyStatus !== ID_REAL_NAME_APPLY_STATUS.TRUE) {
        Modal.alert("提示", "您还没有完成实名, 认证请先完成实名认证", [{ text: "确定" }]);
        return;
      }
      console.log(this.props.schoolInfoApplyStatus);
      switch (this.props.schoolInfoApplyStatus) {
        case SCHOOL_INFO_APPLY_STATUS.TRUE:
          this.props.history.push("/user/schoolInfo");
          break;
        case ID_REAL_NAME_APPLY_STATUS.PROCESS:
          const {
            data: { id: mySchoolInfoApplyId },
          } = await userApi.getMySchoolInfoApplyId();
          this.props.history.push(
            `/user/apply/detail?type=${TYPE["schoolInfoAuth"]}&id=${mySchoolInfoApplyId}`,
          );
          break;
        case ID_REAL_NAME_APPLY_STATUS.FALSE:
          this.props.history.push(processItem.href);
          break;
        default:
          break;
      }
      return;
    }
    // 实名认证
    if (processItem.href === "/user/auth/idCardRealName") {
      switch (this.props.idRealNameApplyStatus) {
        case ID_REAL_NAME_APPLY_STATUS.TRUE:
          this.props.history.push("/user/idCardRealName");
          break;
        case ID_REAL_NAME_APPLY_STATUS.PROCESS:
          const {
            data: { id: myIdRealNameApplyId },
          } = await userApi.getMyIdRealNameApplyId();
          this.props.history.push(
            `/user/apply/detail?type=${TYPE["realNameAuth"]}&id=${myIdRealNameApplyId}`,
          );
          break;
        case ID_REAL_NAME_APPLY_STATUS.FALSE:
          // Modal.alert("提示", "您还没有完成实名, 认证请先完成实名认证(test)", [{ text: "确定" }]);
          Modal.alert("提示", "您还没有完成实名, 认证请先完成实名认证(test)", [
            { text: "取消" },

            {
              text: "确定",
              onPress: _ => {
                this.props.history.push("/user/auth/mergeInfo");
              },
            },
          ]);

          break;
        default:
          break;
      }
      return;
    }
    // 合并认证
    if (processItem.href === "/user/auth/mergeInfo") {
      switch (this.props.mergeInfoApplyStatus) {
        case MERGE_APPLY_STATUS.TRUE:
          this.props.history.push("/user/auth/mergeInfo");
          break;
        case MERGE_APPLY_STATUS.PROCESS:
          const {
            data: { id: myIdRealNameApplyId },
          } = await userApi.getMyIdRealNameApplyId();
          this.props.history.push(
            `/user/apply/detail?type=${TYPE["essentialInformationAuth"]}&id=${myIdRealNameApplyId}`,
          );
          break;
        case ID_REAL_NAME_APPLY_STATUS.FALSE:
          Modal.alert("提示", "您还没有完成实名, 认证请先完成实名认证", [
            { text: "取消" },

            {
              text: "确定",
              onPress: _ => {
                this.props.history.push("/user/auth/mergeInfo");
              },
            },
          ]);

          break;
        default:
          break;
      }
      return;
    }
    if (processItem.href === "/?tab=apply") {
      window.location.href = "/?tab=apply";
    }
    this.props.history.push(processItem.href);
  };
  render() {
    return (
      <div className={style.page}>
        <div className={style.personalCenter}>
          <NavBar className={style.navbar} mode="light">
            个人中心
          </NavBar>
          <div className={style.header}>
            <Flex justify="between">
              <div>
                {this.state.isLogin ? (
                  <div className={style.nick}>{this.state.nick ? this.state.nick : "未命名"}</div>
                ) : (
                    <a href={LOGIN_PATH}>登录</a>
                  )}
                {/* {this.state.isLogin ? <Flex className={style.phone} justify="center">
                  <AliIcon type="icon-dianhua1"></AliIcon>
                  <div className={style.phone}>{this.state.phone}</div>
                </Flex> : null} */}
              </div>
              <div className={style.avatar}>
                {this.state.isLogin ? (
                  <img
                    src={
                      this.state.avatar ? `${this.state.avatar.url}` : `${this.state.defaultAvatar}`
                    }
                    alt="头像"
                  />
                ) : (
                    <a href={LOGIN_PATH}>
                      <img src={`${this.state.defaultAvatar}`} alt="登录" />
                    </a>
                  )}
              </div>
            </Flex>
          </div>
          <Flex className={style.list} wrap="wrap">
            {this.state.list.map((v, k) => {
              return (
                <div
                  key={k}
                  onClick={_ => this.linkToProcess(v)}
                  className={v.title === "设置" ? style.settings : style.item}>
                  <Flex>
                    <AliIcon className={style.icon} type={v.icon} />
                    <div className={style.title}>{v.title}</div>
                  </Flex>
                  <AliIcon className={style.leftIcon} type="icon-icon_arrow_right" />
                </div>
              );
            })}
          </Flex>
        </div>
        <Modal
          visible={this.state.isModalActive}
          transparent
          maskClosable={false}
          title="提示"
          footer={this.state.modalFooter}>
          {this.state.modalBody}
        </Modal>
      </div>
    );
  }
}
class Home extends Component {
  constructor(props) {
    super(props);
    let selectedTab = "home";
    if (props.location.search !== "") {
      let search = qs.parse(props.location.search, { ignoreQueryPrefix: true });
      if (["apply", "home", "my"].includes(search.tab)) {
        selectedTab = search.tab;
      }
    }
    this.state = {
      isLogin: false,
      isAnimaing: true,
      subjectApplyStatus: null,
      idRealNameApplyStatus: null,
      schoolInfoApplyStatus: null,
      mergeInfoApplyStatus: null,
      selectedTab,
      tarbar: [
        {
          icon: "icon-shenqing1-copy",
          selectedIcon: "icon-shenqing1",
          title: "申请记录",
          key: "apply",
          selected: "apply",
          content: withRouter(Form.create()(Apply)),
        },
        {
          icon: "icon-shouye3",
          selectedIcon: "icon-shouye3",
          title: "首页",
          key: "home",
          selected: "home",
          content: withRouter(Main),
        },
        {
          icon: "icon-wode",
          selectedIcon: "icon-wode",
          title: "我的",
          key: "my",
          selected: "my",
          content: withRouter(My),
        },
      ],
    };
  }
  componentDidMount() {
    this.init();
  }
  init = async _ => {
    console.log(this.props)
    const isLogin = storage.has("userIsLogin");
    await new Promise(s => {
      this.setState(
        {
          isLogin,
        },
        s,
      );
    });
    if (isLogin) {
      try {
        const subjectApplyStatusPromise = applyApi.getSubjectApplyStatus();
        const idRealNameApplyStatusPromise = applyApi.getIdRealNameApplyStatus();
        const mergeInfoApplyStatusPromise = applyApi.getMergeInfoApplyStatus();
        const schoolInfoApplyStatusPromise = applyApi.getSchoolInfoApplyStatus();
        let {
          data: { status: subjectApplyStatus },
        } = await subjectApplyStatusPromise;
        let {
          data: { status: idRealNameApplyStatus },
        } = await idRealNameApplyStatusPromise;
        let {
          data: { status: schoolInfoApplyStatus },
        } = await schoolInfoApplyStatusPromise;
        let {
          data: { status: mergeInfoApplyStatus }
        } = await mergeInfoApplyStatusPromise;
        this.setState({
          isAnimaing: false,
          subjectApplyStatus,
          idRealNameApplyStatus,
          schoolInfoApplyStatus,
          mergeInfoApplyStatus,
        });
      } catch (e) {
        console.log(e);
      }
    } else {
      this.setState({
        isAnimaing: false,
      });
    }
  };
  render() {
    return (
      <Spin spinning={this.state.isAnimaing} size="large" style={{ minHeight: "100vh" }}>
        <div className={style.tarbar}>
          <div className={style.content}>

            <TabBar
              unselectedTintColor="#949494"
              tintColor="#33A3F4"
              barTintColor="white"
              tabBarPosition="bottom">
              {this.state.tarbar.map((v, k) => {
                return (
                  <TabBar.Item
                    key={k}
                    icon={<AliIcon className={style.icon} type={v.icon} />}
                    selectedIcon={<AliIcon className={style.selectIcon} type={v.selectedIcon} />}
                    title={v.title}
                    selected={this.state.selectedTab === v.selected}
                    onPress={_ => {
                      this.setState({ selectedTab: v.selected });
                      this.props.history.push(this.props.location.pathname + `?tab=${v.selected}`);
                    }}>
                    {
                      <v.content
                        subjectApplyStatus={this.state.subjectApplyStatus}
                        idRealNameApplyStatus={this.state.idRealNameApplyStatus}
                        schoolInfoApplyStatus={this.state.schoolInfoApplyStatus}
                        mergeInfoApplyStatus={this.state.mergeInfoApplyStatus}
                      />
                    }
                  </TabBar.Item>
                );
              })}
            </TabBar>
          </div>
        </div>
      </Spin>
    );
  }
}
export default withRouter(Home);
