import React from "react";
import gSass from "../../../utils/sass";
import moment from "moment";
import { Link, } from "react-router-dom";
import qs from "qs";
import {
  Breadcrumb,
  Form,
  Input,
  DatePicker,
  message,
  Spin,
  Button,
  Checkbox
} from "antd";
import applyApi from "../../../services/apply";
import coffee, { PROVIDES, PROVIDES_ZH } from "../../../services/coffeeHouseRent";
const style = gSass.admin.applyEdit.edit;
// const { TextArea } = Input;
const formItemLayout = {
  labelCol: {
    lg: {
      span: 4,
      offset: 4,
    },
  },
  wrapperCol: {
    lg: {
      span: 8,
      offset: 0,
    },
  },
};


class Edit extends React.Component {
  constructor(props) {
    super(props);
    let { id } = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    id = parseInt(id);
    this.state = {
      projectId: id,
      spinning: true,
      industryList: [],
      patentTypeList: [],
      patentList: [],
      financingList: [],
      userInfo: {},
      idCardFirstPic: [],
      idCardSecondPic: [],
      previewVisible: false,
      previewImage: '',
      householdList: [],
      residentPic: [],
      applyId: 0,
      infoId: 0,
      coffeeId: 0,
      provideItemList: []//所需列表
    };
  }
  componentDidMount() {
    this.init();
  }
  init = async _ => {
    try {
      // 获得服务器数据
      let { data: { detail, } } = await applyApi.getApplyDetail({ id: this.state.projectId });
      let provideItemList = []
      for (let v in PROVIDES) {
        provideItemList.push({
          value: PROVIDES[v],
          label: PROVIDES_ZH[PROVIDES[v]],
          type: v,
          checked: false,
        })
      }
      for (let [k, v] of Object.entries(detail.info.providedItem)) {
        if (v > 0) {
          if (k === "id") {
            this.setState({
              coffeeId: v
            })
          }
          for (let w of provideItemList) {
            if (w.type === k) {
              w.checked = true
            }
          }
        }
      }
      setTimeout(() => {
        this.props.form.setFieldsValue({
          userNum: detail.info.usePersonNumber,
          startTime: moment(detail.info.startTime, "YYYY-MM-DD HH:mm:ss"),
          endTime: moment(detail.info.endTime, "YYYY-MM-DD HH:mm:ss"),
        });

        this.setState({
          applyId: detail.info.id,
          spinning: false,
          loading: false,
          provideItemList,
        }, () => {
          console.log(this.state.provideItemList)
        })


      }, 0)


    } catch (e) {
      console.log(e);
      this.setState({
        spinning: false,
      });
    }
  };

  //提交
  updateUserInfo = evt => {
    evt.preventDefault();

    this.props.form.validateFields(["userNum", "startTime", "endTime",], (err, fields) => {
      if (err) {
        return message.error(err[Object.keys(err)[0]].errors[0].message, 1);
      }
      let provideItemList = this.state.provideItemList
      coffee.editCoffeeHouseRent({
        coffeeId: this.state.coffeeId,
        applyId: this.state.applyId,
        userNum: fields.userNum,
        startTime: moment(fields.startTime).format("YYYY-MM-DD HH:mm:ss"),
        endTime: moment(fields.endTime).format("YYYY-MM-DD HH:mm:ss"),
        provideItemList,
      })
        .then(json => {
          message.success("修改成功", 1);
        })
        .catch(err => {
          message.error("修改失败,失败原因: " + err.msg, 1);
          console.log(err);
        });
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    //头像上传按钮
    return (
      <Spin tip="Loading..." spinning={this.state.spinning} size="large">
        <div className={style.info}>
          <Breadcrumb className={style.title}>
            <Breadcrumb.Item><Link to="/admin">首页</Link></Breadcrumb.Item>
            <Breadcrumb.Item><Link to="/admin/apply/allList">申请管理</Link></Breadcrumb.Item>
            <Breadcrumb.Item>咖啡吧管理编辑</Breadcrumb.Item>
          </Breadcrumb>
          <div className={style.basicWrap}>
            <Form className={style.form}>
              <Form.Item {...formItemLayout} label="使用人数">
                {getFieldDecorator("userNum", {
                  rules: [{ required: true, message: "请输入使用人数" }],
                })(<Input placeholder="请输入" />)}
              </Form.Item>

              <Form.Item {...formItemLayout} label="开始使用时间"  >
                {getFieldDecorator('startTime', {
                  rules: [{ required: true, message: '请选择开始使用时间' }],
                })(
                  <DatePicker placeholder="请选择" format="YYYY-MM-DD HH:mm:ss" />
                )}
              </Form.Item>
              <Form.Item {...formItemLayout} label="结束使用时间"  >
                {getFieldDecorator('endTime', {
                  rules: [{ required: true, message: '请选择结束使用时间' }],
                })(
                  <DatePicker placeholder="请选择" format="YYYY-MM-DD HH:mm:ss" />
                )}
              </Form.Item>

              <Form.Item {...formItemLayout} label="需要物品(多选)">
                {this.state.provideItemList.map((v, k) => {
                  return (<div className={style.operationMethodItem} key={k}>
                    <Checkbox key={k}
                      onChange={_ => {
                        let provideItemList = this.state.provideItemList;
                        provideItemList[k].checked = !v.checked;
                        this.setState({
                          provideItemList,
                        })
                      }}
                      checked={v.checked}
                    >
                      {v.label}
                    </Checkbox>
                  </div>);
                })}
              </Form.Item>
              <Form.Item wrapperCol={{
                xs: {
                  span: 7,
                  offset: 8,
                },
              }}>
                <Button className={style.btn} type="primary" onClick={this.updateUserInfo}>更新基本信息</Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Spin>
    );
  }
}
export default Form.create()(Edit);
