export default {
  common: {
    content: require('../assets/sass/common/content.scss'),
    header: require('../assets/sass/common/header.scss'),
    sidebar: require('../assets/sass/common/sidebar.scss'),
    basicForm: require('../assets/sass/admin/BasicForm.scss'),
    workPlace: require('../assets/sass/admin/workplace.scss'),
  },
  admin: {
    user: {
      addUser: require('../assets/sass/admin/user/Add.scss'),
      login: require('../assets/sass/admin/user/Login.scss'),
      list: require('../assets/sass/admin/user/List.scss'),
      information: require('../assets/sass/admin/user/Information.scss'),
    },
    operation: {
      list: require('../assets/sass/admin/operation/List.scss'),
      add: require('../assets/sass/admin/operation/Add.scss'),
      groupList: require('../assets/sass/admin/operation/GroupList.scss'),
      addGroup: require('../assets/sass/admin/operation/AddGroup.scss'),
    },
    settings: {
      base: require("../assets/sass/admin/settings/base.scss"),
    },
    school: {
      specialityList: require('../assets/sass/admin/school/SpecialityList.scss'),
      collegeList: require('../assets/sass/admin/school/CollegeList.scss'),
    },
    company: {
      list: require('../assets/sass/admin/company/List.scss'),
      mealCardList: require('../assets/sass/admin/company/MealCardList.scss'),
      industryList: require('../assets/sass/admin/company/IndustryList.scss'),
      paymentRecordList: require('../assets/sass/admin/company/PaymentRecordList.scss'),
      addPaymentRecord: require('../assets/sass/admin/company/AddPaymentRecord.scss'),
      base: require("../assets/sass/admin/company/base.scss"),
      ParkingPermit: require("../assets/sass/admin/company/ParkingPermit.scss"),

    },
    conferenceRoomRent: {
      list: require("../assets/sass/admin/conferenceRoomRent/List.scss"),
      RoomRentDetail: require("../assets/sass/admin/conferenceRoomRent/RoomRentDetail.scss"),
    },
    cafeRent: {
      list: require("../assets/sass/admin/cafeRent/List.scss"),
      RoomRentDetail: require("../assets/sass/admin/cafeRent/RoomRentDetail.scss"),
    },
    project: {
      list: require('../assets/sass/admin/project/List.scss'),
      base: require("../assets/sass/admin/project/base.scss"),
    },
    apply: {
      allApplyList: require('../assets/sass/admin/apply/List.scss'),
      createProjectList: require('../assets/sass/admin/apply/CreateProjectList.scss'),
      companyEnterList: require('../assets/sass/admin/apply/CompanyEnterList.scss'),
      conferenceRoomRentList: require('../assets/sass/admin/apply/ConferenceRoomRent.scss'),
      coffeeHouseRentList: require('../assets/sass/admin/apply/CoffeeHouseRentList.scss'),
      mealCardList: require('../assets/sass/admin/apply/MealCardList.scss'),
      parkingPermitList: require('../assets/sass/admin/apply/ParkingPermitList.scss'),
      recruitList: require('../assets/sass/admin/apply/RecruitList.scss'),
      idCardRealNameList: require('../assets/sass/admin/apply/IdCardRealNameList.scss'),
      schoolInfoList: require('../assets/sass/admin/apply/SchoolInfoList.scss'),
      idCardRealNameInfo: require('../assets/sass/admin/apply/IdCardRealNameInfo.scss'),
      schoolInfo: require('../assets/sass/admin/apply/SchoolInfo.scss'),
      createProjectInfo: require('../assets/sass/admin/apply/CreateProjectInfo.scss'),
      companyEnterInfo: require('../assets/sass/admin/apply/CompanyEnterInfo.scss'),
      conferenceRoomRentInfo: require('../assets/sass/admin/apply/ConferenceRoomRentInfo.scss'),
      coffeeHouseRentInfo: require('../assets/sass/admin/apply/CoffeeHouseRentInfo.scss'),
      mealCardInfo: require('../assets/sass/admin/apply/MealCardInfo.scss'),
      parkingPermitInfo: require('../assets/sass/admin/apply/ParkingPermitInfo.scss'),
      recruitInfo: require('../assets/sass/admin/apply/RecruitInfo.scss'),
    },
    applyEdit: {
      edit: require('../assets/sass/admin/applyEdit/Edit.scss'),
    },
    process: {
      info: require('../assets/sass/admin/process/Info.scss'),
      edit: require('../assets/sass/admin/process/Edit.scss'),
    },
  },

  // 前端
  index: {
    home: require("../assets/sass/index/user/Home.scss"),
    user: {
      apply: {
        index: require("../assets/sass/index/user/apply/Index.scss"),
        createProject: require("../assets/sass/index/user/apply/CreateProject.scss"),
        companyEnter: require("../assets/sass/index/user/apply/CompanyEnter.scss"),
        conferenceRoomRent: require("../assets/sass/index/user/apply/ConferenceRoomRent.scss"),
        coffeeHouseRent: require("../assets/sass/index/user/apply/CoffeeHouseRent.scss"),
        mealCard: require("../assets/sass/index/user/apply/MealCard.scss"),
        parkingPermit: require("../assets/sass/index/user/apply/ParkingPermit.scss"),
        recruit: require("../assets/sass/index/user/apply/Recruit.scss"),
      },
      auth: {
        idCardRealName: require("../assets/sass/index/user/auth/IdCardRealName.scss"),
        schoolInfo: require("../assets/sass/index/user/auth/SchoolInfo.scss"),
        mergeInfo: require("../assets/sass/index/user/auth/MergeInfo.scss"),

      },
      common: {
        login: require("../assets/sass/index/user/common/Login.scss"),
        forgetPassword: require("../assets/sass/index/user/common/ForgetPassword.scss"),
        register: require("../assets/sass/index/user/common/Register.scss"),
        modifyPassword: require("../assets/sass/index/user/common/ModifyPassword.scss"),
      },
      info: require("../assets/sass/index/user/Info.scss"),
      settings: require("../assets/sass/index/user/Settings.scss"),
    },
    company: {
      index: require("../assets/sass/index/company/Index.scss"),
      mealCard: require("../assets/sass/index/company/MealCard.scss"),
      parkingPermit: require("../assets/sass/index/company/ParkingPermit.scss"),
    },
  },

}
