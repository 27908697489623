import React, { Component } from "react";
import { NavBar, Icon, ActivityIndicator, } from "antd-mobile";
import { Link, } from "react-router-dom";
import AliIcon from "../../../utils/iconfont";
import gStyle from "../../../utils/sass";
const style = gStyle.index.company.index;

export default class Index extends Component {
  constructor(props) {
    super(props);
    this.list = [
      {
        id: 1,
        icon: "icon-iconfontzhizuobiaozhun023123",
        title: "我的饭卡",
        href: "/project/mealCard",
      },
      {
        id: 2,
        icon: "icon-xukezheng",
        title: "我的停车证",
        href: "/project/parkingPermit",
      },
    ];
    this.state = {
      isAnimating: true,
    }
  }
  componentDidMount() {
    this.init();
  }
  init = _ => {
    this.setState({
      isAnimating: false,
    })
  }
  render() {
    return (<div className={style.index}>
      <ActivityIndicator animating={this.state.isAnimating} className={style.loading} text="loading..." />
      <NavBar className={style.header} mode="light" icon={<Link to="/?tab=my"><Icon type="left" /></Link>} >我的项目</NavBar>
      <div className={style.list}>
        {this.list.map((v, k) => {
          return (<Link to={v.href} key={k} className={style.item}>
            <div className={style.flex}>
              <div className={style.icon}><AliIcon type={v.icon} /></div>
              <div className={style.title}>{v.title}</div>
            </div>
            <div className={style.leftIcon}><Icon type="right" /></div>
          </Link>);
        })}
      </div>
    </div >);
  }
}
