import React from "react";
import {
  List,
  Picker,
  ActivityIndicator,
  Icon,
  Switch,
  Flex,
  ImagePicker,
  NavBar,
} from "antd-mobile";
import { Form } from "antd";
import { withRouter, Link } from "react-router-dom";
import gStyle from "../../../utils/sass";
import { IS_GRADUATE } from "../../../services/schoolInfo";
import collegeApi from "../../../services/college";
import schoolSpecialityApi from "../../../services/schoolSpeciality";
import { EDUCATION, EDUCATION_ZH } from "../../../services/apply";
import ApplyWithSendMsg from "../../../components/index/user/apply/ApplyWithSendMsg";
import userApi from "../../../services/user";
import { BASE_URL } from "../../../config/prod";

const style = gStyle.index.user.auth.schoolInfo;
const Item = List.Item;

class SchoolInfo extends ApplyWithSendMsg {
  constructor(props) {
    super(props);
    this.state = {
      animating: true,
      isGraduate: false,
      degreePic: [],
      oneCardPassPic: [],
      schoolInfo: {},
      college: [],
      speciality: [],
      grade: [],
      education: [],
    };
  }
  componentDidMount() {
    this.init();
  }

  init = async _ => {
    //获取学院列表
    try {
      let {
        data: { list: college },
      } = await collegeApi.getList({ page: -1, limit: -1 });
      college = [
        college.map(v => ({
          value: v.id,
          label: v.name,
        })),
      ];
      //获取学校专业列表
      let {
        data: { list: speciality },
      } = await schoolSpecialityApi.getList({ page: -1, limit: -1 });
      speciality = [
        speciality.map(v => ({
          value: v.id,
          label: v.name,
        })),
      ];
      //获取学历信息
      let education = [
        Object.values(EDUCATION).map((v, k) => ({
          value: v,
          label: EDUCATION_ZH[v],
        })),
      ];
      let grade = [],
        date = new Date().getFullYear(),
        gradeList = [];
      for (let i = 0; i <= date - 1990; i++) {
        gradeList.push({ value: 1990 + i, label: 1990 + i });
      }
      grade = [gradeList];
      this.setState({
        animating: false,
        college,
        speciality,
        education,
        grade,
      });
      /**
       *申请详情
       */
      const { setFieldsValue } = this.props.form;
      try {
        let {
          data: { detail },
        } = await userApi.getMySchoolInfoDetail();
        this.setState({
          isGraduate: detail.isGraduate === IS_GRADUATE.TRUE,
          degreePic: [{ url: BASE_URL + detail.degreePic }],
          oneCardPassPic: [{ url: BASE_URL + detail.oneCardPassPic }],
        });
        console.log(detail);
        setFieldsValue({
          college: [detail.college],
          speciality: [detail.speciality],
          grade: [detail.grade],
          education: [detail.education],
          isGraduate: [detail.isGraduate],
        });
      } catch (err) {
        console.log(err);
      }
    } catch (err) {
      console.log(err);
    }
  };
  //是否是毕业生
  isGraduateChange = val => {
    this.setState({
      isGraduate: val,
    });
  };

  getApplyDetailDom = _ => {
    const { getFieldProps } = this.props.form;
    return (
      <form className={style.form}>
        <List>
          <Picker
            {...getFieldProps("college", { rules: [{ required: true }] })}
            data={this.state.college}
            title="选择学院"
            cascade={false}>
            <List.Item arrow="horizontal">学院</List.Item>
          </Picker>
          <Picker
            {...getFieldProps("speciality", { rules: [{ required: true }] })}
            data={this.state.speciality}
            title="选择专业"
            cascade={false}>
            <List.Item arrow="horizontal">专业</List.Item>
          </Picker>
          <Picker
            {...getFieldProps("grade", { rules: [{ required: true }] })}
            data={this.state.grade}
            title="选择年级"
            cascade={false}>
            <List.Item arrow="horizontal">年级</List.Item>
          </Picker>
          <Picker
            {...getFieldProps("education", { rules: [{ required: true }] })}
            data={this.state.education}
            title="选择学历"
            cascade={false}>
            <List.Item arrow="horizontal">学历</List.Item>
          </Picker>
          <Item>
            <Flex>
              <div>是否为毕业生</div>
              <Switch
                {...getFieldProps("isGraduate", {
                  initialValue: this.state.isGraduate === IS_GRADUATE.TRUE,
                  valuePropName: "checked",
                  onChange: checked => {
                    this.setState({
                      isGraduate: checked,
                    });
                  },
                })}
                className={style.isGraduate}
              />
            </Flex>
          </Item>
          <div className={style.toolps}>
            提示:如果是毕业生, 请上传学位证图片; 如果不是毕业生, 请上传一卡通图片
          </div>
          <Item className={this.state.isGraduate ? "" : style.hidden}>
            {this.state.isGraduate ? (
              <ImagePicker
                files={this.state.degreePic}
                selectable={this.state.degreePic.length < 1}
                multiple={this.state.multiple}
                style={{ flex: 1 }}
              />
            ) : null}
          </Item>
          <Item className={!this.state.isGraduate ? "" : style.hidden}>
            {!this.state.isGraduate ? (
              <ImagePicker
                files={this.state.oneCardPassPic}
                selectable={this.state.oneCardPassPic.length < 1}
                multiple={this.state.multiple}
                style={{ flex: 1 }}
              />
            ) : null}
          </Item>
        </List>
      </form>
    );
  };
  render() {
    return this.isShowDetail ? (
      this.getApplyDetailDom()
    ) : (
        <div className={style.schoolInfo}>
          <ActivityIndicator
            animating={this.state.animating}
            className={style.loading}
            text="loading..."
          />
          <NavBar
            className={this.isShowDetail ? style.hidden : null}
            mode="light"
            icon={
              <Link to="/?tab=my">
                <Icon type="left" />
              </Link>
            }>
            学校信息
        </NavBar>
          {this.getApplyDetailDom()}
        </div>
      );
  }
}
export default withRouter(Form.create()(SchoolInfo));
