import React, { Component } from "react";
import { NavBar, Icon, ActivityIndicator, } from "antd-mobile";
import { Link, } from "react-router-dom";
import ParkingPermitList from "../../../components/index/company/ParkingPermitList";
import gStyle from "../../../utils/sass";
const style = gStyle.index.company.parkingPermit

export default class ParkingPermit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAnimating: true,
      list: [],
    }
  }
  componentDidMount() {
    this.init()
  }
  init = _ => {
    let list = [
      {
        id: 1,
        name: "吴二伟",
        contactInfo: "15048583856",
        driverLicensePic: {
          id: 2,
          title: "TIM截图20190130162238.png",
          url: "/uploads/20190301/adee4f65fb59b19df681c686856ed3ef.png"
        },
        drivingLicensePic: {
          id: 2,
          title: "TIM截图20190130162238.png",
          url: "/uploads/20190301/adee4f65fb59b19df681c686856ed3ef.png"
        },
        ctime: "2018-12-12 00:00:00",
      },
      {
        id: 2,
        name: "吴二伟",
        contactInfo: "15048583856",
        driverLicensePic: {
          id: 2,
          title: "TIM截图20190130162238.png",
          url: "/uploads/20190301/adee4f65fb59b19df681c686856ed3ef.png"
        },
        drivingLicensePic: {
          id: 2,
          title: "TIM截图20190130162238.png",
          url: "/uploads/20190301/adee4f65fb59b19df681c686856ed3ef.png"
        },
        ctime: "2018-12-12 00:00:00",
      },
    ];
    this.setState({
      list,
      isAnimating: false,
    })
  }
  render() {
    return (<div className={style.index}>
      <ActivityIndicator animating={this.state.isAnimating} className={style.loading} text="loading..." />
      <NavBar className={style.header} mode="light" icon={<Link to="/company/index"><Icon type="left" /></Link>}
        rightContent={this.state.list.length < 3 ? [<div key="0" style={{ marginRight: '16px', fontSize: 14 }} >
          <Link to="/user/apply/parkingPermit">申请停车证</Link></div>] : null}
      >停车证列表</NavBar>
      <ParkingPermitList list={this.state.list} />
    </div >);
  }
}
