import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
  // Redirect,
} from "react-router-dom";
import AdminLogin from "./pages/admin/user/Login";
import AdminIndex from "./pages/admin/Index";
import Page404 from "./pages/common/Page404";
import Test from "./pages/Test";
import Logout from "./pages/common/Logout";
import AdminAuthRoute from "./utils/AdminAuth";
import IndexHome from "./pages/index/Home";
import IndexUserWrap from "./components/index/user/Wrap";
import IndexCompany from "./components/index/company/Wrap";
import IndexProject from "./components/index/project/Wrap";
import "./assets/sass/common.scss";
import AddPaymentRecord from "./pages/admin/company/AddPaymentRecord";

class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
          {/* <Redirect path="/" exact to="/" /> */}
          <Route path="/" exact component={IndexHome} />
          <Route path="/addPaymentRecord" exact component={AddPaymentRecord} />
          <Route path="/test" exact component={Test} />
          <Route path="/logout" exact component={Logout} />
          <Route path="/admin/login" exact component={AdminLogin} />
          <AdminAuthRoute path="/admin" component={AdminIndex} />
          <AdminAuthRoute path="/settings" component={AdminIndex} />
          <Route path="/user" component={IndexUserWrap} />
          <Route path="/company" component={IndexCompany} />
          <Route path="/project" component={IndexProject} />
          <Route component={Page404} />
        </Switch>
      </Router>
    );
  }
}
export default App;
