import React from "react";
import {
  List,
  ActivityIndicator,
  Icon,
  Button,
  ImagePicker,
  Toast,
  Modal,
  NavBar,
  Picker,
  InputItem,
} from "antd-mobile";
import { message } from "antd";
import { createForm } from "rc-form";
import { withRouter, Link } from "react-router-dom";
import gStyle from "../../../../utils/sass";
import parkingPermitApi from "../../../../services/parkingPermit";
import ApplyWithSendMsg from "../../../../components/index/user/apply/ApplyWithSendMsg";
import applyApi, { APPLY_STATUS } from "../../../../services/apply";
import userApi from "../../../../services/user";
import companyApi from "../../../../services/company";
import { BASE_URL } from "../../../../config/prod";
import { antdUploadImg } from "../../../../services/api";
import storage from "../../../../utils/storage";

const style = gStyle.index.user.apply.parkingPermit;
const Item = List.Item;

class ParkingPermit extends ApplyWithSendMsg {
  isShowDetail = false;
  constructor(props) {
    super(props);
    this.isShowDetail = props.isShowDetail || false;
    this.applyId = props.applyId;
    this.state = {
      isAnimating: true,
      identityCodeValidRecode: true,
      isModalActive: false,
      modalBody: "",
      modalFooter: [],
      driverLicensePic: [], //驾驶证
      drivingLicensePic: [], //行驶证
      socialImg: [],//当月社保缴纳凭证
      idCardFirstPic: [],  //正面
      idCardSecondPic: [],  //反面
      applyDetail: {
        id: 1,
        steps: [],
        info: {
          carUserIdCardNo: "",
        },
      },
      allShareholderList: [],
      isAllowEdit: false,
    };
  }
  componentDidMount() {
    this.init();
  }

  init = async _ => {
    const {
      data: { list: shareholderList },
    } = await companyApi.getShareholderList({
      page: -1,
      limit: -1,
      filter: {
        ownUid: storage.get("uid"),
      },
    });
    let allShareholderList = [
      shareholderList.map(v => ({
        value: v.id_card_no,
        label: v.id_card_name + " - " + v.id_card_no,
      })),
    ];
    const {
      data: {
        detail: { idCardNo: ownIdCardNo, name: ownIdCardName },
      },
    } = await userApi.getMyIdRealNameDetail();
    allShareholderList[0].push({
      value: ownIdCardNo,
      label: ownIdCardName + " - " + ownIdCardNo,
    });
    await new Promise(s =>
      this.setState(
        {
          allShareholderList,
        },
        s,
      ),
    );
    if (this.isShowDetail) {
      const { setFieldsValue } = this.props.form;
      try {
        let {
          data: { detail: applyDetail },
        } = await applyApi.getApplyDetail({ id: this.applyId });
        let isAllowEdit = false;
        if (applyDetail.status === APPLY_STATUS['process']) {
          isAllowEdit = true;
        }
        applyDetail.id = applyDetail.apply_uid;
        this.setState({
          isAllowEdit,
          applyDetail,
          isAnimating: false,
          driverLicensePic: [{ url: BASE_URL + applyDetail.info.driverLicensePic, uid: applyDetail.info.driverLicense.id }],
          drivingLicensePic: [{ url: BASE_URL + applyDetail.info.drivingLicensePic, uid: applyDetail.info.drivingLicense.id }],
          idCardFirstPic: [{ url: BASE_URL + applyDetail.info.idCardFirstPic, uid: applyDetail.info.idCardFirst.id }],
          idCardSecondPic: [{ url: BASE_URL + applyDetail.info.idCardSecondPic, uid: applyDetail.info.idCardSecond.id }],
          socialImg: [{ url: BASE_URL + applyDetail.info.socialImg, uid: applyDetail.info.social.id }],
        });
        setFieldsValue({
          carUserIdCardNo: [applyDetail.info.carUserIdCardNo],
          contactInfo: applyDetail.info.contactInfo,
          licenseNum: applyDetail.info.license,
          idCardNo: applyDetail.info.carUserIdCardNo,
        });
      } catch (err) {
        console.log(err);
      }
    } else {
      this.setState({
        isAnimating: false,
      });
    }
  };
  apply = () => {
    this.props.form.validateFields({ force: true }, async err => {
      let fields = this.props.form.getFieldsValue();
      if (err) {
        Toast.fail(err[Object.keys(err)[0]].errors[0].message, 2);
        return;
      }
      if (this.state.driverLicensePic.length === 0) {
        Toast.fail("请上传驾驶证证件照", 1);
        return;
      }
      if (this.state.drivingLicensePic.length === 0) {
        Toast.fail("请上传行驶证证件照", 1);
        return;
      }
      if (this.state.idCardFirstPic.length === 0) {
        Toast.fail("请上传身份证正面", 1);
        return;
      }
      if (this.state.idCardSecondPic.length === 0) {
        Toast.fail("请上传身份证反面", 1);
        return;
      }
      if (this.state.socialImg.length === 0) {
        Toast.fail("请上传当月社保缴纳证", 1);
        return;
      }
      if (this.state.isAllowEdit) {
        let { driverLicensePic, drivingLicensePic, idCardSecondPic, idCardFirstPic, socialImg } = this.state
        if (driverLicensePic.length !== 0) {
          if (driverLicensePic[0].picId) {
            driverLicensePic = driverLicensePic[0].picId
          } else {
            driverLicensePic = driverLicensePic[0].uid
          }
        }
        if (drivingLicensePic.length !== 0) {
          if (drivingLicensePic[0].picId) {
            drivingLicensePic = drivingLicensePic[0].picId
          } else {
            drivingLicensePic = drivingLicensePic[0].uid
          }
        }
        if (idCardFirstPic.length !== 0) {
          if (idCardFirstPic[0].picId) {
            idCardFirstPic = idCardFirstPic[0].picId
          } else {
            idCardFirstPic = idCardFirstPic[0].uid
          }
        }
        if (idCardSecondPic.length !== 0) {
          if (idCardSecondPic[0].picId) {
            idCardSecondPic = idCardSecondPic[0].picId
          } else {
            idCardSecondPic = idCardSecondPic[0].uid
          }
        }

        if (socialImg.length !== 0) {
          if (socialImg[0].picId) {
            socialImg = socialImg[0].picId
          } else {
            socialImg = socialImg[0].uid
          }
        }
        parkingPermitApi.edit({
          applyId: this.applyId,
          admin: false,
          contactInfo: fields.contactInfo,
          licenseNum: fields.licenseNum,
          drivingLicensePic,
          driverLicensePic,
          idCardSecondPic,
          idCardFirstPic,
          socialImg,
        })
          .then(() => {
            this.setState({
              isAllowEdit: false,
            })
            Toast.success("修改成功", 1);
          })
          .catch(err => {
            Toast.fail("修改失败,失败原因: " + err.msg, 1);
          });
      } else {
        try {
          await parkingPermitApi.applyParkingPermit({
            licenseNum: fields.licenseNum,
            idCardNo: fields.idCardNo,
            carUserIdCardNo: fields.carUserIdCardNo[0],
            contactInfo: fields.contactInfo,
            driverLicensePic: this.state.driverLicensePic[0],
            drivingLicensePic: this.state.drivingLicensePic[0],
            idCardFirstPic: this.state.idCardFirstPic[0],
            idCardSecondPic: this.state.idCardSecondPic[0],
            socialImg: this.state.socialImg[0],
          });
          this.setState({
            isModalActive: true,
            modalBody: <div>提交成功,请等待审核</div>,
            modalFooter: [{ text: "确定", onPress: () => this.props.history.goBack() }],
          });
        } catch (err) {
          this.setState({
            isModalActive: true,
            modalBody: <div>提交失败,错误信息:{err.msg}</div>,
            modalFooter: [{ text: "确定", onPress: () => this.setState({ isModalActive: false }) }],
          });
        }
      }
    });
  };
  onReset = () => {
    this.props.form.resetFields();
  };
  getApplyDetailDom = _ => {
    const { getFieldDecorator, getFieldError } = this.props.form;
    return (
      <form className={style.form}>
        <List>
          <div className={style.promptPadding}>
            <div className={style.prompt}>必须是交社保的法人或者股东, 条件必须是吻合才行</div>
          </div>
          {getFieldDecorator("carUserIdCardNo", {
            rules: [{ required: true, message: "请选择车主" }],
          })(
            <Picker data={this.state.allShareholderList} title="请选择车主" onChange={evt => {
              this.props.form.setFieldsValue({
                idCardNo: evt[0],
              });
            }} cascade={false}>
              <List.Item arrow="horizontal">请选择车主</List.Item>
            </Picker>,
          )}
          {getFieldDecorator("contactInfo", {
            rules: [{ required: true, message: "请输入手机号码" }],
          })(
            <InputItem
              clear
              className={style.input}
              type='digit'
              placeholder="请输入"
              error={!!getFieldError("contactInfo")}
              extra={<Icon size="md" color="#ccc" type="right" />}>
              手机号码
            </InputItem>,
          )}
          {getFieldDecorator("licenseNum", {
            rules: [{ required: true, message: "请输入车牌号码" }],
          })(
            <InputItem
              clear
              className={style.input}
              placeholder="请输入"
              error={!!getFieldError("licenseNum")}
              extra={<Icon size="md" color="#ccc" type="right" />}>
              车牌号码
            </InputItem>,
          )}
          {getFieldDecorator("idCardNo", {
            rules: [{ required: true, message: "请输入身份证" }],
          })(
            <InputItem
              clear
              className={style.input}
              placeholder="请输入" disabled
              error={!!getFieldError("idCardNo")}
              extra={<Icon size="md" color="#ccc" type="right" />}>
              身份证
            </InputItem>,
          )}
          <Item>
            <div className={style.title}>驾驶证证件照</div>
            <ImagePicker
              files={this.state.driverLicensePic}
              onChange={(files, type, index) => {
                if (type === "add") {
                  let formData = new FormData();
                  formData.append("name", "file");
                  formData.append("file", files[0].file);
                  antdUploadImg(formData)
                    .then(json => {
                      const {
                        data: { name, picId, url },
                      } = json;
                      this.setState({
                        driverLicensePic: [
                          {
                            name,
                            picId,
                            url: BASE_URL + url,
                          },
                        ],
                      });
                    })
                    .catch(e => {
                      message.error("上传图片失败", 1);
                      console.log(e);
                    });
                } else if (type === "remove") {
                  this.setState({
                    driverLicensePic: [],
                  });
                }
              }}
              selectable={this.state.driverLicensePic.length < 1}
              multiple={this.state.multiple}
              style={{ flex: 1 }}
            />
          </Item>
          <Item>
            <div className={style.title}>行驶证证件照</div>
            <ImagePicker
              files={this.state.drivingLicensePic}
              onChange={(files, type, index) => {
                if (type === "add") {
                  let formData = new FormData();
                  formData.append("name", "file");
                  formData.append("file", files[0].file);
                  antdUploadImg(formData)
                    .then(json => {
                      const {
                        data: { name, picId, url },
                      } = json;
                      this.setState({
                        drivingLicensePic: [
                          {
                            name,
                            picId,
                            url: BASE_URL + url,
                          },
                        ],
                      });
                    })
                    .catch(e => {
                      message.error("上传图片失败", 1);
                      console.log(e);
                    });
                } else if (type === "remove") {
                  this.setState({
                    drivingLicensePic: [],
                  });
                }
              }}
              selectable={this.state.drivingLicensePic.length < 1}
              multiple={this.state.multiple}
              style={{ flex: 1 }}
            />
          </Item>
          <Item>
            <div className={style.title}>身份证正面照</div>
            <ImagePicker
              files={this.state.idCardFirstPic}
              onChange={(files, type, index) => {
                if (type === "add") {
                  let formData = new FormData();
                  formData.append("name", "file");
                  formData.append("file", files[0].file);
                  antdUploadImg(formData)
                    .then(json => {
                      const {
                        data: { name, picId, url },
                      } = json;
                      this.setState({
                        idCardFirstPic: [
                          {
                            name,
                            picId,
                            url: BASE_URL + url,
                          },
                        ],
                      });
                    })
                    .catch(e => {
                      message.error("上传图片失败", 1);
                      console.log(e);
                    });
                } else if (type === "remove") {
                  this.setState({
                    idCardFirstPic: [],
                  });
                }
              }}
              selectable={this.state.idCardFirstPic.length < 1}
              multiple={this.state.multiple}
              style={{ flex: 1 }}
            />
          </Item>
          <Item>
            <div className={style.title}>身份证反面照</div>
            <ImagePicker
              files={this.state.idCardSecondPic}
              onChange={(files, type, index) => {
                if (type === "add") {
                  let formData = new FormData();
                  formData.append("name", "file");
                  formData.append("file", files[0].file);
                  antdUploadImg(formData)
                    .then(json => {
                      const {
                        data: { name, picId, url },
                      } = json;
                      this.setState({
                        idCardSecondPic: [
                          {
                            name,
                            picId,
                            url: BASE_URL + url,
                          },
                        ],
                      });
                    })
                    .catch(e => {
                      message.error("上传图片失败", 1);
                      console.log(e);
                    });
                } else if (type === "remove") {
                  this.setState({
                    idCardSecondPic: [],
                  });
                }
              }}
              selectable={this.state.idCardSecondPic.length < 1}
              multiple={this.state.multiple}
              style={{ flex: 1 }}
            />
          </Item>
          <Item>
            <div className={style.title}>当月社保缴纳凭证</div>
            <ImagePicker
              files={this.state.socialImg}
              onChange={(files, type, index) => {
                if (type === "add") {
                  let formData = new FormData();
                  formData.append("name", "file");
                  formData.append("file", files[0].file);
                  antdUploadImg(formData)
                    .then(json => {
                      const {
                        data: { name, picId, url },
                      } = json;
                      this.setState({
                        socialImg: [
                          {
                            name,
                            picId,
                            url: BASE_URL + url,
                          },
                        ],
                      });
                    })
                    .catch(e => {
                      message.error("上传图片失败", 1);
                      console.log(e);
                    });
                } else if (type === "remove") {
                  this.setState({
                    socialImg: [],
                  });
                }
              }}
              selectable={this.state.socialImg.length < 1}
              multiple={this.state.multiple}
              style={{ flex: 1 }}
            />
          </Item>
        </List>
        {
          this.state.isAllowEdit ? (
            <div className={style.operation}>
              <Button size="small" type="primary" className={style.btn} inline onClick={this.apply}>修改提交</Button>
            </div>
          ) : ''
        }
      </form>
    );
  };
  render() {
    return this.isShowDetail ? (
      this.getApplyDetailDom()
    ) : (
        <div className={style.parkingInfo}>
          <ActivityIndicator
            animating={this.state.isAnimating}
            className={style.loading}
            text="loading..."
          />
          <NavBar
            className={this.isShowDetail ? style.hidden : null}
            mode="light"
            icon={
              <Link to="/">
                <Icon type="left" />
              </Link>
            }>
            停车证申请
        </NavBar>
          {this.getApplyDetailDom()}
          <div className={style.operation}>
            <Button size="small" className={style.btn} inline onClick={this.onReset}>
              重置
          </Button>
            <Button size="small" type="primary" className={style.btn} inline onClick={this.apply}>
              提交
          </Button>
          </div>
          <Modal
            visible={this.state.isModalActive}
            transparent
            maskClosable={false}
            title="提示"
            footer={this.state.modalFooter}>
            {this.state.modalBody}
          </Modal>
        </div>
      );
  }
}
export default withRouter(createForm()(ParkingPermit));
