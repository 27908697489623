import React from "react";
import moment from "moment";
// import applyName from "../../../services/idCardRealName";
import gSass from "../../../utils/sass";
import { BASE_URL, } from '../../../config/prod';
import { Link, } from "react-router-dom";
import qs from "qs";
import {
  Breadcrumb,
  Form,
  Input,
  message,
  Spin,
  Button,
  Upload,
  Icon,
  Modal,
  Select,
  Radio,
  InputNumber,
  Row,
  Col,
  Badge,
  Collapse,
  Cascader,
  Empty,
  DatePicker
} from "antd";
// import { NavLink } from "react-router-dom";
// import { IS_HOUSEHOLDREGISTER } from "../../../services/idCardRealName";
import { IS_GRADUATE } from "../../../services/schoolInfo";
import { getUploadImgUrl } from '../../../services/api';
import { TYPE as PATENT_TYPE, TYPE_ZH as PATENT_TYPE_ZH, FINANCING_TYPE as FinancingType, FINANCING_TYPE_ZH as FinancingTypeZh, SUBORDINATE_TYPE as SubordinateType, SUBORDINATE_TYPE_ZH as SubordinateTypeZh } from "../../../services/patent";
import applyApi, { EDUCATION, EDUCATION_ZH } from "../../../services/apply";
import companyApi from "../../../services/company";
import { getRegion, IdentityCodeValid } from "../../../utils/utils";
const style = gSass.admin.applyEdit.edit;
const Option = Select.Option;
const RadioGroup = Radio.Group;
const Panel = Collapse.Panel;
const { TextArea } = Input;
const formItemLayout = {
  labelCol: {
    lg: {
      span: 4,
      offset: 1,
    },
  },
  wrapperCol: {
    lg: {
      span: 10,
      offset: 0,
    },
  },
};
let uploadList = [];
// 上传附件
const upload = {
  action: BASE_URL + '/antdUploadImg',
  onChange({ file, fileList }) {
    if (file.status !== 'uploading') {
      if (file.response.code === 0) {
        uploadList.push(file.response.data)
      } else {
        console.log(file.response.msg);
      }
    }
  },
  // defaultFileList: uploadList,
}
//地址
const uploadButton = (
  <div>
    <Icon type="plus" />
    <div className="ant-upload-text">上传图片</div>
  </div>
);
class Edit extends React.Component {
  constructor(props) {
    super(props);
    let { id } = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    id = parseInt(id);
    this.state = {
      projectId: id,
      spinning: true,
      industryList: [],
      patentTypeList: [],
      financingTypeList: [],
      subordinateTypeList: [],

      educationList: [],
      patentList: [],  //获取专利的当前fields中存放的下标值
      financingList: [],
      shareholderList: [],
      awardRecordList: [],
      overseasMemberList: [],
      shareholderStudyAbroadEducationProvePicLists: [{
        picId: "",
        url: "",
      }],
      shareholderStudyAbroadEducationProvePicList: [],//股东中的海外留学图片列表
      shareholderStudyAbroadList: [],//股东中的海外留学的数组下标值
      isShareholderAbroad: [],
      userInfo: {},
      previewVisible: false,
      previewImage: '',
      applyId: 0,
      infoId: 0,
      businessLicensePic: [],
      bankAccountOpeningCertificatePic: [],
      uploadStateList: [],//上传的附件列表默认值

      patentPicList: [],//专利证书图片
      awardRecordPicList: [],
      overseasMemberPicList: [],
      financingRecordPicList: [],

      activePatent: [],  //获取专利的数据库的id
      activeFinancingList: [],
      activeAwardRecordList: [],
      activeOverseasMemberList: [],

      activeShareholderList: []
    };
  }
  componentDidMount() {
    this.init();
  }
  init = async _ => {
    try {
      let {
        data: { list: industryList },
      } = await companyApi.getIndustryList({ page: -1, limit: -1 });
      industryList = industryList.map(v => ({
        value: v.id,
        label: v.name,
      }));
      const patentTypeList = Object.values(PATENT_TYPE).map(v => ({
        value: v,
        label: PATENT_TYPE_ZH[v],
      }));

      const financingTypeList = Object.values(FinancingType).map(v => ({
        value: v,
        label: FinancingTypeZh[v]
      }))
      // 所属等级
      const subordinateTypeList = Object.values(SubordinateType).map(v => ({
        value: v,
        label: SubordinateTypeZh[v],
      }))
      //获取学历信息
      let educationList = Object.values(EDUCATION).map((v, k) => ({
        value: v,
        label: EDUCATION_ZH[v],
      }));
      let {
        data: { detail },
      } = await applyApi.getApplyDetail({ id: this.state.projectId });
      let region = await getRegion();
      let shareholderList = [],
        patentList = [],
        financingList = [],
        awardRecordList = [],
        overseasMemberList = [];
      for (let i = 0; i < detail.shareholderList.length; i++) {
        shareholderList.push(i);
      }

      for (let i = 0; i < detail.patentList.length; i++) {
        patentList.push(i);
      }
      for (let i = 0; i < detail.financingRecordList.length; i++) {
        financingList.push(i);
      }
      for (let i = 0; i < detail.awardRecordList.length; i++) {
        awardRecordList.push(i);
      }
      // 海外
      for (let i = 0; i < detail.overseas_id_list.length; i++) {
        overseasMemberList.push(i);
      }

      let shareholderStudyAbroadEducationProvePicList = this.state
        .shareholderStudyAbroadEducationProvePicList,
        shareholderStudyAbroadList = this.state.shareholderStudyAbroadList;
      for (let i = 0; i < detail.shareholderList.length; i++) {
        if (detail.shareholderList[i].studyAbroadInfo !== null) {
          shareholderStudyAbroadEducationProvePicList[i] = [
            {
              uid: detail.shareholderList[i].studyAbroadInfo.educationProvePic.id,
              url: BASE_URL + detail.shareholderList[i].studyAbroadInfo.educationProvePic.url,
            },
          ];
          shareholderStudyAbroadList[i] = [0];
        }
      }
      // let patentPicList = this.state.patentPicList;
      // for (let i = 0; i < detail.patentList.length; i++) {
      //   patentPicList[i] = [{
      //     uid: detail.patentList[i].file_id_list.id,
      //     url: BASE_URL + detail.patentList[i].file_id_list.url,
      //   }]
      // }
      // 附件
      let uploadStateList = this.state.uploadStateList
      if (detail.info.company_plan.length > 0) {
        for (let v of detail.info.company_plan) {
          uploadStateList.push({
            uid: v.picId,
            name: v.name,
            url: BASE_URL + v.url
          })
        }
        uploadList = uploadStateList
      } else {
        uploadStateList = []
      }
      this.setState({
        spinning: false,
        applyId: detail.info.id,
        industryList,
        patentTypeList,
        financingTypeList,
        subordinateTypeList,
        overseasMemberList,
        patentList,
        educationList,
        shareholderStudyAbroadEducationProvePicList,
        shareholderStudyAbroadList,
        region,
        // uploadStateList,
        businessLicensePic: [
          {
            uid: detail.info.businessLicensePic.id,
            url: BASE_URL + detail.info.businessLicensePic.url,
          },
        ],
        bankAccountOpeningCertificatePic: [
          {
            uid: detail.info.bankAccountOpeningCertificatePic.id,
            url: BASE_URL + detail.info.bankAccountOpeningCertificatePic.url,
          },
        ],
        userInfo: {
          shareholderList: detail.shareholderList,
          patentList: detail.patentList,
          financingList: detail.financingList,
          awardRecordList: detail.awardRecordList,
        },
        shareholderList,
        financingList,
        awardRecordList,
      });
      this.props.form.setFieldsValue({
        // 基本信息
        name: detail.applyUser.name,
        idCardNo: detail.applyUser.id_no,
        phone: detail.applyUser.phone,
        email: detail.applyUser.email,
        // 公司介绍
        cname: detail.info.name,
        totalNumber: detail.info.totalNumber,
        teamMemberNumberHai: detail.info.shanghaiNumber,
        registerTime: moment(detail.info.registerTime, "YYYY-MM-DD HH:mm:ss"),
        paySocialSecurityNum: detail.info.insuredPerson,
        registerAddress: [
          [
            detail.info.registerAddress.province,
            detail.info.registerAddress.city,
            detail.info.registerAddress.county,
          ],
        ],
        registerCapital: (detail.info.registerCapital) / 100,
        registerAddressDetail: detail.info.registerAddress.detail,
        mainBusiness: detail.info.mainBusiness,
        industry: detail.info.industryId,
        entrepreneurs: detail.info.entrepreneurs ? 1 : 0,
      });

      // 股东
      let activeShareholderList = []
      // let isShareholderAbroad = []
      for (let i = 0; i < detail.shareholderList.length; i++) {
        activeShareholderList.push({
          id: detail.shareholderList[i].id
        })
        this.props.form.setFieldsValue({
          ["shareholderName" + i]: detail.shareholderList[i].name,
          ["shareholderEducation" + i]: detail.shareholderList[i].education,
          ["shareholderAddress" + i]: [
            detail.shareholderList[i].address.province_cid,
            detail.shareholderList[i].address.city_cid,
            detail.shareholderList[i].address.county_cid,
          ],
          ["shareholderDetail" + i]: detail.shareholderList[i].address.detail,
          ["shareholderShares" + i]: detail.shareholderList[i].holder_shares,

          ["shareholderIdCardNo" + i]: detail.shareholderList[i].idCardNo,
        });
        if (detail.shareholderList[i].studyAbroadInfo !== null) {

          this.props.form.setFieldsValue({
            ["shareholderHasStudyAbroad" + i]: !!detail.shareholderList[i].studyAbroadInfo,
            ["shareholderStudyAbroadSchoolName" + i]: detail.shareholderList[i].studyAbroadInfo
              .school_name,
            ["shareholderStudyAbroadEducation" + i]: detail.shareholderList[i].studyAbroadInfo
              .education,

          })
        }
      }
      // 专利
      let patentPicList = this.state.patentPicList
      let activePatent = [];
      for (let i = 0; i < detail.patentList.length; i++) {
        patentPicList[i] = [
          {
            uid: detail.patentList[i].file_id_list[0].id,
            url: BASE_URL + detail.patentList[i].file_id_list[0].url,
          }
        ]
        activePatent.push({
          id: detail.patentList[i].id
        })
        this.props.form.setFieldsValue({
          ["patentName" + i]: detail.patentList[i].name,
          ["patentTime" + i]: moment(detail.patentList[i].time, "YYYY-MM-DD HH:mm:ss"),
          ["patentType" + i]: detail.patentList[i].type,
        });
      }
      // 融资
      let activeFinancingList = [];
      let financingRecordPicList = this.state.financingRecordPicList
      for (let i = 0; i < detail.financingRecordList.length; i++) {
        financingRecordPicList[i] = [
          {
            uid: detail.financingRecordList[i].file_id_list[0].id,
            url: BASE_URL + detail.financingRecordList[i].file_id_list[0].url,
          }
        ]
        activeFinancingList.push({
          id: detail.financingRecordList[i].id
        })
        this.props.form.setFieldsValue({
          ["financingPrice" + i]: detail.financingRecordList[i].money / 100,
          ["financingTime" + i]: moment(detail.financingRecordList[i].time, "YYYY-MM-DD HH:mm:ss"),
          ["financingSource" + i]: detail.financingRecordList[i].source,
          ["financingMode" + i]: parseInt(detail.financingRecordList[i].mode),
        });
      }
      // 获奖
      let activeAwardRecordList = []
      let awardRecordPicList = this.state.awardRecordPicList
      for (let i = 0; i < detail.awardRecordList.length; i++) {
        awardRecordPicList[i] = [
          {
            uid: detail.awardRecordList[i].file_id_list[0].id,
            url: BASE_URL + detail.awardRecordList[i].file_id_list[0].url,
          }
        ]
        activeAwardRecordList.push({
          id: detail.awardRecordList[i].id
        })
        this.props.form.setFieldsValue({
          ["belongLevel" + i]: parseInt(detail.awardRecordList[i].belong_level),
          ["prizeLevel" + i]: detail.awardRecordList[i].prize_level,
          ["awardName" + i]: detail.awardRecordList[i].name,
          ["awardObtainTime" + i]: moment(
            detail.awardRecordList[i].obtain_time,
            "YYYY-MM-DD HH:mm:ss",

          ),
        });
      }
      // 海外留学成员
      let activeOverseasMemberList = []
      let overseasMemberPicList = this.state.overseasMemberPicList
      for (let i = 0; i < detail.overseas_id_list.length; i++) {
        overseasMemberPicList[i] = [
          {
            uid: detail.overseas_id_list[i].degree_pic_url.id,
            url: BASE_URL + detail.overseas_id_list[i].degree_pic_url.url,
          }
        ]
        activeOverseasMemberList.push({
          id: detail.overseas_id_list[i].id
        })
        this.props.form.setFieldsValue({
          ["overseasMemberName" + i]: detail.overseas_id_list[i].name,
          ["overseasMemberTime" + i]: moment(detail.overseas_id_list[i].graduation_time, "YYYY-MM-DD HH:mm:ss"),
          ["overseasMemberCollege" + i]: detail.overseas_id_list[i].college_id,
          ["overseasMemberSpeciality" + i]: detail.overseas_id_list[i].speciality,
          ["overseasMemberEducation" + i]: detail.overseas_id_list[i].education,
        });
      }
      this.setState({
        overseasMemberPicList,
        patentPicList,
        awardRecordList,
        financingRecordPicList,
        activePatent,
        activeFinancingList,
        activeOverseasMemberList,
        activeAwardRecordList,
        activeShareholderList
      })

    } catch (e) {
      this.setState({
        spinning: false,
      });
    }
  };

  //提交
  updateUserInfo = evt => {
    evt.preventDefault();
    this.props.form.validateFields((err, fields) => {
      if (err) {
        return message.error(err[Object.keys(err)[0]].errors[0].message, 1);
      }
      let { businessLicensePic, bankAccountOpeningCertificatePic } = this.state
      if (businessLicensePic[0].id) {
        businessLicensePic = businessLicensePic[0].response.data.picId
      } else {
        businessLicensePic = businessLicensePic[0].uid
      }
      if (bankAccountOpeningCertificatePic[0].response) {
        bankAccountOpeningCertificatePic = bankAccountOpeningCertificatePic[0].response.data.picId
      } else {
        bankAccountOpeningCertificatePic = bankAccountOpeningCertificatePic[0].uid
      }
      // 海外
      let overseasMemberPicList = this.state.overseasMemberPicList;
      for (let v of overseasMemberPicList) {
        if (v[0].response) {
          let arr = v[0];
          v[0] = {};
          v[0].uid = arr.response.data.picId;
          v[0].url = BASE_URL + arr.response.data.url;
        }
      }
      // 专利
      let patentPicList = this.state.patentPicList;
      for (let v of patentPicList) {
        if (v[0].response) {
          let arr = v[0];
          v[0] = {};
          v[0].uid = arr.response.data.picId;
          v[0].url = BASE_URL + arr.response.data.url;
        }
      }
      // 融资
      let financingRecordPicList = this.state.financingRecordPicList;
      for (let v of financingRecordPicList) {
        if (v[0].response) {
          let arr = v[0];
          v[0] = {};
          v[0].uid = arr.response.data.picId;
          v[0].url = BASE_URL + arr.response.data.url;
        }
      }
      // 获奖
      let awardRecordPicList = this.state.awardRecordPicList;
      for (let v of awardRecordPicList) {
        if (v[0].response) {
          let arr = v[0];
          v[0] = {};
          v[0].uid = arr.response.data.picId;
          v[0].url = BASE_URL + arr.response.data.url;
        }
      }
      for (let i = 0; i < uploadList.length; i++) {
        if (uploadList[i].picId) {
          uploadList[i].uid = uploadList[i].picId.toString();
          delete uploadList[i].picId
        }
      }
      let patentList = [],
        financingList = [],
        prizeList = [],
        overseasMemberList = [],
        shareholderList = [];
      // 专利
      for (let i = 0; i < this.state.activePatent.length; i++) {
        let v = this.state.patentList[i]
        patentList.push({
          name: fields["patentName" + v],
          time: moment(fields["patentTime" + v]).format("YYYY-MM-DD HH:mm:ss"),
          type: fields["patentType" + v],
          id: this.state.activePatent[i].id,
          pic: patentPicList[v] ? [patentPicList[v][0]['uid']] : []
        });
      }
      // 融资
      ///
      for (let i = 0; i < this.state.activeFinancingList.length; i++) {
        let v = this.state.financingList[i]
        financingList.push({
          id: this.state.activeFinancingList[i].id,
          money: fields["financingPrice" + v] * 100,
          time: moment(fields["financingTime" + v]).format("YYYY-MM-DD HH:mm:ss"),
          source: fields["financingSource" + v],
          mode: fields["financingMode" + v],
          pic: financingRecordPicList[v] ? [financingRecordPicList[v][0]['uid']] : []
        });
      }
      // 获奖
      for (let i = 0; i < this.state.activeAwardRecordList.length; i++) {
        let v = this.state.awardRecordList[i]
        prizeList.push({
          id: this.state.activeAwardRecordList[i].id,
          name: fields["awardName" + v],
          time: moment(fields["awardObtainTime" + v]).format("YYYY-MM-DD HH:mm:ss"),
          prize_level: fields["prizeLevel" + v],
          belong_level: fields["belongLevel" + v],
          pic: awardRecordPicList[v] ? [awardRecordPicList[v][0]['uid']] : []
        });
      }
      // 海外 
      for (let i = 0; i < this.state.activeOverseasMemberList.length; i++) {
        let v = this.state.overseasMemberList[i];
        overseasMemberList.push({
          id: this.state.activeOverseasMemberList[i].id,
          name: fields["overseasMemberName" + v],
          college: fields["overseasMemberCollege" + v],
          speciality: fields["overseasMemberSpeciality" + v],
          time: moment(fields["overseasMemberTime" + v]).format("YYYY-MM-DD HH:mm:ss"),
          education: fields["overseasMemberEducation" + v],
          overseasMemberPic: this.state.overseasMemberPicList[v],
        });
      }


      // 转化
      let shareholderStudyAbroadEducationProvePicLists = []
      for (let i = 0; i < this.state.shareholderStudyAbroadEducationProvePicList.length; i++) {
        if (this.state.shareholderStudyAbroadEducationProvePicList[i] !== undefined) {
          shareholderStudyAbroadEducationProvePicLists.push({
            picId: this.state.shareholderStudyAbroadEducationProvePicList[i][0].uid,
            url: this.state.shareholderStudyAbroadEducationProvePicList[i][0].url
          })
        } else {
          shareholderStudyAbroadEducationProvePicLists[i] = []
        }
      }
      for (let i = 0; i < this.state.activeShareholderList.length; i++) {
        let v = this.state.shareholderList[i];
        shareholderList.push({
          id: this.state.activeShareholderList[i].id,
          name: fields["shareholderName" + v],
          // detail: fields["shareholderDetail" + v],
          holderShares: fields["shareholderShares" + v],
          education: fields["shareholderEducation" + v],
          address: {
            province: fields['shareholderAddress' + v][0],
            city: fields['shareholderAddress' + v][1],
            county: fields['shareholderAddress' + v][2],
            detail: fields["shareholderDetail" + v],
          },
          idCardNo: fields["shareholderIdCardNo" + v],
          hasStudyAbroad: fields['shareholderHasStudyAbroad' + v],
          studyAbroadInfo: fields['shareholderHasStudyAbroad' + v] ? {
            schoolName: fields["shareholderStudyAbroadSchoolName" + v],
            education: fields['shareholderEducation' + v],
            educationProvePic: shareholderStudyAbroadEducationProvePicLists[v],
          } : null,
        })
      }
      companyApi
        .edit({
          applyId: this.state.applyId,
          file: uploadList,
          cname: fields.cname,
          totalNumber: parseInt(fields.totalNumber),
          teamMemberNumberHai: parseInt(fields.teamMemberNumberHai),
          industryId: fields.industry,
          mainBusiness: fields.mainBusiness,  //企业简介
          registerTime: moment(fields.registerTime).format("YYYY-MM-DD 00:00:00"),
          paySocialSecurityNum: parseInt(fields.paySocialSecurityNum),
          registerAddress: fields.registerAddress[0],
          registerCapital: parseInt(fields.registerCapital) * 100,
          registerAddressDetail: fields.registerAddressDetail,
          entrepreneurs: fields.entrepreneurs,
          businessLicensePic,
          bankAccountOpeningCertificatePic,
          patentList,
          financingList,
          prizeList,
          overseasMemberList,
          shareholderList,
        })
        .then(json => {
          message.success("修改成功", 1);
        })
        .catch(err => {
          message.error("修改失败,失败原因: " + err.msg, 1);
        });
    });
  };
  //股东海外留学学历证明图片上传
  shareholderStudyAbroadEducationProvePicChange = (files, idx) => {
    let shareholderStudyAbroadEducationProvePicList = this.state
      .shareholderStudyAbroadEducationProvePicList,
      filesList = files.fileList;
    shareholderStudyAbroadEducationProvePicList[idx] = filesList;
    this.setState({ shareholderStudyAbroadEducationProvePicList });
  };
  // 专利证书图片上传
  patentPicList = (files, idx) => {
    let patentPicList = this.state.patentPicList,
      filesList = files.fileList;
    patentPicList[idx] = filesList;
    this.setState({
      patentPicList,
    })
  }
  //上传图片
  handlePreview = file => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true,
    });
  }
  //身份证号码验证
  idCardNoValidation = evt => {
    let val = evt.target.value;
    IdentityCodeValid(val).then(json => {
      this.setState({
        idCardNoValidation: json.pass,
      });
      if (!json.pass) {
        message.error("身份证号码错误: " + json.msg, 1);
      }
    });
  };
  //添加股东
  addShareholder = _ => {
    let fieldNames = [];
    for (let i = 0; i < this.state.shareholderList.length; i++) {
      fieldNames.push(
        "shareholderName" + i,
        "shareholderEducation" + i,
        "shareholderAddress" + i,
        "shareholderDetail" + i,
        "shareholderShares" + i,
        "shareholderHasStudyAbroad" + i,
        "shareholderIdCardNo" + i,
        "shareholderStudyAbroadSchoolName" + i,
        "shareholderStudyAbroadEducation" + i,
      );
    }
    this.props.form.validateFields(fieldNames, (err, fields) => {
      if (err) {
        let firstErrKey = Object.keys(err)[0];
        return message.error(err[firstErrKey].errors[0].message, 2);
      }
      for (let i = 0; i < this.state.shareholderStudyAbroadEducationProvePicList.length; i++) {
        if (this.state.shareholderStudyAbroadEducationProvePicList[i].length === 0) {
          return message.error("请上传股东" + (i + 1) + "的留学证明图片", 2);
        }
      }
      let { shareholderList, activeShareholderList } = this.state;
      if (this.state.shareholderList.length === 0) {
        shareholderList.push(0);
      } else {
        shareholderList.push(shareholderList[shareholderList.length - 1] + 1);
      }
      activeShareholderList.push({
        id: 0
      })
      this.setState({
        shareholderList,
        activeShareholderList
      });
    });
  };
  //专利
  addPatent = _ => {
    let fieldNames = [];
    for (let i = 0; i < this.state.patentList.length; i++) {
      fieldNames.push("patentName" + i, "patentTime" + i, "patentType" + i);
    }
    this.props.form.validateFields(fieldNames, (err, fieldsValue) => {
      if (err) {
        let firstErrKey = Object.keys(err)[0];
        return message.error(err[firstErrKey].errors[0].message, 2);
      }
      let { patentList, activePatent } = this.state;
      if (this.state.patentList.length === 0) {
        patentList.push(0);
        activePatent.push({
          id: 0
        })
      } else {
        patentList.push(patentList[patentList.length - 1] + 1);
        activePatent.push({
          id: 0
        });
      }
      this.setState({
        patentList,
        activePatent
      });
    });
  };
  //融资
  addFinancing = _ => {
    let fieldNames = [];
    for (let i = 0; i < this.state.financingList.length; i++) {
      fieldNames.push("financingPrice" + i, "financingTime" + i, "financingSource" + i, "financingMode" + i);
    }
    this.props.form.validateFields(fieldNames, (err, fieldsValue) => {
      if (err) {
        let firstErrKey = Object.keys(err)[0];
        return message.error(err[firstErrKey].errors[0].message, 2);
      }
      let { financingList, activeFinancingList } = this.state;
      if (this.state.financingList.length === 0) {
        financingList.push(0);
        activeFinancingList.push({
          id: 0
        })
      } else {
        financingList.push(financingList[financingList.length - 1] + 1);
        activeFinancingList.push({
          id: 0
        })
      }
      this.setState({
        financingList,
        activeFinancingList,
      });
    });
  };
  //获奖
  addawardRecord = _ => {
    let fieldNames = [];
    for (let i = 0; i < this.state.awardRecordList.length; i++) {
      fieldNames.push("awardName" + i, "awardObtainTime" + i, "belongLevel" + i, "prizeLevel" + i);
    }
    this.props.form.validateFields(fieldNames, (err, fieldsValue) => {
      if (err) {
        let firstErrKey = Object.keys(err)[0];
        return message.error(err[firstErrKey].errors[0].message, 2);
      }
      let { awardRecordList, activeAwardRecordList } = this.state;
      if (this.state.awardRecordList.length === 0) {
        awardRecordList.push(0);
        activeAwardRecordList.push({
          id: 0
        })
      } else {
        awardRecordList.push(awardRecordList[awardRecordList.length - 1] + 1);
        activeAwardRecordList.push({
          id: 0
        })
      }
      this.setState({
        awardRecordList,
        activeAwardRecordList
      });
    });
  };
  // 海外留学成员
  addOverseasMembers = _ => {
    let fieldNames = [];
    for (let i = 0; i < this.state.overseasMemberList.length; i++) {
      fieldNames.push("overseasMemberName" + i, "overseasMemberTime" + i, "overseasMemberCollege" + i, "overseasMemberSpeciality" + i, "overseasMemberEducation" + i);
    }
    this.props.form.validateFields(fieldNames, (err, fieldsValue) => {
      if (err) {
        let firstErrKey = Object.keys(err)[0];
        return message.error(err[firstErrKey].errors[0].message, 2);
      }
      let { overseasMemberList, activeOverseasMemberList } = this.state;
      if (this.state.overseasMemberList.length === 0) {
        overseasMemberList.push(0);
        activeOverseasMemberList.push({
          id: 0
        })
      } else {
        overseasMemberList.push(overseasMemberList[overseasMemberList.length - 1] + 1);
        activeOverseasMemberList.push({
          id: 0
        })
      }
      this.setState({
        overseasMemberList,
        activeOverseasMemberList
      });
    });
  }
  // 图片的修改
  //海外留学学历证明图片上传
  overseasMemberPicChange = (files, idx) => {
    let overseasMemberPicList = this.state
      .overseasMemberPicList,
      filesList = files.fileList;
    overseasMemberPicList[idx] = filesList;
    this.setState({ overseasMemberPicList });
  };
  // 专利
  patentPicChange = (files, idx) => {
    let patentPicList = this.state
      .patentPicList,
      filesList = files.fileList;
    patentPicList[idx] = filesList;
    this.setState({ patentPicList });
  };
  // 融资
  financingRecordPicChange = (files, idx) => {
    let financingRecordPicList = this.state
      .financingRecordPicList,
      filesList = files.fileList;
    financingRecordPicList[idx] = filesList;
    this.setState({ financingRecordPicList });
  };
  // 获奖
  awardRecordPicChange = (files, idx) => {
    let awardRecordPicList = this.state
      .awardRecordPicList,
      filesList = files.fileList;
    awardRecordPicList[idx] = filesList;
    this.setState({ awardRecordPicList });
  };

  deleteOverseaMember = index => {
    this.props.form.validateFields((err, fields) => {
      delete fields["overseasMemberName" + index]
      delete fields["overseasMemberCollege" + index]
      delete fields["overseasMemberSpeciality" + index]
      delete fields["overseasMemberTime" + index]
      delete fields["overseasMemberEducation" + index]
    })
    let { overseasMemberList, activeOverseasMemberList } = this.state;
    overseasMemberList.splice(index, 1);
    activeOverseasMemberList.splice(index, 1);
    this.setState({
      overseasMemberList,
      activeOverseasMemberList,
    })
  }
  // 删除专利
  deletePatent = index => {
    this.props.form.validateFields((err, fields) => {
      delete fields["patentName" + index]
      delete fields["patentTime" + index]
      delete fields["patentType" + index]
    })
    let { patentList, activePatent } = this.state;
    patentList.splice(index, 1);
    activePatent.splice(index, 1);
    this.setState({
      activePatent,
      patentList,
    })
  }
  // 删除融资
  deleteFinancing = index => {
    this.props.form.validateFields((err, fields) => {
      delete fields["financingPrice" + index]
      delete fields["financingTime" + index]
      delete fields["financingSource" + index]
      delete fields["financingMode" + index]
    })
    let { financingList, activeFinancingList } = this.state;
    financingList.splice(index, 1);
    activeFinancingList.splice(index, 1);
    this.setState({
      financingList,
      activeFinancingList,
    })
  }
  // 删除获奖
  deleteAward = index => {
    this.props.form.validateFields((err, fields) => {
      delete fields["belongLevel" + index]
      delete fields["prizeLevel" + index]
      delete fields["awardName" + index]
      delete fields["awardObtainTime" + index]
    })
    let { awardRecordList, activeAwardRecordList } = this.state;
    awardRecordList.splice(index, 1);
    activeAwardRecordList.splice(index, 1);
    this.setState({
      awardRecordList,
      activeAwardRecordList,
    })
  }
  // 删除股东
  deleteShareholder = index => {
    this.props.form.validateFields((err, fields) => {
      delete fields["financingPrice" + index]
      delete fields["financingTime" + index]
      delete fields["financingSource" + index]
      delete fields["financingMode" + index]
    })
    let { shareholderList, activeShareholderList } = this.state;
    shareholderList.splice(index, 1);
    activeShareholderList.splice(index, 1);
    this.setState({
      shareholderList,
      activeShareholderList,
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { previewVisible, previewImage, businessLicensePic, bankAccountOpeningCertificatePic } = this.state;
    //头像上传按钮
    return (
      <Spin tip="Loading..." spinning={this.state.spinning} size="large">
        <div className={style.info}>
          <Breadcrumb className={style.title}>
            <Breadcrumb.Item><Link to="/admin">首页</Link></Breadcrumb.Item>
            <Breadcrumb.Item><Link to="/admin/apply/allList">申请管理</Link></Breadcrumb.Item>
            <Breadcrumb.Item>公司编辑</Breadcrumb.Item>
          </Breadcrumb>
          <div className={style.basicWrap}>
            {/* <div className={style.title}>基本信息</div> */}
            <Form className={style.form}>
              {/* 基本信息 */}
              <Form.Item {...formItemLayout} label="负责人姓名">
                {getFieldDecorator("name", {
                  rules: [{ required: true, message: "请输入姓名" }],
                })(<Input placeholder="请输入" disabled />)}
              </Form.Item>
              <Form.Item {...formItemLayout} label="联系电话">
                {getFieldDecorator("phone", {
                  rules: [{ required: true, message: "请输入电话" }],
                })(<Input placeholder="请输入" disabled />)}
              </Form.Item>
              <Form.Item {...formItemLayout} label="邮箱">
                {getFieldDecorator("email", {
                  rules: [{ required: true, message: "请输入邮箱" }],
                })(<Input placeholder="请输入" disabled />)}
              </Form.Item>
              <Form.Item {...formItemLayout} label="身份证号码"  >
                {getFieldDecorator('idCardNo', {
                  rules: [{ required: true, message: '请输入身份证号码' }],
                })(
                  <Input placeholder="请输入身份证号码" disabled />)}
              </Form.Item>
              {/* 公司信息 */}

              <Form.Item {...formItemLayout} label="企业名称">
                {getFieldDecorator("cname", {
                  rules: [{ required: true, message: "请输入企业名称" }],
                })(<Input placeholder="请输入公司名称" />)}
              </Form.Item>
              <Form.Item {...formItemLayout} label="公司总人数">
                {getFieldDecorator("totalNumber", {
                  rules: [{ required: true, message: "请输入公司总人数" }],
                })(
                  <InputNumber
                    className={style.input}
                    precision={0}
                    placeholder="请输入公司总人数"
                  />,
                )}
              </Form.Item>
              <Form.Item {...formItemLayout} label="上海户籍人数">
                {getFieldDecorator("teamMemberNumberHai", {
                  rules: [{ required: true, message: "请输入上海户籍人数" }],
                })(
                  <InputNumber
                    className={style.input}
                    precision={0}
                    placeholder="请输入上海户籍人数"
                  />,
                )}
              </Form.Item>
              <Form.Item {...formItemLayout} label="所属行业">
                {getFieldDecorator("industry", {
                  rules: [{ required: true, message: "请输入所属行业" }],
                })(
                  <Select placeholder="请选择所属行业">
                    {this.state.industryList.map((v, k) => {
                      return (
                        <Option key={`${k}`} value={v.value}>
                          {v.label}
                        </Option>
                      );
                    })}
                  </Select>,
                )}
              </Form.Item>
              <Form.Item {...formItemLayout} label="注册时间">
                {getFieldDecorator("registerTime", {
                  rules: [
                    {
                      required: true,
                      message: `请选择注册时间`,
                    },
                  ],
                })(<DatePicker className={style.input} placeholder="请选择" />)}
              </Form.Item>
              {/* 缺注册地址 */}
              <Form.Item {...formItemLayout} label="注册地址">
                {getFieldDecorator("registerAddress", {
                  rules: [
                    {
                      required: true,
                      message: `请选择注册地址`,
                    },
                  ],
                })(<Cascader options={this.state.region} placeholder="请选择" />)}
              </Form.Item>
              <Form.Item {...formItemLayout} label="详细地址">
                {getFieldDecorator("registerAddressDetail", {
                  rules: [{ required: true, message: "请输入详细地址" }],
                })(<Input placeholder="请输入详细地址" />)}
              </Form.Item>
              <Form.Item {...formItemLayout} label="注册资金(元)">
                {getFieldDecorator("registerCapital", {
                  rules: [{ required: true, message: "请输入注册资金(元)" }],
                })(
                  <InputNumber
                    className={style.input}
                    precision={0}
                    placeholder="请输入注册资金(元)"
                  />,
                )}
              </Form.Item>
              <Form.Item {...formItemLayout} label="缴纳社保人数">
                {getFieldDecorator("paySocialSecurityNum", {
                  rules: [{ required: true, message: "请输入缴纳社保人数" }],
                })(
                  <InputNumber
                    className={style.input}
                    precision={0}
                    placeholder="请输入缴纳社保人数"
                  />,
                )}
              </Form.Item>
              <Form.Item {...formItemLayout} label="是否为连续创业者"  >
                {getFieldDecorator('entrepreneurs', {
                  rules: [{ required: true, message: '请选择是否为连续创业者' }],
                })(
                  <RadioGroup onChange={evt => {
                    this.setState({
                      isGraduate: evt.target.value,
                    })
                  }}>
                    <Radio value={IS_GRADUATE.UNDEFINED}> 否</Radio>
                    <Radio value={IS_GRADUATE.TRUE}>是</Radio>
                  </RadioGroup>
                )}
              </Form.Item>

              <Row>
                <Col span={10} offset={5}>
                  <div className={style.panel}>
                    <Collapse>
                      <Panel
                        header={
                          <div>
                            股东列表
                          {this.state.shareholderList.length > 0 ? (
                              <Badge
                                style={{ marginLeft: "10px" }}
                                count={this.state.shareholderList.length}
                              />
                            ) : null}
                          </div>
                        }
                        key="1">
                        <div
                          className={
                            this.state.shareholderList.length > 0
                              ? style.shareholderList
                              : style.hidden
                          }>
                          {this.state.shareholderList.map((v, k) => {
                            return (
                              <div key={k} className={style.shareholderItem}>
                                <div className={style.shareholderTitle}>
                                  <div className={style.theme}>股东 {v + 1}</div>
                                  <div
                                    className={style.icon}
                                    // onClick={_ => {
                                    //   let shareholderList = this.state.shareholderList;
                                    //   shareholderList.splice(k, 1);
                                    //   this.setState({
                                    //     shareholderList,
                                    //   });
                                    // }}
                                    onClick={_ => { this.deleteShareholder(k) }}
                                  >
                                    <Icon type="close-circle" />
                                  </div>
                                </div>
                                <div className={style.item}>
                                  <div className={style.label}>姓名</div>
                                  <div className={style.input}>
                                    {getFieldDecorator("shareholderName" + v, {
                                      rules: [
                                        {
                                          required: true,
                                          message: `请输入股东${k + 1}姓名`,
                                        },
                                      ],
                                    })(<Input className={style.input} placeholder="请输入" />)}
                                  </div>
                                </div>
                                <div className={style.item}>
                                  <div className={style.label}>学历</div>
                                  <div className={style.input}>
                                    {getFieldDecorator("shareholderEducation" + v, {
                                      rules: [
                                        {
                                          required: true,
                                          message: `请选择股东${k + 1}学历`,
                                        },
                                      ],
                                    })(
                                      <Select placeholder="请选择学历">
                                        {this.state.educationList.map((v, k) => {
                                          return (
                                            <Option key={`${k}`} value={v.value}>
                                              {v.label}
                                            </Option>
                                          );
                                        })}
                                      </Select>,
                                    )}
                                  </div>
                                </div>
                                <div className={style.item}>
                                  <div className={style.label}>户籍地址</div>
                                  <div className={style.input}>
                                    {getFieldDecorator("shareholderAddress" + v, {
                                      rules: [
                                        {
                                          required: true,
                                          message: `请选择股东${k + 1}户籍地址`,
                                        },
                                      ],
                                    })(<Cascader options={this.state.region} placeholder="请选择" />)}
                                  </div>
                                </div>
                                <div className={style.item}>
                                  <div className={style.label}>详细地址</div>
                                  <div className={style.input}>
                                    {getFieldDecorator("shareholderDetail" + v, {})(
                                      <Input className={style.input} placeholder="请输入" />,
                                    )}
                                  </div>
                                </div>
                                <div className={style.item}>
                                  <div className={style.label}>持有股份</div>
                                  <div className={style.input}>
                                    {getFieldDecorator("shareholderShares" + v, {})(
                                      <Input className={style.input} placeholder="请输入" />,
                                    )}
                                  </div>
                                </div>
                                <div className={style.item}>
                                  <div className={style.label}>身份证号码</div>
                                  <div className={style.input}>
                                    {getFieldDecorator("shareholderIdCardNo" + v, {
                                      rules: [
                                        {
                                          required: true,
                                          message: `请输入股东${k + 1}身份证号码`,
                                        },
                                      ],
                                    })(
                                      <Input
                                        className={style.input}
                                        placeholder="请输入"
                                        onBlur={this.idCardNoValidation}
                                      />,
                                    )}
                                  </div>
                                </div>
                                <div className={style.item}>
                                  <div className={style.label}>是否海外留学(含港外)</div>
                                  <div className={style.input}>
                                    {getFieldDecorator("shareholderHasStudyAbroad" + v, {
                                      // rules: [
                                      //   {
                                      //     required: true,
                                      //     message: `请选择股东${k + 1}是否海外留学`,
                                      //   },
                                      // ],
                                    })(
                                      <RadioGroup
                                        onChange={evt => {
                                          let shareholderStudyAbroadEducationProvePicList = this.state
                                            .shareholderStudyAbroadEducationProvePicList,
                                            shareholderStudyAbroadList = this.state
                                              .shareholderStudyAbroadList;
                                          if (evt.target.value) {
                                            shareholderStudyAbroadEducationProvePicList[v] = [];
                                            shareholderStudyAbroadList[v] = [0];
                                          } else {
                                            shareholderStudyAbroadList[v] = undefined;
                                          }
                                          this.setState({
                                            shareholderStudyAbroadEducationProvePicList,
                                            shareholderStudyAbroadList,
                                          });
                                        }}>
                                        <Radio value={undefined}>否</Radio>
                                        <Radio value={true}>是</Radio>
                                      </RadioGroup>,
                                    )}
                                  </div>
                                </div>
                                {this.state.shareholderStudyAbroadList[v] ? (
                                  <div className={style.studyAbroadInfo}>
                                    <div className={style.item}>
                                      <div className={style.label}>学校</div>
                                      <div className={style.input}>
                                        {getFieldDecorator("shareholderStudyAbroadSchoolName" + v, {
                                          rules: [
                                            {
                                              required: true,
                                              message: `请输入股东${k + 1}的学校姓名`,
                                            },
                                          ],
                                        })(<Input className={style.input} placeholder="请输入" />)}
                                      </div>
                                    </div>
                                    <div className={style.item}>
                                      <div className={style.label}>学历</div>
                                      <div className={style.input}>
                                        {getFieldDecorator("shareholderStudyAbroadEducation" + v, {
                                          rules: [
                                            {
                                              required: true,
                                              message: `请选择股东${k + 1}海外留学的学历`,
                                            },
                                          ],
                                        })(
                                          <Select placeholder="请选择学历">
                                            {this.state.educationList.map((v, k) => {
                                              return (
                                                <Option key={`${k}`} value={v.value}>
                                                  {v.label}
                                                </Option>
                                              );
                                            })}
                                          </Select>,
                                        )}
                                      </div>
                                    </div>
                                    <div className={style.item}>
                                      <div className={style.label}>学历证明图片</div>
                                      <div className={style.input}>
                                        {getFieldDecorator(
                                          "shareholderStudyAbroadEducationProvePic" + v,
                                          {},
                                        )(
                                          <div className="clearfix">
                                            <Upload
                                              action={getUploadImgUrl()}
                                              listType="picture-card"
                                              fileList={
                                                this.state
                                                  .shareholderStudyAbroadEducationProvePicList[v]
                                              }
                                              onPreview={this.handlePreview}
                                              onChange={files =>
                                                this.shareholderStudyAbroadEducationProvePicChange(
                                                  files,
                                                  v,
                                                )
                                              }>
                                              {this.state.shareholderStudyAbroadEducationProvePicList[
                                                v
                                              ].length > 0
                                                ? null
                                                : uploadButton}
                                            </Upload>
                                            <Modal
                                              visible={previewVisible}
                                              footer={null}
                                              onCancel={this.handleCancel}>
                                              <img
                                                alt="example"
                                                style={{ width: "100%" }}
                                                src={previewImage}
                                              />
                                            </Modal>
                                          </div>,
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            );
                          })}
                        </div>
                        <Empty
                          style={{ marginBottom: 15 }}
                          className={this.state.shareholderList.length > 0 ? style.hidden : ""}
                        />
                        <div className={style.shareholderAdd}>
                          <Icon type="plus" onClick={this.addShareholder} />
                        </div>
                      </Panel>

                      <Panel
                        header={
                          <div>
                            专利列表
                          {this.state.patentList.length > 0 ? (
                              <Badge
                                style={{ marginLeft: "10px" }}
                                count={this.state.patentList.length}
                              />
                            ) : null}
                          </div>
                        }
                        key="2">
                        <div
                          className={
                            this.state.patentList.length > 0 ? style.shareholderList : style.hidden
                          }>
                          {this.state.patentList.map((v, k) => {
                            return (
                              <div key={k} className={style.shareholderItem}>
                                <div className={style.shareholderTitle}>
                                  <div className={style.theme}>专利人 {v + 1}</div>
                                  <div
                                    className={style.icon}
                                    onClick={_ => { this.deletePatent(k) }}>
                                    <Icon type="close-circle" />
                                  </div>
                                </div>
                                <div className={style.item}>
                                  <div className={style.label}>专利名称</div>
                                  <div className={style.input}>
                                    {getFieldDecorator("patentName" + v, {
                                      rules: [
                                        {
                                          required: true,
                                          message: `请输入专利${k + 1}姓名`,
                                        },
                                      ],
                                    })(<Input className={style.input} placeholder="请输入" />)}
                                  </div>
                                </div>
                                <div className={style.item}>
                                  <div className={style.label}>获得时间</div>
                                  <div className={style.input}>
                                    {getFieldDecorator("patentTime" + v, {
                                      rules: [
                                        {
                                          required: true,
                                          message: `请选择专利${k + 1}获得时间`,
                                        },
                                      ],
                                    })(<DatePicker className={style.input} placeholder="请选择" />)}
                                  </div>
                                </div>
                                <div className={style.item}>
                                  <div className={style.label}>专利类型</div>
                                  <div className={style.input}>
                                    {getFieldDecorator("patentType" + v, {
                                      rules: [
                                        {
                                          required: true,
                                          message: `请选择专利${k + 1}专利类型`,
                                        },
                                      ],
                                    })(
                                      <Select placeholder="请选择">
                                        {this.state.patentTypeList.map((v, k) => {
                                          return (
                                            <Option key={`${k}`} value={v.value}>
                                              {v.label}
                                            </Option>
                                          );
                                        })}
                                      </Select>,
                                    )}
                                  </div>
                                </div>
                                <div className={style.item}>
                                  <div className={style.label}>专利证书</div>
                                  <div className={style.input}>
                                    {getFieldDecorator(
                                      "patentPic" + v,
                                      {},
                                    )(
                                      <div className="clearfix">
                                        <Upload
                                          action={getUploadImgUrl()}
                                          listType="picture-card"
                                          fileList={
                                            this.state
                                              .patentPicList[v]
                                          }
                                          onPreview={this.handlePreview}
                                          onChange={files =>
                                            this.patentPicChange(
                                              files,
                                              v,
                                            )
                                          }>
                                          {this.state.patentPicList[v] && this.state.patentPicList[v].length > 0 ? null : uploadButton}
                                        </Upload>
                                        <Modal
                                          visible={previewVisible}
                                          footer={null}
                                          onCancel={this.handleCancel}>
                                          <img
                                            alt="example"
                                            style={{ width: "100%" }}
                                            src={previewImage}
                                          />
                                        </Modal>
                                      </div>,
                                    )}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        <Empty
                          style={{ marginBottom: 15 }}
                          className={this.state.patentList.length > 0 ? style.hidden : ""}
                        />
                        <div className={style.shareholderAdd}>
                          <Icon type="plus" onClick={this.addPatent} />
                        </div>
                      </Panel>
                      <Panel
                        header={
                          <div>
                            融资列表
                          {this.state.financingList.length > 0 ? (
                              <Badge
                                style={{ marginLeft: "10px" }}
                                count={this.state.financingList.length}
                              />
                            ) : null}
                          </div>
                        }
                        key="3">
                        <div
                          className={
                            this.state.financingList.length > 0 ? style.shareholderList : style.hidden
                          }>
                          {this.state.financingList.map((v, k) => {
                            return (
                              <div key={k} className={style.shareholderItem}>
                                <div className={style.shareholderTitle}>
                                  <div className={style.theme}>融资 {v + 1}</div>
                                  <div
                                    className={style.icon}
                                    onClick={_ => { this.deleteFinancing(k) }}>
                                    <Icon type="close-circle" />
                                  </div>
                                </div>
                                <div className={style.item}>
                                  <div className={style.label}>融资金额(元)</div>
                                  <div className={style.input}>
                                    {getFieldDecorator("financingPrice" + v, {
                                      rules: [
                                        {
                                          required: true,
                                          message: `请输入融资${k + 1}金额`,
                                        },
                                      ],
                                    })(
                                      <InputNumber
                                        precision={2}
                                        className={style.input}
                                        placeholder="请输入"
                                      />,
                                    )}
                                  </div>
                                </div>
                                <div className={style.item}>
                                  <div className={style.label}>融资时间</div>
                                  <div className={style.input}>
                                    {getFieldDecorator("financingTime" + v, {
                                      rules: [
                                        {
                                          required: true,
                                          message: `请选择融资${k + 1}融资时间`,
                                        },
                                      ],
                                    })(<DatePicker className={style.input} placeholder="请选择" />)}
                                  </div>
                                </div>
                                <div className={style.item}>
                                  <div className={style.label}>融资来源</div>
                                  <div className={style.input}>
                                    {getFieldDecorator("financingSource" + v, {
                                      rules: [
                                        {
                                          required: true,
                                          message: `请选择融资${k + 1}来源`,
                                        },
                                      ],
                                    })(<Input className={style.input} placeholder="请输入" />)}
                                  </div>
                                </div>
                                <div className={style.item}>
                                  <div className={style.label}>融资形式</div>
                                  <div className={style.input}>
                                    {getFieldDecorator("financingMode" + v, {
                                      rules: [
                                        {
                                          required: true,
                                          message: `请选择融资${k + 1}形式`,
                                        },
                                      ],
                                    })(
                                      <Select placeholder="请选择">
                                        {this.state.financingTypeList.map((v, k) => {
                                          return (
                                            <Option key={`${k}`} value={v.value}>
                                              {v.label}
                                            </Option>
                                          );
                                        })}
                                      </Select>,
                                    )}
                                  </div>
                                  {/* <div className={style.input}>
                                    {getFieldDecorator("financingMode" + v, {
                                      rules: [
                                        {
                                          required: true,
                                          message: `请选择融资${k + 1}形式`,
                                        },
                                      ],
                                    })(<Input className={style.input} placeholder="请输入" />)}
                                  </div> */}
                                </div>
                                <div className={style.item}>
                                  <div className={style.label}>融资证明</div>
                                  <div className={style.input}>
                                    {getFieldDecorator(
                                      "financingRecordPic" + v,
                                      {},
                                    )(
                                      <div className="clearfix">
                                        <Upload
                                          action={getUploadImgUrl()}
                                          listType="picture-card"
                                          fileList={
                                            this.state
                                              .financingRecordPicList[v]
                                          }
                                          onPreview={this.handlePreview}
                                          onChange={files =>
                                            this.financingRecordPicChange(files, v)}>
                                          {this.state.financingRecordPicList[v] && this.state.financingRecordPicList[v].length > 0 ? null : uploadButton}
                                        </Upload>
                                        <Modal
                                          visible={previewVisible}
                                          footer={null}
                                          onCancel={this.handleCancel}>
                                          <img
                                            alt="example"
                                            style={{ width: "100%" }}
                                            src={previewImage}
                                          />
                                        </Modal>
                                      </div>,
                                    )}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        <Empty
                          style={{ marginBottom: 15 }}
                          className={this.state.financingList.length > 0 ? style.hidden : ""}
                        />
                        <div className={style.shareholderAdd}>
                          <Icon type="plus" onClick={this.addFinancing} />
                        </div>
                      </Panel>
                      <Panel
                        header={
                          <div>

                            获奖列表
                          {this.state.awardRecordList.length > 0 ? (
                              <Badge
                                style={{ marginLeft: "10px" }}
                                count={this.state.awardRecordList.length}
                              />
                            ) : null}
                          </div>
                        }
                        key="4">
                        <div
                          className={
                            this.state.awardRecordList.length > 0
                              ? style.shareholderList
                              : style.hidden
                          }>
                          {this.state.awardRecordList.map((v, k) => {
                            return (
                              <div key={k} className={style.shareholderItem}>
                                <div className={style.shareholderTitle}>
                                  <div className={style.theme}>获奖 {v + 1}</div>
                                  <div
                                    className={style.icon}
                                    onClick={_ => { this.deleteAward(k) }}>
                                    <Icon type="close-circle" />
                                  </div>
                                </div>
                                <div className={style.item}>
                                  <div className={style.label}>获奖名称</div>
                                  <div className={style.input}>
                                    {getFieldDecorator("awardName" + v, {
                                      rules: [
                                        {
                                          required: true,
                                          message: `请输入获奖${k + 1}获奖名称`,
                                        },
                                      ],
                                    })(<Input className={style.input} placeholder="请输入" />)}
                                  </div>
                                </div>
                                <div className={style.item}>
                                  <div className={style.label}>获奖时间</div>
                                  <div className={style.input}>
                                    {getFieldDecorator("awardObtainTime" + v, {
                                      rules: [
                                        {
                                          required: true,
                                          message: `请选择获奖${k + 1}获奖名称`,
                                        },
                                      ],
                                    })(<DatePicker className={style.input} placeholder="请选择" />)}
                                  </div>
                                </div>
                                <div className={style.item}>
                                  <div className={style.label}>奖项等级</div>
                                  <div className={style.input}>
                                    {getFieldDecorator("prizeLevel" + v, {
                                      rules: [
                                        {
                                          required: true,
                                          message: `请输入获奖${k + 1}奖项等级`,
                                        },
                                      ],
                                    })(<Input className={style.input} placeholder="请输入" />)}
                                  </div>
                                </div>
                                <div className={style.item}>
                                  <div className={style.label}>所属等级</div>
                                  <div className={style.input}>
                                    {getFieldDecorator("belongLevel" + v, {
                                      rules: [
                                        {
                                          required: true,
                                          message: `请选择获奖${k + 1}所属等级`,
                                        },
                                      ],
                                    })(
                                      <Select placeholder="请选择">
                                        {this.state.subordinateTypeList.map((v, k) => {
                                          return (
                                            <Option key={`${k}`} value={v.value}>
                                              {v.label}
                                            </Option>
                                          );
                                        })}
                                      </Select>,
                                    )}
                                  </div>
                                  {/* <div className={style.input}>
                                    {getFieldDecorator("belongLevel" + v, {
                                      rules: [
                                        {
                                          required: true,
                                          message: `请输入获奖${k + 1}所属等级`,
                                        },
                                      ],
                                    })(<Input className={style.input} placeholder="请输入" />)}
                                  </div> */}
                                </div>
                                <div className={style.item}>
                                  <div className={style.label}>获奖证书</div>
                                  <div className={style.input}>
                                    {getFieldDecorator(
                                      "awardRecordPic" + v,
                                      {},
                                    )(
                                      <div className="clearfix">
                                        <Upload
                                          action={getUploadImgUrl()}
                                          listType="picture-card"
                                          fileList={
                                            this.state
                                              .patentPicList[v]
                                          }
                                          onPreview={this.handlePreview}
                                          onChange={files =>
                                            this.awardRecordPicChange(
                                              files,
                                              v,
                                            )
                                          }>
                                          {this.state.awardRecordPicList[v] && this.state.awardRecordPicList[v].length > 0 ? null : uploadButton}
                                        </Upload>
                                        <Modal
                                          visible={previewVisible}
                                          footer={null}
                                          onCancel={this.handleCancel}>
                                          <img
                                            alt="example"
                                            style={{ width: "100%" }}
                                            src={previewImage}
                                          />
                                        </Modal>
                                      </div>,
                                    )}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        <Empty
                          style={{ marginBottom: 15 }}
                          className={this.state.awardRecordList.length > 0 ? style.hidden : ""}
                        />
                        <div className={style.shareholderAdd}>
                          <Icon type="plus" onClick={this.addawardRecord} />
                        </div>
                      </Panel>
                      <Panel
                        header={
                          <div>
                            海外留学成员
                          {this.state.overseasMemberList.length > 0 ? (
                              <Badge
                                style={{ marginLeft: "10px" }}
                                count={this.state.overseasMemberList.length}
                              />
                            ) : null}
                          </div>
                        }
                        key="5">
                        <div
                          className={
                            this.state.overseasMemberList.length > 0 ? style.shareholderList : style.hidden
                          }>
                          {this.state.overseasMemberList.map((v, k) => {
                            return (
                              <div key={k} className={style.shareholderItem}>
                                <div className={style.shareholderTitle}>
                                  <div className={style.theme}>海外成员 {v + 1}</div>
                                  <div
                                    className={style.icon}
                                    onClick={_ => { this.deleteOverseaMember(k) }}>
                                    <Icon type="close-circle" />
                                  </div>
                                </div>
                                <div className={style.item}>
                                  <div className={style.label}>姓名</div>
                                  <div className={style.input}>
                                    {getFieldDecorator("overseasMemberName" + v, {
                                      rules: [{ required: true, message: `请输入海外成员${k + 1}姓名` }],
                                    })(<Input className={style.input} placeholder="请输入" />)}
                                  </div>
                                </div>
                                <div className={style.item}>
                                  <div className={style.label}>海外学校</div>
                                  <div className={style.input}>
                                    {getFieldDecorator("overseasMemberCollege" + v, {
                                      rules: [{ required: true, message: `请输入海外成员${k + 1}学校` }],
                                    })(<Input className={style.input} placeholder="请输入" />)}
                                  </div>
                                </div>
                                <div className={style.item}>
                                  <div className={style.label}>海外专业</div>
                                  <div className={style.input}>
                                    {getFieldDecorator("overseasMemberSpeciality" + v, {
                                      rules: [{ required: true, message: `请输入海外成员${k + 1}专业` }],
                                    })(<Input className={style.input} placeholder="请输入" />)}
                                  </div>
                                </div>
                                <div className={style.item}>
                                  <div className={style.label}>毕业时间</div>
                                  <div className={style.input}>
                                    {getFieldDecorator("overseasMemberTime" + v, {
                                      rules: [
                                        { required: true, message: `请选择海外成员${k + 1}毕业时间` },
                                      ],
                                    })(<DatePicker className={style.input} placeholder="请选择" />)}
                                  </div>
                                </div>
                                <div className={style.item}>
                                  <div className={style.label}>学历</div>
                                  <div className={style.input}>
                                    {getFieldDecorator("overseasMemberEducation" + v, {
                                      rules: [
                                        { required: true, message: `请选择海外成员${k + 1}学历` },
                                      ],
                                    })(
                                      <Select placeholder="请选择">
                                        {this.state.educationList.map((v, k) => {
                                          return (
                                            <Option key={`${k}`} value={v.value}>
                                              {v.label}
                                            </Option>
                                          );
                                        })}
                                      </Select>,
                                    )}
                                  </div>
                                </div>
                                <div className={style.item}>
                                  <div className={style.label}>学历证明图片</div>
                                  <div className={style.input}>
                                    {getFieldDecorator(
                                      "overseasMemberPic" + v,
                                      {},
                                    )(
                                      <div className="clearfix">
                                        <Upload
                                          action={getUploadImgUrl()}
                                          listType="picture-card"
                                          fileList={
                                            this.state
                                              .overseasMemberPicList[v]
                                          }
                                          onPreview={this.handlePreview}
                                          onChange={files =>
                                            this.overseasMemberPicChange(
                                              files,
                                              v,
                                            )
                                          }>
                                          {this.state.overseasMemberPicList[v] && this.state.overseasMemberPicList[v].length > 0 ? null : uploadButton}
                                        </Upload>
                                        <Modal
                                          visible={previewVisible}
                                          footer={null}
                                          onCancel={this.handleCancel}>
                                          <img
                                            alt="example"
                                            style={{ width: "100%" }}
                                            src={previewImage}
                                          />
                                        </Modal>
                                      </div>,
                                    )}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        <Empty
                          style={{ marginBottom: 15 }}
                          className={this.state.overseasMemberList.length > 0 ? style.hidden : ""}
                        />
                        <div className={style.shareholderAdd}>
                          <Icon type="plus" onClick={this.addOverseasMembers} />
                        </div>
                      </Panel>
                    </Collapse>
                  </div>
                </Col>
              </Row>
              <Form.Item {...formItemLayout} label="上传附件">
                <div className={style.tips}>提示:如果有法人简历、社保缴纳证明等,请上传一张对应的附件</div>
                <Upload {...upload} defaultFileList={this.state.uploadStateList}>
                  <Button className={style.uploadBtn} >
                    上传
                  </Button>
                </Upload>
              </Form.Item>
              <Form.Item {...formItemLayout} label="营业执照证件照">
                {getFieldDecorator("businessLicensePic", {})(
                  <div className="clearfix">
                    <Upload
                      action={getUploadImgUrl()}
                      listType="picture-card"
                      fileList={businessLicensePic}
                      onPreview={this.handlePreview}
                      onChange={this.businessLicensePicChange}>
                      {businessLicensePic.length > 0 ? null : uploadButton}
                    </Upload>
                    <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                      <img alt="example" style={{ width: "100%" }} src={previewImage} />
                    </Modal>
                  </div>,
                )}
              </Form.Item>
              <Form.Item {...formItemLayout} label="银行开户正面图片">
                {getFieldDecorator("bankAccountOpeningCertificatePic", {})(
                  <div className="clearfix">
                    <Upload
                      action={getUploadImgUrl()}
                      listType="picture-card"
                      fileList={bankAccountOpeningCertificatePic}
                      onPreview={this.handlePreview}
                      onChange={this.bankAccountOpeningCertificatePicChange}>
                      {bankAccountOpeningCertificatePic.length > 0 ? null : uploadButton}
                    </Upload>
                    <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                      <img alt="example" style={{ width: "100%" }} src={previewImage} />
                    </Modal>
                  </div>,
                )}
              </Form.Item>
              <Form.Item {...formItemLayout} label="主营业务">
                {getFieldDecorator("mainBusiness", {})(
                  <TextArea rows={4} placeholder="请输入主营业务" />,
                )}
              </Form.Item>
              <Form.Item className={style.control}>
                <Button className={style.btn} type="primary" onClick={this.updateUserInfo}>更新基本信息</Button>
                {/* <NavLink to={`/admin/company/paymentRecordList?id=${this.state.companyId}`}>
                  <Button className={style.btn} type="primary">
                    查看缴费记录
                </Button>
                </NavLink>
                <NavLink to={`/admin/company/addPaymentRecord?id=${this.state.companyId}`}>
                  <Button className={style.btn} type="primary">
                    添加缴费记录
                </Button>
                </NavLink> */}
              </Form.Item>
            </Form>
          </div>
        </div>
      </Spin>
    );
  }
}
export default Form.create()(Edit);
