import React, { Component } from "react"
import {
  Table,
  Button,
  Breadcrumb,
  Input,
  Icon,
  Menu,
  Dropdown,
  Popconfirm,
  Tooltip,
  Modal,
  message,
  Form,
  InputNumber,
  Select,
  DatePicker,
} from "antd"
import { Link } from "react-router-dom"
import csn from "classnames"
import qs from "qs"
import moment from "moment"
import { TYPE, TYPE_ZH } from "../../../utils/constant"
import companyApi, {
  TYPE as PAYMENT_TYPE,
  TYPE_ZH as PAYMENT_TYPE_ZH,
  STATUS as PAYMENT_STATUS,
  STATUS_ZH as PAYMENT_STATUS_ZH,
} from "../../../services/company"
import gSass from "../../../utils/sass"

const style = gSass.admin.company.paymentRecordList

const Search = Input.Search
const Option = Select.Option
const RangePicker = DatePicker.RangePicker
class List extends Component {
  columns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "公司名",
      dataIndex: "company",
      render: (company, record, index) => {
        return (
          <Tooltip
            placement="right"
            title={_ => {
              return (
                <div>
                  <div>公司id : {company.id}</div>
                </div>
              )
            }}>
            <span style={{ color: "#1890ff" }}>{company.name}</span>
          </Tooltip>
        )
      },
    },
    {
      title: "类型",
      dataIndex: "type",
      render: type => {
        return PAYMENT_TYPE_ZH[type]
      },
    },
    {
      title: "状态",
      dataIndex: "status",
      render: status => {
        return PAYMENT_STATUS_ZH[status]
      },
    },
    {
      title: "金额( 元 )",
      dataIndex: "money",
      render: val => {
        return <div>{(val / 100).toFixed(2)}</div>
      },
    },
    {
      title: "费用所属年月",
      dataIndex: "date",
      render: date => {
        return date.substr(0, 10)
      },
    },
    {
      title: "操作",
      dataIndex: "operation",
      width: 300,
      render: (text, record) => (
        <div>
          <span
            style={{ color: "#1890ff", cursor: "pointer" }}
            onClick={_ => {
              this.props.form.setFieldsValue({
                paymentStatus: record.status,
                paymentType: record.type,
                paymentMoney: record.money / 100,
              })
              this.setState({
                editPaymentRecordModalActive: true,
                currentRecord: record,
              })
            }}>
            编辑
          </span>
          <div className="separate-line" />
          <span className={style.btn}>
            <Popconfirm
              title={<div>你确定要删除记录{record.id}吗?</div>}
              onConfirm={_ => this.deletePaymentRecord([record.id])}>
              <span>删除</span>
            </Popconfirm>
          </span>
        </div>
      ),
    },
  ]

  constructor(props) {
    super(props)
    const search = qs.parse(props.location.search, { ignoreQueryPrefix: true })
    let companyId = null
    if (search.id > 0) {
      companyId = search.id
    }
    this.state = {
      hasFilter: false,
      tableIsLoading: true,
      editPaymentRecordModalActive: false,
      dateSelect: TYPE.undefined,
      moneySelect: TYPE.undefined,
      currentRecord: {
        company: {
          id: TYPE.undefined,
          name: "",
        },
        type: TYPE.undefined,
        status: TYPE.undefined,
        money: TYPE.undefined,
      },
      page: 1,
      limit: 8,
      filter: {
        search: "",
        companyId,
      },
      typeChildren: [
        {
          value: PAYMENT_TYPE.airConditioning,
          label: PAYMENT_TYPE_ZH[PAYMENT_TYPE.airConditioning],
        },
        {
          value: PAYMENT_TYPE.property,
          label: PAYMENT_TYPE_ZH[PAYMENT_TYPE.property],
        },
        {
          value: PAYMENT_TYPE.hatchService,
          label: PAYMENT_TYPE_ZH[PAYMENT_TYPE.hatchService],
        },
      ],
      paymentStatusList: [
        {
          value: PAYMENT_STATUS.notPay,
          label: PAYMENT_STATUS_ZH[PAYMENT_STATUS.notPay],
        },
        {
          value: PAYMENT_STATUS.hasPay,
          label: PAYMENT_STATUS_ZH[PAYMENT_STATUS.hasPay],
        },
        {
          value: PAYMENT_STATUS.delayPay,
          label: PAYMENT_STATUS_ZH[PAYMENT_STATUS.delayPay],
        },
      ],
      list: [],
      selectedRows: [],
      companyList: [],
      pagination: {
        current: 1,
        pageSize: 8,
        showTotal: total => {
          return `共${total}条记录`
        },
        onChange: async (page, pageSize) => {
          const pagination = this.state.pagination
          pagination.current = page
          pagination.pageSize = pageSize
          try {
            // 注释掉的东西不能删
            let page = pagination.current,
              limit = pagination.pageSize,
              filter = this.state.filter
            this.setState({
              tableIsLoading: true,
            })
            let json = await companyApi.getPaymentRecordList({ page, limit, filter })
            pagination.total = json.count
            this.setState({
              tableIsLoading: false,
              list: json.data.list,
              pagination,
            })
          } catch (err) {
            console.log(err)
            this.setState({
              tableIsLoading: false,
            })
          }
        },
      },
    }
  }

  componentDidMount() {
    this.init()
  }

  init = async _ => {
    try {
      this.getPaymentRecordList()
      let {
        data: { list: oriCompanyList },
      } = await companyApi.getList({ page: -1, limit: -1 })
      let companyList = []
      for (let v of oriCompanyList) {
        companyList.push({
          id: v.id,
          title: v.companyName,
        })
      }
      this.setState({
        companyList,
      })
    } catch (e) {}
  }
  getPaymentRecordList = async _ => {
    try {
      const {
        data: { list },
      } = await companyApi.getPaymentRecordList({
        page: this.state.pagination.current,
        limit: this.state.pagination.pageSize,
        filter: this.state.filter,
      })
      this.setState({
        tableIsLoading: false,
        list,
      })
    } catch (e) {
      this.setState({
        tableIsLoading: false,
      })
    }
  }
  //table 筛选(排序,页码)
  handleChange = (pagination, filters, sorter) => {}
  //删除缴费记录
  deletePaymentRecord = async idArr => {
    try {
      await companyApi.deletePaymentRecord(idArr)
      let list = this.state.list
      list = list.filter(v => !idArr.includes(v.id))
      this.setState({
        list,
      })
      message.success("删除成功", 1)
    } catch (err) {
      message.error("删除失败,错误原因: " + err.msg, 3)
    }
  }
  //筛选
  onFilter = evt => {
    evt.preventDefault()
    this.setState({
      tableIsLoading: true,
    })
    this.props.form.validateFields(
      [
        "companyNameCondition",
        "companyNameVal",
        "paymentStatusCondition",
        "paymentStatusVal",
        "paymentMoneyCondition",
        "paymentMoneyVal",
        "paymentMinMoneyVal",
        "paymentMaxMoneyVal",
        "dateCondition",
        "dateBetweenVal",
        "dateBeforeVal",
        "dateAfterVal",
        "paymentMoneyCondition",
        "typeCondition",
      ],
      (err, fields) => {
        let companyName = {
            condition: fields.companyNameCondition || TYPE.undefined,
            value: fields.companyNameVal || "",
          },
          type = {
            condition: fields.typeCondition || TYPE.undefined,
            value: fields.typeVal || "",
          },
          paymentStatus = {
            condition: fields.paymentStatusCondition || TYPE.undefined,
            value: fields.paymentStatusVal || "",
          },
          paymentMoney = {
            condition: fields.paymentMoneyCondition || TYPE.undefined,
            value:
              this.state.moneySelect !== TYPE.betweenValue
                ? fields.paymentMoneyVal || ""
                : {
                    min: fields.paymentMinMoneyVal * 100 || "",
                    max: fields.paymentMaxMoneyVal * 100 || "",
                  },
          },
          date = {
            condition: fields.dateCondition || TYPE.undefined,
            value:
              this.state.dateSelect === TYPE.before
                ? fields.dateBeforeVal
                  ? moment(fields.dateBeforeVal).format("YYYY-MM-DD HH:mm:ss")
                  : ""
                : this.state.dateSelect === TYPE.betweenTime
                ? fields.dateBetweenVal.length > 0
                  ? {
                      min: fields.dateBetweenVal
                        ? fields.dateBetweenVal[0]
                          ? moment(fields.dateBetweenVal[0]).format("YYYY-MM-DD HH:mm:ss")
                          : ""
                        : "",
                      max: fields.dateBetweenVal
                        ? fields.dateBetweenVal[1]
                          ? moment(fields.dateBetweenVal[1]).format("YYYY-MM-DD HH:mm:ss")
                          : ""
                        : "",
                    }
                  : ""
                : fields.dateAfterVal
                ? moment(fields.dateAfterVal).format("YYYY-MM-DD HH:mm:ss")
                : "",
          }
        console.log({ companyName, type, paymentStatus, paymentMoney, date })
        //关闭模态框
        this.setState({
          hasFilter: false,
          tableIsLoading: false,
        })
      },
    )
  }
  //编辑
  editPaymentRecord = evt => {
    evt.preventDefault()
    this.props.form.validateFields(
      ["paymentType", "paymentStatus", "paymentMoney"],
      async (err, fields) => {
        if (err !== null) {
          return
        }
        console.log({
          recordId: this.state.currentRecord.id,
          paymentType: fields.paymentType,
          paymentStatus: fields.paymentStatus,
          paymentMoney: parseInt(fields.paymentMoney) * 100,
        })
        try {
          await companyApi.editPaymentRecord({
            id: this.state.currentRecord.id,
            paymentType: fields.paymentType,
            paymentStatus: fields.paymentStatus,
            paymentMoney: parseInt(fields.paymentMoney) * 100,
          })
          let list = this.state.list
          let recordIdx = list.findIndex(value => {
            return value.id === this.state.currentRecord.id
          })
          console.log(recordIdx)
          list[recordIdx].type = fields.paymentType
          list[recordIdx].status = fields.paymentStatus
          list[recordIdx].money = parseInt(fields.paymentMoney) * 100
          this.setState({
            editPaymentRecordModalActive: false,
            list,
          })
          message.success("更新成功", 1)
        } catch (e) {
          console.log(e)
          message.error("更新失败")
        }
      },
    )
  }

  render() {
    const { getFieldDecorator } = this.props.form
    return (
      <div className={style.companyList}>
        <Breadcrumb className={style.title}>
          <Breadcrumb.Item>
            <Link to="/admin">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/admin/company/list">公司管理</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>公司缴费记录列表</Breadcrumb.Item>
        </Breadcrumb>
        <div className={style.filters}>
          <div className={style.item}>
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item>
                    <div
                      onClick={_ => {
                        let idArr = []
                        for (let v of this.state.selectedRows) {
                          idArr.push(v.id)
                        }
                        if (idArr.length < 1) {
                          return message.error("请选择要删除的公司", 2)
                        }
                        this.deletePaymentRecord(idArr)
                      }}>
                      删除
                    </div>
                  </Menu.Item>
                </Menu>
              }
              className={style.drown}
              placement="bottomLeft">
              <Button>
                更多操作 <Icon type="down" />
              </Button>
            </Dropdown>
            <Button
              className={style.filterBtn}
              type="primary"
              icon="filter"
              onClick={_ => {
                this.setState({
                  hasFilter: true,
                })
              }}>
              筛选
            </Button>
          </div>
          <Search
            placeholder="请输入姓名|手机号|身份证号码..."
            enterButton="搜索"
            onSearch={val => {}}
            className={style.search}
          />
        </div>
        <Table
          className={style.table}
          columns={this.columns}
          dataSource={this.state.list}
          onChange={this.handleChange}
          rowKey="id"
          loading={this.state.tableIsLoading}
          rowSelection={{
            onChange: (selectedRowKeys, selectedRows) => {
              this.setState({
                selectedRows,
              })
            },
          }}
          pagination={this.state.pagination}
        />
        <Modal
          title="筛选"
          visible={this.state.hasFilter}
          okText="确认"
          cancelText="取消"
          onCancel={_ => {
            this.setState({ hasFilter: false })
          }}
          onOk={this.onFilter}>
          <Form className={style.formList}>
            <div className={style.item}>
              <div className={style.title}>公司名称</div>
              {getFieldDecorator("companyNameCondition", { initialValue: TYPE.undefined })(
                <Select className={style.condition}>
                  <Option value={TYPE.undefined}>请选择</Option>
                  <Option value={TYPE.eqString}>{TYPE_ZH[TYPE.eqString]}</Option>
                  <Option value={TYPE.like}>{TYPE_ZH[TYPE.like]}</Option>
                  <Option value={TYPE.notLike}>{TYPE_ZH[TYPE.notLike]}</Option>
                </Select>,
              )}
              {getFieldDecorator("companyNameVal", {})(
                <Input placeholder="请输入" className={style.input} />,
              )}
            </div>
            <div className={style.item}>
              <div className={style.title}>缴纳类型</div>
              {getFieldDecorator("typeCondition", { initialValue: TYPE.undefined })(
                <Select className={style.condition}>
                  <Option value={TYPE.undefined}>请选择</Option>
                  <Option value={TYPE.in}>{TYPE_ZH[TYPE.in]}</Option>
                  <Option value={TYPE.notIn}>{TYPE_ZH[TYPE.notIn]}</Option>
                </Select>,
              )}
              {getFieldDecorator("typeVal", {})(
                <Select mode="multiple" placeholder="请选择" className={style.input}>
                  {this.state.typeChildren.map((v, k) => {
                    return (
                      <Option key={`${k}`} value={v.value}>
                        {v.label}
                      </Option>
                    )
                  })}
                </Select>,
              )}
            </div>
            <div className={style.item}>
              <div className={style.title}>缴纳状态</div>
              {getFieldDecorator("paymentStatusCondition", { initialValue: TYPE.undefined })(
                <Select className={style.condition}>
                  <Option value={TYPE.undefined}>请选择</Option>
                  <Option value={TYPE.eq}>{TYPE_ZH[TYPE.eq]}</Option>
                  <Option value={TYPE.neq}>{TYPE_ZH[TYPE.neq]}</Option>
                </Select>,
              )}
              {getFieldDecorator("paymentStatusVal", {})(
                <Select placeholder="请选择" className={style.input}>
                  {this.state.paymentStatusList.map((v, k) => {
                    return (
                      <Option key={`${k}`} value={v.value}>
                        {v.label}
                      </Option>
                    )
                  })}
                </Select>,
              )}
            </div>
            <div className={style.item}>
              <div className={style.title}>缴纳金额( 元 ) </div>
              {getFieldDecorator("paymentMoneyCondition", { initialValue: TYPE.undefined })(
                <Select
                  className={style.condition}
                  onChange={val => {
                    this.setState({ moneySelect: val })
                  }}>
                  <Option value={TYPE.undefined}>请选择</Option>
                  <Option value={TYPE.eq}>{TYPE_ZH[TYPE.eq]}</Option>
                  <Option value={TYPE.lt}>{TYPE_ZH[TYPE.lt]}</Option>
                  <Option value={TYPE.gt}>{TYPE_ZH[TYPE.gt]}</Option>
                  <Option value={TYPE.neq}>{TYPE_ZH[TYPE.neq]}</Option>
                  <Option value={TYPE.betweenValue}>{TYPE_ZH[TYPE.betweenValue]}</Option>
                </Select>,
              )}
              {getFieldDecorator("paymentMoneyVal", {})(
                <InputNumber
                  placeholder="请输入"
                  className={
                    this.state.moneySelect === TYPE.betweenValue ? style.hidden : style.input
                  }
                />,
              )}
              <div
                className={
                  this.state.moneySelect === TYPE.betweenValue
                    ? csn(style.input, style.moneyBetween)
                    : style.hidden
                }>
                {getFieldDecorator("paymentMinMoneyVal", {})(
                  <InputNumber placeholder="金额最小值" className={style.moneyInput} />,
                )}
                -
                {getFieldDecorator("paymentMaxMoneyVal", {})(
                  <InputNumber placeholder="金额最大值" className={style.moneyInput} />,
                )}
              </div>
            </div>
            <div className={style.item}>
              <div className={style.title}>缴费时间</div>
              {getFieldDecorator("dateCondition", { initialValue: TYPE.undefined })(
                <Select
                  className={style.condition}
                  onChange={val => {
                    this.setState({ dateSelect: val })
                  }}>
                  <Option value={TYPE.undefined}>请选择</Option>
                  <Option value={TYPE.before}>{TYPE_ZH[TYPE.before]}</Option>
                  <Option value={TYPE.after}>{TYPE_ZH[TYPE.after]}</Option>
                  <Option value={TYPE.betweenTime}>{TYPE_ZH[TYPE.betweenTime]}</Option>
                </Select>,
              )}
              {getFieldDecorator("dateBeforeVal", {})(
                <DatePicker
                  format={"YYYY-MM-DD"}
                  className={this.state.dateSelect === TYPE.before ? style.input : style.hidden}
                />,
              )}
              {getFieldDecorator("dateBetweenVal", {})(
                <RangePicker
                  format={"YYYY-MM-DD"}
                  className={
                    this.state.dateSelect === TYPE.betweenTime ? style.input : style.hidden
                  }
                />,
              )}
              {getFieldDecorator("dateAfterVal", {})(
                <DatePicker
                  format={"YYYY-MM-DD"}
                  className={this.state.dateSelect === TYPE.after ? style.input : style.hidden}
                />,
              )}
            </div>
          </Form>
        </Modal>
        <Modal
          title="编辑"
          visible={this.state.editPaymentRecordModalActive}
          okText="提交"
          cancelText="取消"
          onCancel={_ => {
            this.setState({ editPaymentRecordModalActive: false })
          }}
          onOk={this.editPaymentRecord}>
          <Form className={style.formList}>
            <Form.Item label="公司名称" labelCol={{ span: 5 }} wrapperCol={{ span: 18 }}>
              <Select
                placeholder="请选择"
                defaultValue={this.state.currentRecord.company.id}
                disabled>
                {this.state.companyList.map((v, k) => {
                  return (
                    <Option key={`${k}`} value={v.id}>
                      {v.title}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>
            <Form.Item label="缴纳类型" labelCol={{ span: 5 }} wrapperCol={{ span: 18 }}>
              {getFieldDecorator("paymentType", {
                rules: [{ required: true, message: "请选择缴纳类型" }],
              })(
                <Select placeholder="请选择">
                  {this.state.typeChildren.map((v, k) => {
                    return (
                      <Option key={`${k}`} value={v.value}>
                        {v.label}
                      </Option>
                    )
                  })}
                </Select>,
              )}
            </Form.Item>
            <Form.Item label="缴纳状态" labelCol={{ span: 5 }} wrapperCol={{ span: 18 }}>
              {getFieldDecorator("paymentStatus", {
                rules: [{ required: true, message: "请选择缴纳状态" }],
              })(
                <Select placeholder="请选择">
                  {this.state.paymentStatusList.map((v, k) => {
                    return (
                      <Option key={`${k}`} value={v.value}>
                        {v.label}
                      </Option>
                    )
                  })}
                </Select>,
              )}
            </Form.Item>
            <Form.Item label="缴纳金额(元)" labelCol={{ span: 5 }} wrapperCol={{ span: 18 }}>
              {getFieldDecorator("paymentMoney", {
                rules: [{ required: true, message: "请输入缴纳金额" }],
              })(<InputNumber placeholder="请输入" style={{ width: "100%" }} />)}
            </Form.Item>
          </Form>
        </Modal>
      </div>
    )
  }
}
export default Form.create()(List)
