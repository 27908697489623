import React, { Component } from 'react';
import {
  Breadcrumb, Form, Alert, Spin, Card, Icon, Button, Drawer,
  message, Input, Radio, Modal,
} from 'antd';
import { Link, } from "react-router-dom";
import gSass from '../../../utils/sass';
import cns from "classnames";
import affairsApi, { nameMapDbId, } from "../../../services/affairs";
import userApi from "../../../services/user";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Toast } from 'antd-mobile';

const style = gSass.admin.process.edit;
const RadioGroup = Radio.Group;

// a little function to help us with reordering the result
const reorder = (steps, startIndex, endIndex) => {
  const result = Array.from(steps);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: 8 * 2,
  // margin: `0 ${grid}px 0 0`,
  // change background colour if dragging
  // background: isDragging ? 'lightgreen' : 'grey',
  border: isDragging ? 'dashed 1px #ccc' : 'none',
  // styles we need to apply on draggables
  ...draggableStyle,
});
const getListStyle = isDraggingOver => ({
  // background: isDraggingOver ? 'lightblue' : 'lightgrey',
  border: isDraggingOver ? 'solid 1px #eee' : 'none',
  display: 'flex',
  // padding: grid,
  overflow: 'auto',
});
class Edit extends Component {
  undefinedChooseStepIdx = -1;
  constructor(props) {
    super(props)
    const affairsName = props.computedMatch.params.affairs;
    this.state = {
      showDrawerMask: true,
      loading: true,
      isChooseAdminUserModalActive: false,//选择管理员的modal
      currEditStepIdx: 0,//当前编辑的步骤idx
      isStepDetailDrawerActive: false,//编辑步骤详情的drawer
      adminList: [],
      affairs: {
        id: nameMapDbId[affairsName],
        name: '',
        description: '',
        steps: [],
      },
      adminIdMapNick: {},
    };
  }

  componentDidMount() {
    this.init();
  }

  init = async _ => {
    //todo 获取事务详情 
    const { data: { affairs: affairsDetail } } = await affairsApi.detail({ id: this.state.affairs.id });
    if (affairsDetail.steps.length === 0) {
      affairsDetail.steps.push({
        name: '步骤1',
        should_all_pass: 1,
        charge_uid_list: [],
      })
    }
    //获取管理员列表 adminList
    let { data: { list: adminList } } = await userApi.getAdminList({ page: -1, limit: -1 });
    //通过管理员列表 格式化一个 管理员 id=>nick 的映射
    adminList = adminList.map(v => ({
      id: v.id,
      nick: v.nick || ('未命名管理员' + v.id),
    }))
    let adminIdMapNick = [];
    for (let v of adminList) {
      adminIdMapNick[v.id] = v.nick;
    }
    this.setState({
      loading: false,
      adminList: adminList,
      affairs: affairsDetail,
      adminIdMapNick,
    })

  }
  openStepDetailDrawer = currEditStepIdx => {
    this.setState({
      currEditStepIdx,
      isStepDetailDrawerActive: true,
    })
  }
  closeStepDetailDrawer = _ => {
    this.closeChooseAdminUserModal()
    if (this.state.currEditStepIdx > this.undefinedChooseStepIdx &&
      this.state.affairs.steps.length > this.state.currEditStepIdx &&
      this.state.affairs.steps[this.state.currEditStepIdx].charge_uid_list.length === 0) {
      let affairs = this.state.affairs;
      if (affairs.steps.length > 1) {
        affairs.steps.splice(this.state.currEditStepIdx, 1)
        this.setState({
          affairs
        })
      }
    } else {
      if (this.state.currEditStepIdx > this.undefinedChooseStepIdx &&
        this.state.affairs.steps.length > this.state.currEditStepIdx &&
        this.state.affairs.steps[this.state.currEditStepIdx].charge_uid_list.length === 1) {
        let affairs = this.state.affairs;
        affairs.steps[this.state.currEditStepIdx].should_all_pass = 1;
        this.setState({
          affairs,
        })
      }
    }
    this.setState({
      isStepDetailDrawerActive: false,
      currEditStepIdx: this.undefinedChooseStepIdx,
    })
  }
  closeChooseAdminUserModal = _ => {
    this.setState({
      showDrawerMask: true,
      isChooseAdminUserModalActive: false,
    })
  }
  showChooseAdminUserModal = _ => {
    this.setState({
      showDrawerMask: false,
      isChooseAdminUserModalActive: true,
    })
  }
  saveStep = _ => {
    this.closeStepDetailDrawer();
  }
  deleteStep = k => {
    let affairs = this.state.affairs;
    if (affairs.steps.length > 1) {
      affairs.steps.splice(k, 1);
    } else {
      message.info("每个流程至少有一个步骤", 1)
    }
    this.setState({
      affairs,
    })
  }
  addStep = currStepIdx => {
    let affairs = this.state.affairs;
    affairs.steps.splice(currStepIdx + 1, 0, {
      name: '未命名',
      should_all_pass: 1,
      //审核人列表
      charge_uid_list: [],
    });
    this.setState({
      affairs,
    })
    this.openStepDetailDrawer(currStepIdx + 1);
  }
  onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    let affairs = this.state.affairs;
    const steps = reorder(
      affairs.steps,
      result.source.index,
      result.destination.index,
    );
    affairs.steps = steps;
    this.setState({
      affairs,
    });
  }
  valiateFormData = async _ => {
    const { affairs, } = this.state;
    if (affairs.name === '') {
      return Promise.reject('事务名称不能为空')
    }
    if (affairs.steps.length === 0) {
      return Promise.reject('事务最少有一个步骤')
    }
    for (let step of affairs.steps) {
      if (step.name === '') {
        return Promise.reject('步骤名称不能为空')
      }
      if (step.charge_uid_list.length === 0) {
        return Promise.reject('审核人至少为1人')
      }
    }
  }
  updateAfairs = _ => {
    console.log(this.state.affairs)
    const { affairs, } = this.state;
    this.valiateFormData().then(async _ => {
      try {
        await affairsApi.edit({ id: affairs.id, detail: affairs })
        Toast.success('更新成功', 1, null, false)
      } catch (e) {
        Toast.fail('更新失败,错误信息: ' + e.msg, 3, null, false)
      }
    }).catch(e => {
      Toast.fail(e, 2)
    })
  }
  render() {
    // const { getFieldDecorator } = this.props.form;
    return (
      <div className={style.edit}>
        <Breadcrumb className={style.title}>
          <Breadcrumb.Item><Link to="/admin">首页</Link></Breadcrumb.Item>
          <Breadcrumb.Item><Link to="/admin/apply/allList">事务</Link></Breadcrumb.Item>
          <Breadcrumb.Item>编辑事务</Breadcrumb.Item>
        </Breadcrumb>
        <div className={style.prompt}>
          <Alert description="您此次编辑的事务不会影响到上一次事务设置; 您可以拖动下面的步骤进行审核顺序的调整" type="info" showIcon message="提示" />
        </div>
        <Spin spinning={this.state.loading} size="large">
          <div className={style.content}>
            <div className={style.affairsName}>流程名称: <span className={style.affairsTitle}>{this.state.affairs.name}</span></div>
            <div className={style.processList}>
              <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId="droppable" direction="horizontal">
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                      {...provided.droppableProps}
                    >
                      {this.state.affairs.steps.map((stepItem, k) => {
                        return (
                          <Draggable key={k} draggableId={k.toString()} index={k}>
                            {(provided, snapshot) => (
                              <div className={style.item} ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}>
                                <Card size="small" extra={<Icon className={style.icon} type="close" onClick={_ => {
                                  this.deleteStep(k);
                                }} />} className={style.processItem}
                                  title={<div className={style.theme}> <Icon type="user" /><Input className={style.step} value={stepItem.name}
                                    onChange={evt => {
                                      let affairs = this.state.affairs;
                                      affairs.steps[k].name = evt.target.value;
                                      this.setState({
                                        affairs,
                                      })
                                    }}
                                    onBlur={evt => {
                                      if (evt.target.value === "") {
                                        message.error("请输入步骤名", 1);
                                        let affairs = this.state.affairs;
                                        affairs.steps[k].name = "未命名";
                                        this.setState({
                                          affairs,
                                        })
                                      }
                                    }}
                                  /></div>}
                                > <div style={{ minHeight: '20px', }} onClick={_ => this.openStepDetailDrawer(k)}>
                                    {stepItem.charge_uid_list.map(stepChargeUserItemId => {
                                      return (<div key={stepChargeUserItemId} className={style.name}>{this.state.adminIdMapNick[stepChargeUserItemId]}</div>);
                                    })}
                                  </div>
                                </Card>
                                <Button className={style.processBtn} onClick={_ => {
                                  this.addStep(k)
                                }} shape="circle" type="primary" icon="plus" />
                              </div>
                            )}
                          </Draggable>);
                      })}
                    </div>)
                  }
                </Droppable>
              </DragDropContext>
            </div>
            <div style={{ justifyContent: 'center', display: 'flex' }}>
              <Button onClick={this.updateAfairs}>提交</Button>
            </div>
            <Drawer mask={this.state.showDrawerMask} width={600} title="编辑事务" placement="right" closable={false} onClose={this.closeStepDetailDrawer}
              className={style.drawer} visible={this.state.isStepDetailDrawerActive} >
              {this.state.currEditStepIdx > this.undefinedChooseStepIdx && this.state.affairs.steps.length > this.state.currEditStepIdx ?
                <div className={style.stepChargeUserName}>
                  <div className={style.name}>步骤名:</div>
                  <Input className={style.stepName} value={this.state.affairs.steps[this.state.currEditStepIdx].name}
                    onChange={evt => {
                      let affairs = this.state.affairs;
                      affairs.steps[this.state.currEditStepIdx].name = evt.target.value;
                      this.setState({
                        affairs,
                      })
                    }}
                    onBlur={evt => {
                      if (evt.target.value === "") {
                        message.error("请输入步骤名", 1);
                        let affairs = this.state.affairs;
                        affairs.steps[this.state.currEditStepIdx].name = "未命名";
                        this.setState({
                          affairs,
                        })
                      }
                    }}
                  /> </div> : null}
              {this.state.currEditStepIdx > this.undefinedChooseStepIdx && this.state.affairs.steps.length > this.state.currEditStepIdx ?
                <div className={style.stepChargeUserList}>
                  <div className={style.stepChargeUseTitle}>审核人员列表:</div>
                  <div>
                    {this.state.affairs.steps[this.state.currEditStepIdx].charge_uid_list.map((v, k) => {
                      return <div className={style.chargeUserItem} key={k}>
                        <div className={style.chargeUserNick}>{this.state.adminIdMapNick[v]}</div>
                        <div onClick={_ => {
                          let affairs = this.state.affairs;
                          affairs.steps[this.state.currEditStepIdx].charge_uid_list = affairs.steps[this.state.currEditStepIdx].charge_uid_list.filter(uid => {
                            return v !== uid;
                          })
                          this.setState({ affairs })
                        }}><Icon type="close" className={style.icon} /></div>
                      </div>
                    })}
                  </div>
                </div> : null}

              {this.state.currEditStepIdx > this.undefinedChooseStepIdx && this.state.affairs.steps.length > this.state.currEditStepIdx
                ? <div className={this.state.affairs.steps[this.state.currEditStepIdx].charge_uid_list.length > 1 ? style.promptShow : style.hidden}>
                  <div className={style.prompt}>会签: 在一个流程中所有选中的用户都必须审核通过, 申请成功;<br />
                    或签: 在一个流程中所有选中的用户其中一个通过审核, 申请成功</div>
                  <RadioGroup value={this.state.affairs.steps[this.state.currEditStepIdx].should_all_pass} onChange={evt => {
                    let affairs = this.state.affairs;
                    affairs.steps[this.state.currEditStepIdx].should_all_pass = evt.target.value;
                    this.setState({
                      affairs,
                    })
                  }}>
                    <Radio value={0}>或签</Radio>
                    <Radio value={1}>会签</Radio>
                  </RadioGroup></div> : null}
              <div className={style.selectBtn}>
                <Button onClick={this.showChooseAdminUserModal} type="primary" className={style.btn}>选择用户</Button>
                <Button onClick={this.saveStep} type="primary" className={style.btn}>保存</Button>
              </div>
              {this.state.currEditStepIdx > this.undefinedChooseStepIdx && this.state.affairs.steps.length > this.state.currEditStepIdx ?
                <Modal visible={this.state.isChooseAdminUserModalActive} className={style.modal} title="选择人员" onOk={this.closeChooseAdminUserModal} onCancel={this.closeChooseAdminUserModal}
                >
                  <div className={style.adminList}>
                    {this.state.adminList.map((v, k) => {
                      return <div key={k} className={style.adminItem}>
                        <Button className={cns(style.adminTitle, {
                          [style.adminIsChoose]: this.state.affairs.steps[this.state.currEditStepIdx].charge_uid_list.includes(v.id)
                        })} key={k} onClick={_ => {
                          let affairs = this.state.affairs;
                          if (affairs.steps[this.state.currEditStepIdx].charge_uid_list.includes(v.id)) {
                            affairs.steps[this.state.currEditStepIdx].charge_uid_list = affairs.steps[this.state.currEditStepIdx].charge_uid_list.filter(v2 => v2 !== v.id)
                          } else {
                            affairs.steps[this.state.currEditStepIdx].charge_uid_list.push(v.id)
                          }
                          this.setState({
                            affairs,
                          })
                        }} >{v.nick}</Button>
                      </div>
                    })}
                  </div>
                </Modal> : null}
            </Drawer>
          </div>
        </Spin>

      </div >
    );
  }
}
export default Form.create()(Edit);
